import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import React, { createRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { TextBoxLatest } from '../../common/TextBoxLatest/TextBoxLatest';
import { createStyles, makeStyles } from '@mui/styles';
import SearchIcon from "@mui/icons-material/Search";
import { IState } from '../../../redux/reducers/rootReducers';
import { connect, useDispatch } from 'react-redux';
import { globalSearchSSP } from '../../../redux/actions/GlobalSearch/GlobalSearch';
import { handleTime } from '../../helpers/helperFunctions';


function SearchResults(props:any) {
  const {loggedContact} = props;
  return <> {loggedContact?.id && <SearchResults1 {...props} />}</>
}
function SearchResults1(props:any) {
  const {AllContactAid, articleList, contactTickets,loggedContact={}} = props;
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchRef:any = createRef();
  const classes = useStyles();
  useEffect(()=>{
    if(searchRef.current){
      searchRef.current.value = param.value || "";
      dispatch(globalSearchSSP(loggedContact?.id,param?.value || ""))
    }
  },[param.value])
  const onchangeHandler = () =>{
    console.log("onchangeHandler", searchRef.current.value)
    handleTime.cancel();
    handleTime.setup(()=>{
      navigate(`/SearchResults/${searchRef.current.value}`)
    });
  }
  /* ==== callbacks ==== */
  const articleCB = (id:number)=>{
    navigate(`/ArticlesList/${id}`)
  }
  const serviceCB = (id:number)=>{
    navigate(`/ViewService?id=${id}`)
  }
  const ticketsCB = (id:number)=>{
    //navigate(`${id}`)
  }
  return (
    <>
      <Box className="p-2">
        <Box className="pb-1">
          <Typography className="text-center pb-1" variant='h5'>Search Results</Typography>
          <TextBoxLatest
              multiline={false}
              placeholder="Please Enter"
              title={""}
              ref={searchRef}
              onchangeHandler={onchangeHandler}
              isMandotary={false}
              errorMsg={``}
              isError={false}
              name='search'
              type="search"
              parentClassNames = "ssp-global-search"
              InputProps={{
                  classes: { input: classes.input1 },
                  endAdornment: (
                      <InputAdornment onClick={()=>{
                        onchangeHandler()
                      }} className='icon-holder' position="end">
                      <SearchIcon className={`${classes.searchIcon} ssp-home-search-icon`} />
                      </InputAdornment>
                  ),
              }}
          />
        </Box>
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <Typography>Tickets</Typography>
                <aside className="aside">
                  {(!contactTickets?.map || contactTickets.length===0) && <NoDataFound />}
                  {contactTickets?.map && contactTickets?.map((i:any,index:number)=>{
                    const {title,description} = i.ticketLogs;
                    return (
                      <React.Fragment key={index}>
                        <ListItem
                          callback={ticketsCB} 
                          id = {i.id}
                          label = {title}
                          description = {description}
                        />
                      </React.Fragment>
                    )
                  })}
                </aside>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>Services</Typography>
              <aside className="aside">
                {(!AllContactAid?.map || AllContactAid.length===0) && <NoDataFound />}
                {AllContactAid?.map && AllContactAid?.map((i:any,index:number)=>{
                  const {name,description,id} = i;
                  return (
                    <React.Fragment key={index}>
                      <ListItem
                        callback={serviceCB} 
                        id = {id}
                        label = {name}
                        description = {description}
                      />
                    </React.Fragment>
                  )
                })}
              </aside>
            </Grid>
           
            <Grid item xs={12} md={4}>
                <Typography>Articles</Typography>
                <aside className="aside">
                  {(!articleList?.map || articleList.length===0) && <NoDataFound />}
                  {articleList?.map && articleList?.map((i:any,index:number)=>{
                    const {name,keywords,id} = i;
                    return (
                      <React.Fragment key={index}>
                        <ListItem
                          callback={articleCB} 
                          id = {id}
                          label = {name}
                          description = {keywords}
                        />
                      </React.Fragment>
                    )
                  })}
                </aside>
            </Grid>
        </Grid>
      </Box>
    </>
  )
}

const NoDataFound = () =>{
  return(
  <Box className="p-2 text-center">
    <Typography>No Records Found</Typography>
  </Box>)
}
interface IListItem {
  id : number;
  label : string;
  description : string;
  callback:any;
}
const ListItem = (props:IListItem) =>{
  const {id, label, description, callback} = props;
  return(<>
    <Box onClick={()=>callback(id)} className="search-result-item">
      <Typography><span className='text-primary'>#{id}</span> {" "} {label}</Typography>
      <Typography className="desc">{description}</Typography>
    </Box>
  </>)
}

const useStyles = makeStyles(() =>
  createStyles({
    input1: {
      fontSize: "13px",
      fontWeight: 400,
    },
    searchIcon: {
      fontSize: "16px",
      color:"#5572df"
    },
  }
))

const mapStateToProps = (state:IState) => {
  return ({
    AllContactAid : state?.ContactsReducer?.AllContactAid,
    articleList : state?.KnowledgeBaseReducer?.articleList,
    contactTickets : state?.ContactsReducer?.contactTickets,
    loggedContact : state?.ContactsReducer?.loggedContact
  })
};

export default connect(mapStateToProps)(SearchResults);