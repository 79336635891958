import { DataGrid, GridColDef } from '@mui/x-data-grid';
import * as React from 'react';
import { connect } from 'react-redux';
import { reportTicketLoad } from '../../redux/actions/TicketsManagement/TicketsManagement';
import { IState } from '../../redux/reducers/rootReducers';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
  } from "@mui/x-data-grid";
import CSVLoader from './CSVLoader';
const columns: GridColDef[] = [
    {
        "field": "id",
        "headerName": "id",
        "width": 150
    },
    {
        "field": "sourceCode",
        "headerName": "sourceCode",
        "width": 150
    },
    {
        "field": "title",
        "headerName": "Subject",
        "width": 150
    },
    {
        "field": "openDateTime",
        "headerName": "openDateTime",
        "width": 150
    },
    {
        "field": "dueDateTime",
        "headerName": "dueDateTime",
        "width": 150
    },
    {
        "field": "slaRemaining",
        "headerName": "slaRemaining",
        "width": 150
    },
    {
        "field": "ticketStatus",
        "headerName": "ticketStatus",
        "width": 150
    },
    {
        "field": "priority",
        "headerName": "priority",
        "width": 150
    },
    {
        "field": "ticketType",
        "headerName": "ticketType",
        "width": 150
    },
    {
        "field": "group",
        "headerName": "group",
        "width": 150
    },
    {
        "field": "agent",
        "headerName": "agent",
        "width": 150
    },
    {
        "field": "contacts",
        "headerName": "contacts",
        "width": 150
    },
    {
        "field": "contactOrganisation",
        "headerName": "contactOrganisation",
        "width": 150
    },
    {
        "field": "note",
        "headerName": "note",
        "width": 150
    },
    {
        "field": "thirdPartyRefNo",
        "headerName": "thirdPartyRefNo",
        "width": 150
    }
];
function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
}
export function ReportsTest({allTickets=[],dispatch}:{allTickets:any,dispatch:any}) {
    const loadTickets = React.useCallback(() =>{
        dispatch(reportTicketLoad())
    },[]);
    const table = React.useMemo(()=>{
        return <DataGrid 
                    rows={allTickets} 
                    columns={columns} 
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
    },[allTickets?.length])
    const loader = React.useMemo(()=>{
        return <CSVLoader />
    },[])
    return (
        <>
            <div style={{ height: "80vh", width: '100%' }}>
                <button onClick={loadTickets}>Load Tickets</button>
                <button onClick={()=>{
                    dispatch({ type: "STORE_TICKETS_FOR_REPORT", payload:[]});
                }}>reset</button>
                {loader}
                {allTickets?.length}
                <hr />
                {table}
            </div>
        </>
    );
}


const mapStateToProps = (state:IState) => {
    return ({
      allTickets: state?.AllTicketsReducer?.allTickets,
    })
  };
  
export default connect(mapStateToProps)(ReportsTest);