import { Box, Typography } from '@mui/material';
import { createRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    image,
    lineHeight,
    link,
    list,
    paragraphStyle,
    table,
    textStyle
} from "suneditor/src/plugins";
import { getTemplatesByID } from '../../../redux/actions/adminInterfaces/EmailTemplates';
import { FileUpload } from '../../../redux/actions/FileUpload/FileUpload';
import { toggleEmailPopup } from '../../../redux/actions/TicketsManagement/MobileTicketActions';
import { getTicketByID } from '../../../redux/actions/TicketsManagement/TicketsManagement';
import { IState } from '../../../redux/reducers/rootReducers';
import { TextBoxLatest } from '../../common/TextBoxLatest/TextBoxLatest';
import { getDynamicFieldValuesForTicket } from '../../helpers/helperFunctions';
import BottomDrawer from '../MobileCommon/BottomDrawer';
import FormWrapper from './FormWrapper';
import { sendEmailAfterTicketCreation } from '../../../services/dashboard/dashboard.service';


// {
//     "sourceCode": "Phone",
//     "TicketLogs": {
//         "TicketId": 13,
//         "name": "string",
//         "actions": "AddUpdate",
//         "companyName": "string",
//         "from": "research@simplisysltd.onmicrosoft.com",
//         "to": "ragullingan@gmail.com",
//         "title": "New Request Approval",
//         "body": "<p>Hi&nbsp;Ragul</p><p>Please note that we have updated your Case as follows:</p><p>null<br></p><p>Any questions please do not hesitate to give HR Case Team a call quoting this Case reference number.</p><p>Thanks</p><p>GAC Ooty Admin<br></p>",
//         "cc": "something@gmail.com",
//         "bcc": null,
//         "description": "string",
//         "roleId": 0,
//         "sourceCode": "Phone",
//         "isActive": true,
//         "ticketLogAttachmentsList": []
//     }
// }


interface ISendEmailProps {
    dispatch?:any;
    info?:any;
}
function SendEmail(props:ISendEmailProps) {
    const {dispatch,info=null} = props;
    const toRef:any = createRef();
    const ccRef:any = createRef();
    const bccRef:any = createRef();
    const subjectRef:any = createRef();

    const [ticketInfo,setTicketInfo] = useState<any>(null);
    const [template,setTemplate] = useState<any>({});
    const [bodyVal,setBodyVal] = useState<any>('');

    const submitSendEmail = (val:any) =>{
        console.log("submitSendEmail",val)
        const {to="",cc="",bcc="",subject="",body=""} = val || {};
        const payload = {
            "sourceCode": "Phone",
            "TicketLogs": {
                "TicketId": ticketInfo?.id || "",
                "name": "",
                "actions": "AddUpdate",
                "companyName": "string",
                "from": template?.from || "",
                "to": to,
                "title": subject,
                "body": body,
                "cc": cc,
                "bcc": bcc,
                "description": "string",
                "roleId": 0,
                "sourceCode": "Phone",
                "isActive": true,
                "ticketLogAttachmentsList": []
            }
        }
        sendEmailAfterTicketCreation(payload).then((res:any)=>{
            console.log("sendEmailAfterTicketCreation",res.data)
        })
        closeSendEmail();
    }
    const closeSendEmail = () =>{
        dispatch(toggleEmailPopup(null))
        clearAll()
    }


    const handleChange = (content: any) => {
        setBodyVal(content);
    };
    const getTemplatesByIDCB = (res:any) =>{
        console.log("getTemplatesByIDCB",res,ticketInfo)
        if(subjectRef?.current) subjectRef.current.value = getDynamicFieldValuesForTicket(res.subject,ticketInfo);

        const formattedBody = getDynamicFieldValuesForTicket(res.body,ticketInfo)
        setBodyVal(formattedBody||"")
        setTemplate(res)
    }
    const clearAll = ()=>{
        setTemplate(null);
        subjectRef.current.value = "";
        toRef.current.value = "";
        ccRef.current.value = "";
        bccRef.current.value = "";
        setBodyVal('');
        setTicketInfo(null)
    }
    useEffect(()=>{
        if(info?.ticketId){
            dispatch(getTicketByID(info?.ticketId,getTicketByIDCB))
        }
    },[info?.ticketId])
    useEffect(()=>{
        if(info?.emailTemplateId){
            dispatch(getTemplatesByID(info?.emailTemplateId,getTemplatesByIDCB));
        }
        const {circulationListTO,contacts, circulationListCC, circulationListBCC} = ticketInfo?.ticketLogs || {}; 
        if(toRef?.current) toRef.current.value = circulationListTO || contacts?.email || "";
        if(ccRef?.current) ccRef.current.value = circulationListCC;
        if(bccRef?.current) bccRef.current.value = circulationListBCC;
    },[ticketInfo])

    const getTicketByIDCB = (resStatus:string,res:any) =>{
        if(resStatus === "0"){
            console.log("getTicketByIDCB",res)
            setTicketInfo(res)
        }
    }

    const onImageUploadBefore = (files: any, info: any, uploadHandler: any) => {
        const uploadCB = (resStatus: string, res: any) => {
          if (resStatus !== "0") return;
          let { filePath = "", name = "", fileSize = 0 } = res?.data;
          console.log("uploadesRes", res.data);
          const response = {
            result: [
              {
                url: filePath,
                name: name,
                size: fileSize,
              },
            ],
          };
          uploadHandler(response);
        };
        let fileObject = {
          description: "First File",
          filetypeid: 1,
          ExtensionTypeId: 1,
          name: "email-photo",
          formFile: files[0],
        };
        dispatch(FileUpload(fileObject, () => {}, false, uploadCB));
    };
    return (
        <Box>
            <BottomDrawer
                isOpened ={info?.emailTemplateId ? true : false}
                onClose ={closeSendEmail}
                title="Send Email"
            >
                <>
                    <FormWrapper
                        onClose={closeSendEmail}
                        onSubmit={submitSendEmail}
                        submitLabel='Send Email'
                    >
                        <Box className="sendMailDrawer">
                            <TextBoxLatest
                                placeholder="Please Specify"
                                title={"To"}
                                ref={toRef}
                                isMandotary={false}
                                errorMsg={``}
                                isError={false}
                                name='to'
                                type="text"
                            />
                            <TextBoxLatest
                                placeholder="Please Specify"
                                title={"CC"}
                                ref={ccRef}
                                isMandotary={false}
                                errorMsg={``}
                                isError={false}
                                name='cc'
                                type="text"
                            />
                            <TextBoxLatest
                                placeholder="Please Specify"
                                title={"BCC"}
                                ref={bccRef}
                                isMandotary={false}
                                errorMsg={``}
                                isError={false}
                                name='bcc'
                                type="text"
                            />
                            {/* <hr/> */}
                            <TextBoxLatest
                                placeholder="Please Specify"
                                title={"Subject"}
                                ref={subjectRef}
                                isMandotary={false}
                                errorMsg={``}
                                isError={false}
                                name='subject'
                                type="text"
                            />
                         
                            {template?.id &&
                                <>
                                    <Typography>Email Body</Typography>
                                    <SunEditor
                                        autoFocus={true}
                                        lang="en"
                                        name='body'
                                        setOptions={{
                                            showPathLabel: false,
                                            minHeight: "30vh",
                                            maxHeight: "30vh",
                                            placeholder: "Enter your text here!!!",
                                            plugins: [
                                                align,
                                                font,
                                                fontColor,
                                                fontSize,
                                                formatBlock,
                                                hiliteColor,
                                                horizontalRule,
                                                lineHeight,
                                                list,
                                                paragraphStyle,
                                                table,
                                                template,
                                                textStyle,
                                                image,
                                                link,
                                            ],
                                            buttonList: [
                                                ["font", "fontSize", "formatBlock"],
                                                [
                                                "bold",
                                                "underline",
                                                "italic"
                                                ],
                                                ["fontColor", "hiliteColor"],
                                                ["align", "horizontalRule", "list", "lineHeight"],
                                                ["table", "link", "image"],
                                                ["undo", "redo"],
                                            ],
                                            formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                                            font: [
                                                "Arial",
                                                "Calibri",
                                                "Comic Sans",
                                                "Courier",
                                                "Garamond",
                                                "Georgia",
                                                "Impact",
                                                "Lucida Console",
                                                "Palatino Linotype",
                                                "Segoe UI",
                                                "Tahoma",
                                                "Times New Roman",
                                                "Trebuchet MS",
                                            ],
                                            imageMultipleFile: true,
                                        }}
                                        onImageUploadBefore={onImageUploadBefore}
                                        defaultValue={bodyVal}
                                        setContents={bodyVal}
                                        onChange={(content: string) => {
                                            handleChange(content);
                                        }}
                                    />
                                </>
                            }
                        </Box>
                    </FormWrapper>
                </>
            </BottomDrawer>
        </Box>
    )
}



const mapStateToProps = (state:IState) => {
    return ({
        info: state?.AllTicketsReducer?.sendEmail,
    })
  };
  
export default connect(mapStateToProps)(SendEmail);
// export default SendEmail