import { Box, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import styled from "styled-components";
import { FieldsContext } from "./WebFormWorkspace";
import InputBoxIcon from "./../../assest/webform-icons/textBox.svg"
import HeadingIcon from "./../../assest/webform-icons/text.svg"
import TextAreaIcon from "./../../assest/webform-icons/text-area.svg"
import CalendarIcon from "./../../assest/webform-icons/calendar.svg"
import RadioBtnIcon from "./../../assest/webform-icons/radio.png"
import DropDownIcon from "./../../assest/webform-icons/dropdown.png"
import CheckBoxIcon from "./../../assest/webform-icons/checkbox.png"
import { iWebFormField, returnInitArr } from "./schema";

// import "./styles.css";
export const TrialWebForm = () => {
    return (
        <div style={{ display: "flex" }}>
            <TrialWebForm1 />
        </div>
    )
}




export const StyledBlockWrapper = styled.div`
  position: relative;
  background: white;
  padding: 20px;
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 4px;
  cursor: move;
  &:hover {
    //background: #eeeeee;
  }
`;

const sortableOptions = {
    animation: 150,
    fallbackOnBody: true,
    swapThreshold: 0.65,
    ghostClass: "ghost",
    group: "shared",
    forceFallback: true,
    put: false,
    pull: "clone",
};
const returnImg = (indexVal:string="1") =>{
    const tempObj:any = {
        "InputBox"  : <img
            srcSet={InputBoxIcon}
            src={InputBoxIcon}
            alt={"some title"}
            loading="lazy"
            height={"35px"}
        />,
        "Heading"  : <img
            srcSet={HeadingIcon}
            src={HeadingIcon}
            alt={"some title"}
            loading="lazy"
            height={"35px"}
        />,
        "TextArea"  : <img
            srcSet={TextAreaIcon}
            src={TextAreaIcon}
            alt={"some title"}
            loading="lazy"
            height={"35px"}
        />,
        "DatePicker"  : <img
            srcSet={CalendarIcon}
            src={CalendarIcon}
            alt={"some title"}
            loading="lazy"
            height={"35px"}
        />,
        "RadioButton"  : <img
            srcSet={RadioBtnIcon}
            src={RadioBtnIcon}
            alt={"some title"}
            loading="lazy"
            height={"35px"}
        />,
        "DropDown"  : <img
            srcSet={DropDownIcon}
            src={DropDownIcon}
            alt={"some title"}
            loading="lazy"
            height={"35px"}
        />,
        "CheckBox"  : <img
            srcSet={CheckBoxIcon}
            src={CheckBoxIcon}
            alt={"some title"}
            loading="lazy"
            height={"35px"}
        />,
        
    }
    return tempObj[indexVal] || <></>;
}


export function TrialWebForm1() {
    
    const {blocks} = useContext(FieldsContext)
    const [sideBlocks, setSideBlocks] = useState<iWebFormField[]>([...returnInitArr()]);
    useEffect(()=>{
        setSideBlocks([...returnInitArr()])
    },[blocks?.length])
    const setListAction = (...props:any) =>{
        return;
    }
    return (
        <Grid item md={2} xs={12} >
            <Typography>Form Elements</Typography>
            <hr/>
            <ReactSortable className="row pl-1 webform-formelements" onAdd={(e) => {}} list={sideBlocks} setList={setListAction} {...sortableOptions}>
                {sideBlocks.map((block, blockIndex) => (
                    <Grid item md={6} xs={12}>
                        <StyledBlockWrapper key={blockIndex} className="block">
                            <Box className="webformElements">
                                {returnImg(block.fieldType)}
                                <Typography>
                                    {block.fieldType}
                                </Typography>
                            </Box>
                        </StyledBlockWrapper>
                    </Grid>
                ))}
            </ReactSortable>
        </Grid>
    );
}

export default TrialWebForm;