import { Box, Button } from '@mui/material'
import React, { useState } from 'react'
import { IState } from '../../../../redux/reducers/rootReducers';
import { connect } from 'react-redux';
import CustomSelect from '../../../common/customSelect/CustomSelect';
import CustomFormLabel from '../../../common/form-label/CustomFormLabel ';
import { bulkUpdateNewTickets, getBulkNewTicket } from '../../../../redux/actions/TicketsManagement/TicketsManagement';
import { triggerAppAlert } from '../../../../redux/actions/SelfServicePortal/ContactAid/ContactAid';
import { debounce } from 'lodash';
interface IAgentGroupOptionProps{
    groups : any[];
    dispatch:any;
    ticketStatus : any [];
    selectedTicketIds:any;
    handleClose:any;
}
function AgentGroupOption(props:IAgentGroupOptionProps) {
    const {groups=[], ticketStatus=[],dispatch,selectedTicketIds,handleClose} = props;
    const [agentOptions,setAgentOptions] = useState<any>([])
    const [values,setValues] = useState<any>({
        fieldVal:  {
            groupId : null,
            agentId : null,
        },
        errors:{
            groupId : null,
            agentId : null,
        }
    })
    const loadTickets = debounce((statusIds:number[]=[],groupIds:number[]=[]) =>{
        dispatch(getBulkNewTicket("",statusIds,groupIds,10000,handleClose))
    },150);
    const getAllTicket = () =>{
        console.log("AllNewTickets", groups,ticketStatus);
        const statusIds:number[] = [];
        const groupIds:number[] = [];
        ticketStatus.map((i:any)=>{if(i.name === "New"){statusIds.push(i.id);}})
        groups.map((i:any)=>{groupIds.push(i.id);})
        loadTickets(statusIds,groupIds);
    }
    
    const validateForm = () =>{
        let valid = true;
        let tempObj:any = {};
        if(!values?.fieldVal?.groupId?.id){
            valid =  false;
            tempObj.groupId = "Group is required";
        }
        if(!values?.fieldVal?.agentId?.id){
            valid =  false;
            tempObj.agentId = "Agent is required";
        }
        if(!valid){
            setValues({
                ...values,
                errors:{
                    ...values.errors,
                    ...tempObj
                }
            })
        }
        return valid
    }
    const proceedDeleteItems = () =>{
        const newToMeId = ticketStatus.find((x:any)=>x.name === "NewtoMe")?.id;
        if(!newToMeId) {
            dispatch(triggerAppAlert(`User doesn't have Role Permission to do this operation! Please contact Admin.`, 'warning'))
            return;
        };
        const isValid = validateForm();
        if(!isValid) return;
        const payload = {
            "sourceCode": "System",
            "ticketLogs": {
                "sourceCode": "System",
                "groupId": values.fieldVal.groupId.id,
                "agentId": values.fieldVal.agentId.id,
                "statusId": newToMeId
            }
        }
        dispatch(bulkUpdateNewTickets(selectedTicketIds,payload,bulkUpdateNewTicketsCB))
    }
    const bulkUpdateNewTicketsCB = () =>{
        getAllTicket();
    }
    return (
        <Box>
            <CustomFormLabel
                labelName={"Select Group"}
                isMandotary={true}
                classNames='sml-txt'
            />
            <CustomSelect
                isOpen={false}
                placeholder="Select Group"
                width={"100%"}
                name="groupId"
                isColor={false}
                isDisabled={false}
                isError={values?.errors?.groupId ? true : false}
                helperText={values?.errors?.groupId || ""}
                defaultValue={values?.fieldVal?.groupId || null}
                options={groups || []}
                handleChange={(e: any) => {
                    setAgentOptions(e?.agentsList || [])
                    setValues({
                        fieldVal:  {
                            groupId : e,
                            agentId : null,
                        },
                        errors:{
                            ...values.errors,
                            groupId : null,
                        }
                    })
                }}
                isMulti={false}
                customClassNames="sml-txt-dropdown new-service"
            />
             <CustomFormLabel
                labelName={"Select Agent"}
                isMandotary={true}
                classNames='sml-txt'
            />
            <CustomSelect
                isOpen={false}
                placeholder="Select Agent"
                width={"100%"}
                name="agentId"
                isColor={false}
                isDisabled={false}
                isError={values?.errors?.agentId ? true : false}
                helperText={values?.errors?.agentId || ""}
                defaultValue={values?.fieldVal?.agentId || null}
                options={agentOptions || []}
                handleChange={(e: any) => {
                    setValues({
                        fieldVal:  {
                            ...values.fieldVal,
                            agentId : e,
                        },
                        errors:{
                            ...values.errors,
                            agentId : null,
                        }
                    })
                }}
                isMulti={false}
                customClassNames="sml-txt-dropdown new-service"
            />

            <Box className="pt-1 text-center">
                <Button onClick={handleClose}  variant="outlined" size="small">Cancel</Button> &nbsp;
                <Button onClick={proceedDeleteItems} variant="contained" size="small">Submit</Button>
            </Box>
        </Box>
    )
}


const mapStateToProps = (state:IState) => {
    return ({
        groups : state?.TicketsReducer?.allResponses?.groups,
        ticketStatus : state?.TicketsReducer?.allResponses?.ticketStatus,
    })
  };
  
export default connect(mapStateToProps)(AgentGroupOption);