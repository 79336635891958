import { Box, Button } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { ReactSortable } from 'react-sortablejs'
import { iWebFieldDropZoneProps, iWebFormField } from './schema';
import { StyledBlockWrapper } from './TrialWebForm';
import SelectWebformField from './SelectWebformField';
import { FieldsContext } from './WebFormWorkspace';
const sortableOptions = {
    animation: 150,
    fallbackOnBody: true,
    swapThreshold: 0.65,
    ghostClass: "ghost",
    group: "shared",
    forceFallback: true
  };
  
const WebFieldDropZone = () => {
    const {blocks,setBlocks,setEditFieldIndex,setEditFieldVal} = useContext(FieldsContext)
    
    const editItem = (id:number,val:iWebFormField) =>{
        setEditFieldIndex(id)
        setEditFieldVal(val)
    }
    const deleteItem = (ind:number) =>{
        setBlocks([...blocks.filter((x:any,index:number)=> index !== ind)])
    }

    useEffect(()=>{
        console.log('WebFieldDropZone',blocks)
    },[blocks?.length])
    return (
        <>
            <Box className="white-bg webform-holder">
                <div className="webForm-dropable-holder">
                    <ReactSortable  list={blocks} setList={setBlocks} {...sortableOptions}>
                        {blocks.map((block: any, blockIndex: number) => {
                            return (
                                <div key={blockIndex} className="dnd-item">
                                    <StyledBlockWrapper className="block">
                                        <SelectWebformField block={block} />
                                    </StyledBlockWrapper>
                                    <div className="dnd-actions drag-buttons">
                                        <Button onClick={() => { editItem(blockIndex,block) }}>
                                            Edit
                                        </Button>
                                        <Button onClick={() => { deleteItem(blockIndex) }}>
                                            Delete
                                        </Button>
                                    </div>
                                </div>
                            )
                        })}
                    </ReactSortable>
                </div>
            </Box>
        </>
    )
}

export default WebFieldDropZone