const initialState = {
  AllLocation: [],
}
const LocationsReducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case 'GET_LOCATIONS':
          return {
              ...state,
              AllLocation: action.payload,
          };
      default:
          return state;
  }
}

export default LocationsReducer;
