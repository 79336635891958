import { addDays, subMonths, subYears } from "date-fns";

export const initialStateRange = {
    feedbackList: [],
    rangeval: {
      startDate: subYears(new Date(), 1),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  };
  
  const FeedbackReducer = (
    state = initialStateRange,
    action: { type: any; payload: any }) => {
    switch (action.type) {
      case "STORE_ALL_FEEDBACK":
        return {
          ...state,
          feedbackList: action.payload,
        };
      case "FEED_RANGE_VALUE":
        return {
          ...state,
          rangeval: action.payload,
        };
      case "RESET_FILTER_SURVEY":
        return {
          ...state,
          rangeval: initialStateRange.rangeval,
        };
      default:
        return state;
    }
  };
  
  export default FeedbackReducer;