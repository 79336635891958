import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend, Tooltip);

const footer = (tooltipItems: any) => {
  let sum = 0;

  tooltipItems.forEach(function(tooltipItem: any) {
    sum += tooltipItem.parsed.y;
  });
  return 'Total: ' + sum;
};


const HorizontalChartActual = ({
  id='myReportBar',
  label,
  dataSets,
  callback,
  isDarkMode,
}: {
  label: any;
  dataSets: any;
  callback?: any;
  id?: any
  isDarkMode?: any
}) => {
  const data = {
    labels: label,
    datasets: dataSets,
  };
  const onchange = () =>{
    console.log(callback)
    if (callback) callback()
  }
  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          color: !isDarkMode ? "black":"white",
          font: {
            size: 10, // Adjust the font size here
          },
          padding: 2, // Adjust the padding
          // boxWidth: 12, // Adjust the width of the colored box
          boxheight: 1, // Adjust the width of the colored box
        },
      },
  
      tooltip: {
        usePointStyle: true,
        callbacks: {
          footer: footer,
        },
        filter: function (tooltipItem: any) {
          var value = tooltipItem.dataset.data[tooltipItem.dataIndex];
          return true;
        }
      },
      title: {
        display: false,
        text: "Chart.js B",
      },
      datalabels: {
        display: false,
        align: "center",
        anchor: "center",
        formatter: function (value: any) {
          value = value.toString();
          value = value.split(/(?=(?:...)*$)/);
          value = value.join(",");
          return value;
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          callback: (xValue: any) => Math.floor(xValue),
          color: !isDarkMode ? "black":"white",
          font: {
            size: 8, // Adjust the font size for x-axis ticks
          },
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: !isDarkMode ? "black":"white",
          font: {
            size: 8, // Adjust the font size for y-axis ticks
          },
        },
      }
    },
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    indexAxis: 'y', // Set this to 'y' to make the bars horizontal
  };
  React.useEffect(()=>{
    setTimeout(()=>{
      onchange()
    },100)
  },[label])
  return <Bar height={400} id={id} data={data} options={options} onChange={onchange}
  />;
};
export default HorizontalChartActual;
