const initialState = {
    Data: [],
    showLoading: false,
  };

const checkUserNameReducer = (state = initialState, action: any)=>{
    switch (action.type) {
        case "checkUserName":
          return {
            ...state,
            Data: action.payload,
          };
        default:
          return state;
      }
}  

export default checkUserNameReducer