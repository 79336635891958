import { useEffect, useMemo, useState } from "react";
import AgentRoleAccrodianItem from "./AgentRoleAccrodianItem";
import { IState } from "../../../../redux/reducers/rootReducers";
import { connect, useDispatch } from "react-redux";
import { selectAllAgentPermissions } from "../../../../redux/actions/userManagement/role";
const getInitialCount = (obj:any={})=>{
    const keys = Object.keys(obj);
    let checkedCount = 0;
    keys.map((i)=>{
        checkedCount += obj[i]?.length;
    })
    return checkedCount
}
const PrioritiesBox = (props:any) =>{
    const {priorities=[],checkedItems={}} = props;
    const dispatch = useDispatch();
    const [priorityList, setPriorityList] = useState<any>([]);
    const [keysLength, setKeysLength] = useState<any>(getInitialCount(checkedItems));
    const [firstLoad, setfirstLoad] = useState<any>(true);

    useEffect(()=>{
        const keys = Object.keys(checkedItems);
        let checkedCount = 0;
        keys.map((i)=>{
            checkedCount += checkedItems[i]?.length;
        })
        console.log('checkedCount',keysLength,checkedCount)
        if(keysLength === checkedCount) return;
        setKeysLength(checkedCount)
    },[checkedItems])
    useEffect(()=>{
        console.log("PrioritiesBoxPrioritiesBox",checkedItems,priorities)
        if(!priorities) return;
        if(!checkedItems){
           // setPriorityList(priorities)
            return;
        }
        const filteredArr = priorities.filter((i:any)=>{
            let checked = false;
            console.log('checkedItems09876',checkedItems,i)
            if(i?.ticketTypeIds?.map){
                i?.ticketTypeIds.map((j:any)=>{
                    if(checkedItems?.[`${j}`]?.map && checkedItems?.[`${j}`]?.length > 0){
                        checked = true; 
                    }
                })
            }
            return checked;
        })
        console.log("filteredArr",filteredArr)
        setPriorityList(filteredArr)
        if(firstLoad){
            setfirstLoad(false);
            return;
        }
        dispatch(selectAllAgentPermissions(
            {"Priorities":{}}, "Priorities"
        ))
    },[keysLength]);
    useEffect(()=>{
        console.log('priorityList?.length',priorityList?.length)
    },[priorityList])
    const layout = useMemo(()=><>
        {priorityList?.length>0 &&
            <AgentRoleAccrodianItem  info={priorityList} keyLabel={'Priorities'} />
        }
    </>,[priorityList?.length])
    return(<>
        {layout}
    </>)
}
const mapStateToProps = (state:IState) => {
    return ({
        checkedItems : state?.roleReducer?.agentCheckBoxStatus?.TicketTypes
        //?.TicketTypes
    })
};
export default connect(mapStateToProps)(PrioritiesBox);
//AgentCheckBoxList;//
// export default PrioritiesBox;