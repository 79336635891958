
export enum ActionTypeLoader {
    LOADER = 'LOADER'
}

export interface ILoader {
    open: boolean
}

export interface ILoaderType {
    type: ActionTypeLoader.LOADER,
    payload: ILoader,
}

export const Loader = (loader: ILoader) => {
    return {
        type: ActionTypeLoader.LOADER,
        payload: loader
    };
}