import { Box, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React from 'react'

function MobileLoader() {
    const classes = useStyles();
  return (
    <Box className={classes.loaderBox}>
            <CircularProgress />
    </Box>
  )
}
const useStyles: any = makeStyles(() => ({
    loaderBox: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "10px",
      padding: "15px",
      "& span": {
        width: "20px !important",
        height: "20px  !important",
      },
    },
  }));
export default MobileLoader
