import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../redux/reducers/rootReducers";
import { useEffect, useState } from "react";
import { ActionType } from "../../redux/actions/Snackbars";

export default function CustomizedSnackbars() {
  const snackbarState = useSelector((state: IState) => state.snackbar.snackbar);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(snackbarState.open);
  const [snackbar, setSnackbar] = useState(snackbarState);

  useEffect(() => {
    console.log("snackbar", JSON.parse(JSON.stringify(snackbarState)).open);
    if (JSON.parse(JSON.stringify(snackbarState)).open) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [snackbarState]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    // if (reason === 'clickaway') {
    dispatch({
      type: ActionType.SNACKBAR,
      payload: {
        ...snackbarState,
        open: false,
        message : "",
      },
    });
    // }
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" , }}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
        className="Alert-popup"
      >
        <Alert variant="filled" severity={JSON.parse(JSON.stringify(snackbarState)).severity}>
          {JSON.parse(JSON.stringify(snackbarState)).message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
