import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomFormLabel from '../common/form-label/CustomFormLabel '
import AsyncDropDown from '../../pages/new-ticket/LatestTicket/AsyncDrodown/AsyncDropDown'
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { getOrganisationsForDropdown } from '../../redux/actions/CMDB/Organisation';
import { getContactsForDropdown } from '../../redux/actions/ContactManagement/Contact';
interface IOrgDropDownProps {
    callback : (val:any)=>void;
    defaultValue ?: null| any;
}
interface IContactDropDownProps {
    callback : (val:any)=>void;
    defaultValue ?: null| any;
}
function OrgDropDown({callback,defaultValue=null}:IOrgDropDownProps) {
    const dispatch = useDispatch();
    const [contactOrgVal,setContactOrgVal] = useState(defaultValue);
    const promiseOptionsForOrg = debounce((inputValue: any, callback: any) => {
        dispatch(getOrganisationsForDropdown(inputValue,callback,true))
    },300);
    useEffect(()=>{
        if(callback) callback(contactOrgVal);
    },[contactOrgVal])
    return (
        <Box>
        <CustomFormLabel classNames='sml-txt' labelName={"Choose Organisation"} isMandotary={true} />
                    <AsyncDropDown
                        promiseOptions={promiseOptionsForOrg}
                        name="contactOrganisationId"
                        placeHolder="Search and select a Organisation"
                        handleChange={(e: any) => {
                            // resetErrors("contactOrganisationId")
                            setContactOrgVal(e)
                        }}
                        isError={false}
                        defaultValue={contactOrgVal}
                        customClassNames = "sml-txt-dropdown"
                    />
        </Box>
    )
}


export function ContactDropDownLazy({callback,defaultValue=null}:IContactDropDownProps) {
    const dispatch = useDispatch();
    const [contactContactVal,setContactContactVal] = useState(defaultValue);
    const promiseOptionsForContact = debounce((inputValue: any, callback: any) => {
        dispatch(getContactsForDropdown(inputValue,callback))
    },300);
    useEffect(()=>{
        if(callback) callback(contactContactVal);
    },[contactContactVal])
    return (
        <Box>
        <CustomFormLabel classNames='sml-txt' labelName={"Choose Contact"} isMandotary={true} />
                    <AsyncDropDown
                        promiseOptions={promiseOptionsForContact}
                        name="contactId"
                        placeHolder="Search and select a Contact"
                        handleChange={(e: any) => {
                            // resetErrors("contactContactanisationId")
                            setContactContactVal(e)
                        }}
                        isError={false}
                        defaultValue={contactContactVal}
                        customClassNames = "sml-txt-dropdown"
                    />
        </Box>
    )
}

export default OrgDropDown
