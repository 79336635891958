const initialState = {
    searchVal: "",
    contacts: [],
    statuses: [],
    contactTickets:[]
  }
  const FiltersSSPReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case 'SEARCH_VALUE':
            return {
                ...state,
                searchVal: action.payload,
            };
        case 'CONTACT_VALUE':
            return {
                ...state,
                contacts: action.payload,
            };
        case 'CONTACT_SSP_TICKETS':
            return {
                ...state,
                contactTickets: action.payload,
            };
        case 'STATUS_VALUE':
            return {
                ...state,
                statuses: action.payload,
            };
        default:
            return state;
    }
  }
  
  export default FiltersSSPReducer;
  