import {
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import intl from "react-intl-universal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IState } from "../../../redux/reducers/rootReducers";
import deleteIcon from "../../../assest/icons/delete.svg";
import editIcon from "../../../assest/icons/edit.svg";
import restoreIcon from "../../../assest/icons/restore.svg";
import AddNewPart from "../../common/AddNew";
import CommonStyles from "../../common/CommonStyles";
import Card from "../../common/card";
import Breadcrumbs from "../../common2/Breadcrumbs";
import Model from "../../common2/Model";
import TopFilter from "../../common2/TopFilter";
// import getEmailException, { hardDeleteMailException, multipalUpdateEmailException, softDeleteMailException } from "../../redux/actions/adminInterfaces/EmailException";

function MsNav() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state: IState) => state?.MailExceptionListReducer?.AllMailExceptionList);
  const unAssignedData = useSelector((state: IState) => state?.MailExceptionListReducer?.unAssignedData);
  const classes = CommonStyles();
  const [seachData, setSearchData] = useState("");
  const [group, setGroup] = useState([]);
  const [newOptions, setNewOptions] = useState([]);
  const [permissionInfo, setpermissionInfo] = useState({} as any);
  const rolesAccess = useSelector(
    (state: IState) => state?.LoginReducer?.roleAccess
  );
  const [checked, setChecked] = React.useState(false);
  const [update, setUpdate] = useState(false);
  const [active, setActive] = useState([]);
  const [records, setrecords] = useState([]);
  const [exception, setException] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [getInfo, setGetInfo] = useState([]);


  useEffect(() => {
    if (data?.hasOwnProperty("name")) {
      setException([]);
    } else {
      setException(data);
    }

  }, [data]);

  useEffect(() => {

    let tempOption: any = [];
    if (unAssignedData?.data?.length >= 0) {
      unAssignedData?.data?.map((item: any) => {
        tempOption.push({ label: item?.name, value: item?.name, id: item?.id });
      });
      console.log("check new imple=>", tempOption);
      setNewOptions(tempOption)
    }
  }, [unAssignedData])
  
  const handleSearch = (e: any) => {
    let lowerCase = e.target.value;

    setSearchData(lowerCase);
  };
  const filterHandler = (e: any) => {};
  const checkBoxHandler = (e: any, id: any) => {
    setChecked(e.target.checked);
  };
  const showDataHandler = (show: any) => {
    const newItems = exception?.filter((val: any) => val.isActive === show);
    setActive(show);
    if (show) {
      setUpdate(false);
    }
    if (show === false) {
      setUpdate(true);
    }
    // console.log("records",records);
  };

  useEffect(() => {
    const val = true;
    showDataHandler(val);
  }, [exception]);

  const updateAllHandler = (value: any, id: any) => {
    //confusing about operation happening
    // let arrayids: any[] = [];
    // data.forEach((d: any) => {
    //   if (d.isActive) {
    //     arrayids.push(d.id);
    //   }
    //   if (d.isActive === false) {
    //     arrayids.push(d.id);
    //   }
    // });
    // dispatch(multipalUpdateEmailException({ ...value }, arrayids, update));
  };
  // const bulkDeleteHandler = (value: any, id: any) => {
  //   let arrayids: any[] = [];
  //   MailExceptionList.forEach((d: any) => {
  //     if (d.isActive) {
  //       arrayids.push(d.id);
  //     }
  //   });
  //   dispatch(bulkDeleteMailException(arrayids));
  // };
  const deleteHandler = (val: string) => {
    // console.log("active>>>>>", active);
    // const id = parseInt(val);
    // console.log("delete id", id);
    // dispatch(softDeleteMailException(id, !active));
  };
  const activeHandler = (type: any, value: any, id: any) => {
    // dispatch(multipalUpdateEmailException({ ...value }, id, true));
  };

  useEffect(() => {
    console.log("pop", data);
    setrecords(data);
  }, [data]);

  useEffect(() => {
    console.log("records", records);
  }, [records]);

  // useEffect(() => {
  //   dispatch(getEmailException());
  // }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      const re = new RegExp(seachData, "i");
      const filtered = data.filter((entry: any) =>
        Object.values(entry).some(
          (val) => typeof val === "string" && val.match(re)
        )
      );
      setrecords(filtered);
    }
  }, [seachData, active]);
  
  const EditHandler = (id: string) => {
    navigate(`/Add-MailExceptionList/${id}`);
  };

  const softDelete = (id: number) => {};

  const hardDelete = (key: number) => {
    // const deleteItem: any = records[key];
    // setrecords(records.filter((x: any) => x.id !== key));
    // dispatch(hardDeleteMailException(deleteItem.id));
  };
  const onSubmitHandler = () => {
    // if (getInfo[0] === `${intl.get("In-Active")}`) {
    //   dispatch(softDeleteMailException(getInfo[1],getInfo[2]));
    // }
    // if (getInfo[0] === `${intl.get("Delete")}`) {
    //   dispatch(hardDeleteMailException(getInfo[1]));
    // }
    // setIsOpen(false);
  };
  useEffect(() => {
    if (rolesAccess) {
      console.log("rolesAccess.EmailTemplates", rolesAccess.MailExceptionList);
      setpermissionInfo({
        ...rolesAccess.MailExceptionList,
        isSuperAdmin: rolesAccess.isSuperAdmin,
      });
    }
  }, [rolesAccess]);
  const checkPermission = (valueKey: string) => {
    let val: boolean = permissionInfo[valueKey] ? true : false;
    return val;
  };
  return (
    <>
      <Breadcrumbs
        data={[
          {
            title: "System Management ",
            path: "/adminPanel",
          },
          {
            title: "MS Nav",
            path: "/MSnav",
          },
        ]}
      />
      <Box className="pageHolder">
        <TopFilter
          path="/AddMSnav"
          Data={group}
          SearchHandler={handleSearch}
          value={seachData}
          filterHandler={filterHandler}
          checkBoxHandler={checkBoxHandler}
          checked={checked}
          hideSelectAllOption={active?.length > 0 ? false : true}
          hideActiveBtns={false}
          filterItems={showDataHandler}
          updateAllHandler={updateAllHandler}
          // bulkDeleteHandler={bulkDeleteHandler}
          titleState={update}
          temp={active}
          placeHolder="Search ..."
          hideAddBtn={
            checkPermission("Create") || checkPermission("isSuperAdmin")
              ? false
              : true
          }
        />
        <Model
          open={isOpen}
          label={`Proceed for ${getInfo[0]}`}
          onSubmitHandler={onSubmitHandler}
          handleClose={() => setIsOpen(false)}
          btn={`${intl.get("Update")}`}
        />

        <Grid container className="pt-1" spacing={1}>
          {records.length > 0 ? (
            <>
              {records.map((item: any, key: number) => {
                const { id, name, subject, isActive } = item;
                const type = [
                  `${intl.get("Delete")}`,
                  `${intl.get("Active")}`,
                  `${intl.get("In-Active")}`,
                ];
                return (
                  <>
                    {active === isActive && (
                      <Grid item md={3} xs={12} key={key}>
                        <Card
                          styleProp={classes.cardStyle}
                          customHeader={
                            <Box
                              className={`${classes.row} ${classes.alignItemsCenter} ${classes.justifyBetween} ${classes.w100}`}
                            >
                              <Typography
                                variant="body2"
                                className={classes.title}
                              >
                                {name}
                              </Typography>
                            </Box>
                          }
                          cardBody={
                            <Box>
                              <Typography variant="body2">
                                <b>Subject :</b>
                              </Typography>
                              <Typography variant="body1">{subject}</Typography>
                            </Box>
                          }
                          cardFooter={
                            <Box>
                              {permissionInfo &&
                                (checkPermission("ReActivate") ||
                                  checkPermission("isSuperAdmin")) &&
                                !isActive && (
                                  <Tooltip title={`${intl.get("Active")}`}>
                                    <IconButton
                                      size="small"
                                      onClick={(e: any) =>
                                        activeHandler(type, item, item.id)
                                      }
                                    >
                                      <img src={restoreIcon} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              {permissionInfo &&
                                (checkPermission("Edit") ||
                                  checkPermission("isSuperAdmin")) &&
                                isActive && (
                                  <Tooltip title={`${intl.get("Edit")}`}>
                                    <IconButton
                                      disabled={isActive ? false : true}
                                      size="small"
                                      onClick={() => {
                                        EditHandler(id);
                                      }}
                                    >
                                      <img src={editIcon} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              {permissionInfo &&
                                (checkPermission("DeActivate") ||
                                  checkPermission("isSuperAdmin")) &&
                                isActive && (
                                  <Tooltip title={`${intl.get("In-Active")}`}>
                                    <IconButton
                                      disabled={isActive ? false : true}
                                      size="small"
                                      className="cardIconBtn"
                                      onClick={() => {
                                        deleteHandler(id);
                                      }}
                                    >
                                      <img src={deleteIcon} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                            </Box>
                          }
                        />
                      </Grid>
                    )}
                  </>
                );
              })}
            </>
          ) : (
            <>
              <AddNewPart title="Add New" path="/AddMSnav" />
            </>
          )}
        </Grid>
      </Box>
    </>
  );
}
export default MsNav;
