const initialState = {
  data:[],
}
const WebformReducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
      case 'STORE_WEBFORM':
          return {
              ...state,
              data: action.payload,
          };
      default:
          return state;
  }
}

export default WebformReducer;
