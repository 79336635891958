import { Box, Checkbox, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import intl from "react-intl-universal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import deleteIcon from "../../assest/icons/delete.svg";
import editIcon from "../../assest/icons/edit.svg";
import restoreIcon from "../../assest/icons/restore.svg";
import getWebForm, { WebFormStatusChange, softDeleteWebForm } from "../../redux/actions/WebForms/webForms";
import { IState } from "../../redux/reducers/rootReducers";
import AddNewPart from "../common/AddNew";
import CommonStyles from "../common/CommonStyles";
import Card from "../common/card";
import Breadcrumbs from "../common2/Breadcrumbs";
import TopFilter from "../common2/TopFilter";

export default function WebFormList() {

    const classes = CommonStyles()
    const dispatch = useDispatch()
    const navigate=useNavigate()
    const [data, setData] = useState<any>([]);
    const [records, setRecords] = useState([])
    const [filterArr, setfilterArr] = useState([]);
    const [searchData, setSearchData] = useState('');
    const [listStatus, setlistStatus] = useState(true);
    const [activeWebForm, setAtiveWebForm] = useState([])
    const WebFormList = useSelector((state: IState) => state?.WebformReducer?.data);
    const [checked, setChecked] = useState(false);
    const [update, setUpdate] = useState(false);
    const rolesAccess = useSelector((state: IState) => state?.LoginReducer?.roleAccess);
    const [permissionInfo, setpermissionInfo] = useState({} as any);
    const checkPermission: any = (valueKey: string) => {
        let val: boolean = permissionInfo[valueKey] ? true : false
        return val;
    }
    useEffect(() => {
        if (rolesAccess) {
            console.log('rolesAccess.OrganisationAzureActiveDirectiory', rolesAccess.OrganisationAzureActiveDirectiory)
            setpermissionInfo({ ...rolesAccess.OrganisationAzureActiveDirectiory, isSuperAdmin: rolesAccess.isSuperAdmin })
        }
    }, [rolesAccess]);
    const filterHandler = (e: any) => { };
    const checkBoxHandler = (e: any, id: any) => {
        setChecked(e.target.checked);
    };
    const handleSearch = (e: any) => {
        let lowerCase = e.target.value;
        setSearchData(lowerCase);
    };
    const showDataHandler = (show: any) => {
        setlistStatus(show);
        if (show) {
            setUpdate(false);
        }
        if (show === false) {
            setUpdate(true);
        }

    };
    useEffect(() => {
        dispatch(getWebForm("Ticket",getWebFormCb))
    }, [])
    const getWebFormCb = (data: any) => {
        console.log('data---', data)
        setData(data.map((i: any) => {
            return i;
        }));
        setRecords(data)
    }
    const updateAllHandler = (value: any, id: any) => { //confusing about operation happening
        let arrayids: any[] = [];
        data.forEach((d: any) => {
            if (d.isActive) {
                arrayids.push(d.id);
            }
            if (d.isActive === false) {
                arrayids.push(d.id);
            }
        });
        dispatch(WebFormStatusChange({ ...value }, arrayids, update,"Ticket"));
    };
    const deleteHandler = (val: string) => {
        console.log("listStatus>>>>>", listStatus);
        const id = parseInt(val);
        console.log("delete id", id)
        dispatch(softDeleteWebForm(id, !listStatus,"Ticket"));
    };
    const EditHandler = (val: string) => {
        console.log("editId", val);
        const editVal = records.filter((x: any) => x.id === val);
        navigate(`/webForms/${val}`);
    };
    const activeHandler = (type: any, value: any, id: any) => {
        dispatch(WebFormStatusChange({ ...value }, id, true,"Ticket"));
    };
    useEffect(() => {
        if (data) {
            toggleActiveList(listStatus);
        }
        if (data && data.length > 0) {
            let activeWebForm = data.filter((x: any) => x.isActive);
            setAtiveWebForm(activeWebForm.length);
            console.log("activeWebForm", activeWebForm)
        }
    }, [data]);
    const toggleActiveList = (types: boolean) => {
        const filtered = WebFormList.filter((a: any) => a.isActive === types);
        setRecords(filtered);
    };
    useEffect(() => {
        if (WebFormList && WebFormList.length > 0) {
            const re = new RegExp(searchData, "i");
            const filtered = WebFormList.filter(
                (entry: any) =>
                    entry.isActive === listStatus &&
                    Object.values(entry).some(
                        (val) => typeof val === "string" && val.match(re)
                    )
            );
            console.log("filtered>>>>", filtered, data, !listStatus);
            setRecords(filtered);
        }
    }, [searchData, listStatus]);
    return (
        <>
            <Breadcrumbs
                data={[
                    {
                        title:'System Management ',
                        path:'/adminPanel' 
                    },
                    {
                        title: "Web-Form",
                        path: "/webFormList",
                    },
                ]}
            />
            <Box className="pageHolder">
                <TopFilter
                    path="/webForms"
                    Data={WebFormList}
                    SearchHandler={handleSearch}
                    value={searchData}
                    filterHandler={filterHandler}
                    checkBoxHandler={checkBoxHandler}
                    checked={false}
                    hideSelectAllOption={true}
                    filterItems={showDataHandler}
                    updateAllHandler={updateAllHandler}
                    //bulkDeleteHandler={bulkDeleteHandler}
                    titleState={false}
                    temp={activeWebForm}
                    placeHolder="Search ..."
                    hideAddBtn={false}
                />
                <Grid container className="pt-1" spacing={1}>
                    {records && records.length > 0 ? (
                        <>
                            {records.map((item: any, key: number) => {
                                const { id, clientId, tenantId, name, isActive,type } = item;
                                const types = [
                                    `${intl.get("Delete")}`,
                                    `${intl.get("Active")}`,
                                    `${intl.get("In-Active")}`,
                                ];
                                if (type === "Ticket") {
                                    return (
                                        <React.Fragment key={key}>
                                            {listStatus === isActive &&
                                                <Grid item md={3} xs={12} key={key}>
                                                    <Card
                                                        styleProp={classes.cardStyle}
                                                        cardHeaderTitle1={
                                                            <Typography variant="body2" className={classes.title}>
                                                                {name}
                                                            </Typography>
                                                        }
                                                        cardHeaderTitle2={
                                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                                {checked ? (
                                                                    <Checkbox
                                                                        size="small"
                                                                        color="primary"
                                                                        checked={checked}
                                                                    />
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Box>
                                                        }
                                                        // cardBody={
                                                        //     <Box className="listed-cards">
                                                        //         <Typography variant="body1">
                                                        //             {/* tenant Id : {tenantId} */}
                                                        //         </Typography>
                                                        //         <Typography variant="body1">
                                                        //             {/* Client Id : {clientId} */}
                                                        //         </Typography>
                                                        //     </Box>
                                                        // }
                                                        cardFooter={
                                                            <Box >
                                                                {permissionInfo && (checkPermission('ReActivate') || checkPermission('isSuperAdmin')) && !isActive && (
                                                                    <Tooltip title={`${intl.get("Active")}`}>
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={(e: any) =>
                                                                                activeHandler(types, item, item.id)
                                                                            }
                                                                        >
                                                                            <img src={restoreIcon} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )}
                                                                {permissionInfo && (checkPermission('Edit') || checkPermission('isSuperAdmin')) && isActive && (
                                                                    <Tooltip title={`${intl.get("Edit")}`}>
                                                                        <IconButton
                                                                            disabled={isActive ? false : true}
                                                                            size="small"
                                                                            onClick={() => {
                                                                                EditHandler(id);
                                                                            }}
                                                                        >
                                                                            <img src={editIcon} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )
                                                                }
                                                                {permissionInfo && (checkPermission('DeActivate') || checkPermission('isSuperAdmin')) && isActive && (
                                                                    <Tooltip title={`${intl.get("In-Active")}`}>
                                                                        <IconButton
                                                                            disabled={isActive ? false : true}
                                                                            size="small"
                                                                            className="cardIconBtn"
                                                                            onClick={() => {
                                                                                deleteHandler(id);
                                                                            }}
                                                                        >
                                                                            <img src={deleteIcon} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )}
                                                            </Box>
                                                        }
                                                    />
                                                </Grid>
                                            }
                                        </React.Fragment>
                                    )
                                }
                            })
                            }
                        </>
                    ) :
                        (
                            <>
                                <AddNewPart title="Add New" path="/webForms" />
                            </>
                        )
                    }
                </Grid>
            </Box>

        </>
    )
}