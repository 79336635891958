import { Checkbox,FormControl,FormControlLabel,FormGroup,FormLabel,Grid, Radio, RadioGroup, Tab, TextareaAutosize } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import addRole, {
  allApiCallsForRole,
  singleRole,
  updateRole,
  alertSnackbar
} from "../../../redux/actions/userManagement/role";
import { IState } from "../../../redux/reducers/rootReducers";
import InputBox from "../../common2/InputBox";
import AppLoader from "../../common/AppLoader";
import CommonStyles from "../../common/CommonStyles";
import SystemAdmin from "./SystemAdmin";
import UserAdmin from "./UserAdmin";
import sysData, {formEditFlowData} from "./formSysAdminData";
import ApiRequest from "./generateApiRequest";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import agentDataFormat, {formEditFlowAgentData, formEditFlowSSPAgentData} from "./formAgentData";
import Breadcrumbs from "../../common2/Breadcrumbs";
import sspDataFormat from "./formSSPData";
import SSPAdminPermissions from "./SSPAdminPermissions";
function AddRole1() {
  let firstLoad = true;
  const classes = CommonStyles();
  const dispatch = useDispatch();
  const { id }: any = useParams();
  const navigate = useNavigate();
  const [UserAccessData, setUserAccessData] = useState<any>({});
  const [editPriorityData, seteditPriorityData] = useState<any>([]);
  const [editCategoryData, seteditCategoryData] = useState<any>([]);
  const [editAidData, seteditAidData] = useState<any>([]);
  const [editSSPCategoryData, seteditSSPCategoryData] = useState<any>([]);
  const [loader, setloader] = useState(false);
  const [SSPAccessData, setSSPAccessData] = useState({} as any);
  const [AccessData, setAccessData] = useState({} as any);
  const [AgentData, setAgentData] = useState<any>(
    {
      AllAgentData : {},
      priorityData : [],
      categoryData : []
    }
  );
  const [SspUserData, setSspUserData] = useState<any>(
    {
      AllAgentData : {},
      priorityData : [],
      categoryData : []
    }
  );
  const [isUpdate, setisUpdate] = useState<boolean>(false);
  const [UserType, setUserType] = useState({
    superAdmin:true,
    systemAdmin:false,
    Agent:false,
    User:false,
  });
  const [Data, setData] = useState({
    "id": 0,
    "name": "",
    "description": "",
    "isActive": true,
    "isSuperAdmin": false,
    "agentPermissionsList": []
  });
  const [labelChanges, setlabelChanges] = useState({
    "OrganisationAzureActiveDirectiory": 'Active Directory',
    "TicketPermissions": "",
    "MailService": "Email Settings",
    "APITicketManagement": 'Ticket Management',
    "APISystemManagement": 'System Management',
    "APIFilesManagement": 'Files Management',
  } as any);
  const [configFields, setconfigFields] = useState({
    "name": false,
    "description": false,
  } as any);
  const systemType = useSelector(
    (state: IState) => state.systemTypeReducer?.Data
  );
  const TicketTypes = useSelector((state: IState) => state?.ticketReducer?.Data?.data);
  const group = useSelector((state: IState) => state?.groupReducer?.Data?.data);
  const category = useSelector(
    (state: IState) => state?.categoryReducer?.Data?.data
  );
  const emailSettings =  useSelector(
    (state: IState) => state?.EmailSettingsReducer?.AllEmailSettings
  );
  const priority = useSelector(
    (state: IState) => state?.priorityReducer?.Data?.data
  );
  const permission = useSelector(
    (state: IState) => state?.permissionReducer?.Data?.data
  );
  const [TabValue, setTabValue] = useState('1');
  const stopPageloader = () =>{
    setloader(false);
  }
  const getUpdateValues = (resStatus:string, response:any,TicketTypes=[],group=[],category=[],priority=[],permission=[],emailSettings=[],systemType=[],ticketStatusList=[],contactServices=[])=>{
    console.log('resallData',TicketTypes,group,category,priority,permission,emailSettings,systemType,ticketStatusList)
    if(resStatus === '0'){
      try{
        stopPageloader();
        // updating the values on edit
        const formSysData = {}//sysData(systemType,TicketTypes,group,category,priority,permission);
        const editedData = formEditFlowData(formSysData,response);
        setAccessData(editedData.data);
        console.log('single role data',response)
        const formAgentData = agentDataFormat(TicketTypes,group,category,priority,permission,emailSettings,ticketStatusList);
        const editedAgentData = formEditFlowAgentData(formAgentData,response,TicketTypes,priority,category,ticketStatusList);
        const sspUserData = sspDataFormat(TicketTypes,category,contactServices,permission);
        const editedSSPAgentData = formEditFlowSSPAgentData(sspUserData,response,TicketTypes);
        console.log('editedAgentData',editedSSPAgentData)
        seteditPriorityData(editedAgentData.priorityList);
        seteditCategoryData(editedAgentData.categoryList);
        
        setUserAccessData(editedAgentData.data);
        setData({
          ...Data,
           description : response.description,
           id : response.id,
           name : response.name,
        })
        seteditSSPCategoryData(editedSSPAgentData.categoryList);
        seteditAidData(editedSSPAgentData.aidList);
        setSSPAccessData(editedSSPAgentData.data);
        console.log('outputRes',{
          ...UserType,
          superAdmin:response.isSuperAdmin,
          systemAdmin:editedData.isSysAdmin,
          Agent : editedAgentData.isAgent,
          User : editedSSPAgentData.isSSPAgent
      })
        setUserType({
            ...UserType,
            superAdmin:response.isSuperAdmin,
            systemAdmin:editedData.isSysAdmin,
            Agent : editedAgentData.isAgent,
            User : editedSSPAgentData.isSSPAgent
        });
        if(editedSSPAgentData.isSSPAgent){
          setTabValue('3')
        }else if(editedData.isSysAdmin){
          setTabValue('1')
        }else if(editedAgentData.isAgent){
          setTabValue('2')
        }
      }catch(e){ stopPageloader(); console.log('roleIssue',e)}
    }
  }
  const apiCallback = (TicketTypes=[],group=[],category=[],priority=[],permission=[],emailSettings=[],systemType=[],ticketStatusList=[],contactServices=[]) =>{
      console.log("callbcontactServices=[]",contactServices)
      if(id){
        setloader(true);
        setisUpdate(true);
        setTimeout(()=>{
          dispatch(singleRole(id,getUpdateValues,TicketTypes,group,category,priority,permission,emailSettings,systemType,ticketStatusList,contactServices))
        },200)
      }else{
        console.log('systemType=>>.',systemType)
        const formSysData = {};//sysData(systemType,TicketTypes,group,category,priority,permission);
        const formAgentData = agentDataFormat(TicketTypes,group,category,priority,permission,emailSettings,ticketStatusList);
        const sspUserData = sspDataFormat(TicketTypes,category,contactServices,permission);//TicketTypes:any,category:any,SSPservices:any,permission:any
        setUserAccessData(formAgentData)
        setAccessData(formSysData);
        setSSPAccessData(sspUserData);
        stopPageloader();
      }
  }
  useEffect(() => {
    if(firstLoad){
      console.log('firstLoad')
      firstLoad = false;
      setloader(true);
      dispatch(allApiCallsForRole(apiCallback))
    }
    // dispatch(getPermission());
    // dispatch(getTicket());
    // dispatch(getCategory());
    // dispatch(getGroup());
    // dispatch(getSystemType());
    // dispatch(getPriority());
    // dispatch(getEmailSettings());
  }, []);
  /* Super admin Access=======*/
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const checked = event.target.checked
    const tabIndex = event.target.tabIndex
    setUserType({
      ...UserType,
      [name]: checked
    });
    if(checked) setTabValue(`${tabIndex}`);
  };
  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const checked = event.target.checked
    const dummyInfo:any = {systemAdmin:false,Agent:false,User:false}
    setUserType({
      ...dummyInfo,
      [name]: checked
    });
  };
  /*ENDS Super admin Access=======*/
  const InputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    const name = event.target.name
    setData({
      ...Data,
      [name]:val
    })
    if(name in configFields && configFields[name] && val.trim() !== ''){
        setconfigFields({
          ...configFields,
          [name] : false
        })
    }
  };
  const postUpdate = (resSts:string) => {
    setloader(false);
    if(resSts === '0'){
      //navigate(-1);
      navigate(`/Roles`);
    }
  };
  const validate = () => {
    let valid = true;
    let conFields = {...configFields};
    const fields:any = Object.keys(configFields);
    const allData:any = Data;
    for (const field of fields) {
      if(allData[field].trim() === ''){
        valid = false;
        console.log(field);
        conFields = {
          ...conFields,
          [field] : true
        }
      }
    }
    setconfigFields(conFields)
    return valid;
  }
  const submitHandler = () =>{
    if(!validate()) return;
    console.log('systemType',systemType)
    //return;
    setloader(true);
    const reqFormat = ApiRequest(permission,systemType,UserType,Data,AccessData,AgentData,SspUserData);//SspUserData - can be used
    console.log('reqFormat',reqFormat);
    return
    if(!reqFormat.isSuperAdmin && reqFormat?.agentPermissionsList && reqFormat.agentPermissionsList.length === 0){
      setloader(false);
      dispatch(alertSnackbar('Please Choose permissions to Proceed!','warning'));
      return;
    }
    if(reqFormat.agentPermissionsList[0]?.isContact){
      reqFormat.isSSPRole = true
    }else{
      reqFormat.isSSPRole = false
    }
    if(!isUpdate){
      dispatch(addRole(reqFormat,postUpdate));
    }else {
      dispatch(updateRole(reqFormat,reqFormat.id,postUpdate))
    }

  }
  const backHandler = () =>{
    navigate(`/Roles`);
  }
  const usrAdminCallback = useCallback((AllData:any,priorityLists:any,categoryLists:any) =>{
      // console.log('AllData',AllData);
      // console.log('priorityLists',priorityLists);
      // console.log('categoryLists',categoryLists);
      setAgentData({
        AllAgentData : AllData,
        priorityData : priorityLists,
        categoryData : categoryLists
      })
  },[]);
  const SSPusrCallback = useCallback((AllData:any,priorityLists:any,categoryLists:any) =>{
    // console.log('AllData',AllData);
    // console.log('priorityLists',priorityLists);
    // console.log('categoryLists',categoryLists);
    setSspUserData({
      AllAgentData : AllData,
      priorityData : priorityLists,
      categoryData : categoryLists
    })
  },[]);
  const EditPermissionsCB = useCallback((modifiedData:any) =>{
    setAccessData(modifiedData);
  },[]);
  const TabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  return (
    <Box >
      <Breadcrumbs
        data={[
          {
             title:'UserManagement ',
             path:'/UserManagement' 
          },
          {
             title:'Roles ',
             path:'/Roles' 
          },
          {
            title:'Add-Roles ',
            path:'/add-roles' 
         }
          ]
        }
         />
         <Typography variant="h6" className={`${classes.themeColorTxt}`}style={{ marginBottom: "1rem",marginTop: "1rem" }}>
          {id ? 'Edit' : 'Add'} Role
      </Typography>
      <AppLoader enabled={loader} />
      <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <div className='aside'>
          <Grid item xs={12} md={12}>
          <InputBox
                title="Title:"
                type="text"
                inputStyle={classes.inputStyle}
                classname="h-inputs p-0"
                name="name"
                styleProp={classes.title}
                value={Data.name}
                onChange={InputHandler}
                errMsg = 'Please Enter Role Title'
                showErr= {configFields.name}
              />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography className={`${classes.themeColorTxt}`}>
              <FormControl>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="superAdmin"
                    control={
                    <Checkbox
                      checked={UserType.superAdmin ? true : false}
                      onChange={handleChange1}
                      name="superAdmin"
                    />}
                    label="isSuper Admin"
                    labelPlacement="end"
                    className="sml-txt roles-checkbox"
                  />
                </FormGroup>
            </FormControl>
            </Typography>
          </Grid>
          {!UserType.superAdmin &&
            <>
              <br/>
              <Typography variant="caption" className={`${classes.themeColorTxt}`} display="block">Choose User Type:</Typography>
              <Grid item xs={12} md={12} >
                  <FormControl>
                    <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="systemAdmin"
                          control={<Checkbox
                            checked={UserType.systemAdmin ? true : false}
                            tabIndex={1}
                            onChange={handleChange}
                            disabled = {UserType.User  ? true : false}
                            name="systemAdmin"
                          />}
                          label="System Admin"
                          labelPlacement="end"
                          className="sml-txt roles-checkbox"
                        />
                        <FormControlLabel
                          value="Agent"
                          control={<Checkbox
                            checked={UserType.Agent  ? true : false}
                            tabIndex={2}
                            onChange={handleChange}
                            disabled = {UserType.User  ? true : false}
                            name="Agent"
                          />}
                          label="Agent"
                          labelPlacement="end"
                          className="sml-txt roles-checkbox"
                        />
                        <FormControlLabel
                          value="User"
                          control={<Checkbox
                            checked={UserType.User  ? true : false}
                            onChange={(e:any)=>{
                                const checked = e.target.checked;
                                if(checked){
                                  setUserType({
                                    superAdmin:false,
                                    systemAdmin:false,
                                    Agent:false,
                                    User:true,
                                  });
                                  setTabValue('3')
                                } else {
                                  setUserType({
                                    superAdmin:false,
                                    systemAdmin:false,
                                    Agent:false,
                                    User:false,
                                  });
                                  setTabValue('1')
                                }
                            }}
                            disabled = {(UserType.Agent || UserType.systemAdmin)  ? true : false}
                            name="User"
                          />}
                          label="User (SSP)"
                          labelPlacement="end"
                          className="sml-txt roles-checkbox"
                        />
                    </FormGroup>
                  </FormControl>
              </Grid>
            </>
          }
          <Grid item xs={12} md={12}>
              <Box>
                  <Typography variant="caption" className={`${classes.themeColorTxt}`} >Description:</Typography>
                  {/* //className={classes.title} */}
                  <TextareaAutosize
                  className={`${classes.themeBgColor} ${classes.themeColorTxt}`}
                    aria-label="empty textarea"
                    style={{ width: "100%", height: "160px",resize:'none' }}
                    value={Data.description}
                    name="description"
                    onChange={(e: any) => {
                      InputHandler(e);
                    }}
                  />
                  {
                    configFields.description && <Typography className={classes.errInlineMsg} variant="caption" display="block">Please Enter Description</Typography>
                  }
                </Box>
          </Grid>
        
          <Box >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <br/><br/>
                <Box className="text-center">
                      <Button
                        className={`${classes.btn} ${classes.btnCancel}`}
                        variant="outlined"
                        onClick={backHandler}
                      >
                        <CancelIcon style={{ marginRight: "5px"  }} />
                        Cancel
                      </Button>

                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      
                        <Button
                          className={`${classes.btn} ${classes.btnSubmit}`}
                          variant="contained"
                          onClick={submitHandler}
                        >
                          {isUpdate ? 'Update' : 'Save'}
                        </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          </div>
        </Grid>
        {!UserType.superAdmin && 
        <Grid item xs={12} md={8}>
          {/* {TabValue} <br /> */}
          {(UserType.systemAdmin || UserType.Agent || UserType.User) && 
          <>
            <>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={TabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={TabChange} aria-label="lab API tabs example">
                          {UserType.systemAdmin && <Tab label="System Admin Permissions" value="1" />}
                          {UserType.Agent &&  <Tab label="Agent Permissions" value="2" />}
                          {UserType.User && <Tab label="SSP Permissions" value="3" />}
                      </TabList>
                    </Box>
                  </TabContext>
                </Box>
            </>
            <Box className={`${TabValue === '1' ? '' : 'd-none'}`}>
              {!UserType.superAdmin && UserType.systemAdmin &&
                <SystemAdmin access={AccessData} callback={EditPermissionsCB} labels={labelChanges}/>
              }
            </Box>
            <Box className={`${ TabValue === '2' ? '' : 'd-none'}`}>
                <UserAdmin 
                    AllAccess = {UserAccessData}
                    callback={usrAdminCallback}
                    isEdit = {isUpdate} 
                    EditCategoryList = {editCategoryData}
                    EditPriorityList = {editPriorityData}
                />
            </Box>
            <Box className={`${ TabValue === '3' ? '' : 'd-none'}`}>
                <SSPAdminPermissions 
                  AllAccess={SSPAccessData} 
                  callback={SSPusrCallback} 
                  isEdit = {isUpdate} 
                  EditCategoryList = {editSSPCategoryData}
                  EditPriorityList = {editAidData}
                />
            </Box>
          </>
          }
        </Grid>
        }
      </Grid>
      </Box>
    </Box>
  );
}
export const roleLabels:any = {
  "TicketTypes":"Ticket Types",
  "Categories":"Categories",
  "Priorities":"Priorities",
  "WorkingTimePlan":"Working Time Plan",
  "SLA":"SLA",
  "TicketStatus":"Ticket Status",
  "EmailTemplates":"Email Templates",
  "Groups":"Groups",
  "Roles":"Roles",
  "Agent":"Agent",
  "Contacts":"Contacts",
  "LocationType":"Location Type",
  "Location":"Location",
  "Organisation":"Organisation",
  "OrganisationType":"Organisation Type",
  "Division":"Division",
  "Department":"Department",
  "AssetStatus":"Asset Status",
  "AssetType":"Asset Type",
  "CMDB":"CMDB",
  "TicketSource":"Ticket Source",
  "AdditionalField":"Additional Field",
  "OrganisationAzureActiveDirectiory":"Azure Active Directiory",
  "MailService":"Email Settings",
  "APISystemManagement":"System API",
  "APITicketManagement":"Ticket API",
  "APIFilesManagement":"Files API",
  "NewTickets" : "New Tickets",
  "TicketDashboard" : "Ticket Management",
  "ArticlesType":"Article Types",
  "CustomReports":"Custom Reports",
  "CsvReports":"CSV Reports",
  "PowerBiReport":"Power BI Report",
  "SurveyTemplates":"Survey Templates",
  "SurveyResults":"Survey Results",
  "ArticleType":"Article Type",
  "AISearch":"AI Search",
  "AISync":"AI Sync",
}
//TicketPermissions
export default AddRole1;