import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import React, { useEffect } from 'react';
import DrillDownFilter from './DrillDownFilter';
import HomeGroupFilter from './HomeGroupFilter';
import { useSelector } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';



function DashboardSettings() {
  const  homeFilter = useSelector((state:IState)=>state?.TicketsReducer?.homeDashboardFilter)

  const [filterAnchorEl, setFilterAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [filterCount, setFilterCount] = React.useState<number>(0);
  
  const toggleFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setFilterAnchorEl(null)
  };
  useEffect(()=>{
    let tempArr =  Object.keys(homeFilter).map(i=>{
      return Object.keys(homeFilter[i])
    }).flat()
    setFilterCount(tempArr.length);
  },[homeFilter])
  
  
  return (
    <Box>
      <Box className=" p-1 home-page-controls">
              <Box className="text-right">
                <Button className="home-cog" onClick={toggleFilter}>
                    <span className="pointer simple-icon icon-settings"></span>
                    {filterCount >0 && <span className='count'>{filterCount}</span>}
                </Button>
              </Box>
             
              {filterAnchorEl  && 
                <Box className="home-page-controls-popover white-bg">
                    <DrillDownFilter handleClose={handleClose} />
                </Box>
              }
              
        </Box>
    </Box>
  )
}


export default  DashboardSettings;