import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import { createRef, useEffect, useReducer, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    image,
    imageGallery,
    lineHeight,
    link,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    video,
} from 'suneditor/src/plugins';
import { ValidationResultS } from '../../pages/new-ticket/LatestTicket/Type';
import { isValidate } from '../../pages/new-ticket/LatestTicket/Validation';
import { FileUpload } from '../../redux/actions/FileUpload/FileUpload';
import { addSurvey, editSurvey, getSurveyById } from '../../redux/actions/Survey/survey';
import { IState } from '../../redux/reducers/rootReducers';
import { TextBoxLatest } from '../common/TextBoxLatest/TextBoxLatest';
import CustomSelect from '../common/customSelect/CustomSelect';
import CustomFormLabel from '../common/form-label/CustomFormLabel ';
import Breadcrumbs from "../common2/Breadcrumbs";
import { addSurveySchema, iSurvey } from './schema';
import getWebForm from '../../redux/actions/WebForms/webForms';
import CommonStyles from '../common/CommonStyles';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const initVal: CounterState = {
    ticketType: null,
    webform: null,
    category: null,
    thumbIconPath: "",
    thumbIconId: 0,
    errors: {}
}

// Define the type for the state
interface CounterState {
    ticketType: null | any;
    webform: null | any;
    category: null | any;
    thumbIconPath: string;
    thumbIconId: number;
    errors: any
}

// Define the type for the action
type CounterAction = { type: string, payload?: any };

// Reducer function
const articalReducer = (state: CounterState = initVal, action: CounterAction): CounterState => {
    switch (action.type) {
        case 'CHANGE_VALUE':
            return {
                ...state,
                ...action.payload
            };
        case 'STORE_ERR':
            return {
                ...state,
                errors: action.payload
            };
        case 'UNSET_ERR':
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload]: null
                }
            };
        default:
            return state;
    }
};

// "name": "string",
// "webformId": 0,
// "EmailTemplateId": 0,
// "Description": "string",
// "Thanks Message": "string",
// "isOneResponsePerUser": true,
// "Boby":"String"

function CreateSurvey(props: any) {
    const { categoryList = [] } = props;
    const param = useParams();
    const dispatch = useDispatch();
    const [editVal, seteditVal] = useState<any>(null);
    const [surveyTemplateList, setSurveyTemplateList] = useState<any>([0]);
   const WebFormList = useSelector((state: IState) => state?.WebformReducer?.data);
    // const getAllArticleTypeCB = (resStatus:string,res:any) => {
    //     if(resStatus === "0"){
    //         setSurveyTemplateList(res)
    //     }
    // }
    useEffect(() => {
        // dispatch(getArticleType(getAllArticleTypeCB))
        dispatch(getWebForm("Survey"))
    }, [])
    useEffect(() => {
        if (!param?.id) return;
        dispatch(getSurveyById(param.id, getSurveyByIdCB))
    }, [param?.id])
    const getSurveyByIdCB = (resStatus: string, res: any) => {
        console.log("getSurveyByIdCB", res, resStatus)
        if (resStatus === "0") {
            initVal.webform = res?.webform || null;
            seteditVal(res)
        }
    }
    return (
        <>
            {((WebFormList.length > 0) && (!param?.id || editVal?.id)) &&
                <CreateSurvey1 surveyTemplateList={WebFormList} editVal={editVal} />
            }
            {(WebFormList.length === 0) && <>To create a survey , please ensure that you have created the survey template first.</>}
        </>
    )
}
function CreateSurvey1(props: any) {
    console.log("CreateArticle1_initVal",initVal)
    const { editVal, surveyTemplateList = [] } = props;
    const navigate = useNavigate();
    const classes=CommonStyles()
    const param = useParams();
    const nameRef: any = createRef();
    const descRef: any = createRef();
    const thanksMsgRef: any = createRef();
    const isOneResponsePerUserRef: any = useRef();
    const [body, setBody] = useState<any>(editVal?.body || "");
    const [state, customDispatch] = useReducer(articalReducer, initVal);

    const [activeTemplates, setActiveTemplates] = useState<any>([])
    const [surveyTemplateVal, setsurveyTemplateVal] = useState<any>(editVal?.webform || null);
    

    const dispatch = useDispatch();
    useEffect(() => {
      const newItems = surveyTemplateList?.filter(
        (val: any) => val.isActive === true
      );
      setActiveTemplates(newItems);
    }, [surveyTemplateList])
    const onImageUploadBefore = (files: any, info: any, uploadHandler: any) => {
        const uploadCB = (resStatus: string, res: any) => {
            if (resStatus !== "0") return;
            let { filePath = "", name = "", fileSize = 0 } = res?.data;
            console.log("uploadesRes", res.data)
            const response = {
                "result": [
                    {
                        "url": filePath,
                        "name": name,
                        "size": fileSize
                    },
                ]
            }
            uploadHandler(response)
        }
        let fileObject = {
            description: "First File",
            filetypeid: 1,
            ExtensionTypeId: 1,
            name: "email-photo",
            formFile: files[0],
        };
        dispatch(FileUpload(fileObject, () => { }, false, uploadCB,null,"surveyattachmentinline"));
    }
    const unsetErr = (name: string = "") => {
        customDispatch({
            type: "UNSET_ERR",
            payload: name
        })
    }
    const cancelHandler = () => {
        navigate('/SurveyList')
    }
    const onSubmit = () => {
        const { category, ticketType } = state;
        const payload: iSurvey = {
            "id": param.id ? +param.id : 0,
            "name": nameRef?.current?.value || "",
            "webformId":surveyTemplateVal?.id || 0,
            "description": descRef?.current?.value || "",
            "thanksMessage": thanksMsgRef?.current?.value || "",
            "isOneResponsePerUser": isOneResponsePerUserRef?.current?.checked,
            "body": body,
            "isActive": true,
        }

        /* ================== validation ================== */
        const { name, webformId, thanksMessage } = payload;
        const validation: ValidationResultS = isValidate(
            { name, webformId, thanksMessage },
            addSurveySchema
        );
        if (!validation.isValid) {
            validation.errors.body = [];
            if (payload.body.length < 130) {
                validation.errors.body = ["Please Enter Minimum 100 Charachters to continue."]
            }
            customDispatch({
                type: "STORE_ERR",
                payload: {
                    ...validation.errors
                }
            })
            return;
        }
        if (param.id) {
            dispatch(editSurvey(payload.id, payload, submitCB))
            return;
        }
        console.log("onSubmit_____", payload);
        dispatch(addSurvey(payload, submitCB))
    }
    const submitCB = (resStatus: string, res: any) => {
        console.log("submitCB", resStatus, res)
        if (resStatus === "0") {
            cancelHandler()
        }
    }
    useEffect(() => {
        /* ==== Prefill EDIT value ==== */
        if (!editVal) return;
        const { description, name, thanksMessage } = editVal;
        if (nameRef?.current) nameRef.current.value = name;
        if (descRef?.current) descRef.current.value = description;
        if (thanksMsgRef?.current) thanksMsgRef.current.value = thanksMessage;
    }, [editVal])
    
    return (
        <Box className="p-1">
            <Breadcrumbs
                data={[
                    {
                        title: 'System Management',
                        path: '/adminPanel'
                    },
                    {
                        title: 'Survey List ',
                        path: '/SurveyList'
                    },
                    {
                        title: `${param?.id ? "Edit" : "Add"} Survey`,
                        path: `${param.id ? "/SurveyList/" + param.id : "/CreateSurvey"}`
                    }
                ]
                }
            />
            <Box className="white-bg p-2 mt-1 add-article">
                <Typography>
                    {param?.id ? "Edit Survey" : "Add New Survey"}
                </Typography>
                <hr />
                <Grid container spacing={1}>

                    <Grid item xs={12} md={4}>
                        {/* Name text Box */}
                        <TextBoxLatest
                            multiline={false}
                            placeholder="Enter Name"
                            title={"Name"}
                            onchangeHandler={() => {
                                if (state?.errors?.name) { unsetErr("name") }
                            }}
                            ref={nameRef}
                            isMandotary={true}
                            errorMsg={`Please Enter`}
                            isError={state?.errors?.name || false}
                            name='name'
                        />

                        <CustomFormLabel
                            labelName={"Survey Template"}
                            isMandotary={true}
                            classNames='sml-txt'
                        />

                        {/* survey template dropdown */}

                        <CustomSelect
                            isOpen={false}
                            placeholder="Survey Template"
                            width={"100%"}
                            name="webformId"
                            isColor={false}
                            isDisabled={false}
                            isError={state?.errors?.webformId || false}
                            helperText={`Survey Template is required.`}
                            defaultValue={surveyTemplateVal}
                            options={activeTemplates}
                            handleChange={(e: any) => {
                                setsurveyTemplateVal(e)
                                if (state?.errors?.webformId) {
                                    unsetErr("webformId")
                                }
                            }}
                            isMulti={false}
                            customClassNames="sml-txt-dropdown new-service"
                        />

                        {/* Description text Box */}

                        <TextBoxLatest
                            multiline={true}
                            placeholder="Enter Description"
                            title={"Description"}
                            onchangeHandler={() => { unsetErr("Description") }}
                            ref={descRef}
                            isMandotary={false}
                            errorMsg={`Please Enter`}
                            isError={false}
                            name='Description'
                        />
                        <TextBoxLatest
                            multiline={true}
                            placeholder="Enter Thanks Message"
                            title={"Thanks Message"}
                            onchangeHandler={() => { unsetErr("thanksMessage") }}
                            ref={thanksMsgRef}
                            isMandotary={true}
                            errorMsg={`Please Enter`}
                            isError={state?.errors?.thanksMessage || false}
                            name='thanksMessage'
                        />

                        {/* One Answer per Object radio Button */}
                        <Typography className={`radio-label`} >
                            <Checkbox
                                defaultChecked={editVal?.isOneResponsePerUser || true}
                                inputRef={isOneResponsePerUserRef}
                            />
                            <span>One Answer per Object</span>
                        </Typography>


                        <Box className="text-center pt-2">
                            <Button 
                            className={`${classes.btn} ${classes.btnCancel}`}
                            variant="contained"
                            onClick={cancelHandler}>
                                Cancel
                            </Button> &nbsp; &nbsp; &nbsp;
                            <Button variant='contained' onClick={onSubmit}>
                                {param?.id ? "Update" : "Submit"}
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={8} className='CreateSurvey-header'>
                    {/* Header Editor text Box */}
                        <CustomFormLabel
                            labelName={"Header"}
                            isMandotary={true}
                            classNames='sml-txt'
                        />
                        <SunEditor
                            autoFocus={true}
                            lang="en"
                            setOptions={{
                                showPathLabel: false,
                                minHeight: "270px",
                                placeholder: "Enter your text here!!!",
                                plugins: [
                                    align,
                                    font,
                                    fontColor,
                                    fontSize,
                                    formatBlock,
                                    hiliteColor,
                                    horizontalRule,
                                    lineHeight,
                                    list,
                                    paragraphStyle,
                                    table,
                                    template,
                                    textStyle,
                                    image,
                                    video,
                                    link,
                                    imageGallery
                                ],
                                buttonList: [
                                    ["undo", "redo"],
                                    ["font", "fontSize", "formatBlock", "paragraphStyle"],
                                    [
                                        "bold",
                                        "underline",
                                        "italic",
                                        "strike",
                                        "subscript",
                                        "superscript"
                                    ],
                                    ["fontColor", "hiliteColor"],
                                    ["align", "list", "lineHeight"],
                                    ["table", "horizontalRule",],
                                    ["link", "image", "video"],
                                    ["showBlocks", "codeView"]
                                ],
                                formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                                font: [
                                    "Arial",
                                    "Calibri",
                                    "Comic Sans",
                                    "Courier",
                                    "Garamond",
                                    "Georgia",
                                    "Impact",
                                    "Lucida Console",
                                    "Palatino Linotype",
                                    "Segoe UI",
                                    "Tahoma",
                                    "Times New Roman",
                                    "Trebuchet MS"
                                ],
                                // imageMultipleFile: true,
                            }}
                            // onImageUpload={()=>{
                            //   console.log('upload Progress')
                            // }}
                            onImageUploadBefore={onImageUploadBefore}
                            //imageMultipleFile={true}
                            defaultValue={body}
                            // setContents={values?.body}
                            onChange={(content: string) => {
                                setBody(content)
                                console.log('setBody(content)', content)
                                unsetErr("body")
                            }}
                        />
                        {(state?.errors?.body?.length > 0) && (
                            <CustomFormLabel
                                labelName={state.errors.body[0]}
                                isMandotary={false}
                                classNames='sml-txt txt-danger'
                            />
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

// export default CreateSurvey;
const mapStateToProps = (state: IState) => {
    return ({
        categoryList: state?.categoryReducer?.Data?.data,
    })
};
export default connect(mapStateToProps)(CreateSurvey);