import { connect, useDispatch } from "react-redux";
import { IState } from "../../../../redux/reducers/rootReducers";
import CommonStyles from "../../../common/CommonStyles";
import { Box,FormControlLabel,Checkbox } from "@mui/material";
import { selectAllPermissions } from "../../../../redux/actions/userManagement/role";
import { useEffect, useState } from "react";
interface IValueType {
    check : any;
    uncheck : any;
    totalPermissionCount : number;
}
const createDataSet = (info:any)=>{
    const keys = Object.keys(info).filter((x)=>x !== 'title' && x !== "onlyAllowAccess")
    const check:any = {};
    const uncheck:any = {};
    let totalPermissionCount = 0;
    keys.map((i:string)=>{
        uncheck[i] = [];
        check[i] = info[i].permissions.map((j:any)=>j.id);
        totalPermissionCount += info[i].permissions.length;
    })
    console.log('createDataSet', {
        check,
        uncheck,
        totalPermissionCount
    })
    return {
        check,
        uncheck,
        totalPermissionCount
    }
}
const getCheckedCount = (info:any,checkedItems:any)=>{
    const keys = Object.keys(info).filter((x)=>x !== 'title' && x !== "onlyAllowAccess")
    let totalPermissionCount = 0;
    keys.map((i:string)=>{
        // console.log('xxx',checkedItems,checkedItems?.[i],i)
        if(checkedItems?.[i]?.length) totalPermissionCount += checkedItems[i]?.length;
    })
    return totalPermissionCount;   
}
const SelectAllOption = ({checkedItems,info}:any) =>{
    const classes = CommonStyles();
    const dispatch = useDispatch();
    const [valueObj,setValueObj] = useState<IValueType>({
        check : [],
        uncheck : [],
        totalPermissionCount : 0
    });
    const [checked, setChecked] = useState(false);
    const [intermediate, setIntermediate] = useState(false);
    const toggleCheckAll = (e:any) =>{
        const {checked} = e.target;
        dispatch(selectAllPermissions(
            checked ? valueObj?.check : valueObj?.uncheck
        ))
    }
    useEffect(()=>{
        console.log('SelectAllOptionSelectAllOption')
        setValueObj(createDataSet(info))
    },[])
    useEffect(()=>{
        console.log('isALLChecked',info,
            valueObj?.totalPermissionCount,
            getCheckedCount(info,checkedItems)
        )
        const checkedCount = getCheckedCount(info,checkedItems);
        setChecked(checkedCount > 0 && (valueObj?.totalPermissionCount <= checkedCount) ? true : false);
        setIntermediate(checkedCount > 0 && valueObj?.totalPermissionCount > checkedCount ? true : false);
    },[valueObj?.totalPermissionCount,checkedItems])
    return(<>
        <Box className={classes.roleAllowAccessBtn}>
            <FormControlLabel
                value=''
                checked={checked}
                control={
                    <Checkbox size="small"
                        indeterminate={intermediate} 
                        checked={checked}
                    />
                }
                onChange={toggleCheckAll}
                label='Allow access'
                labelPlacement="end"
                className="sml-txt"
            />
        </Box>  
    </>)
}
const mapStateToProps = (state:IState) => {
    return ({
        checkedItems : state?.roleReducer?.checkedStatus,
    })
};
export default connect(mapStateToProps)(SelectAllOption);