import { Box } from "@mui/material";
import WebFormWorkspace from "./WebFormWorkspace";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getWebFormById } from "../../redux/actions/WebForms/webForms";
import { connect, useDispatch } from "react-redux";
import { getCategory } from "../../redux/actions/SystemManagement/AddCategory";
import { IState } from "../../redux/reducers/rootReducers";

const WebForms = (props:any) =>{
    const {type="Ticket",ticketTypes=[]} = props;
    const param = useParams();
    const dispatch = useDispatch();
    const [editVal,setEditVal] = useState<any>(null);
    useEffect(()=>{
        if(type==="Ticket"){
            dispatch(getCategory())
        }
    },[])
    useEffect(()=>{
        if(!param.id) return;
        console.log('paramparam',param)
        dispatch(getWebFormById(param.id,editCallback))
    },[param?.id])
    const editCallback = (resStatus:string,res:any) =>{
        if(resStatus === "0"){
            console.log("editCallback",res)
            setEditVal(res)
        }
    }
    
    return(
        <>
            {type === "Ticket" ?
                <Box className="p-1">
                    {( ticketTypes.length > 0) ? <>
                        {(!param?.id || (param?.id && editVal)) && <WebFormWorkspace type={type} editValue={editVal} />}
                    </> :
                        <>Loading...!</>}
                </Box>
                :
                <Box className="p-1">
                    <>
                        {(!param?.id || (param?.id && editVal)) && <WebFormWorkspace type={type} editValue={editVal} />}
                    </>
                </Box>
            }
        </>
    )
}



const mapStateToProps = (state:IState) => {
    return ({
      //LoginReducer: state?.LoginReducer?.roleAccess,
        // categoryList : state?.categoryReducer?.Data?.data,
        ticketTypes : state?.TicketsReducer?.allResponses?.TicketTypes
    })
  };
  
export default connect(mapStateToProps)(WebForms);
// export default WebForms;