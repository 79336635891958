import CloseIcon from "@mui/icons-material/Close";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import SaveIcon from "@mui/icons-material/Save";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import SyncAltRoundedIcon from "@mui/icons-material/SyncAltRounded";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import * as React from "react";
import { forwardRef } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import BasicButton from "../../components/common/button/BasicButton";
import CustomSelect from "../../components/common/customSelect/CustomSelect";
import CustomDatePicker from "../../components/common/date-picker/CustomDatePicker";
import CustomDialog from "../../components/common/dialog/CustomDialog";
import Radio from "../../components/common/radio/Radio";
import CustomInput from "../../components/common/textField/CustomInput";
import LanguageData from "../../configs/LanguageData.json";
import configs from "../../configs/config";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import { getAllTickets } from "../../redux/actions/Dashboard/dashboard.action";
import { ActionType } from "../../redux/actions/Snackbars";
import { getPermission } from "../../redux/actions/SystemManagement/Permission";
import getEmailSettings from "../../redux/actions/adminInterfaces/EmailSettings";
import { IState } from "../../redux/reducers/rootReducers";
import {
  addNewTicket,
  updateNewTicket,
} from "../../services/dashboard/dashboard.service";
import { uploadTicketAttachment } from "../../services/fileUpload/FileUpload";
import { getTimeLogs } from "../../services/timeline/timeline.service";
import timeSpanToReadbleFormat from "../../util/timeSpanToReadbleFormat";



const tableIcons: any = {
  Search: forwardRef((props, ref) => <SearchRoundedIcon />),
  Clear: forwardRef((props, ref) => <CloseRoundedIcon />),
  ResetSearch: forwardRef((props, ref) => <CloseRoundedIcon />),
  SortArrow: forwardRef((props, ref) => (
    <SyncAltRoundedIcon
      sx={{ color: "gray", transform: "rotate(90deg)", width: "15px" }}
    />
  )),
  Export: forwardRef((props, ref) => <GetAppRoundedIcon />),
  Filter: forwardRef((props, ref) => (
    <FilterListRoundedIcon sx={{ width: "20px" }} />
  )),
  FirstPage: forwardRef((props, ref) => (
    <FirstPageRoundedIcon sx={{ width: "20px" }} />
  )),
  LastPage: forwardRef((props, ref) => (
    <LastPageRoundedIcon sx={{ width: "20px" }} />
  )),
  NextPage: forwardRef((props, ref) => (
    <NavigateNextRoundedIcon sx={{ width: "20px" }} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <NavigateBeforeRoundedIcon sx={{ width: "20px" }} />
  )),
};

const ticketCreation = async (payload: any) => {
  console.log("payload", payload);
  let newTicket: any = await addNewTicket(payload);
  return newTicket;
};
const updateTicket = async (id: any, payload: any) => {
  console.log("payload", payload);
  let newTicket: any = await updateNewTicket(payload, id);
  return newTicket;
};
const getTimeLineById = async (id: Number, pageCount: Number) => {
  let timelineLogs = await getTimeLogs(id, pageCount);
  return timelineLogs;
};
const fileAttachment = async (attachment: any) => {
  let fileAttachment = await uploadTicketAttachment(attachment);
  return fileAttachment;
};

export const convertToTimeSpan = (day: any, hour: any, minute: any) => {
  let dayTemp = day?  day?.toString() : "0";
  let hourTemp = hour ? hour?.toString(): "0";
  let minuteTemp: any = minute ? minute?.toString(): "0";
  console.log("hour, minute", dayTemp, hourTemp, minuteTemp);
  let hourFormated = hourTemp?.length == 1 ? `0${hourTemp}` : `${hourTemp}`;
  let minuteFormated =
    minuteTemp?.length == 1 ? `0${minuteTemp}` : `${minuteTemp}`;

  if (dayTemp !== "0") {
    return `${day}.${hourFormated}:${minuteFormated}:00`;
  } else {
    return `${hourFormated}:${minuteFormated}:00`;
  }
};

const CustomHookForFormik = (

  formData: any,
  submittedData: any,
  setIsAddUpdate: any,
  setIsSendEMailPopup: any,
  emailChecked: any,
  setSubmittedData: any,
  type: any,
  setData: any,
  setEditData: any,
  editData: any,
  filterEmailData: any

) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ticketId } = useParams();
  const currAgent = useSelector(
    (state: IState) => state?.agentReducer?.loggedInAgent
  );
  // console.log("configs",configs);
  
  const formik = useFormik({
    initialValues: {
      agent: formData.currentAgent,
      status: null,
      note: "",
      template: null,
      nextActionDesc:
        "Contact client to initiate investigation of the fault within SLA",
      nextActionDate: null,
      group: null,
      filteredTemplate: null,
      filterAgent: [],
      effortDay: "0",
      effortHour: "00",
      effortMinute: "00",
      downTimeDay: "0",
      downTimeHour: "00",
      downTimeMinute: "00",
      effortShow: null,
    },
    validationSchema:
      type == "history"
        ? emailChecked
          ? validationSchemaForHistoryForTemplate
          : validationSchemaForHistory
        : type == "quick-update"
        ? validationSchemaForHistory
        : type == "send-email"
        ? validationSchemaForEmail
        : type == "add"
        ? emailChecked
          ? validationSchemaForAddForTemplate
          : validationSchemaForAdd
        : type == "update"
        ? emailChecked
          ? validationSchemaForUpdateForTemplate
          : validationSchemaForUpdate
        : validationSchema,
    onSubmit: (values: any, actions) => {
      //   alert(JSON.stringify(values, null, 2));
      
      console.log("submited formik  add update ----", submittedData);
      const getfirstTaskByWorkflow =(workflowNodesList:any)=>{
        if(!workflowNodesList){
          return []
        }
        const firstNode = workflowNodesList.filter((x:any)=>x.nodeId==='1')?.[0];
        if(!firstNode?.id){return []};
        return [{
          workflowId: submittedData?.workflow?.id ?submittedData?.workflow?.id : 0 ,
          sourceCode: submittedData?.source?.label,
          ticketTaskLogs:{
            title:firstNode?.nodeLabel,
            subject:firstNode?.nodeLabel,
            statusId: firstNode?.statusId,
            description:firstNode?.description,
            contactId: submittedData?.primaryContact?.id,
            agentId: values.agent.id,
            workflowNodeId: firstNode?.id,
            sourceCode: submittedData?.source?.label,
          }
        }]
        //submittedData.workflow.workflowNodesList[0]
      }
      if (type == "add") {
        console.log("submited formik  add update ----", submittedData);
        let tempAsset: any = [];
        formData?.assetSelected.filter((item: any) => {
          let a = {
            assetId: item?.id,
          };
          tempAsset.push(a);
        });
        let payload = {
          isActive: true,
          remainingSLAInMinutes: 0,
          sourceCode: submittedData?.source?.label,
          workflowId: submittedData?.workflow?.id ?submittedData?.workflow?.id : 0 ,
          ticketLogs: {
            circulationListTO: submittedData?.primaryContact?.mail
              ? submittedData?.primaryContact?.mail
              : null,
            title: submittedData?.subject,
            description: submittedData?.desc,
            categoryId: submittedData?.category?.id,
            statusId: values?.status?.id,
            ticketTypeId: submittedData?.ticketType?.id,
            priorityId: submittedData?.priority?.id,
            sourceCode: submittedData?.source?.label,
            openDateTime: submittedData?.openDate,
            dueDateTime: submittedData?.dueDate,
            firstResponseDueDateTime: submittedData?.nextActionDate,
            isActive: true,
            contactId: submittedData?.primaryContact?.id,
            secondaryContactId: submittedData?.secondaryContact?.id,
            groupId: values.group.id,
            nextActionDescription: submittedData?.nextActionDesc,
            agentId: values.agent.id,
            note: values.note,
            ticketLogAssetList: tempAsset,
            effort: convertToTimeSpan(
              values?.effortDay,
              values?.effortHour,
              values?.effortMinute
            ),
            downtime: convertToTimeSpan(
              values?.downTimeDay,
              values?.downTimeHour,
              values?.downTimeMinute
            ),
          },
          ticketTagList: submittedData.tags,
          ticketTaskList: getfirstTaskByWorkflow(submittedData?.workflow?.workflowNodesList)
        };
        console.log("payload >>> new ticket>>", payload);
        ticketCreation(payload).then((res: any) => {
          console.log(
            "ticketCreation res",
            res,
            res.data.ticketLogs.id,
            submittedData.attachment
          );
          if (res.status == 201) {
            submittedData.attachment.forEach((item1: any) => {
              let attachment = {
                ticketLogId: res.data.ticketLogs.id,
                attachmentId: item1.id,
              };
              // fileAttachment(attachment).then((res1: any) => {
              //   console.log("fileAttachment res", res1);
              // });
            });
            dispatch(getAllTickets());
            setIsAddUpdate(false);
            setSubmittedData({
              ...res.data,
              ...{
                template: values.template,
                contactList: formData.contactList,
              },
            });
            if (emailChecked) {
              setIsSendEMailPopup(true);
            } else {
              navigate(-1);
            }
            dispatch({
              type: ActionType.SNACKBAR,
              payload: {
                message: `${LanguageData.TICKET_ADDED_SUCCESSFULLY}${res?.data?.id}`,
                severity: "success",
                open: true,
              },
            });
          }
        });
      } else if (type == "update") {
        let resultCC = "";
        filterEmailData?.selectedCC.forEach((d: any) => {
          resultCC += d.value + ";";
        });
        // Remove the trailing comma
        resultCC = resultCC.slice(0, -1);

        let resultBCC = "";
        filterEmailData?.selectedBCC.forEach((d: any) => {
          resultBCC += d.value + ";";
        });
        // Remove the trailing comma
        resultBCC = resultBCC.slice(0, -1);

        let resultTo = "";
        filterEmailData?.selectedTo.forEach((d: any) => {
          resultTo += d.value + ";";
        });
        // Remove the trailing comma
        resultTo = resultTo.slice(0, -1);

        console.log(
          "edit to show",
          editData,
        );
        delete editData?.ticketLogs.user;
        // delete editData?.ticketLogs.ticketAttachmentList;
        delete editData?.ticketLogs.updatedBy;
        delete editData?.ticketLogs.updatedDateTime;
        delete editData?.ticketLogs.scheduleDateTime;
        delete editData?.ticketLogs.id;
        delete editData?.ticketLogs.createdDateTime;
        delete editData?.ticketLogs.createdByUserId;
        delete editData?.ticketLogs.effort;

        let tempAsset: any = [];
        formData?.assetSelected.filter((item: any) => {
          let a = {
            assetId: item?.assetId,
          };
          tempAsset.push(a);
        });

        let payload = {
          isActive: true,
          remainingSLAInMinutes: 0,
          sourceCode: configs.SOURCE,
          workflowId: submittedData?.workflow?.id ?submittedData?.workflow?.id : 0 ,
          ticketLogs: {
            ...editData?.ticketLogs,
            circulationListCC: resultCC ? resultCC : null,
            circulationListBCC: resultBCC ? resultBCC : null,
            circulationListTO: resultTo ? resultTo : null,
            title: submittedData?.subject,
            description: submittedData?.desc,
            categoryId: submittedData?.category?.id,
            statusId: values?.status?.id,
            ticketTypeId: submittedData?.ticketType?.id,
            priorityId: submittedData?.priority?.id,
            sourceCode: configs.SOURCE,
            // openDateTime: submittedData?.openDate,
            dueDateTime: submittedData?.dueDate,
            firstResponseDueDateTime: submittedData?.nextActionDate,
            isActive: true,
            contactId: submittedData?.primaryContact?.id,
            secondaryContactId: submittedData?.secondaryContact?.id,
            groupId: values.group.id,
            nextActionDescription: submittedData?.nextActionDesc,
            agentId: values.agent.id,
            note: values.note,
            ticketLogAssetList: tempAsset,
            effort: convertToTimeSpan(
              values.effortDay,
              values.effortHour,
              values.effortMinute
            ),
            downtime: convertToTimeSpan(
              values.downTimeDay,
              values.downTimeHour,
              values.downTimeMinute
            ),
          },
          ticketTagList: submittedData.tags,
          WorkflowNodesId: 0,
          // ticketLogAttachmentsList: submittedData?.ticketLogAttachmentsList,
          slaRemaining: {
            ticks: 0,
          },
        };
        updateTicket(ticketId, payload).then((res: any) => {
          console.log("ticketCreation res", res);
          setEditData(res.data);
          if (res.status == 200) {
            submittedData.attachment.forEach((item1: any) => {
              let attachment = {
                ticketLogId: res.data.ticketLogs.id,
                attachmentId: item1.id,
              };
              fileAttachment(attachment).then((res1: any) => {
                console.log("fileAttachment res", res1);
              });
            });
            setIsAddUpdate(false);
            getTimeLineById(Number(ticketId), 0).then((res: any) => {
              if (res?.ticketLog?.length > 0) {
                setData({
                  logData: res?.ticketLog,
                  totalEfforts: res?.totalEfforts,
                });
              } else {
              }
            });

            setSubmittedData({ ...res.data, ...{ template: values.template } });
            if (emailChecked) {
              setIsSendEMailPopup(true);
            } else {
              navigate(-1);
            }
            dispatch({
              type: ActionType.SNACKBAR,
              payload: {
                message: `${LanguageData.SUCCESS} (${res?.data?.id}) ${LanguageData.TICKET_UPDATED} `,
                severity: "success",
                open: true,
              },
            });
          }
        });
      } else if (type == "history") {
        console.log(
          "edit to show",
          editData,
        );
        delete editData?.ticketLogs.user;
        // delete editData?.ticketLogs.ticketAttachmentList;
        delete editData?.ticketLogs.updatedBy;
        delete editData?.ticketLogs.updatedDateTime;
        delete editData?.ticketLogs.scheduleDateTime;
        delete editData?.ticketLogs.id;
        delete editData?.ticketLogs.createdDateTime;
        delete editData?.ticketLogs.createdByUserId;
        let tempAsset: any = [];
        editData?.ticketLogs?.ticketLogAssetList?.filter((item: any) => {
          let a = {
            assetId: item?.assetId,
          };
          tempAsset.push(a);
        });

        let payload = {
          isActive: true,
          remainingSLAInMinutes: 0,
          sourceCode: editData.sourceCode,
          workflowId: editData?.workflowId,
          ticketLogs: {
            ...editData?.ticketLogs,
            statusId: values?.status?.id,
            firstResponseDueDateTime: values?.nextActionDate,
            agentId: values?.agent?.id,
            nextActionDescription: values?.nextActionDesc,
            note: values?.note,
            sourceCode: editData.sourceCode,
            groupId: values?.group?.id,
            ticketLogAssetList: tempAsset,
            effort: convertToTimeSpan(
              values.effortDay,
              values.effortHour,
              values.effortMinute
            ),
            downtime: convertToTimeSpan(
              values.downTimeDay,
              values.downTimeHour,
              values.downTimeMinute
            ),
          },
          slaRemaining: {
            ticks: 0,
          },
          WorkflowNodesId: 0,
          ticketTagList: editData.ticketTagList,
        };
        console.log("updateupdateupdate", 1);
        updateTicket(ticketId, payload).then((res: any) => {
          console.log(
            "editData?.ticketLogs?.ticketAttachmentList?",
            editData?.ticketLogs?.ticketLogAttachmentsList
          );
          if (res.status == 200) {
            editData?.ticketLogs?.ticketLogAttachmentsList.forEach(
              (item1: any) => {
                let attachment = {
                  ticketLogId: res.data.ticketLogs.id,
                  attachmentId: item1.attachmentId,
                };
                fileAttachment(attachment).then((res1: any) => {
                  console.log("fileAttachment res", res1);
                });
              }
            );
            setIsAddUpdate(false);
            getTimeLineById(Number(ticketId), 0).then((res1: any) => {
              if (res1?.ticketLog?.length > 0) {
                setData({
                  logData: res1?.ticketLog,
                  totalEfforts: res1?.totalEfforts,
                });
              } else {
              }
            });
            setSubmittedData({ ...res.data, ...{ template: values.template } });
            setEditData(res.data);
            if (emailChecked) {
              setIsSendEMailPopup(true);
            } else {
              // navigate("/dashboardMain");
            }
            dispatch({
              type: ActionType.SNACKBAR,
              payload: {
                message: `${LanguageData.SUCCESS}(${res?.data?.id}) ${LanguageData.TICKET_UPDATED} `,
                severity: "success",
                open: true,
              },
            });
          }
        });
      } else if (type == "quick-update") {
        delete editData?.ticketLogs.user;
        // delete editData?.ticketLogs.ticketAttachmentList;
        delete editData?.ticketLogs.updatedBy;
        delete editData?.ticketLogs.updatedDateTime;
        delete editData?.ticketLogs.scheduleDateTime;
        delete editData?.ticketLogs.id;
        delete editData?.ticketLogs.createdDateTime;
        delete editData?.ticketLogs.createdByUserId;
        delete editData?.ticketLogs.effort;

        let payload = {
          isActive: true,
          remainingSLAInMinutes: 0,
          workflowId: editData?.workflowId,
          ticketLogs: {
            ...editData?.ticketLogs,
            status: values?.status?.id,
            firstResponseDueDateTime: values?.nextActionDate,
            agentId: values?.agent?.id,
            nextActionDescription: values?.nextActionDesc,
            note: values?.note,
            sourceCode: configs.SOURCE,
            groupId: values?.group?.id,
            encodeURIffort: convertToTimeSpan(
              values.effortDay,
              values.effortHour,
              values.effortMinute
            ),
            downtime: convertToTimeSpan(
              values.downTimeDay,
              values.downTimeHour,
              values.downTimeMinute
            ),
          },
          slaRemaining: {
            ticks: 0,
          },
          WorkflowNodesId: 0,
          ticketTagList: editData.ticketTagList,
        };
        updateTicket(editData?.id, payload).then((res: any) => {
          console.log(
            "editData?.ticketLogs?.ticketAttachmentList?",
            editData?.ticketLogs?.ticketLogAttachmentsList
          );
          if (res.status == 200) {
            editData?.ticketLogs?.ticketLogAttachmentsList?.forEach(
              (item1: any) => {
                let attachment = {
                  ticketLogId: res.data.ticketLogs.id,
                  attachmentId: item1.attachmentId,
                };
                fileAttachment(attachment).then((res1: any) => {
                  console.log("fileAttachment res", res1);
                });
              }
            );
            setIsAddUpdate(false);
            setSubmittedData({ ...res.data, ...{ template: values.template } });
            // setEditData(res.data);
            setEditData();
            if (emailChecked) {
              setIsSendEMailPopup(true);
            } else {
              // navigate("/dashboardMain");
            }
            dispatch({
              type: ActionType.SNACKBAR,
              payload: {
                message: `${LanguageData.SUCCESS} (${res.data.id}) ${LanguageData.TICKET_UPDATED} `,
                severity: "success",
                open: true,
              },
            });
          }
        });
      } else if (type == "send-email") {
        setSubmittedData({ ...editData, ...{ template: values.template } });
        setIsAddUpdate(false);
        setIsSendEMailPopup(true);
      }
      actions.setSubmitting(true);
    },
  });

  return formik;
};

const validationSchemaForHistory = yup.object({
  agent: yup
    .object()
    .typeError("Agent is required")
    .required("Agent is required"),
  status: yup
    .object()
    .typeError("Status is required")
    .required("Status is required"),
  nextActionDesc: yup.string().max(100, "Maximum character exceeds(Max-100)"),
  effortDay: yup
    .number()
    .min(0, "must be positive number")
    .required("Day is required"),
  effortHour: yup
    .number()
    .min(0, "must be positive number")
    .required("Hour is required"),
  effortMinute: yup
    .number()
    .min(0, "must be positive number")
    .required("Minute is required"),

  downTimeDay: yup
    .number()
    .min(0, "must be positive number")
    .required("Day is required"),
  downTimeHour: yup
    .number()
    .min(0, "must be positive number")
    .required("Hour is required"),
  downTimeMinute: yup
    .number()
    .min(0, "must be positive number")
    .required("Minute is required"),
});
const validationSchemaForHistoryWithoutNextActionValidation = yup.object({
  agent: yup
    .object()
    .typeError("Agent is required")
    .required("Agent is required"),
  status: yup
    .object()
    .typeError("Status is required")
    .required("Status is required"),
  nextActionDesc: yup.string().max(100, "Maximum character exceeds(Max-100)"),
  effortDay: yup
    .number()
    .min(0, "must be positive number")
    .required("Day is required"),
  effortHour: yup
    .number()
    .min(0, "must be positive number")
    .required("Hour is required"),
  effortMinute: yup
    .number()
    .min(0, "must be positive number")
    .required("Minute is required"),

  downTimeDay: yup
    .number()
    .min(0, "must be positive number")
    .required("Day is required"),
  downTimeHour: yup
    .number()
    .min(0, "must be positive number")
    .required("Hour is required"),
  downTimeMinute: yup
    .number()
    .min(0, "must be positive number")
    .required("Minute is required"),
});
const validationSchemaForHistoryForTemplate = yup.object({
  agent: yup
    .object()
    .typeError("Agent is required")
    .required("Agent is required"),
  status: yup
    .object()
    .typeError("Status is required")
    .required("Status is required"),
  nextActionDesc: yup.string().max(100, "Maximum character exceeds(Max-100)"),
  template: yup.object().typeError("Primary contact is required"),
  effortDay: yup
    .number()
    .min(0, "must be positive number")
    .required("Day is required"),
  effortHour: yup
    .number()
    .min(0, "must be positive number")
    .required("Hour is required"),
  effortMinute: yup
    .number()
    .min(0, "must be positive number")
    .required("Minute is required"),

  downTimeDay: yup
    .number()
    .min(0, "must be positive number")
    .required("Day is required"),
  downTimeHour: yup
    .number()
    .min(0, "must be positive number")
    .required("Hour is required"),
  downTimeMinute: yup
    .number()
    .min(0, "must be positive number")
    .required("Minute is required"),
});
const validationSchemaForAdd = yup.object({
  agent: yup
    .object()
    .typeError("Agent is required")
    .required("Agent is required"),
  status: yup
    .object()
    .typeError("Status is required")
    .required("Status is required"),
  group: yup
    .object()
    .typeError("Group is required")
    .required("Group is required"),
  effortDay: yup
    .number()
    .min(0, "must be positive number")
    .required("Day is required"),
  effortHour: yup
    .number()
    .min(0, "must be positive number")
    .required("Hour is required"),
  effortMinute: yup
    .number()
    .min(0, "must be positive number")
    .required("Minute is required"),
  downTimeDay: yup
    .number()
    .min(0, "must be positive number")
    .required("Day is required"),
  downTimeHour: yup
    .number()
    .min(0, "must be positive number")
    .required("Hour is required"),
  downTimeMinute: yup
    .number()
    .min(0, "must be positive number")
    .required("Minute is required"),
});
const validationSchemaForAddForTemplate = yup.object({
  agent: yup
    .object()
    .typeError("Agent is required")
    .required("Agent is required"),
  status: yup
    .object()
    .typeError("Status is required")
    .required("Status is required"),
  group: yup
    .object()
    .typeError("Group is required")
    .required("Group is required"),
  effortDay: yup
    .number()
    .min(0, "must be positive number")
    .required("Day is required"),
  effortHour: yup
    .number()
    .min(0, "must be positive number")
    .required("Hour is required"),
  effortMinute: yup
    .number()
    .min(0, "must be positive number")
    .required("Minute is required"),

  downTimeDay: yup
    .number()
    .min(0, "must be positive number")
    .required("Day is required"),
  downTimeHour: yup
    .number()
    .min(0, "must be positive number")
    .required("Hour is required"),
  downTimeMinute: yup
    .number()
    .min(0, "must be positive number")
    .required("Minute is required"),
});
const validationSchema = yup.object({
  agent: yup
    .object()
    .typeError("Agent is required")
    .required("Agent is required"),
  status: yup
    .object()
    .typeError("Status is required")
    .required("Status is required"),
  group: yup
    .object()
    .typeError("Group is required")
    .required("Group is required"),
});
const validationSchemaForUpdate = yup.object({
  agent: yup
    .object()
    .typeError("Agent is required")
    .required("Agent is required"),
  status: yup
    .object()
    .typeError("Status is required")
    .required("Status is required"),
  effortDay: yup
    .number()
    .min(0, "must be positive number")
    .required("Day is required"),
  effortHour: yup
    .number()
    .min(0, "must be positive number")
    .required("Hour is required"),
  effortMinute: yup
    .number()
    .min(0, "must be positive number")
    .required("Minute is required"),

  downTimeDay: yup
    .number()
    .min(0, "must be positive number")
    .required("Day is required"),
  downTimeHour: yup
    .number()
    .min(0, "must be positive number")
    .required("Hour is required"),
  downTimeMinute: yup
    .number()
    .min(0, "must be positive number")
    .required("Minute is required"),
});
const validationSchemaForUpdateForTemplate = yup.object({
  agent: yup
    .object()
    .typeError("Agent is required")
    .required("Agent is required"),
  status: yup
    .object()
    .typeError("Status is required")
    .required("Status is required"),
  template: yup.object().typeError("Primary contact is required"),
  effortDay: yup
    .number()
    .min(0, "must be positive number")
    .required("Day is required"),
  effortHour: yup
    .number()
    .min(0, "must be positive number")
    .required("Hour is required"),
  effortMinute: yup
    .number()
    .min(0, "must be positive number")
    .required("Minute is required"),

  downTimeDay: yup
    .number()
    .min(0, "must be positive number")
    .required("Day is required"),
  downTimeHour: yup
    .number()
    .min(0, "must be positive number")
    .required("Hour is required"),
  downTimeMinute: yup
    .number()
    .min(0, "must be positive number")
    .required("Minute is required"),
});
const validationSchemaForEmail = yup.object({
  template: yup.object().typeError("Primary contact is required"),
});

const AddUpdate = ({
  setIsAddUpdate,
  isAddUpdate,
  formData,
  submittedData,
  setIsSendEMailPopup,
  setSubmittedData,
  type,
  datas,
  setData,
  editData,
  setEditData,
  filterEmailData,
}: {
  setIsAddUpdate: any;
  isAddUpdate: any;
  formData: any;
  submittedData: any;
  setIsSendEMailPopup: any;
  setSubmittedData: any;
  type: any;
  setData: any;
  datas: any;
  editData: any;
  setEditData: any;
  filterEmailData?: any;
}) => {
  const [emailChecked, setEmailChecked] = React.useState<any>(true);
  const [roleAccess, setroleAccess] = React.useState({} as any);

  const priorityIdFromUrl: string | boolean = useGetQueryParams("priority");
  const dispatch = useDispatch();
  const messageRef: any = React.useRef();
  const groupFromUrl: string | boolean = useGetQueryParams("group");
  const statusFromUrl: string | boolean = useGetQueryParams("status");
  const ownerFromUrl: string | boolean = useGetQueryParams("owner");
  const currAgent = useSelector(
    (state: IState) => state?.agentReducer?.loggedInAgent
  );
  const PermissionData = useSelector(
    (state: IState) => state?.permissionReducer?.Data?.data
  );
  const currRole = useSelector(
    (state: IState) => state?.roleReducer?.LOGGED_URS_ROLE
  );
  const isEmailShow = useSelector(
    (state: IState) => state?.EmailSettingsReducer?.AllEmailSettings
  );
  const { ticketId } = useParams();

  // const classes = CommonStyles();

  const formik = CustomHookForFormik(
    formData,
    submittedData,
    setIsAddUpdate,
    setIsSendEMailPopup,
    emailChecked,
    setSubmittedData,
    type,
    setData,
    setEditData,
    editData,
    filterEmailData
  );
  React.useEffect(() => {
    if (type == "update" || type == "history") {
      formik.setFieldValue("agent", submittedData.agent);
      let groupTemp = formData?.group?.filter((x: any) =>
        x?.userGroupList?.find(
          (y: any) =>
            y?.agent?.id === submittedData?.agent?.id &&
            y?.isActive == true &&
            y?.isDefault == true
        )
      );

      if (priorityIdFromUrl) {
        let statusTemp = formData.status.find(
          (x: any) => x.name === "InProgress"
        );
        formik.setFieldValue("status", statusTemp);
      } else {
        formik.setFieldValue("status", submittedData.status);
      }
    }
    if (type == "add") {
      formik.setFieldValue("agent", submittedData.agent);

      let groupTemp = formData?.group?.filter((x: any) =>
        x?.userGroupList?.find(
          (y: any) =>
            y?.agent?.id === submittedData?.agent?.id &&
            y?.isActive == true &&
            y?.isDefault == true
        )
      );
      console.log("formData?.group?.currAgent---", formData);
      formik.setFieldValue("group", { ...groupTemp[0] });
      console.log("formData?.group?.userGroupList", { ...groupTemp[0] });
    }
    if (type == "send-email") {
      let temp: any = [];
      formData.template.map((ele: any) => {
        if (ele.actions == "QuickEmail") {
          temp.push(ele);
        }
      });
      temp.length == 1 && formik.setFieldValue("template", { ...temp[0] });
      formik.setFieldTouched("template", false);
      formik.setFieldValue("filteredTemplate", temp);
    }
    if (type == "update" || type == "history") {
      let temp: any = [];
      formData.template.map((ele: any) => {
        if (ele.actions == "AddUpdate") {
          temp.push(ele);
        }
      });
      temp.length == 1 && formik.setFieldValue("template", temp);
      console.log("sjjsjjsjs", temp);
      formik.setFieldValue("filteredTemplate", temp);
    }
  }, [submittedData, formData, priorityIdFromUrl, currAgent]);

  

  React.useEffect(() => {
    dispatch(getPermission());
    dispatch(getEmailSettings());
  }, []);

  React.useEffect(() => {
    console.log("isEmailShow", isEmailShow);
    if (isEmailShow?.length > 0) {
      setEmailChecked(true);
    } else {
      setEmailChecked(false);
    }
  }, [isEmailShow]);

  React.useEffect(() => {
    if (type == "history" || type == "quick-update") {
      console.log("formData?.group", formData);
      let agentTemp = formData?.agent.find(
        (x: any) => x.id === editData?.ticketLogs?.agentId
      );
      agentTemp && formik.setFieldValue("agent", agentTemp);
      //--------------------------------------------------
      let groupTemp = formData?.group.find(
        (x: any) => x.id === editData?.ticketLogs?.groupId
      );
      formik.setFieldValue("group", groupTemp);
      //----------------------------------------------
      formik.setFieldValue(
        "nextActionDate",
        moment.utc(editData?.ticketLogs?.firstResponseDueDateTime)
      );
      //---------------------------------------------
      let statusTemp = formData?.status.find(
        (x: any) => x.id === editData?.ticketLogs?.statusId
      );
      formik.setFieldValue("status", statusTemp);
    }
    if (type == "add") {
      formik.setFieldValue("status", formData?.currentStatus);
    }
    if (type !== "add") {
      formik.setFieldValue("effortShow", editData?.ticketLogs?.effort);
    }
  }, [formData, editData]);

  React.useEffect(() => {
    let temp: any = [];
    console.log('formik?.values?.group', formik?.values?.group)
    if (formik?.values?.group) {
      formik?.values?.group?.userGroupList?.filter((y: any) => {
        if(y?.isActive){
        let a: any = {
          label: `${y?.agent?.firstName} ${y?.agent?.lastName} (${y?.agent?.userName})`,
          name: y?.firstName,
          value: y?.userId,
          id: y?.userId,
          color: "red",
        };
        temp.push(a);
      }
      });

      formik.setFieldValue("filterAgent", temp);
    }
  }, [formik.values.group]);

  React.useEffect(() => {
    console.log(
      "formik.values?.status",
      formik.values?.status,
      formData.template
    );
    if (type !== "send-email") {
      if (formik.values?.status?.label == "NewtoMe") {
        formik.setFieldValue("template", null);
        let temp: any = [];
        formData.template.map((ele: any) => {
          if (ele.actions == "Assign") {
            temp.push(ele);
          }
        });
        temp.length == 1 && formik.setFieldValue("template", { ...temp[0] });
        formik.setFieldValue("filteredTemplate", temp);
      } else if (formik.values?.status?.label == "Resolved") {
        formik.setFieldValue("template", null);
        let temp: any = [];
        formData.template.map((ele: any) => {
          if (ele.actions == "Resolve") {
            temp.push(ele);
          }
        });
        console.log("ResolvedResolved", temp);

        temp.length == 1 && formik.setFieldValue("template", { ...temp[0] });
        formik.setFieldValue("filteredTemplate", temp);
      } else if (formik.values?.status?.label == "Closed") {
        formik.setFieldValue("template", null);
        let temp: any = [];
        formData.template.map((ele: any) => {
          if (ele.actions == "Close") {
            temp.push(ele);
          }
        });
        temp.length == 1 && formik.setFieldValue("template", { ...temp[0] });
        formik.setFieldValue("filteredTemplate", temp);
      } else if (formik.values?.status?.label == "OnHold") {
        formik.setFieldValue("template", null);
        let temp: any = [];
        formData.template.map((ele: any) => {
          if (ele.actions == "AddUpdate") {
            temp.push(ele);
          }
        });
        temp.length == 1 && formik.setFieldValue("template", { ...temp[0] });
        formik.setFieldValue("filteredTemplate", temp);
      } else if (formik.values?.status?.label == "InProgress") {
        formik.setFieldValue("template", null);
        let temp: any = [];
        formData.template.map((ele: any) => {
          if (ele.actions == "AddUpdate") {
            temp.push(ele);
          }
        });
        temp.length == 1 && formik.setFieldValue("template", { ...temp[0] });
        formik.setFieldValue("filteredTemplate", temp);
      } else {
        formik.setFieldValue("filteredTemplate", []);
        formik.setFieldValue("template", null);
      }
    }
  }, [formik.values.status, formData.template]);
  const FindItem = (list: any, searchItem: any) => {
    let temp = list?.find((x: any) => x?.id == searchItem);
    console.log("temp", temp);
    return temp;
  };

  React.useEffect(() => {
    if (groupFromUrl) {
      let temp = FindItem(formData?.group, groupFromUrl);
      temp !== undefined && formik.setFieldValue("group", temp);
    }
    if (statusFromUrl) {
      let temp = FindItem(formData?.status, statusFromUrl);
      temp !== undefined && formik.setFieldValue("status", temp);
    }
    if (ownerFromUrl) {
      let temp = FindItem(formData?.agent, ownerFromUrl);
      temp !== undefined && formik.setFieldValue("agent", temp);
    }
  }, [groupFromUrl, statusFromUrl, ownerFromUrl, formData]);

  React.useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [emailChecked]);

  return (
    <>
      <CustomDialog
        heading={
          <>
            <div
              style={{
                textAlign: "right",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginRight: "50px",
                alignItems: "center",
              }}
            >
              <div>
                {type == `send-email`
                  ? `Quick email send (${ticketId})`
                  : type == `update`
                  ? `Edit ticket`
                  : type == "history" || type == `quick-update`
                  ? `Quick update (${ticketId})`
                  : `Create new ticket `}
              </div>
              {type !== "add" && (
                <div style={{ fontSize: "10px" }}>
                  Effort: {timeSpanToReadbleFormat(formik?.values?.effortShow)}
                </div>
              )}
            </div>
          </>
        }
        body={
          <>
            <form onSubmit={formik.handleSubmit}>
              {type == "add" && (
                <Grid
                  container
                  columnSpacing={5}
                  rowSpacing={2}
                  columns={{ xs: 1, sm: 1, md: 2 }}
                >
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px" }}>
                      Group<span style={{ color: "red" }}>*</span>
                    </span>
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select a Group"
                      width={"100%"}
                      name="group"
                      isColor={false}
                      isDisabled={false}
                      isError={
                        formik.touched.group && Boolean(formik.errors.group)
                      }
                      defaultValue={formik.values.group}
                      options={formData.group}
                      handleChange={(e: any) => {
                        formik.setFieldValue("group", e);
                        formik.setFieldValue("agent", null);
                      }}
                      isMulti={false}
                    />

                    {formik.touched.group && formik.errors.group ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.group}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px" }}>
                      Agent <span style={{ color: "red" }}>*</span>
                    </span>
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select an agent"
                      width={"auto"}
                      name="agent"
                      isColor={false}
                      isDisabled={false}
                      isError={
                        formik.touched.agent && Boolean(formik.errors.agent)
                      }
                      defaultValue={formik.values.agent}
                      options={formik.values.filterAgent}
                      handleChange={(e: any) => {
                        console.log("agentagentagent", formData);
                        formik.setFieldValue("agent", e);
                        if (e.id == formData.currentAgent.id) {
                          let statusTemp = formData?.status.find(
                            (x: any) => x.name === "InProgress"
                          );
                          formik.setFieldValue("status", statusTemp);
                        } else {
                          let statusTemp = formData?.status.find(
                            (x: any) => x.name === "NewtoMe"
                          );
                          formik.setFieldValue("status", statusTemp);
                        }
                      }}
                      isMulti={false}
                    />
                    {Boolean(formik.touched.agent) && formik.errors.agent ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.agent}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px" }}>
                      Status <span style={{ color: "red" }}>*</span>
                    </span>
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select a status"
                      width={"auto"}
                      name="status"
                      isColor={false}
                      isDisabled={false}
                      isError={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                      defaultValue={formik.values.status}
                      options={formData.status}
                      handleChange={(e: any) => {
                        formik.setFieldValue("status", e);
                      }}
                      isMulti={false}
                    />
                    {Boolean(formik.touched.status) && formik.errors.status ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.status}
                      </span>
                    ) : null}
                  </Grid>

                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px", fontWeight: 900 }}>
                      Effort <span style={{ color: "red" }}>*</span>
                    </span>

                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 10 }}
                    >
                      <div>
                        <span style={{ fontSize: "14px" }}>Day</span>
                        <CustomInput
                          type="number"
                          isDisabled={false}
                          readOnly={false}
                          onClick={null}
                          iconPosition={"start"}
                          icon={false}
                          isError={
                            formik.touched.effortDay &&
                            Boolean(formik.errors.effortDay)
                          }
                          label={"Enter the effort"}
                          name="effortDay"
                          value={formik.values.effortDay}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.effortDay && formik.errors.effortDay
                          }
                          placeholder={"Enter the effort"}
                        />{" "}
                      </div>
                      <div>
                        <span style={{ fontSize: "14px" }}>Hour</span>
                        <CustomInput
                          type="number"
                          isDisabled={false}
                          readOnly={false}
                          onClick={null}
                          iconPosition={"start"}
                          icon={false}
                          isError={
                            formik.touched.effortHour &&
                            Boolean(formik.errors.effortHour)
                          }
                          label={"Enter the effort"}
                          name="effortHour"
                          value={formik.values.effortHour}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.effortHour &&
                            formik.errors.effortHour
                          }
                          placeholder={"Enter the effort"}
                        />{" "}
                      </div>
                      <div>
                        <span style={{ fontSize: "14px" }}>Minute</span>
                        <CustomInput
                          type="number"
                          isDisabled={false}
                          readOnly={false}
                          onClick={null}
                          iconPosition={"start"}
                          icon={false}
                          isError={
                            formik.touched.effortMinute &&
                            Boolean(formik.errors.effortMinute)
                          }
                          label={"Enter the effort"}
                          name="effortMinute"
                          value={formik.values.effortMinute}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.effortMinute &&
                            formik.errors.effortMinute
                          }
                          placeholder={"Enter the effort"}
                        />{" "}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px" }}>Note</span>
                    <CustomInput
                      isDisabled={false}
                      readOnly={false}
                      onClick={null}
                      iconPosition={"start"}
                      icon={false}
                      isError={
                        formik.touched.note && Boolean(formik.errors.note)
                      }
                      label={"Enter the Notes"}
                      name="note"
                      value={formik.values.note}
                      onChange={formik.handleChange}
                      helperText={formik.touched.note && formik.errors.note}
                      placeholder={"Enter the Note"}
                    />{" "}
                  </Grid>

                  <Grid item xs={1} sm={1} md={1} key={1}>
                    {formData?.assetSelected?.length > 0 &&
                      (formik?.values?.status?.name == "Closed" ||
                        formik?.values?.status?.name == "Resolved") && (
                        <>
                          <span style={{ fontSize: "14px", fontWeight: 900 }}>
                            Down time <span style={{ color: "red" }}> *</span>
                          </span>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 10,
                            }}
                          >
                            <div>
                              <span style={{ fontSize: "14px" }}>Day</span>
                              <CustomInput
                                type="number"
                                isDisabled={false}
                                readOnly={false}
                                onClick={null}
                                iconPosition={"start"}
                                icon={false}
                                isError={
                                  formik.touched.downTimeDay &&
                                  Boolean(formik.errors.downTimeDay)
                                }
                                label={"Enter the day"}
                                name="downTimeDay"
                                value={formik.values.downTimeDay}
                                onChange={formik.handleChange}
                                helperText={
                                  formik.touched.downTimeDay &&
                                  formik.errors.downTimeDay
                                }
                                placeholder={"Enter the day"}
                              />{" "}
                            </div>
                            <div>
                              <span style={{ fontSize: "14px" }}>Hour</span>
                              <CustomInput
                                type="number"
                                isDisabled={false}
                                readOnly={false}
                                onClick={null}
                                iconPosition={"start"}
                                icon={false}
                                isError={
                                  formik.touched.downTimeHour &&
                                  Boolean(formik.errors.downTimeHour)
                                }
                                label={"Enter the hour"}
                                name="downTimeHour"
                                value={formik.values.downTimeHour}
                                onChange={formik.handleChange}
                                helperText={
                                  formik.touched.downTimeHour &&
                                  formik.errors.downTimeHour
                                }
                                placeholder={"Enter the hour"}
                              />{" "}
                            </div>
                            <div>
                              <span style={{ fontSize: "14px" }}>Minute</span>
                              <CustomInput
                                type="number"
                                isDisabled={false}
                                readOnly={false}
                                onClick={null}
                                iconPosition={"start"}
                                icon={false}
                                isError={
                                  formik.touched.downTimeMinute &&
                                  Boolean(formik.errors.downTimeMinute)
                                }
                                label={"Enter the minute"}
                                name="downTimeMinute"
                                value={formik.values.downTimeMinute}
                                onChange={formik.handleChange}
                                helperText={
                                  formik.touched.downTimeMinute &&
                                  formik.errors.downTimeMinute
                                }
                                placeholder={"Enter the minute"}
                              />{" "}
                            </div>
                          </div>
                        </>
                      )}
                  </Grid>
                  {isEmailShow?.length > 0 && (
                    <>
                      <Grid item xs={1} sm={1} md={1} key={1}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <Radio
                            setEmailChecked={setEmailChecked}
                            emailChecked={emailChecked}
                          />
                          <span style={{ fontSize: "14px" }}>Send email</span>
                        </div>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} key={1}>
                        {emailChecked && (
                          <>
                            <span style={{ fontSize: "14px" }}>
                              Select a template
                              <span style={{ color: "red" }}>*</span>
                            </span>
                            <CustomSelect
                              isOpen={false}
                              placeholder="Select a Template"
                              width={"100%"}
                              name="template"
                              isColor={false}
                              isDisabled={false}
                              isError={
                                formik.touched.template &&
                                Boolean(formik.errors.template)
                              }
                              defaultValue={formik.values.template}
                              options={formik.values.filteredTemplate}
                              handleChange={(e: any) => {
                                console.log("template", e);
                                formik.setFieldValue("template", e);
                              }}
                              isMulti={false}
                            />
                          </>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
              {type == "update" && (
                <Grid
                  container
                  columnSpacing={5}
                  rowSpacing={2}
                  columns={{ xs: 1, sm: 1, md: 2 }}
                >
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px" }}>
                      Group<span style={{ color: "red" }}>*</span>
                    </span>
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select a Group"
                      width={"100%"}
                      name="group"
                      isColor={false}
                      isDisabled={false}
                      isError={
                        formik.touched.group && Boolean(formik.errors.group)
                      }
                      defaultValue={formik.values.group}
                      options={formData.group}
                      handleChange={(e: any) => {
                        formik.setFieldValue("group", e);
                        formik.setFieldValue("agent", null);
                      }}
                      isMulti={false}
                    />

                    {formik.touched.group && formik.errors.group ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.group}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px" }}>
                      Agent <span style={{ color: "red" }}>*</span>
                    </span>
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select an agent"
                      width={"auto"}
                      name="agent"
                      isColor={false}
                      isDisabled={false}
                      isError={
                        formik.touched.agent && Boolean(formik.errors.agent)
                      }
                      defaultValue={formik.values.agent}
                      options={formik.values.filterAgent}
                      handleChange={(e: any) => {
                        console.log("agentagentagent", formData);
                        formik.setFieldValue("agent", e);
                        if (e.id == formData.currentAgent.id) {
                          let statusTemp = formData?.status.find(
                            (x: any) => x.name === "InProgress"
                          );
                          formik.setFieldValue("status", statusTemp);
                        } else {
                          let statusTemp = formData?.status.find(
                            (x: any) => x.name === "NewtoMe"
                          );
                          formik.setFieldValue("status", statusTemp);
                        }
                      }}
                      isMulti={false}
                    />
                    {Boolean(formik.touched.agent) && formik.errors.agent ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.agent}
                      </span>
                    ) : null}
                  </Grid>

                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px" }}>
                      Status <span style={{ color: "red" }}>*</span>
                    </span>
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select a status"
                      width={"auto"}
                      name="status"
                      isColor={false}
                      isDisabled={false}
                      isError={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                      defaultValue={formik.values.status}
                      options={formData.status}
                      handleChange={(e: any) => {
                        formik.setFieldValue("status", e);
                      }}
                      isMulti={false}
                    />
                    {Boolean(formik.touched.status) && formik.errors.status ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.status}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px", fontWeight: 900 }}>
                      Effort <span style={{ color: "red" }}>*</span>
                    </span>
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 10 }}
                    >
                      <div>
                        <span style={{ fontSize: "14px" }}>Day</span>
                        <CustomInput
                          type="number"
                          isDisabled={false}
                          readOnly={false}
                          onClick={null}
                          iconPosition={"start"}
                          icon={false}
                          isError={
                            formik.touched.effortDay &&
                            Boolean(formik.errors.effortDay)
                          }
                          label={"Enter the effort"}
                          name="effortDay"
                          value={formik.values.effortDay}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.effortDay && formik.errors.effortDay
                          }
                          placeholder={"Enter the effort"}
                        />{" "}
                      </div>
                      <div>
                        <span style={{ fontSize: "14px" }}>Hour</span>
                        <CustomInput
                          type="number"
                          isDisabled={false}
                          readOnly={false}
                          onClick={null}
                          iconPosition={"start"}
                          icon={false}
                          isError={
                            formik.touched.effortHour &&
                            Boolean(formik.errors.effortHour)
                          }
                          label={"Enter the effort"}
                          name="effortHour"
                          value={formik.values.effortHour}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.effortHour &&
                            formik.errors.effortHour
                          }
                          placeholder={"Enter the effort"}
                        />{" "}
                      </div>
                      <div>
                        <span style={{ fontSize: "14px" }}>Minute</span>
                        <CustomInput
                          type="number"
                          isDisabled={false}
                          readOnly={false}
                          onClick={null}
                          iconPosition={"start"}
                          icon={false}
                          isError={
                            formik.touched.effortMinute &&
                            Boolean(formik.errors.effortMinute)
                          }
                          label={"Enter the effort"}
                          name="effortMinute"
                          value={formik.values.effortMinute}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.effortMinute &&
                            formik.errors.effortMinute
                          }
                          placeholder={"Enter the effort"}
                        />{" "}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px" }}>Note</span>
                    <CustomInput
                      isDisabled={false}
                      readOnly={false}
                      onClick={null}
                      iconPosition={"start"}
                      icon={false}
                      isError={
                        formik.touched.note && Boolean(formik.errors.note)
                      }
                      label={"Enter the Notes"}
                      name="note"
                      value={formik.values.note}
                      onChange={formik.handleChange}
                      helperText={formik.touched.note && formik.errors.note}
                      placeholder={"Enter the Note"}
                    />{" "}
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    {formData?.assetSelected?.length > 0 &&
                      (formik?.values?.status?.name == "Closed" ||
                        formik?.values?.status?.name == "Resolved") && (
                        <>
                          <span style={{ fontSize: "14px", fontWeight: 900 }}>
                            Down time <span style={{ color: "red" }}> *</span>
                          </span>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 10,
                            }}
                          >
                            <div>
                              <span style={{ fontSize: "14px" }}>Day</span>
                              <CustomInput
                                type="number"
                                isDisabled={false}
                                readOnly={false}
                                onClick={null}
                                iconPosition={"start"}
                                icon={false}
                                isError={
                                  formik.touched.downTimeDay &&
                                  Boolean(formik.errors.downTimeDay)
                                }
                                label={"Enter the day"}
                                name="downTimeDay"
                                value={formik.values.downTimeDay}
                                onChange={formik.handleChange}
                                helperText={
                                  formik.touched.downTimeDay &&
                                  formik.errors.downTimeDay
                                }
                                placeholder={"Enter the day"}
                              />{" "}
                            </div>
                            <div>
                              <span style={{ fontSize: "14px" }}>Hour</span>
                              <CustomInput
                                type="number"
                                isDisabled={false}
                                readOnly={false}
                                onClick={null}
                                iconPosition={"start"}
                                icon={false}
                                isError={
                                  formik.touched.downTimeHour &&
                                  Boolean(formik.errors.downTimeHour)
                                }
                                label={"Enter the hour"}
                                name="downTimeHour"
                                value={formik.values.downTimeHour}
                                onChange={formik.handleChange}
                                helperText={
                                  formik.touched.downTimeHour &&
                                  formik.errors.downTimeHour
                                }
                                placeholder={"Enter the hour"}
                              />{" "}
                            </div>
                            <div>
                              <span style={{ fontSize: "14px" }}>Minute</span>
                              <CustomInput
                                type="number"
                                isDisabled={false}
                                readOnly={false}
                                onClick={null}
                                iconPosition={"start"}
                                icon={false}
                                isError={
                                  formik.touched.downTimeMinute &&
                                  Boolean(formik.errors.downTimeMinute)
                                }
                                label={"Enter the minute"}
                                name="downTimeMinute"
                                value={formik.values.downTimeMinute}
                                onChange={formik.handleChange}
                                helperText={
                                  formik.touched.downTimeMinute &&
                                  formik.errors.downTimeMinute
                                }
                                placeholder={"Enter the minute"}
                              />{" "}
                            </div>
                          </div>
                        </>
                      )}
                  </Grid>
                  {isEmailShow?.length > 0 && (
                    <>
                      <Grid item xs={1} sm={1} md={1} key={1}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <Radio
                            setEmailChecked={setEmailChecked}
                            emailChecked={emailChecked}
                          />
                          <span style={{ fontSize: "14px" }}>Send email</span>
                        </div>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} key={1}>
                        {emailChecked && (
                          <>
                            <span style={{ fontSize: "14px" }}>
                              Select a template
                              <span style={{ color: "red" }}>*</span>
                            </span>
                            <CustomSelect
                              isOpen={false}
                              placeholder="Select a Template"
                              width={"100%"}
                              name="template"
                              isColor={false}
                              isDisabled={false}
                              isError={
                                formik.touched.template &&
                                Boolean(formik.errors.template)
                              }
                              defaultValue={formik.values.template}
                              options={formik.values.filteredTemplate}
                              handleChange={(e: any) => {
                                console.log("template", e);
                                formik.setFieldValue("template", e);
                              }}
                              isMulti={false}
                            />
                          </>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
              {type == "history" && (
                <Grid
                  container
                  columnSpacing={5}
                  rowSpacing={2}
                  columns={{ xs: 1, sm: 1, md: 2 }}
                >
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px" }}>
                      Group<span style={{ color: "red" }}>*</span>
                    </span>
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select a Group"
                      width={"100%"}
                      name="group"
                      isColor={false}
                      isDisabled={false}
                      isError={
                        formik.touched.group && Boolean(formik.errors.group)
                      }
                      defaultValue={formik.values.group}
                      options={formData.group}
                      handleChange={(e: any) => {
                        formik.setFieldValue("group", e);
                        formik.setFieldValue("agent", null);
                      }}
                      isMulti={false}
                    />
                    {formik.touched.group && formik.errors.group ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.group}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px" }}>
                      Agent <span style={{ color: "red" }}>*</span>
                    </span>
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select a ticket type"
                      width={"auto"}
                      name="agent"
                      isColor={false}
                      isDisabled={false}
                      isError={
                        formik.touched.agent && Boolean(formik.errors.agent)
                      }
                      defaultValue={formik.values.agent}
                      options={formik.values.filterAgent}
                      handleChange={(e: any) => {
                        console.log("agentagentagent", formData);
                        formik.setFieldValue("agent", e);
                        if (e.id == formData.currentAgent.id) {
                          let statusTemp = formData?.status.find(
                            (x: any) => x.name === "InProgress"
                          );
                          formik.setFieldValue("status", statusTemp);
                        } else {
                          let statusTemp = formData?.status.find(
                            (x: any) => x.name === "NewtoMe"
                          );
                          formik.setFieldValue("status", statusTemp);
                        }
                      }}
                      isMulti={false}
                    />
                    {Boolean(formik.touched.agent) && formik.errors.agent ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.agent}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px" }}>
                      Status <span style={{ color: "red" }}>*</span>
                    </span>
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select a status"
                      width={"auto"}
                      name="status"
                      isColor={false}
                      isDisabled={false}
                      isError={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                      defaultValue={formik.values.status}
                      options={formData.status}
                      handleChange={(e: any) => {
                        formik.setFieldValue("status", e);
                      }}
                      isMulti={false}
                    />
                    {Boolean(formik.touched.status) && formik.errors.status ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.status}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px", fontWeight: 900 }}>
                      Effort <span style={{ color: "red" }}>*</span>
                    </span>
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 10 }}
                    >
                      <div>
                        <span style={{ fontSize: "14px" }}>Day</span>
                        <CustomInput
                          type="number"
                          isDisabled={false}
                          readOnly={false}
                          onClick={null}
                          iconPosition={"start"}
                          icon={false}
                          isError={
                            formik.touched.effortDay &&
                            Boolean(formik.errors.effortDay)
                          }
                          label={"Enter the effort"}
                          name="effortDay"
                          value={formik.values.effortDay}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.effortDay && formik.errors.effortDay
                          }
                          placeholder={"Enter the effort"}
                        />{" "}
                      </div>
                      <div>
                        <span style={{ fontSize: "14px" }}>Hour</span>
                        <CustomInput
                          type="number"
                          isDisabled={false}
                          readOnly={false}
                          onClick={null}
                          iconPosition={"start"}
                          icon={false}
                          isError={
                            formik.touched.effortHour &&
                            Boolean(formik.errors.effortHour)
                          }
                          label={"Enter the effort"}
                          name="effortHour"
                          value={formik.values.effortHour}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.effortHour &&
                            formik.errors.effortHour
                          }
                          placeholder={"Enter the effort"}
                        />{" "}
                      </div>
                      <div>
                        <span style={{ fontSize: "14px" }}>Minute</span>
                        <CustomInput
                          type="number"
                          isDisabled={false}
                          readOnly={false}
                          onClick={null}
                          iconPosition={"start"}
                          icon={false}
                          isError={
                            formik.touched.effortMinute &&
                            Boolean(formik.errors.effortMinute)
                          }
                          label={"Enter the effort"}
                          name="effortMinute"
                          value={formik.values.effortMinute}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.effortMinute &&
                            formik.errors.effortMinute
                          }
                          placeholder={"Enter the effort"}
                        />{" "}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px" }}>Note</span>
                    <CustomInput
                      isDisabled={false}
                      readOnly={false}
                      onClick={null}
                      iconPosition={"start"}
                      icon={false}
                      isError={
                        formik.touched.note && Boolean(formik.errors.note)
                      }
                      label={"Enter the Notes"}
                      name="note"
                      value={formik.values.note}
                      onChange={formik.handleChange}
                      helperText={formik.touched.note && formik.errors.note}
                      placeholder={"Enter the Note"}
                    />{" "}
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    {editData?.ticketLogs?.ticketLogAssetList?.length > 0 &&
                      (formik?.values?.status?.name == "Closed" ||
                        formik?.values?.status?.name == "Resolved") && (
                        <>
                          <span style={{ fontSize: "14px", fontWeight: 900 }}>
                            Down time <span style={{ color: "red" }}> *</span>
                          </span>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 10,
                            }}
                          >
                            <div>
                              <span style={{ fontSize: "14px" }}>Day</span>
                              <CustomInput
                                type="number"
                                isDisabled={false}
                                readOnly={false}
                                onClick={null}
                                iconPosition={"start"}
                                icon={false}
                                isError={
                                  formik.touched.downTimeDay &&
                                  Boolean(formik.errors.downTimeDay)
                                }
                                label={"Enter the day"}
                                name="downTimeDay"
                                value={formik.values.downTimeDay}
                                onChange={formik.handleChange}
                                helperText={
                                  formik.touched.downTimeDay &&
                                  formik.errors.downTimeDay
                                }
                                placeholder={"Enter the day"}
                              />{" "}
                            </div>
                            <div>
                              <span style={{ fontSize: "14px" }}>Hour</span>
                              <CustomInput
                                type="number"
                                isDisabled={false}
                                readOnly={false}
                                onClick={null}
                                iconPosition={"start"}
                                icon={false}
                                isError={
                                  formik.touched.downTimeHour &&
                                  Boolean(formik.errors.downTimeHour)
                                }
                                label={"Enter the hour"}
                                name="downTimeHour"
                                value={formik.values.downTimeHour}
                                onChange={formik.handleChange}
                                helperText={
                                  formik.touched.downTimeHour &&
                                  formik.errors.downTimeHour
                                }
                                placeholder={"Enter the hour"}
                              />{" "}
                            </div>
                            <div>
                              <span style={{ fontSize: "14px" }}>Minute</span>
                              <CustomInput
                                type="number"
                                isDisabled={false}
                                readOnly={false}
                                onClick={null}
                                iconPosition={"start"}
                                icon={false}
                                isError={
                                  formik.touched.downTimeMinute &&
                                  Boolean(formik.errors.downTimeMinute)
                                }
                                label={"Enter the minute"}
                                name="downTimeMinute"
                                value={formik.values.downTimeMinute}
                                onChange={formik.handleChange}
                                helperText={
                                  formik.touched.downTimeMinute &&
                                  formik.errors.downTimeMinute
                                }
                                placeholder={"Enter the minute"}
                              />{" "}
                            </div>
                          </div>
                        </>
                      )}
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    {!(
                      formik?.values?.status?.name == "Resolved" ||
                      formik?.values?.status?.name == "Closed"
                    ) && (
                      <>
                        <span style={{ fontSize: "14px" }}>
                          First action Date
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        <CustomDatePicker
                          isDisabled={false}
                          onHandleChange={(e: any) => {
                            formik.setFieldValue("nextActionDate", e);
                          }}
                          value={formik.values.nextActionDate}
                          isError={
                            formik.touched.nextActionDate &&
                            Boolean(formik.errors.nextActionDate)
                          }
                          helperText={
                            formik.touched.nextActionDate &&
                            formik.errors.nextActionDate
                          }
                          name={"nextActionDate"}
                        />
                      </>
                    )}
                  </Grid>
                  {isEmailShow?.length > 0 && (
                    <Grid item xs={1} sm={1} md={1} key={1}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <Radio
                          setEmailChecked={setEmailChecked}
                          emailChecked={emailChecked}
                        />
                        <span style={{ fontSize: "14px" }}>Send email</span>
                      </div>
                    </Grid>
                  )}

                  <Grid item xs={1} sm={1} md={1} key={1}>
                    {!(
                      formik?.values?.status?.name == "Resolved" ||
                      formik?.values?.status?.name == "Closed"
                    ) && (
                      <>
                        <span style={{ fontSize: "14px" }}>
                          First action Description
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </span>
                        <CustomInput
                          isDisabled={false}
                          readOnly={false}
                          onClick={null}
                          iconPosition={"start"}
                          icon={false}
                          isError={
                            formik.touched.nextActionDesc &&
                            Boolean(formik.errors.nextActionDesc)
                          }
                          label={"Enter the First Action Description"}
                          name="nextActionDesc"
                          value={formik.values.nextActionDesc}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.nextActionDesc &&
                            formik.errors.nextActionDesc
                          }
                          placeholder={"Eg: Please look into this issue"}
                        />
                      </>
                    )}
                  </Grid>

                  {isEmailShow?.length > 0 && (
                    <Grid item xs={1} sm={1} md={1} key={1} ref={messageRef}>
                      {emailChecked && (
                        <>
                          <span style={{ fontSize: "14px" }}>
                            Select a template
                            <span style={{ color: "red" }}>*</span>
                          </span>
                          <CustomSelect
                            isOpen={false}
                            placeholder="Select a Template"
                            width={"100%"}
                            name="template"
                            isColor={false}
                            isDisabled={false}
                            isError={
                              formik.touched.template &&
                              Boolean(formik.errors.template)
                            }
                            defaultValue={formik.values.template}
                            options={formik.values.filteredTemplate}
                            handleChange={(e: any) => {
                              console.log("template", e);
                              formik.setFieldValue("template", e);
                            }}
                            isMulti={false}
                          />
                        </>
                      )}
                    </Grid>
                  )}
                </Grid>
              )}
              {type == "quick-update" && (
                <Grid
                  container
                  columnSpacing={5}
                  rowSpacing={2}
                  columns={{ xs: 1, sm: 1, md: 2 }}
                >
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px" }}>
                      Group<span style={{ color: "red" }}>*</span>
                    </span>
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select a Group"
                      width={"100%"}
                      name="group"
                      isColor={false}
                      isDisabled={false}
                      isError={
                        formik.touched.group && Boolean(formik.errors.group)
                      }
                      defaultValue={formik.values.group}
                      options={formData.group}
                      handleChange={(e: any) => {
                        formik.setFieldValue("group", e);
                        formik.setFieldValue("agent", null);
                      }}
                      isMulti={false}
                    />
                    {formik.touched.group && formik.errors.group ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.group}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px" }}>
                      Agent <span style={{ color: "red" }}>*</span>
                    </span>
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select a ticket type"
                      width={"auto"}
                      name="agent"
                      isColor={false}
                      isDisabled={false}
                      isError={
                        formik.touched.agent && Boolean(formik.errors.agent)
                      }
                      defaultValue={formik.values.agent}
                      options={formik.values.filterAgent}
                      handleChange={(e: any) => {
                        console.log("agentagentagent", formData);
                        formik.setFieldValue("agent", e);

                        if (e.id == formData.currentAgent.id) {
                          let statusTemp = formData?.status.find(
                            (x: any) => x.name === "InProgress"
                          );
                          formik.setFieldValue("status", statusTemp);
                        } else {
                          let statusTemp = formData?.status.find(
                            (x: any) => x.name === "NewtoMe"
                          );
                          formik.setFieldValue("status", statusTemp);
                        }
                      }}
                      isMulti={false}
                    />
                    {Boolean(formik.touched.agent) && formik.errors.agent ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.agent}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px" }}>
                      Status <span style={{ color: "red" }}>*</span>
                    </span>
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select a status"
                      width={"auto"}
                      name="status"
                      isColor={false}
                      isDisabled={false}
                      isError={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                      defaultValue={formik.values.status}
                      options={formData.status}
                      handleChange={(e: any) => {
                        formik.setFieldValue("status", e);
                      }}
                      isMulti={false}
                    />
                    {Boolean(formik.touched.status) && formik.errors.status ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.status}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px", fontWeight: 900 }}>
                      Effort <span style={{ color: "red" }}>*</span>
                    </span>
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 10 }}
                    >
                      <div>
                        <span style={{ fontSize: "14px" }}>Day</span>
                        <CustomInput
                          type="number"
                          isDisabled={false}
                          readOnly={false}
                          onClick={null}
                          iconPosition={"start"}
                          icon={false}
                          isError={
                            formik.touched.effortDay &&
                            Boolean(formik.errors.effortDay)
                          }
                          label={"Enter the effort"}
                          name="effortDay"
                          value={formik.values.effortDay}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.effortDay && formik.errors.effortDay
                          }
                          placeholder={"Enter the effort"}
                        />{" "}
                      </div>
                      <div>
                        <span style={{ fontSize: "14px" }}>Hour</span>
                        <CustomInput
                          type="number"
                          isDisabled={false}
                          readOnly={false}
                          onClick={null}
                          iconPosition={"start"}
                          icon={false}
                          isError={
                            formik.touched.effortHour &&
                            Boolean(formik.errors.effortHour)
                          }
                          label={"Enter the effort"}
                          name="effortHour"
                          value={formik.values.effortHour}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.effortHour &&
                            formik.errors.effortHour
                          }
                          placeholder={"Enter the effort"}
                        />{" "}
                      </div>
                      <div>
                        <span style={{ fontSize: "14px" }}>Minute</span>
                        <CustomInput
                          type="number"
                          isDisabled={false}
                          readOnly={false}
                          onClick={null}
                          iconPosition={"start"}
                          icon={false}
                          isError={
                            formik.touched.effortMinute &&
                            Boolean(formik.errors.effortMinute)
                          }
                          label={"Enter the effort"}
                          name="effortMinute"
                          value={formik.values.effortMinute}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.effortMinute &&
                            formik.errors.effortMinute
                          }
                          placeholder={"Enter the effort"}
                        />{" "}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    <span style={{ fontSize: "14px" }}>Note</span>
                    <CustomInput
                      isDisabled={false}
                      readOnly={false}
                      onClick={null}
                      iconPosition={"start"}
                      icon={false}
                      isError={
                        formik.touched.note && Boolean(formik.errors.note)
                      }
                      label={"Enter the Notes"}
                      name="note"
                      value={formik.values.note}
                      onChange={formik.handleChange}
                      helperText={formik.touched.note && formik.errors.note}
                      placeholder={"Enter the Note"}
                    />{" "}
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    {editData?.ticketLogs?.ticketLogAssetList?.length > 0 &&
                      (formik?.values?.status?.name == "Closed" ||
                        formik?.values?.status?.name == "Resolved") && (
                        <>
                          <span style={{ fontSize: "14px", fontWeight: 900 }}>
                            Down time <span style={{ color: "red" }}> *</span>
                          </span>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 10,
                            }}
                          >
                            <div>
                              <span style={{ fontSize: "14px" }}>Day</span>
                              <CustomInput
                                type="number"
                                isDisabled={false}
                                readOnly={false}
                                onClick={null}
                                iconPosition={"start"}
                                icon={false}
                                isError={
                                  formik.touched.downTimeDay &&
                                  Boolean(formik.errors.downTimeDay)
                                }
                                label={"Enter the day"}
                                name="downTimeDay"
                                value={formik.values.downTimeDay}
                                onChange={formik.handleChange}
                                helperText={
                                  formik.touched.downTimeDay &&
                                  formik.errors.downTimeDay
                                }
                                placeholder={"Enter the day"}
                              />{" "}
                            </div>
                            <div>
                              <span style={{ fontSize: "14px" }}>Hour</span>
                              <CustomInput
                                type="number"
                                isDisabled={false}
                                readOnly={false}
                                onClick={null}
                                iconPosition={"start"}
                                icon={false}
                                isError={
                                  formik.touched.downTimeHour &&
                                  Boolean(formik.errors.downTimeHour)
                                }
                                label={"Enter the hour"}
                                name="downTimeHour"
                                value={formik.values.downTimeHour}
                                onChange={formik.handleChange}
                                helperText={
                                  formik.touched.downTimeHour &&
                                  formik.errors.downTimeHour
                                }
                                placeholder={"Enter the hour"}
                              />{" "}
                            </div>
                            <div>
                              <span style={{ fontSize: "14px" }}>Minute</span>
                              <CustomInput
                                type="number"
                                isDisabled={false}
                                readOnly={false}
                                onClick={null}
                                iconPosition={"start"}
                                icon={false}
                                isError={
                                  formik.touched.downTimeMinute &&
                                  Boolean(formik.errors.downTimeMinute)
                                }
                                label={"Enter the minute"}
                                name="downTimeMinute"
                                value={formik.values.downTimeMinute}
                                onChange={formik.handleChange}
                                helperText={
                                  formik.touched.downTimeMinute &&
                                  formik.errors.downTimeMinute
                                }
                                placeholder={"Enter the minute"}
                              />{" "}
                            </div>
                          </div>
                        </>
                      )}
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    {!(
                      formik?.values?.status?.name == "Resolved" ||
                      formik?.values?.status?.name == "Closed"
                    ) && (
                      <>
                        <span style={{ fontSize: "14px" }}>
                          First action Date
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        <CustomDatePicker
                          isDisabled={false}
                          onHandleChange={(e: any) => {
                            formik.setFieldValue("nextActionDate", e);
                          }}
                          value={formik.values.nextActionDate}
                          isError={
                            formik.touched.nextActionDate &&
                            Boolean(formik.errors.nextActionDate)
                          }
                          helperText={
                            formik.touched.nextActionDate &&
                            formik.errors.nextActionDate
                          }
                          name={"nextActionDate"}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    {!(
                      formik?.values?.status?.name == "Resolved" ||
                      formik?.values?.status?.name == "Closed"
                    ) && (
                      <>
                        <span style={{ fontSize: "14px" }}>
                          First action Description
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </span>
                        <CustomInput
                          isDisabled={false}
                          readOnly={false}
                          onClick={null}
                          iconPosition={"start"}
                          icon={false}
                          isError={
                            formik.touched.nextActionDesc &&
                            Boolean(formik.errors.nextActionDesc)
                          }
                          label={"Enter the First Action Description"}
                          name="nextActionDesc"
                          value={formik.values.nextActionDesc}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.nextActionDesc &&
                            formik.errors.nextActionDesc
                          }
                          placeholder={"Eg: Please look into this issue"}
                        />
                      </>
                    )}
                  </Grid>

                  {/* <Grid item xs={1} sm={1} md={1} key={1}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Radio
                        setEmailChecked={setEmailChecked}
                        emailChecked={emailChecked}
                      />
                      <span style={{ fontSize: "14px" }}>Send email</span>
                    </div>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} key={1}>
                    {emailChecked && (
                      <>
                        <span style={{ fontSize: "14px" }}>
                          Select a template
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        <CustomSelect
                          isOpen={false}
                          placeholder="Select a Template"
                          width={"100%"}
                          name="template"
                          isColor={false}
                          isDisabled={false}
                          isError={
                            formik.touched.template &&
                            Boolean(formik.errors.template)
                          }
                          defaultValue={formik.values.template}
                          options={formik.values.filteredTemplate}
                          handleChange={(e: any) => {
                            console.log("template", e);
                            formik.setFieldValue("template", e);
                          }}
                          isMulti={false}
                        />
                      </>
                    )}
                  </Grid> */}
                </Grid>
              )}
              {type == "send-email" && (
                <Grid
                  container
                  columnSpacing={5}
                  rowSpacing={2}
                  columns={{ xs: 1, sm: 1, md: 2 }}
                >
                  <Grid item xs={1} sm={1} md={2} key={1}>
                    <>
                      <span style={{ fontSize: "14px" }}>
                        Select a template<span style={{ color: "red" }}>*</span>
                      </span>
                      <CustomSelect
                        isOpen={false}
                        placeholder="Select a Template"
                        width={"100%"}
                        name="template"
                        isColor={false}
                        isDisabled={false}
                        isError={
                          formik.touched.template &&
                          Boolean(formik.errors.template)
                        }
                        defaultValue={formik.values.template}
                        options={formik.values.filteredTemplate}
                        handleChange={(e: any) => {
                          console.log("template", e);
                          formik.setFieldValue("template", e);
                        }}
                        isMulti={false}
                      />
                    </>
                  </Grid>
                </Grid>
              )}
            </form>
          </>
        }
        open={isAddUpdate}
        handleClose={() => {
          setIsAddUpdate(false);
        }}
        footer={
          <>
            <div style={{ display: "flex", gap: 10 }}>
              <BasicButton
                onClick={() => {
                  setIsAddUpdate(false);
                }}
                type={"cancel"}
                isLoading={false}
                isDisabled={false}
                color="primary"
                size="large"
                label={"Cancel"}
                variant={"outlined"}
                endIcon={<CloseIcon sx={{ width: "20px" }} />}
              />
              <form>
                <BasicButton
                  onClick={formik.handleSubmit}
                  type="submit"
                  label={"Save"}
                  isLoading={false}
                  isDisabled={false}
                  color="primary"
                  size="large"
                  variant={"contained"}
                  endIcon={<SaveIcon sx={{ width: "20px" }} />}
                />
              </form>
            </div>
          </>
        }
      />
    </>
  );
};
export default AddUpdate;
