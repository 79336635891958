const initialState = {
  Data: [],
  showLoading: false,
  loggedInAgent : {},
  isAgentAdded:"",
  singleAgent:{},
  saveProfile:"",
  saveOrgProfile:"",
};

const agentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "successCall":
      return {
        ...state,
        isAgentAdded: action.payload,
      };
    case "getAgent":
      return {
        ...state,
        Data: action.payload,
      };
    case "saveProfile":
        return {
          ...state,
          saveProfile: action.payload,
        };
    case "saveOrgProfile":
          return {
            ...state,
            saveOrgProfile: action.payload,
          };
    case "singleAgent":
      return {
        ...state,
        singleAgent: action.payload,
      };
    case "LOGGED_IN_AGENT":
      return{
        ...state,
        loggedInAgent: action.payload,
      };
    case "updateAgent":
    case "multipalUpdateAgent":
    case "softDeleteAgent":
      return {
        ...state,
      };
    case "deleteAgent":
      return {
        ...state,
      };
    case "loading":
      return {
        ...state,
        showLoading: action.payload,
      };
    default:
      return state;
  }
};

export default agentReducer;
