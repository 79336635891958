import { Box, Button, Grid } from "@mui/material";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { ReactSortable } from "react-sortablejs";
import { getCategory } from "../../redux/actions/SystemManagement/AddCategory";
import { addWebForm, editWebFormById } from "../../redux/actions/WebForms/webForms";
import { WebFormInputBox } from "./FieldTypes/InputBox";
import { WebFormTextArea } from "./FieldTypes/TextArea";
import GeneralProperties from "./GeneralProperties";
import { StyledBlockWrapper, TrialWebForm1 } from "./TrialWebForm";
import WebFormProperties from "./WebFormProperties";
import { WebFormDropDown } from "./FieldTypes/DropDown";
import WebFieldDropZone from "./WebFieldDropZone";
import { iWebFormField } from "./schema";
export const FieldsContext = createContext<any>("webFormFields");

function WebFormWorkspace({type,editValue}:{type:string,editValue:any}) {
  const navigate = useNavigate();
  const param = useParams();
  const dispatch = useDispatch();
  const [blocks, setBlocks] = useState<any>(editValue?.webformFieldList || []);


  const [editFieldIndex, setEditFieldIndex] = useState<null | number>(null);
  const [editFieldVal, setEditFieldVal] = useState<null | number>(null);


  const cancelHandler = () =>{
    navigate(type==="Ticket" ? '/webFormList' : "/SurveyTemplates")
  }
  
  const submitHandler = useCallback((titleVal:string="",description:string="",ticketType:any=0,categoryVal:any=0) =>{
    console.log("submitHandler",blocks, titleVal,ticketType,categoryVal)
    // return;
    const fieldsList:iWebFormField[] = blocks.map((i: any,index:number) => {
      return {
        ...i,
        id: i?.oldId || 0,
        webFormId: param.id ? +param.id : 0,
        fieldId: `${i.id}`,
        ranking : index + 1
      }
    });
    const payload = {
      "id": param.id ? +param.id : 0,
      "name": titleVal,
      "description": description,
      "ticketTypeId" : ticketType,
      "categoryId": categoryVal,
      "type" : type,
      "webformFieldList": fieldsList,
      "isActive": true,
    }
    // return
    if (param.id) {
      dispatch(editWebFormById(payload, cancelHandler))
    } else {
      dispatch(addWebForm(payload, cancelHandler))
    }
  },[blocks])
  
  
  const generalForm = useMemo(()=><GeneralProperties type={type} initialVal={editValue} submitHandler={submitHandler}/>,[submitHandler,type,editValue])
  return (
    <Box className="white_bg webforms">
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          {(!param?.id || (param?.id && editValue)) && generalForm}
        </Grid>
        <Grid item md={12} xs={12}><hr /></Grid>


        <FieldsContext.Provider value={{ blocks, setBlocks, editFieldIndex, setEditFieldIndex, editFieldVal,setEditFieldVal }}>


          <TrialWebForm1 />

          <Grid item md={7} xs={12} className={`dropableWebWorkspace ${editFieldIndex !== null ? "disabled" : ""}`}>
            <WebFieldDropZone />
          </Grid>

          {editFieldIndex !== null && <WebFormProperties />}
        </FieldsContext.Provider>
      </Grid>

    </Box>
  );
}

export default WebFormWorkspace;