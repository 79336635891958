import Joi from "joi";

const oldPassword = Joi.string()
  .trim()
  .max(30)
  .messages({
    "string.empty": "Current Password is required.",
    "any.required": "Current Password is required.",
    "string.max":
      "Current Password length must be less than or equal to 30 characters.",
});

const newPasswordSchema = Joi.string()
    .min(8)
    .max(30)
    .required()
    .pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[^a-zA-Z\\d\\s]).{8,}$'))
    .messages({
        'string.base': ' Password must be a string',
        'string.empty': ' Password cannot be empty',
        'string.min': ' Password must be at least {#limit} characters long',
        'string.max': ' Password must be between {#limit} characters long',
        'any.required': ' Password is required',
        'string.pattern.base': ' Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character. Example : Abc@1234',
});
const confirmPasswordSchema = Joi.any()
  .required()
  .valid(Joi.ref('password'))
  .messages({
    'any.only': 'Passwords do not match',
    'any.required': 'Please confirm your password',
  });
export const schemaForPasswordReset = Joi.object({
    currentPassword: oldPassword,
    password: newPasswordSchema,
    confirmPassword:confirmPasswordSchema    
});