import { Box, Skeleton } from "@mui/material";

export const DashboardSkeletonLoader = () => {
    return (
        <>
        <Box sx={{ display: "flex",justifyContent:"space-between", gap: "5px",alignItems:"center", marginBottom:"10px" }}>
            <Skeleton width="5%"
                variant="rounded"
                height={"30px"}
                animation="wave"
            ></Skeleton>
            <Skeleton width="20%"
                variant="rounded"
                height={"20px"}
                animation="wave"
            ></Skeleton>
            <Skeleton width="25%"
                variant="rounded"
                height={"20px"}
                animation="wave"
            ></Skeleton>
            <Box sx={{ display: "flex",alignItems:"center" }}>
                <Skeleton variant="circular" height={"35px"} width={"35px"}></Skeleton>
                <Skeleton variant="circular" height={"35px"} width={"35px"}></Skeleton>
                <Skeleton variant="circular" height={"35px"} width={"35px"}></Skeleton>
            </Box>
            <Skeleton width="25%"
                variant="rounded"
                height={"20px"}
                animation="wave"
            ></Skeleton>
            <Box sx={{ display: "flex", gap:"2px",alignItems:"center" }}>
                <Skeleton variant="rounded" height={"35px"} width={"35px"}></Skeleton>
                <Skeleton variant="rounded" height={"35px"} width={"35px"}></Skeleton>
                <Skeleton variant="rounded" height={"35px"} width={"35px"}></Skeleton>
            </Box>

         </Box>
        <Box sx={{ display: "flex",paddingBottom:"15px", gap: "15px" }}>
            <Skeleton width="25%"
                variant="rounded"
                height={"200px"}
                animation="wave"
            ></Skeleton>
            <Skeleton width="25%"
                variant="rounded"
                height={"200px"}
                animation="wave"
            ></Skeleton>
            <Skeleton width="50%"
                variant="rounded"
                height={"200px"}
                animation="wave"
            ></Skeleton>
        </Box>
        <Skeleton width="100%"
                variant="rounded"
                height={"400px"}
                animation="wave"
        ></Skeleton>
        </>
    )
}
export const PrioritySkeletonLoader = () => {
    return (
        <>
        <Box sx={{ display: "flex",paddingBottom:"15px", gap: "15px" }}>
            <Skeleton width="20%"
                variant="rounded"
                height={"40px"}
                animation="wave"
            ></Skeleton>
            <Skeleton width="20%"
                variant="rounded"
                height={"40px"}
                animation="wave"
            ></Skeleton>
            <Skeleton width="20%"
                variant="rounded"
                height={"40px"}
                animation="wave"
            ></Skeleton>
            <Skeleton width="20%"
                variant="rounded"
                height={"40px"}
                animation="wave"
            ></Skeleton>
            <Skeleton width="20%"
                variant="rounded"
                height={"40px"}
                animation="wave"
            ></Skeleton>
        </Box>
        <Box sx={{ display: "flex",paddingBottom:"15px", gap: "15px" }}>
            <Skeleton width="20%"
                variant="rounded"
                height={"200px"}
                animation="wave"
            ></Skeleton>
            <Skeleton width="20%"
                variant="rounded"
                height={"200px"}
                animation="wave"
            ></Skeleton>
            <Skeleton width="20%"
                variant="rounded"
                height={"200px"}
                animation="wave"
            ></Skeleton>
            <Skeleton width="20%"
                variant="rounded"
                height={"200px"}
                animation="wave"
            ></Skeleton>
            <Skeleton width="20%"
                variant="rounded"
                height={"200px"}
                animation="wave"
            ></Skeleton>
        </Box>
        </>
    )
}
export const PriorityColumnSkeletonLoader = () => {
    return (
        <>
            <Skeleton width="100%"
                variant="rounded"
                height={"100px"}
                animation="wave"
            ></Skeleton>
            <br/>
            <Skeleton width="100%"
                variant="rounded"
                height={"100px"}
                animation="wave"
            ></Skeleton>
            <br/>
            <Skeleton width="100%"
                variant="rounded"
                height={"100px"}
                animation="wave"
            ></Skeleton>
            <br/>
            <Skeleton width="100%"
                variant="rounded"
                height={"100px"}
                animation="wave"
            ></Skeleton>
            <br/>
            <Skeleton width="100%"
                variant="rounded"
                height={"100px"}
                animation="wave"
            ></Skeleton>
        </>
    )
}
export const NewBoxSkeletonLoader = () => {
    return (
        <>
        <Box sx={{ display: "flex",flexWrap:"wrap",justifyContent:"space-between",paddingBottom:"15px", gap: "2px" }}>
        <Skeleton width="48%"
                variant="rounded"
                height={"50px"}
                animation="wave"
        ></Skeleton>
        <Skeleton width="48%"
                variant="rounded"
                height={"50px"}
                animation="wave"
        ></Skeleton>
        <Skeleton width="48%"
                variant="rounded"
                height={"50px"}
                animation="wave"
        ></Skeleton>
        <Skeleton width="48%"
                variant="rounded"
                height={"50px"}
                animation="wave"
        ></Skeleton>
        </Box>
        </>
    )
}
export const GroupListSkeletonLoader = () => {
    return (
        <>
        <Box sx={{ display: "flex",justifyContent:"space-between",paddingBottom:"15px", gap: "2px" }}>
        <Skeleton width="20%"
                variant="rounded"
                height={"50px"}
                animation="wave"
        ></Skeleton>
        <Skeleton width="20%"
                variant="rounded"
                height={"50px"}
                animation="wave"
        ></Skeleton>
        <Skeleton width="20%"
                variant="rounded"
                height={"50px"}
                animation="wave"
        ></Skeleton>
        <Skeleton width="20%"
                variant="rounded"
                height={"50px"}
                animation="wave"
        ></Skeleton>
        <Skeleton width="20%"
                variant="rounded"
                height={"50px"}
                animation="wave"
        ></Skeleton>
        </Box>
        </>
    )
}
export const ModalHeaderSkeleton = () => {
    return (
        <>
        <Box sx={{ display: "flex",justifyContent:"space-between", gap: "5px",alignItems:"center" }}>
            <Box sx={{ display: "flex",justifyContent:"space-between", gap: "5px",alignItems:"center" }}>
                <Skeleton 
                    width="40px"
                    variant="rounded"
                    height={"30px"}
                    animation="wave"
                ></Skeleton>
                <Skeleton 
                    width="150px"
                    variant="rounded"
                    height={"30px"}
                    animation="wave"
                ></Skeleton>
            </Box>

            <Skeleton 
                width="40%"
                variant="rounded"
                height={"25px"}
                animation="wave"
            ></Skeleton>
            
        </Box>
        </>
    )
}
export const ModalAsideSkeleton = () => {
    return (
        <>
            <Box sx={{ display: "flex",flexWrap:"wrap",justifyContent:"space-between", gap: "5px",alignItems:"center" }}>
                <Skeleton 
                    width="48%"
                    variant="rounded"
                    height={"20px"}
                    animation="wave"
                ></Skeleton>
                <Skeleton 
                    width="48%"
                    variant="rounded"
                    height={"20px"}
                    animation="wave"
                ></Skeleton>
            
                <Skeleton 
                    width="48%"
                    variant="rounded"
                    height={"20px"}
                    animation="wave"
                ></Skeleton>
                <Skeleton 
                    width="48%"
                    variant="rounded"
                    height={"20px"}
                    animation="wave"
                ></Skeleton>
            
                <Skeleton 
                    width="48%"
                    variant="rounded"
                    height={"20px"}
                    animation="wave"
                ></Skeleton>
                <Skeleton 
                    width="48%"
                    variant="rounded"
                    height={"20px"}
                    animation="wave"
                ></Skeleton>
            
                <Skeleton 
                    width="48%"
                    variant="rounded"
                    height={"20px"}
                    animation="wave"
                ></Skeleton>
                <Skeleton 
                    width="48%"
                    variant="rounded"
                    height={"20px"}
                    animation="wave"
                ></Skeleton>
            
                <Skeleton 
                    width="48%"
                    variant="rounded"
                    height={"20px"}
                    animation="wave"
                ></Skeleton>
                <Skeleton 
                    width="48%"
                    variant="rounded"
                    height={"20px"}
                    animation="wave"
                ></Skeleton>
            </Box>  
        </>
    )
}
export default DashboardSkeletonLoader;