import Joi from "joi";

const firstNameSchema = Joi.string().trim().min(3).max(100).messages({
    "string.empty": "First Name is required.",
    "any.required": "First Name is required.",
    "string.max": "First Name length must be less than or equal to 100 characters.",
    "string.min": "Min 3 character required.",
});

const lasttNameSchema = Joi.string().trim().min(3).max(100).messages({
    "string.empty": "Last Name is required.",
    "any.required": "Last Name is required.",
    "string.max": "Last Name length must be less than or equal to 100 characters.",
    "string.min": "Min 3 character required.",
});

const emailSchema = Joi.string()
    .trim()
    .email({ tlds: { allow: false } }) // You can adjust this based on your email validation requirements
    .messages({
        "string.empty": "Email is required.",
        "string.email": "Please provide a valid email address.",
        "any.required": "Email is required.",
    });

    const usernameSchema = Joi.string()
    .trim()
    .max(100)
    .messages({
        "string.empty": "Username is required.",
        "string.max": "Username must not exceed 100 characters.",
        "any.required": "Username is required.",
    });

const mobileNumberSchema = Joi.string()
    .trim()
    .regex(/^\d{10,15}$/) // Allowing only numerical values with a length between 10 and 15 digits
    .messages({
        "string.empty": "Mobile number is required.",
        "string.pattern.base": "Please provide a valid mobile number with 10 to 15 numerical digits.",
        "any.required": "Mobile number is required.",
    });
const nameSchema = Joi.string().trim().min(3).max(50).required().messages({
    "string.empty": "Organisation Name is required.",
    "any.required": "Organisation Name is required.",
    "string.max": "Organisation Name length must be less than or equal to 50 characters.",
    "string.min": "Min 3 character required.",
});
const addressLine1Schema = Joi.string().trim().min(3).max(50).required().messages({
    "string.empty": "AdressLine1 is required.",
    "any.required": "AdressLine1 is required.",
    "string.max": "AdressLine1 length must be less than or equal to 50 characters.",
    "string.min": "Min 3 character required.",
});
const addressLine2Schema = Joi.string()
const citySchema = Joi.string().trim().min(3).max(50).required().messages({
    "string.empty": "City is required.",
    "any.required": "City is required.",
    "string.max": "City length must be less than or equal to 50 characters.",
    "string.min": "Min 3 character required.",
});
const postcodeSchema = Joi.string().trim().min(3).max(50).required().messages({
    "string.empty": "Postcode is required.",
    "any.required": "Postcode is required.",
    "string.max": "Postcode length must be less than or equal to 50 characters.",
    "string.min": "Min 3 character required.",
});
const countrySchema = Joi.string().trim().min(1).max(50).required().messages({
    "string.empty": "Country is required.",
    "any.required": "Country is required.",
    "string.max": "Country length must be less than or equal to 50 characters.",
    "string.min": "Min 1 character required.",
});
const coreProductSchema=Joi.number().integer().min(1).messages({
    "number.base": "Core Product ID must be a number.",
    "number.integer": "Core Product ID must be an integer.",
    "number.min": "Core Product is required.",
    "any.required": "Core Product is required.",
});
export const schemaForOrgCreationFormData = Joi.object({
    firstName: firstNameSchema,
    lastName: lasttNameSchema,
    persEmail: emailSchema,
    userName: usernameSchema,
    // mobilePhone: mobileNumberSchema,
    name: nameSchema,
    profEmail: emailSchema,
    profMobile: mobileNumberSchema,
    addressLine1:addressLine1Schema,
    // addressLine2:addressLine2Schema,
    city:citySchema,
    postcode:postcodeSchema,
    country:countrySchema,
    coreProductId:coreProductSchema
});