
export enum ActionType {
    DASHBOARD_TYPE = 'DASHBOARD_TYPE'
}
export interface IDashboardType {
    type: ActionType.DASHBOARD_TYPE,
    payload: string,
}

export const dashboardType = (type: string) => {
    return {
        type: ActionType.DASHBOARD_TYPE,
        payload: type
    };
}