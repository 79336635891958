import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { createRef, memo, useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { IState } from "../../../redux/reducers/rootReducers";
import { getTicketByID, quickUpdateTicket } from '../../../redux/actions/TicketsManagement/TicketsManagement';
import { TextBoxLatest } from '../../common/TextBoxLatest/TextBoxLatest';
import { iFormData } from '../../../pages/new-ticket/LatestTicket/Type';
import { handleTime, returnFormValues } from '../../helpers/helperFunctions';
import AsyncDropDown from '../../../pages/new-ticket/LatestTicket/AsyncDrodown/AsyncDropDown';
import { getContactsForDropdown } from '../../../redux/actions/ContactManagement/Contact';
import { debounce } from 'lodash';
import { LabelFieldBox } from '../../postLogin/MyLicenses';
import BasicButton from '../../common/button/BasicButton';
import moment from 'moment';
import timeSpanToReadbleFormat from '../../../util/timeSpanToReadbleFormat';
import CustomFormLabel from '../../common/form-label/CustomFormLabel ';
import { getOrganisationsForDropdown } from '../../../redux/actions/CMDB/Organisation';
import CloseCategoryOption from './CloseCategoryOption';
import Radio from '../../common/radio/Radio';
import CustomSelect from '../../common/customSelect/CustomSelect';
import getTemplates from '../../../redux/actions/adminInterfaces/EmailTemplates';


export const GenrateTemplateField = ({
  isEmailShow,
  handleSelectChange,
  value,
  setEmailChecked,
  emailChecked,
  template,
  setTemplate,
  error,
}: {
  isEmailShow: any;
  handleSelectChange: any;
  value: any;
  setEmailChecked: any;
  emailChecked: any;
  template: any;
  setTemplate: any;
  error: any;
}) => {
  useEffect(() => {
    console.log("isEmailShow", isEmailShow);
  }, [isEmailShow]);

  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Radio
            setEmailChecked={setEmailChecked}
            emailChecked={emailChecked}
          />
          <span style={{ fontSize: "14px" }}>Send email</span>
        </div>
        {emailChecked && (
          <>
            <span style={{ fontSize: "14px" }}>
              Select a template
              <span style={{ color: "red" }}>*</span>
            </span>
            <CustomSelect
              isOpen={false}
              placeholder="Select a Template"
              width={"100%"}
              name="template"
              isColor={false}
              isDisabled={false}
              isError={false}
              defaultValue={value}
              options={template}
              handleChange={(e: any) => {
                handleSelectChange(e, "template");
              }}
              isMulti={false}
            />
          </>
        )}
      </>
    </>
  );
};



interface ResolvePopupProps {
  ticketIdWithStatusID: any;
  statusObj: any;
}
const ResolvePopup = memo((props: ResolvePopupProps) => {
  const { ticketIdWithStatusID, statusObj } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [errorObj, setErrorObj] = useState({});
  const [curentTicket, setCurentTicket] = useState<any>({});
  const [emailChecked, setEmailChecked] = useState<boolean>(false);
  const [template, setTemplate] = useState<any>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const [buttonDisabled, setButtonDisabled] = useState<any>(false);


  const TemplatesList = useSelector(
    (state: IState) => state?.EmailTemplatesReducer?.AllEmailTemplates
  );

  useEffect(() => {
    dispatch(getTemplates());
  }, []);

  useEffect(() => {
    console.log("curentTicketpoiuyt", TemplatesList);
    handleTemplateBasesOnstatus();
  }, [statusObj?.[ticketIdWithStatusID?.statusId]?.name]);

  const handleSelectChange = (e: unknown, type: string) => {
    setSelectedTemplate(e);
  };

  const handleTemplateBasesOnstatus = () => {
    if (statusObj?.[ticketIdWithStatusID?.statusId]?.name == "NewtoMe") {
      handleSelectChange(null, "template");
      let temp: any = [];
      TemplatesList.map((ele: any) => {
        if (ele.actions == "Assign") {
          temp.push(ele);
        }
      });
      setTemplate(temp);
      if (temp.length == 1) {
        handleSelectChange(temp, "template");
      } else {
        handleSelectChange(null, "template");
      }
    } else if (
      statusObj?.[ticketIdWithStatusID?.statusId]?.name == "Resolved"
    ) {
      handleSelectChange(null, "template");
      let temp: any = [];
      TemplatesList.map((ele: any) => {
        if (ele.actions == "Resolve") {
          temp.push(ele);
        }
      });
      setTemplate(temp);
      if (temp.length == 1) {
        handleSelectChange(temp[0], "template");
      } else {
        handleSelectChange(null, "template");
      }
    } else if (statusObj?.[ticketIdWithStatusID?.statusId]?.name == "Closed") {
      handleSelectChange(null, "template");
      let temp: any = [];
      TemplatesList.map((ele: any) => {
        if (ele.actions == "Close") {
          temp.push(ele);
        }
      });
      setTemplate(temp);
      if (temp.length == 1) {
        handleSelectChange(temp[0], "template");
      } else {
        handleSelectChange(null, "template");
      }
    } else if (statusObj?.[ticketIdWithStatusID?.statusId]?.name == "OnHold") {
      handleSelectChange(null, "template");
      let temp: any = [];
      TemplatesList.map((ele: any) => {
        if (ele.actions == "AddUpdate") {
          temp.push(ele);
        }
      });
      setTemplate(temp);
      if (temp.length == 1) {
        handleSelectChange(temp[0], "template");
      } else {
        handleSelectChange(null, "template");
      }
    } else if (
      statusObj?.[ticketIdWithStatusID?.statusId]?.name == "InProgress"
    ) {
      handleSelectChange(null, "template");
      let temp: any = [];
      TemplatesList.map((ele: any) => {
        if (ele.actions == "AddUpdate") {
          temp.push(ele);
        }
      });
      console.log("check template", temp);
      setTemplate(temp);

      if (temp.length == 1) {
        handleSelectChange(temp[0], "template");
      } else {
        handleSelectChange(null, "template");
      }
    } else {
    }
  };

  useEffect(() => {
    if (!ticketIdWithStatusID.ticketId) return;
    handleTime.cancel();
    handleTime.setup(() => {
      dispatch(getTicketByID(ticketIdWithStatusID.ticketId, getTicketByIDCB))
    },200)
  }, [ticketIdWithStatusID])
  const getTicketByIDCB = (resStatus: string, res: any) => {
    if (resStatus !== "0") return;
    setCurentTicket(res)
  }
  const onCloseHandler = useCallback(() => {
    setErrorObj({});
    setButtonDisabled(false)
    dispatch({
      type: "RESOLVE_POPUP_INFO",
      payload: {}
    })
  }, [])


  const quickUpdateTicketCB = (res: any) => {
    console.log("quickUpdateTicketCB", res);
    setButtonDisabled(false)
    if (res === "failure") return
    if (emailChecked) {
      dispatch({
        type: "SEND_EMAIL_VIEW",
        payload: {
          ...res,
          template: selectedTemplate,
        },
      });
    }
    onCloseHandler();
  }


  const { contacts, title, description, agent, ticketStatus, group, openDateTime, slaRemaining, dueDateTime, slaRemainingInTicks }: any = curentTicket?.ticketLogs || {};
  const onSubmit = (event: any) => {
    event.preventDefault();
      if (!event.currentTarget) return
      const data: iFormData = returnFormValues(event.currentTarget)
      const validation = validationHelper(data, statusObj?.[ticketIdWithStatusID?.statusId]?.name)
      if (!validation.isValid) {
        setErrorObj(validation.errors);
        return;
      }
      setButtonDisabled(true);

      console.log("onSubmitpreventDefault", data);
      // return;
      dispatch(quickUpdateTicket(
        {
          ...curentTicket, ticketLogs: {
            ...curentTicket?.ticketLogs,
            note: data?.note || "",
            statusId: ticketIdWithStatusID?.statusId,
            ticketStatus: statusObj?.[ticketIdWithStatusID?.statusId],
            contactOrganisationId: +data?.contactOrganisationId || 0,
            thirdPartyRefNo: data?.thirdPartyRefNo || ""
          }
        },
        quickUpdateTicketCB
      ));
  }
  return (
    <>
      {ticketIdWithStatusID?.ticketId > 0 &&
        <Box className={`${classes.popupHolder} ${classes.textWithSpanTitle}`}>
          <Box className="modal-dialog">
            <Box className="modal-content">
              <Box className="modal-header">
                <Box className="row m-0 rsolveTicketTitle txt-ellipsis align-items-center gap15">
                  <Avatar
                    className="sml-avatar"
                    sx={{ height: 25, width: 25 }}
                    alt={contacts?.name || "PP"}
                    src={`${contacts?.profilePicturePath || ""}`}
                  />
                  <Typography className="txtCapitalize ">
                    <span><span className="txt-primary">#{ticketIdWithStatusID?.ticketId} </span> {title} </span>
                  </Typography>
                </Box>
                <Typography className="cursor-pointer" onClick={onCloseHandler}>
                  <CloseIcon className="cursor-pointer" />
                </Typography>
              </Box>
              <Box className="modal-body resolve-popup">
                <Box className="p-1 pr-0">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <LabelFieldBox label={"Title"} value={title} />
                      <LabelFieldBox label={"Description"} showMore={true} value={description} />
                      <LabelFieldBox label={"Primary Contact"} value={contacts?.name} />
                      <LabelFieldBox label={"Group"} value={group?.name} />
                      <LabelFieldBox label={"Agent"} value={`${agent?.firstName || ""} ${agent?.lastName || ""}`} />
                      <LabelFieldBox label={"Open Date"} value={moment(openDateTime).format("L LT")} />
                      <LabelFieldBox label={"Due Date"} value={moment(dueDateTime).format("L LT")} />

                    </Grid>
                    <Grid item xs={12} md={6}>
                      {slaRemaining && <LabelFieldBox className={`sla ${slaRemainingInTicks <= 0 ? "txt-danger" : "txt-success"}`} label={"SLA"} value={ticketStatus?.name === "OnHold" ? "On-Hold" : timeSpanToReadbleFormat(slaRemaining, "ss")} />}
                      <form onSubmit={onSubmit}>
                        {curentTicket?.ticketLogs?.id && <>
                          <ThirdPartyForm curentTicket={curentTicket} statusName={statusObj?.[ticketIdWithStatusID?.statusId]?.name} errorObj={errorObj} unsetError={setErrorObj} />
                          <div style={{ width: "100%" }}>
                            <GenrateTemplateField
                              setEmailChecked={setEmailChecked}
                              emailChecked={emailChecked}
                              isEmailShow={false}
                              handleSelectChange={handleSelectChange}
                              value={selectedTemplate}
                              template={template}
                              setTemplate={setTemplate}
                              error={{
                                isValid: false,
                                errors: {},
                              }}
                            />
                          </div>
                          <CloseCategoryOption />
                          <Box className="pt-2 text-right" style={{ marginTop: "6px" }}>

                            <BasicButton
                              type={"button"}
                              label={"Close"}
                              size="small"
                              variant={"outlined"}
                              onClick={onCloseHandler}
                            /> &nbsp; &nbsp;
                            <BasicButton
                              type={buttonDisabled ? "button" : "submit"}
                              label={buttonDisabled ? "Loading..." : "Submit"}
                              size="small"
                              variant={"contained"}
                            />
                          </Box>
                        </>
                        }
                      </form>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      }
    </>);

})

const ThirdPartyForm = (props: any) => {
  const { errorObj, unsetError, statusName, curentTicket } = props;
  const noteRef: any = createRef();
  const thirdPartyNumRef: any = createRef();
  const dispatch = useDispatch();
  const promiseOptionsForOrg = debounce((inputValue: any, callback: any) => {
    if (inputValue.length > 2) {
      dispatch(getOrganisationsForDropdown(inputValue, callback, true))
    } else {
      callback([]); // Pass options to the callback
    }
  }, 300);
  const [contactOrgVal, setContactOrgVal] = useState(null)
  const resetErrors = debounce((key: string) => {
    unsetError({
      ...errorObj,
      [key]: false
    });
  }, 300)
  useEffect(() => {
    console.log("curentTicketpoiuyt", curentTicket)
    if (thirdPartyNumRef?.current) {
      thirdPartyNumRef.current.value = curentTicket?.ticketLogs?.thirdPartyRefNo || "";
    }
    if (curentTicket?.ticketLogs?.contactOrganisation?.name) {
      setContactOrgVal({
        ...curentTicket?.ticketLogs?.contactOrganisation,
        label: curentTicket?.ticketLogs?.contactOrganisation?.name || "",
        value: curentTicket?.ticketLogs?.contactOrganisation?.name || "",
      })
    }
  }, [curentTicket])
  return (<>
    {statusName === "ThirdParty" &&
      <Box className="thirdPartyBox-popup">
        <CustomFormLabel classNames='sml-txt' labelName={"Choose Third Party"} isMandotary={true} />
        <AsyncDropDown
          promiseOptions={promiseOptionsForOrg}
          name="contactOrganisationId"
          placeHolder="Search and select a Third Party"
          handleChange={(e: any) => {
            resetErrors("contactOrganisationId")
            setContactOrgVal(e)
          }}
          isError={errorObj?.contactOrganisationId ? true : false}
          defaultValue={contactOrgVal}
          customClassNames="sml-txt-dropdown thirdparty-dropdown"
        />
        <p style={{ fontSize: "12px", margin: 0, color: "#c8c8c8" }}>* You need at least 3 characters to search</p>
        {errorObj?.contactOrganisationId && <CustomFormLabel classNames='sml-txt txt-danger' labelName={errorObj?.contactOrganisationId} isMandotary={false} />}

        <TextBoxLatest
          placeholder="Third Party Ref Number"
          ref={thirdPartyNumRef}
          name='thirdPartyRefNo'
          parentClassNames=''
          title='Please Enter Third Party Ref Number'
          isError={errorObj?.thirdPartyRefNo ? true : false}
          errorMsg={errorObj?.thirdPartyRefNo || "Please Enter"}
          onchangeHandler={() => { resetErrors("thirdPartyRefNo") }}
          isMandotary={true}
        />
      </Box>
    }


    <TextBoxLatest
      placeholder="Please Enter"
      ref={noteRef}
      name='note'
      title={statusName !== "ThirdParty" ? 'Please Enter Resolve Notes' : 'Notes'}
      multiline={true}
      parentClassNames=''
      isError={errorObj?.note ? true : false}
      errorMsg={errorObj?.note || "Please Enter"}
      onchangeHandler={() => { resetErrors("note") }}
      isMandotary={statusName === "Resolved"}
    />
  </>)
}


const validationHelper = (payload: iFormData, status: string) => {
  console.log("validationHelper", payload, status)
  let temp: any = {
    isValid: true,
    errors: {}
  }
  if (status !== "Resolved") {
    if (!payload?.thirdPartyRefNo?.trim || payload?.thirdPartyRefNo?.trim() === "") {
      temp.isValid = false;
      temp.errors.thirdPartyRefNo = "Please Enter Third Party Ref Number";
    }
    if (!payload?.contactOrganisationId?.trim || payload?.contactOrganisationId?.trim() === "") {
      temp.isValid = false;
      temp.errors.contactOrganisationId = "Please Choose Third Party Organisation";
    }
  } else {
    if (!payload?.note?.trim || payload?.note?.trim() === "") {
      temp.isValid = false;
      temp.errors.note = "Please Enter Resolve Notes";
    }
  }

  return temp;
}
const useStyles = makeStyles(() =>
  createStyles({
    popupHolder: {
      position: "fixed",
      top: 0,
      left: 0,
      background: "#0005",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      zIndex: 1399,
      "& .modal-dialog": {
        display: "flex",
        height: '100%',
        justifyContent: "center",
        alignItems: "center",
      },
      "& .modal-header": {
        display: "flex",
        alignItems: "center",
        padding: "20px",
        justifyContent: "space-between",
        borderBottom: "1px solid #ddd",
        paddingBottom: "10px"
      },
      "& .modal-body": {
        maxHeight: "calc(100vh - 200px)",
        overflowY: "scroll",
        padding: "0px"
      },
      "& .modal-body > div": {
        margin: "0 !important",
        maxWidth: "calc(100% - 15px)"
      },
      "& .modal-content": {
        background: "#fff",
        width: "100%",
        maxWidth: "700px",
        borderRadius: "5px",
        //padding: "20px",
        "& img.Contactprofile": {
          width: "calc(100% - 10px)",
        },
        "& img": {
          objectFit: "cover"
        }
      }
    },
    textWithSpanTitle: {
      "& .title-txts": {
        lineHeight: '1.5',
        marginBottom: 10,
        fontSize: '13px',
        "& > span": {
          display: 'block',
          fontSize: '11px',
          marginBottom: '2px',
          lineHeight: '1',
          color: "#5559"//mode.link,
        }
      }
    },
    txtWithLink: {
      fontSize: ".7em",
      display: "flex",
      alignItems: "center",
      gap: "5px",
      "& span": {
        wordBreak: "break-all"
      },
      "& a": {
        fontSize: ".7em",
        textDecoration: "none",
        display: "inline-block",
        lineHeight: 1,
        "& svg": {
          fontSize: "12px"
        }
      }
    }
  }))


const mapStateToProps = (state: IState) => {
  return ({
    ticketIdWithStatusID: state?.TicketViewReducer?.resolvePopupId,
    statusObj: state?.TicketsReducer?.alldataStructure?.statusTypesById
  })
};

export default connect(mapStateToProps)(ResolvePopup);