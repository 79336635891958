import axios from "axios"; 
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";
import LanguageData from "../../../configs/LanguageData.json";
export const url = `${configs.BASE_URL}/ContactOrganisation`;
export const OrganisationUrl = `${configs.BASE_URL}/ContactOrganisation`;
const bulkUpdate = `${configs.BASE_URL}/ContactOrganisation/status/change?ids=`;

export const STORE_COMPANY = 'STORE_COMPANY';
export const STORE_COMPANY_STRUCT = 'STORE_COMPANY_STRUCT';
export const configureCompanies = (COMPANIES:any [] = []) => {
  const contactData:any = {}
  contactData.tempOrg = {};
  contactData.tempDept = {};
  contactData.tempDiv = {};
  contactData.tempLoca = {};
  COMPANIES.map((i : any)=>{
    contactData.tempOrg[i.id]=i.name;
    i.locationList.map((j : any)=>{
      if(j?.location?.name){
        contactData.tempLoca[j.locationId] = j.location.name;
      }
    })
    
    i.departmentList.map((j : any)=>{
      if(j?.department?.name){
        contactData.tempDept[j.departmentId]=j.department.name;
      }
    })
    
    i.divisionList.map((j : any)=>{
      if(j.division.name){
        contactData.tempDiv[j.divisionId]=j.division.name
      }
    })
  });
  return contactData;
}
export const removeOrganisation = (id: any) => ({
    type: "REMOVE_ORG",
    payload: id,
});
export const GetOrganisation = (data: any) => ({
    type: STORE_COMPANY,
    payload: data,
});
export const StoreOrganisationLazy = (data: any) => ({
  type: "LOAD_MORE_ORGANISATIONS",
  payload: data,
});
export const GetOrganisationAction = (callback?:any) => {
    return async (dispatch: any) => {
      try {
        const response = await axios(url);
        console.log(response)
        dispatch(GetOrganisation(response.data))
        if(callback) callback(['0', response.data]);
      } catch (e:any) {
          if(callback) callback(['1', e.response])
          console.log(e.response)
      }
    };
};
export const GetOrganisationLazy = (pageNum: Number,isActive:boolean,search:string,callback:any,isThirdParty:boolean) => {
  return async (dispatch: any) => {
    try {
      let count = 50;
      let searchVal = search ? `&search=${search}` : "";
      let response = await axios.get(`${url}?page=${pageNum}&count=${count}&isActive=${isActive}${searchVal}${isThirdParty ? "&isThirdParty=true":""}`);
      console.log("getContactsLazy-e",new Date())
      if(callback) callback(pageNum,response.data, response.data.length === count);
      if(pageNum === 1){
        dispatch(GetOrganisation(response.data))
      } else {
        dispatch(StoreOrganisationLazy(response.data));
      }
    } catch (error:any) {
      console.log(error);
    }
  };
  
}
export const getOrganisationsForDropdown = (searchKey:string,callback:any,isThirdParty:boolean) => {
  return async (dispatch: any) => {
    try {
      let response = await axios.get(`${url}?search=${searchKey}&page=1&count=50&isActive=true${isThirdParty ? "&isThirdParty=true":""}`);
      console.log("getOrganisations-Lazy-e",new Date())
      if(callback) callback(response.data.map((i:any)=>{
        const {id,isVIP, knownAs, name, email } = i;
        return {
          id,
          isVIP, 
          label : name,
          value : id,
        }
      }));
    } catch (error:any) {
      console.log(error);
      if(callback) callback([]);
    }
  };
  
}
export const softDeleteOrganisation = (id: any,status:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${bulkUpdate}${id}&status=${status}`);
      // dispatch(GetOrganisationAction());
      dispatch(removeOrganisation(id));
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_ORGANISATION}${status ? `${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`}`,
          severity: "success",
          open: true,
        },
      });
    } catch (error) {
      return console.log(error);
    }
  };
};
export const multipalUpdateOrganisation = (data: any, ids: any,status:any) => {
  const idMsg = ids.length === undefined ? `${LanguageData.SUCCESS_ORGANISATION}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_ORGANISATION}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  const statusMsg = status ? `${LanguageData.SUCCESS_ORGANISATION}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_ORGANISATION}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  return function (dispatch: any) {
    axios.delete(`${bulkUpdate}${ids}&status=${status}`, data).then((resp) => {
      dispatch({
        type: "multipalUpdateIntuneType",
      });
      dispatch(GetOrganisationAction());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: ids.length === undefined ? idMsg : statusMsg,
          severity: "success",
          open: true,
        },
      });
    });
  };
};