import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import CommonStyles from "../common/CommonStyles";
import { useLocation } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import WebhookOutlinedIcon from "@mui/icons-material/WebhookOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import UnAssignIcon from "./../../assest/icons/unAssign.svg";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import InventoryIcon from '@mui/icons-material/Inventory';
import { useNavigate } from "react-router-dom";
import CustomModalDialog from "../common/customDialog";
import StarsIcon from "@mui/icons-material/Stars";
import CommonAppCarousel from "./commonCarousel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import InfoIcon from '@mui/icons-material/Info';
const SettingsControl: React.FC = () => {
  const navigate = useNavigate();
  const [Data, setData] = React.useState([
    { name: "My organisation", url: "/organisationProfile", access: true },
    { name: "Current Plan", url: "/Settings", access: true },
    { name: "Organisation Settings", url: "/organisationSettings", access: true },
    { name: "Products", url: "/MyProducts", access: true },
    { name: "Security", url: "/Settingss", access: false },
    { name: "Rest API's", url: "/Settingss", access: false },
    { name: "About ", url: "/about", access: true },

  ] as any);
  const [ModalOpen, setModalOpen] = React.useState(false);
  const handleClickClose = () => {
    // ;
    setModalOpen(false);
  };
  const handleClickOpen = () => {
    setModalOpen(true);
  };
  const location = useLocation();
  const useStyles = CommonStyles;
  const classes = useStyles();
  function isActive(pathname: string) {
    let res =
      pathname.replaceAll("/", "") === location.pathname.replaceAll("/", "")
        ? true
        : false;
    return res;
  }
  const launchPage = (e: any, key: number) => {
    // ;
    let { access, url } = Data[key];
    access ? navigate(url) : handleClickOpen();
  };
  const configItems = [
    {
      name: "Advanced account permissions",
      img: "https://cdn.monday.com/images/feature-lock/account-permissions.png",
      desc: "This type of permission allows you to manage easily. Keep control of who can do what on your account by setting up account permissions.",
    },
    {
      name: "Milestones in timeline",
      img: "https://cdn.monday.com/images/feature-lock/milestones.png",
      desc: "Set project milestones to clearly identify critical points in your projects",
    },
  ];
  const [carouselArr, setcarouselArr] = React.useState([] as any);
  const genCarouselArr = () => {
    let items = [];
    items = configItems.map((item) => {
      return (
        <Grid container className={classes.upgradePopup} spacing={3}>
          <Grid item xs={6}>
            <img src={item.img} width="100%" alt="" />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">{item.name}</Typography>
            <p>{item.desc}</p>
          </Grid>
        </Grid>
      );
    });
    setcarouselArr(items);
  };
  useEffect(() => {
    genCarouselArr();
  }, []);
  return (
    <>
      {/* <Typography variant="h6">Admin</Typography> */}
      <List className="configList">
        {Data.map((item: any, key: number) => {
          return (
            <ListItem
              key={key}
              className={[isActive(`/${item.url}`) ? "active" : ""].join(" ")}
            >
              <Box
                onClick={(event) => launchPage(event, key)}
                className={`customBtnLink ${classes.themeColorTxt}`}
              >
                {getIcon(key)} 
                <Typography className={classes.themeColorTxt} variant="caption">
                  {item.name}
                </Typography>
              </Box>
            </ListItem>
          );
        })}
      </List>
      <CustomModalDialog
        title=""
        id="upgradeModal"
        isOpen={ModalOpen}
        onClose={handleClickClose}
        headerContent={
          <>
            <Box className={classes.upgradeModalTitle}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={12} md={8}>
                  <Typography variant="caption">
                    You are on the Free Plan
                  </Typography>
                  <Typography variant="h6">
                    This feature is part of the{" "}
                    <span className={classes.txtSuccess}>Enterprise</span> plan.
                    Reach out to our customer success team for more information.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} className="txtRight">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<StarsIcon />}
                  >
                    {" "}
                    Upgrade to Enterprise
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        }
        bodyContent={
          <Box className={classes.upgradePopupHolder}>
            <CommonAppCarousel
              bodyContent={carouselArr}
              prevIcon={
                <>
                  <ArrowBackIosIcon />
                </>
              }
              nxtIcon={
                <>
                  <ArrowForwardIosIcon />
                </>
              }
              classNames="upgradeModal"
            />
          </Box>
        }
        // footerContent={
        //     <>my footer content</>
        // }
      />
    </>
  );
};
const getIcon = (key:number)=>{
  return(
    <>
      {key === 0 && <PersonOutlineOutlinedIcon />}
      {key === 1 && <CategoryOutlinedIcon />}
      {key === 2 && <SettingsOutlinedIcon />}
      {key === 3 && <InventoryIcon />}
      {key === 4 && <GppGoodOutlinedIcon />}
      {key === 5 && <WebhookOutlinedIcon />}
      {key === 6 && <InfoIcon />}

    </>
  )
}
export default SettingsControl;
