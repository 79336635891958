import axios from "axios";
import { ITicketType } from "../../redux/actions/SystemManagement/TicketTypes";
import configs from "../../configs/config";

export const getTicketTypes = async () => {
  try {
    let tickets = await axios(`${configs.BASE_URL}/TicketType`);
    return tickets.data;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getTicketSourceAction = () => {
  return async (dispatch: any) => {
    let source = await getSource();
    dispatch({
      type: "source",
      payload: source,
    });
  };
};
export const getSource = async () => {
  try {
    let source = await axios(`${configs.BASE_URL}/Source`);
    return source.data;
  } catch (e) {
    console.log(e);
    return false;
  }
};
export const getSourceStatus= async () => {
  try {
    let source = await axios(`${configs.BASE_URL}/Organisation/setup`);
    return source;
  } catch (e) {
    console.log(e);
    return false;
  }
};
export const editSourceById = async (id: any, payload: any) => {
  try {
    let source = await axios.put(
      `${configs.BASE_URL}/Source/${id}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const addTicketType = async (newTicket: any) => {
  try {
    return await axios.post(`${configs.BASE_URL}/TicketType`, newTicket, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateTicketType = async (newTicket: ITicketType) => {
  try {
    let data = {
      name: newTicket.name,
      displayName: newTicket.displayName,
    };
    return await axios.put(
      `${configs.BASE_URL}/TicketType/${newTicket.id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    console.log(e);
  }
};

export const deleteTicketType = async (ids: number | string, status: any) => {
  try {
    return await axios.delete(
      `${configs.BASE_URL}/TicketType/status/change?ids${ids}&status=${status}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    console.log(e);
  }
};
