const initialState = {
  DepartmentsList: [],
  DivisionList:[],
  OrganisationList: [],
  OrganisationInfo: {},
}
const DepartmentReducer = (state = initialState, action: { type: any; payload: any; }) => {
  const {type,payload} = action;
  switch (type) {
    case 'STORE_DEPARTMENTS':
        return {
            ...state,
            DepartmentsList: payload,
        };
    case 'STORE_COMPANY':
        return {
            ...state,
            OrganisationList: payload,
        };
    case 'LOAD_MORE_ORGANISATIONS':
        return {
            ...state,
            OrganisationList: [...state.OrganisationList , ...payload],
        };
    case 'REMOVE_ORG':
        return {
            ...state,
            OrganisationList: state.OrganisationList.filter((i:any)=>i.id !== payload),
        };
    case 'STORE_DIVISION':
        return {
          ...state,
          DivisionList: payload,
        };
    default:
        return state;
  }
}

export default DepartmentReducer;

