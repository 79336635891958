import axios from "axios";
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";
import LanguageData from "../../../configs/LanguageData.json";
const url = `${configs.BASE_URL}/mailexceptionallist`;
const bulkUpdate = `${url}/status/change?ids=`;
const bulkDelete = `${url}/permanent/delete?ids=`;
const hardDeleteUrl = `${url}/permanent/delete`;
export const ADD_MAILEXCEPTION = "ADD_MAILEXCEPTION";
export const GET_MAILEXCEPTION = "GET_MAILEXCEPTION";
export const storeAllMailException = (val: any) => ({
  type: GET_MAILEXCEPTION,
  payload: val,
});

export const addMailException = (data: any, callback: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(url, data);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_NEW_EMAIL_EXCEPTION}${LanguageData.TICKET_CREATED}`,
          severity: "success",
          open: true,
        },
      });
      callback();
    } catch (error: any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message:
            error.response && typeof error.response.data === "string"
              ? error.response.data
              : "Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const getEmailExceptionByID = (id: any = "", callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await axios(`${url}/${id}`);
      if (callback) callback(response.data);
    } catch (error: any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message:
            error.response.data && typeof error.response.data === "string"
              ? error.response.data
              : "Something went wrong! Try again.",
          severity: "error",
          open: true,
        },
      });
      if (callback) callback(false);
      return console.log(error.response);
    }
  };
};
export const getEmailException = (callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await axios(url);
      // ;
      const formattedData = response.data.map((item: any) => {
        let optionItem = {
          ...item,
          label: item.name,
          value: item.name,
        };
        return optionItem;
      });
      dispatch(storeAllMailException(formattedData));
      if (callback) callback(formattedData);
    } catch (error: any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message:
            error.response.data && typeof error.response.data === "string"
              ? error.response.data
              : "Something went wrong! Try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const softDeleteMailException = (id: any, status: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(
        `${bulkUpdate}${id}&status=${status}`
      );
      dispatch({
        type: "softDeleteMailException",
        payload: response,
      });
      dispatch(getEmailException());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_EMAIL_EXCEPTION}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`,
          severity: "success",
          open: true,
        },
      });
    } catch (error) {
      return console.log(error);
    }
  };
};

export const hardDeleteMailException = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await axios.delete(`${hardDeleteUrl}/${id}`);
      dispatch({
        type: "hardDeleteMailException",
        payload: response,
      });
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_EMAIL_EXCEPTION}${LanguageData.TICKET_DELETED}`,
          severity: "success",
          open: true,
        },
      });
      dispatch(getEmailException());
      // ;
    } catch (error: any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message:
            error.response && typeof error.response.data === "string"
              ? error.response.data
              : "Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const bulkDeleteMailException = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${bulkDelete}${id}`);
      dispatch({
        type: "bulkDeleteMailException",
        payload: response,
      });
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "Success ! \n Priority updated to Active",
          severity: "success",
          open: true,
        },
      });
      dispatch(getEmailException());
    } catch (error) {
      return console.log(error);
    }
  };
};
export const editMailException = (id: number, request: any, callback: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await axios.put(`${url}/${id}`, request);
      dispatch({
        type: "editMailException",
      });
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_EMAIL_EXCEPTION}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      });
      console.log(`${url}/${id}`);
      callback();
    } catch (error: any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response
            ? typeof error.response.data === "string"
              ? error.response.data
              : "Please Enter valid Details to Proceed!"
            : "Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const multipalUpdateEmailException = (
  data: any,
  ids: any,
  status: any
) => {
  const idMsg =
    ids.length === undefined
      ? `${LanguageData.SUCCESS_EMAIL_EXCEPTION}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}`
      : `${LanguageData.SUCCESS_EMAIL_EXCEPTION}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`;
  const statusMsg = status
    ? `${LanguageData.SUCCESS_EMAIL_EXCEPTION}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}`
    : `${LanguageData.SUCCESS_EMAIL_EXCEPTION}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`;
  return function (dispatch: any) {
    axios.delete(`${bulkUpdate}${ids}&status=${status}`, data).then((resp) => {
      dispatch({
        type: "multipalUpdateEmailException",
      });
      dispatch(getEmailException());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: ids.length === undefined ? idMsg : statusMsg,
          severity: "success",
          open: true,
        },
      });
    });
  };
};

export const loading = (status: any) => {
  return {
    type: "loading",
    payload: status,
  };
};

export default getEmailException;
