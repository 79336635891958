import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { ListItemIcon } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import logoImgOnlyS from "./../../../assest/logo/logo-icon.png";
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import { BackIcon, HamBurger } from '../../common/svgIcons/chartIcon';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { storeFiltersValue } from '../../../redux/actions/TicketsManagement/GetTickets';
import { DoLogOutAction } from '../../../redux/actions/Login/Login';
import LogoutIcon from '@mui/icons-material/Logout';
interface Props {
  window?: () => Window;
  toggler? : boolean;
  backIcon? : boolean;
  backPath? : string;
  pageTitle ?: string;
  rightControls ?: JSX.Element;
  leftControls ?: JSX.Element;
}
interface NavItem {
    name: string;
    icon: JSX.Element;
    handler?:()=>void;
}

const drawerWidth = 280;

const navItems:NavItem[] = [
    {   
        name:"Home",
        icon:<HomeOutlinedIcon />,
    },
    {   
        name:"New Ticket",
        icon:<ConfirmationNumberOutlinedIcon />,
    },
    {   
        name:"Search Tickets",
        icon:<SearchOutlinedIcon />,
    }
    // {   
    //     name:"Home",
    //     icon:<InboxIcon />
    // },
    // {   
    //     name:"Home",
    //     icon:<InboxIcon />
    // },
];

const CustomListItem = () =>{
    return(<>
        {navItems.map((i:NavItem,index:number)=>(
            <ListItem key={index}  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                    {i.icon}
                </ListItemIcon>
                <ListItemText primary={i.name} />
                </ListItemButton>
            </ListItem>
        ))}
    </>)
}
export function NavBar(props: Props) {
  const dispatch = useDispatch();
  const { window, rightControls,pageTitle, leftControls, toggler=true,backIcon=false,backPath="/" } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const backHandler = () =>{
    navigate(`${backPath}`);
  }


  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <Box sx={{marginTop:"15px",marginLeft:"15px"}}><img src={logoImgOnlyS} alt="" height={40}/></Box>
      <Divider />
      <List>
            {/* <CustomListItem /> */}
            <ListItem onClick={()=>navigate('/searchResults')}  disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <SearchOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Search Tickets"} />
                </ListItemButton>
            </ListItem>

            <ListItem onClick={()=>{dispatch(DoLogOutAction())}}  disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Logout"} />
                </ListItemButton>
            </ListItem>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  // React.useEffect(()=>{
  //   dispatch(storeFiltersValue(``, 'TCKT_SEARCH_VALUE'));
  // },[])

  return (
    <Box className="mobile-navBar" sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar sx={{justifyContent:"space-between"}}>
            <Box className="mobile-left-menus mobile-menus">
                  {toggler &&
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2}}
                    >
                        <HamBurger />
                    </IconButton>
                }
                {backIcon && 
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={backHandler}
                        sx={{ mr: 2}}
                    >
                        <BackIcon />
                    </IconButton>
                }
                {leftControls}
                {pageTitle && <Typography>{pageTitle}</Typography>}
            </Box>
            <Box className="mobile-right-menus mobile-menus">
                {rightControls}
            </Box>
        </Toolbar>
      </AppBar>



      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{ display: { xs: 'block', sm: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth } }}
        >
          {drawer}
        </Drawer>
      </nav>


      <Box component="main">
        <Toolbar />
        <Typography></Typography>
      </Box>
    </Box>
  );
}
export default NavBar;