import { Box } from "@mui/material"
import { memo } from "react"
import { useDispatch } from "react-redux";
import { bulkFileUploadService } from "../../services/fileUpload/FileUpload";
import { getFileObjUrl } from "../helpers/helperFunctions";
export interface IDashboardSecondaryNavbar {
  callback: (files: any) => void;
}
export const CommonAddAttachment = memo((props: IDashboardSecondaryNavbar) => {
  const { callback } = props;
  const onChangeHandler = async (e: any) => {
    // const files = e.target.files
    const files = Array.from(e.target.files);
    const filesList: any = files.map((item:any)=>{
      return getFileObjUrl(item)
    })
        
      
    callback(filesList)
    e.target.value =""
  };
  return (
    <>
      <Box>
        <input
          id="AssetFileUpload"
          accept=".jpeg, .img, .png, .pdf"
          type="file"
          multiple={true}
          onChange={onChangeHandler}
          //value={FileUploadVal}
          className="d-none"
        />
        <label
          className="Add-Attachment"
          htmlFor="AssetFileUpload"
          style={{
            fontSize: "16px",
            cursor: "pointer",
            color: "#0A93FC",
            display: "inline-block",
            padding: "10px 0",
          }}
        >
          + Add attachment
        </label>
      </Box>
    </>
  )
})
export default CommonAddAttachment;