import React, { createContext, useState, ReactNode } from 'react';
import { ITicket } from '../../../redux/actions/TicketsManagement/TicketsManagement';

// Define a type for the context value
interface AiSuggestionContextType {
  value: ITicket | null;
  setValue: (newValue: ITicket | null) => void;
}

// Create a Context with a default value
const AiSuggestionContext = createContext<AiSuggestionContextType | null >(null);

// Create a Provider Component
const AiSuggestionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [value, setValue] = useState<any>(null);

  return (
    <AiSuggestionContext.Provider value={{ value, setValue }}>
      {children}
    </AiSuggestionContext.Provider>
  );
};

export { AiSuggestionContext, AiSuggestionProvider };