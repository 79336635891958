import { Suspense, lazy, memo, useEffect, useMemo } from "react";
import Sortable from "sortablejs";
import { Box } from "@mui/material";
import { PriorityColumnSkeletonLoader } from "./DashboardSkeletonLoader";
import PriorityDropable from "./PriorityDropable";
import PriorityTicketList from "./PriorityTicketList";
//import PriorityTicketList from "./PriorityTicketList";
// const PriorityTicketList = lazy(()=>import("./PriorityTicketList"));
// const PriorityDropable = lazy(()=>import("./PriorityDropable"));
export const PriorityColumn = memo((props:any) =>{
    const {columnId} = props;
    //console.log('PriorityColumncolumnId',columnId)
    useEffect(()=>{
      console.log('page-rerender',"PriorityColumn",columnId)
        var usersList: any = document.getElementById(`priorityColumnId${columnId}`);
        if(!usersList) return;
        var sortable = Sortable.create(usersList, {
          group: {
            name: "list",
            put: false,
            pull: "clone",
          },
          onStart: function (e:any) {
            // alert('dropped')
            let evt: any = document.getElementsByTagName("html");
            evt[0].classList.add("ItemDragged");
            document.getElementById(`PriorityDropable-${columnId}`)?.classList?.add('d-none');
          },
          onEnd: function (e:any) {
            let evt: any = document.getElementsByTagName("html");
            evt[0].classList.remove("ItemDragged");
            document.getElementById(`PriorityDropable-${columnId}`)?.classList?.remove('d-none');
          },
        });
    },[])
    const dropable = useMemo(()=><PriorityDropable id={`${columnId}`}/>,[])
    const PriorityTickets = useMemo(()=><PriorityTicketList columnId={columnId}  />,[])
    return(
        <>
            <Box className="pt-05" id={`priorityColumnId${columnId}`}>
                {dropable}
                <Suspense fallback={<><PriorityColumnSkeletonLoader/></>}>
                  {PriorityTickets}
                </Suspense>
            </Box>
        </>
    )
})
export default PriorityColumn;