
export enum ActionType {
    ADD = 'ADD',
    SNACKBAR = "SNACKBAR"
}
export interface IAddAction {
    type: ActionType.ADD,
    payload: number,
}

export const add = () => {
    return {
        type: "ADD",
        payload: "9"
    };
}