import React, { useCallback, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { triggerAppAlert } from "../../../redux/actions/SelfServicePortal/ContactAid/ContactAid";
import { IState } from "../../../redux/reducers/rootReducers";
import { Box, Typography } from "@mui/material";
import { NoDataIcon } from "../../../components/common/svgIcons/chartIcon";
export interface ITreeChartValue {
  x: string;
  y: number;
  color?:string;
  id?:number
}
interface ITreemapChartProps{
    values : ITreeChartValue[];
    title ?: string;
    callback ?: (index:number,value:ITreeChartValue)=>void;
    colors : string[]
}
const TreemapChart: React.FC<ITreemapChartProps> = ({title="",callback,values=[],colors=[]}:ITreemapChartProps) => {
  const toggleTheme = useSelector((state: IState) => state.theme.theme);
  const dispatch = useDispatch();
  const handleBarClick = useCallback((event: any, chartContext: any, { dataPointIndex }: { dataPointIndex: number }) => {
    dispatch(triggerAppAlert(values?.[dataPointIndex]?.x,"success"))
    if(callback && dataPointIndex >= 0) callback(dataPointIndex,values[dataPointIndex]);
  },[values]);
  const [chartOptions,setChartOptions] = useState<ApexOptions>({
    chart: {
      type: "treemap",
      height: 350,
      toolbar: { show: false },
      events : {
        click : handleBarClick
      },
    },
    colors : colors,
      // stroke: {
      //   width: 2, // Removes border by setting width to 0
      //   colors: ['#FF000000'], // Sets the border color to red
      // },
    theme: {
      // mode: 'dark', // Enables dark mode
      mode: toggleTheme ? 'dark' : 'light', // Enables dark mode
    },
    plotOptions: {
      treemap: {
        distributed: true, // Enables unique colors for each node
        enableShades: false, // Ensures each node has a distinct color without shading variations
      },
    },
  });

  const [chartSeries, setChartSeries] = useState([
    {
      data: values
    },
  ]);

  useEffect(()=>{
    setChartSeries([
      {
        data: values
      },
    ])
  },[values])
  useEffect(()=>{
    setChartOptions((oldVal)=>{
      return {
        ...oldVal,
        colors : colors,
      }
    })
  },[colors])

  useEffect(()=>{
    setChartOptions({
      ...chartOptions,
      theme: {
        mode: toggleTheme ? 'dark' : 'light', // Enables dark mode
      },
    })
  },[toggleTheme])

  return (
    <Box>
      {values.length === 0  ? 
        <ChartNoDataPlaceHolder />
      :
        <Chart options={chartOptions} series={chartSeries} type="treemap" height={270} />
      }
    </Box>
  );
};


export const ChartNoDataPlaceHolder = ({title="No Values found"}:{title?:string}) =>{
  return(
    <Box>
        <Box className="text-center pt-4">
          <NoDataIcon />
          <Typography>{title}</Typography>
        </Box>
    </Box>
  )
}

export default TreemapChart;
