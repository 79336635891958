import { Suspense, lazy, memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { IState } from "../../../../redux/reducers/rootReducers";
import { Box, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { NewBoxSkeletonLoader } from "./views/widgets/DashboardSkeletonLoader";
import CustomTheme from "../../../common/CustomTheme";
import NewTicketsList from "./views/widgets/NewTicketsList";
import LaunchIcon from '@mui/icons-material/Launch';
import { Link } from "react-router-dom";
//const NewTicketsList = lazy(()=>import('./views/widgets/NewTicketsList'))
export const NewTicketsBox = memo(() =>{
    const classes = newTicketsUseStyles();
    const AllTickets = useSelector((state: IState) => state?.AllTicketsReducer?.newTickets);
    const rolesAccess = useSelector((state: IState) => state?.LoginReducer?.roleAccess);
    const tiketsList = useMemo(()=><NewTicketsList AllTickets={AllTickets}/>,[AllTickets,classes])
    
    
    return (
        <>
            {/* {pageHolder} */}
          {(rolesAccess.isSuperAdmin || rolesAccess?.NewTickets) && 
            <Box className={`${classes.newTcktsBox} px-5p py-5p position-relative`} >
              {/* {isComponentLoaded? */}
              {/* <Suspense fallback={<><NewBoxSkeletonLoader /></>}> */}
                <Typography
                  className={`${classes.title} ${classes.themeColorTxt}`}
                  color="textSecondary"
                >
                  {/* New Tickets ({AllTickets?.length})  */}
                 <Link className="newTickets-link" to="/AllNewTickets"> New Tickets ({AllTickets?.length}) </Link>
                </Typography>
                  {tiketsList}
              {/* </Suspense>  */}
              {/* : <><NewBoxSkeletonLoader /></> */}
              {/* } */}
            </Box>
            }
    </>
    )
})

export const loadMoreBtnStyles = makeStyles(() =>
  createStyles({
    loadMoreBtn:{
      flex:"0 0 100%",
      padding: "10px 10px 0px 10px",
      fontSize: ".8em",
      textAlign: "center",
      color: "#1261b4",
      "& span":{
        cursor:"pointer"
      }
    }
}))
const data = CustomTheme();
const mode = data.theme;
const modeType = data.mode;
export const newTicketsUseStyles = makeStyles(() =>
  createStyles({
    row:{
      display:"flex"
    },
    title: {
      flexGrow: 1,
    },
    flxWrap: {
      flexWrap: "wrap",
    },
    themeColorTxt: {
      color: mode.text,
    },
    newTcktsBox: {
      width:"100%",
      border:"1px solid #5555",
      // background: mode.text !== "#ffffff" ? "#C2D8FA" : mode.solids,
      // backgroundImage:
      //   mode.text !== "#ffffff"
      //     ? "linear-gradient(to bottom, #9fd9fc, #a9ddfc, #b3e1fc, #bde4fc, #c7e8fc)"
      //     : "unset",
      borderRadius:10,
      "& > p":{
        fontSize:12,
        textAlign: 'center',
        paddingBottom:5
      },
      "& > div" :{
        maxHeight:'150px',
        minHeight:'150px',
        // maxWidth: '700px',
        overflow:'scroll',
        width:'auto',
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
          width: "0px",
        },
      }
    }
}))
export default NewTicketsBox;