import React from "react";
import {
  createStyles,
  makeStyles,
  withStyles,
} from "@mui/styles";

import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import { Box, TextField, Typography } from "@mui/material";
import CommonStyles from "./../common/CommonStyles";
import { createTheme } from "@mui/material/styles";

const theme:any = createTheme();
const BootstrapInput = withStyles(() =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 12,
      width: "250px",
      paddingLeft: "12px",
      paddingRight: "5px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

const useStyles = makeStyles(() =>
  createStyles({
    margin: {
      marginBottom: "1rem",
      width: "100%",
      marginRight: "10px",
    },
    Box: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      "@media screen and (max-width:980px)": {
        display: "block",
      },
    },
    title: {
      lineHeight: "20px",
      fontWeight: 400,
      width: "150px",
      fontSize: "12px",
      minWidth: "150px",
      "@media screen and (max-width:980px)": {
        marginBottom: "5px",
        width: "100%",
      },
    },
    inputBox: {
      width: "100%",
      "& >div": {
        width: "100%",
      },
      "@media screen and (max-width:980px)": {
        "& input": {
          paddingTop: "5.5px",
          paddingBottom: "5.5px",
        },
      },
    },
  })
);

export default function CustomizedSelects({
  type,
  title,
  placeholder,
  required=false,
  styleProp,
  inputStyle,
  classname,
  onChange,
  value,
  name,
  min,
  max,
  disable,
  label,
  id,
  defaultValue,
  onClick,
  props,
  classNames,
  errMsg = '',
  showErr = false,
  multiple,
  onFocusHandler
}: any) {
  const mode = CommonStyles();
  const classes = useStyles();
console.log('showErr',showErr,title)
  return (
    <Box className="w-100">
      <FormControl className={`${classes.margin} cmn-inputBox`}>
        <Box className={`${classes.Box} ${classname}`}>
          {title && (
            <Typography
              className={`${classes.title} ${mode.themeColorTxt} ${styleProp}`}
            >
              {title} {required && <span className={mode.dangerTxt}> *</span>}
            </Typography>
          )}
          <TextField
            {...props}
            helperText = {showErr ? errMsg : ""}
            error = {showErr}
            disabled={disable}
            label={label}
            format={type=="Date" &&'DD/MM/YYYY'}
            id={id ? id : "Input"}
            variant="outlined"
            size="small"
            className={`${classNames} ${classes.inputBox} ${inputStyle}`}
            placeholder={placeholder}
            type={type}
            multiple={multiple}
            InputProps={{ inputProps: { min: min, max: max } }}
            required={required?required:false}
            value={value}
            name={name}
            onChange={(e: any) => onChange(e)}
            onClick={(e: any) => onClick?.(e)}
            defaultValue={defaultValue}
            onFocus={(e: any) => onFocusHandler?.(e)}
          />
          {/* {showErr && <Typography variant="caption" className={mode.errInlineMsg} display="block">{errMsg}</Typography>} */}
        </Box>
      </FormControl>
    </Box>
  );
}
