import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../../redux/reducers/rootReducers";
import { IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CommonStyles from "../../../common/CommonStyles";
import { memo, useEffect, useState } from "react";
import { storeFiltersValue } from "../../../../redux/actions/TicketsManagement/GetTickets";

export const SearchBox = memo(() =>{
    //filtersTicketManagementReducer
    console.log('rerender1-SearchBox')
    const classes = CommonStyles();
    const dispatch = useDispatch();
    const searchVal = useSelector(
        (state: IState) => state?.filtersTicketManagementReducer?.searchVal
    );
    const [value,setValue] = useState('');
    const onKeyChange = (e:any) =>{
        console.log(e.target.value)
        setValue(e.target.value)
        dispatch(storeFiltersValue(e.target.value, 'TCKT_SEARCH_VALUE'));
    }
    const onSearchClick = () =>{
        dispatch(storeFiltersValue(value, 'TCKT_SEARCH_VALUE'));
    }
    useEffect(()=>{
        console.log('searchVal',searchVal)
    },[searchVal])
    return (
        <>
            <Paper className={classes.SearchBox}>
                <InputBase
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={onKeyChange}
                    id='FilterSearchBox'
                    value={searchVal}
                />
                <IconButton type="button" aria-label="search" onClick={onSearchClick}>
                    <SearchIcon />
                </IconButton>
            </Paper>
        </>
    )
})
export default SearchBox;