import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { IState } from '../../../redux/reducers/rootReducers'
import TreemapChart, { ITreeChartValue } from './TreeMapChart'
import { ChartHelper, IBasicObj } from './TrendsChart'
import { id } from 'date-fns/locale'
import { NoDataIcon } from '../../../components/common/svgIcons/chartIcon'
import { debounce } from 'lodash'
interface IPriorityTreeChartProps {
    dashboardSummary:any;
    dispatch:any;
}
const PriorityTreeChart = ({dashboardSummary={},dispatch}:IPriorityTreeChartProps) => {
    const [priorityData, setPriorityData] = useState<ITreeChartValue[]>([]);
    const [data, setData] = useState({
        labels:[],
        values : [],
        colors : []
    });
    useEffect(() => {
        loadData();
    }, [dashboardSummary]);
    const loadData = debounce(() =>{
        // console.log("dashboardSummary", dashboardSummary)
        if (dashboardSummary) {
            const tempData:any = {
                labels:[],
                values : [],
                colors:[]
            };

            const tempObj = Object.keys(dashboardSummary).map((i) => {
                tempData.labels.push(i);
                tempData.values.push(dashboardSummary?.[i]?.count);
                tempData.colors.push(dashboardSummary?.[i]?.color || "#ddd111")

                return { x: i, y: dashboardSummary?.[i]?.count,id: dashboardSummary?.[i]?.id, color : dashboardSummary?.[i]?.color || "#ddd111"}
            })
            setData(tempData)
            setPriorityData(tempObj);
        }
    },100)

    const treeCallback = (index: any, value: any) => {
        console.log("handleBarClick-Tree",dashboardSummary, index, value);
        dispatch({
            type:"STORE_HOME_DASHBOARD_AGENT_PRIORITY",
            payload :  { [`${value.id}`] : value.x}
        })
    };
    const toggleWithCustomLegends = (objToRemoveItems: IBasicObj) => {
        const tempArr:ITreeChartValue[] = [];
        Object.keys(dashboardSummary).map((i,index) => {
            if(objToRemoveItems[index]) return;
            tempArr.push({ x: i, y: dashboardSummary?.[i]?.count, id:dashboardSummary?.[i]?.id, color : dashboardSummary?.[i]?.color || "#ddd111"});
        })
        setPriorityData(tempArr);
    }
    const colors = priorityData.map(i=>`${i.color}`)
    return (
        <Box className="home-chart-box white-bg" sx={{height:333}}>
            <Box className="chart-header pb-1">
                <Typography className="chart-title">{"Priority"}</Typography>
                <ChartHelper colors={data.colors} callback={toggleWithCustomLegends} labels={data.labels} values={data.values} />
            </Box>
            {priorityData.length > 0 ?
                <TreemapChart
                    title=''
                    callback={treeCallback}
                    values={priorityData}
                    colors={colors}
                />
                :
                <Box className="text-center pt-4">
                    <NoDataIcon />
                    <Typography>No Values found</Typography>
                </Box>
            }
        </Box>
    )
}


const mapStateToProps = (state: IState) => {
    return ({
      dashboardSummary: state?.TicketsReducer?.dashboardSummary?.priority,
    })
  };
export default connect(mapStateToProps)(PriorityTreeChart);
// export default PriorityTreeChart
