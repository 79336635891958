import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import { addDays, subYears } from "date-fns";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getReportById
} from "../../redux/actions/Reports/reports";
import { storeFiltersValue } from "../../redux/actions/TicketsManagement/GetTickets";
import { IState } from "../../redux/reducers/rootReducers";
import CommonStyles from "../common/CommonStyles";
import Breadcrumbs from "../common2/Breadcrumbs";
import { getDateRange } from "../helpers/helperFunctions";
import CommonAppFilter from "../navbar/sideNavbar/CommonAppFilterCopy";
import PivotTableModal from "./PivotTableModal";

const generateTimeRange = (FiltersVal: any) => {
  if (FiltersVal?.rangeVal) {
    return (
      <p
        style={{
          color: "black",
          textDecoration: "underline",
          marginTop: "10px",
          fontSize: "14px",
        }}
      >
        {moment(FiltersVal?.rangeVal?.startDate).format("L")} -{" "}
        {moment(FiltersVal?.rangeVal?.endDate).format("L")}
      </p>
    );
  }
};

function CreateReportCopy() {
  const param: any = useParams();
  const classes = CommonStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    id: 0,
    name: "",
    chartType: "",
    isActive: true,
    filter: "",
  });
  const [chartEditIndex, setChartEditIndex] = useState(-1);
  const [chartTitle, setChartTitle] = useState("");
  const [chartsList, setChartsList] = useState<any>([]);
  const profileImg = useSelector(
    (state: IState) => state?.agentReducer?.saveOrgProfile
  );
  const [state, setState] = useState<any>({});

  const AllTickets = useSelector(
    (state: IState) => state?.TicketsReducerForReport?.allTickets
  );
  const AllTicketsLogs = useSelector(
    (state: IState) => state?.TicketsReducerForReport?.allTicketLogs
  );
  

  const FiltersVal: any = useSelector(
    (state: IState) => state?.filtersTicketManagementReducerForReport
  );
  

  const loaderSf: any = useSelector(
    (state: IState) => state?.LayoutTicketManagementReducer?.showLoader
  );

  const [logoSrc, setLogoSrc] = useState(profileImg);

  useEffect(() => {
    if (param.id) {
      getReportById(param.id, getByIdCB);
    } else {
      setChartsList([]);
      const currentDate = new Date();
      const yesterday = new Date();

      yesterday.setDate(currentDate.getDate() - 1);

      let rangeState = {
        startDate: subYears(new Date(), 1),
        endDate: addDays(new Date(), 0),
        key: "selection",
      };

      dispatch(
        storeFiltersValue(
          { node: "OpenDateTime", ...rangeState },
          "TCKT_RANGE_VALUE_REPORT"
        )
      );
    }
    console.log("profileImg", profileImg);
  }, [param.id]);

  const getByIdCB = (resStatus: string, res: any) => {
    console.log("checvk check error report black screen", res);

    if (res) {
      const tempCharts: any = JSON.parse(res?.value);
      setChartsList(tempCharts?.values);
      setData({ ...res });

      dispatch(
        storeFiltersValue(
          JSON.parse(res?.filterValue)?.agents,
          "TCKT_AGENTS_VALUE_REPORT"
        )
      );

      let temp: any = JSON.parse(res?.filterValue);
      console.log("temp daterange", temp);
      if (temp.rangeVal.key !== "selection") {
        const dateRange = getDateRange(temp.rangeVal.key);

        if (dateRange) {
          console.log("date check", new Date(dateRange.endDate).toISOString());

          let rangeState = {
            startDate: new Date(dateRange.startDate),
            endDate: new Date(dateRange.endDate),
            key: temp.rangeVal.key,
            node: temp.rangeVal.node,
          };

          console.log("date check", rangeState, temp);

          dispatch(storeFiltersValue(rangeState, "TCKT_RANGE_VALUE_REPORT"));
        }
      } else {
        let rangeState = {
          startDate: new Date(temp?.rangeVal?.startDate),
          endDate: new Date(temp?.rangeVal?.endDate),
          key: temp.rangeVal?.key,
          node: temp.rangeVal?.node,
        };
        console.log("temp daterange--", rangeState);

        dispatch(storeFiltersValue(rangeState, "TCKT_RANGE_VALUE_REPORT"));
      }
    }
  };
  
  const exportPDF = () => {
    window.print();
  };
  const fileChangeHandler = (e: any) => {
    console.log(e.target.value);
    var reader: any = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      console.log(reader.result);
      setLogoSrc(reader.result);
    };
    reader.onerror = function (error: any) {
      console.log("Error: ", error);
    };
  };

  

  const PageButtons = (props: any) => {
    const { onClickHandler, title } = props;
    return (
      <Button className={classes.ReportBtns} onClick={onClickHandler}>
        {title}
      </Button>
    );
  };


  const addRecord = () => {
    console.log("addRecord", chartsList, state);
    const tempObj = {
      title: chartTitle,
      value: {
        cols: state.cols,
        rows: state.rows,
        rendererName: state.rendererName,
        aggregatorName: state.aggregatorName,
        vals: state.vals,
      },
      type: "column",
      default: true,
    };
    if (chartEditIndex === -1) {
      setChartsList([...chartsList, tempObj]);
    } else {
      const tempArr = [...chartsList];
      tempArr[chartEditIndex] = tempObj;
      setChartsList(tempArr);
      setChartEditIndex(-1);
    }
    clearFields();
  };
  const clearFields = () => {
    setChartTitle("");
  };
  const saveCallback = (resStatus: string, response: any) => {
    if (resStatus !== "0") return;
    console.log("callback", resStatus, response);
    navigate("/All-Reports");
  };

  const handleExportPDF = async () => {
    addRecord();
  };
  return (
    <div>
      <div className="report-hide">
        <Breadcrumbs
          data={[
            {
              title: "Reports Management ",
              path: "/ReportsManagement",
            },
            {
              title: "Custom Reports ",
              path: "/All-Reports",
            },
            {
              title: `${param.id ? "Edit" : "Add New"} Report`,
              path: "/CreateReport",
            },
          ]}
        />
      </div>
      <br className="report-hide" />
      <Divider style={{ marginBottom: "10px" }} className="report-hide" />
      <div className="report-hide">
        {" "}
        <CommonAppFilter
          report={true}
          hideAddBtn={true}
          hideViewMode={true}
          hideSort={true}
          hideAgent={false}
          hideSearch={true}
          hideFilter={false}
          hideExtraIcons={true}
          customIconFunctions={
            [
              // {
              //   title: "Refresh Tickets",
              //   icon: <ViewCarouselOutlinedIcon />,//ViewCompactOutlinedIcon,ViewCarouselOutlinedIcon
              //   focused: isCSVExport,
              //   focusedIcon: <ViewCompactOutlinedIcon />,
              //   functionHandler: layoutShift,
              // },
              // {
              //   title: "Power BI",
              //   icon: <ViewCarouselOutlinedIcon />,//ViewCompactOutlinedIcon,ViewCarouselOutlinedIcon
              //   focused: isCSVExport,
              //   focusedIcon: <ViewCompactOutlinedIcon />,
              //   functionHandler: layoutShift,
              // }
            ]
          }
        />
        <Divider style={{ marginTop: "10px" }} />
      </div>
      {loaderSf && (
        <Box className={classes.loaderBox}>
          <CircularProgress />
        </Box>
      )}

      <span
        className="report-inside-title-id edit-report-title"
        style={{
          color: "gray",
          textDecoration: "underline",
          marginBottom: "50px",
        }}
      >
        {param.id && `Report id - ${param.id}`}
      </span>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        className="report-hide"
      >
        {" "}
        <PageButtons title="Export Report" onClickHandler={exportPDF} />
      </div>
      <Grid container className="pt-1 pb-5 pl-1 report-hide-margin" spacing={1}>
        <Box style={{ width: "100%" }}>
          <Box className={`${classes.row} ${classes.justifyCenter} px-2`}>
            <div className="custom-chart custom-Report-chart">
              <input
                type="file"
                name=""
                id="reportLogo"
                className="d-none"
                onChange={fileChangeHandler}
              />
              <label htmlFor="reportLogo">
                <img
                  src={logoSrc === "" ? logoSrc : logoSrc}
                  alt=""
                  style={{ width: "150px" }}
                />
              </label>
              <p
                style={{
                  fontSize: "25px",
                  textAlign: "left",
                  marginBottom: "25px",
                }}
              >
                {data.name}
              </p>
              {generateTimeRange(FiltersVal)}
              {loaderSf ? (
                <Box className={classes.loaderBox}>
                  <CircularProgress />
                </Box>
              ) : (
                chartsList?.map((item: any) => {
                  return (
                    <div
                      className="hide-pivot-container"
                      style={{ width: "100%" }}
                    >
                      {item?.title && (
                        <Typography
                          variant="body2"
                          className="li-title"
                          style={{
                            color: "black",
                            marginTop: "15px",
                            fontSize: "20px",
                            textDecoration: "underline",
                          }}
                        >
                          {item?.title}
                        </Typography>
                      )}
                      <PivotTableModal
                        AllTicketsLogs={AllTicketsLogs}
                        data={AllTickets}
                        onClick={handleExportPDF}
                        state={item.value}
                        setState={setState}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </Box>
          {/* <Chart chartOptions={lineChartOptions} /> 
                    <Chart chartOptions={lineChartOptions} /> */}
          {/* <Chart chartOptions={columnChartOptions} />
                    <Chart chartOptions={barChartOptions} /> */}
          {/* {chartsList.map((i:any)=>{
                        return(
                            <>
                                <Chart chartOptions={returnOptions(i)} />
                            </>
                        )
                    })} */}
        </Box>
      </Grid>
    </div>
  );
}
export default CreateReportCopy;
