import { connect, useDispatch } from "react-redux";
import { IState } from "../../../../redux/reducers/rootReducers";
import CommonStyles from "../../../common/CommonStyles";
import { Box,FormControlLabel,Checkbox } from "@mui/material";
import { selectAllAgentPermissions, selectAllPermissions } from "../../../../redux/actions/userManagement/role";
import { useEffect, useState } from "react";
interface IValueType {
    check : any;
    uncheck : any;
    totalPermissionCount : number;
}
const getValueStruct = (keyLabel="",info=[],permissions=[])=>{
    const permissionIds:any = []
    const allowAccess:any = permissions.filter((i:any)=>{
        if(i.name === "AllowAccess" || keyLabel === "TicketTypes"){
            permissionIds.push(i.id)
        }
    })
    //?.[0];
    const temObj:any = {};
    const unCheckTemObj:any = {};
    let totalPermissionCount = 0;
    info.map((i:any)=>{
        temObj[i?.id] = permissionIds;//allowAccess?.[0]?.id ? [keyLabel === "TicketTypes" ? allowAccess?.[0]?.id] : [];
        totalPermissionCount += permissionIds?.length;
        unCheckTemObj[i?.id] = [];
    })
    console.log("getValueStructgetValueStruct",
        {
            check : {
                [keyLabel] : temObj
            },
            uncheck : {
                [keyLabel] : unCheckTemObj
            },
            totalPermissionCount:totalPermissionCount
        }
    )
    return {
        check : {
            [keyLabel] : temObj
        },
        uncheck : {
            [keyLabel] : unCheckTemObj
        },
        totalPermissionCount:totalPermissionCount
    }
}
const getCheckedCount = (checkedObj:any={})=>{
    let count = 0;
    const keys = Object.keys(checkedObj)
    keys.map((i:any)=>{
        count += checkedObj[i].length;
    })
    return count;
}
const AgentSelectAllOption = ({checkedItems,permissions,keyLabel,info}:any) =>{
    const classes = CommonStyles();
    const dispatch = useDispatch();
    const [valueObj,setValueObj] = useState<any>({
        check : [],
        uncheck : [],
        totalPermissionCount : 1
    });
    const [checked, setChecked] = useState(false);
    const [intermediate, setIntermediate] = useState(false);
    const toggleCheckAll = (e:any) =>{
        const {checked} = e.target;
        if(keyLabel==="TicketTypes" && !checked){
            dispatch({
                type: "TOGGLE_TICKET_TYPE_VALUES",
                payload: true
            });
            return;
        }
        dispatch(selectAllAgentPermissions(
            checked ? valueObj?.check : valueObj?.uncheck,keyLabel
        ))
    }
    useEffect(()=>{
        setValueObj(getValueStruct(keyLabel,info,permissions))
    },[info])
    useEffect(()=>{
        console.log('AgentSelectAllOptionAgentSelectAllOptioncheckedItems',checkedItems?.[keyLabel])
        if(checkedItems?.[keyLabel]){
            //console.log('getCheckedCount(checkedItems?.[keyLabel])',getCheckedCount(checkedItems?.[keyLabel]))
            const checkedCount = getCheckedCount(checkedItems?.[keyLabel]);
            setChecked(checkedCount > 0 && (valueObj?.totalPermissionCount <= checkedCount) ? true : false);
            setIntermediate(checkedCount > 0 && valueObj?.totalPermissionCount > checkedCount ? true : false);
        }else{
            setChecked(false);
            setIntermediate(false);
        }
    },[checkedItems, valueObj?.totalPermissionCount])


    // useEffect(()=>{
    //     console.log('AgentSelectAllOptionAgentSelectAllOptioncheckedItems',checkedItems,checkedItems?.[keyLabel])
    // },[checkedItems])
    //console.log('checkedItems,permissions',keyLabel,info,checkedItems,permissions)
    return(<>
        <Box className={classes.roleAllowAccessBtn}>
            <FormControlLabel
                value=''
                // checked={checked}
                control={
                    <Checkbox size="small"
                        indeterminate={intermediate} 
                        checked={checked}
                    />
                }
                onChange={toggleCheckAll}
                label='Allow access'
                labelPlacement="end"
                className="sml-txt"
            />
        </Box>  
    </>)
}
const mapStateToProps = (state:IState) => {
    return ({
        checkedItems : state?.roleReducer?.agentCheckBoxStatus,
        permissions : state?.permissionReducer?.Data?.data,
    })
};
export default connect(mapStateToProps)(AgentSelectAllOption);