import axios from "axios";
import { ActionType } from "../dashboard";
import configs from "../../../configs/config";
import LanguageData from "../../../configs/LanguageData.json";

const SSOConfig = `${configs.BASE_URL}/sso`;
const bulkUpdate = `${configs.BASE_URL}/sso/status/change?ids=`;


export const getAllSSOConfig = (callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await axios(`${SSOConfig}`);
      dispatch({
        type: "GET_SSOCONFIG_INFO",
        payload: response.data,
      });
      if (callback) callback(response.data);
    } catch (error: any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "We're facing technical difficulties! Please Try again later!",
          severity: "warning",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const getSSOConfigByID = (id: any="", callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await axios(`${SSOConfig}/${id}`);
      if (callback) callback(response.data)
    } catch (error: any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response.data && typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  }
};
export const editSSOConfig = (id: any, request: any, callback: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await axios.put(`${SSOConfig}/${id}`, request);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message:`${LanguageData.SUCCESS_NEW_SSO}${LanguageData.TICKET_CREATED}` ,
          severity: "success",
          open: true,
        },
      });
      callback();
    } catch (error: any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!" : "Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const addSSOConfig = (data: any, callback: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(SSOConfig, data)
      debugger
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_SSO}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        }
      });
      debugger
      callback();
    } catch (error: any) {
      debugger
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response && typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error);
    }
  };
};
export const softDeleteSSOConfig = (id: any,status:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${bulkUpdate}${id}&status=${status}`);
      dispatch(getAllSSOConfig());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_SSO}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`,
          severity: "success",
          open: true,
        },
      });
    } catch (error) {
      return console.log(error);
    }
  };
};
export const multipalUpdateSSOConfig = (data: any, ids: any,status:any) => {
  const idMsg = ids.length === undefined ? `${LanguageData.SUCCESS_SSO}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_SSO}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  const statusMsg = status ? `${LanguageData.SUCCESS_SSO}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_SSO}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  return function (dispatch: any) {
    axios.delete(`${bulkUpdate}${ids}&status=${status}`, data).then((resp) => {
      dispatch({
        type: "multipalUpdateSSOConType",
      });
      dispatch(getAllSSOConfig());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: ids.length === undefined ? idMsg : statusMsg,
          severity: "success",
          open: true,
        },
      });
    });
  };
};