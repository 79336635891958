import axios,{AxiosResponse} from "axios";
import configs from "../../configs/config";
import { ActionType, snackbar } from "../../redux/actions/Snackbars";
import intl from "react-intl-universal";
import ticketStatusAdd from "../../redux/actions/SystemManagement/TicketStatus";
import LanguageData from "../../configs/LanguageData.json"

export const url = `${configs.BASE_URL}/Location`;
const deleteUrl = `${configs.BASE_URL}/Location/permanent/delete/`;
const bulkUpdate = `${configs.BASE_URL}/Location/status/change?ids=`;

export const getLocation = async () => {
  try {
    const tickets = await axios.get(url);
      console.log("api is called",tickets.data);
      return tickets.data;

  } catch (e) {
      console.log(e)
  }
}
export const LocationAdd =  (data: any,callback?:any) => {
  return async (dispatch: any) => {
  try {
   const response =  await axios.post(`${configs.BASE_URL}/Location`, data);
    dispatch({
      type: ActionType.SNACKBAR,
      payload: {
        message: `${LanguageData.SUCCESS_NEW_LOCATION}${LanguageData.TICKET_CREATED}`,
        severity: "success",
        open: true,
      },
    });
    callback();
  }catch (error: any) {
    dispatch({
      type: ActionType.SNACKBAR,
      payload: {
        message: error.response && typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Please try again.",
        severity: "error",
        open: true,
      },
    });
    return console.log(error.response);
  }
}
};
export const updateLocation =  (id: any, data: any, callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.put(`${url}/${id}`,data);
      // ;
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_LOCATION}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      });
      callback();
    }catch (error:any) {
    dispatch({
      type: ActionType.SNACKBAR,
      payload: {
        message: error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.",
        severity: "error",
        open: true,
      },
    });
    return console.log(error.response);
  }
};
};

export const singleLocation = (id: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${url}/${id}`);
      if (callback) callback(response.data)
      dispatch({
        type: "singleAssetStatus",
        payload: response,
      });
    } catch (error) {
      return console.log(error);
    }
  };
};




export const multipalUpdateLocation = async (data: any, ids: any,status:any) => {
  
  
  try {
    let result = await axios.delete(`${bulkUpdate}${ids}&status=${status}`, data);
    console.log("bulk delete",result)
    
   if (result.status==200){
    return true
    
   }
   else{
    return false
   }
  }  catch (error:any) {
    if (error.response) {
     
      return error.response.data
    }  
  }
};


export const softDeleteLocation = async (id: any) => {
  {
    try {
      let result = await axios.delete(`${bulkUpdate}${id}`);
      
     if (result.status==200){
      return true
      
     }
     else{
      return false
     }
    }  catch (error:any) {
      if (error.response) {
       
        return error.response.data
      }  
    }
  };
}
export const changeLocation = async (id: any, status:boolean) => {
  {
    try {
      let result = await axios.delete(`${configs.BASE_URL}/Location/status/change?ids=${id}&status=${status}`);
      
     if (result.status==200){
      return true
      
     }
     else{
      return false
     }
    }  catch (error:any) {
      if (error.response) {
       
        return error.response.data
      }  
    }
  };
}

export const deleteLocation = async(id: any) => {
  {
    try {
      let s =  await axios.delete(`${deleteUrl}${id}`);
      
      console.log("result after calling AssetStatusAdd", s)

      return s.data
    }  catch (error:any) {
      if (error.response) {
       
        return error.response.data
      }  
    }
  };
};

export const SnackBar =(msg: any,severity:string)=>{
  return async (dispatch: any) => {

  dispatch({
    type: ActionType.SNACKBAR,
    payload: {
      message: msg,
      severity: severity,
      open: true,
    },
  });
}
}

export const loading = (status: any) => {
  return {
    type: "loading",
    payload: status,
  };
};

export default LocationAdd;