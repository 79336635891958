import { ActionType, IDashboardType } from "../actions/SecondaryNavbar"

export interface IDashboard {
    DashboardType: string
}
const initState: IDashboard = {
    DashboardType: "Priority"
}

const dashboardType= (state: IDashboard = initState, action: IDashboardType) => {
    switch (action.type) {
        case ActionType.DASHBOARD_TYPE:
            return {
                ...state,
                DashboardType: action.payload
            }
        default:
            return state
    }
}

export default dashboardType;