import { Box, Grid, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { IDashboardInsights } from '../../../redux/actions/TicketsManagement/schema';
import { IState } from '../../../redux/reducers/rootReducers';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getTicketStatus } from '../../../redux/actions/SystemManagement/TicketStatus';
import { triggerAppAlert } from '../../../redux/actions/SelfServicePortal/ContactAid/ContactAid';
interface IHomeProps {
    dispatch:any;
    dashboardSummary:IDashboardInsights|undefined;
    ticketStatuses:any[]
}
const excludedStatuses = ["Closed", "New", "NewtoMe", "Delete", "Resolved"];

const CountCards:React.FC<IHomeProps> = ({dashboardSummary,ticketStatuses=[],dispatch}:IHomeProps) => {
    const {dueToday=0, dueInWeek=0, overdue=0, resolvedToday=0, ticketStatus} = dashboardSummary||{};
    const navigate = useNavigate();
    const [statusObj,setStatusObj] = useState<{[key : string] : number[]}>({})
    // const {newTicketCount=0,opeanTicketCount=0,dataList} = dashboardSummary || {};
    const onClickHandlers = (type:string) =>{
        dispatch({
            type,
            payload : null
        })
        navigate("/TicketManagementSys")
    }
    const onStatusSelections = (e:React.MouseEvent<HTMLElement>,baseStatus:string) =>{
        e.stopPropagation();
        if(ticketStatuses.length===0 || baseStatus === "NewtoMe") return;
        dispatch({
            type: "SET_FILTER_WITH_STATUS",
            payload : statusObj[baseStatus] || []
        })
        navigate("/TicketManagementSys")
    }
    const onClickResolvedToday = (e:React.MouseEvent<HTMLElement>) =>{
        e.stopPropagation();
        if(ticketStatuses.length===0) return;
        dispatch({
            type: "SET_FILTER_WITH_RESOLVED_TODAY",
            payload : statusObj["Resolved"] || []
        })
        navigate("/TicketManagementSys")
    }
    useEffect(()=>{
        if(ticketStatuses.length===0){
            dispatch(getTicketStatus());
        }
    },[])
    useEffect(()=>{
        if(ticketStatuses.length===0) return;
        const tempObj:{[key : string] : number[]} = {
            "all" : []
        }
        ticketStatuses.map((i:{id:number,type:string,isActive:boolean,name:string})=>{
            if(i.isActive && i.type=== "TicketStatus"){
                if(!excludedStatuses.includes(i.name)){
                    tempObj.all.push(i.id);
                }
                tempObj[i.name] = (tempObj[i.name] ?? []).concat(i.id);
            }
        })
        setStatusObj(tempObj)
    },[ticketStatuses.length])
    
    return (
        <Box>
            <Typography gutterBottom>Tickets In Progress</Typography>
            <Grid container spacing={2}>
                <Grid item xs={2} md={2}>
                    <Box
                        onClick={()=>{
                            dispatch(triggerAppAlert("Click through with Overdue tickets is under development","warning"))
                        }}
                        className="home-countCard"  sx={{backgroundColor:"#ee638b"}}>
                        <Typography variant='h4'>{overdue}</Typography>
                        <Typography className="datalabel">Overdue</Typography>
                    </Box>
                </Grid>

                <Grid item xs={2} md={2}>
                    <Box 
                        onClick={()=>{onClickHandlers("SET_FILTER_DUE_TODAY")}}
                        className="home-countCard"  sx={{backgroundColor:"#d1da90"}}>
                        <Typography variant='h4'>{dueToday}</Typography>
                        <Typography className="datalabel">Due Today</Typography>
                    </Box>
                </Grid>

                <Grid item xs={2} md={2}>
                    <Box
                        onClick={()=>{onClickHandlers("SET_FILTER_DUE_WEEK")}} 
                        className="home-countCard"  sx={{backgroundColor:"#c7deb3"}}>
                        <Typography variant='h4'>{dueInWeek}</Typography>
                        <Typography className="datalabel">Due in 7 days</Typography>
                    </Box>
                </Grid>

                <Grid item xs={2} md={2}>
                    <Box onClick={()=>navigate("/AllNewTickets")} className="home-countCard" sx={{backgroundColor:"#b6dfff"}}>
                        <Typography variant='h4'>{ticketStatus?.newTicketCount || 0}</Typography>
                        <Typography className="datalabel">New Tickets</Typography>
                    </Box>
                </Grid>

                <Grid item xs={2} md={2}>
                    <Box 
                        onClick={(e)=>onStatusSelections(e,"all")}
                        className="home-countCard"  sx={{backgroundColor:"#dbf9f0"}}>
                        <Typography variant='h4'>{ticketStatus?.openTicketCount || 0}</Typography>
                        <Typography className="datalabel">Open Tickets</Typography>
                        <Box className="countTooltip">
                        <Typography><span className="simple-icon icon-info"></span></Typography>
                            <Box className="countTooltip-body">
                                <Box className="white-bg">
                                    <ul>
                                        {Object.keys(ticketStatus?.dataList||{}).map((i)=>{
                                            return (
                                                    <li className={`${i==="NewtoMe" ? "disabled" : "item"}`} onClick={(e)=>onStatusSelections(e,i)}
                                                        key={i}>{i} : {ticketStatus?.dataList?.[i]?.count ||0 }
                                                    </li>)
                                        })}
                                    </ul>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={2} md={2}>
                    <Box
                        onClick={onClickResolvedToday}
                         className="home-countCard"  sx={{backgroundColor:"#b3dec1"}}>
                        <Typography variant='h4'>{resolvedToday}</Typography>
                        <Typography className="datalabel">Resolved Today</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}


const mapStateToProps = (state:IState) => {
    return ({
        dashboardSummary : state?.TicketsReducer?.dashboardSummary,
        ticketStatuses : state?.ticketStatusReducer?.Data?.data
    })
  };
export default connect(mapStateToProps)(CountCards);