import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import * as React from 'react';

type Anchor = 'top' | 'left' | 'bottom' | 'right';
interface IBottomDrawerProps{
    children:JSX.Element;
    isOpened : boolean;
    onClose : (val?:any)=>void;
    title:string;
    rightCornerHandler?: ()=>void;
    rightCornerText?:string;
    className?:string;
}
function BottomDrawer(props:IBottomDrawerProps) {
    const {children,className="",isOpened=false,onClose,title,rightCornerHandler,rightCornerText} = props;
    const [state, setState] = React.useState(isOpened);
    React.useEffect(()=>{
        setState(isOpened)   
    },[isOpened])
  return (
    <div>
      
          {/* <Button onClick={toggleDrawer(true)}>anchor</Button> */}
          <Drawer
            anchor={"bottom"}
            open={state}
            onClose={onClose}
            className={`mobile-bottom-drawer ${className}`}
          >
            <Typography className='p-10p mobile-bottom-drawer-title'>
              <span>{title}</span>
              <span className='rightLink' onClick={rightCornerHandler}>{rightCornerText}</span>
            </Typography>
            <Divider />
            <Box className='p-10p bottom-drawer-body'>
              {children}
            </Box>
          </Drawer>
        
    </div>
  );
}



// const list = () => (
//     <Box
//       sx={{ width:  'auto'}}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//           <ListItem  disablePadding>
//             <ListItemButton>
//                 <ListItemIcon><MailIcon /></ListItemIcon> <ListItemText primary={"bottom"} />
//             </ListItemButton>
//           </ListItem>
//       </List>
//     </Box>
//   );


export default BottomDrawer