const initialState = {
  AllReports: [],
}
const ReportsReducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case 'STORE_REPORTS':
          return {
              ...state,
              AllReports: action.payload,
          };
      default:
          return state;
  }
}

export default ReportsReducer;
