import Joi from "joi";

// VALIDATION FOR TICKET CREATION FORM DATA
const ticketTypeSchema = Joi.number()
  .required()
  .custom((value, helpers) => {
    if (value === 0) {
      return helpers.error("number.notZero");
    }
    return value;
  })
  .messages({
    "number.base": "Ticket type is required.",
    "any.required": "Ticket type is required.",
    "number.notZero": "Ticket type is required.",
  });

  const articleTypeSchema = Joi.number()
  .required()
  .custom((value, helpers) => {
    if (value === 0) {
      return helpers.error("number.notZero");
    }
    return value;
  })
  .messages({
    "number.base": "Article type is required.",
    "any.required": "Article type is required.",
    "number.notZero": "Article type is required.",
  });

const subjectSchema = Joi.string()
  .trim()
  .max(100)
  .messages({
    "string.empty": "Name is required.",
    "any.required": "Name is required.",
    "string.max":
      "Name length must be less than or equal to 100 characters.",
  });

const catSchema = Joi.number().required()
  .custom((value, helpers) => {
    if (value === 0) {
      return helpers.error("number.notZero");
    }
    return value;
  })
  .messages({
  "number.empty": "Category is required.",
  "number.base": "Category is required.",
  "any.required": "Category is required.",
  "number.notZero": "Category is required.",
});
export const addArticleSchema = Joi.object({
    name: subjectSchema,
    categoryId: catSchema,
    articleTypeId: articleTypeSchema,
    ticketTypeId: ticketTypeSchema,
});

export interface iArticle {
    "id":number;
    "name":string;
    "categoryId":number;
    "ticketTypeId": number;
    "body" : string;
    "keywords": string;
    "articleTypeId": number;
    "isPublished": boolean;
    "issspAccess": boolean;
    "isActive": boolean;
    "thumbnailIconPath":string;
    "thumbnailIconId":number;
    "articleAttachmentList": any[];
    "thumbnailIconObj" ?: any;
  }