import { Box, Button, Grid, Popover, Skeleton, Tooltip, Typography } from "@mui/material";
import { debounce } from "lodash";
import { createContext, createRef, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getAIResponses } from "../../../redux/actions/ChatBot/chatBot";
import { getTicketByID } from "../../../redux/actions/TicketsManagement/TicketsManagement";
import parse from 'html-react-parser';
import { IState } from "../../../redux/reducers/rootReducers";
import { TextBoxLatest } from "../../common/TextBoxLatest/TextBoxLatest";
import AIIcon from "../../../assest/AI.png";
import { FallbackAvatar } from "../../MobileComponents/MobileUtilities/ContactView";
import { AiSuggestionContext, AiSuggestionProvider } from "./AiSuggestionContext";
import ViewArticlePopup from "./ViewArticlePopup";
import { useParams } from "react-router-dom";
interface IAISuggestionsProps {
  callback: (val: any) => void;
  AiPermission?:boolean;
  defaultVal?:any
}
interface iCallBackPayload {
  agent: Object;
  ticketType: Object;
  group: Object;
  priority: Object;
  title : String;
  description : String;
}

function AISuggestions(props: IAISuggestionsProps) {
  const dispatch = useDispatch();
  const titleRef:any = createRef();
  const params = useParams();
  const { AiPermission=false,callback,defaultVal } = props;
  const [suggestions, setSuggestions] = useState([]);

  useEffect(()=>{
    console.log("paramsticketId",defaultVal,params?.ticketId)
    if(titleRef?.current){
      titleRef.current.value = defaultVal;
      onChangeHandler({target:{value:defaultVal}})
    }
  },[defaultVal])
  const onChangeHandler = debounce((e:any) => {
    const {value} = e.target
    console.log("AISuggestions",props)
    if(!AiPermission) return;
    if (value.length > 5) {
      loadTicketSuggestions(value);
    } else {
      setSuggestions([]);
    }
  },200)

  const loadTicketSuggestions = debounce((value) => {
    dispatch(getAIResponses(value, getAIResponsesCB));
  }, 500);

  const getAIResponsesCB = (isSuccess: boolean, response: any) => {
    setSuggestions(isSuccess ? response : []);
  };
  return (
    <>
      {AiPermission && <>
        <Box className="copilot white-bg">
          <Box className="copilot-input">
            <Box> 
              <FallbackAvatar
                    src={AIIcon}
                    alt={"AI-Img"}
                    fallback={`${"AI-Img"}`}
                    width={24}
                    height={24}
                />
            </Box>
            <TextBoxLatest
                placeholder="Search Using ServoAI to assist you"
                ref={titleRef}
                onchangeHandler={onChangeHandler}
            />
          </Box>
          <AiSuggestionProvider>
            <>
              {suggestions.length > 0 &&
                <Box className=" subject_suggestion overflow-scroll-x">
                  {suggestions.map((i: any, index: number) => {
                    return (
                      <>
                        <SuggestionItem callback={callback} item={i} />
                      </>
                    );
                  })}
                </Box>
              }
            </>
          </AiSuggestionProvider>
        </Box>


        <ViewArticlePopup/>
      </>}
    </>
  );
}

const SuggestionItem = ({item,callback}:any) =>{
  const {id, title, description, ticketTypeId, categoryId, entityId, entityType, tenantCode,entityResolution} = item;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const context = useContext(AiSuggestionContext);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const useSuggestion = () =>{
    console.log('useSuggestion',context?.value)
    if(entityType==="Article"){
      dispatch({
        type:"ARTICLE_POPUP_INFO",
        payload : {
          articleId: entityId,
          articleBody : entityResolution,
          title
        }
      })
    }else{
        if(!context?.value?.ticketLogs) return;
        console.log("useSuggestion1",{
          priority : context?.value?.ticketLogs?.priority,
          ticketType :context?.value?.ticketLogs?.ticketType,
          ticketStatus :context?.value?.ticketLogs?.ticketStatus,
          note :context?.value?.ticketLogs?.note,
          title :context?.value?.ticketLogs?.title,
          description :context?.value?.ticketLogs?.description,
          category : context?.value?.ticketLogs?.category,
        })
        callback({
          priority : context?.value?.ticketLogs?.priority,
          ticketType :context?.value?.ticketLogs?.ticketType,
          ticketStatus :context?.value?.ticketLogs?.ticketStatus,
          note :context?.value?.ticketLogs?.note,
          title :context?.value?.ticketLogs?.title,
          description :context?.value?.ticketLogs?.description,
          category : context?.value?.ticketLogs?.category,
        })
    }
  }
  return(
    <>
      <Tooltip className="suggestion_tooltip" open={open} onClose={handleClose} placement="bottom-end" onOpen={handleOpen} title={<>
        <TooltipDetails suggestion={item}/>
      </>}> 
        <Typography className={`suggestion-txt ${open && "active" }`} onClick={useSuggestion}>
          {entityType === 'Article' && <small>{entityType}</small>} <span> {entityType === 'Article' ? title : entityResolution}</span>
          
          <span className="use-it">
              {entityType !== 'Article'&&<>{(context?.value?.ticketLogs) ? "Click to Use It.!" : "Loading..."}</>}
              {entityType === 'Article' && "Open Article"}
          </span>
        </Typography>
      </Tooltip>
    </>
  )
}


const TooltipDetails = ({suggestion}:any) => {
  const {id, title, description, ticketTypeId, categoryId, entityId, entityType, tenantCode,entityResolution} = suggestion;
  return(<>
  <Box className="suggestion_content">
              {entityType ==="Article" ? 
                <Box>
                  <TextContent label="Title" value={title} />
                  {/* <hr /> */}
                  {/* <Button className="suggestionBtn">Open the Article</Button> */}
                  {/* {parse(`${entityResolution || ""}`)} */}
                </Box> 
                : 
                <Box>
                  <TextContent label="Title" value={title} />
                  <TextContent label="Description" value={description} />
                  <TextContent label="Resolution Note" value={entityResolution} />
                  <SuggestionTicketInfo  ticketId={entityId}/>
                </Box>
              }
            </Box>
  </>)
}

const TinySkeleton = () =>{
  return(
    <Skeleton width="100px"
                variant="rounded"
                height={"15px"}
                animation="wave"
    ></Skeleton>
  )
}
const SuggestionTicketInfo = ({ticketId}:any) =>{
  const dispatch = useDispatch();
  const context = useContext(AiSuggestionContext);
  useEffect(()=>{
    loadData()
    return ()=>{
      console.log("SuggestionTicketInfo")
      context?.setValue && context?.setValue(null)
    }
  },[])
  const loadData = debounce(() =>{
    if (ticketId) {
      dispatch(getTicketByID(ticketId, getTicketByIDCB,false));
    }
  },200)
  const getTicketByIDCB = (resStatus: string, res: any) => {
    if (resStatus === "0") {
      context?.setValue && context?.setValue(res)
    }
  };
  return (<>
          <Box className="suggetionTicket">
            <hr/>
            <Grid container spacing={0}>
                {/* <Grid item md={6}><TextContent label="Agent" value={context?.value?.ticketLogs?.agent?.firstName ? `${context?.value?.ticketLogs?.agent?.firstName} ${context?.value?.ticketLogs?.agent?.lastName}` : undefined} /></Grid>
                <Grid item md={6}><TextContent label="Group" value={context?.value?.ticketLogs?.group?.name} /></Grid> */}
                <Grid item md={6}><TextContent label="Priority" value={context?.value?.ticketLogs?.priority?.name} isLoaded={context?.value?.ticketLogs ? true : false} /></Grid>
                <Grid item md={6}><TextContent label="Ticket Status" value={context?.value?.ticketLogs?.ticketStatus?.displayName} isLoaded={context?.value?.ticketLogs ? true : false} /></Grid>
                <Grid item md={6}><TextContent label="Ticket Type" value={context?.value?.ticketLogs?.ticketType?.name} isLoaded={context?.value?.ticketLogs ? true : false} /></Grid>
                <Grid item md={6}><TextContent label="Category" value={context?.value?.ticketLogs?.category?.name} isLoaded={context?.value?.ticketLogs ? true : false} /></Grid>
            </Grid>
          </Box>
    </>)

}


const TextContent = ({label,value="-",isLoaded=true}:any) =>{
  return(
    <Box className="pb-05">
      <Typography className="label"><u>{label} : </u></Typography>
      <Typography className="value ellipses-4l">{isLoaded ? value : <><TinySkeleton /></> }</Typography>
    </Box>
  )
}

const mapStateToProps = (state:IState) => {
  return ({
    AiPermission: state?.FeatureReducer?.featurePermissions?.AI,
  })
};

export default connect(mapStateToProps)(AISuggestions);
// export default AISuggestions;
