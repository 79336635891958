import { Box } from '@mui/material'
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { lazyLoadConversations } from '../../redux/actions/ChatBot/chatBot';
import { ChatItem, IMessageItem } from './ChatBot';

function ChatHistory() {
    const dispatch = useDispatch();
    const bodyRef: any = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState<boolean>(false);


    const [hasMore,setHasMore] = useState<boolean>(true);
    const [list,setList] = useState<any>([]);
    const [page, setPage] = useState(1);
    
    const fetchContacts = (pageNum?:number) => {
      setLoading(true);
      dispatch(lazyLoadConversations(pageNum || page,lazyLoadConversationsCB));
    }


    const lazyLoadConversationsCB = (res:any,hasMoreItems:boolean) => {
      setLoading(false);
      setPage(page+1)
      setHasMore(hasMoreItems);
      if(page === 1){
        setList(res)
      }else{
        setList((prevItems:any) => [...res, ...prevItems]);
      }
    };
    
    
    const handleScroll = debounce(() => {
      if (bodyRef.current) {
        const { scrollTop } = bodyRef.current;
        if (scrollTop === 0 && !loading && hasMore) {
          fetchContacts();
        }
      }
    },200);


    useEffect(()=>{
      fetchContacts(1);
    },[])


  // Attach the scroll event listener
    useEffect(() => {
      const container = bodyRef.current;
      if (container) {
        container.addEventListener('scroll', handleScroll);
        return () => container.removeEventListener('scroll', handleScroll);
      }
    }, [loading]);
  return (
    <Box className="chat-history-holder">
        
            {loading && <div>Loading...</div>}
            <Box className="chatbody chat-history-body noscrollbar" ref={bodyRef}>
              <Box>
                  {list.map((i: IMessageItem, index: number) => {
                          return (
                                <Box key={index}>
                                      <ChatItem type={i.type} message={i.message} createdAt={i.createdAt} />
                                </Box>)
                  })}
              </Box>
            </Box>
      
    </Box>
  )
}

export default ChatHistory
