import { Box, Button, Grid, Typography } from '@mui/material';
import { useRef } from 'react';
import ReactToPrint, { useReactToPrint } from 'react-to-print';

function PrintArticleWrapper(props: any) {
    const { children,title="" } = props;
    const componentRef = useRef<HTMLDivElement>(null);

    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    //     pageStyle: `
    //       @page {
    //         size: landscape;
    //         margin: 5;
    //       }
    //       @media print {
    //         body {
    //           visibility: hidden;
    //         }
    //         #printableArea, #printableArea * {
    //           visibility: visible;
    //         }
    //         #printableArea {
    //           position: absolute;
    //           left: 0;
    //           top: 0;
    //           width:100%;
    //         }
    //       }
    //     `,
    //     documentTitle: title,
    //     onBeforePrint: async () => {
    //         if (!componentRef.current) return;
    //         /* will be triggered before printing PDF */

    //         // const target = componentRef.current;
    //         // const canvas = await html2canvas(target, { scale: 2 });
    //         // const imageData = canvas.toDataURL('image/png');
    //         // const pdf = new jsPDF('p', 'mm', 'a4');
    //         // pdf.addImage(imageData, 'PNG', 0, 0, 210, 297);
    //         // pdf.save('file.pdf');
    //     },
    // });

    return (
        <Box>
            <Grid className='p-1 white-bg print-article' container spacing={0} alignItems={"center"}>
                <Grid item md={7}>
                    <Typography>
                        {title}
                    </Typography>
                </Grid>
                <Grid item md={5} className="text-right print-article-btn">
              
                      <ReactToPrint
                          trigger={() => <Button className='txtCapitalize' size='small'>Print Article (Landscape)</Button>}
                          content={() => componentRef.current}
                          pageStyle="@page { size: landscape; }"
                          documentTitle={title}
                      />
                      <ReactToPrint
                          trigger={() => <Button className='txtCapitalize' size='small'>Print Article</Button>}
                          content={() => componentRef.current}
                          pageStyle="@page { size: A4; }"
                          documentTitle={title}
                      />
                    
                    
                </Grid>

            </Grid>
            <Box className="pt-1">
              <Box id="printableArea" ref={componentRef}>
                  {children} 
              </Box>
            </Box>

           


        </Box>
    );
}

export default PrintArticleWrapper;
