import { Suspense, lazy, memo, useEffect, useMemo } from "react";
import Sortable from "sortablejs";
import { Box } from "@mui/material";
import { PriorityColumnSkeletonLoader } from "./DashboardSkeletonLoader";
import StatusTicketsList from "./StatusTicketsList";
const StatusDropable = lazy(()=>import("./StatusDropable"));
export const StatusColumn = memo((props:any) =>{
    const {columnId,columnName} = props;
    useEffect(()=>{
        
        var usersList: any = document.getElementById(`statusColumnId${columnId}`);
        if(!usersList) return;
        var sortable = Sortable.create(usersList, {
          group: {
            name: "list",
            put: false,
            pull: "clone",
          },
          onStart: function (e:any) {
            // alert('dropped')
            let evt: any = document.getElementsByTagName("html");
            evt[0].classList.add("ItemDragged");
            document.getElementById(`StatusDropable-${columnId}`)?.classList?.add('d-none');
          },
          onEnd: function (e:any) {
            let evt: any = document.getElementsByTagName("html");
            evt[0].classList.remove("ItemDragged");
            document.getElementById(`StatusDropable-${columnId}`)?.classList?.remove('d-none');
          },
        });
    },[])
    const dropable = useMemo(()=><StatusDropable id={`${columnId}`} columnName={columnName} />,[columnId])
    // const list = useMemo(()=>
    // <StatusTicketsList columnId={columnId}/> 
    // ,[columnId])
    return(
        <>
        <Box className="pt-05" id={`statusColumnId${columnId}`}>
          {dropable}
          <Suspense fallback={<><PriorityColumnSkeletonLoader/></>}>
                    {/* {list} */}
                    <StatusTicketsList columnId={columnId}/> 
          </Suspense>
          
            {/* <Suspense fallback={<><PriorityColumnSkeletonLoader/></>}>
                    <StatusTicketsList columnId={columnId}/>
            </Suspense> */}
        </Box>
        </>
    )
});
export default StatusColumn;