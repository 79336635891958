import { forwardRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getArticles } from '../../../redux/actions/KnowledgeBase/article';
import { Box } from '@mui/material';
import MaterialTable from "material-table";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SyncAltRoundedIcon from "@mui/icons-material/SyncAltRounded";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const tableIcons: any = {
    Search: forwardRef((props, ref) => <SearchRoundedIcon />),
    DetailPanel: forwardRef((props, ref) => <ChevronRightIcon />),
    Clear: forwardRef((props, ref) => <CloseRoundedIcon />),
    ResetSearch: forwardRef((props, ref) => <CloseRoundedIcon />),
    SortArrow: forwardRef((props, ref) => (
      <SyncAltRoundedIcon
        sx={{ color: "gray", transform: "rotate(90deg)", width: "15px" }}
      />
    )),
    Export: forwardRef((props, ref) => <GetAppRoundedIcon />),
    Filter: forwardRef((props, ref) => (
      <FilterListRoundedIcon sx={{ width: "20px" }} />
    )),
    FirstPage: forwardRef((props, ref) => (
      <FirstPageRoundedIcon sx={{ width: "20px" }} />
    )),
    LastPage: forwardRef((props, ref) => (
      <LastPageRoundedIcon sx={{ width: "20px" }} />
    )),
    NextPage: forwardRef((props, ref) => (
      <NavigateNextRoundedIcon sx={{ width: "20px" }} />
    )),
    PreviousPage: forwardRef((props, ref) => (
      <NavigateBeforeRoundedIcon sx={{ width: "20px" }} />
    )),
  };

export default function ArticlesTable(props:any) {
    const {callback,list=[]} = props;
    console.log('listlistlistlist',list)
    return (
        <Box className="article_table_select">
            <MaterialTable
                onSelectionChange={(selected: any) => {
                  console.log("selected_selectedArr", selected);
                  callback(selected)
                //   selectedArr = selected;
                }}
                options={{
                  filtering: false,
                  sorting: false,
                  exportButton: false,
                  showTitle: false,
                  selection: true,
                  showFirstLastPageButtons: false,
                  paginationType: "stepped",
                  headerStyle: {
                    backgroundColor: "#EAF2FD",
                  },
                }}
                localization={{
                  toolbar: {
                    nRowsSelected: "{0} Article(s) selected",
                  },
                  body: {
                    emptyDataSourceMessage: "No Articles to display",
                  },
                }}
                columns={[
                  { title: "Id", field: "id", width:"50px" },
                  { title: "Name", field: "name", width:"95%" },
                ]}
                data={list}
                title=""
                icons={tableIcons}
              />
        </Box>
    );
}