import { ConstructionOutlined } from '@mui/icons-material';
import axios from 'axios';
import configs from '../../configs/config';
import { ActionType } from '../../redux/actions/Snackbars';
import * as intl from "react-intl-universal";
import LanguageData from "../../configs/LanguageData.json";
import { triggerAppAlert } from '../../redux/actions/SelfServicePortal/ContactAid/ContactAid';
const url = `${configs.BASE_URL}/AssetType`;



const loading = (status: any) => {
    return {
      type: "loading",
      payload: status,
    };
  };

  export const getAssetType = async () => {
    try {
      const tickets = await axios.get(`${configs.BASE_URL}/AssetType`);
        console.log("api is called",tickets.data);
        return tickets.data;
        
    } catch (e) {
        console.log(e)
    }
}
export const assetTypeAdd = async (data: any, callback:any) => {
    try {
      ;
      const response = await axios.post(url,data);
      console.log(response);

      callback('0',response.data)
    } catch (e:any) {
      ;
      callback('1',e.response.data)
      console.log(e.response)
    }
};
export const getAssetTypeById = async (id:string, callback:any ) => {
  try {
    ;
    const response = await axios(url+'/'+id);
    console.log(response);

    callback('0',response.data)
  } catch (e:any) {
    ;
    callback('1',e.response.data)
    console.log(e.response)
  }
};
export const updateAssetTypeStatus = async (data: any, id: any) => {

    try {
      await axios.put(`${configs.BASE_URL}/AssetType/${id}`, data)
  
     
      return true

    } catch (error:any) {
      if (error.response) {
       
        return error.response.data
      }  
    }
};
export const editAssetType = (id:number,request:any,callback:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.put(`${url}/${id}`,request);
      ;
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_ASSET_TYPE}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      });
      console.log(`${url}/${id}`);
      callback();
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};




export const softDeleteAssetType = async (id: any) => {
  const statusMsg = id ? `${LanguageData.SUCCESS_ASSET}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_ASSET}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
    try {
      await axios.delete(`${configs.BASE_URL}/AssetType/${id}`);  

      return true
    } catch (error:any) {
      if (error.response) {
       
        return  error.response.data
      }  
    }
};

export const paramentDeleteAssetType = async (id: any) => {

    try {
      await axios.delete(`${configs.BASE_URL}/AssetType/permanent/delete/${id}`);  
     
      return true

    } catch (error:any) {
      if (error.response) {
       
        return error.response.data
      }  
    }
};


export const multipleUpdateAssetType = async ( ids: any,status:any) => {
    try {
      await axios.delete(`${configs.BASE_URL}/AssetType/status/change/?ids=${ids}&status=${status}`)
       
      return true
    } catch (error:any) {
      if (error.response) {
       
        return error.response.data
      }  
    }
};


export const SnackBar =(msg: any,severity:string = "success" )=>{
  return async (dispatch: any) => {

  dispatch({
    type: ActionType.SNACKBAR,
    payload: {
      message: msg,
      severity: severity,
      open: true,
    },
  });
}
}

export const testApi = async (data: any) => {
  try {
     await axios.post(`http://192.168.0.101:7274/Ticket/check`, {
      "CreatedDateTime": new Date().toISOString()
  }, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return true

  } catch (error:any) {
    if (error.response) {
     
      return error.response.data
    }  
  }
};


