import { ActionType } from "../Snackbars";
import LanguageData from "../../../configs/LanguageData.json"

export const newToMeDropUpdate = (ticketId:any) =>{
  return async (dispatch: any) => {
    console.log('newToMeDropUpdate_ticketId',ticketId)
    dispatch({ type: "DROPPED_TO_NEWTOME", payload:ticketId});
  }
}
export const priorityToPriorityUpdate = (ticketId:any,objToReplace:any,response?:any) =>{
    return async (dispatch: any) => {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS}${ticketId} ${LanguageData.TICKET_UPDATED_SUCESSFULLY}${objToReplace?.priorityId} `,
          severity: "success",
          open: true,
        },
      });
      dispatch({ type: "DROPPED_TO_PRIORITY", payload:{ticketId,objToReplace,response}});
    }
}
export const statusToStatusUpdate = (ticketId:any,objToReplace:any,response?:any) =>{
  return async (dispatch: any) => {
    dispatch({
      type: ActionType.SNACKBAR,
      payload: {
        message: `${LanguageData.SUCCESS}${ticketId} ${LanguageData.TICKET_UPDATED_SUCESSFULLY}${objToReplace?.statusId}`,
        severity: "success",
        open: true,
      },
    });
    dispatch({ type: "DROPPED_TO_STATUS", payload:{ticketId,objToReplace,response}});
  }
}
export const groupListDropableUpdate = (ticketId:any,objToReplace:any,isMyTickets:any,response?:any) =>{
  return async (dispatch: any) => {
    dispatch({ type: "DROPPED_TO_GROUPLIST", payload:{ticketId,objToReplace,isMyTickets,response}});
  }
}
export default newToMeDropUpdate;