import { useSelector } from "react-redux";
import CommonStyles from "../../../../../common/CommonStyles";
import { IState } from "../../../../../../redux/reducers/rootReducers";
import { useNavigate } from "react-router-dom";
import { lazy, memo, useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import TicketCard from "../../../Ticketcard";
import TicketCardNew from "../../../../../TicketManagementSystem/Modules/TicketCard";
import CustomChip from "../../../../../common/chip/CustomChip";
import Merge from "../../../../../merge/popup/Merge";
//const TicketCard = lazy(()=>import("../../../Ticketcard"));
const PriorityTicketList = memo((props:any) =>{
    const {columnId} = props;
    const navigate = useNavigate();
    const AllTickets = useSelector((state: IState) => state?.AllTicketsReducer?.otherTickets);
    const [allTickets,setAllTickets] = useState([]);
    

    
    const dummyFunction = useCallback((id: number)=>{
        console.log("")
        navigate(`/home/ticket/${id}`);
    },[]);
    useEffect(()=>{
        if(!AllTickets?.filter) return;        
        setAllTickets(AllTickets?.filter((item:any)=>item?.ticketLogs?.priorityId === columnId))
    },[AllTickets])
    const ticketCards = useMemo(()=><>
        {allTickets?.map((item:any)=>{
            return(
                <>
                
                    <Box ticket-id={item.id}>

                        <TicketCardNew
                            ticketData={item}
                        />
                        {/* {item.id} {item?.ticketLogs?.title}
                        {item?.ticketLogs?.contactVal?.name} */}
                    </Box>
                </>
            )
        })}
    </>,[allTickets])
    return(
        <>
            {ticketCards}
        </>
    )
})
export default PriorityTicketList;