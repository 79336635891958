import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from "@mui/material/InputAdornment";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { createTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import React, { createRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import addIcon from "../../assest/icons/plus.svg";
import CommonStyles from "../common/CommonStyles";
import { TextBoxLatest } from "../common/TextBoxLatest/TextBoxLatest";
import { debounce } from "lodash";

const theme = createTheme();
const useStyles = makeStyles(() =>
  createStyles({
    Box: {
      marginTop: "0px",
      padding: "10px 0",
      display: "flex",
      gap:10,
      alignItems: "center",
      // marginLeft: "-15px",
      // marginRight: "-15px",
      borderBottom: "1px solid #ddd",
      "@media screen and (max-width : 980px)": {
        flexWrap: "wrap",
        // background: mode.solids,
        marginLeft: "-10px",
        marginRight: "-10px",
        ">div": {
          flexWrap: "wrap",
        },
      },
    },
    padLeft: {
      paddingLeft: "30px",
    },
    searchBox: {
      width: "200px",
    },
    input1: {
      height: 6,
      fontSize: "13px",
      fontWeight: 400,
      paddingLeft: '0px !important',
    },
    searchIcon: {
      fontSize: "16px",
      marginLeft: "5px"
    },
    btn: {
      "&.hover": {
        backgroundColor: "red",
      },
      fontSize: "10px",
      textTransform: "none",
      "@media screen and (max-width : 980px)": {
        marginLeft: "5px",
        fontSize: "9px !important",
        textTransform: "uppercase",
        color: "#000000",
        "& img": {
          height: "14px",
        },
      },
    },
    activebtn: {
      border: "1px solid blue",
      marginLeft: "1rem",
      fontSize: "10px",
      textTransform: "none",
      "@media screen and (max-width : 980px)": {
        marginLeft: "5px",
        fontSize: "9px !important",
        textTransform: "uppercase",
        color: "#000000",
        "& img": {
          height: "14px",
        },
      },
    },
    title: {
      fontSize: 12,
      background: "transparent",
      border: "none",
      outline: "none",
      padding: 0,
      cursor: "none",
    },
    visibleCondtion: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      gap:"10px",
      paddingLeft: "10px",
    },
    unVisibleCondtion: {
      display: "none",
    },
    backIcon: {
      marginLeft: "10px",
      marginRight: "10px",
    },
    TopFilter: {
      position: "sticky",
      top: "81px",
      // background: mode.solids,
      zIndex: 999,
      paddingTop: "10px",
      "& button": {
        // color: mode.text
      },
      "@media screen and (max-width : 980px)": {
        position: "static",
        marginLeft: "-10px",
      },
    },
    actBtn: {
      minWidth: "auto",
      "& img ": {
        marginLeft: "10px",
        height: "20px",
        cursor: "pointer",
        "@media screen and (max-width : 980px)": {
          height: "13px",
        },
      },
    },
  })
);

interface ITopFilterNew{
  callback : any;
  addHandler ?: any;
  backNavigationPath : string;
  extraFeatures? : any;
  hideAddBtn?: boolean;
  hideToggleBtns?: boolean;
}

function TopFilterNew(props: ITopFilterNew) {
  const { callback, addHandler, backNavigationPath, extraFeatures,hideToggleBtns=false,hideAddBtn=false} = props;
  const searchRef:any = createRef();
  const classes = useStyles();
  const cStyles = CommonStyles();
  const addDataHandler = () => {
    if(addHandler)addHandler();
  };

  const onSearchKeyDownHandler = (event: any) =>{
    if (event.key === 'Enter') {
        // Handle the Enter key event here
        console.log('Enter key pressed');
        triggerCallback()
    }
  }

  const onChangeHandler = debounce((event: any) =>{
    triggerCallback()
  },1000)


  const [activeStatus, setActiveStatus] = React.useState('active');
  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveStatus((event.target as HTMLInputElement).value);
  };
  useEffect(()=>{
    triggerCallback()
  },[activeStatus])
  
  const triggerCallback = () =>{
    callback({
        search : searchRef?.current?.value || "",
        isActive : activeStatus === "active"
    })
  }
  return (
    <div className={`${classes.TopFilter} ${cStyles.themeBgColor} common-top-filter`}>
      <Box className={`${classes.Box} contacts-first-filter`}>
        <Link to={backNavigationPath}>
          <Tooltip title="Back"><IconButton><ArrowLeftIcon className="backIcon" /></IconButton></Tooltip>
        </Link>
        <Box>
            <TextBoxLatest
                placeholder="Please Enter"
                ref={searchRef}
                onKeyDownHandler={onSearchKeyDownHandler}
                onchangeHandler={onChangeHandler}
                type="search"
                InputProps={{
                  classes: { input: classes.input1 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
            />
        </Box>
        {!hideAddBtn&& 
        <Box>
            <Button
                size="small"
                className={`${classes.btn} common-btn`}
                onClick={addDataHandler}
            >
                <img src={addIcon} alt="add_icon" style={{ marginRight: "8px" }} />
                Add New
            </Button>
        </Box>
        }

        
        {!hideToggleBtns &&
          <Box>
            <FormControl className="top-filter-active-btns">
              <RadioGroup
                row
                aria-labelledby="status-btns"
                name="status-btns"
                value={activeStatus}
                onChange={handleStatusChange}
              >
                <FormControlLabel className={`${activeStatus ==="in-active" ? "checked" : ""}`} value="in-active" control={<Radio />} label="Show In-Active" />
                <FormControlLabel className={`${activeStatus ==="active" ? "checked" : ""}`} value="active" control={<Radio />} label="Show Active" />
              </RadioGroup>
            </FormControl>
          </Box>
        }

        <Box className="last-col">
            {extraFeatures && extraFeatures}
        </Box>
        
      </Box>

    </div>
  );
}

export default TopFilterNew;
