import { Box, Button, Typography } from "@mui/material";
import CustomModalDialog from "./customDialog";
import CommonStyles from "./CommonStyles";
import CancelIcon from "@mui/icons-material/Cancel";
export const TicketQuickView = (props:any) =>{
    const {ticketInfo,onCloseModal,openStatus,onSubmitForm}= props;
    console.log("ticketInfo", ticketInfo)
    const classes = CommonStyles();
    return (
        <>
            <CustomModalDialog
                title=""
                parentClasses="sml-popup"
                id="raiseTicketModalSSP"
                isOpen={openStatus}
                onClose={onCloseModal}
                // headerContent={<>
                //     {/* <Typography variant="body2">
                //         # Ticket ID :  {ticketInfo?.id}
                //     </Typography> */}
                // </>}
                bodyContent={<>
                    <Box className="text-center">
                    <Typography variant="body2">
                       Subject :  {ticketInfo?.ticketLogs?.title}
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                    Closing ticket with ID - {`${ticketInfo?.id}`} Are you sure you want to close it?
                    </Typography>
                    <br/>
                    <Box className="text-center">
                        <Button
                            className={`${classes.btn} ${classes.btnCancel}`}
                            variant="outlined"
                            onClick={onCloseModal}
                        >
                            <CancelIcon style={{ marginRight: "5px" }} />
                            Cancel
                        </Button>

                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <Button
                            className={`${classes.btn} ${classes.btnSubmit}`}
                            variant="contained"
                            onClick={()=>{onSubmitForm(ticketInfo)}}
                        >
                            Proceed
                        </Button>
                    </Box>
                    </Box>
                </>}
            />
        </>
    )
}
export default TicketQuickView;