import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Card from "../common/card";
import TopFilter from "../common2/TopFilter";
import Breadcrumbs from "./../common2/Breadcrumbs";
import ChartIcon, {CSVIcon, PowerBiIcon} from "../common/svgIcons/chartIcon";
import CommonStyles from "../common/CommonStyles";
import ViewIcon from "../../assest/icons/viewIcon.svg";
import editIcon from "../../assest/icons/edit.svg";
import { useNavigate } from "react-router-dom";
import  { getPivotTableAction } from "../../redux/actions/Reports/reports";
import { useDispatch } from "react-redux";
import CommonAppFilter from "../navbar/sideNavbar/CommonAppFilter";

const AllPivotTable = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes  = CommonStyles();
    const [records, setRecords] = useState([]);
    const [seachData, setSearchData] = useState("");
    const [listStatus, setlistStatus] = useState(true);
    const [list, setlist] = useState([]);
    const [update, setUpdate] = useState(false);
    const updateAllHandler = (value: any, id: any) => {};

    const showDataHandler = (show: any) => {
        setlistStatus(show);
    };

    const handleSearch = (e: any) => {
        setSearchData(e.target.value);
    };
    const getPivotTable = async () => {
        let res = await getPivotTableAction();
        return res;
      };
    const getReportsCB = (resStatus:string,data:any)=>{
        console.log("123data",resStatus, data)
        if(resStatus === "0"){
            setRecords(data)
        }
    }
    useEffect(()=>{
        let s = getPivotTable();
        s.then((res: any) => {
            console.log('getPivotTableres', res)
            setRecords(res)
        })
    },[])
    return (
        <>
            <Breadcrumbs
                data={[
                    {
                        title: 'Reports Management ',
                        path: '/ReportsManagement'
                      },
                      {
                        title: 'Custom Pivot table ',
                        path: '/All-Pivot-Table'
                      }
                ]}
            />
            <Box className="pageHolder">
                
                <TopFilter
                    path="/pivot-table"
                    Data={list}
                    SearchHandler={handleSearch}
                    value={seachData}
                    filterHandler={updateAllHandler}
                    checkBoxHandler={updateAllHandler}
                    checked={false}
                    hideSelectAllOption={true}
                    hideActiveBtns={true}
                    filterItems={showDataHandler}
                    updateAllHandler={updateAllHandler}
                    titleState={false}
                    // hideAddBtn={true}
                    pathname="/CreateReport"
                    placeHolder="Search ..."
                />
                
                <Grid container className="pt-1" spacing={1}>
                    {records && records.map((item: any, key: number) => {
                        const { icon, name, path, id } = item;
                        return (
                            <Grid item md={3} xs={12} key={key}>
                                <Card
                                    styleProp={classes.cardStyle}
                                    customHeader={
                                        <Box
                                        className={`${classes.row} ${classes.alignItemsCenter} ${classes.justifyBetween} ${classes.w100}`}
                                        >
                                        <Typography variant="body2" className={classes.title}>
                                            Report ID : {id}
                                        </Typography>
                                        {/* <div>
                                            {icon}
                                        </div> */}
                                        </Box>
                                    }
                                    cardBody={
                                        <Box>
                                            <Typography variant="body2">
                                                <b>Name : </b> {name}
                                            </Typography>
                                        </Box>
                                    }
                                    cardFooter={
                                        <Box>
                                          <Tooltip title="View">
                                            <img
                                              src={editIcon}
                                              alt="view icon"
                                              onClick={()=>{navigate(`/pivot-table/${id}`)}}
                                            />
                                          </Tooltip>
                                     </Box>   
                                    }
                                    />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </>

    )
}
export default AllPivotTable;