import {dataStructure} from './formSysAdminData';
export const adminTypes:any = {};
    
const ApiRequest:any = (permissionList:any,systemType:any,UserType:any,Data:any,AccessData:any,AgentData:any,SSPAccessData:any) =>{
    const allowAccessPerm = permissionList.filter((x:any)=>x.name === 'AllowAccess');
    const allowAccessId = allowAccessPerm.length > 0 ? allowAccessPerm[0].id : 0;
    const request = Data;
    if(UserType.superAdmin){
        request.isSuperAdmin =  UserType.superAdmin;
        return request;
    }
    systemType.forEach((a:any)=>{
        if(a.parentId === null){
            adminTypes[a.name] = a.id
        }
    });
    const generateList = (adminTypeId:number,permId:any) =>{
        const permissionFormat = {
            "adminTypeId": adminTypes[adminTypeId],//systemType [id]
            "adminChildIdLeavel1": null,// INCIDENT (COL-1) [ID]
            "adminChildIdLeavel2": null,
            "adminChildIdLeavel3": null,
            "adminChildIdLeavel4": null,
            "permissionId": permId,//PERMISSIONS LIST - (ALLOW ACCESS -ID) - STATIC
            "isActive": true,
            "isContact":false,//SSP role
            "isAgentAdmin": false, // FROM AGENT PT ADMIN
            "isSystemAdmin": true // FROM admin PT ADMIN
        };
        return permissionFormat;
    }
    
    
   

    // generate permissions list
    const modifiedList:any =[];
    Object.keys(AccessData).forEach((x:any)=>{
        Object.keys(AccessData[x]).forEach((i:any)=>{
            if(i !== 'title' && i !== "onlyAllowAccess"){
                AccessData[x][i].permissions.forEach((j:any)=>{
                    if(j.checked && adminTypes[i]){
                        modifiedList.push(generateList(i,j.id))
                    }
                })
            }
        })
    })





    // =================================== AGENT PERMISSIONS ==================================
    console.log('API REQUEST', AgentData)
    const AgentGenerateList = (adminTypeId:string,permId:any=null,level1:number | null = null,level2:number | null = null,level3:number | null = null,level4:number | null = null) =>{
        const permissionFormat = {
            "adminTypeId": adminTypes[adminTypeId],//systemType [id]
            "adminChildIdLeavel1": level1, // INCIDENT (COL-1) [ID]
            "adminChildIdLeavel2": level2,
            "adminChildIdLeavel3": level3,
            "adminChildIdLeavel4": level4,
            "permissionId": permId, //PERMISSIONS LIST - (ALLOW ACCESS -ID) - STATIC
            "isActive": true,
            "isContact":false,//SSP role
            "isAgentAdmin": true, // FROM AGENT PT ADMIN
            "isSystemAdmin": false // FROM admin PT ADMIN
        };
        return permissionFormat;
    }
    AgentData.categoryData.forEach((i:any,index:number)=>{ //pushing category data to payload
        i.isChecked && modifiedList.push(AgentGenerateList('Categories' , allowAccessId, i.id))
        i.children.forEach((j:any,jIndex:number)=>{
            j.isChecked && modifiedList.push(AgentGenerateList('Categories' , allowAccessId, i.id, j.id))
            j.children.forEach((k:any,kIndex:number)=>{
                k.isChecked && modifiedList.push(AgentGenerateList('Categories' , allowAccessId, i.id, j.id, k.id))
                k.children.forEach((l:any,lIndex:number)=>{
                    l.isChecked && modifiedList.push(AgentGenerateList('Categories' , allowAccessId, i.id, j.id, k.id, l.id))
                });
            });
        });
    });

    //console.log(adminTypes)
    AgentData.AllAgentData.Groups.map((i:any)=>{
        i.isChecked && modifiedList.push(AgentGenerateList('Groups' , allowAccessId, i.id));
    })
    AgentData.AllAgentData.TicketStatus.map((i:any)=>{
        i.isChecked && modifiedList.push(AgentGenerateList('TicketStatus' , allowAccessId, i.id));
    })


    AgentData.AllAgentData.MailService.map((i:any)=>{
        i.isChecked && modifiedList.push(AgentGenerateList('MailService' , allowAccessId, i.id));
    })

    Object.keys(AgentData.AllAgentData.TicketTypes).map((i:any)=>{
        AgentData.AllAgentData.TicketTypes[i].permissions.map((j:any)=>{
            j.checked && modifiedList.push(AgentGenerateList('TicketTypes', j.id, AgentData.AllAgentData.TicketTypes[i].ticketTypeId))
        });
    })

    // =============== AGENT PRIORITIES ==================
    AgentData.priorityData.map((i:any)=>{
        i.permissions.map((j:any)=>{
            //console.log(adminTypes,`AgentGenerateList('TicketTypes', j.id, i.id)`,AgentGenerateList('TicketTypes', j.id, i.id))
            j.checked && modifiedList.push(AgentGenerateList('Priorities', j.id, i.id))
        })
    })


    /* ==================== SSP User ========================*/
    console.log('SSPAccessData',SSPAccessData)
    SSPAccessData.priorityData.map((i:any)=>{
        i.permissions.map((j:any)=>{
            //console.log('AgentGenerateList123',{...AgentGenerateList('Priorities', j.id, i.id),"isContact":true,"isAgentAdmin":false})
            j.checked && modifiedList.push({...AgentGenerateList('ContactAid', j.id, i.id),"isContact":true,"isAgentAdmin":false})
        })
    })
    Object.keys(SSPAccessData.AllAgentData.TicketTypes).map((i:any)=>{
        SSPAccessData.AllAgentData.TicketTypes[i].permissions.map((j:any)=>{
            j.checked && modifiedList.push({...AgentGenerateList('TicketTypes', j.id, AgentData.AllAgentData.TicketTypes[i].ticketTypeId),"isContact":true,"isAgentAdmin":false})
        });
    })
    SSPAccessData.categoryData.forEach((i:any,index:number)=>{ //pushing category data to payload
        i.isChecked && modifiedList.push({...AgentGenerateList('Categories' , allowAccessId, i.id),"isContact":true,"isAgentAdmin":false})
        i.children.forEach((j:any,jIndex:number)=>{
            j.isChecked && modifiedList.push({...AgentGenerateList('Categories' , allowAccessId, i.id, j.id),"isContact":true,"isAgentAdmin":false})
            j.children.forEach((k:any,kIndex:number)=>{
                k.isChecked && modifiedList.push({...AgentGenerateList('Categories' , allowAccessId, i.id, j.id, k.id),"isContact":true,"isAgentAdmin":false})
                k.children.forEach((l:any,lIndex:number)=>{
                    l.isChecked && modifiedList.push({...AgentGenerateList('Categories' , allowAccessId, i.id, j.id, k.id, l.id),"isContact":true,"isAgentAdmin":false})
                });
            });
        });
    });
  
    request.agentPermissionsList = modifiedList;
    return request;
}
export default ApiRequest;