const initialState = {
  Data: [],
};

const slaReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "getSLA":
      return {
        ...state,
        Data: action.payload,
      };
    case "singleSLA":
      return {
        ...state,
        Data: action.payload,
      };
    case "updateSLA":
    case "multipalUpdateSLA":
    case "multipalParmanentDeleteSLA":
    case "softDeleteSLA":
      return {
        ...state,
      };
    case "deleteSLA":
      return {
        ...state,
      };
    case "loading":
      return {
        ...state,
        showLoading: action.payload,
      };

    default:
      return state;
  }
};

export default slaReducer;
