import axios from "axios";
import configs from "../../../configs/config";
import { alertMessage, triggerAppAlert } from "../SelfServicePortal/ContactAid/ContactAid";
import { iSurvey } from "../../../components/Survey/schema";
import LanguageData from "../../../configs/LanguageData.json"
export const url = `${configs.BASE_URL}/survey`;
export const CommonGetSurveyurl = `${configs.BASE_URL}/common/Survey`;
export const urlStatusChange = `${configs.BASE_URL}/survey/status/change?ids=`;
export const sspUrl = `${configs.BASE_URL}/user/article`;

export const getSurveys = (callback?:any,isSSP:boolean  =false) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${isSSP ? sspUrl : url}`);
      response.data = response.data.map((i:any)=>{
        return {...i,rid:`#${i.id}`,value:i.name,label:i.name}
      }) 
      dispatch({
        type : "STORE_ALL_SURVEY",
        payload : response.data
      })
      try{callback && callback("0",response.data)}catch(e){}
    } catch (error:any) {
      callback && callback("1",error.response)
      return console.log(error.response);
    }
  };
};
export const getSurveyById = (id:string|number,callback?:any,isSSP?:boolean) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${isSSP ? sspUrl : url}/${id}`);
      if(response?.data?.webform){ 
        response.data.webform = {
          ...response.data.webform,
          label: response.data.webform.name,
          value: response.data.webform.name
        }
      }
      try{callback && callback("0",response.data)}catch(e){}
    } catch (error:any) {
      callback && callback("1",error.response)
      return console.log(error.response);
    }
  };
};
export const getSurveyByCode = (id:string|number,callback?:any,TenantCode?:any) => {
  console.log("TenantCode1",TenantCode);
  return async (dispatch: any) => {
    try {
      const response = await axios(`${CommonGetSurveyurl}/${id}`,{
        headers: {
          "tenant-code": TenantCode,
        },
      });
      if(response?.data?.webform){ 
        response.data.webform = {
          ...response.data.webform,
          label: response.data.webform.name,
          value: response.data.webform.name
        }
      }
      console.log("TenantCode",TenantCode);
      try{callback && callback("0",response.data)}catch(e){}
      
    } catch (error:any) {
      callback && callback("1",error.response)
      return console.log(error.response);
    }
  };
};
export const addSurvey = (data: iSurvey, callback: any  = undefined) => {
    return async (dispatch: any) => {
      try {
        const response = await axios.post(url,data);
        dispatch(alertMessage(`${LanguageData.SUCCESS_NEW_SURVEY}${LanguageData.TICKET_CREATED}`,"success"))
        try{callback && callback("0",response.data)}catch(e){}
      } catch (error:any) {
        dispatch(alertMessage(error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.","error"))
        callback && callback("1",error.response)
        return console.log(error.response);
      }
    };
};
export const editSurvey = (id:any,data: any, callback?:any) => {
    return async (dispatch: any) => {
      try {
        const response = await axios.put(`${url}/${id}`,data);
        dispatch(alertMessage(`${LanguageData.SUCCESS_SURVEY}${LanguageData.TICKET_UPDATED}`,"success"))
        try{callback && callback("0",response.data)}catch(e){}
      } catch (error:any) {
        dispatch(alertMessage(error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.","error"))
        callback && callback("1",error.response)
        return console.log(error.response);
      }
    };
};
export const statusUpdateSurvey = (id:any,status: any, callback?:any) => {
  const statusMsg = status ? `${LanguageData.SUCCESS_SURVEY}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_SURVEY}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${urlStatusChange}${id}&status=${status}`);
      dispatch(getSurveys())
      dispatch(triggerAppAlert(statusMsg, 'success'))
      try{callback && callback("0",response.data)}catch(e){}
    } catch (error:any) {
      dispatch(alertMessage(error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.","error"))
      callback && callback("1",error.response)
      return console.log(error.response);
    }
  };
};