import { Box, Button, Grid, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import CommonTextArea from "../../../common/textarea/CommonTextarea";
import CustomFormLabel from "../../../common/form-label/CustomFormLabel ";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { createStyles, makeStyles } from "@mui/styles";
import { connect, useDispatch } from "react-redux";
import addRole, { unsetSelectedOptions, updateRole } from "../../../../redux/actions/userManagement/role";
import { IState } from "../../../../redux/reducers/rootReducers";
import { generateApiRequest } from "./generateApiRequest";
import { useNavigate, useParams } from "react-router-dom";
import { debounce } from "lodash";

const FormFields = memo((props: any) => {
    const param = useParams();
    const navigate = useNavigate();
    const { initialVals, callback, systemItems, rankingObj, agentItems,systemTpesByName,categoryObject, permissions=[] } = props;
    const dispatch = useDispatch()
    const classes = useStyles();
    const [name,setName] = useState(initialVals?.name);
    const [description,setDescription] = useState(initialVals?.description);
    //{ name: "", description: "", isSuperAdmin: false, isSysAdmin: false, isAgent: false, isSSPUser: false })
    const [isSuperAdmin,setisSuperAdmin] = useState(initialVals?.isSuperAdmin);
    const [isSysAdmin,setIsSysAdmin] = useState(initialVals?.isSysAdmin);
    const [isAgent,setIsAgent] = useState(initialVals?.isAgent);
    const [isSSPUser,setIsSSPUser] = useState(initialVals?.isSSPUser);
    useEffect(()=>{
        console.log('categoryObject_categoryObject',categoryObject)
    },[categoryObject])
    const onChangeSuperAdmin = useCallback(() =>{
        dispatch(unsetSelectedOptions()) 
        setisSuperAdmin(!isSuperAdmin);
        setIsSysAdmin(false);
        setIsAgent(false);
        setIsSSPUser(false);
    },[isSuperAdmin]);
    const onChangeSystemAdmin = useCallback(() =>{
        setIsSysAdmin(!isSysAdmin);
        dispatch(unsetSelectedOptions("UNSET_SYSTEM_ROLE_ITEMS"))
    },[isSysAdmin]);
    const onChangeAgent = useCallback(() =>{
        setIsAgent(!isAgent);
        dispatch(unsetSelectedOptions("UNSET_AGENT_ROLE_ITEMS"))
    },[isAgent]);
    const onChangeSSP = useCallback(() =>{
        dispatch(unsetSelectedOptions())
        setIsSSPUser(!isSSPUser);
    },[isSSPUser]);
    const onChangeHandler = useCallback((e:React.ChangeEvent<HTMLInputElement>|any) =>{
        const {value,name} = e.target;
        if(name === "name"){
            setName(value);
        } else{
            setDescription(value);
        }
    },[])
    useEffect(()=>{
        console.log('initialVals.description',initialVals.name)
    },[])
    useEffect(()=>{
        callback({
            name,
            description,
            isSuperAdmin,
            isSysAdmin,
            isAgent,
            isSSPUser,
        })
    },[name,description,isSuperAdmin,isSysAdmin,isAgent,isSSPUser])
    const SubmitHandler = debounce(() =>{
        console.log("SubmitHandler systemItems" , systemItems)
        console.log("SubmitHandler agentItems" , agentItems)
        console.log("SubmitHandler name" , name)
        console.log("SubmitHandler description" , description)
        console.log("SubmitHandler isSuperAdmin" , isSuperAdmin)
        console.log("SubmitHandler isSysAdmin" , isSysAdmin)
        console.log("SubmitHandler isAgent" , isAgent)
        console.log("SubmitHandler isSSPUser" , isSSPUser)
        //generateApiRequest(payload);
        const payload = generateApiRequest({id:param.id,systemItems, agentItems,systemTpesByName, name, description, isSuperAdmin, isSysAdmin, isAgent, isSSPUser, categoryObject,rankingObj,permissions});
        console.log("payload_generateApiRequest",payload)
        // return;
        if(!param?.id){
            dispatch(addRole(payload,postUpdate));
        }else {
            dispatch(updateRole(payload,param.id,postUpdate))
        }
    },500)
    const postUpdate = (resSts:string) => {
        if(resSts === '0'){
          //navigate(-1);
          navigate(`/Roles`);
        }
    };
    const nameField = useMemo(() => <>
        <Box className="">
            <CustomFormLabel
                labelName={"Title"}
                isMandotary={true}
                classNames='sml-txt'
            />
            <CommonTextArea
                multiline={false}
                name={"name"}
                onChange={onChangeHandler}
                value={initialVals.name}
            />
        </Box>
    </>, []);
    const descField = useMemo(() => <>
        <Box className="pt-1">
            <CustomFormLabel
                labelName={"Description"}
                isMandotary={true}
                classNames='sml-txt'
            />
            <CommonTextArea
                multiline={true}
                name={"description"}
                onChange={onChangeHandler}
                value={initialVals.description}
            />
        </Box>
    </>, []);
    const userType = useMemo(()=><>
        <Box className="">
            <Box className="pb-1 pt-1">
                <Box className={`${classes?.row} ${classes?.gap5}`}>
                    <Typography className="radio-label" onClick={onChangeSuperAdmin}>
                        {isSuperAdmin ? <CheckBoxIcon /> :  <CheckBoxOutlineBlankIcon /> }
                        <span> isSuper Admin</span>
                    </Typography>

                </Box>
            </Box>
            {!isSuperAdmin &&
                <Box>
                    <CustomFormLabel
                        labelName={"Choose User Type"}
                        isMandotary={true}
                        classNames='sml-txt'
                    />
                    <Box className={`${classes?.row} ${classes?.gap5}`}>
                        <Typography className={`radio-label ${isSSPUser ? "disabled" : ""}`} onClick={onChangeSystemAdmin}>
                            {isSysAdmin ? <CheckBoxIcon /> :  <CheckBoxOutlineBlankIcon /> }
                            <span>System Admin</span>
                        </Typography>
                        <Typography className={`radio-label ${isSSPUser ? "disabled" : ""}`}  onClick={onChangeAgent}>
                            {isAgent ? <CheckBoxIcon /> :  <CheckBoxOutlineBlankIcon /> }
                            <span>Agent</span>
                        </Typography>
                        <Typography className={`radio-label ${isSysAdmin || isAgent ? "disabled" : ""}`}  onClick={onChangeSSP}>
                            {isSSPUser ? <CheckBoxIcon /> :  <CheckBoxOutlineBlankIcon /> }
                            <span>User (SSP)</span>
                        </Typography>
                    </Box>
                </Box>
            }
        </Box>
    </>,[isSuperAdmin, isSysAdmin, isAgent, isSSPUser]);
    return (<>
        <Grid item xs={12} md={4}>
            <aside className="aside">
                {nameField}
                {userType}
                {descField}
                <Box className="pt-1 text-center">
                    <Button variant="outlined" size="small" className="sml-btn" onClick={()=>{postUpdate("0")}}>
                        Cancel
                    </Button>&nbsp;
                    <Button variant="contained" size="small" className="sml-btn" onClick={SubmitHandler}>
                        Submit
                    </Button>
                </Box>
            </aside>
        
    </Grid>
    </>)
})
const useStyles = makeStyles(() =>
  createStyles({
    row: {
      display:"flex"
    },
    gap5:{
        gap:"5px"
    },
    checkBoxRow:{
        
    }
}))


const mapStateToProps = (state:IState) => {
    return ({
        agentItems : state?.roleReducer?.agentCheckBoxStatus,
        systemItems : state?.roleReducer?.checkedStatus,
        rankingObj : state?.roleReducer?.rankingObj,
        categoryObject : state?.categoryReducer?.categoryObjectById,
        systemTpesByName : state?.systemTypeReducer?.systemTpesByName,
        permissions : state?.permissionReducer?.Data?.data
    })
};
export default connect(mapStateToProps)(FormFields);
// export default FormFields;