import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Add } from "./Add";
import { Edit } from "./Edit";
import { getDynamicFields } from "../../redux/actions/adminInterfaces/EmailSettings";
import { useDispatch } from "react-redux";
import Breadcrumbs from "./../common2/Breadcrumbs";

export const AddEmailExceptionListHome = () => {
  const param: any = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDynamicFields());
  }, [dispatch]);

  return (
    <>
      <Breadcrumbs
        data={[
          {
            title: "System Management ",
            path: "/adminPanel",
          },
          {
            title: "Mail Exception",
            path: "/MailExceptionList",
          },
          {
            title: `${!param.id ? "Add " : "Edit "}Mail Exception`,
            path: `${
              !param.id
                ? "/Add-MailExceptionList"
                : `/Add-MailExceptionList/${param.id}`
            }`,
          },
        ]}
      />
      <br />
      <Box className="mb2 pb2 email-templates-headings">
        <Typography variant="h6" style={{ marginLeft: "5px" }}>
          {!param.id ? "Add " : "Edit "}
          Mail Exception
        </Typography>
      </Box>
      {param.id ? (
        <>
          <Edit id={param.id} />
        </>
      ) : (
        <>
          <Add />
        </>
      )}
    </>
  );
};
