import {
  Box,
  Button,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CommonStyles from "../common/CommonStyles";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getContactAid,
  getContactAidById,
} from "../../redux/actions/SelfServicePortal/ContactAid/ContactAid";
import { createStyles, makeStyles } from "@mui/styles";
import ServiceImg from "../../assest/sspIcons/access.svg";
import ViewIcon from "../../assest/icons/viewIcon.svg"
import CustomModalDialog from "../common/customDialog";
import CustomFormLabel from "../common/form-label/CustomFormLabel ";
import CustomSelect from "../common/customSelect/CustomSelect";
import CustomInput from "../common/textField/CustomInput";
import CancelIcon from "@mui/icons-material/Cancel";
import { Formik } from "formik";
import * as Yup from "yup";
import { ActionType } from "../../redux/actions/Snackbars";
import { addNewTicket } from "../../services/dashboard/dashboard.service";
import {
  getTicketSources,
  getTicketStatus,
} from "../../redux/actions/SystemManagement/TicketStatus";
import { IState } from "../../redux/reducers/rootReducers";
import { downloadMultiFilesById } from "../../redux/actions/TicketsManagement/TicketsManagement";
import { useNavigate, useParams } from "react-router-dom";
import { CategoryTree } from "./CategoryTree";
import getTicket from "../../redux/actions/SystemManagement/ticket";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CommonAddAttachment from "../common/CommonAddAttachment";
import { AttachmentItem } from "./NewTicket";
const getValidationSchema = () => {
  const schemaObject: any = {};
  schemaObject["description"] = Yup.string().test(
    "description",
    function (value = "") {
      if (value.trim() === "") {
        return this.createError({
          path: this.path,
          message: "Please fill this field",
        });
      } else {
        return true;
      }
    }
  );
  return Yup.object().shape({
    ...schemaObject,
    category: Yup.object()
      .typeError("Category is required")
      .required("Categoryis required"),
  });
};
export const addTicketAsContactUrl = "user/Ticket";
export const AidServices = () => {
  const param = useParams();
  const getStatusByContactUrl = "user/TicketStatus";
  const classes = CommonStyles();
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [newTicketId, setNewTicketId] = useState(0);
  const [PendingAuthId, setPendingAuthId] = useState(0);
  const [modalContent, setModalContent] = useState<any>({});
  const [searchVal, setsearchVal] = useState("");
  const [imgdata, setImgData] = useState<any>({});
  const [data, setData] = useState<any>({
    description: "",
    categoryId: null,
    attachmentList: [],
  });
  const [allRecords, setAllRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const [open, setOpen] = useState(false);
  const currContact = useSelector(
    (state: IState) => state?.ContactsReducer?.loggedContact
  );
  const TicketSources = useSelector(
    (state: IState) => state?.ticketStatusReducer?.TicketSources
  );
  const getContactAidCB = (resStatus: string, res: any) => {
    if (resStatus === "0") {
      console.log("res", res);
      const iconIds: number[] = [];
      res.map((i: { iconId: number }) => {
        if (i.iconId === 0 || iconIds.includes(i.iconId)) return;
        iconIds.push(i.iconId);
      });
      if (iconIds.length > 0 || iconIds.join() === "") {
        dispatch(
          downloadMultiFilesById(`${iconIds.join()}`, downloadMultiFilesByIdCB)
        );
      }
      setRecords(res);
      if (allRecords.length === 0) setAllRecords(res);
    }
  };
  const downloadMultiFilesByIdCB = (res: any) => {
    console.log("downloadMultiFilesByIdCB", res);
    const tempObj: any = {};
    res.map((i: any) => {
      tempObj[`${i.id}`] = i.filePath;
    });
    setImgData({ ...tempObj });
  };
  useEffect(() => {
    console.log("param", param);
    if (param?.id) {
      dispatch(getContactAid(getContactAidCB, "user/ContactAid"));
    }
  }, [param]);
  const getTicketTypesCB = (props: any) => {
    console.log("ttcb", props);
  };
  useEffect(() => {
    dispatch(getTicketStatus(getTicketStatusCB, getStatusByContactUrl));
    dispatch(getContactAid(getContactAidCB, "user/ContactAid"));
    if (!param.id) {
      dispatch(getContactAid(getContactAidCB, "user/ContactAid"));
    }
    if (!TicketSources || TicketSources.length === 0) {
      dispatch(getTicketSources(null, "user/Source"));
    }
  }, []);
  const getTicketStatusCB = (response: any) => {
    const resStatus = response[0];
    const res = response[1];
    console.log(resStatus, res);
    if (resStatus === "0") {
      let newStatusId = 0;
      let authStatusId = 0;
      res.map((i: any) => {
        console.log("newTicketId", i.name);
        if (newStatusId === 0 && i.name === "New") {
          newStatusId = i.id;
        } else if (authStatusId === 0 && i.name === "PendingAuthorisation") {
          authStatusId = i.id;
        }
      });
      setNewTicketId(newStatusId);
      setPendingAuthId(authStatusId);
    }
  };
  const closeModal = () => {
    setOpen(false);
  };
  const openModal = () => {
    setOpen(true);
  };
  const chooseService = (index: number) => {
    // setModalContent(records[index]);
    navigate(`/ViewService/${records[index]}`)
    // openModal();
  };
  const ticketCreation = async (payload: any) => {
    console.log("payload", payload);
    let newTicket: any = await addNewTicket(payload, addTicketAsContactUrl);
    return newTicket;
  };
  return (
    <>
      <Box className={`sm-mt-10 ${classes.whiteBox}`}>
        <Grid container className="pt-1 sm-pt-1" spacing={1}>
          {/* <Grid item md={3} xs={12}>
                        Service Catalogue
                        <CategoryTree />
                    </Grid> */}
          <Grid item md={12} xs={12}>
              <Box>
              <Grid container alignItems={'center'} spacing={0}>
                <Grid item md={9} xs={12}>
                  <Typography className={styles.pageTitle}>Service Catalogue</Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    className={styles.searchBox}
                    type="search"
                    placeholder={"Search"}
                    variant="outlined"
                    size="small"
                    // value={value}
                    onChange={(e: any) => setsearchVal(e.target.value)}
                    InputProps={{
                      classes: { input: styles.input1 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon className={styles.searchIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              </Box>
              {records?.length === 0 && <>
                  <Typography className="text-center">No Records Found</Typography>
              </>}
              <Grid container className="pt-1" spacing={0}>
                {records.map((i: any, index: number) => {
                  const { name, description, id } = i;
                  const borderTop = "";
                  const show =
                    name.indexOf(searchVal) !== -1 ||
                    description.indexOf(searchVal) !== -1;
                  return (
                    <>
                      {show && (
                        <Grid item md={2} xs={12} className={`${borderTop}`}>
                          <Box className={styles.serviceBox}>
                            <Grid container alignItems={"center"} spacing={2}>
                              <Grid item md={3} xs={3}>
                                <img
                                  src={
                                    imgdata?.[`${i.iconId}`]
                                      ? imgdata[`${i.iconId}`]
                                      : ServiceImg
                                  }
                                  alt=""
                                  width={"100%"}
                                />
                              </Grid>
                              <Grid item md={9} xs={9}>
                                <Typography
                                  className={styles.serviceBoxTitle}
                                  variant="body2"
                                >
                                  {name}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Typography variant="body2">
                              {description}
                            </Typography>
                            {/* <Typography
                              variant="body2"
                              onClick={() => {
                                navigate(`/ViewService/${i.id}`);
                              }}
                              className={styles.linkTxt}
                            >
                              {"View Service"}
                            </Typography> */}
                            <Tooltip title="View Service">
                              <img
                                src={ViewIcon}
                                alt="view icon"
                                onClick={(event) => {
                                  navigate(`/ViewService/${i.id}`)
                                }}
                              />
                            </Tooltip>
                          </Box>
                        </Grid>
                      )}
                    </>
                  );
                })}
              </Grid>
          </Grid>
        </Grid>
      </Box>
      <CustomModalDialog
        title=""
        parentClasses="sml-popup"
        id="raiseTicketModalSSP"
        isOpen={open}
        onClose={closeModal}
        headerContent={
          <>
            <Typography variant="body2">{modalContent?.name}</Typography>
          </>
        }
        bodyContent={
          <>
            <Formik
              initialValues={{ ...data }}
              validationSchema={getValidationSchema()}
              onSubmit={(values, { resetForm }) => {
                console.log("Success! Data submitted.", values);
                const {
                  description,
                  attachmentList,
                  category,
                }: {
                  description: string;
                  attachmentList: any[];
                  category: any;
                } = values;
                console.log("attachmentList", attachmentList);
                const {
                  categoryId,
                  groupId,
                  ticketTypeId,
                  agentId,
                  isAuthorizeRequired,
                } = modalContent;
                const sspSource = TicketSources.filter(
                  (x: any) => x?.name === "SSP"
                )?.[0]?.id;
                let request: any = {
                  sourceCode: "SSP",
                  contactAidId:modalContent.id,
                  ticketLogs: {
                    ...values,
                    ticketTypeId: ticketTypeId,
                    groupId: groupId,
                    agentId: agentId,
                    categoryId: category?.id ? category.id : 0,
                    title: description,
                    sourceCode: "SSP",
                    statusId: isAuthorizeRequired ? PendingAuthId : newTicketId,
                    managerId: isAuthorizeRequired
                      ? currContact.managerId ? currContact.managerId : 0
                      : 0,
                    ticketLogAttachmentsList: attachmentList.map((i: any) => {
                      return { attachmentId: i.id };
                    }),
                  },
                };
                //ticketType,groupId,categoryId,title
                console.log("request123", modalContent);
                ticketCreation(request).then((res: any) => {
                  console.log("ticketCreation res", res);
                  if (res.status == 201) {
                    dispatch({
                      type: ActionType.SNACKBAR,
                      payload: {
                        message:  `New ticket added successfully! TicketID - ${res?.data?.id}`,
                        severity: "success",
                        open: true,
                      },
                    });
                    setTimeout(() => {
                      closeModal();
                      resetForm();
                    }, 1000);
                  }
                });
              }}
            >
              {(props) => (
                <AddForm
                  {...props}
                  classes={classes}
                  modalContent={modalContent}
                  closeModal={closeModal}
                />
              )}
            </Formik>
          </>
        }
      />
    </>
  );
};

export const AddForm = memo((props: any) => {
  const {
    errors,
    touched,
    values,
    setFieldValue,
    modalContent,
    classes,
    submitForm,
    closeModal,
  } = props;
  const currContact = useSelector(
    (state: IState) => state?.ContactsReducer?.loggedContact
  );
  const dispatch = useDispatch();
  const onChangeFunction = (fieldName: string, value: any) => {
    setFieldValue(fieldName, value);
  };
  const [categoryList, setCategoryList] = useState<any>([]);
  useEffect(() => {
    console.log("onloadPage", props, modalContent);
    dispatch(
      getContactAidById(
        modalContent?.id,
        getContactAidByIdCB,
        "user/ContactAid"
      )
    );
  }, []);
  const getContactAidByIdCB = (resStatus: string, res: any) => {
    console.log("getContactAidByIdCB", resStatus, res);
    if (resStatus === "0") {
      const tempArr: any = [];
      res.map((i: any) => {
        tempArr.push({
          label: i?.name,
          value: i?.id,
          id: i?.id,
        });
      });
      setCategoryList(tempArr);
    }
  };
  useEffect(() => {
    console.log("props123", props);
  }, [props]);
  useEffect(() => {
    if (currContact?.id) {
      console.log("currContact", currContact);
      setFieldValue("contactId", currContact?.id);
    }
  }, [currContact]);
  const imageUploadCB = useCallback((files) => {
    onChangeFunction("attachmentList", [...values?.attachmentList, ...files]);
  }, []);
  const attachmentDeleteHandler: any = useCallback((id: number, currentValues: any) => {
    const updatedAttachmentList = currentValues?.attachmentList.filter((i: any) => i.id !== id);
    onChangeFunction("attachmentList", updatedAttachmentList);
}, []);
  return (
    <>
      <Box>
        <CustomFormLabel labelName={"Category"} isMandotary={true} />
        <CustomSelect
          isMulti={false}
          isOpen={false}
          placeholder="Please Select"
          width={"100%"}
          name="category"
          isColor={false}
          isDisabled={false}
          isError={touched.category && Boolean(errors.category)}
          defaultValue={values.category}
          options={categoryList}
          helperText={touched.category && errors.category}
          handleChange={
            (e: any) => {
              onChangeFunction("category", e);
            }
            //(e: any) => { onChangeHandler(e, "agentId") }
          }
        />
      </Box>
      <CustomFormLabel
        labelName={"Please specify your problem below:"}
        isMandotary={true}
      />
      <CustomInput
        isDisabled={false}
        readOnly={false}
        onClick={null}
        iconPosition={"start"}
        icon={false}
        isError={touched.description && Boolean(errors.description)}
        label={"Description"}
        name="desc"
        value={values.description}
        onChange={(e: any) => {
          onChangeFunction("description", e.target.value);
        }}
        helperText={touched.description && errors.description}
        placeholder={"Please Enter"}
        classNames={"bigTextarea"}
      />
      <Box>
        <CommonAddAttachment callback={imageUploadCB} />
        {values?.attachmentList && (
          <Box className={`${classes.row} ${classes.rowWrap} `}>
            {values?.attachmentList.map((i: any, index: number) => {
              return (
                <>
                  {/* <img src={`${i.filePath}`}  height={50}/> */}
                  <Box className="col-4 p-3p" key={index}>
                    <AttachmentItem
                      fileData={i}
                      callback={()=>{attachmentDeleteHandler(i.id, values)}}
                    />
                  </Box>
                </>
              );
            })}
          </Box>
        )}
      </Box>
      <Box className="text-center">
        <Button
          className={`${classes.btn} ${classes.btnCancel}`}
          variant="outlined"
          onClick={closeModal}
        >
          <CancelIcon style={{ marginRight: "5px" }} />
          Cancel
        </Button>

        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <Button
          className={`${classes.btn} ${classes.btnSubmit}`}
          variant="contained"
          onClick={submitForm}
        >
          Submit
        </Button>
      </Box>
    </>
  );
});
const useStyles = makeStyles(() =>
  createStyles({
    serviceBox: {
      height: "100%",
      padding: 15,
      "& p": {
        fontSize: 9,
      },
    },
    pageTitle:{
      fontSize: "1.5rem",
      fontWeight: "900",
      "@media screen and (max-width : 980px)": {
        paddingBottom : "15px !important",
        fontSize: "1rem"
      }
    },
    serviceBoxTitle: {
      fontSize: 12,
      fontWeight: 600,
      color: "#071C69",
    },
    linkTxt: {
      color: "royalblue",
      fontSize: "11px !important",
      marginTop: "5px",
      cursor: "pointer",
    },
    searchBox: {
      width: "100%",
    },
    input1: {
      height: 6,
      fontSize: "13px",
      fontWeight: 400,
    },
    searchIcon: {
      fontSize: "16px",
      marginLeft: "-5px",
    },
  })
);
export default AidServices;
