import axios from "axios";
import configs from "../../../configs/config";
import { alertMessage, contactAidUrlSSP, storeAllContactsAid } from "../SelfServicePortal/ContactAid/ContactAid";
import { sspUrl } from "../KnowledgeBase/article";
import { getTicketByContactUserId } from "../TicketsManagement/TicketsManagement";

export const globalSearchSSP = (contactId:number,searchVal:string) => {
  return async (dispatch: any) => {
    try {
      dispatch(getTicketByContactUserId(contactId, null,searchVal));
      Promise.all([sspUrl,contactAidUrlSSP].map((endpoint:string|number,index:number) => axios.get(`${endpoint}`))).then(
        (all:any)=> {
          dispatch({type : "STORE_ALL_ARTICLES",payload : all[0].data.filter((i:any)=>{
            const values= `${Object.values(i)}`.toLowerCase();
            return values.includes(`${searchVal}`.toLowerCase());
          })});
          dispatch(storeAllContactsAid(all[1].data.filter((i:any)=>{
            const values= `${Object.values(i)}`.toLowerCase();
            return values.includes(`${searchVal}`.toLowerCase());
          })));
        }
      ); 
    } catch (error:any) {
      return console.log(error.response);
    }
  };
};