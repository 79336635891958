import { Popover } from '@mui/material';
import { memo } from 'react';
interface ICommonPopover{
    anchorEl:any;
    onClose : () => void;
    children?: JSX.Element;
}
const CommonPopover = memo((props:ICommonPopover)=> {
    const {anchorEl,onClose,children} = props;

    const handleClose = () => {
        onClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
            >
                {children}
            </Popover>
        </>
    )
})

export default CommonPopover;