export type IConditionFieldTypes =  "ClosedDate" | "OpenDate"| "LastActionDate"| "SlaRemaining" ;
interface WorkflowNodeTask {
    id?: number;
    title?: string;
    description?: string;
    statusId?: number;
    ticketTypeId?: number;
    priorityId?: number;
    groupId?: number;
    dynamicAgent?: string;
    dynamicManager?: string | null;
    agentId?: number;
    managerId?: number;
    group?: string | null;
    agent?: string | null;
    priority?: string | null;
    ticketStatus?: string | null;
    manager?: string | null;
    ticketType?: string | null;
    emailTemplateId?: number;
}


interface WorkflowNode {
    id: number;
    name: string;
    minimumVotesRequired?: number;
    totalVotes?: number;
    positionX: string | null;
    positionY: string | null;
    nodeLabel: string | null;
    type: "output" |  "decision" | "default";
    nodeColor?: string | null;
    edgeId?: string | null;
    nextActionApprovalIds?: string;
    nextActionRejectIds?: string;
    nodeId?: string | null;
    edgeSource?: string | null;
    edgeTarget?: string | null;
    sourceHandle?: string | null;
    targetHandle?: string | null;
    isNode?: boolean;
    isClickable?: boolean;
    isEdge?: boolean;
    changeParent?: boolean;
    actionFields: string | null;
    isActive?: boolean;
    workflowNodeTaskList?: WorkflowNodeTask[];    
}

export interface IWorkflow {
    id?: number;
    name: string;
    description: string;
    isActive?: boolean;
    workflowNodeList: WorkflowNode[];
    workflowConditionList:any[];
    workflowFilterList:any[];
    workflowSourceList:any[];
}



export interface IConditionValue {
  executionType:string|number;
  durationType:string|number;
  inPercentage:string|number;
  inDuration:string|number;
  id:any;
}



export interface IWorkflowSidebarProps{
    options ?: WorkflowNodeSettings[];
}
export interface WorkflowNodeSettings {
    name: string;
    apiLabel: string;
    type: 'input' | 'output' | 'decision' | 'default';
    nodeColor: string;
    actions?: string[];
    votesRequired?: boolean;
    changeParent?: boolean;
    defaultId?: string;
    clickable?: boolean;
    hideCustomSource?: boolean;
  }



export const getNodeStyle = (nodeColor:string,type?:string)=>{
    return  { backgroundColor: nodeColor, border: "none", boxShadow: "0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f", color: "#fff", borderRadius: type === "decision" ? "50px" : "5px"}
}



export const defaultValueChart:{
    TicketCreation : any,
    BusinessRule : any,
} = {
    TicketCreation: {
        name: "Workflow",
        apiLabel: "TicketCreation",
        type: 'input',
        nodeColor: "#2F80ED",
        defaultId: "1",
        id: '1', 
        clickable: false,
        data: { label: 'Workflow' }, 
        position: { x: 50, y: 50 },
        style : getNodeStyle("#2F80ED")
    },
    BusinessRule: {
        name: "Business Rule",
        apiLabel: "BusinessRule",
        type: 'input',
        nodeColor: "#2F80ED",
        clickable: false,
        defaultId: "0",
        id: '0', 
        data: { label: 'Business Rule' }, 
        position: { x: 50, y: 50 },
        style : getNodeStyle("#2F80ED")
    }
}









export const workflowActions:WorkflowNodeSettings[] = [
    {
      name: "Workflow",
      apiLabel: "TicketCreation",
      type: 'input',
      nodeColor: "#2F80ED",
      actions: [""],
      votesRequired: false, 
      changeParent: false,
      defaultId:"1",
      clickable: false
    },
   {
      name: "Update Parent Ticket",
      apiLabel: "UpdateTicket",
      type: "output",
      nodeColor: "#fec600",
      actions: ['statusId','groupId', 'agentId','ticketTypeId', 'priorityId'],
      votesRequired: false,
      changeParent: true,
      clickable: true
    },
    {
      name: "Manager Approval",
      apiLabel: "ManagerApproval",
      type: "decision",
      nodeColor: "#fec600",
      actions: ['contactList', 'statusId','emailTemplateId'],
      votesRequired: true,
      changeParent: false,
      clickable: true
    },
    {
      name: "Agent Approval",
      apiLabel: "AssignAgent",
      type: "decision",
      nodeColor: "#fec600",
      actions: ['statusId','agentList','emailTemplateId'],
      votesRequired: true,
      changeParent: false,
      clickable: true
    },
    {
      name: "Send Email",
      apiLabel: "SendEmail",
      type: "output",
      nodeColor: "#fec600",
      actions: ['emailTemplateId'],
      votesRequired: false,
      changeParent: false,
      clickable: true
    },
    {
      name: "Resolve Ticket",
      apiLabel: "ResolveTicket",
      type: "default",
      nodeColor: "#2e7d32",
      actions: ['statusId'],
      votesRequired: false,
      changeParent: false,
      clickable: true
    },
    {
      name: "Close Ticket",
      apiLabel: "CloseTicket",
      type: "output",
      nodeColor: "#d32f2f",
      actions: ['statusId'],
      votesRequired: false,
      changeParent: false,
      clickable: true,
      hideCustomSource: true
    },
  ]
  export const businessRuleActions:WorkflowNodeSettings[] = [
    {
      name: "Business Rule",
      apiLabel: "BusinessRule",
      type: 'input',
      nodeColor: "#2F80ED",
      actions: [""],
      votesRequired: false,
      changeParent: false,
      defaultId:"0",
      clickable: false
    },{
      name: "Update Parent Ticket",
      apiLabel: "UpdateTicket",
      type: "output",
      nodeColor: "#fec600",
      actions: ['statusId','groupId', 'agentId','ticketTypeId', 'priorityId'],
      votesRequired: false,
      changeParent: false,
      clickable: true
    },
    {
      name: "Send Email",
      apiLabel: "SendEmail",
      type: "output",
      nodeColor: "#fec600",
      actions: ['emailTemplateId'],
      votesRequired: false,
      changeParent: false,
      clickable: true
    }
  ]







// ============ WORKFLOW PAYLOAD GENERATE ===========
const getFormattedFilterVal = (val:any) =>{
  const values:any = [];
    if(val?.property?.dataType === 'int'){
      if(val?.property?.name === "CategoryId"){
        // debugger;
        values.push(...Object.keys(val.value||{}).map(i=>Number(i)))
        // values.push(...val.value)
      }else if(val.value.length){
          values.push(...val.value.map((i:any)=>i.id))
      }else{
        values.push(val.value.id)
      }
    }else if(val?.property?.dataType === 'string' || val?.property?.dataType === 'bool'){
      if(val.value.length){
        values.push(...val.value.map((i:any)=>i.value))
      }else{
        values.push(val.value.value)
      }
    }

  console.log('getFormattedFilterVal',values,val,val?.property)
  return values.join('|');
}

export const formFilterList = (filterVals:any[]) =>{
  const workflowFilterList = [
    ...filterVals.map((i:any)=>{
      const tempObj = {
        "id" : i.oldId || 0,
        "ticketPropertyId": i?.property?.id,
        "conditionalOperatorId": i?.condition?.id,
        "value": getFormattedFilterVal(i),
        "nextValueOperatorId":i?.isOr ? "or" : "and"
      }
      return tempObj
    })
  ]
  return workflowFilterList
}

export const formConditionObj = (data:any)=>{
  const keys = ["OpenDate", "ClosedDate","LastActionDate","SlaRemaining"];
  const conditionList:any = [];
  
  keys.map((i:any)=>{
    const timeObj = {
      days : data?.[`${i}-days`] || "00",
      hours : data?.[`${i}-hours`] || "00",
      mins : data?.[`${i}-mins`] || "00",
      percentage : +data?.[`${i}-percentage`] ? +data?.[`${i}-percentage`] : 0
    };
    const {days, hours, mins,percentage} = timeObj;
    if(days !== "00" || hours !== "00" || mins !== "00" || percentage !== 0 ){
      const temp =  {
        "id": 0,
        "name": i,
        "inPercentage": percentage,
        "durationType": data?.[`${i}-durationType`] || "InDuration",
        "executionType": data?.[`${i}-executionType`] || "",
        "inDuration": `${days}.${hours}:${mins}:00`
      }
      conditionList.push(temp);
    }
  })
  
  return conditionList;
}
export const formSourceList = (arr:any[]=[]) =>{
  const modifiedArr = arr.map((i)=>{
    return {
      // id:i?.id,
      sourceCode : i.code,
    }
  })
  return modifiedArr;
}


const checkNextAction = (node:any,edges:any=[]) => {
  const tempObj = {
    nextActionRejectIds:"",
    nextActionApprovalIds:""
  }
  edges.map((i:any) => {
    if (i.source === node?.id) {
      if(i?.sourceHandle === "onReject"){
        tempObj.nextActionRejectIds = `${tempObj.nextActionRejectIds === "" ? "" : tempObj.nextActionRejectIds +"|"}${i.target}`
      } else {
        tempObj.nextActionApprovalIds = `${tempObj.nextActionApprovalIds === "" ? "" : tempObj.nextActionApprovalIds +"|"}${i.target}`
      }
    }
  });
  console.log('tempObjtempObj',tempObj)
  return tempObj;
}
export const generateNodeList = (nodes:any=[],edges:any=[]) =>{
  const nodeList:WorkflowNode[] = [];
  nodes.map((i:any)=>{
    const {sourceHandle=null,targetHandle=null,name="",apiLabel="",type="",minimumVotesRequired=0,workflowNodeTaskList=[],nodeColor="",clickable,defaultId=null,actions=[],id="",oldId=0,position={}} = i
      const { x="0", y="0" } = position;
      nodeList.push({
        id: oldId,
        name:apiLabel,
        minimumVotesRequired : +minimumVotesRequired ? +minimumVotesRequired : 0,
        totalVotes: workflowNodeTaskList.length,
        positionX: `${x}`,
        positionY: `${y}`,
        nodeLabel: name,
        type,
        nodeColor: nodeColor,
        edgeId: "",
        // nextActionApprovalIds: "",
        // nextActionRejectIds: "",
        nodeId: defaultId || id,
        edgeSource: "",
        edgeTarget: "",
        sourceHandle,
        targetHandle,
        isNode: true,
        isClickable: clickable,
        isEdge: false,
        changeParent: apiLabel === "UpdateTicket",
        actionFields: `${actions}`,
        // votesRequired,
        workflowNodeTaskList: workflowNodeTaskList.map((i:any)=>{
            if(i?.agentId === -1){
              i.dynamicAgent = "{RoundRobin}";
              i.agentId = 0;
            }else if(i?.agentId === -2){
              i.dynamicAgent = "{Owner}";
              i.agentId = 0;
            }
            return i;
        }),
        ...checkNextAction(i,edges)
      })
  })

  edges.map((i:any)=>{
    const {name="",type="",id="",targetHandle=null,oldId=0,source="",target="",sourceHandle=""} = i
      nodeList.push({
        id: oldId,
        name,
        minimumVotesRequired : 0,
        totalVotes: 0,
        positionX: "",
        positionY: "",
        nodeLabel: "",
        type,
        nodeColor: "",
        edgeId: id,
        nextActionApprovalIds: "",
        nextActionRejectIds: "",
        nodeId: "",
        edgeSource: source,
        edgeTarget: target,
        sourceHandle: sourceHandle,
        targetHandle: targetHandle,
        isNode: false,
        isClickable: false,
        isEdge: true,
        changeParent: false,
        actionFields: ``,
        workflowNodeTaskList:[]
      })
  })
  console.log(nodes,edges)
  return nodeList;
}


export const getFilterValuesOnEdit = (value:any,allResponses:any=null,workflowFilterOptions:any) =>{
  //debugger;
  console.log("getFilterValues",allResponses)
  if(allResponses && Object.keys(allResponses)?.length === 0) return [];
  try{
      let chosenVals = value?.value.split('|');
      const property = value?.ticketProperty?.name;
      //const ticketProperty = value?.ticketProperty?.ticketProperty;
      const valType = value?.ticketProperty?.dataType;
      const multiOrSingle = value?.conditionalOperator?.type;
      let returnVal:any = [];
      if(valType === "bool"){
          if(chosenVals.length === 2){
            return [{id : 1,label : "true",value : "true"},{id : 2,label : "false",value : "false"}]
          }else if(chosenVals.length === 1){
              let val = {
                id : chosenVals[0] === "true" ? 1 : 2,
                label : chosenVals[0], 
                value : chosenVals[0]
              }
              return multiOrSingle === "Multiple" ? [val] : val
          }
      }else if(valType === "string"){
        if(multiOrSingle === "Single"){
          return {
            label : chosenVals[0],
            value : chosenVals[0],
            __isNew__: true
          }
        } else{
          return chosenVals.map((i:string)=>{
            return {
              label : i,
              value : i,
              __isNew__: true
            }
          })
        }
      } else if(property === "CategoryId") {
        const tempObj:any = {};
        chosenVals.map((i:string)=>{
          tempObj[i] = true
          return +i
        })
        return tempObj;
      }else if(property === "StatusId" && allResponses.ticketStatus) {
        const options = allResponses.ticketStatus.filter((i:any)=>chosenVals.includes(`${i.id}`));
        return multiOrSingle === "Multiple" ? options : options[0]
        // chosenVals.map((i:string)=>{
        //   return +i
        // })
      } else if(property === "TicketTypeId" && allResponses.TicketTypes) {
        const options = allResponses.TicketTypes.filter((i:any)=>chosenVals.includes(`${i.id}`));
        return multiOrSingle === "Multiple" ? options : options[0]
        // chosenVals.map((i:string)=>{
        //   return +i
        // })
      }else if(property === "ContactId") {
        const options = allResponses.contacts.filter((i:any)=>chosenVals.includes(`${i.id}`));
        return multiOrSingle === "Multiple" ? options : options[0]
        // chosenVals.map((i:string)=>{
        //   return +i
        // })
      } else if(property === "OrganisationId") {
        const options = allResponses.OrgList.filter((i:any)=>chosenVals.includes(`${i.id}`));
        return multiOrSingle === "Multiple" ? options : options[0]
        // chosenVals.map((i:string)=>{
        //   return +i
        // })
      } else if(property === "ContactAidId" && allResponses?.contactAidList) {
        const options = allResponses?.contactAidList?.filter((i:any)=>chosenVals.includes(`${i.id}`));
        return options && multiOrSingle === "Multiple" ? options : options[0]
        // chosenVals.map((i:string)=>{
        //   return +i
        // })
      }
      return returnVal;
  }catch(e){
    console.log("getFilterValues",e)
    return []
  }
}