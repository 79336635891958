import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { createRef, useEffect, useState } from 'react';
import CustomFormLabel from '../../common/form-label/CustomFormLabel ';
import { iWebFormField } from '../schema';

export const WebFormDropDown = (props: {fieldConfig:iWebFormField,value?:any,editable?:any}) => {
    // const { fieldLabel = "",name="", placeholder = "Please Enter", isMandatory=false,fieldsOptions="Hi|Hello" } = props?.fieldConfig;
    const {fieldLabel,fieldOptions="",fieldDescription,isMandatory,id} = props.fieldConfig;
    const [menuOptions,setOptions] = useState<string[]>([]);
    useEffect(()=>{
        console.log('WebFormDropDown_values',fieldOptions)
        if(fieldOptions && fieldOptions !== ""){
            let vals = fieldOptions.split('|')
            setOptions(vals)
        }
    },[fieldOptions])
    return (
        <div>
            <CustomFormLabel
                labelName={fieldLabel}
                isMandotary={isMandatory}
            />
            <FormControl sx={{ minWidth: "100%" }}>
                <Select
                    // value={props.value ? props.value : "please select"}
                    defaultValue={props.value ? props.value : "please select"}
                    // onChange={handleChange}
                    fullWidth={true}
                    name={`${id}`}
                    // displayEmpty
                    className='webform-dropdown'
                    // disabled={disabled || false}
                    readOnly={!props?.editable}
                    inputProps={{ 'aria-label': 'Without label' }}
                    
                >
                    <MenuItem className='webform-dropdown-option' value={"pleaseSelect"}>Please Select</MenuItem>
                    {menuOptions.map((i:any,index:number)=>{
                       return <MenuItem className='webform-dropdown-option' key={index} value={i}>{i} </MenuItem>
                    })}
                </Select>
                {(fieldDescription && fieldDescription.trim() !== "") && <FormHelperText className="helper-text-webform">{fieldDescription}</FormHelperText>}
            </FormControl>
        </div>
    )
}