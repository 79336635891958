import { memo, useState, useMemo } from "react";
import CommonStyles from "../../../common/CommonStyles";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControl, FormControlLabel, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import AgentCheckBoxList from "./AgentCheckboxList";
import AgentSelectAllOption from "./AgentSelectAllOption";


const AgentRoleAccrodianItem = memo(({info,keyLabel,isSSP=false}:any) =>{
    const [expanded,setExpand] = useState(false);
    // console.log('AgentRoleAccrodianItem',info,keyLabel)
    const classes = CommonStyles();
    const toggleCollapse = () =>{
        setExpand(!expanded); 
    }
    // const selectAll = useMemo(() => {
    //     return(<AgentSelectAllOption keyLabel={keyLabel} info={info} />)
    // },[info?.length])
    const layout = useMemo(() => {
        return <>
            <Grid container spacing={0} alignItems="center">
                {/* info {info?.length} */}
                <AgentCheckBoxList 
                    items={info}
                    keyLabel={keyLabel}
                    isSSP={isSSP}
                />
            </Grid>
        </>
    }, [info?.length])
    return(
        <>
            <Accordion expanded={expanded} className="accordionDtls">
                <AccordionSummary aria-controls={`${keyLabel}usr-content`} id={`${keyLabel}usr-header`} className='accrodTitle'>
                    <Box className={`${classes.row} ${classes.justifyBetween} w-100`}>
                        <Box>
                            <Typography>{keyLabel}</Typography>
                        </Box>
                        <Box className={`${classes.row} ${classes.alignItemsCenter}`} >
                            {/* {selectAll} */}
                            <AgentSelectAllOption keyLabel={keyLabel} info={info} />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {expanded ?
                                <KeyboardArrowUpOutlinedIcon className="pointer" onClick={toggleCollapse} /> :
                                <KeyboardArrowDownOutlinedIcon className="pointer" onClick={toggleCollapse} /> 
                            }
                        </Box>
                    </Box>

                </AccordionSummary>
                <AccordionDetails>
                    {layout}
                </AccordionDetails>
            </Accordion>
        </>
    )
})

export default AgentRoleAccrodianItem;
// const mapStateToProps = (state:IState) => {
//     return ({
//       systemTypes : state?.systemTypeReducer?.Data,
//       permissions : state?.permissionReducer?.Data?.data,
//     //   state
//     })
// };
// export default connect(mapStateToProps)(RoleAccrodianItem);