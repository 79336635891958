import axios from "axios";
import { timeSpanIntoObject } from "../../../components/helpers/helperFunctions";
import { getFilterValuesOnEdit, getNodeStyle } from "../../../components/workflowConfig/workflowModules/schema";
import LanguageData from "../../../configs/LanguageData.json";
import configs from "../../../configs/config";
import { alertMessage, contactAidUrl, triggerAppAlert } from "../SelfServicePortal/ContactAid/ContactAid";
import { generateNodeValues } from "../../../components/workflowConfig/workflowModules/NodeActionsForm";
export const conditionsURL = `${configs.BASE_URL}/conditionalOperator`;
export const TicketPropertyURL = `${configs.BASE_URL}/ticketProperty`;
export const url = `${configs.BASE_URL}/workflow`;
export const workFlowUrl = `${configs.BASE_URL}/workflow`;
//export const url = `${configs.BASE_URL}/Permissions`;

export const storeNodeErrors = (value:any) => {
  return async (dispatch: any) => {
      dispatch({type: "STORE_WORKFLOW_ERRORS",payload: value});
      dispatch(triggerAppAlert(value?.actionError ? "Please fill the required fields & Select atleast one action property to proceed!" : "Please fill the required fields to proceed!" , "warning"));
      // dispatch(triggerAppAlert("Please complete the form to continue","warning"));
  }
}
export const unsetNodeErrors = (fieldName:any) => {
  return async (dispatch: any) => {
      dispatch({type: "UNSET_WORKFLOW_ERRORS",payload: fieldName})
  }
}
export const resetNodeErrors = () => {
  return async (dispatch: any) => {
      dispatch({type: "RESET_WORKFLOW_ERRORS",payload: null})
  }
}
const getWorkflow = (callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(url);
      dispatch({type: "STORE_WORKFLOW",payload: response.data})
      try{
        if(callback) callback(response.data);
      }catch(e:any){
        console.log('e')
      }
    } catch (error:any) {
      return console.log(error);
    }
  };
}

// {
          //     "agentVal": null,
          //     "groupVal": null,
          //     "statusVal": null,
          //     "emailTemplateVal": null,
          //     "ticketTypeVal": null,
          //     "priorityVal": null
          // },

export const getWorkflowById = (id:string|number,callback?:any,allResponses?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${url}/details/${id}`);
      response.data.workflowSourceObj = {};
      response.data.workflowSourceList.map((i:any)=>{
          response.data.workflowSourceObj[i.sourceCode] = true;
      })
      const conditonObj:any = {};
      response.data.workflowConditionList.map((i:any)=>{
        const {id,name,inPercentage,durationType,executionType,inDuration} = i
        conditonObj[name]={
            executionType,
            durationType,
            inPercentage,
            inDuration,
            id,
            formattedDuration : timeSpanIntoObject(inDuration)
        }
      })
      response.data.conditonObj = conditonObj;

      response.data.workflowFilterList = response.data.workflowFilterList.map((i:any)=>{
        const {nextValueOperatorId,conditionalOperator,ticketProperty,valueObject,id} = i;
        return {
            id,
            oldId:id,
            value: valueObject ? {...valueObject,label: valueObject?.name, value: valueObject?.id} : getFilterValuesOnEdit(i,allResponses,{}),
            isOr : nextValueOperatorId === "or",
            condition : conditionalOperator ? {...conditionalOperator,label:conditionalOperator?.name,value:conditionalOperator?.id} : null,
            property : ticketProperty ? {...ticketProperty,label:ticketProperty?.name,value:ticketProperty?.id} : null,
        }
      })
      
      response.data.workflowNodeObj ={
        nodes : [],
        edges : []
      }
      response.data.workflowNodeList.map((i:any)=>{
        const {id,actionFields="",edgeSource,votesRequired,sourceHandle,targetHandle,edgeTarget,edgeId,name,minimumVotesRequired=0,positionY=0,positionX=0,isEdge,isNode,nodeLabel,type,nodeColor="#2F80ED",nodeId="",isClickable,workflowNodeTaskList=[]} = i;
        
        if(isNode){
          const tempObj:any =  {
            "id": nodeId,
            "oldId" : id,
            type,
            "position": {"x": +positionX,"y": +positionY},
            "actions": actionFields.split(","),
            "data": {"label": nodeLabel},
            "name": nodeLabel,
            "apiLabel": name,
            votesRequired : (actionFields.includes("contactList") || actionFields.includes("agentList")) ? true : false,
            nodeColor,
            style : getNodeStyle(nodeColor || "#2F80ED",type),
            "clickable": isClickable,
            "dataToPrefill": generateNodeValues(allResponses,i?.workflowNodeTaskList?.[0]),
            workflowNodeTaskList,
            minimumVotesRequired,
          }
          if(name === "ManagerApproval"){
            tempObj.contactVal = [];
            workflowNodeTaskList.map((i:any)=>{
              // if(val.name === "AssignAgent"){
              //   if(i?.agent?.id){
              //     tempObj.workflowNodeAgentApprovalList.push(
              //       {
              //         id : i.agent.id,
              //         oldId: i.id,
              //         value : `${i.agent.firstName} ${i.agent.lastName}`,
              //         label : `${i.agent.firstName} ${i.agent.lastName}`,
              //         isActive : true,
              //       }
              //     )
              //   }
              // }
              if(i?.manager?.id){
                tempObj.contactVal.push({
                  id : i?.manager.id,
                  oldId: i.id,
                  value : i?.manager.id,
                  label : `${i?.manager?.name}`,
                  isActive : true,
                })
              }else if(i?.dynamicManager !== ""){
                tempObj.contactVal.push({
                  oldId: i.id,
                  id : i?.dynamicManager === "{PrimaryContactLineManager}" ? -1 : -2,
                  value : `${i?.dynamicManager}`,
                  label : `${i?.dynamicManager}`,
                  isActive : true,
                })
              }
            }) 
          }

          if(name === "AssignAgent"){
            tempObj.agentVal = [];
            workflowNodeTaskList.map((i:any)=>{
                if(i?.agent?.id){
                  tempObj.agentVal.push(
                    {
                      id : i.agent.id,
                      oldId: i.id,
                      value : i.agent.id ,//`${i.agent.firstName} ${i.agent.lastName}`,
                      label : `${i.agent.firstName} ${i.agent.lastName}`,
                      isActive : true,
                    }
                  )
                }else if(i.dynamicAgent !== ""){
                  tempObj.agentVal.push({
                    label : i.dynamicAgent,
                    value : i.dynamicAgent,
                    isActive : true,
                    id : i.dynamicAgent === "{RoundRobin}" ? -1 : -2//-1
                  })
                }
            }) 
          }
          response.data.workflowNodeObj.nodes.push(tempObj)
        } else{
          response.data.workflowNodeObj.edges.push({
            "source": edgeSource,
            "sourceHandle": sourceHandle,
            "target": edgeTarget,
            "targetHandle": targetHandle,
            "animated": false,
            "markerEnd": {
                "type": "arrowclosed",
                "width": 20,
                "height": 20
            },
            "type": "smoothstep",
            "id": edgeId
          })
        }
      })
      
      dispatch(storeWorkFlowFilters(response?.data?.workflowFilterList || []));

      try {
        if(callback) callback('0',response.data)
      } catch(e){
        console.log(e)
      }
    } catch (error:any) {
      if(callback) callback('1',error.response)
      return console.log(error);
    }
  };
};
export const WorkFlowStatusChange = (data: any, ids: any,status:any) => {
  const statusMsg = status ? `${LanguageData.SUCCESS_WORKFLOW}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_WORKFLOW}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  return function (dispatch: any) {
    axios.delete(`${url}/status/change?ids=${ids}&status=${status}`, data).then((resp) => {
      dispatch(getWorkflow());
      dispatch(triggerAppAlert(statusMsg, 'success'))
    });
  };
};
export const addWorkflow = (request:any,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(url,request);
      //dispatch()
      //STORE_WORKFLOW
      dispatch(alertMessage(`${LanguageData.SUCCESS_NEW_WORKFLOW}${LanguageData.TICKET_CREATED}`,"success"))
      if(callback) callback('0',response)
    } catch (error:any) {
      console.log("AddWorkflow_Err",error?.response);
      if(error?.response?.data && typeof error?.response?.data === "string"){
        dispatch(alertMessage(error?.response?.data,"error"))
      }
      return console.log(error);
    }
  };
};
export const editWorkflowById = (request:any,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.put(`${url}/${request.id}`,request);
      //dispatch()
      //STORE_WORKFLOW
      if(callback) callback('0',response)
      dispatch(alertMessage(`${LanguageData.SUCCESS_WORKFLOW} ${LanguageData.TICKET_UPDATED}`,"success"))
    } catch (error:any) {
      if(error?.response?.data && typeof error?.response?.data === "string"){
        dispatch(alertMessage(error?.response?.data,"error"))
      }
      return console.log(error);
    }
  };
};
export const storeWorkFlowFilters:(val:any)=>void = (val:any) => {
  return async (dispatch: any) => {
      dispatch({type: "WORKFLOW_FILTER_OPTIONS",payload: val})
  };
};
export const storeWorkFlowNodes = (val:any) => {
  return async (dispatch: any) => {
      dispatch({type: "WORKFLOW_NODES",payload: val})
  };
};
export const getTicketProperties = (allResponses:any,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(TicketPropertyURL);
      const aidList = await axios(contactAidUrl);
      const aidOptions:any=[];
      aidList.data.map((i:any)=>{
        if(i.isActive){
          aidOptions.push({
            ...i,
            id : i.id,
            label : i.name,
            value : i.id,
            isActive : true
          })
        }
      })
      console.log("aidList",aidOptions)
      const propertiesObj:any = {}
      const properties = response.data.map((i:any)=>{
        const {name} = i;
        i.value=i.name;
        i.label=i.displayName;
        if(name === "TicketTypeId"){
          i.inputvalueList = allResponses?.TicketTypes || [];
        }else if(name === "StatusId"){
          i.inputvalueList = allResponses?.ticketStatus || [];
        }else if(name === "ContactAidId"){
          i.inputvalueList = aidOptions;
        }
        propertiesObj[i.id] = i;
        return i;
      })
      console.log("STORE_WORKFLOW_FILTER_PROPERTIES",propertiesObj)
      dispatch({type: "STORE_WORKFLOW_FILTER_PROPERTIES",payload: {propertiesObj,properties}})
      try{
        if(callback) callback(response.data);
      }catch(e:any){
        console.log('e')
      }
    } catch (error:any) {
      return console.log(error);
    }
  };
};
export const getWorkflowConditions = (callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(conditionsURL);
      dispatch({type: "STORE_WORKFLOW_FILTER_CONDITIONS",payload: response.data.map((i:any)=>{
        return {
          ...i,
          value:i.name,
          label:i.name,
        }
      })})
      try{
        if(callback) callback(response.data);
      }catch(e:any){
        console.log('e')
      }
    } catch (error:any) {
      return console.log(error);
    }
  };
};
export default getWorkflow;