import axios from "axios";
import { getTicketsByFilterUrl } from "../../../../src/services/dashboard/dashboard.service";
import { url as OrgUrl } from '../../actions/CMDB/Organisation';
import { url as contactsUrl } from "../ContactManagement/Contact";
import { url as priorityUrl } from "../SystemManagement/AddPriority";
import { ticketSourcesurl, url as ticketStatusUrl } from "../SystemManagement/TicketStatus";
import { url as TicketTypesUrl } from "../SystemManagement/ticket";
import { url as AgentsUrl } from "../userManagement/agents";
import { url as groupsUrl } from "../userManagement/group";
const APIS:any  = [groupsUrl,priorityUrl,ticketSourcesurl,`${ticketStatusUrl}`,contactsUrl,TicketTypesUrl,AgentsUrl,OrgUrl];//,OrgUrl-->getTicketsByFilterUrl

//To form a JSON structure
export const formInfoData = (arr:any[]=[], configArr:any=[]) =>{
  const tempData:any = {};
  try{
    arr.forEach((i:any)=>{
      configArr.forEach((j:any)=>{
        if(!tempData[j.name]){
          tempData[j.name] = {}
        }
        const key= j.vals[0];
        const keyVal= j.vals?.[1] ? j.vals[1] : 'id';
        tempData[j.name][i[keyVal]] =  key === '' ? i : i[key];
      })
      
    })
  }catch(e){console.log('formInfoData',e)}
  return tempData;
}

//get All System management data
export const getAllDAshboardData = (callback?:any) => {
  return async (dispatch: any) => {
    try{
      Promise.all(APIS.map((endpoint:string,index:number) => axios.get(`${endpoint}`))).then(
        (all)=> {
          dispatch(asyncCallback(all,callback));
        }
      ).catch((e:any)=>{
        if(callback) callback("1",e);
      }); 
    }catch(e){
      if(callback) callback("1",e);
      return console.log('get all data', e)
    }
  };
};

//callback function after get system management details
export const asyncCallback = (allResponse:any, callback?:any) =>{
  return async (dispatch: any) => {
    try{
      console.log('allResponse>>',allResponse)
      let tempAllInfo:any ={};
      const AllData:any = {};

      //store group
      AllData.groups = allResponse[0].data;
      tempAllInfo = {...tempAllInfo, ...formInfoData(AllData.groups, [
          {name:'groups', vals:['name']},
        ])
      }

      //store priority
      AllData.priorities = allResponse[1].data; 
      tempAllInfo = {...tempAllInfo, ...formInfoData(AllData.priorities, [
          {name:'priority', vals:['name']},
          {name:'priorityTypesById', vals:['','id']},
        ])
      }

      //Ticket Source
      AllData.ticketSources = allResponse[2].data;
      tempAllInfo = {...tempAllInfo, ...formInfoData(AllData.ticketSources, [
          {name:'sources', vals:['name']},
          {name:'sourceTypes', vals:['id','name']},
        ])
      }

      
      // store ticket status
      AllData.ticketStatus = allResponse[3].data;
      tempAllInfo = {...tempAllInfo, ...formInfoData(AllData.ticketStatus, [
        {name:'status', vals:['displayName']},
        {name:'statusNames', vals:['name']},
        {name:'statusTypes', vals:['id','name']},
        {name:'statusTypesById', vals:['','id']},
      ])
      }


      //store contacts
      AllData.contacts = allResponse[4].data;
      tempAllInfo = {...tempAllInfo, ...formInfoData(AllData.contacts, [
          {name:'contacts', vals:['userName']},
          {name:'contactsById', vals:['','id']}
        ])
      }

      //store TicketTypes
      AllData.TicketTypes = allResponse[5].data;
      tempAllInfo = {...tempAllInfo, ...formInfoData(AllData.TicketTypes, [
          {name:'TicketTypes', vals:['displayName']}
        ])
      }
      //store agents
      AllData.AgentsList = allResponse[6].data;
      tempAllInfo = {...tempAllInfo, ...formInfoData(AllData.AgentsList, [
          {name:'Agents', vals:['userName']}
        ])
      };

      //store agents
      AllData.OrgList = allResponse[7].data;
      tempAllInfo = {...tempAllInfo, ...formInfoData(AllData.OrgList, [
          {name:'Organisation', vals:['name']}
        ])
      };

      //store agents
      dispatch(storeResponses(AllData));
      console.log('payload:tempAllInfo',allResponse,tempAllInfo)
      dispatch({ type: "STORE_ALL_STRUCTURES", payload:tempAllInfo});

      //store agents

    }catch(e){
      if(callback) callback("1",e);
      return console.log('get all data', e)
    }
  };
};

//store all response to redux
export const storeResponses = (AllData:any) =>{
  return { type: "STORE_ALL_RESPONSES", payload:AllData}
}

export const STORE_NEW_TICKETS = "STORE_NEW_TICKETS";
export const STORE_NEW_TO_ME_TICKETS = "STORE_NEW_TO_ME_TICKETS";
export const STORE_PRIORITY_TICKETS = "STORE_PRIORITY_TICKETS";
export const STATUS_TO_IGNORE = ["New","NewtoMe","Closed"];
export const storeTickets = (ticketSet:string,data:any) =>{
  return { type: ticketSet, payload:data}
}

//get tickets with status
export const getTicketsWithStatus = (ticketSet:string="",statusIds?:string|number,callback?:any) =>{
  return async (dispatch: any) => {
    try{
      const ticketsRes:any = await axios.get(`${getTicketsByFilterUrl}?page=0&count=50&statusIds=${statusIds}`);
      dispatch(storeTickets(ticketSet,ticketsRes.data))
      if(callback) callback("0", ticketsRes.data)
    }catch(e){
      if(callback) callback("1",e);
      return console.log('get all data', e)
    }
  };
}

export default getAllDAshboardData;