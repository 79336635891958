import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { memo } from "react";
import { useDispatch } from "react-redux";
import { storeFiltersValue } from "../../../redux/actions/TicketsManagement/GetTickets";
import { initialStateRange } from "../../../redux/reducers/Survey/feedback";
export const ResetFilterSurvey = memo(() => {
    
    const dispatch = useDispatch();
    const classes = useStyles();
    
    const clickHandler = () => {
        dispatch(storeFiltersValue(initialStateRange, 'RESET_FILTER_SURVEY'));
    }
    
    return (
        <>
            <Typography onClick={clickHandler} className={classes.title} variant="body2">
                Reset Filter
            </Typography>
        </>
    )
})
const useStyles = makeStyles(() => ({
    title:{
        fontSize: "10px",
        cursor: "pointer"
    }
}))
export default ResetFilterSurvey;