import React, { useEffect, useRef, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import {
  Button,
  Container,
  Typography,
} from "@mui/material";
import logoImgOnlyS from "./../../assest/logo/logo-icon.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from 'react-otp-input';
import { LoginActionWithOtp, storeLoginInfo } from "../../redux/actions/Login/Login";
import { createStyles, makeStyles, styled } from "@mui/styles";
import { getMFAEnable } from "../../redux/actions/FreeTrial/FreeTrial";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
const styles = {
  pageWrap: {
    // backgroundImage: `url(${backImg})`,
  },
};
export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }}/>
))({
    color: "#000",
    fontSize: ".7em",
    boxShadow: "0 0 1px 1px #ddd",
    backgroundColor: "#fff",
    borderRadius: 0,
    padding: "10px 15px",
    "&.Copied":{
      color: "green"
    }
})
function LoginMFA() {
  const param = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ErrorMsg, setErrorMsg] = useState("");
  const [imgUrl, setimgUrl] = useState("");
  const [setupKey, setSetupKey] = useState("");
  const [otp, setOtp] = useState('');
  const [accountStatus, setAccountStatus] = useState('');
  const [isMFAConfigured,setIsMFAConfigured] = useState('')
  const [Copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = `${setupKey}`;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    setCopied(true)
  };
  const onSubmitCB = (resStatus:string, res:any) => {
    console.log('props',res)
    if(resStatus === "0"){ 
      if(accountStatus !== "New"){
        dispatch(storeLoginInfo(res));
        navigate('/');
      }else {
        navigate(`/resetPassword/${res?.token}`);
      }
    } else {
      setErrorMsg('* Entered OTP is invalid!')
    }
    
  }
  const onSubmit = () => {
    if(otp.length < 6){
      setErrorMsg('* Please enter 6 digit of OTP to proceed!')
      return;
    }
    dispatch(LoginActionWithOtp(otp,onSubmitCB,param?.val))
  };
  const classes = loginStyles();
  const MFACB = (resStatus:string,res:any) =>{
    if(resStatus === "0"){
      setimgUrl(`${res?.qrCodeImageUrl}`)
      setSetupKey(`${res.manualKey}`)
    }
  }
  useEffect(()=>{
    if(!param?.val) return
    const accountStatus = new URLSearchParams(location.search).get("accountStatus");
    const isTwoFactorSetupCompleted = new URLSearchParams(location.search).get("isTwoFactorSetupCompleted");
    console.log('param?.val',accountStatus);
    setAccountStatus(`${accountStatus}`);
    setIsMFAConfigured(`${isTwoFactorSetupCompleted}`)
    debugger;
    if(isTwoFactorSetupCompleted === "false"){
      dispatch(getMFAEnable(MFACB,param.val));
    }
  },[])
  return (
    <>
      <Box className="LoginWrap" style={styles.pageWrap}>
        <Container maxWidth="lg" className="LoginContainer">
        <Box component="div">
            <Box className="LoginTopMenu">
             
            </Box>
          </Box>

          <Box className="loginForm">
            <Box className="formHolder">
            <Box className="formHolder__logo">
                <img src={logoImgOnlyS} alt="" width={70} />
              </Box>
              <Box>
                <Typography variant="h5" gutterBottom className="text-center">
                  Enter OTP
                </Typography>
                <Box className="text-center">
                  {(isMFAConfigured === "false" && imgUrl !=="") &&
                    <>
                      <img src={imgUrl} alt=""  height={160}/>
                      <Typography variant="body2" className={`${classes.noteTxt}`}>  
                        Setup Key : {setupKey} 
                        <CustomTooltip className={`pointer ${Copied ? "Copied" : ""}`} title={Copied ? "Copied" : "Copy Code"}>
                          <ContentCopyIcon sx={{color:`${Copied ? "green" : ""}`}} onClick={copyToClipboard}  />
                        </CustomTooltip>
                      </Typography>
                    </>
                  }
                  <br />
                </Box>
                <OtpInput
                  value={otp}
                  onChange={(e:any)=>{setErrorMsg("");setOtp(e)}}
                  numInputs={6}
                  inputType="number"
                  placeholder="______"
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} className="otp-box"/>}
                />
                <br/>
              </Box>
              <Box>
                {ErrorMsg !== "" && (
                  <Typography
                    variant="body2"
                    className="txt-danger text-center"
                    gutterBottom
                  >
                    {ErrorMsg}
                  </Typography>
                )}
              </Box>
              <Box className="text-center">
                <Button
                  className="LoginBtn"
                  onClick={onSubmit}
                  variant="contained"
                >
                  Submit
                </Button>
              </Box>
              <Box>
                  <Typography variant="body2" className="text-center" >  
                      <span className="pointer" onClick = {()=>{navigate('/')}} >Cancel </span>
                  </Typography>
              </Box>
              <Box>
                {/* <Typography variant="body2" className={classes.noteTxt}>  
                  Please check your <br/> authenticator app for the OTP!
                </Typography> */}
                {accountStatus !== "Completed" && (
                <CustomTooltip title={
                  <Box>
                      <Typography variant="body2" className={`${classes.noteTxt} pointer`}>  
                        Step 1 : Install Authenticator app. 
                      </Typography>
                      <Typography variant="body2" className={`${classes.noteTxt} pointer`}>  
                        Step 2 : Login with your email address. 
                      </Typography>
                      <Typography variant="body2" className={`${classes.noteTxt} pointer`}>  
                        Step 3 : Use the above QR code or Setup Key to generate the OTP. 
                      </Typography>
                      <Typography variant="body2" className={`${classes.noteTxt} pointer`}>  
                        Step 4 : Enter the OTP and Submit!
                      </Typography>
                  </Box>
                }>
                    <Typography variant="body2" className={`${classes.noteTxt} text-center pointer`}>  
                      Need Help?
                    </Typography>
                </CustomTooltip>
                )}
              </Box>
            </Box>
          </Box>
          <Box><br/><br/><br/></Box>
        </Container>
      </Box>
    </>
  );
}

export const loginStyles = makeStyles(() =>
  createStyles({
    noteTxt: {
      padding: "10px",
      borderRadius: "8px",
      fontSize: 10,
      color: "#555",
      '& svg':{
          height:12,
          width:12,
          marginLeft:5,
          cursor:"pointer"
      }
    },
  })
);
export default LoginMFA;
