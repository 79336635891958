import { Avatar, Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getArticles, getArticlesById } from '../../../redux/actions/KnowledgeBase/article';
import parse from 'html-react-parser';
import Breadcrumbs from "./../../common2/Breadcrumbs";
import PrintArticleWrapper from '../PrintArticleWrapper';
import FileViewFromBlobUrl from '../../MobileComponents/MobileCommon/FileView';

function ViewArticleSSP() {
    const path = useLocation();
    const searchPath = path.search;
    const param = useParams();
    const dispatch = useDispatch();
    const [articleVal,setArticleVal] = useState<any>(null);
    const [relatedArticles,setRelatedArticles] = useState<any>([]);
    const [list, setList] = useState([]);
    useEffect(()=>{
        dispatch(getArticles(getCallback,true))
    },[])
    const searchVal: string | null = new URLSearchParams(searchPath).get("searchVal");
    const getCallback = (resStatus:string,res:any)=>{
        if(resStatus==="0"){
            setList(res);
            const relative = res.filter((i:any)=>{
                const objVals = `${Object.values(i)}`.toLowerCase();
                const { isActive,id } = i;
                return isActive && param?.id !== `${id}` && objVals.indexOf(`${searchVal || ""}`.toLowerCase()) >= 0
            })
            console.log("getArticlesByIdCB", searchVal,relative)  

            setRelatedArticles([...relative])
        }
    }
    useEffect(()=>{
        if(param?.id){
            dispatch(getArticlesById(param?.id,getArticlesByIdCB,true))
        }
    },[param?.id])
    const getArticlesByIdCB = (resStatus: string, res: any) => {
        if (resStatus === "0"){
            setArticleVal(res);
            console.log("getArticlesByIdCB",res)  
        }
    }
    return (
        <Box className="p-sm-0 p-2">
                <Breadcrumbs
                    data={[
                        {
                            title: 'Knowledge Base ',
                            path: '/'
                        },
                        {
                            title: 'Article List ',
                            path: '/ArticlesSSP'
                        },
                        {
                            title: 'View Article ',
                            path: `/ArticlesList/${param?.id || 0}?searchVal=${searchVal}`
                        }
                    ]
                    }
                />
                <Grid container spacing={1} className="pt-1">
                    <Grid item md={8}>
                        <PrintArticleWrapper title={`# Article ID : ${articleVal?.id}`} >
                            <Box className="white-bg article-viewer-box p-2">
                                {articleVal && <>

                                    <Typography variant="h4">
                                        {articleVal?.name}
                                    </Typography>
                                    <hr />

                                    {parse(`${articleVal?.body || ""}`)}

                                </>}
                            </Box>
                        </PrintArticleWrapper>
                        {articleVal?.articleAttachmentList?.length > 0 && (
                            <>
                                <hr />
                                <Box className={`white-bg p-2`}>
                                    <Typography variant="h6" gutterBottom>
                                        Attachments
                                    </Typography>
                                    <Box className={`row`}>
                                        {articleVal?.articleAttachmentList?.map((i: any, index: number) => {
                                            return (
                                                <Box className="col-auto p-3p" key={index}>
                                                    <FileViewFromBlobUrl url={i.attachmentPath} />
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Grid>
                    <Grid item md={4} xs={12} className='ssp-arcticle'>
                        <aside className='aside'>
                            <Box className="white-bg p-2">
                                <Typography variant="h6">
                                    Related Articles
                                </Typography>
                                <hr/>
                                {relatedArticles.map((i:any,index:number)=>{
                                    return <>
                                        {index<=4 && <ArticleThumb  data={i}/>}
                                    </>
                                })}
                            </Box>
                            <Box className="pt-1"></Box>
                            <Box className="white-bg p-2">
                                <Typography variant="h6">
                                    Trending Articles
                                </Typography>
                                <hr/>
                                {list.map((i:any,index:number)=>{
                                    return <>
                                        {index<=4 && <ArticleThumb  data={i}/>}
                                    </>
                                })}
                            </Box>
                        </aside>
                    </Grid>
                </Grid>
        </Box>
    )
}

const ArticleThumb = (props:any) =>{
    const {data} = props;
    const navigate = useNavigate();
    return(<>
        <Grid onClick={()=>{navigate(`/ArticlesList/${data?.id}`)}} container alignItems={"center"} className="pb-1 cursor-pointer" spacing={1}>
            <Grid item xs={3}>
                <Avatar
                    // className="iconAvatar contact-popup-avatar"
                    alt={"Thumb Image"}
                    src={"https://images.unsplash.com/photo-1611162616475-46b635cb6868?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
                    onClick={() => { }}
                    sx={{height:40,width:40}}
                    // height="50px"
                    // width="50px"
                />
            </Grid>
            <Grid item xs={9}>
                <Typography variant='body2'>
                    {data?.name || "Article Name"}
                </Typography>
            </Grid>
        </Grid>
    </>)
}
export default ViewArticleSSP
