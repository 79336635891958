import React, { useEffect, useState,Suspense, useMemo } from "react";
import { Routes } from "react-router-dom";
import { IState } from "../redux/reducers/rootReducers";
import { useSelector } from "react-redux";
import { PrivateAuth, PostLoginAdminAuth, SSPAuth, AdminPortalAuth, PostLoginLoader } from "./PrivateAuth";
import { Box } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

const AppRouter: React.FC = () => {
  const [tokenValues,setTokenValues] = useState<any>({});
  const isUserLoggedIn:any = useSelector((state: IState) => state?.LoginReducer?.isLoggedIn);
  useEffect(()=>{
    let logInfo: any = localStorage.getItem("loginInfo");
    setTokenValues(JSON.parse(logInfo || "{}"));
  },[isUserLoggedIn])
  const isRolesLoaded = useSelector((state: IState) => state?.LoginReducer?.roleAccess?.isLoaded);


  const returnRoutes = useMemo(() =>{
    const { isContactLogin,isAdminLogin,isAgentLogin } = tokenValues;
    console.log("returnRoutestokenValues",tokenValues)
    if(isContactLogin){
      return SSPAuth();
    }else if(isAdminLogin){
      return AdminPortalAuth();
    }else if(isAgentLogin){
      if(isRolesLoaded){
        return PostLoginAdminAuth();
      }else {
        return PostLoginLoader();
      }
    }else{
      return PrivateAuth();
    }
  },[tokenValues,isUserLoggedIn,isRolesLoaded])
  return (
    <>
    <Suspense fallback={<>
      <Box className="page-loader">
        <CircularProgress />
      </Box>
    </>}>
      <Routes>
        {returnRoutes}
      </Routes>
    </Suspense>
    </>
  );
};

export default AppRouter;
