import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Suspense, memo, useCallback, useEffect, useMemo } from "react";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllTicketsForRepeorts } from "../../../redux/actions/TicketsManagement/TicketsManagement";
import { IState } from "../../../redux/reducers/rootReducers";
import AgentsOptionReport from "./FilterComponents/AgentsOptionReport";
import CalendarRangeOptionReport from "./FilterComponents/CalendarRangeOptionReport";
import LayoutShift from "./FilterComponents/LayoutShift";
import MoreActionsReport from "./FilterComponents/MoreActionsReport";
import SearchBox from "./FilterComponents/SearchBox";
import SortOption from "./FilterComponents/SortOption";
import { IDashboardSecondaryNavbar } from "./SecondaryNavbar";
import { handleTime } from "../../helpers/helperFunctions";
import ResetFilterForReport from "./FilterComponents/ResetFilterForReport";
const CommonAppFilter:React.FC<IDashboardSecondaryNavbar> = memo((
  props: IDashboardSecondaryNavbar
) => {
    const {hideExtraIcons= false,hideAddBtn,hideViewMode,hideAgent,hideSearch,hideSort,hideFilter,report} = props;
    console.log('TicketDashboardHolder-CommonAppFilter')
    const AllData = useSelector((state: IState) => state?.TicketsReducer?.allResponses);
    //const currAgent = useSelector((state: IState) => state?.agentReducer?.loggedInAgent);
    const FiltersVal: any = useSelector((state: IState) => state?.filtersTicketManagementReducerForReport);
    const AllInfo = useSelector((state: IState) => state?.TicketsReducer?.alldataStructure);
    const dispatch = useDispatch();
    const classes = useStyles();
    const navigate = useNavigate();
    const addNewTicket = useCallback(() => {
      navigate('/home/ticket/new-ticket')
    },[]);
    //const [loader,setLoader] = useState(false);
    const callback=()=>{
      //setLoader(false)
    }
    let s = report

    useEffect(()=>{
      console.log('report check api',s);
      //setLoader(true)
      //const {searchVal,contacts,agents,agentsWithGroup,statuses,priorities,ticketTypes,sortType,rangeVal} = FiltersVal;
      // if(currAgent?.id && searchVal=== "" && contacts.length === 0 && agents.length === 0 && agentsWithGroup.length === 0 && statuses.length === 0 && priorities.length === 0 && ticketTypes.length === 0 && sortType=== "unset" && rangeVal===null && AllTickets?.length === 0){
      //       dispatch(storeFiltersValue([currAgent?.id], 'TCKT_AGENTS_VALUE'));
      //       return
      // }

      handleTime.cancel();
      if(!AllData?.ticketStatus ) return;//|| firstload
      handleTime.setup(()=>{
        document.body.classList.add('loader')
        console.log("risked-START",new Date());
        dispatch(getAllTicketsForRepeorts(FiltersVal,AllData,AllInfo,callback,s))
      });
    },[FiltersVal])

  const addBtn = useMemo(()=><>
  {!hideAddBtn &&
  <Button className={classes.addNewButton}onClick={addNewTicket}variant="contained">New Item</Button>}
  </>,[hideAddBtn,classes]);

  const resetAndCalendar = useMemo(()=><><CalendarRangeOptionReport />
  <ResetFilterForReport /></>,[classes])
  const LayoutShiftOption = useMemo(()=><>{!hideViewMode && <LayoutShift /> }</>,[hideViewMode,classes]);
  const SearchBoxOption = useMemo(()=><>{!hideSearch && <SearchBox /> }</>,[hideSearch,classes]);
  // const FilterOptionsOption = useMemo(()=><>{!hideFilter && <FilterOptions /> }</>,[hideFilter,classes]);
  const SortOptionOption = useMemo(()=><>{!hideSort && <SortOption /> }</>,[hideSort,classes]);
  const AgentsOptionOption = useMemo(()=><>{!hideAgent && <AgentsOptionReport /> }</>,[hideAgent,classes]);
  // const MoreActionsOption = ()=><MoreActions report={report} hideExtraIcons={hideExtraIcons}/>;
  const filterHolder = useMemo(() => <>
    <div className={`sticky-Top-0 ${classes.row} ${classes.gap10} ${classes.alignCenter} tickect-management-button`} style={{ justifyContent: "space-evenly" }}>
      <Suspense fallback={<Typography className="ticket-ms-loading">Loading...</Typography>}>
        {/* {!hideAddBtn &&
          <Button className={classes.addNewButton}
            onClick={addNewTicket}
            variant="contained" 
          >New Item</Button>
        } */}
        {addBtn}
        {SearchBoxOption}
        {/* {FilterOptionsOption} */}
        {SortOptionOption}
        {LayoutShiftOption}
        {AgentsOptionOption}
        {/* {!hideSearch && <SearchBox />} */}
        {/* {!hideFilter &&<FilterOptions />} */}
        {/* {!hideSort && <SortOption />} */}
        {/* {!hideAgent && <AgentsOption />} */}
        {resetAndCalendar}
        {/* {MoreActionsOption} */}
        <MoreActionsReport report={report} hideExtraIcons={hideExtraIcons}/>
        {/* <MoreActions report={report} hideExtraIcons={hideExtraIcons} /> */}
      </Suspense>
    </div></>, [classes])
  return (<>
      {filterHolder}
    </>
  );
})


const useStyles = makeStyles(() => ({
  filterBox:{
      padding: "1.5rem 0",
      borderBottom: "1px solid #ddd"
  },
  row:{
    display:"flex"
  },
  alignCenter:{
    alignItems:"center"
  },
  gap10:{
    gap:"10px"
  },
  addNewButton: {
    color: "white",
    height: "23px",
    fontSize: "9px",
    backgroundColor: "#007bff",
    padding: "2px",
    whiteSpace: "nowrap",
  }
}));
export default CommonAppFilter;