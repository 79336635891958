const initialState = {
    Data: [],
    assetBaseStatusList: [],
    AssetSources:[]
  };
  
  const assetStatusReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case "getAssetBaseStatus":
        return {
          ...state,
          assetBaseStatusList: action.payload,
        };
      case "getAssetStatus":
        return {
          ...state,
          Data: action.payload,
        };
      case "getAssetSources":
        return {
          ...state,
          AssetSources: action.payload,
        };
      case "singleAssetStatus":
        return {
          ...state,
          Data: action.payload,
        };
      case "updateAssetStatus":
      case "multipalUpdateAssetStatus":
    //   case "multipalParmanentDeleteAssetStatus":
      case "softDeleteAssetStatus":
        return {
          ...state,
        };
      case "deleteAssetStatus":
        return {
          ...state,
        };
      case "loading":
        return {
          ...state,
          showLoading: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default assetStatusReducer;
  