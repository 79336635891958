import { Box } from "@mui/material";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sortable from "sortablejs";
import { TicketPriorityChange } from "../../../../../../services/dashboard/dashboard.service";
import { getAllTickets } from "../../../../../../redux/actions/TicketsManagement/TicketsManagement";
import { IState } from "../../../../../../redux/reducers/rootReducers";
import { priorityToPriorityUpdate } from "../../../../../../redux/actions/TicketsManagement/TicketActionUtils";
import { ActionType } from "../../../../../../redux/actions/Snackbars";
import configs from "../../../../../../configs/config";

export const PriorityDropable = memo((props:any) =>{
    console.log('configs',configs)
    const AllInfo = useSelector((state: IState) => state?.TicketsReducer?.alldataStructure);
    const {id} = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(()=>{
        let allGroups: any = document.getElementById(`PriorityDropable-${id}`);
        if(!allGroups) return;
        var sortable = Sortable.create(allGroups, {
            animation: 150,
            group: "list",
            onAdd: function (e: any) {
                let isMyTickets = e.item.getAttribute("data-mytickets");
                let ticketId = e.item.getAttribute("data-ticket-id");
                console.log('isMyTickets',isMyTickets)
                if(isMyTickets === 'myTickets' || isMyTickets === 'newTickets'){
                    //alert('redirect')
                    navigate(`/home/ticket/${ticketId}?priority=${id}`)
                    return
                }
                ticketDropped(e)
                e.clone.replaceWith(e.item);
            },
        });
    },[])
    const ticketDropped = async (evt: any) => {
        let priorityId = evt.to.getAttribute("label-prioritycolid");
        let ticketId = evt.item.getAttribute("ticket-id");
        const droppedPriorityName = evt.to.getAttribute("label-prioritycolname");
        const tempObj = {
            sourceCode : configs.SOURCE,
            priorityId : +priorityId
        }
        //dispatch(priorityToPriorityUpdate(ticketId,tempObj))
        //debugger;
        //return
        TicketPriorityChange(configs.SOURCE,
            ticketId,
            priorityId
        ).then((response: any) => {
            dispatch(priorityToPriorityUpdate(ticketId,tempObj,response));
        });
    };
    return (<Box sx={{height:100,background:"#555"}} className="column-overlay" label-prioritycolid={id} label-prioritycolname={"column Name"} id={`PriorityDropable-${id}`}></Box>)
})
export default PriorityDropable;