import { Avatar, Box, Button, CircularProgress, IconButton, InputAdornment, Typography } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateContactsSsp } from '../../../redux/actions/ContactManagement/Contact';
import { FileUpload } from '../../../redux/actions/FileUpload/FileUpload';
import { IState } from '../../../redux/reducers/rootReducers';
import CommonStyles from '../../common/CommonStyles';
import coverImg from "./../../../assest/coverImg.jpg";
import profile from "./../../../assest/profile.png";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import SideDrawer from '../../common/sideDrawer';
import { TextBoxLatest } from '../../common/TextBoxLatest/TextBoxLatest';
import { returnFormValues } from '../../helpers/helperFunctions';
import { isValidate } from '../../../pages/new-ticket/LatestTicket/Validation';
import { schemaForPasswordReset } from './schema';
import { changePasswordContactUser } from '../../../redux/actions/SelfServicePortal/ContactAid/ContactAid';
import CustomFormLabel from '../../common/form-label/CustomFormLabel ';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function ProfileBanner() {
    const classes = CommonStyles();
    const dispatch = useDispatch();
    const [loader, setloader] = useState(false);
    const [drawerState, setDrawerState] = useState<boolean>(false);
    const [uploadVal, setUploadVal] = useState<number>(0);
    const [passwordErr,setPasswordErr] = useState("");


    const currContact = useSelector((state: IState) => state?.ContactsReducer?.loggedContact);
    const profileImg = useSelector((state: IState) => state?.agentReducer?.saveProfile);
    const uploadHandler = (e: any) => {
        e.preventDefault();
        let fileObject = {
            description: "First File",
            filetypeid: 1,
            ExtensionTypeId: 1,
            Name: "profile",
            formFile: e.target.files[0],
        };
        setloader(true);
        dispatch(FileUpload(fileObject, getUploadingPercentage, false, uploadCB));
    };
    const getUploadingPercentage = (val:any) =>{
        console.log('getUploadingPercentage',val)
        setUploadVal(val)
    }
    const uploadCB = (resStatus: string, res: any) => {
        setloader(false);
        setUploadVal(0);
        console.log("UPLOAD-CB", res);
        if (resStatus === "0") {
            let docId = res.data.id;
            let currcontactInfo = { ...currContact };
            currcontactInfo.profilePictureId = docId;
            currcontactInfo.profilePicturePath = res.data.filePath;
            dispatch(UpdateContactsSsp(currcontactInfo))
        }
    };
    const changePassword = () => {
        setDrawerState(true);
    };
    const OnCloseDrawer = () => {
        setDrawerState(false);
        setPasswordErr("")
    }


    const onPasswordSubmit = (data:any) =>{
        dispatch(changePasswordContactUser(data, changePasswordCB));
    }
    const changePasswordCB = (resStatus:string,res?:any) =>{
        if(resStatus === "1"){
            setPasswordErr(res || "");
            return;
        }
        OnCloseDrawer();
    }
    return (
        <>
            <Box className={classes.profileBox}>
                <Box>
                    <Box>
                        <Box>
                            <Box className={classes.profileImgHolder}>
                                <img src={coverImg} alt="cover Img" height={200} width="100%" />
                                <Box className="profileInfo">
                                    <Box className="profileImg">
                                        <Box>
                                            <Box className="profilePicHolder">
                                                {profileImg === "" ? (
                                                     <Avatar
                                                     className="sml-avatar"
                                                     sx={{ height: 80, width: 80 }}
                                                     alt={"?"}
                                                     src={profile}
                                                   />
                                                ) : (
                                                    <Avatar
                                                     className="sml-avatar"
                                                     sx={{ height: 80, width: 80 }}
                                                     alt={"?"}
                                                     src={profile}
                                                   />
                                                )}
                                                {loader && <Box className="img-loader"><Typography>{uploadVal}%</Typography></Box>}
                                                <input
                                                    type="file"
                                                    id="csvUpload"
                                                    accept=".jpg,.png,.jpeg"
                                                    onChange={uploadHandler}
                                                    className="d-none"
                                                />
                                                <label htmlFor="csvUpload">
                                                    <span>Upload Profile Image</span>
                                                </label>
                                            </Box>
                                        </Box>
                                        <Box className="profileTxt">
                                            <Typography
                                                className={classes.themeColorTxt}
                                                variant="h6"
                                            >
                                                {currContact.knownAs} <br />
                                            </Typography>

                                            <Typography
                                                className={classes.themeColorTxt}
                                                variant="body2"
                                            >
                                                {currContact.email} <br />
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className="controls">
                                        <Typography
                                            className={`${classes.themeColorTxt} ${classes.row} ${classes.alignItemsCenter}`}
                                            variant="body2"
                                            display="block"
                                            gutterBottom
                                            onClick={changePassword}
                                        >
                                            <GppGoodOutlinedIcon />
                                            Change Password
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <br />
                        </Box>
                    </Box>
                </Box>
            </Box>



            <SideDrawer
                onClose={OnCloseDrawer}
                drawerState={drawerState}
                title="Change Password"
            >
                <Box className="px-2">
                     <ChangePasswordForm passwordErr={passwordErr} onPasswordSubmit={onPasswordSubmit} onClose={OnCloseDrawer} />                               
                </Box>
            </SideDrawer>
        </>
    )
}


interface iChangePasswordForm {
    onClose : any;
    onPasswordSubmit:any;
    passwordErr:string;
    isReset?:boolean;
}
export const ChangePasswordForm = memo(({isReset,passwordErr,onPasswordSubmit}:iChangePasswordForm) =>{
    const classes = CommonStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [showCurrPassword, setShowCurrPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errorVal,setErrorVal] = useState<any>({
        currentPassword: false,
        password: false,
        confirmPassword: false,
    })
    const onFormSubmit = (e:any) =>{
        e.preventDefault();
        const data = returnFormValues(e.currentTarget);
        if(isReset){
            data['currentPassword'] = "__"
        }
        const validation = isValidate(data, schemaForPasswordReset);
        if (!validation.isValid) {
            setErrorVal(validation.errors)
            return
        }
        onPasswordSubmit({ 
            ...data
        })
    }
    useEffect(()=>{
        setErrorVal({
            ...errorVal,
            'all' : passwordErr
        })
    },[passwordErr])
    
    const unsetErr = (name:string) =>{
        setErrorVal({
            ...errorVal,
            [name] : false,
            'all' : false
        })
    }
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleClickShowCurrPassword = () => {
        setShowCurrPassword(!showCurrPassword);
    };
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    return(<>
        <form onSubmit={onFormSubmit}>
            {!isReset &&
            <Box className="pt-1">
                <TextBoxLatest
                    multiline={false}
                    placeholder={"Please Enter"}
                    title={"Current Password"}
                    isMandotary={true}
                    type={showCurrPassword ? "text" : "password"}
                    name={'currentPassword'}
                    errorMsg={`${errorVal?.currentPassword || ""}`}
                    isError={errorVal?.currentPassword ? true : false}
                    onchangeHandler={()=>unsetErr("currentPassword")}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleClickShowCurrPassword}>
                                    {showCurrPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            }
            <Box className="pt-1">
                <TextBoxLatest
                    multiline={false}
                    placeholder="Please Enter"
                    title="New Password"
                    isMandotary={true}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    errorMsg={`${errorVal?.password || ""}`}
                    isError={errorVal?.password ? true : false}
                    onchangeHandler={() => unsetErr("password")}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleClickShowPassword}>
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Box className="pt-1">
                <TextBoxLatest
                    multiline={false}
                    placeholder={"Please Enter"}
                    title={"Confirm Password"}
                    isMandotary={true}
                    type={showConfirmPassword ? "text" : "password"}
                    name={'confirmPassword'}
                    errorMsg={`${errorVal?.confirmPassword || ""}`}
                    isError={errorVal?.confirmPassword ? true : false}
                    onchangeHandler={()=>unsetErr("confirmPassword")}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleClickShowConfirmPassword}>
                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            {errorVal?.all && <Box className="p-1">
                    <CustomFormLabel
                        labelName={errorVal?.all}
                        isMandotary={false}
                        classNames='sml-txt txt-danger'
                    />
            </Box>}
            <Box className="pt-1">
                <Button
                    variant="contained"
                    type="submit"
                    className={`${classes.btn} ${classes.btnSubmit} w-100 m-0`}
                >Change Password</Button>
            </Box>
        </form>
    </>)
})

export default ProfileBanner