import axios from "axios";
import configs from "../../../../../configs/config";
import { ActionType } from "../../../Snackbars";
const url = `${configs.BASE_URL}/master/MailService`;
const hardDeleteUrl = `${url}/permanent/delete`;
export const GET_SETTINGS_SSP = 'GET_SETTINGS_SSP';
export const ADD_SETTINGS_SSP='ADD_SETTINGS_SSP'


export const storeAllEmailSettingsSsp = (val: any) => ({
    type: GET_SETTINGS_SSP,
    payload: val,
});
export const addNewEmailSettingsSsp = (val: any) => ({
  type: ADD_SETTINGS_SSP,
  payload: val,
});
export const getEmailSettingsSsp = (callback?: any) => {
    return async (dispatch: any) => {
        try {
            const response: any = await axios(url);
            dispatch(storeAllEmailSettingsSsp(response.data));
            console.log("response.data",response.data);
            
            // if (callback) callback(response.data)
        } catch (error: any) {
            dispatch({
                type: ActionType.SNACKBAR,
                payload: {
                    message: error.response.data && typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Try again.",
                    severity: "error",
                    open: true,
                },
            });
            return console.log(error.response);
        }
    };
};
export const getMailSettingsSspByID = (id:any,callback?:any) => {
    return async (dispatch: any) => {
      try {
        const response:any = await axios(`${url}/${id}`);
        callback(response.data)
      } catch (error:any) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error.response.data && typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Try again.",
            severity: "error",
            open: true,
          },
        });
        return console.log(error.response);
      }
    };
  };
export const editEmailSettingsSsp = (id:number,request:any,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.put(`${url}/${id}`,request);
      ;
      console.log(`${url}/${id}`);
      dispatch(addNewEmailSettingsSsp('0'));
      callback();
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const addEmailSettingsSspAction = (data: any,callback:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(url,data);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "New Email Setting Added Successfully!",
          severity: "success",
          open: true,
        },
      });
      callback();
      ;
    } catch (error:any) {
      dispatch(addNewEmailSettingsSsp('1'));
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response &&  typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const hardDeleteEmailSettingsSsp = (id:number) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.delete(`${hardDeleteUrl}/${id}`);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "Record Deleted Successfully!",
          severity: "success",
          open: true,
        },
      });
      dispatch(getEmailSettingsSsp())
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response && typeof error.response.data === 'string' ? error.response.data :"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};