import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { Typography, Button, Box } from '@mui/material';
interface IConfirmationPopupProps{
    onClose:any;
    onSubmit:any;
    title:string;
    desc:string;
    buttonLabel?:string
}
function ConfirmationPopup(props:IConfirmationPopupProps) {
    const {onClose,onSubmit,desc,title,buttonLabel="Proceed"} = props;
  return (
    <Box>
        <Box className="confirmationPopup">
          <Box className="content p-2">
              <ClearIcon onClick={onClose} className="closeIcon"/>
              <Typography>
                {title}.!
                <span> {desc} </span>
              </Typography>
              <Box className="text-center p-1">
                <Button
                  variant="outlined"
                  onClick={onClose}
                  size={"small"}
                >Cancel</Button>

                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <Button
                  variant="contained"
                  onClick={onSubmit}
                  size={"small"}
                >{buttonLabel}</Button>
              </Box>
          </Box>
      
        </Box>
    </Box>
  )
}

export default ConfirmationPopup