import { ActionType, ISnackbar, ISnackbarType } from "../actions/Snackbars"

export interface ISnackbarState {
    snackbar: ISnackbar
}
const initState: ISnackbarState = {
    snackbar: {
        message: "",
        severity: "success",
        open: false
    }
}

const snackbar = (state: ISnackbarState = initState, action: ISnackbarType) => {
    switch (action.type) {
        case ActionType.SNACKBAR:
            return {
                ...state,
                snackbar: action.payload
            }
        default:
            return state
    }
}

export default snackbar;