const sspDataFormat = (SSPservices:any) => {
    const AllInfo:any = {
        'contactServices' : []
    }
    SSPservices.map((i: any) => {
        if (i.isActive && i?.ticketType?.name) {
            AllInfo.contactServices.push(
                {
                    id: i.id,
                    isActive: i.isActive,
                    name: i.name,
                    ticketTypesId: i?.ticketType?.id
                }
            )
        }
    })
    return AllInfo
}
export const formEditFlowAgentData = (defaultData:any, roleData:any, TicketTypes:any, SSPservices:any, category:any,ticketStatusList:any=[]) =>{//updating system admin values on edit
    const ticketStructure:any  = {};
    TicketTypes.map((i:any)=>{ 
        ticketStructure[i.id] =  i.name;
    });
    let isAgent = false;
    const dataStruct = '';
    const allData = JSON.parse(JSON.stringify(defaultData));
    const SSPservicesList:any =[];
    const categoriesList:any =[];
    roleData.agentPermissionsList.map((i:any)=>{
        const adminType = i.adminTypes.name;
        if(i.isActive && i.isAgentAdmin){
            isAgent = true;
            //console.log(adminType)
            if(adminType === 'TicketTypes'){
                //console.log('ticketStructure',ticketStructure);
                //console.log(adminType);
                //ticketStructure[i.adminChildIdLeavel1]
                if(allData?.TicketTypes?.[ticketStructure[i.adminChildIdLeavel1]]?.permissions){
                    allData?.TicketTypes?.[ticketStructure[i.adminChildIdLeavel1]]?.permissions.map((k:any)=>{
                        if(k.id === i.permissionId){
                            k.checked = true;   
                        }
                    })
                }
            }else if(adminType === 'Categories'){
                if(!categoriesList.some((items:any)=>items.id === i.id)){
                    categoriesList.push(i)
                }
            }else if(adminType === 'ContactAid'){
                if(!SSPservicesList.some((items:any)=>items.id === i.id)){
                    SSPservicesList.push(i)
                }
            }else if(allData[adminType]) {
                allData[adminType].map((j:any)=>{
                    if(j.id === i.adminChildIdLeavel1){
                        j.isChecked = true;
                    }
                })
            }
        }
    })
    return {isAgent : isAgent, data : allData, categoryList:categoriesList , SSPservicesList:SSPservicesList};
}
export default sspDataFormat;