import axios from "axios";
import configs from "../../configs/config";
import { IFileUpload } from "../../redux/actions/FileUpload/FileUpload";
import { SnackBar } from "../AssetsManagement/AssetType";

export const fileUploadService: any = async (
  fileObejct: IFileUpload,
  updateFileUploadPercentage: any,
  fileUploadCallback: any,
  callbackObj: any = {},
  moduleType: any = ""
) => {
  try {
    const formData = new FormData();
    formData.append("formFile", fileObejct.formFile);
    const response = await axios.post(
      `${configs.BASE_URL_FILE_UPLOAD}/Files?description=${fileObejct.description}&filetypeid=${fileObejct.filetypeid}&moduleType=${moduleType}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          if (updateFileUploadPercentage) {
            updateFileUploadPercentage(
              Math.round((100 * data.loaded) / data.total)
            );
          }
        },
      }
    );
    // response.data.filePath = response.data.filePath.replaceAll("https://simplisysstoragev1.blob.core.windows.net","");
    // response.data.filePath = "https://simplisysstoragev1.blob.core.windows.net" + response.data.filePath;
    try {
      fileUploadCallback("0", { ...response, ...callbackObj });
    } catch (e: any) {
      console.log(e);
    }
    return response;
  } catch (e: any) {
    console.log(e);
    fileUploadCallback("1", e.response, callbackObj);
  }
};

export const fileUploadServiceNew: any = async (
  fileObejct: any,
) => {
  try {
    const formData = new FormData();
    formData.append("formFile", fileObejct.formFile);
    const response = await axios.post(
      `${configs.BASE_URL_FILE_UPLOAD}/Files?description=${fileObejct.name}&filetypeid=${fileObejct.fileTypeId}&moduleType=${fileObejct.moduleType}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (e: any) {
    return false;
  }
};

export const fileUploadServiceForBloeStorage: any = async (
  url: string,
  fileObejct: any,
  fileUploadCallback: any
) => {
  try {
    if (fileObejct) {
      const formData = new FormData();
      formData.set("", fileObejct);
      console.log("fileObejct.formFilesssssss", fileObejct);

      const response = await fetch(`${url}`, {
        method: "PUT",
        headers: {
          "x-ms-blob-type": "BlockBlob",
          "Content-Type": fileObejct?.type,
        },
        body: fileObejct,
      });
      console.log("response ", response);
      fileUploadCallback("1", response);
    }
  } catch (error) {
    console.error("Error:", error);
    fileUploadCallback("0", error);
  }
};



/*__________________MULTIPLE_FILE_UPLOAD_WITH_BLOB__________________*/
export const multipleFileUploadServiceForBloeStorage = async (blobUrls:string[]=[],fieObjs:any=[]) => {
    try {
        // Create an array of fetch PUT promises
        const putPromises = blobUrls.map((url, index) => {
            return fetch(url, {
                method: "PUT",
                headers: {
                  "x-ms-blob-type": "BlockBlob",
                  "Content-Type": `${fieObjs[index]?.type}`,
                },
                body: fieObjs[index],
            });
        });

        // Wait for all PUT requests to complete
        const responses = await Promise.all(putPromises);

        // Check if all responses are OK
        const dataPromises = responses.map(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json(); // Parse the JSON from the response
        });

        // Wait for all data to be retrieved
        const data = await Promise.all(dataPromises);

        console.log('Success:', data); // Process the retrieved data as needed
    } catch (error) {
        console.error('Error puting data:', error);
    }
};





const exportNewFile = () => {
  return new FormData();
};
export const bulkFileUploadService: any = (
  fileArr: any[] = [],
  callback?: any
) => {
  return async (dispatch: any) => {
    const returnError = (e: any) => {
      dispatch(
        SnackBar("Adding attachments failed! Please try again!", "warning")
      );
      if (callback) callback("1", e);
    };
    try {
      Promise.all(
        fileArr.map(async (item: any, index: number) => {
          const formData = exportNewFile();
          formData.append("formFile", item);
          return axios.post(
            `${configs.BASE_URL_FILE_UPLOAD}/Files?description=${item.name}&filetypeid=${item.type}&moduleType=emailtemplateattachmentinline`,
            formData
          );
        })
      )
        .then((all: any) => {
          all = all.map((i: any) => {
            if (i.data.filePath) {
              // i.data.filePath = i.data.filePath.replaceAll("https://simplisysstoragev1.blob.core.windows.net","");
              // i.data.filePath = "https://simplisysstoragev1.blob.core.windows.net" + i.data.filePath;
            }
            return i;
          });
          console.log("resAll", all);
          try {
            if (callback) callback("0", all);
          } catch (e: any) {
            returnError(e);
          }
        })
        .catch((e: any) => {
          returnError(e);
        });
    } catch (e: any) {
      returnError(e);
    }
  };
};

export const getAllFilesService = async () => {
  try {
    let files = await axios.get(`${configs.BASE_URL_FILE_UPLOAD}/Files`);
    return files.data;
  } catch (e) {
    console.log(e);
  }
};

export const deleteFileService = async (fileId: any) => {
  try {
    return await axios.delete(
      `${configs.BASE_URL_FILE_UPLOAD}/Files/${fileId}`
    );
  } catch (e) {
    console.log(e);
  }
};

export const getTicketAttachmentService = async () => {
  try {
    let files = await axios.get(`${configs.BASE_URL_Ticket}/TicketAttachment`);
    return files.data;
  } catch (e) {
    console.log(e);
  }
};

export const uploadTicketAttachment = async (ticketAttachment: any) => {
  try {
    let files = await axios.post(
      `${configs.BASE_URL_Ticket}/TicketAttachment`,
      ticketAttachment,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return files.data;
  } catch (e) {
    console.log(e);
    return false;
  }
};
