import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { useNavigate } from 'react-router-dom';
import CustomDialog from '../../../components/common/dialog/CustomDialog';
import CallRequest from './CallRequest';
import { IState } from '../../../redux/reducers/rootReducers';
import { connect } from 'react-redux';
interface IViewPricingTable {
    list?:any[]
}
function ViewPricingTable({list=[]}:IViewPricingTable) {
    const navigate = useNavigate();
    const startTrial = (item: any) => {
        if(item.isEnterprises){
            setShowContact(true)
        }else{
            navigate(`/freeTrial/${item.id}`);
        }
    };
    /* === CONTACT POPUP === */
    const [showContact, setShowContact] = useState<boolean>(false);
    return (
        <>
            <Box className="pricingFeaturesTable">
                <Box className="table_header">
                    <Grid container justifyContent={"center"}>
                        <Grid item md={4}>
                            <Typography component={"h4"}>Features</Typography>
                        </Grid>
                        {list.map((i: any) => {
                            const {isEnterprises} = i
                            return (
                                <Grid item md={2}>
                                    <Box className="text-center">
                                        <Typography component={"h4"}> {i.name} </Typography>
                                        <Button className={`upgrade_btn ${isEnterprises ? "contact" : ""}`} onClick={()=>startTrial(i)}>
                                            {isEnterprises ? "Talk to Us" : "Try for Free"}
                                        </Button>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>

                <Grid container>
                    <Grid item md={4}>
                        {(list?.[0]?.featureList || []).map((j: any) => {
                            return (<Box>
                                <Typography>{j.displayName}</Typography>
                            </Box>)
                        })}
                    </Grid>
                    {list.map((i: any) => {
                        return (
                            <Grid item md={2}>
                                <Box className="text-center">
                                    {(i.featureList || []).map((j: any) => {
                                        const isBool = j?.preDefinedFeature?.dataType === 'bool' || j?.preDefinedFeature === null;
                                        console.log("isBoolisBool",isBool,j.data,j?.preDefinedFeature)
                                        return (
                                            <Typography>
                                                {isBool 
                                                ? 
                                                    j.data === "true" 
                                                    ? 
                                                    <TaskAltOutlinedIcon />:
                                                    <CloseIcon/> 
                                                : (j.data || '-')}
                                            </Typography>)
                                    })}
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
            <CustomDialog
                heading={`Request a call back!`}
                body={
                <>
                    <CallRequest setShowContact={setShowContact} />
                </>
                }
                open={showContact}
                handleClose={() => {
                    setShowContact(false);
                }}
            />
        </>
    )
}

// export default ViewPricingTable
const mapStateToProps = (state:IState) => {
    return ({
      list: state?.FeatureReducer?.viewOnlyFeatures,
    })
  };
  
  export default connect(mapStateToProps)(ViewPricingTable);