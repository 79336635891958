import { Avatar, Box, Button, IconButton, InputAdornment, Typography } from '@mui/material'
import axios from 'axios'
import React, { createRef, useEffect, useRef, useState } from 'react'
import { TextBoxLatest } from '../common/TextBoxLatest/TextBoxLatest';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import WebAssetOutlinedIcon from '@mui/icons-material/WebAssetOutlined';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import "./bot.css";
import { connect, useDispatch } from 'react-redux';
import getBotReply, { initiateChat } from '../../redux/actions/ChatBot/chatBot';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import BotImg from "../../assest/botImg.png"
import moment from 'moment';
import { IState } from '../../redux/reducers/rootReducers';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
export interface IMessageItem {
    type: "response" | "request";
    message: string;
    createdAt:string;
}
function ChatBot({AiPermission=false}:{AiPermission?:boolean}) {
    const [show, setShow] = useState(false);
    return (<>
        {AiPermission && 
            <>
                <Box className="bot-button">
                    <IconButton onClick={() => setShow(!show)} className="" color="inherit">
                        {show ? 
                        <CloseIcon className="close" /> :
                        <span className="simple-icon icon-bubbles"></span>}
                    </IconButton>
                </Box>
                {(show) ? <><ChatBotContent closeHandler={setShow}/></> :<></>}
            </>
        }
        </>
    )
}
function ChatBotContent({closeHandler}:any) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const valueRef: any = createRef();
    const bodyRef: any = useRef(null);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState<IMessageItem[]>([])
    const getData = debounce((val: string) => {
        setLoading(true);
        dispatch(getBotReply(val, getBotReplyCB));
        scrollToBottom();
    }, 200)
    const getBotReplyCB = (isSuccess: boolean, response: string = "") => {
        // if(isSuccess){
        setLoading(false);
        const tempObj: IMessageItem = {
            type: "response",
            message: response,
            createdAt : new Date().toISOString()
        }
        setList((old) => old.concat(tempObj));
        scrollToBottom();
        // }
    }
    const onSearchKeyDownHandler = (event: any) => {
        if (event.key === 'Enter') {
            if (`${valueRef?.current?.value}`.trim() === "") {
                return;
            }
            const tempObj: IMessageItem = {
                type: "request",
                message: valueRef?.current?.value || "",
                createdAt : new Date().toISOString()
            }
            setList((old) => old.concat(tempObj)); // sending a message            
            getData(valueRef?.current?.value);
            valueRef.current.value = ""; // unset input box
            scrollToBottom();
        }
    }
    const scrollToBottom = () => {
        if (bodyRef?.current) { // scroll to the bottom of chat
            setTimeout(() => {
                bodyRef.current.scroll(0, bodyRef.current.scrollHeight + 100);
            }, 100);
            // bodyRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }

    useEffect(() => {
        dispatch(initiateChat(initiateChatCB))
        return () => {
            // effect
        };
    }, [])

    const initiateChatCB = (chatHistory: IMessageItem[]) => {
        setList(chatHistory)
    }

    useEffect(() => {
        scrollToBottom();
    }, [list.length])
    return (
            <Box className={`bot-holder`}>
                <Box className="bot-header">
                    <SmartToyOutlinedIcon />
                    <Typography component="h4">ServoAI</Typography>
                    <Typography component="span">
                        <WebAssetOutlinedIcon className="close" onClick={() => { navigate('/chatHistory'); closeHandler(false); }} />
                        <CloseIcon className="close" onClick={() => closeHandler(false)} />
                    </Typography>
                </Box>
                <Box className="bot-body">
                    {/* noscrollbar */}
                    <Box className="chatbody noscrollbar" ref={bodyRef}>

                        {list.map((i: IMessageItem) => {
                            return (
                                    <Box>
                                        <ChatItem type={i.type} message={i.message} createdAt={i.createdAt} />
                                    </Box>
                                )
                        })}
                        {loading &&
                            <Box className={`chatItem`}>
                                <Box className="message">
                                    <Typography>
                                        <Box className='dot-loader'></Box>
                                    </Typography>
                                </Box>
                            </Box>
                        }



                    </Box>
                </Box>
                <Box className="bot-footer">
                    <TextBoxLatest
                        placeholder="Please Enter"
                        ref={valueRef}
                        name='search'
                        onKeyDownHandler={onSearchKeyDownHandler}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment onClick={() => { onSearchKeyDownHandler({ key: "Enter" }) }} className='icon-holder' position="end">
                                    <SendRoundedIcon className="pointer" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
    )
}


export const ChatItem = ({type,message,createdAt}:IMessageItem) => {
    return(
    <Box className={`chatItem ${type === "request" && "by-user"}`}>
        {type === "request" ?
            <Typography><span>{moment.utc(createdAt).local().fromNow()}</span> You &nbsp; &nbsp; &nbsp;</Typography> :
            <Box className="bot-name">
                <SmartToyOutlinedIcon />
                <Typography>Servodesk Bot</Typography>
            </Box>
        }
        <Box className="message">
            <Typography>{message}</Typography>
            {type === "request" &&
                <Typography component="span">
                    <CheckCircleOutlineIcon />
                </Typography>
            }
        </Box>
    </Box>)
}


const mapStateToProps = (state:IState) => {
    return ({
      AiPermission: state?.FeatureReducer?.featurePermissions?.AI,
    })
  };
  
export default connect(mapStateToProps)(ChatBot);
// export default ChatBot
