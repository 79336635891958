import { Box, Button } from '@mui/material'
import React from 'react'
import { returnFormValues } from '../../helpers/helperFunctions';
interface IFormWrapperProps{
    children : JSX.Element;
    onClose: (val:any)=> void;
    onSubmit: (val:any)=> void;
    cancelLabel?:string;
    submitLabel?:string;
}
function FormWrapper(props:IFormWrapperProps) {
    const {children,onClose,onSubmit,cancelLabel="Cancel",submitLabel="Submit"} = props;
    const onSubmitHandler = (e:any)=>{
        e?.preventDefault(); 
        e?.stopPropagation();
        const data = returnFormValues(e.currentTarget);
        console.log("FormWrapper_onSubmitHandler",data)
        onSubmit(data);
    }
    return (
        <Box component={"form"} className='mobile-formWrapper' onSubmit={onSubmitHandler}>
            {children}

            <Box className="mobile-formWrapper-footer">
                <Box><Button onClick={onClose} className='btn-outlined' >{cancelLabel}</Button></Box>
                <Box><Button type='submit' className='btn-filled' >{submitLabel}</Button></Box>
            </Box>
        </Box>
    )
}

export default FormWrapper