import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { alpha, styled } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
// web.cjs is required for IE11 support
import { useSpring, animated } from 'react-spring';
import { TransitionProps } from '@mui/material/transitions';
import { Formik } from 'formik';
import useDoubleClick from '../../../hooks/useDoubleClick';
import { TextBoxLatest } from '../TextBoxLatest/TextBoxLatest';
import { debounce } from 'lodash';
import { Typography } from '@mui/material';

function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));




const TreeRecursive = ({data,onHandleClick, color,id}:{data: any,onHandleClick: any, color: any, id: any}) => {
    return data.map((item1 : any)=>{

        return (
          
            <StyledTreeItem  nodeId={item1.id} label={item1.name} onClick={()=>{onHandleClick(item1.id,item1.name)}
              } sx={{color: id==item1.id ? "blue" : "black"}}  >
              {/* Call the <TreeRecursive /> component with the current item.childrens */}
              { item1.hasOwnProperty("itemList") && <TreeRecursive data ={item1.itemList} onHandleClick={onHandleClick} color="black" id={id} />}
              { item1.hasOwnProperty("childrenItemList") && <TreeRecursive data ={ item1.childrenItemList} onHandleClick={onHandleClick} color="black" id={id} />}
            </StyledTreeItem>
      )
    })
  
  };


interface ICategoryval{
  id:number,
  label:string
}  
interface ICustomTreeProps{
  data : any[];
  title?:string;
  defaultValue : number | null;
  onSelect : (val:ICategoryval) =>void;
}
export default function CustomTree({data, onSelect, title="", defaultValue}:ICustomTreeProps) {
  const [id, setId]= React.useState<any>(defaultValue || null)
  const [tempCategory, setTempCategory]= React.useState<any>(data)

  const searchRef:any = React.createRef();
  console.log('CustomTree', data)
  const onHandleClick=( id: any, name: any)=>{
      console.log('onHandleClick',onHandleClick)
      onSelect({id,label:name})
      setId(id)
  }
  const onSearch = debounce((e:any) =>{
    console.log("onSearchonSearch", e?.target?.value)
    setTempCategory(getCategoryWithSearch(data,e?.target?.value))
  },300)
  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <TextBoxLatest
          placeholder="Search..."
          onchangeHandler={onSearch}
          ref={searchRef}
          name='search'
          type='search'
        />
      </div>
      <Typography>{title}</Typography>
      <TreeView
        aria-label="customized"
        defaultExpanded={['1']}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}
        sx={{ height: 264, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
      >
          <TreeRecursive data={tempCategory} onHandleClick={onHandleClick} color="blue" id={id}/>
        <div></div>
      </TreeView>
    </>
  );
}







const checkName = (name: string = "",value:string) => {
  return name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
};
const getCategoryWithSearch = (category:any,value:string) => {
  console.log("value", value);
  if (!category) return;
  try {
    let subList: any = [];

    category.forEach((i: any) => {
      if (i.isActive) {
        let itemsList: any = [];

        i.itemList.forEach((j: any) => {
          if (j.isActive) {
            let childList = j.childrenItemList.filter((k: any) =>
              checkName(k.name,value)
            );
            if (childList.length > 0 || checkName(j.name,value)) {
              j.childrenItemList = childList;
              itemsList.push(j);
            }
          }
        });

        if (itemsList.length > 0 || checkName(i.name,value)) {
          i.itemList = itemsList;
          subList.push(i);
        }
      }
    });

    console.log("setTempCat(subList)", category?.[0], subList, value);
    return subList;
  } catch (e) {
    return  null;
    console.log(e);
  }
}