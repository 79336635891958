import { Box, TextField, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import CommonStyles from "../common/CommonStyles";
export default function AutoCompleteInputBox({
  type,
  title,
  placeholder='Please Enter',
  required,
  styleProp,
  inputStyle,
  classname,
  onChange,
  value,
  name,
  min,
  max,
  disable,
  label,
  defaultValue,
  errMsg = '',
  showErr = false,
  ...props
}: any) {
  const classes = CommonStyles();
  //const { size, ...rest } = props;
  return (
    <Box>
      <FormControl className={classes.contactSearchDropdown}>
        <Box className={`${classes.Box} ${classname}`}>
          <Typography className={`${styleProp}`}>{title}</Typography>
          <TextField
            id="Input"
            className={`${inputStyle} assest-contact`}
            placeholder = {placeholder}
            variant="outlined"
            size="small"
            {...props}
          />
        {showErr && <Typography variant="caption" className={classes.errInlineMsg} display="block">{errMsg}</Typography>}
        </Box>
      </FormControl>
      
    </Box>
  );
}
