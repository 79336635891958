import { Box, Button, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleTime } from '../../../components/helpers/helperFunctions';
import { IState } from '../../../redux/reducers/rootReducers';
import { IGroupData } from './GroupDataChart';
import HomeGroupFilter from './HomeGroupFilter';
interface IDrillDownProps {
    handleClose: () => void;
    homeFilter: { [key: string]: any }; 
    groupsList?: Array<{  [key: string]: any  }>;
    dispatch: any;
    dashboardGroup:{ [key: string]: any };
    groupsRes:{ [key: string]: any };
}  
interface Item {
    id : number,
    label : string,
    value : number
  }
interface IGroupVal{
    key:string;
    values: string[];
    agentIds:number[];
    groupId:number;
}
type ICheckItemObj = {
    [key: string]: number;
};
const DrillDownFilter = ({dispatch,groupsRes={},dashboardGroup={},groupsList=[],handleClose,homeFilter={}}:IDrillDownProps) => {
    const navigate = useNavigate()
    const [allActiveGroups, setAllActiveGroups] = useState<IGroupData[]>([])
    const [groupData,setGroupData] = useState<{ [key : string] : IGroupVal}>({})
    const drillDown = () => {
        console.log("drillDownhomeFilter",homeFilter);
        const tempArr:any = [];
        Object.keys(homeFilter.group).map((i)=>{
            tempArr.push({
            groupId: homeFilter.group[i].group.id,
            agentId: homeFilter.group[i].agentId
            })
        })
        Object.values(homeFilter.groupIds).map((i)=>{
            tempArr.push({
            groupId: i,
            agentId: 0
            })
        })
        console.log("SET_FILTER_FROM_HOME",tempArr)

        // return;
        dispatch({
            type : "SET_FILTER_FROM_HOME",
            payload : {
                agentsWithGroup: tempArr,
                priorities: Object.keys(homeFilter.priority).map(i=>Number(i)),
                ticketTypes: Object.values(homeFilter.ticketType),
            }
        })
        navigate("/TicketManagementSys")
        // handleClose();
    };

    const loadGroups = () =>{
        handleTime.cancel();
        handleTime.setup(() => { 
            const filteredGroups = Object.keys(dashboardGroup||{});
            const tempArr:IGroupData[] = [];
            groupsList.map((i:any)=>{
                if(i.isActive){
                    if(filteredGroups.length === 0 || dashboardGroup[i.name]){
                        tempArr.push({
                            id : i.id,
                            name : i.name,
                            value : i.id,
                        });
                    }
                }
            })
            setAllActiveGroups(tempArr);
            
        },500);
    }
    
    const removeVal = (mainKey:"priority"|"group"|"ticketType",key:number|string) =>{
        const tempObj = {...homeFilter};
        delete tempObj[mainKey][key];
        dispatch({
            type:"REMOVE_HOME_DASHBOARD_FILTER",
            payload :  tempObj
        })
    }
    useEffect(()=>{
        loadGroups();
    },[])
    useEffect(()=>{
            const tempObj:any = {};
            Object.keys(homeFilter.group||{}).map((i:string)=>{
                const val = homeFilter.group[i];
                if(!tempObj[val?.group?.name]) tempObj[val.group.name] = {values:[],agentIds:[]};
                tempObj[val.group.name].values.push(val.agentName);
                tempObj[val.group.name].groupId = val.group.id;
                tempObj[val.group.name].agentIds.push(val.agentId);

            })
            console.log("homeFilter",homeFilter,tempObj)
            setGroupData(tempObj)
    },[homeFilter])

    const clearFilters = () =>{
        dispatch({
            type:"CLEAR_HOME_DASHBOARD_FILTER",
            payload : null
        })
    }
    return (
        <Box className="drill-down-menu">
            <Box sx={{ width: 350, p: 2 }}>
                <Box className="row m-0 justify-content-between pb-1">
                    <Typography>Selected Properties</Typography>
                    {!(allActiveGroups.length===0 && Object.keys(groupData).length === 0 &&  Object.keys(homeFilter.priority).length === 0 &&  Object.keys(homeFilter.ticketType).length === 0) &&
                        // <Typography className='text-primary pointer' onClick={drillDown}><small>Get Tickets</small></Typography>
                        <Typography className='text-primary pointer' onClick={clearFilters}><small>Clear</small></Typography>
                    }
                </Box>
                <Divider />

                {/* {(allActiveGroups.length===0 &&Object.keys(groupData).length === 0 && Object.keys(homeFilter.priority).length === 0 && Object.keys(homeFilter.ticketType).length === 0) && 
                    <Box className="pb-3">
                        <ChartNoDataPlaceHolder title={"No Filter Properties Selected!"} />
                    </Box> 
                } */}
                <Box className="home-filter-holder">
                    {Object.keys(groupData).length > 0 ? 
                        <Box className="pt-1">
                            <Typography className='head'>Agent & Group</Typography>
                            {Object.keys(groupData).map((i,index)=>{
                                return (
                                    <Box key={`grp_${index}`}>
                                        <Typography className='sub-head'>{i}</Typography>
                                        <ul className="row m-0 gap5">
                                            {groupData[i]?.values.map && groupData[i]?.values.map((j,index)=>{
                                                return(
                                                    <li key={`${i}_${index}`} className="pointer">
                                                        {j} &nbsp; 
                                                        {/* {`${groupData[i].groupId}_${groupData[i].agentIds[index]}`} &nbsp; */}
                                                        <span onClick={()=>{removeVal("group",`${groupData[i].groupId}_${groupData[i].agentIds[index]}`)}} className='simple-icon icon-close pointer'></span>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </Box>
                                )
                            })}
                            
                        </Box>
                        :
                        <Box className="pt-1">
                            <Typography className='head'>Groups</Typography>
                            <HomeGroupFilter />
                        </Box>
                    }
                        
                    
                    {Object.keys(homeFilter.priority).length > 0 && 
                        <Box className="pt-1">
                            <Typography className='head'>Priority</Typography>
                            <ul className="row m-0 gap5">
                                {Object.keys(homeFilter.priority||{}).map((i)=>{
                                    return(<>
                                        <li className="pointer">
                                            {homeFilter.priority[i]} &nbsp;
                                            <span onClick={()=>{removeVal("priority",i)}} className='simple-icon icon-close pointer'></span>
                                        </li>
                                    </>)
                                })}
                            </ul>
                        </Box>
                    }
                    {Object.keys(homeFilter.ticketType).length > 0 && 
                        <Box className="pt-1">
                            <Typography className='head'>Ticket Types</Typography>
                            <ul className="row m-0 gap5">
                                {Object.keys(homeFilter.ticketType||{}).map((i)=>{
                                    return(<>
                                        <li className="pointer">
                                            {i} &nbsp;
                                            <span onClick={()=>{removeVal("ticketType",i)}} className='simple-icon icon-close pointer'></span>
                                        </li>
                                    </>)
                                })}
                            </ul>
                        </Box>
                    }
                </Box>
                <hr/>
                <Box className="text-center">
                    <Button onClick={handleClose} className='custom-button outlined'>Close</Button> &nbsp;
                    <Button onClick={drillDown} className='custom-button'>Get Tickets</Button>
                </Box>
            </Box>

        </Box>
    )
}



const mapStateToProps = (state: IState) => {
    return ({
      homeFilter: state?.TicketsReducer?.homeDashboardFilter,
      groupsList : state?.groupReducer?.Data?.data,
      dashboardGroup: state?.TicketsReducer?.dashboardGroup,
      groupsRes : state?.GroupInfoViewReducer?.groupVals?.group,
    })
  };
export default connect(mapStateToProps)(DrillDownFilter);
