import { Box, Tooltip, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { IState } from "../../../redux/reducers/rootReducers";
import CustomTheme from "../../common/CustomTheme";
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles } from "@mui/styles";
import { storeFiltersValue } from "../../../redux/actions/TicketsManagement/GetTickets";
import { storeDashboardSummary } from "../../../redux/actions/TicketsManagement/TicketsManagement";
import GroupTicketDropable from "./GroupTicketDropable";
//import { getGroup, url as groupsUrl } from "../../../redux/actions/userManagement/group";
const data = CustomTheme();
const TicketGroups = memo((props:any) => {
    const {groupVals,FiltersVal,groups,groupShrinked}=props;
    // console.log('CustomTheme_groupVals',groupVals)
    const classes = useStyles();
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(storeDashboardSummary())
    },[])
    useEffect(()=>{
        console.log('groupVals',groupVals)
    },[groupVals])
    const returnIsChecked = useCallback((groupId:any,agentId:any) =>{
        const isChecked = FiltersVal.find((obj: any) => {
            return obj.groupId === groupId && obj.agentId === agentId;
        })?.groupId ? true : false;
        return isChecked;
    },[FiltersVal])
    const chooseUser = useCallback((groupId: number, agentId: number | string, action: string) => {
        let searchVal: any = `${groupId}and${agentId}`;
        console.log('chooseUser_searchVal', searchVal);
        let tempArr: any = [...FiltersVal];
        if (action === "add") {
            tempArr.push({ groupId, agentId });
        } else {
            //tempArr = tempArr.filter((i: any) => (i.groupId !== groupId && i.agentId !== agentId));
            tempArr = tempArr.filter((x:any)=>!(x.groupId===groupId && x.agentId === agentId))
        }
        console.log('FiltersVal', tempArr);
        dispatch(storeFiltersValue(tempArr, 'TCKT_GROUP_AGENTS_VALUE'));
    },[FiltersVal])
    const groupListItems = useMemo(() => <Box className={`flx-gap-10 ${classes.row}`}>
        {groups?.map && groups.map((i: any, index: number) => {
            const idGroupChecked = returnIsChecked(i.id, 0);
            const agentsCountObj = groupVals?.[i?.name]?.agentList ||{};
            return (<>
                <Box className="GroupBox" key={index}>
                    <Box className="pos-rel">
                        <GroupTicketDropable
                            id={`groupIdgroup${i.id}`}
                            label-agent-id={0}
                            label-agent-name={i.name}
                            label-groupid={i.id}
                            labelGroupName={i.name}
                            labelGroupid={i.id}
                            labelAgentName={i.name}
                            labelAgentId={0}
                            labelIstitledrop={0}
                        />
                        <Tooltip title={`Unassigned tickets : ${groupVals?.[i?.name]?.agentList?.Unassigned?.count || 0 }`} placement="top">
                        <Typography className="title">
                            <span className="name" onClick={() => { chooseUser(i.id, 0, idGroupChecked ? "" : "add") }}>{i?.name} {idGroupChecked && <CheckIcon />}</span>
                            <span>{groupVals?.[i?.name]?.count ? groupVals?.[i?.name]?.count : 0}</span>
                        </Typography>
                        </Tooltip>
                    </Box>
                    <Box>
                    
                        {i?.userGroupList?.filter((k: any) => k?.agent?.isActive).map((j: any) => {
                            const isChecked = returnIsChecked(i.id, j.userId);
                            return (<Box className="pos-rel">
                                <GroupTicketDropable
                                    id={`groupId${j.userId}${i.id}`}
                                    label-agent-id={j.userId}
                                    label-agent-name={`${j.agent.firstName} ${j.agent.lastName}`}
                                    label-groupid={i.id}
                                    labelGroupName={i.name}
                                    labelGroupid={i.id}
                                    labelAgentName={`${j.agent.firstName} ${j.agent.lastName}`}
                                    labelAgentId={j.userId}
                                    labelIstitledrop={1}
                                />
                                <Typography>
                                    <span className="name" onClick={() => { chooseUser(i.id, j.userId, isChecked ? "" : "add") }}>{j?.agent?.firstName}
                                        {isChecked && <CheckIcon />}
                                    </span> 
                                    <span>{agentsCountObj?.[`${j?.agent?.firstName} ${j?.agent?.lastName}`]?.count || 0}</span>
                                </Typography>
                            </Box>)
                        })}
                    </Box>
                </Box>
            </>)
        })}
    </Box>, [groups?.length,groupVals,FiltersVal,classes])

    const groupHolder = useMemo(() => <Box className={`${classes?.newTcktsBox} p-1 py-5p ${groupShrinked ? "d-none" : ""}`} >
        <Typography
            className={`${classes?.title} ${classes?.themeColorTxt}`}
            color="textSecondary"
        > Groups ({groups?.length})</Typography>
        <Box className="groupsHolder">{groupListItems}</Box>
    </Box>, [groupShrinked,groups?.length,groupVals,FiltersVal,classes])


    return (<>{groupHolder}</>)
})


//const data = CustomTheme();
const mode = data?.theme;
const useStyles = makeStyles(() => ({
    mutedText: {
        color: "#6c757d !important"
    },
    newTcktsBox: {
        // width: "100%",
        border:"1px solid #5555",
        maxWidth: "700px",
        // background: mode.text !== "#ffffff" ? "#C2D8FA" : mode.solids,
        // backgroundImage:
        //     mode.text !== "#ffffff"
        //         ? "linear-gradient(to bottom, #9fd9fc, #a9ddfc, #b3e1fc, #bde4fc, #c7e8fc)"
        //         : "unset",
        borderRadius: 10,
        "& > p": {
            fontSize: 12,
            textAlign: 'center',
            paddingBottom: 5
        },
        "& > div > div": {
            // maxHeight: '150px',
            minHeight: '150px',
            maxWidth: '700px',
            overflow: 'scroll',
            width: 'auto',
            scrollbarWidth: 'none',
            "&::-webkit-scrollbar": {
                width: "0px",
            },
        }
    },
    title: {
        flexGrow: 1,
    },
    themeColorTxt: {
        color: mode.text,
    },
    row: {
        display: "flex",
    }
}))

//
//export default TicketGroups;
const mapStateToProps = (state:IState) => {
    return ({
        groupVals : state?.GroupInfoViewReducer?.groupVals?.group,
        FiltersVal : state?.filtersTicketManagementReducer?.agentsWithGroup,
        groups : state?.TicketsReducer?.allResponses?.groups,
        groupShrinked : state?.LayoutTicketManagementReducer?.groupShrinked,
    })
  };
  
  export default connect(mapStateToProps)(TicketGroups);