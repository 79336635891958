import { Box, Button, Grid, Typography } from '@mui/material';
import NoAccessImg from '../../assest/NoAccess.png';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

const AccessDenied = () =>{
    const navigate = useNavigate();
    const backToHome = useCallback(()=>{
        navigate('/');
    },[])
    return(<Box className="noAccessPage">
        <Grid container spacing={0} alignItems={'center'}>
            <Grid item xs={12} md={5}>
                <img src={NoAccessImg} alt="NoAccessImg" width={"100%"} />
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant='h4'>Oops...</Typography>
                <Typography variant='h4'>Access denied!</Typography>
                <br/>
                <Typography variant='body1'>You are not authorized to access this page.</Typography>
                <Typography variant='body1' sx={{width:"65%"}}>
                    We're sorry, but you don't have required role permissions to access this page. Please contact the administrator.
                </Typography>
                <Button variant='contained' onClick={backToHome}>
                    Back to Home
                </Button>
            </Grid>
        </Grid>
    </Box>)
}
export default AccessDenied;