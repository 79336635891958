import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [20,80],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: [20,30],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

const footer = (tooltipItems: any) => {
  let sum = 0;

  tooltipItems.forEach(function(tooltipItem: any) {
    sum += tooltipItem.parsed.y;
  });
  return 'Total: ' + sum;
};
const VerticalChart = ({
  id='myReportBar',
  label,
  dataSets=[],
  callback,
  isDarkMode,
}: {
  label: any;
  dataSets: any;
  callback?: any;
  id?: any
  isDarkMode?: boolean
})=>{
  const data = {
    labels: label,
    datasets: dataSets,
  };
  const onchange = () =>{
    console.log(callback)
    if (callback) callback()
  }
  const options = {
    maintainAspectRatio: false,	// Don't maintain w/h ratio

    indexAxis: 'x' as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    //   responsive: true,
    plugins: {
      legend: {
        display: true,
        labels: {
          boxWidth: 10,      // Custom size for the legend box width (color box)
          boxHeight: 10,     // Custom size for the legend box height
          color: !isDarkMode ? "black" : "white",
          font: {
            size: 10,
          },
          padding: 5,
           
        },
        
      },
      tooltip: {
        usePointStyle: true,
        callbacks: {
          footer: footer,
        },
        filter: function (tooltipItem: any) {
          var value = tooltipItem.dataset.data[tooltipItem.dataIndex];
          return true;
        }
      },
      datalabels: {
        display: false,
      },
      title: {
        display: false,
        text: 'Chart.js B',
      },
    },
    scales: {
      x: {
        ticks: {
          color: !isDarkMode ? "black" : "white",
          font: {
            size: 10, 
          },
        },
      },
      y: {
        ticks: {
          color: !isDarkMode ? "black" : "white",
          font: {
            size: 10, 
          },
        },
      },
    },
  };
  React.useEffect(()=>{
    setTimeout(()=>{
      onchange()
    },100)
  },[label])
    return (
        <Bar
          height={400}
          data={data}
          options={options}
        />
    );
  }
  export default VerticalChart;