import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../../../redux/reducers/rootReducers";
import { Box } from "@mui/material";
import React, { memo, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import CustomTheme from "../../../../common/CustomTheme";
import PriorityTitle from "./widgets/PriorityTitle";
import PriorityColumn from "./widgets/PriorityColumn";
// const PriorityTitle = lazy(() => import("./widgets/PriorityTitle"));
// const PriorityColumn = lazy(() => import("./widgets/PriorityColumn"));
const data = CustomTheme();
const mode = data.theme;


export const TicketPriorityView = memo(() => {
    const classes = useStyles();
    const AllPriorities:any = useSelector((state: IState) => state?.TicketsReducer?.allResponses?.priorities);
    const [isLoaded,setIsLoaded] = useState(false);
    useEffect(()=>{
        setIsLoaded(true) 
    },[])
    // if(!AllPriorities || AllPriorities?.length === 0){
    //   return(<><PrioritySkeletonLoader /></>)
    // }
    const priorityList = useMemo(()=><>
        {isLoaded ? 
        <Box className={`${classes.whiteBox} p-5p`}>
            {AllPriorities?.length > 0 &&
                <Box className={`${classes.row} pt-1 px-2 overflow-scroll-x`}>
                    {AllPriorities.map && AllPriorities.map((i:any,index:number)=>{
                        return(
                        <React.Fragment key={index}>
                            <PriorityItem
                                name = {i?.name}
                                color = {i?.color}
                                id  = {i?.id }
                                key = {index}
                            />
                        </React.Fragment>
                    )})
                    }
                </Box>
            }
        </Box>
        : <>LOADING...</>}
    </>,[AllPriorities?.length,isLoaded,classes])
    return (<>
        {priorityList}
    </>)
})
const PriorityItem = memo(({name,color,id,key}:{name:string,color:string,id:number,key:number}) =>{
    const shrinkVal: any = useSelector((state: IState) => state?.LayoutTicketManagementReducer?.columnShrinked);
    const content = useMemo(()=><React.Fragment key={key}>
        <PriorityTitle title={name} color={color} />
        <PriorityColumn columnId={id}  />
    </React.Fragment>,[])
    return(<>
            <Box
                key={key}
                className={`${shrinkVal ? "col min-150 shrinked-cardCol" : "col-4"
                    } trans-5s px-10 position-relative`}
            >
                {content}
            </Box>
    </>)
})


const useStyles = makeStyles(() => ({
    whiteBox: {
        padding: "20px",
        background: mode.whiteSolid,
        border: "1px solid #ddd",
        "&.emailTemplateMailBox": {
            "& .cmn-inputBox": {
                margin: 0,
                "& .sm-pd": {
                    padding: 0,
                    "& > div": {
                        background: 'none',
                        "& fieldset": {
                            border: 'none',
                            borderBottom: '1px solid #f1f1f1'
                        }
                    }
                }
            }
        }
    },
    row: {
        display: "flex",
    }
})
)

export default TicketPriorityView;