import constructionimg from "./underconstruction.png"
import HomeIcon from '@mui/icons-material/Home';
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
const UnderConstruction =()=>{
    const navigate =useNavigate()
    return(
        <Grid container spacing={12} className="underconstruction">
            <Grid item xs={12} md={12} className="constructionimg">
                <img src={constructionimg} alt="underconstruction" />
            </Grid>

            <Grid item xs={6} md={8} className="pt-0" style={{fontSize:"20px"}}>
                <em><b>Our website is under construction, but we are ready to go! Special surprise for our subscribers only </b></em>
            </Grid>

            <Grid item xs={12} md={12} className="pt-0">
                <Button onClick={()=>{navigate('/')}} variant="outlined" startIcon={<HomeIcon />}>Home</Button>
            </Grid>
        </Grid>

    )
}
export default UnderConstruction;