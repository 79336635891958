import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";
import CommonStyles from "../common/CommonStyles";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../redux/reducers/rootReducers";
import {
  url as OrganizationUrl,
  getAllContactsInfo,
} from "../../services/cmdb/Organization";
import {
  formInfoData,
  getAllTickets,
  multiApiCalls,
} from "../../redux/actions/TicketsManagement/TicketsManagement";
import { url as AssetUrl } from "../../redux/actions/AssetsManagement/Assets";
import { storeFiltersValue } from "../../redux/actions/TicketsManagement/GetTickets";
import { ConvertZ, handleTime } from "../helpers/helperFunctions";
import timeSpanToReadbleFormat from "../../util/timeSpanToReadbleFormat";
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton   />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
const colWidth: any = {
  assetInfo: 400,
  AssetEffort: 200,
  subject: 200,
  description: 300,
  nextActionDescription: 300,
  AssetDownTime: 200,
  createdDateTime: 200,
};

export const printColumns = [
  "id~Ticket ID",
  "title~Subject",
  "description~Description",
  "ticketType~Ticket Type",
  "contactId~Contact Known As",
  "contactEmail~Contact's Email",
  "contactJob~Contact's Job Title",
  "Org~Third party Organisation",
  "contactOrg~Contact Organisation",
  "contactDiv~Division",
  "contactDep~Department",
  "contactLoc~Location",
  "createdDateTime~Created Date",
  "dueDateTime~Due Date",
  "effort~Total effort",
  "firstResponseDueDateTime~First Action Date",
  "groupId~Group",
  "nextActionDescription~First Action Description",
  "note~Note",
  "ownerId~Agent",
  "priority~Priority",
  "slaRemaining~SLA Remaining",
  "source~Source",
  "status~Status",
  "updatedDateTime~Updated Date",
  "assetInfo~Assets name",
  "assetTag~Assets Tag",
  "assetType~Assets Type",
  "AssetDownTime~Downtime",
]; //AssetDownTime,AssetEffort
export default function ReportExportCSV(props: any) {
  const { allTickets, AllInfo, AllData } = props;
  const dispatch = useDispatch();
  const classes = CommonStyles();
  const [rows, setRows] = useState([] as any);
  const [columns, setColumns] = useState([] as any);
  const [ContactsInfo, setContactsInfo] = useState({} as any);
  const [AssetInfo, setAssetInfo] = useState({} as any);
  const currAgent = useSelector(
    (state: IState) => state?.agentReducer?.loggedInAgent
  );

  const handleColumnVisibilityChange = (model: any) => {
    console.log('Selected Columns:', model);
    props.setColumnVisibilityModel(model);
  };

  // const AllInfo = useSelector((state: IState) => state?.TicketsReducer?.alldataStructure);
  //const allTickets = useSelector((state: IState) => state?.TicketsReducer?.allTickets);
  const FiltersVal: any = useSelector(
    (state: IState) => state?.filtersTicketManagementReducer
  );
  //const allTickets = useSelector((state: IState) => state?.AllTicketsReducer?.otherTickets);
  useEffect(() => {
    console.log("ContactsInfo1212", ContactsInfo.locations);
  }, [ContactsInfo]);
  //const printColumns = ['id~Ticket ID', "body~Body", "title~Title", "subject~Subject", "ticketType~Ticket Type", "contactId~Contact", "contactEmail~Contact's Email", "contactJob~Contact's Job Title", "contactOrg~Organisation", "contactDiv~Division", "contactLoc~Location", "createdDateTime~Created Date", "description~Description", "dueDateTime~Due Date", "effort~Effort", "firstResponseDueDateTime~Next Action Date", "groupId~Group", "nextActionDescription~Next Action Description", "note~Note", "ownerId~Agent", "priority~Priority", "slaRemaining~SLA Remaining", "secondaryContactId~Secondary contact", "source~Source", "status~Status","assetInfo~Assets"];//category~Category
  useEffect(() => {
    // dispatch(multiApiCalls([AssetUrl], ["AssetsList"], multiApiCB));
    const {
      searchVal,
      contacts,
      agents,
      agentsWithGroup,
      statuses,
      priorities,
      ticketTypes,
      sortType,
      rangeVal,
    } = FiltersVal;
    if (
      searchVal === "" &&
      contacts.length === 0 &&
      agents.length === 0 &&
      agentsWithGroup.length === 0 &&
      statuses.length === 0 &&
      priorities.length === 0 &&
      ticketTypes.length === 0 &&
      sortType === "unset" &&
      rangeVal === null
    ) {
      console.log("storeFiltersValue123456", currAgent?.id);
      dispatch(storeFiltersValue([currAgent?.id], "TCKT_AGENTS_VALUE"));
    }
  }, []);
  // useEffect(()=>{
  //   console.log('do_api_call',AllInfo?.ticketStatus)
  //   if(!AllInfo?.ticketStatus) return;
  //   handleTime.cancel()
  //   handleTime.setup(()=>{dispatch(getAllTickets(FiltersVal,AllInfo))});
  // },[FiltersVal])
  console.log("AllInfo", allTickets);
  const multiApiCB = (resStatus: string, res: any) => {
    console.log("multiApiCB", res);
    if (resStatus === "0") {
      let assetList = res?.AssetsList ? res.AssetsList : [];
      console.log("res?.AssetsList", res?.AssetsList);
      const formData = formInfoData(assetList, [
        { name: "assets", vals: ["name"] },
        { name: "assetsById", vals: ["", "id"] },
      ]);
      setAssetInfo(formData);
      console.log("multiApiCB", formData);
      dispatch(getAllTickets(FiltersVal, AllInfo));
    }
  };
  useEffect(() => {
    if (
      Object.keys(allTickets).length === 0
    )
      return;
    console.log("AllInfoallTickets", AllInfo, allTickets);
    if (allTickets?.length === 0) return;
    console.log("AllContact");
    handleTime.cancel();
    handleTime.setup(() => {
      dispatch(getAllContactsInfo(contactsInfoDataCB));
    });
  }, [AllInfo, allTickets, AssetInfo]);
  
  const contactsInfoDataCB = (resStatus: string, res: any) => {
    console.log("contactsInfoDataCB", resStatus, res);
    if (resStatus === "0") {
      setContactsInfo(res);
      paintData(res);
    }
  };
 
  const paintData = (contactInfo: any = {}) => {
    try {
      // const allKeys: any = Object.keys(allTickets.AllTickets[0].ticketLogs);
      //const allKeys: any = Object.keys(allTickets.AllTickets[0].ticketLogs);
      //allKeys.push('ticketCreatedDate')
      const filteredCols: any = [];
      printColumns.map((i: string) => {
        const field: any = i.split("~");
        const val = {
          field: field[0],
          headerName: field[1],
          width: colWidth?.[field[0]] ? colWidth[field[0]] : 140,
        };
        filteredCols.push(val);
      });

      setColumns(filteredCols);

      const modifiedArr: any = [];
      allTickets?.map((j: any) => {
        if (!j?.ticketLogs) return;
        const logs = j?.ticketLogs;
        const tempObj: any = {};
        printColumns.map((i: any) => {
          const splitVal: any = i.split("~");
          if (!tempObj[splitVal[0]]) {
            tempObj[splitVal[0]] = "";
          }
          if (splitVal[0] === "ticketType") {
            tempObj[splitVal[0]] = logs?.ticketType?.name || "-";
          } else if (splitVal[0] === "groupId") {
            tempObj[splitVal[0]] = logs?.group?.name || "-";
          } else if (splitVal[0] === "ownerId") {
            tempObj[splitVal[0]] = logs?.agent
              ? `${logs?.agent?.firstName} ${logs?.agent?.lastName}`
              : "-";
          } else if (splitVal[0] === "priority") {
            tempObj[splitVal[0]] = logs?.priority?.name || "-";
          } else if (splitVal[0] === "contactEmail") {
            tempObj[splitVal[0]] = logs?.contacts?.email || "-";
          }else if (splitVal[0] === "contactEmail") {
            tempObj[splitVal[0]] = logs?.contacts?.email || "-";
          } else if (splitVal[0] === "contactJob") {
            tempObj[splitVal[0]] = logs?.contacts?.jobTitle || "-";
          } 
          else if (splitVal[0] === "contactId") {
            tempObj[splitVal[0]] = logs?.contacts?.name || "-";
          } else if (splitVal[0] === "contactLoc") {
            tempObj[splitVal[0]] = logs?.contacts?.officeLocation || "-";
          }  else if (splitVal[0] === "Org") {
            tempObj[splitVal[0]] = logs?.contactOrganisation?.name || "-";
          }  else if (splitVal[0] === "contactOrg") {
            tempObj[splitVal[0]] = logs?.contacts?.contactOrganisation?.name || "-";
          } else if (splitVal[0] === "contactDep") {
            tempObj[splitVal[0]] = logs?.contacts?.department?.name || "-";
          }else if (splitVal[0] === "contactDiv") {
            tempObj[splitVal[0]] = logs?.contacts?.division?.name || "-";
          }else if (splitVal[0] === "source") {
            tempObj[splitVal[0]] = j?.sourceCode || "-";
          } else if (splitVal[0] === "status") {
            tempObj[splitVal[0]] = logs?.ticketStatus?.displayName || "-";
          }
          else if (splitVal[0] === "updatedDateTime") {
            tempObj[splitVal[0]] = ConvertZ(j?.createdDateTime)|| "-";
          }  else if (splitVal[0] === "effort") {
            tempObj[splitVal[0]] = j?.totalEffort || "-";
          } else if (splitVal[0] === "AssetDownTime") {
            tempObj[splitVal[0]] = j?.totalDowntime || "-";
          } else if (splitVal[0] === "createdDateTime") {
            tempObj[splitVal[0]] = ConvertZ(logs?.openDateTime) || "-";
          } else {
            tempObj[splitVal[0]] = logs[splitVal[0]];
          }
          tempObj["id"] = j.id;
          tempObj["body"] = logs?.body || "-";
          tempObj["description"] = logs?.description || "-";
          tempObj["title"] = logs?.title || "-";
          tempObj["firstResponseDueDateTime"] = ConvertZ(logs?.firstResponseDueDateTime) || "-";
          tempObj["dueDateTime"] = ConvertZ(logs?.dueDateTime) || "-";
          tempObj["nextActionDescription"] = logs?.nextActionDescription || "-";
          tempObj["note"] = logs?.note || "-";
          tempObj["slaRemaining"]  = logs?.ticketStatus?.name === "OnHold" ? "onHold" : timeSpanToReadbleFormat(logs?.slaRemaining) || "-";

        });
        if (logs?.ticketLogAssetList) {
          const assetIds = logs?.ticketLogAssetList?.map((i: any) => {
            return i?.asset?.name;
          });
          const assetTags = logs?.ticketLogAssetList?.map((i: any) => {
            return i?.asset?.assetTag;
          });const assettypes = logs?.ticketLogAssetList?.map((i: any) => {
            return i?.asset?.assetType?.displayName;
          });
            tempObj['assetInfo'] =  assetIds?.length > 0 ? assetIds?.join(',') : "-";//assetIds.length;
            tempObj['assetType'] =  assettypes?.length > 0 ? assettypes?.join(',') : "-";//assetIds.length;
            tempObj['assetTag'] =  assetTags?.length > 0 ? assetTags?.join(',') : "-";//assetIds.length;

            // tempObj['AssetEffort'] = getAssetInfo(assetIds).effort.join(' | ');//assetIds.length;
            // tempObj['AssetDownTime'] = getAssetInfo(assetIds).downtime.join(' | ');//assetIds.length;
            console.log("logs.ticketLogAssetList", AssetInfo, assetIds);
        }
        else{
          tempObj['assetInfo'] =  "-";//assetIds.length;
        }
        modifiedArr.push(tempObj);
      });
      setRows(modifiedArr);
    } catch (error) {
      console.log("paintData", error);
    }
  };
  return (
    <Box className={`${classes.whiteBox} p-0`}>
      <div style={{ height: "100vh", width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={allTickets?.length > 0 ? rows : []}
         
          slots={{
            toolbar: CustomToolbar,
          }}
          onColumnVisibilityModelChange={handleColumnVisibilityChange}
          columnVisibilityModel={props?.columnVisibilityModel}
        />
      </div>
    </Box>
  );
}