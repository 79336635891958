import axios from "axios";
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";
const userTicketUrl = `${configs.BASE_URL_Ticket}/user/Ticket/filters?page=0&count=5`
export const getContactTickets = (contactId:any="",filtersVal?:any,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.post(`${userTicketUrl}`,{});
      dispatch({
        type: "CONTACT_SSP_TICKETS",
        payload:response.data,
      })
      if(callback) callback("0",response.data);
    } catch (error) {
      return console.log("err",error);
    }
  };
};
export const storeFiltersValue = (value:any,type:string) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: type,
        payload:value,
      })
    } catch (error) {
      return console.log("err",error);
    }
  };
};

export default getContactTickets;