import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../common2/Breadcrumbs";
import WebForms from "../../webForms/WebForms";

function AddSurveyTemplate() {
    const param = useParams();
    const submitHandler = () => {}
    return (
        <>
            <Box className="p-1">
                <Breadcrumbs
                    data={[
                        {
                            title: 'System Management ',
                            path: '/adminPanel'
                        },
                        {
                            title: 'Survey Template',
                            path: '/SurveyTemplates'
                        },
                        {
                            title: `${param?.id ? "Edit" : "Add"} Survey Template`,
                            path: `${param.id ? "/AddSurveyTemplate/" + param.id : "/AddSurveyTemplate"}`
                        }
                    ]
                    }
                />
                <WebForms type = "Survey" />
            </Box>
        </>
    )
}

export default AddSurveyTemplate