const initialState = {
  count: 0,
  allNotif: [],
};

const NotifReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case "STORE_COUNT":
      return {
        ...state,
        count: action.payload,
      };
    case "STORE_ALL_NOTIF":
      return {
        ...state,
        allNotif: action.payload,
      };
    default:
      return state;
  }
};

export default NotifReducer;
