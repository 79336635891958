import React, {  createRef, useEffect } from 'react'
import { TextBoxLatest } from '../../common/TextBoxLatest/TextBoxLatest'
import { FormHelperText } from '@mui/material';
import { iWebFormField } from '../schema';

export const WebFormTextArea = (props: {fieldConfig:iWebFormField,value?:any,editable?:any}) => {
    const { fieldPlaceholder, fieldDescription, fieldLabel, isMandatory, id} = props?.fieldConfig;
    const ref: any = createRef();
    useEffect(() => {
        if (props?.value && ref?.current) {
            ref.current.value = props?.value;
        }
        console.log("refref", props?.value);
    }, [props.value]);
    return (
        <div>
            <TextBoxLatest
                multiline={true}
                placeholder={fieldPlaceholder}
                title={fieldLabel}
                ref={ref}
                isMandotary={isMandatory}
                type= {"text"}
                name={`${id}`}
                InputProps={{
                    "readOnly": !props?.editable,
                }}
            />
            {(fieldDescription && fieldDescription.trim() !== "") && <FormHelperText className="helper-text-webform">{fieldDescription}</FormHelperText>}
        </div>
    )
}