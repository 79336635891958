import { useLocation } from "react-router-dom";

const isValid= (value:  string|null)=>{
    const result = value ? value : false
return result 
}

const useGetQueryParams =(id: string)=>{
    const location = useLocation();
    const search = location.search;
    const params = new URLSearchParams(search);
    const result = isValid(params.get(id))
    return result;
}
export default useGetQueryParams
