import React, { useState } from "react";
// import { SetThemeAction } from "../../redux/actions/Login/Login";
// import { useDispatch, useSelector } from "react-redux";
function CustomTheme(){
    const themeColors = {
        light:{
            sidebar:'#c5d0f7',
            body:'#F0F3FE',
            topBar:'#F0F3FE',
            solids:'#F5F7FF',
            text:'#000000',
            link : '#007bff',
            cardBody:'#ffffff',
            brightness: 'brightness(1)',
            brightnessInvert: 'brightness(0)',
            cardHeader:'#ebeffc',
            whiteSolid:'#FFFFFF',
            blckWhite:'#000000',
            gryToWhite:"##d9d9d9",
            solidToDark:"#ffffff"
        },
        dark:{
            sidebar:'#120f22',
            body:'#1e1c2a',
            topBar:'#1e1c2a',
            solids:'#3f4044',
            text:'#ffffff',
            link : '#ffffff',
            cardBody:'#55555563',
            cardHeader:'#555555',
            brightness: 'brightness(0)',
            brightnessInvert: 'brightness(1)',
            whiteSolid:'#3f4044',
            blckWhite:'#ffffff',
            gryToWhite:"#3f4044",
            solidToDark:"#120f22"
        }
    };
    const classes = {
        mode:'light',
        theme:themeColors.light,
        all:themeColors
    }
    return classes;
}
export default CustomTheme;