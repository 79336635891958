import { IAddAction } from "../actions/dashboard"


export interface IAddState {
    Add: number
}
const initState: IAddState = {
    Add: 0
}

const Add= (state: IAddState = initState, action: IAddAction) => {
    switch (action.type) {
        case "ADD":
            return {
                ...state,
                Add: action.payload
            }
        default:
            return state
    }
}

export default Add;