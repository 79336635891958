import * as React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CustomTheme from "./../common/CustomTheme";
import CommonStyles from "./CommonStyles";

//themeBgColor
const data = CustomTheme();
const mode = data.theme;
export interface IAvatarText {
  isExtLink: boolean;
  avatarText: string;
  avatarURL: string;
  avatarAlt: string;
  redirectLink: string;
  isActive? : any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  typography: {
    marginLeft: 10,
    fontSize: 12,
    color: mode.text,
  },
}));

const AvatarWithText: React.FC<IAvatarText> = (props: IAvatarText) => {
  const classes = useStyles();
  const cStyles = CommonStyles();
  const { avatarText, avatarURL, avatarAlt, redirectLink, isExtLink,isActive } = props;
  return (
    <>
      {isExtLink ? (
        <a
          href={redirectLink}
          target="blank"
          style={{ textDecoration: "none" }}
        >
          <Box className={classes.root}>
            <Avatar
              sx={{ width: 40, height: 40}}
              alt={avatarAlt}
              src={avatarURL}
            />
            <Typography
              className={`${classes.typography} ${cStyles.themeColorTxt}`}
              variant="h6"
              component="h6"
            >
              {avatarText}
            </Typography>
          </Box>
        </a>
      ) : (
        redirectLink ? (
          <Link to={redirectLink} style={{ textDecoration: "none" }}>
          <Box className={classes.root}>
            <Avatar
              sx={{ width: 40, height: 40 }}
              alt={avatarAlt}
              src={avatarURL}
            />
            <Typography
              className={`${classes.typography} ${cStyles.themeColorTxt}`}
              variant="h6"
              component="h6"
            >
              {avatarText}
            </Typography>
          </Box>
        </Link>
        )
      :
      <div  style={{ textDecoration: "none" }}>
      <Box className={classes.root}>
        <Avatar
          sx={{ width: 40, height: 40 }}
          alt={avatarAlt}
          src={avatarURL}
        />
        <Typography
          className={`${classes.typography} ${cStyles.themeColorTxt}`}
          variant="h6"
          component="h6"
        >
          {avatarText}
        </Typography>
      </Box>
    </div>
        
      )}
    </>
  );
};

export default AvatarWithText;
