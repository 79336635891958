import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'

function ArticlesListSSP() {
    const [articleList,setArticleList] = useState([]);
    const getCallback = (resStatus:string,res:any)=>{
        if(resStatus==="0"){
            setArticleList(res);
        }
    }
    return (
            <Box className="p-2">
                <Grid container className='pt-1' spacing={1}>
                    <Grid item xs={12} md={3}>
                        <Box className="p-2 bg-white">
                            {articleList.map((i:any,index:number)=>{
                                return(
                                    <>
                                        {i?.name}
                                    </>
                                )
                            })}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
    )
}

export default ArticlesListSSP
