import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';
import ColumnChart from './ColumnChart';
import { handleTime } from '../../../components/helpers/helperFunctions';
export interface IGroupData {
    id : number;
    name : string;
    value : number;
}
interface IValue{
    currentGroup : IGroupData|null,
    activeIndex : number,
    labels : string[],
    values : number[],
}
const GroupDataChart = ({dispatch,groupsRes={},groupsList=[],dashboardFilterGroup={}}:any) => {
    const [allActiveGroups, setAllActiveGroups] = useState<IGroupData[]>([])
    const [values,setValues] = useState<IValue>({
        currentGroup : null,
        activeIndex : 0,
        labels : [],
        values : [],
    })
   
    const loadGroups = () =>{
        handleTime.cancel();
        handleTime.setup(() => { 
            const filteredGroups = Object.keys(dashboardFilterGroup||{});
            const tempArr:IGroupData[] = [];
            groupsList.map((i:any)=>{
                if(i.isActive){
                    if(filteredGroups.length === 0 || dashboardFilterGroup[i.name]){
                        tempArr.push({
                            id : i.id,
                            name : i.name,
                            value : i.id,
                        });
                    }
                }
            })
            setAllActiveGroups(tempArr);
            
        },500);
    }
    useEffect(()=>{
        if(allActiveGroups.length>0){
            updateGroup(allActiveGroups[0],0)
        }else{
            setValues({ currentGroup : null, activeIndex : 0, labels : [], values : []})
        }
    },[allActiveGroups.length])
    useEffect(()=>{
        loadGroups()
        console.log("updateGroupupdateGroup",groupsRes,dashboardFilterGroup,groupsList)
    },[groupsRes,dashboardFilterGroup,groupsList.length])


    const updateGroup = (group:IGroupData,index:number) =>{
            const agentList = groupsRes[group.name]?.agentList || {};
            const labels = Object.keys(agentList).map((i)=>{ return `${i}`});
            const tempObj:IValue = {
                currentGroup : group,
                activeIndex : index,
                labels : labels,
                values : Object.values(agentList).map((i:any)=>Number(i.count)),
            }
            console.log("updateGroup",tempObj)
            setValues(tempObj)
    }


    const handleBarClick = (index:number,label:string,value?:number) => {
        if(!values.currentGroup) return;
        const agentId = groupsRes[values.currentGroup.name].agentList[label].id;
        console.log("STORE_HOME_DASHBOARD_AGENT_GROUP",label,agentId)
        dispatch({
            type:"STORE_HOME_DASHBOARD_AGENT_GROUP",
            payload :  { [`${values.currentGroup.id}_${agentId}`] : {
                group : values?.currentGroup,
                agentName : label,
                agentId
            }}
        })
    };
    return (
        <div>   
            <ColumnChart 
                callback={handleBarClick} 
                //height={275} 
                title = "Groups" 
                labels={values?.labels||[]} 
                values={values?.values||[]}
                height={250}
            />

            <Box className="chart-group-tiles justify-content-center row m-0 gap5">
                        {allActiveGroups.filter((_,index)=>index<=3).map((i,index:number)=>{
                            return( <> 
                                <div key={index}>
                                    <span onClick={()=>{updateGroup(i,index)}} className={`${index === values?.activeIndex ? "active" : ""}`}>
                                        {i.name}  ({groupsRes?.[i.name]?.count||0})
                                    </span>
                                </div>
                            </>)
                        })}
                        {allActiveGroups.length > 4 &&  
                            <div className='otherGroups'>
                                <span className={`${values?.activeIndex > 3 ? "active" : ""}`}>+ {allActiveGroups.length-4}</span>
                                <div className='otherGroups-list white-bg'>
                                    {allActiveGroups.filter((_,index)=>index>3).map((i,index:number)=>{
                                        return(
                                            <div key={index}>
                                                <span onClick={()=>{updateGroup(i,index+4)}} className={`${index+4 === values?.activeIndex ? "active" : ""}`}>
                                                    {i.name}  ({groupsRes?.[i.name]?.count||0})
                                                </span>
                                            </div>)
                                    })}
                                </div>
                            </div>
                        }
            </Box>
        </div>
    )
}
const mapStateToProps = (state:IState) => {
    return ({
        groupsRes : state?.GroupInfoViewReducer?.groupVals?.group,
        dashboardFilterGroup: state?.TicketsReducer?.dashboardGroup,
        groupsList: state?.groupReducer?.Data?.data,
    })
};
export default connect(mapStateToProps)(GroupDataChart);