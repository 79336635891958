import { useSelector } from "react-redux";
import { IState } from "../../../../../../redux/reducers/rootReducers";
import { Box } from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import CommonStyles from "../../../../../common/CommonStyles";
import TicketCard from "../../../Ticketcard";
import TicketCardNew from "../../../../../TicketManagementSystem/Modules/TicketCard";

const StatusTicketsList = memo((props: any) => {
    const { columnId } = props;
    const AllTickets = useSelector((state: IState) => state?.AllTicketsReducer?.otherTickets);
    // const dummyFunction = useCallback(() => { }, []);
    const [tickets, setTickets] = useState([]);
    useEffect(() => {
        if (AllTickets?.filter) {
            setTickets(AllTickets?.filter((i: any) => i?.ticketLogs?.statusId === columnId))
        }
    }, [AllTickets])
    const ticektsList = useMemo(() => <>
        {tickets && tickets.map((item: any) => {
            return (
                <>
                    <Box ticket-id={item.id}>
                        <TicketCardNew
                            ticketData={item}
                        />
                        {/* <TicketCard
                                ticketData={item}
                                onEditClick={dummyFunction}
                                onQuickUpdate={dummyFunction}
                                onMergeClick={dummyFunction}
                                allTicktes={[]}
                                callback={dummyFunction}
                            /> */}
                        {/* {item.id} {item?.ticketLogs?.title} */}
                    </Box>
                </>
            )
        })}</>, [tickets?.length])
    return (
        <>
            {ticektsList}
        </>
    )
})
export default StatusTicketsList;