import { TabContext, TabList } from "@mui/lab";
import { Box, Grid, Tab } from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { IRolesFormType } from "../AddRole-bkp";
import SystemAdminAccess from "./SystemAdminAccess";
import UserAdminAccess from "./UserAdminAccess";
import SSPAdminAccess from "./SSPPermissions";
const PermissionsBox = memo((props:{initialVals:IRolesFormType;}) => {
    const { initialVals} = props;
    const {isSuperAdmin, isSysAdmin, isAgent, isSSPUser} = initialVals;
    const [TabValue, setTabValue] = useState('1');
    const tabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };
    useEffect(() => {
        if(isSSPUser){
            setTabValue('3')
        }else if(isAgent && !isSysAdmin){
            setTabValue('2')
        } else {
            setTabValue('1')
        }
    }, [isSuperAdmin, isSysAdmin, isAgent, isSSPUser])
    return (<>
        <Grid item xs={12} md={8}>
          <Box className={`${(isSysAdmin || isAgent || isSSPUser) ? "" : "d-none"}`}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={TabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={tabChange} aria-label="lab API tabs example">
                      {isSysAdmin && <Tab label="System Admin Permissions" value="1" />}
                      {isAgent &&  <Tab label="Agent Permissions" value="2" />}
                      {isSSPUser && <Tab label="SSP Permissions" value="3" />}
                  </TabList>
                </Box>
              </TabContext>
            </Box>
            <Box className={`${!isSuperAdmin && isSysAdmin && TabValue === '1' ? '' : 'd-none'}`}>
                <SystemAdminAccess />
            </Box>
            <Box className={`${!isSuperAdmin && isAgent && TabValue === '2' ? '' : 'd-none'}`}>
                {!isSuperAdmin && isAgent && TabValue === '2' && <UserAdminAccess />}
            </Box>
            <Box className={`${!isSuperAdmin && isSSPUser && TabValue === '3' ? '' : 'd-none'}`}>
                {!isSuperAdmin && isSSPUser && TabValue === '3' && <SSPAdminAccess />}
            </Box>
          </Box>
        </Grid>
    </>)
})
export default PermissionsBox;