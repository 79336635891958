import { combineReducers } from "redux";
import { IChangeTheme } from "../actions/Theme/Theme";
import Add, { IAddState } from "./Dashboard";
import dashboardType, { IDashboard } from "./SecondaryNavbar";
import snackbar, { ISnackbarState } from "./Snackbar";
import AddTicketType, { IAddTypeState } from "./SystemManagement/TicketTypes";
import Theme, { ITheme } from "./Theme/Theme";
import loader, { ILoaderState } from "./Loader";
import Dashboard, { IDashboardInterface } from "./Dashboard/dashboard.reducer";
import { IFileUploadType } from "../actions/FileUpload/FileUpload";
import FileUpload from "./FileUpload/FileUpload";
import { IAPIStatus } from "../actions/apiStatus.action";
import priorityReducer from "./SystemManagement/Priority";
import workingTimePlanReducer from "./SystemManagement/WTP";
import slaReducer from "./SystemManagement/SLA";
import groupReducer from "./userManagement/Group";
import ticketReducer from "./SystemManagement/ticket";
import agentReducer from "./userManagement/Agent";
import categoryReducer from "./SystemManagement/Category";
import roleReducer from "./userManagement/Role";
import permissionReducer from "../reducers/SystemManagement/Permission";
import ticketStatusReducer from "../reducers/SystemManagement/TicketStatus";
import systemTypeReducer from "../reducers/SystemTypes/SystemTypes";
import apiStatus, { IAPIStatusProps } from "./apiStatus.reducer";
import LicenseReducer from "../reducers/userManagement/license";
import PricingReducer from "./userManagement/pricing";
import FreeTrialReducer from "./FreeTrial/FreeTrial";
import userGroupReducer from "./userManagement/userGroup";
import LoginReducer from "./Login";
import checkUserNameReducer from "./validation/UserName";
import DirectoryReducer from "./adminInterfaces/ActiveDirectory";
import ContactsReducer from "./ContactManagement/Contact";
import LocationsReducer from "./Location/Location";
import EmailSettingsReducer from "./adminInterfaces/EmailSettings";
import EmailTemplatesReducer from "./adminInterfaces/EmailTemplates";
import AssetsReducer from "./AssetsManagement/Assets";
import DepartmentReducer from "./CMDB/Departments";
import AdditionalFieldReducer from "./AdditionalFields/AdditionalFields";
import AdditionalFieldTypesReducer from "./AdditionalFieldTypes/AdditionalFieldTypes";
import AssetTypesReducer from "./AssetsManagement/AssetTypes";
import TicketsReducer, { AllTicketsReducer, TicketsReducerForCSV, TicketsReducerForReport } from "./TicketsManagement/TicketsManagement";
import ReportsReducer from "./Reports/reports";
import FiltersSSPReducer from "./FiltersSSP/FiltersSSP";
import filtersTicketManagementReducer, { filtersTicketManagementReducerForCSV, filtersTicketManagementReducerForReport, GroupInfoViewReducer, LayoutTicketManagementReducer, mobileFiltersReducer, TicketViewReducer } from "./FiltersTicketManagement/FiltersTicketManagement";
import WhatsAppReducer from "./SystemManagement/WhatsApp";
import WorkflowReducer from "./Workflow/Workflow";
import IntuneReducer from "./SystemManagement/Intune";
import EmailSettingsSspReducer from "./EmailSettingsSsp/EmailSettingsSsp";
import EmailTemplatesSspReducer from "./EmailTempletesSsp/EmailTempletesSsp";
import assetStatusReducer from "./SystemManagement/AssetStatus";
import SSOConfigReducer from "./SystemManagement/SSOConfig";
import WebformReducer from "./WebForms/webForms";
import TeamsConfigReducer from "./SystemManagement/TeamsConfig";
import KnowledgeBaseReducer from "./KnowledgeBase/KnowledgeBase";
import FeedbackReducer from "./Survey/feedback";
import SurveyReducer from "./Survey/survey";
import MailExceptionListReducer from "./adminInterfaces/MailExceptionList";
import WallboardReducer from "./Wallboard/Wallboard";
import NotifReducer from "../../pages/notification/NotifReducer";
import FeatureReducer from "./Features/Features";
import RecordingReducer from "../../components/SceenShare/RecordingReducer";
export interface IState {
  Add: IAddState;
  theme: ITheme;
  dashboardType: IDashboard;
  ticketTypes: IAddTypeState;
  snackbar: ISnackbarState;
  loader: ILoaderState;
  dashboard: IDashboardInterface;
  fileupload: IFileUploadType;
  apiStatus: IAPIStatusProps;
  priorityReducer: any;
  workingTimePlanReducer: any;
  slaReducer: any;
  groupReducer: any;
  ticketReducer: any;
  agentReducer: any;
  categoryReducer: any;
  roleReducer: any;
  permissionReducer: any;
  ticketStatusReducer: any;
  systemTypeReducer: any;
  licenseReducer: any;
  PricingReducer: any;
  FreeTrialReducer: any;
  userGroupReducer: any;
  LoginReducer: any;
  checkUserNameReducer: any;
  DirectoryReducer: any;
  ContactsReducer: any;
  LocationsReducer: any;
  EmailSettingsReducer: any;
  AssetsReducer: any;
  EmailTemplatesReducer: any;
  DepartmentReducer:any;
  AdditionalFieldReducer:any;
  AdditionalFieldTypesReducer:any;
  AssetTypesReducer:any;
  TicketsReducer:any;
  ReportsReducer:any;
  FiltersSSPReducer:any;
  filtersTicketManagementReducer:any;
  filtersTicketManagementReducerForReport: any;
  filtersTicketManagementReducerForCSV: any;
  LayoutTicketManagementReducer:any;
  AllTicketsReducer:any;
  TicketsReducerForReport: any;
  TicketsReducerForCSV: any;
  WhatsAppReducer:any;
  IntuneReducer:any;
  SSOConfigReducer:any;
  FeedbackReducer:any;
  TeamsConfigReducer:any;
  WorkflowReducer:any;
  TicketViewReducer:any
  EmailSettingsSspReducer: any;
  EmailTemplatesSspReducer:any;
  GroupInfoViewReducer:any;
  assetStatusReducer:any;
  WebformReducer:any;
  sendEmailView: any;
  KnowledgeBaseReducer:any;
  SurveyReducer:any;
  MailExceptionListReducer: any,
  WallboardReducer: any,
  mobileFiltersReducer:any,
  FeatureReducer: any,
  NotifReducer: any,
  RecordingReducer: any
}

export default combineReducers({
  Add: Add,
  theme: Theme,
  dashboardType: dashboardType,
  ticketTypes: AddTicketType,
  permissionReducer: permissionReducer,
  snackbar: snackbar,
  loader: loader,
  dashboard: Dashboard,
  fileupload: FileUpload,
  apiStatus: apiStatus,
  priorityReducer: priorityReducer,
  workingTimePlanReducer: workingTimePlanReducer,
  slaReducer: slaReducer,
  groupReducer: groupReducer,
  ticketReducer: ticketReducer,
  agentReducer: agentReducer,
  categoryReducer: categoryReducer,
  roleReducer: roleReducer,
  ticketStatusReducer: ticketStatusReducer,
  systemTypeReducer: systemTypeReducer,
  licenseReducer: LicenseReducer,
  PricingReducer: PricingReducer,
  FreeTrialReducer: FreeTrialReducer,
  userGroupReducer: userGroupReducer,
  LoginReducer: LoginReducer,
  checkUserNameReducer: checkUserNameReducer,
  DirectoryReducer: DirectoryReducer,
  ContactsReducer: ContactsReducer,
  LocationsReducer: LocationsReducer,
  EmailSettingsReducer: EmailSettingsReducer,
  AssetsReducer: AssetsReducer,
  EmailTemplatesReducer: EmailTemplatesReducer,
  MailExceptionListReducer: MailExceptionListReducer,
  DepartmentReducer:DepartmentReducer,
  AdditionalFieldReducer:AdditionalFieldReducer,
  AdditionalFieldTypesReducer:AdditionalFieldTypesReducer,
  AssetTypesReducer:AssetTypesReducer,
  assetStatusReducer: assetStatusReducer,
  TicketsReducer:TicketsReducer,
  ReportsReducer:ReportsReducer,
  FiltersSSPReducer:FiltersSSPReducer,
  WhatsAppReducer:WhatsAppReducer,
  IntuneReducer:IntuneReducer,
  SSOConfigReducer:SSOConfigReducer,
  FeedbackReducer:FeedbackReducer,
  SurveyReducer:SurveyReducer,
  TeamsConfigReducer:TeamsConfigReducer,
  filtersTicketManagementReducer:filtersTicketManagementReducer,
  filtersTicketManagementReducerForReport: filtersTicketManagementReducerForReport,
  filtersTicketManagementReducerForCSV: filtersTicketManagementReducerForCSV,
  LayoutTicketManagementReducer:LayoutTicketManagementReducer,
  AllTicketsReducer:AllTicketsReducer,
  TicketsReducerForReport: TicketsReducerForReport,
  TicketsReducerForCSV: TicketsReducerForCSV,
  WorkflowReducer:WorkflowReducer,
  TicketViewReducer: TicketViewReducer,
  WallboardReducer: WallboardReducer,
  EmailSettingsSspReducer: EmailSettingsSspReducer,
  EmailTemplatesSspReducer: EmailTemplatesSspReducer,
  GroupInfoViewReducer: GroupInfoViewReducer,
  WebformReducer:WebformReducer,
  KnowledgeBaseReducer:KnowledgeBaseReducer,
  mobileFiltersReducer:mobileFiltersReducer,
  NotifReducer: NotifReducer,
  FeatureReducer:FeatureReducer,
  recording: RecordingReducer
});
