import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList} from '@mui/lab';
import { storeFiltersValue } from '../../../redux/actions/TicketsManagement/GetTickets';
interface ITicketQuickFilterProps {
    filtersVal ?:any; 
    dispatch?:any;
    allResponses ?:any;
}
function TicketQuickFilter(props:ITicketQuickFilterProps) {
    const {filtersVal , allResponses, dispatch} = props;
    const [tabValue, setTabValue] = useState('1');
    const TabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };
    useEffect(()=>{
        console.log("filtersVal",filtersVal);
    },[filtersVal])
    const toggleItems = (key:any,id:number,val:boolean) =>{
        dispatch(storeFiltersValue({[key]: val ? id : val},"TOGGLE_MOBILE_FILTER_VALUE"));
    }
    return (
        <Box className="mbl-quick-filter">
            <TabContext value={tabValue}>
                <TabList onChange={TabChange} className="mbl-quick-filter-row"  aria-label="quick-fiter">
                    <Tab label="Status" value="1" />
                    <Tab label="Priority" value="2" />
                </TabList>
            </TabContext>
            {tabValue === "1" && <Box className="item-list">
                {(allResponses?.ticketStatus || []).map((i:any)=>{
                    const isActive = filtersVal?.["ticketStatus-"+i.id] ? true : false;
                    return(<Box onClick={()=>{toggleItems(`ticketStatus-${i?.id}`, i?.id, !isActive)}} className={`item ${isActive && "active"}`}>{i?.label}</Box>)
                })}    
            </Box>}
            {tabValue === "2" && <Box className="item-list">
                {(allResponses?.priorities || []).map((i:any)=>{
                    const isActive = filtersVal?.["priority-"+i.id] ? true : false;
                    return(<Box onClick={()=>{toggleItems(`priority-${i?.id}`, i?.id, !isActive)}} className={`item ${isActive && "active"}`}>{i?.label}</Box>)
                })}    
            </Box>}
        </Box>
    )
}

const mapStateToProps = (state:IState) => {
    return ({
        filtersVal : state?.mobileFiltersReducer?.otherValues,
        allResponses: state?.TicketsReducer?.allResponses,
    })
  };
  
export default connect(mapStateToProps)(TicketQuickFilter);
// export default TicketQuickFilter
