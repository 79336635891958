import React from 'react'
import { IState } from '../../redux/reducers/rootReducers';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CircularProgress from "@mui/material/CircularProgress";

const CSVLoader = ({loader}:any) => {
  const classes = useStyles();
  return (
    <div>
      {loader && 
        <Box className={classes.loaderBox}>
              <CircularProgress />
        </Box>
      }
    </div>
  )
}
const useStyles: any = makeStyles(() => ({
  loaderBox: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "10px",
    padding: "15px",
    "& span": {
      width: "20px !important",
      height: "20px  !important",
    },
  },
}));
const mapStateToProps = (state:IState) => {
    return ({
        loader: state?.AllTicketsReducer?.loader,
    })
  };
  
export default connect(mapStateToProps)(CSVLoader);