const initialState = {
    DirectoryItem: [],
    getDirectoryList: [],
    isSaved:''
}
const DirectoryReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case 'ADD_DIRECTORY':
            return {
                ...state,
                DirectoryItem: action.payload,
            };
        case 'GET_DIRECTORY':
            return {
                ...state,
                getDirectoryList: action.payload,
            };
        case 'IS_SUBMIT':
            return {
                ...state,
                isSaved: action.payload,
            };
        default:
            return state;
    }
}
  
export default DirectoryReducer;
  