import React, { useEffect } from 'react'
import { IState } from '../../../redux/reducers/rootReducers';
import { connect } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { IstoreTicketPopoverInfoProps } from '../../../redux/actions/TicketsManagement/schema';
import CommonPopover from '../MobileCommon/Popover';
import storeTicketPopoverInfo, { storeAddUpdateInfo } from '../../../redux/actions/TicketsManagement/MobileTicketActions';
import { AddUpdateIcon } from '../../common/svgIcons/chartIcon';
interface ITicketActionsProps{
    mobileActionsPopover?: IstoreTicketPopoverInfoProps;
    dispatch?:any;
}
function TicketActions(props:ITicketActionsProps) {
    const {mobileActionsPopover,dispatch} = props
    useEffect(()=>{
            console.log("mobileActionsPopover",mobileActionsPopover)
    },[mobileActionsPopover])
    const closePopover = () =>{
        dispatch(storeTicketPopoverInfo(null))
    }


    /* ======== TICKET ACTIONS ======= */
    const openAddUpdate = () =>{
        dispatch(storeAddUpdateInfo(mobileActionsPopover?.ticketId || null))
        closePopover();
    }
  return (
    <Box>
        <CommonPopover
            anchorEl = {mobileActionsPopover?.anchorEl}
            onClose = {closePopover}
        >
            <>
                <Typography className="ticket-action-item" onClick={openAddUpdate}><AddUpdateIcon /> Add Update</Typography>

                {/* <Typography className="ticket-action-item"><AddUpdateIcon /> Add Update</Typography>
                <Typography className="ticket-action-item"><AddUpdateIcon /> Add Update</Typography>
                <Typography className="ticket-action-item"><AddUpdateIcon /> Add Update</Typography>
                <Typography className="ticket-action-item"><AddUpdateIcon /> Add Update</Typography> */}
            </>

        </CommonPopover>
    </Box>
  )
}


const mapStateToProps = (state:IState) => {
    return ({
        mobileActionsPopover: state?.AllTicketsReducer?.mobileActionsPopover,
    })
  };
  
export default connect(mapStateToProps)(TicketActions);