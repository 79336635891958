const initialState = {
    data:[],
    filterProperties:null,
    filterConditions:[],
    workflowFiltersVal:[],
    workflowNodes:null,
    errors:{}
}
const WorkflowReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case 'UNSET_WORKFLOW_ERRORS':
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action?.payload] : false
                },
            };
        case 'RESET_WORKFLOW_ERRORS':
            return {
                ...state,
                errors: {}
            };
        case 'STORE_WORKFLOW_ERRORS':
            return {
                ...state,
                errors: action.payload,
            };
        case 'STORE_WORKFLOW':
            return {
                ...state,
                data: action.payload,
            };
        case 'WORKFLOW_FILTER_OPTIONS':
            return {
                ...state,
                workflowFiltersVal: action.payload,
            };
        case 'WORKFLOW_NODES':
            return {
                ...state,
                workflowNodes: action.payload,
            };
        case 'STORE_WORKFLOW_FILTER_PROPERTIES':
            return {
                ...state,
                filterProperties: action.payload.properties,
                filterPropertyObj : action.payload.propertiesObj,
            };
        case 'STORE_WORKFLOW_FILTER_CONDITIONS':
            return {
                ...state,
                filterConditions: action.payload,
            };
        default:
            return state;
    }
}
  
export default WorkflowReducer;
  