import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { IState } from '../../../../redux/reducers/rootReducers';
import { getAllCategoryIdsByTypeId } from '../../../../redux/actions/SystemManagement/AddCategory';
interface Props {
    categoryList?:any[],
    checkedTicketTypeItems:any,
    dispatch:any
}
function HelperModuleForCategory({categoryList=[],checkedTicketTypeItems={},dispatch}:Props) {
    console.log("checkedTicketTypeItems",checkedTicketTypeItems)
    useEffect(()=>{
        const keys = Object.keys(checkedTicketTypeItems);
        let idToDownload = null;
        keys.map((i)=>{
            if(checkedTicketTypeItems[i].length === 0) {
                idToDownload = i;
            }
        })
        if(idToDownload){
            dispatch(getAllCategoryIdsByTypeId(idToDownload))
        }
    },[checkedTicketTypeItems])

    
    return (
        <div>
            {/* {categoryList?.length} */}
        
        </div>
    )
}

const mapStateToProps = (state:IState) => {
    return ({
        //allInfo : state?.TicketsReducer?.allResponses
        categoryList : state?.categoryReducer?.categoryList,
        checkedTicketTypeItems : state?.roleReducer?.agentCheckBoxStatus?.TicketTypes,
        roleDataSet : state?.roleReducer?.roleInfo,
    })
};
export default connect(mapStateToProps)(HelperModuleForCategory);

// export default HelperModuleForCategory
