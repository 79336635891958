import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import AiIcon from "../../../assest/AI.png"
import { useDispatch } from 'react-redux';
import { syncArticlesAI, syncTicketsAI } from '../../../redux/actions/ChatBot/chatBot';
import { debounce } from 'lodash';
import Breadcrumbs from "../../common2/Breadcrumbs";
function AISync() {
    const dispatch = useDispatch();
    const syncTickets = debounce(() =>{
        dispatch(syncTicketsAI())
    },100)
    const syncArticles = debounce(() =>{
        dispatch(syncArticlesAI())
    },100)
    return (
        <Box>
            <Breadcrumbs
                data={[
                    {
                        title: 'Knowledge Base ',
                        path: '/KnowledgeBase'
                    },
                    {
                        title: 'AI Sync ',
                        path: '/AI-Sync'
                    }
                ]}
            />
            <Box className="white-bg p-2">
                    <Container>
                        <Typography component="h1" className='ai-page-title text-center'>
                            <img src={AiIcon} height={"40px"} alt="" />
                            Sync your Data to AI
                        </Typography>
                        <hr />
                        <Grid
                            container
                            spacing={{md: 3}}
                            justifyContent={"center"}
                        >
                            <Grid item xs={6} md={3}>
                                <Box className="syncInfo">
                                    <Typography component="h1">
                                        <span className="simple-icon icon-layers"></span> 
                                    </Typography>
                                    
                                    <Typography component="h3">Sync Tickets</Typography>
                                    <Divider/>
                                    
                                        <Typography>Synchronize your ticket data with AI to enhance issue resolution efficiency and response accuracy.</Typography>

                                    <Button onClick={syncTickets}><span className="simple-icon icon-refresh"></span> Sync</Button>
                                </Box>
                            </Grid>

                            <Grid item xs={6} md={3}>
                                <Box className="syncInfo">
                                    <Typography component="h1">
                                        <span className="simple-icon icon-graduation"></span> 
                                    </Typography>
                                    
                                    <Typography component="h3">Sync Articles</Typography>
                                    <Divider/>
                                    <Typography>Update and sync your articles with AI to ensure the most relevant and accurate information is available</Typography>

                                    <Button onClick={syncArticles}><span className="simple-icon icon-refresh"></span> Sync</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
            </Box>
        </Box>
    )
}

export default AISync
