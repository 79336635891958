// Refractoring code in progress.
import { createStyles, makeStyles } from "@mui/styles";
import Card from "../../common/card";
const CustomCard = ({prefixIcon,headerColor,width, header,subHeader, body, height,className}: {prefixIcon: Object,headerColor?:String,width?: any,header?: Object,subHeader?: Object, body?:any, height?: any,className?: any}) => {
  const useStyles = makeStyles(() =>
    createStyles({
      ThemeCard: {
        // fontFamily: "'DM Sans', sans-serif !important"
        padding: "0",
        width: `${width?width:"100%"}`,
        height: `${height ? height : "auto"} !important`,
        // background: `${headerColor} !important`,
        boxShadow: "1px 2px 2px rgb(4 24 87 / 22%) ",
        borderRadius: "10px !important ",
        overflow: "hidden",
        marginBottom: "0px !important",
        fontFamily: "'DM Sans', sans-serif !important",
        "& *": {
          // fontFamily: "'DM Sans', sans-serif !important"
        },
        "& .statusBox": {
          height: "25px",
          width: "40px",
        },
        "& .cardHeader": {
          display: "block !important",
          background: "#fff !important",//`${headerColor} !important`,
          padding: "5px 10px !important",
          fontFamily: "'DM Sans', sans-serif !important"
        },
        "& .userIcon": {
          padding: "0 0",
        },
        "& .cardContent": {
          padding: "14px !important",
          height: `${height} !important`,
          background: `${headerColor} !important`,
          "& td,th": {
            fontSize: "12px",
            padding: "4px",
            wordBreak: "break-all",
          },
          "& p": {
            fontSize: "10px",
          },
          "& .cardSubTitle": {
            fontSize: 13,
            margin: "0",
          },
        },
        "& .cardFooter": {
          padding: "0px !important",
          background: `${headerColor} !important`,
          
          "&  img": {
            cursor: "pointer",
          },
          "& >div": {
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            "& >*": {
              marginLeft: "10px",
            },
          },
          "& p": {
            fontSize: "12px",
            color: "blue",
          },
        },
        "@media screen and (max-width:980px)": {
          margin: 0,
        },
      },
    })
  );
  const classes = useStyles();
  return (
    <>
    <div id="23" style={{width: `${width}`, height:`${height}`}} className="card-theme">
        <Card 
          styleProp={`${classes.ThemeCard} ${className}`}
          customHeader={header ? 
              <div
              
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "self-start",
                  position:"relative",
                  height: "100%"

                }}
              >
                
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    fontFamily: "'DM Sans', sans-serif !important"
                  }}
                >
                  
                  {header}
                  {subHeader?subHeader:<></>}

                  
                </div>
                {prefixIcon}
              </div> : 
              null
          }
          cardBody={
            body ? body : null
          }
          cardFooter={null}
        />
        </div>
    </>
  );
};

export default CustomCard;
