const initialState = {
  AllContacts: [],
  editContacts: [],
  isContactAdded:'',
  isContactSync:false,
  contactImgDetails : {},
  contactImgDetailsById:{},
  loggedContact:{},
  AllContactAid:[],
  contactTickets:[],
  contactsSSP:[],
  contactByIdValues : {},
  ContactAidById:{},
  loggedContactOrg:{}
}
const ContactsReducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case 'STORE_SSP_CONTACTS':
          return {
              ...state,
              contactsSSP: action.payload,
          };
    case 'STORE_CONTACTS_BY_ID':
      return {
          ...state,
          contactByIdValues: {...state?.contactByIdValues , [action?.payload?.id] : action.payload},
      };
    case 'LOAD_MORE_CONTACTS':
      return {
          ...state,
          AllContacts: [...state.AllContacts , ...action.payload],
      };
    case 'REMOVE_CONTACT_ON_STATUS_CHANGE':
      return {
          ...state,
          AllContacts: state.AllContacts.filter((i:{id:number})=>i.id !== action.payload),
      };
    case 'GET_CONTACTS':
          return {
              ...state,
              AllContacts: action.payload,
          };
    case 'LOGGEDIN_CONTACT':
            return {
                ...state,
                loggedContact: action.payload.contacts,
                loggedContactOrg: action.payload.organisation,
            };
      case 'STORE_CONTACT_AID':
          return {
              ...state,
              AllContactAid: action.payload,
              DefaultService:action.payload.find((ele: any) => ele.isDefault === true)    
          };
    case 'STORE_CONTACT_AID_BY_ID':
            return {
                ...state,
                ContactAidById: {
                    ...state.ContactAidById,
                    [action.payload.id] : action.payload 
                }
        };
    case 'STORE_CONTACT_IMAGES':
          return {
              ...state,
              contactImgDetails: action.payload,
          };
    case 'STORE_CONTACT_IMAGES_BY_ID':
            return {
                ...state,
                contactImgDetailsById: action.payload,
            };
    case 'ADD_CONTACTS':
          return {
              ...state,
              isContactAdded: action.payload,
          };
    case 'EDIT_CONTACT':
          return {
              ...state,
              editContacts: action.payload,
          };
    case 'STORE_CONTACT_TICKETS':
        return {
            ...state,
            contactTickets: action.payload,
        };
    case 'SYNC_CONTACT':
        return {
            ...state,
            isContactSync: action.payload,
        };
      default:
          return state;
  }
}

export default ContactsReducer;
