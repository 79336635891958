import { memo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { loadMoreBtnStyles } from "../NewTickets";
import { IState } from "../../../../../redux/reducers/rootReducers";
import { Box } from "@mui/material";
import { loadNewToMeTickets } from "../../../../../redux/actions/TicketsManagement/TicketsManagement";

const LoadMoreNewToMe = memo((props:any) =>{
    const {AllData,pageCount,AllTickets,hasMoreTickets,FiltersVal,AllInfo} = props;
    const dispatch = useDispatch();
    const classes = loadMoreBtnStyles();
    
    const [loading,setLoading] = useState(false);
    const loadMoreData = () =>{
        if(AllTickets.length === 0 ) return;
        setLoading(true)
        setTimeout(()=>{dispatch(loadNewToMeTickets(FiltersVal,AllData,pageCount+1,AllTickets,AllInfo,loadOtherTicketsCB))})
    }
    const loadOtherTicketsCB = (res:any) =>{
        setLoading(false)
    }
    return (<>
        {(AllTickets?.length !== 0 && hasMoreTickets) && 
            <Box className={`load-more-btn ${classes?.loadMoreBtn}`}>
                {loading ? 
                <>Loading...</> :
                <span onClick={loadMoreData}>Load More</span>
                }
            </Box>
        }
        </>)
})

const mapStateToProps = (state:IState) => {
    return ({
        // LoginReducer: state?.LoginReducer?.roleAccess,
        AllInfo : state?.TicketsReducer?.alldataStructure,
        AllData : state?.TicketsReducer?.allResponses,
        pageCount : state?.AllTicketsReducer?.pageNoNewToMeTickets,
        AllTickets : state?.AllTicketsReducer?.newToMeTickets,
        hasMoreTickets : state?.AllTicketsReducer?.hasNewToMeTickets,
        FiltersVal : state?.filtersTicketManagementReducer,
    })
};
  
export default connect(mapStateToProps)(LoadMoreNewToMe);