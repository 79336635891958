import axios from "axios";
import {
    getTicketsByFilterUrl
} from "../../../../src/services/dashboard/dashboard.service";
import { IstoreTicketPopoverInfoProps } from "./schema";

export const storeTicketPopoverInfo = (data: IstoreTicketPopoverInfoProps|null) => {
  return {
      type: "STORE_POPOVER_INFO",
      payload: data
  }
};


export const storeSortInfo = (open: boolean) => {
  return {
      type: "STORE_SORT_INFO",
      payload: open
  }
};
export const toggleEmailPopup = (obj: any) => {
  return {
      type: "TOGGLE_SEND_EMAIL",
      payload: obj
  }
};


export const toggleMobileFilter = (open: boolean) => {
  return {
      type: "ENABLE_MOBILE_FILTER",
      payload: open
  }
};

export const storeContactView = (info: any) => {
  return {
      type: "STORE_CONTACT_VIEW_INFO",
      payload: info
  }
};

export const storeAddUpdateInfo = (ticketId: any) => {
  return {
      type: "STORE_ADD_UPDATE_INFO",
      payload: ticketId
  }
};

const getNewTickets = (statusIds: any, queryUserIds?: string) => {
  return async (dispatch: any) => {
    try {
      const ticketsRes: any = await axios.get(
        `${getTicketsByFilterUrl}?page=0&count=100&statusIds=${statusIds?.["New"]}`
      ); 
    } catch (error: any) {
      console.log(error.response);
      return error.response;
    }
  };
};
export default storeTicketPopoverInfo;
