const initialState = {
    Data: []
  };
  
  const SSOConfigReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case "GET_SSOCONFIG_INFO":
        return {
          ...state,
          Data: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default SSOConfigReducer;