import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from "@mui/icons-material/Save";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SortableTree from "react-sortable-tree";
import { getCategoryByIdForRoles, getCategoryV2 } from '../../redux/actions/SystemManagement/AddCategory';
import { useStyles } from '../workflowConfig/workflowModules/AddNewFilterValue';
import BasicButton from './button/BasicButton';
interface Node {
    id: string;
    children?: Node[];
}
interface BasicObj {
    [key: string]: any;
}
interface checkedItems {
    [key: string]: checkedItems1;
}
interface checkedItems1 {
    [key: string]: boolean;
}
interface IChooseAllCategoryProps {
    closeHandler:()=>void;
    callback:(val:BasicObj)=>void;
    defaultValue?:BasicObj|undefined;
    ticketTypeIds?:any;
    checkForTypeIds?:boolean;
}
function ChooseAllCategory(props:IChooseAllCategoryProps) {
    const {closeHandler,checkForTypeIds=false,defaultValue={},callback,ticketTypeIds=null} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [treeData, setTreeData] = useState<any>([]);
    const [allCategoryData, setAllCategoryData] = useState<any>([]);
    const [selectedIndex, setSelectedIndex] = useState<number|null>(null);
    const [checkedIDs, setCheckedIDs] = useState<checkedItems>(defaultValue);
    const [categoriesById, setCategoriesById] = useState<BasicObj>({});

    
    
    const checkBoxCategory = (e:any,node:any) => {
        const checked:boolean = e.target.checked;
        const tempObj = getChildIds(node,checked)
        console.log("isFirstLevel",node,tempObj)
        updateCheckedState(tempObj);
    }

    const chooseAllCategoryItems = (e:any,id:number,index:number) => {
        const checked:boolean = e.target.checked;
        if(categoriesById?.[id]){
            const tempObj = getChildIds(categoriesById?.[id],checked)
            updateCheckedState(tempObj);
        }else{
            dispatch(getCategoryByIdForRoles(id,getCategoryTreeAndPrefillCB,checked));
        }
    }

    const updateTreeData = (e:any) =>{
        setTreeData(e);
    }
    const chooseCategory = (i:any,index:number)=>{
        setSelectedIndex(index);
    }
    

    const getCategoryTreeCB = (resStatus:string,res:any)=>{
        if(resStatus === "0"){
            setTreeData([res]);
            setCategoriesById({
                ...categoriesById,
                [res?.id] : res
            });
        }
    }
    const getCategoryTreeAndPrefillCB = (resStatus:string,res:any,checked:boolean)=>{
        if(resStatus === "0"){
            setCategoriesById({
                ...categoriesById,
                [res?.id] : res
            });
            const tempObj = getChildIds(res,checked)
            updateCheckedState(tempObj);
        }
    }
    const updateCheckedState = (newVal:any) =>{
        const tempObj:any = {...checkedIDs, ...newVal}
        
        const ids = Object.keys(tempObj)
        ids.map((i)=>{
            if(!tempObj?.[i]) delete tempObj[i];
        })
        console.log("updateCheckedState1",tempObj)
        setCheckedIDs(tempObj);
    }
    const onSubmit = () =>{
        callback(checkedIDs);
        closeHandler();
    }
    const getCategoryV2CB = (res:{isSuccess : boolean, data : any}) => {
        console.log("ticketTypeIds",ticketTypeIds,res?.data)
        const tempArr:any =[];
        res?.data.map((i:any)=>{
            if(!checkForTypeIds){
                tempArr.push({
                    ...i,
                    isFirstLevel : true
                })
            }else if(ticketTypeIds.includes(i.ticketTypeId)){
                tempArr.push({
                    ...i,
                    isFirstLevel : true
                })
            }
            
        })
        setAllCategoryData(tempArr);
    }


    useEffect(()=>{
        if(selectedIndex === null)return;
        if(categoriesById?.[allCategoryData?.[selectedIndex]?.id]){
            setTreeData([categoriesById?.[allCategoryData?.[selectedIndex]?.id]]);
            return;
        }
        dispatch(getCategoryByIdForRoles(allCategoryData?.[selectedIndex]?.id,getCategoryTreeCB,false));
    },[selectedIndex])
    useEffect(()=>{
        dispatch(getCategoryV2(getCategoryV2CB));
        return ()=> console.log('unmount');
    },[])
    useEffect(()=>{
        if(allCategoryData.length>0){
            setSelectedIndex(0);
        }
    },[allCategoryData.length])
    return (<>
        {allCategoryData?.length > 0 ? <></> : <>No Categories available!</>}
        <Box className={classes.popupHolder}>
            <Box className="modal-dialog">
                <Box className="modal-content">
                    <Box className="modal-header">
                        Choose Category 
                        <Box>
                        <CloseIcon className="modalCloseBtn" onClick={()=>{closeHandler()}} />
                        </Box>
                    </Box>
                    <Box className="modal-body">
                        <Box className="p-1">
                            <Box className={classes.row}>
                                <Box>
                                    {allCategoryData.map((i:any,index:number)=>{
                                        return(
                                            <Box className={`${classes.categoryBtn} ${selectedIndex === index ? "active" : ""} categoryPopup-TabLinks`}>
                                                <Typography onClick={()=>{chooseCategory(i,index)}} >{i?.name}</Typography>
                                                <FormControlLabel
                                                    value=''
                                                    control={
                                                        <Checkbox size="small" 
                                                            onChange={(e:any)=>{
                                                                chooseAllCategoryItems(e,i.id,index)
                                                            }}
                                                            // indeterminate={(checkedIDs?.[i?.ticketTypeId] && !checkedIDs?.[i?.ticketTypeId]?.[`${i?.id}`]) ? true : false}
                                                            name={i.title}
                                                            checked={(checkedIDs?.[`${i?.id}`]) ? true : false}
                                                        />
                                                    }
                                                    label={i.title}
                                                    labelPlacement="end"
                                                    className="sml-txt"
                                                />
                                            </Box>
                                        )
                                    })}
                                </Box>


                                <Box>
                                    <Box className="aside t-0">
                                        <SortableTree
                                            treeData={treeData}
                                            isVirtualized={false}
                                            canDrag={false}
                                            onChange={updateTreeData}
                                            //onChange={}
                                            generateNodeProps={({ node }:any) => ({
                                            title: (
                                                <FormControlLabel
                                                    value=''
                                                    control={
                                                        <Checkbox size="small" 
                                                            indeterminate={false}
                                                            onChange={(e:any)=>{
                                                                    checkBoxCategory(e ,node)
                                                                }
                                                            }
                                                            name={node.title}
                                                            checked={checkedIDs?.[node?.id] ? true : false}
                                                        />
                                                    }
                                                    label={`${node.title}`}
                                                    labelPlacement="end"
                                                    className="sml-txt"
                                                />
                                            ),
                                            })}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box className="modal-footer">
                        <Box className="text-right p-1">
                        <BasicButton
                            onClick={closeHandler}
                            type={"cancel"}
                            isLoading={false}
                            isDisabled={false}
                            color="primary"
                            size="large"
                            label={"Cancel"}
                            variant={"outlined"}
                            endIcon={<CloseIcon sx={{ width: "20px" }} />}
                        />

                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <BasicButton
                            onClick={onSubmit}
                            type="submit"
                            label={`Save ${Object.keys(checkedIDs)?.length} Items`}
                            isLoading={false}
                            isDisabled={false}
                            color="primary"
                            size="large"
                            variant={"contained"}
                            endIcon={<SaveIcon sx={{ width: "20px" }} />}
                        />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    </>)
}

const getChildIds = (node: Node | null,val:boolean): Record<string, boolean> => {
    const ids:BasicObj = {};

    const collectIds = (currentNode: Node | null) => {
        if (!currentNode) return;
        ids[currentNode.id] = val;

        currentNode.children?.forEach(collectIds);
    };

    collectIds(node);
    console.log("getChildIds_ids",ids)
    return ids;
}
export default ChooseAllCategory
