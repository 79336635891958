import axios from "axios";
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";

const url = `${configs.BASE_URL}/SystemTypes`;



const getSystemType = () => {
    return async (dispatch: any) => {
        try {
          const response = await axios(url);
          dispatch(loading(false));
          dispatch({
            type: "getSystemType",
            payload: response.data,
          });
        } catch (error) {
          return console.log(error);
        }
      };
    };



  

export const loading = (status: any) => {
  return {
    type: "loading",
    payload: status,
  };
};

export default getSystemType;