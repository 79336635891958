import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { debounce } from "lodash";
import React, { Suspense, memo, useCallback, useEffect, useMemo } from "react";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllCalendarTickets, getAllTickets } from "../../../redux/actions/TicketsManagement/TicketsManagement";
import { IState } from "../../../redux/reducers/rootReducers";
import AgentsOption from "./FilterComponents/AgentsOption";
import CalendarRangeOption from "./FilterComponents/CalendarRangeOption";
import FilterOptions from "./FilterComponents/FilterOptions";
import LayoutShift from "./FilterComponents/LayoutShift";
import MoreActions from "./FilterComponents/MoreActions";
import ResetFilters from "./FilterComponents/ResetFilter";
import SearchBox from "./FilterComponents/SearchBox";
import SortOption from "./FilterComponents/SortOption";
import { IDashboardSecondaryNavbar } from "./SecondaryNavbar";
import { handleTime } from "../../helpers/helperFunctions";
const CommonAppFilter:React.FC<IDashboardSecondaryNavbar> = memo((
  props: IDashboardSecondaryNavbar
) => {
    const {hideExtraIcons= false,hideAddBtn,hideViewMode,hideAgent,hideSearch,hideSort,hideFilter,report,page=""} = props;
    console.log('TicketDashboardHolder-CommonAppFilter')
    const AllData = useSelector((state: IState) => state?.TicketsReducer?.allResponses);
    //const currAgent = useSelector((state: IState) => state?.agentReducer?.loggedInAgent);
    const FiltersVal: any = useSelector((state: IState) => state?.filtersTicketManagementReducer);
    const AllInfo = useSelector((state: IState) => state?.TicketsReducer?.alldataStructure);
    const dispatch = useDispatch();
    const classes = useStyles();
    const navigate = useNavigate();
    const addNewTicket = useCallback(() => {
      navigate('/home/ticket/new-ticket')
    },[]);
    //const [loader,setLoader] = useState(false);
    const callback=()=>{
      //setLoader(false)
    }
    let s = report
    useEffect(()=>{
      loadTickets();
    },[])
    useEffect(()=>{
      loadTickets();
    },[FiltersVal])
  const loadTickets = ()=>{
    if(!AllData?.ticketStatus ) return;//|| firstload
    handleTime.cancel();
    handleTime.setup(()=>{
      document.body.classList.add('loader');
      if(page==='calendar'){
        dispatch(getAllCalendarTickets(FiltersVal,AllData,callback))
      }else{
        dispatch(getAllTickets(FiltersVal,AllData,AllInfo,callback,s))
      }      
    },1500);
  }
  const addBtn = useMemo(()=><>
  {!hideAddBtn &&
  <Button className={classes.addNewButton}onClick={addNewTicket}variant="contained">New Item</Button>}
  </>,[hideAddBtn,classes]);

  const resetAndCalendar = useMemo(()=><><CalendarRangeOption />
  <ResetFilters /></>,[classes])
  const LayoutShiftOption = useMemo(()=><>{!hideViewMode && <LayoutShift /> }</>,[hideViewMode,classes]);
  const SearchBoxOption = useMemo(()=><>{!hideSearch && <SearchBox /> }</>,[hideSearch,classes]);
  const FilterOptionsOption = useMemo(()=><>{!hideFilter && <FilterOptions /> }</>,[hideFilter,classes]);
  const SortOptionOption = useMemo(()=><>{!hideSort && <SortOption /> }</>,[hideSort,classes]);
  const AgentsOptionOption = useMemo(()=><>{!hideAgent && <AgentsOption /> }</>,[hideAgent,classes]);
  // const MoreActionsOption = ()=><MoreActions report={report} hideExtraIcons={hideExtraIcons}/>;
  const filterHolder = useMemo(() => <>
    <div className={`sticky-Top-0 ${classes.row} ${classes.gap10} ${classes.alignCenter} tickect-management-button`} style={{ justifyContent: "space-evenly",}}>
      <Suspense fallback={<Typography className="ticket-ms-loading">Loading...</Typography>}>
        {/* {!hideAddBtn &&
          <Button className={classes.addNewButton}
            onClick={addNewTicket}
            variant="contained" 
          >New Item</Button>
        } */}
        {addBtn}
        {SearchBoxOption}
        {FilterOptionsOption}
        {SortOptionOption}
        {LayoutShiftOption}
        {AgentsOptionOption}
        {/* {!hideSearch && <SearchBox />} */}
        {/* {!hideFilter &&<FilterOptions />} */}
        {/* {!hideSort && <SortOption />} */}
        {/* {!hideAgent && <AgentsOption />} */}
        {resetAndCalendar}
        {/* {MoreActionsOption} */}
        <MoreActions report={report} hideExtraIcons={hideExtraIcons} page={page}/>
        {/* <MoreActions report={report} hideExtraIcons={hideExtraIcons} /> */}
      </Suspense>
    </div></>, [classes])
  return (<>
      {filterHolder}
    </>
  );
})


const useStyles = makeStyles(() => ({
  filterBox:{
      padding: "1.5rem 0",
      borderBottom: "1px solid #ddd"
  },
  row:{
    display:"flex"
  },
  alignCenter:{
    alignItems:"center"
  },
  gap10:{
    gap:"10px"
  },
  addNewButton: {
    color: "white",
    height: "23px",
    fontSize: "9px",
    backgroundColor: "#007bff",
    padding: "2px",
    whiteSpace: "nowrap",
  }
}));
export default CommonAppFilter;