import axios from "axios";
import configs from "../../../configs/config";
import { alertMessage, triggerAppAlert } from "../SelfServicePortal/ContactAid/ContactAid";
import { iArticle } from "../../../components/knowledgeBase/schema";
import LanguageData from "../../../configs/LanguageData.json"
import { multipleFileUploadServiceForBloeStorage } from "../../../services/fileUpload/FileUpload";
export const url = `${configs.BASE_URL}/article`;
export const getArticleUrl = `${configs.BASE_URL}/article`;
export const urlStatusChange = `${configs.BASE_URL}/article/status/change?ids=`;//1%2C2%2C3%2C4%2C5%2C6%2C7&status=false
export const sspUrl = `${configs.BASE_URL}/user/article`;
export const getArticles = (callback:any,isSSP:boolean  =false) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${isSSP ? sspUrl : url}`);
      response.data = response.data.map((i:any)=>{
        return {...i,rid:`#${i.id}`,value:i.name,label:i.name}
      }) 
      dispatch({
        type : "STORE_ALL_ARTICLES",
        payload : response.data
      })
      try{callback && callback("0",response.data)}catch(e){}
    } catch (error:any) {
      callback && callback("1",error.response)
      return console.log(error.response);
    }
  };
};
export const getArticlesById = (id:string|number,callback?:any,isSSP?:boolean) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${isSSP ? sspUrl : url}/${id}`);
      if(response?.data?.articleType){ 
        response.data.articleType = {
          ...response.data.articleType,
          label: response.data.articleType.name,
          value: response.data.articleType.name
        }
      }
      if(response?.data?.ticketType){ 
        response.data.ticketType = {
          ...response.data.ticketType,
          label: response.data.ticketType.displayName,
          value: response.data.ticketType.displayName
        }
      }
      if(response?.data?.category){ 
        response.data.category = {
          ...response.data.category,
          label: response.data.category.name,
          value: response.data.category.name
        }
      }
      if(response.data.articleAttachmentList){
        response.data.articleAttachmentList = response.data.articleAttachmentList.map((i:any)=>{
          return {
            ...i,
            path : i.attachmentPath,
            name : i.attachmentPath,
            oldId : i.id,
            isOld : true
          }
        }) 
      }
      try{callback && callback("0",response.data)}catch(e){}
    } catch (error:any) {
      callback && callback("1",error.response)
      return console.log(error.response);
    }
  };
};
export const addArticle = (data: iArticle, callback: any  = undefined) => {
    return async (dispatch: any) => {
      try {
        const response = await axios.post(url,data);
        dispatch(alertMessage(`${LanguageData.SUCCESS_NEW_ARTICLE}${LanguageData.TICKET_CREATED}`,"success"))
        dispatch(uploadFilesToBlob(response.data, data))
        try{callback && callback("0",response.data)}catch(e){}
      } catch (error:any) {
        dispatch(alertMessage(error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.","error"))
        callback && callback("1",error.response)
        return console.log(error.response);
      }
    };
};
export const uploadFilesToBlob = (response:any,payload: iArticle, callback: any  = undefined) => {
    return async (dispatch: any) => {
      try {

        const urls = []
        const fileObjs = []
        if(payload?.thumbnailIconObj?.fileObj){
          urls.push(response.thumbnailIconPath)
          fileObjs.push(payload.thumbnailIconObj.fileObj)
        }
        if(payload.articleAttachmentList.length>0){
          payload.articleAttachmentList.map((i:{attachmentPath:string,fileObj:any,isOld:boolean},index:number)=>{
            const {fileObj} = i;
            if(fileObj?.fileObj){
              urls.push(response.articleAttachmentList[index].attachmentPath)
              // urls.push(attachmentPath)
              fileObjs.push(fileObj?.fileObj)
            }
          })
        }
        dispatch(multipleFileUploadServiceForBloeStorage(urls,fileObjs))
      } catch (error:any) {
        console.log("uploadFilesToBlob",error)
      }
    };
};
export const editArticle = (id:any,data: any, callback?:any) => {
    return async (dispatch: any) => {
      try {
        const response = await axios.put(`${url}/${id}`,data);
        dispatch(alertMessage(`${LanguageData.SUCCESS_ARTICLE}${LanguageData.TICKET_UPDATED}`,"success"))
        dispatch(uploadFilesToBlob(response.data, data))
        try{callback && callback("0",response.data)}catch(e){}
      } catch (error:any) {
        dispatch(alertMessage(error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.","error"))
        callback && callback("1",error.response)
        return console.log(error.response);
      }
    };
};
export const statusUpdateArticle = (id:any,status: any, callback?:any) => {
  const statusMsg = status ? `${LanguageData.SUCCESS_ARTICLE}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_ARTICLE}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  return async (dispatch: any) => {
    try {
      // urlStatusChange//1%2C2%2C3%2C4%2C5%2C6%2C7&status=false
      const response = await axios.delete(`${urlStatusChange}${id}&status=${status}`);
      dispatch(triggerAppAlert(statusMsg, 'success'))
      try{callback && callback("0",response.data)}catch(e){}
    } catch (error:any) {
      dispatch(alertMessage(error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.","error"))
      callback && callback("1",error.response)
      return console.log(error.response);
    }
  };
};