import { connect, useDispatch, useSelector } from "react-redux";
import { IState } from "../../../../redux/reducers/rootReducers";
import { memo, useEffect, useState } from "react";
import SelectAllCategoriesOption from "./SelectAllCategories";
import CommonStyles from "../../../common/CommonStyles";
import { Accordion, Box, AccordionSummary, Typography, AccordionDetails, Button } from "@mui/material";
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { storeCategoriesForRole } from "../../../../redux/actions/userManagement/role";
import { ChooseCategory } from "../../../workflowConfig/workflowModules/AddNewFilterValue";
import ChooseAllCategory from "../../../common/ChooseAllCategory";

const CategoryPart = ({checkedItems,checkedCategories={}}:any) =>{
    const [expanded,setExpand] = useState(true);
    const dispatch = useDispatch();
    const classes = CommonStyles();
    const [showCategory,setShowCategory] = useState(false);
    
    const [activeTicketTypes,setActiveTicketTypes] = useState<any>([]);
    const toggleCollapse = () =>{
        setExpand(!expanded); 
    }
    const closeHandler = () =>{
        setShowCategory(false)
    }
    const ChooseCategoryCB = (ids:any) =>{
        console.log("ChooseCategoryCB",ids)
        dispatch(storeCategoriesForRole(ids))
        closeHandler()
    }
    const getSelectedTicketTypeIds = ()=>{
        if(!checkedItems) return null
        const keys = Object.keys(checkedItems);
        const selectedTicketTypes =  keys.filter((i:any)=>checkedItems[i].length > 0)
        return selectedTicketTypes.map(i=>Number(i));
    }
     useEffect(()=>{
        console.log("findheadache",checkedItems,checkedCategories)
    },[checkedItems,checkedCategories])
    useEffect(()=>{
        const selectedTicketTypes = getSelectedTicketTypeIds()
        setActiveTicketTypes(selectedTicketTypes||[])
    },[checkedItems])
    return(<>
        {activeTicketTypes?.length > 0 && 
            <Accordion expanded={expanded} className="accordionDtls">
                <AccordionSummary aria-controls={`roles-category-content`} id={`roles-category-header`} className='accrodTitle'>
                    <Box className={`${classes.row} ${classes.justifyBetween} w-100`}>
                        <Box>
                            <Typography>Categories</Typography>
                        </Box>
                        <Box className={`${classes.row} ${classes.alignItemsCenter}`} >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {expanded ?
                                <KeyboardArrowUpOutlinedIcon className="pointer" onClick={toggleCollapse} /> :
                                <KeyboardArrowDownOutlinedIcon className="pointer" onClick={toggleCollapse} /> 
                            }
                        </Box>
                    </Box>

                </AccordionSummary>
                <AccordionDetails>
                    <Button className="choose-Category-Btn" onClick={()=>{setShowCategory(true)}}>Select Category {Object.keys(checkedCategories)?.length}</Button>
                    
                </AccordionDetails>
            </Accordion>
        }
        {showCategory && <>
            <ChooseAllCategory 
                closeHandler = {closeHandler}
                ticketTypeIds={activeTicketTypes}
                checkForTypeIds={true}
                defaultValue={checkedCategories}
                callback= {(values:any)=>{
                    ChooseCategoryCB(values)
                }}
            />
            </>
        }
    </>)
}


const mapStateToProps = (state:IState) => {
    return ({
        checkedItems : state?.roleReducer?.agentCheckBoxStatus?.TicketTypes,
        checkedCategories : state?.roleReducer?.agentCheckBoxStatus?.Categories,
    })
};
export default connect(mapStateToProps)(CategoryPart);//AgentCheckBoxList;//