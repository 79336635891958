import { Box, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';
interface IHomeGroupFilterProps{
    dispatch:any;
    dashboardGroup:{ [key: string]: any };
    groupsList?: Array<{  [key: string]: any  }>;
}
interface Item {
    id : number,
    label : string,
    value : number
}
const HomeGroupFilter = ({dispatch,dashboardGroup,groupsList}:IHomeGroupFilterProps) => {
    const [list,setList] = useState<Item[]>([]);
    const toggleGroups = (id:number,checked:boolean,label:string) =>{
        const tempObj:any = {...dashboardGroup};
        if(!checked){ 
          delete tempObj[label];
        } else{
          tempObj[label] = id;
        }
        dispatch({
            type:"STORE_DASHBOARD_GROUPS",
            payload : tempObj
        })
    }
    useEffect(()=>{
        if(!groupsList?.map) return;
        const tempArr:Item[] = [];
        groupsList.map((i:any)=>{
          if(!i.isActive) return;
          tempArr.push({ id : i.id, label : i.name, value : i.name })
        })
        setList(tempArr)
    },[groupsList])
    return (
        <Box>
            {/* <Box sx={{ width: 350, p: 2 }}>
                <Box className="row m-0 justify-content-between pb-1">
                    <Typography>Groups</Typography>
                </Box>
                <Divider />  */}
                <Box className="row m-0 pt-1 gap5 dashboard-grup-selector">
                    {list.map((i: Item) => {
                        return (
                            <Typography onClick={() => toggleGroups(i.id, dashboardGroup?.[i.label] ? false : true, i.label)} className={`cursor-pointer ${dashboardGroup?.[i.label] ? 'checked' : ""}`}>
                                {i.label}
                            </Typography>
                        )
                    })}
                </Box>
            {/* </Box> */}
        </Box>
    )
}

const mapStateToProps = (state: IState) => {
    return ({
      homeFilter: state?.TicketsReducer?.homeDashboardFilter,
      groupsList : state?.groupReducer?.Data?.data,
      dashboardGroup: state?.TicketsReducer?.dashboardGroup,
    })
  };
export default connect(mapStateToProps)(HomeGroupFilter);
