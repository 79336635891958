import * as React from "react";
import "react-phone-input-2/lib/style.css";
import BasicButton from "../../components/common/button/BasicButton";
import CustomTree from "../../components/common/custom-tree/CustomTree";
import CustomDialog from "../../components/common/dialog/CustomDialog";
import { IState } from "../../redux/reducers/rootReducers";
import { connect } from "react-redux";
import { getCategoryByTypeId, singleCategory } from "../../redux/actions/SystemManagement/AddCategory";
interface ICategoryTreeSelection {
    callback: (val:any) => void;
    ticketTypeId?:number;
    defaultValue?:number;
    dispatch?:any;
}


const CategoryTreeSelection = (props: ICategoryTreeSelection) => {
    const { callback, ticketTypeId=0, defaultValue=0,dispatch } = props;
    const [categoryVal, setcategoryVal] = React.useState<any>({});
    const [loading, setLoading] = React.useState<any>(false);
    const [currVal, setCurrVal] = React.useState<any>({});

    const onSelectCategory = (val:any) =>{
        console.log("onSelectCategory", val)
        setCurrVal(val)
    }
    const handleClose = () =>{
        setcategoryVal(null)
        dispatch({
            type:"CATEGORY_POPUP_TICKET_TYPE_ID",
            payload : 0
        })
    }
    React.useEffect(()=>{
        if(ticketTypeId>0){
            // dispatch(singleCategory(ticketTypeId,singleCategoryCB))
            dispatch(getCategoryByTypeId(ticketTypeId,singleCategoryCB))
            setLoading(true)
        }else{
            handleClose();
        }
    },[ticketTypeId])

    const singleCategoryCB = (res:{isSuccess : boolean, data : any}) =>{
        const {isSuccess, data} = res;
        setLoading(false)
        if(isSuccess){
            setcategoryVal(data)
        }
    }
    const onSubmit = () =>{
        callback(currVal);
        handleClose();
    }
    return (
        <>
            <CustomDialog
                heading="Choose Category"
                body={
                    <>
                        <div className="category-content" style={{ width: "400px" }}>
                            {loading && <>Loading...</>}
                            {categoryVal &&
                                <CustomTree
                                    title={categoryVal?.name || ""}
                                    data={categoryVal?.subCategoryList || []}
                                    defaultValue={defaultValue}
                                    onSelect={onSelectCategory}
                                />
                            }
                        </div>
                    </>
                }
                open={(ticketTypeId && ticketTypeId > 0) ? true : false}
                handleClose={handleClose}
                footer={
                    <>
                        <BasicButton
                            type={"submit"}
                            isLoading={false}
                            isDisabled={false}
                            color="primary"
                            label={"Save"}
                            onClick={onSubmit}
                            size="large"
                            variant={"contained"}
                            endIcon={false}
                        />
                    </>
                }
            />
        </>
    );
};

const mapStateToProps = (state:IState) => {
    return ({
        ticketTypeId : state?.TicketViewReducer?.categoryPopupTicketTypeId,
    })
  };
  
export default connect(mapStateToProps)(CategoryTreeSelection);
// export default CategoryTreeSelection