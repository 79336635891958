import { Container } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import getSystemType from '../../../redux/actions/SystemTypes/SystemTypes';
import { getRole } from '../../../redux/actions/userManagement/role';
import { IState } from '../../../redux/reducers/rootReducers';
import DataPreloadedWrapper from '../../../util/DataPreloadedWrapper';
import Breadcrumbs from "./../../common2/Breadcrumbs";
import "./Ranking.css";
import RankingContent from './RankingContent';

const Ranking = (props:any) => {
  const {systemTypes={}, rolesList=[]} = props;
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(true)
  useEffect(()=>{
    dispatch(getRole());
    // dispatch(getPriority());
    if(!systemTypes?.Priorities){
      dispatch(getSystemType())
    }
  },[])
  useEffect(()=>{
    if(loading && systemTypes?.Priorities && systemTypes?.TicketStatus && rolesList.length >0 ){
      setLoading(false)
    }
  },[systemTypes,rolesList])
  return (
    <>
    <Container className="ml-0">
      <Box className="pb-1 Ranking">
        <Breadcrumbs
          data={[
              {
                  title: 'User Management ',
                  path: '/UserManagement'
              },
              {
                  title: 'Ranking',
                  path: '/Ranking'
              }
          ]}
        />
      </Box>
      {loading ? <> Loading...! </> : <>
        <DataPreloadedWrapper>
          <RankingContent />
        </DataPreloadedWrapper>
      </>}
      </Container>
    </>
  )
}



const mapStateToProps = (state:IState) => {
  return ({
    systemTypes: state?.systemTypeReducer?.systemTpesByName,
    rolesList: state?.roleReducer?.Data?.data,
    // priorityList: state?.priorityReducer?.Data?.data,
  })
};

export default connect(mapStateToProps)(Ranking);