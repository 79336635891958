const initialState = {
    Data: []
  };
  
  const WhatsAppReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case "WHATSAPP_INFO":
        return {
          ...state,
          Data: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default WhatsAppReducer;
  