import React from 'react';
import { connect } from 'react-redux';
// import { setAuthentication } from './path/to/actions';
import AccessDenied from '../components/OtherPages/AccessDenied';
import { getPagePermission } from '../components/helpers/helperFunctions';
import { IState } from '../redux/reducers/rootReducers';
const ProtectedRoute = ({ LoginReducer, ...rest }:any) => {
  //   return (
  //     <div>
  //       <h1>Counter: {count}</h1>
  //       <button onClick={increment}>Increment</button>
  //       <button onClick={decrement}>Decrement</button>
  //     </div>
  //   );
  // };

  return (
    <React.Fragment>
      {getPagePermission(LoginReducer,rest?.path) ?
        <>{rest.component}</> :
      <AccessDenied />}
    </React.Fragment>
  );
};

const mapStateToProps = (state:IState) => {
  return ({
    LoginReducer: state?.LoginReducer?.roleAccess,
  })
};

export default connect(mapStateToProps)(ProtectedRoute);