import React, { memo } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CommonStyles from "./CommonStyles";
import AddIcon from "@mui/icons-material/Add";
export interface IButton {
  title: String;
  path: String;
}
export const AddNewPart = memo((props: IButton) =>{
  const theme = createTheme();
  const mode = CommonStyles();
  const { title, path } = props;
  const navigate = useNavigate();
  const redirect = (path: String) => {
    navigate(`${path}`);
  };
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        flex: "0 0 100%",
        "& > div": {
          padding: "20px",
          maxWidth: "100%",
          textAlign: "center",
        },
        "& > *": {
          margin: theme.spacing(1),
        },
      },
      activeButton: {
        backgroundColor: "blue",
        color: "white",
        "&:hover": {
          backgroundColor: "blue",
        },
      },
      addNewBox: {
        color: mode.themeColorTxt,
      },
    })
  );
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box className={classes.addNewBox}>
        <Typography gutterBottom variant="h6">
          No Records Found
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            //onclickHandler();
            redirect(path);
          }}
          color="primary"
        >
          <>
            <AddIcon />
            {title}
          </>
        </Button>
      </Box>
    </div>
  );
})
export default AddNewPart;

