import { Box, Typography } from '@mui/material'
import React from 'react'
// import MobileTicketCard from '../MobileUtilities/MobileTicketCard';
import TicketActions from '../MobileUtilities/TicketActions'
import ContactViewOption from '../MobileUtilities/ContactView'
import TicketList from './Tickets/TicketList'

const SearchResultTickets = () => {
  return (
    <Box className="pt-1">
        <Typography className="pb-1">Recent Searches</Typography>
        {/* <MobileTicketCard /> */}
        <TicketList />













        <TicketActions />
        <ContactViewOption />

    </Box>
  )
}

export default SearchResultTickets
