import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CollapseIcon } from '../../common/svgIcons/chartIcon';
import { Box } from '@mui/material';

interface IAccordionCollapseProps{
    title : string | JSX.Element;
    id:string;
    body : JSX.Element;
}
export default function AccordionCollapse(props:IAccordionCollapseProps) {
    const {title,body,id} =  props;
    return (
        <Box className="accordion-holder">
            <Accordion defaultExpanded className="mobile-accrodion">
                <AccordionSummary
                    className='mobile-collapse-summary'
                    expandIcon={<CollapseIcon />}
                    aria-controls={`${id}-pane-content`}
                    id={`${id}-pane-header`}
                >
                    <Typography className='collapse-title'>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails className="collapse-body">
                    <Box className="collapse-body-content">{body}</Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}