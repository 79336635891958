
export const rankingInitVal:RankingState = {
    roleVal : null,
    roleList : [],
    statusObj : {},
    priorityObj : {},
    statusToShow : [],
    prioritiesToShow : [],
    servicesToShow:[],
    otherPermissions : [],
    sortedPriority : [],
    sortedStatus : [],
    sortedAid : [],
    roleByIdVal:{},
}

export interface RankingState {
    roleVal  : null | DropDownVal;
    roleList : any[];
    statusObj : any;
    priorityObj: any;
    statusToShow : any[];
    prioritiesToShow : any[];
    servicesToShow:any[];
    otherPermissions : any[];
    sortedPriority : any[];
    sortedStatus : any[];
    sortedAid:any[];
    roleByIdVal: any;
}

export interface DropDownVal {
    label  : number | string;
    id  : number | string;
    value  : number | string;
}

type RankingAction = { type: string,payload?:any};

// Reducer function
export const rankingReducer = (state: RankingState=rankingInitVal, action: RankingAction): RankingState => {
  const {type,payload} = action;
  switch (type) {
    case 'STORE_ROLE_OPTIONS':
      return { 
            ...state,
            roleList : payload
    };
    case 'ROLE_CHANGE':
      return { 
            ...state,
            roleVal : payload,
            sortedPriority : [],
            sortedStatus : [],
            statusToShow : [],
            prioritiesToShow : [],
            servicesToShow:[],
            otherPermissions : [],
            roleByIdVal:{}
    };
    case 'STORE_PRIORITIES_OBJ':
      return { 
            ...state,
            priorityObj : payload
    };
    case 'STORE_STATUS_OBJ':
      return { 
            ...state,
            statusObj : payload
    };
    case 'SORT_STATUS':
      return { 
            ...state,
            sortedStatus : payload
    };
    case 'SORT_AID':
      return {
        ...state,
        sortedAid:payload
      }
    case 'SORT_PRIORITY':
      return { 
            ...state,
            sortedPriority : payload
    };
    case 'RECORDS_TO_SHOW' : 
      return {
        ...state,
        statusToShow : payload.status,
        prioritiesToShow : payload.priority,
        servicesToShow : payload.aidList,
        otherPermissions : payload.otherPermissions,
        roleByIdVal : payload.roleByIdVal
      }
    default:
      return state;
  }
};



export interface IRankingProps{
    systemTypes?:any, 
    aidList:any[],
    rolesList?:any[],
    priorityList?:any[],
    ticketStatusList?:any[],
    statusObj?:any
}