import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { useEffect, useState } from 'react';
import CustomFormLabel from '../../common/form-label/CustomFormLabel ';
import { iWebFormField } from '../schema';

export const WebCheckBox = ({fieldConfig,value,editable,disabled=false}: {fieldConfig:iWebFormField,value?:any,editable?:boolean,disabled?:boolean}) => {
    // const { fieldLabel = "",name="", placeholder = "Please Enter", isMandatory=false,fieldsOptions="Hi|Hello" } = props?.fieldConfig;
    const {fieldLabel,fieldOptions="",fieldDescription,isMandatory,id} = fieldConfig;
    const [menuOptions,setOptions] = useState<string[]>([]);
    useEffect(()=>{
        console.log('WebCheckBox_values',fieldConfig,fieldOptions)
        if(fieldOptions && fieldOptions !== ""){
            let vals = fieldOptions.split('|')
            setOptions(vals)
        }
    },[fieldOptions])
        return (
        <div>
            <CustomFormLabel
                labelName={fieldLabel}
                isMandotary={isMandatory}
            />
            {(fieldDescription && fieldDescription.trim() !== "") && <FormHelperText className="helper-text-webform">{fieldDescription}</FormHelperText>}
            <FormGroup className={`webForm-checkboxholder ${editable ? '' : 'readonly-checkbox'}`}>
                {menuOptions.length === 0 && 
                    <FormControlLabel 
                        name={`${id}`}
                        control={<Checkbox name=''  />} 
                        label={"Add Options..."} 
                        disabled
                    />
                }
                {menuOptions.map((i:any,index:number)=>{
                    const trimmedOption = i.trim(); 
                    const isChecked = value?.includes(trimmedOption)
                    return <FormControlLabel 
                        key={index} 
                        control={<Checkbox 
                                    value={trimmedOption} 
                                    name={`checkBox_${id}_${trimmedOption}`} 
                                    size="small" defaultChecked={isChecked}
                                />} 
                        label={i} />
                })}
            </FormGroup>
        </div>
    )
}