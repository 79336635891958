import axios, { AxiosResponse } from "axios";
import configs from "../../configs/config";
import { ActionType, snackbar } from "../../redux/actions/Snackbars";
import intl from "react-intl-universal";
import ticketStatusAdd from "../../redux/actions/SystemManagement/TicketStatus";
import { url as DivisionUrl } from "./Division";
import { url as LocationUrl } from "./Location";//DivisionUrl,LocationUrl
import { formInfoData } from "../../redux/actions/TicketsManagement/TicketsManagement";
import LanguageData from "../../configs/LanguageData.json";
export const url = `${configs.BASE_URL}/ContactOrganisation`;
const deleteUrl = `${configs.BASE_URL}/ContactOrganisation/permanent/delete/`;
const bulkUpdate = `${configs.BASE_URL}/ContactOrganisation/status/change?ids=`;
const trackingUrl =`${configs.BASE_URL}/master/Organisation/status/by/name?name=`
let isCanceled = false;
//const source = axios.CancelToken.source();

export const getOrganizationTrackingStatus = async (orgName:any,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${trackingUrl}${orgName}`);
       if(callback){
           dispatch(callback(response.data))
       }
    } catch (error:any) {
      return console.log(error.response);
    }
  }
};
export const getSTatusOrg = (orgName:any,callback?:any) => {
    return async (dispatch: any) => {
      try {
        const response:any = await axios(`${trackingUrl}${orgName}`);
        // ;
        //dispatch(storeAllEmailTemplates(response.data));
        if(callback) callback(response.data)
      } catch (error:any) {
        return console.log(error.response);
      }
    };
  };
export const getOrganization = async () => {
  try {
    const tickets = await axios.get(url);
    console.log("api is called", tickets.data);
    return tickets.data;

  } catch (e) {
    console.log(e)
  }
}
export const OrganizationAdd = (data: any, callback: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(`${configs.BASE_URL}/ContactOrganisation`, data);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_NEW_ORGANISATION}${LanguageData.TICKET_CREATED}`,
          severity: "success",
          open: true,
        },
      });
      callback();
    } catch (error: any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response && typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const updateOrganization = (id:number,request:any,callback:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.put(`${url}/${id}`,request);
      // ;
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_ORGANISATION}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      });
      callback();
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
// export const updateOrganization = async (id: any, data: any, callback: any) => {
//   try {
//     const response = await axios.put(`${url}/${id}`, data);
//     console.log("api is called update", response, response.data);
//     return response.data;
//     callback();
//   }
//   catch (error: any) {
//     if (error.response) {
//       console.log("this is error of update", error.response.data)

//       return {
//         isError: true,
//         message: error.response.data
//       }
//     }

//   }
// }
export const singleOrganization = (id: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${url}/${id}`);
      if (callback) callback(response.data)
      dispatch({
        type: "singleAssetStatus",
        payload: response,
      });
    } catch (error) {
      return console.log(error);
    }
  };
};




export const multipalUpdateOrganization = async (data: any, ids: any, status: any) => {


  try {
    let result = await axios.delete(`${bulkUpdate}${ids}&status=${status}`, data);
    console.log("bulk delete", result)

    if (result.status == 200) {
      return true

    }
    else {
      return false
    }
  } catch (error: any) {
    if (error.response) {

      return error.response.data
    }
  }
};


export const softDeleteOrganization = async (id: any) => {
  {
    try {
      let result = await axios.delete(`${bulkUpdate}${id}`);

      if (result.status == 200) {
        return true

      }
      else {
        return false
      }
    } catch (error: any) {
      if (error.response) {

        return error.response.data
      }
    }
  };
}
export const changeOrganization = async (id: any, status: boolean) => {
  {
    try {
      let result = await axios.delete(`${configs.BASE_URL}/ContactOrganisation/status/change?ids=${id}&status=${status}`);

      if (result.status == 200) {
        return true

      }
      else {
        return false
      }
    } catch (error: any) {
      if (error.response) {

        return error.response.data
      }
    }
  };
}

export const deleteOrganization = async (id: any) => {
  {
    try {
      let s = await axios.delete(`${deleteUrl}${id}`);

      console.log("result after calling AssetStatusAdd", s)

      return s.data
    } catch (error: any) {
      if (error.response) {

        return error.response.data
      }
    }
  };
};

export const SnackBar = (msg: any, severity: string) => {
  return async (dispatch: any) => {

    dispatch({
      type: ActionType.SNACKBAR,
      payload: {
        message: msg,
        severity: severity,
        open: true,
      },
    });
  }
}

export const loading = (status: any) => {
  return {
    type: "loading",
    payload: status,
  };
};
export const getAllContactsInfo = (callback?: any) => {
  return async (dispatch: any) => {
    try {
      const allIds = [url, DivisionUrl, LocationUrl];
      Promise.all(allIds.map((endpoint: string, index: number) => axios.get(`${endpoint}`))).then(
        (all) => {
          console.log('allContactResponse', all)
          /*
            AllData.ticketSources = allResponse[2].data;
            tempAllInfo = {...tempAllInfo, ...formInfoData(AllData.ticketSources, [
                {name:'sources', vals:['name']},
                {name:'sourceTypes', vals:['id','name']},
              ])
            }
          */
          formInfoData()
          try {
            if (callback) callback("0",
              {
                ...formInfoData(all[0].data, [
                  { name: 'organisation', vals: ['name'] },
                  { name: 'organisationById', vals: ['', 'id'] }
                ]),
                ...formInfoData(all[0].data, [
                  { name: 'divisions', vals: ['name'] },
                  { name: 'divisionsById', vals: ['', 'id'] }
                ]),
                ...formInfoData(all[0].data, [
                  { name: 'locations', vals: ['name'] },
                  { name: 'locationsById', vals: ['', 'id'] }
                ])
              }
            );
          } catch (e) {
            console.log(e)
          }
        }
      );
    } catch (e) {
      console.log('get all data', e)
      if (callback) callback("1", e);
    }
  }
}
export default OrganizationAdd;