import { Box, Container, Typography } from '@mui/material';
import React from 'react'
import logoImg from "./../../../assest/logo/logo.png";
import { useNavigate } from 'react-router-dom';

function PricingBanner() {
    const navigate = useNavigate();
  return (
    <Box className="pricingBanner bg-theme-secondary">
              <Container maxWidth="lg">
                <Box className="pricingTopBar">
                  <Box>
                      <img
                          src={logoImg}
                          alt=""
                          height={66}
                          width={"auto"}
                          onClick={() => {
                            navigate("/");
                          }}
                      />
                  </Box>
                  <Box>
                    <Typography variant="h4">Let's get started</Typography>
                    <Typography>Servo charge your support function, no Credit Card required!</Typography>
                  </Box>
                </Box>
              </Container>
            </Box>
  )
}

export default PricingBanner
