import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { updateCheckBoxes } from "../../../../redux/actions/userManagement/role";
import { Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { IState } from "../../../../redux/reducers/rootReducers";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const PermissionsFormGroup = memo(({...rest}:any) => {
    const {permissions,titleKey,checkedItems} = rest;
    const dispatch = useDispatch();
    const changeHandler = useCallback((val:number,checked:boolean) =>{
        //console.log(e.target)
        dispatch(updateCheckBoxes(titleKey,val, checked ? "edit" : "add"))
    },[])
    const layout = useMemo(()=><>
        <FormGroup aria-label="position" row>
            {permissions.map((item: any, ind: number) => {
                const {id,name} = item;
                const checked = checkedItems && checkedItems?.[titleKey]?.includes && checkedItems?.[titleKey]?.includes(id) ? true : false;
                return (
                    <div key={ind} className={`flx-20'}`}>
                        <Typography className={`radio-label`} onClick={()=>{changeHandler(id,checked)}}>
                            {checked ? <CheckBoxIcon /> :  <CheckBoxOutlineBlankIcon /> }
                            <span>{name}</span>
                        </Typography> &nbsp;&nbsp;
                    </div>
                )
            })
            }
        </FormGroup>
    </>,[checkedItems?.[titleKey]?.length])
    return (<>
        {layout}
    </>)
})
//export default PermissionsFormGroup;//connect(mapStateToProps)(PermissionsFormGroup);
const mapStateToProps = (state:IState) => {
    return ({
        checkedItems : state?.roleReducer?.checkedStatus,
      //const checkBoxItems = useSelector((state: IState) => state?.roleReducer?.checkedStatus);
    })
};
export default connect(mapStateToProps)(PermissionsFormGroup);