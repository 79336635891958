import axios from "axios";
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";

export const url = `${configs.BASE_URL}/Permissions`;
export const permissionListUrl = `${configs.BASE_URL}/Permissions`;
const deleteUrl = `${configs.BASE_URL}/Priority/permanent/delete/`;
const changeStatus = `${configs.BASE_URL}/Priority/status/change?ids=`;
const bulkDelete =  `${configs.BASE_URL}/Priority//permanent/delete?ids=`

export const storePermissions = (val: any) => ({
  type: 'getPermission',
  payload: val,
});

const addPermissions = (data: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: "addPermissions",
        payload: response,
      });
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "addPermissions Created Successfully",
          severity: "success",
          open: true,
        },
      });
    } catch (error) {
      return console.log(error);
    }
  };
};

export const getPermission = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios(url);
      dispatch(loading(false));
      dispatch(storePermissions(response));
    } catch (error) {
      return console.log(error);
    }
  };
};

export const singlePermission = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${url}/${id}`);
      dispatch({
        type: "singlePermission",
        payload: response,
      });
    } catch (error) {
      return console.log(error);
    }
  };
};



export const loading = (status: any) => {
  return {
    type: "loading",
    payload: status,
  };
};

export default addPermissions;
