import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";

export async function exportMultipleChartsToPdf(callback:any) {
  const doc = new jsPDF("p", "px",'A4',true);
  const elements = document.getElementsByClassName("custom-chart");

  await createPdf({ doc, elements });
  doc.setDisplayMode('fullheight','continuous','UseOutlines')
  doc.save(`report.pdf`);
  if(callback) callback();
}

async function createPdf({
  doc,
  elements,
}: {
  doc: jsPDF;
  elements: HTMLCollectionOf<Element>;
}) {
  const padding = 0;
  const marginTop = 0;
  let currentPage = 1;
  let currentY = 0;

  const pageWidth: any = doc.internal.pageSize.getWidth();
  const pageHeight: any = doc.internal.pageSize.getHeight();

  for (let i = 0; i < elements.length; i++) {
    const el = elements.item(0) as HTMLElement;
    const imgData = await htmlToImage.toJpeg(el, { quality: 2 });

    let elHeight = el.offsetHeight;
    let elWidth = el.offsetWidth;
    const remainingSpace = pageHeight - currentY;

    if (elWidth > pageWidth || elHeight > pageHeight) {
      // If the image is too large for the page, resize it
      const widthRatio = pageWidth / elWidth;
      const heightRatio = pageHeight / elHeight;
      const resizeRatio = Math.min(widthRatio, heightRatio);
      elWidth *= resizeRatio;
      elHeight *= resizeRatio;
    }


    // if (i > 0 && elHeight > remainingSpace) {
    //   doc.addPage();
    //   // currentPage++;
    // }

    doc.addImage(imgData, "JPEG", padding, marginTop, elWidth, elHeight);

    // currentY += elHeight;
  }
}