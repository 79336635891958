import Highcharts from "highcharts";

export const lineChartOptions: Highcharts.Options = {
  credits:{
    enabled:false,
  },
  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'middle',
    itemMarginTop: 10,
    itemMarginBottom: 10
  },
  title: {
    text: "Top 5 Incidents",
  },
  xAxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    crosshair: true,
  },
  series: [
    {
      type: "line",
      data: [1, 2, 3, 8, 4, 7],
    },
    {
      type: "line",
      data: [5, 7, 6, 9, 5, 4],
    },
  ],
};

export const barChartOptions: Highcharts.Options = {
  credits:{
    enabled:false,
  },
  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'middle',
    itemMarginTop: 10,
    itemMarginBottom: 10
  },
  chart: {
  height: 250,
  type: "bar",
  },
  title: {
    text: "Top 5 Categories",
  },
  series: [
    {
      name: "Year 1800",
      type: "column",
      data: [107, 31, 635, 203, 2],
    },
    {
      name: "Year 1900",
      type: "column",
      data: [133, 156, 947, 408, 6],
    },
    {
      name: "Year 2000",
      type: "column",
      data: [814, 841, 3714, 727, 31],
    },
    {
      name: "Year 2016",
      type: "column",
      data: [1216, 1001, 4436, 738, 40],
    },
  ],
};

export const columnChartOptions: Highcharts.Options = {
  credits:{
    enabled:false,
  },
  legend: {
    itemStyle: {
      font: '6pt Trebuchet MS, Verdana, sans-serif',
      color: '#A0A0A0'
   },
  //  itemHoverStyle: {
  //     color: '#000'
  //  },
  //  itemHiddenStyle: {
  //     color: '#444'
  //  },
    // layout: 'vertical',
    // align: 'right',
    // verticalAlign: 'middle',
    // itemMarginTop: 10,
    // itemMarginBottom: 10
    align: 'center',
    verticalAlign: 'bottom',
    x: 0,
    y: 0,
    symbolHeight: 7
  },
  chart: {
    type: "column",
    height: 300,
  },
  title: {
    text: "",
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  series: [],
};

export const lineChartOptionsData = (AllInfo:any={},tickets:any=[]) =>{
    const chartOptions : any = {...lineChartOptions};
    const xAxis = [];
    const data:any = {};
    tickets.forEach((i:any)=>{
      console.log(i)
      if(!i?.ticketLogs?.ticketType) return;
      if(!data[i.ticketLogs.ticketType]){
        data[i.ticketLogs.ticketType] = 0;
      }
    })
    console.log(chartOptions.xAxis.categories)
    return chartOptions;
}
export const barChartOptionsData = (AllInfo:any={},tickets:any=[]) =>{
  const chartOptions : any = {...barChartOptions};
    const xAxis = [];
    const data:any = {};
    tickets.forEach((i:any)=>{
      console.log(i)
      if(!i?.ticketLogs?.ticketType) return;
      if(!data[i.ticketLogs.ticketType]){
        data[i.ticketLogs.ticketType] = 0;
      }
    })
  console.log(chartOptions.series)
  return chartOptions;
}
  export const columnChartOptionsData = (Info:string='',tickets:any=[],key:string='ticketType',infoVal:any={}) =>{
  const chartOptions : any = JSON.parse(JSON.stringify(columnChartOptions));
    const xAxis:any = [];
    const data:any = {};
    tickets.forEach((i:any)=>{
      //console.log(i)
      if(!i?.ticketLogs?.[key]) return;
      if(!data[i.ticketLogs[key]]){
        data[i.ticketLogs[key]] = 0;
      }
      data[i.ticketLogs[key]] += 1;
      if(!(xAxis.includes(i.ticketLogs[key])) && i.ticketLogs[key] !== 0){
        xAxis.push(i.ticketLogs[key]);
      }
    });
    chartOptions.xAxis.categories = [];
    chartOptions.series = [];
    chartOptions.title.text = Info; 
    xAxis.map((i:any)=>{
        if(infoVal && infoVal?.[`${i}`]){
          //chartOptions.xAxis.categories.push(infoVal[`${i}`]);
          chartOptions.series.push({
            name: `${infoVal[`${i}`]} (${data[i]})`,
            type: "column",
            data: [data[i]],
          })
        }
    });
    chartOptions.xAxis.categories.push(Info);
    
  console.log(xAxis, data,columnChartOptions, chartOptions)
  return chartOptions;
}