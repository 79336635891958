import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
interface ICommonRadioGroupOption{
    label:string;
    value:string;
}
interface ICommonRadioGroup{
    id:string;
    title?:string;
    labelPlacement?: "top" | "start" | "bottom" | "end";
    name:string;
    options : ICommonRadioGroupOption[] | string[];
    defaultValue:string;
    isRow?:boolean;
    subHeading?:string;
}
export default function CommonRadioGroup(props:ICommonRadioGroup) {
    const {id,title,labelPlacement="end",name,options,defaultValue,isRow=false,subHeading } =props;
  return (
    <FormControl className='mobileRadioBox'>
      {title && <FormLabel id={id}>{title}</FormLabel>}
      {subHeading && <FormLabel className='sub-heading' id={id}>{subHeading}</FormLabel>}
      <RadioGroup
        row={isRow}
        aria-labelledby={id}
        name={name}
        defaultValue={defaultValue}
        className='mobileRadioBox-list'
      >
            {options.map((i:ICommonRadioGroupOption | string,index:number)=>{
                const isString = typeof i === "string";
                return(
                    <React.Fragment key={index}>
                        <FormControlLabel
                            value={isString ? i : i.value}
                            control={<Radio />}
                            label={isString ? i : i.label}
                            labelPlacement={labelPlacement}
                        />
                    </React.Fragment>
                )
            })}
      </RadioGroup>
    </FormControl>
  );
}
