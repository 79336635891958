import { ITicketType, IActionTicketTypes, TicketTypes } from "../../actions/SystemManagement/TicketTypes"

function createData(
    id: number,
    name: string,
    displayName: string,
    isActive:boolean
): ITicketType {
    return {
        id,
        name,
        displayName,
        isActive,
    };
}

const rows = [
    createData(1, 'Complaint', 'Serial complaints',true),
    createData(2, 'Fault', 'Mechanical faults',false),
    createData(3, 'Quote', 'Mainanance quote',true),

];
export interface IAddTypeState {
    ticketTypes: Array<ITicketType>
}
const initState: IAddTypeState = {
    ticketTypes: rows
}

const AddTicketType = (state: IAddTypeState = initState, action: IActionTicketTypes) => {
    switch (action.type) {
        case TicketTypes.ADD_TICKET_TYPE:
            return {
                ...state,
                ticketTypes: action.payload
            }
        default:
            return state
    }
}

export default AddTicketType;