import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

function Loading() {
  return (
    <CircularProgress
      disableShrink
      style={{ marginLeft: "48%", marginTop: "3%" }}
    />
  );
}

export default Loading;
