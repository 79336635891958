const initialState = {
    assetTypeList: [],
  }
  const AssetTypesReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case 'STORE_ASSETTYPES':
            return {
                ...state,
                assetTypeList: action.payload,
            };
        default:
            return state;
    }
  }
  
  export default AssetTypesReducer;
  
  