import * as React from "react";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import CustomSelect from "../../../components/common/customSelect/CustomSelect";
import { Grid } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AsyncDropDown from "../../new-ticket/LatestTicket/AsyncDrodown/AsyncDropDown";
import axios from "axios";
import configs from "../../../configs/config";
import { useDispatch } from "react-redux";
import { ActionType } from "../../../redux/actions/dashboard";
export default function FilterEmail({
  editData,
  filterEmailData,
  setFilterEmailData,
  formData,
}: {
  editData: any;
  filterEmailData: any;
  setFilterEmailData: any;
  formData: any;
}) {
  console.log("editData?.circulationListCC", filterEmailData);
  const dispatch = useDispatch();
  const promiseOptions = (inputValue: any, callback: any) => {
    if (inputValue.length > 2) {
      axios
        .get(
          `${configs.BASE_URL}/Contacts?search=${inputValue}&page=1&count=50&isActive=true`
        )
        .then((response: any) => {
          const data = response.data;
          console.error("options", data);
          const options = data?.map((item: any) => ({
            label: item.email,
            name: item.name,
            value: item.name,
            id: item.id,
            knownAs: item.knownAs,
            userName: item.userName,
            jobTitle: item.jobTitle,
            email: item.email,
            whatsAppNumber: item.whatsAppNumber,
            company: item.companyName,
            place: item.officeLocation,
            no: item.mobilePhone,
            isVIP: item.isVIP,
            alert: item.alert,
            profilePictureId: item.profilePictureId,
          }));
          callback(options); // Pass options to the callback
        })
        .catch((error: any) => {
          console.error("Error fetching data:", error);
          callback([]); // Pass options to the callback
          dispatch({
            type: ActionType.SNACKBAR,
            payload: {
              message: `Server Error! Log out and try again`,
              severity: "error",
              open: true,
            },
          });
        });
    } else {
      callback([]); // Pass options to the callback
    }
  };
  React.useEffect(() => {
    if (editData && formData) {
      const splitCC = editData?.circulationListCC?.split(";");
      const splitBCC = editData?.circulationListBCC?.split(";");
      const splitTO = editData?.circulationListTO?.split(";");
      // console.log('editData?.circulationListCC', editData)
      let temp: any = [];
      splitCC?.filter((ele: any) => {
        let a: any = {
          label: ele,
          name: ele,
          value: ele,
          id: ele,
          color: "red",
        };
        temp.push(a);
      });

      let temp1: any = [];
      splitBCC?.filter((ele: any) => {
        let a: any = {
          label: ele,
          name: ele,
          value: ele,
          id: ele,
          color: "red",
        };
        temp1.push(a);
      });

      let temp2: any = [];
      splitTO?.filter((ele: any) => {
        let a: any = {
          label: ele,
          name: ele,
          value: ele,
          id: ele,
        };
        temp2.push(a);
      });

      console.log("filtyerEmail check", editData);
      let temp3: any = [];

      formData?.contactList?.filter((item: any) => {
        if (item.email !== null) {
          let a: any = {
            label: item?.email,
            name: item?.email,
            value: item?.email,
            id: item?.email,
          };
          temp3.push(a);
        }
      });
      setFilterEmailData({
        cc: [...temp, ...temp3],
        selectedCC: [...temp],
        selectedBCC: [...temp1],
        selectedTo: [...temp2],
        bcc: [...temp1, ...temp3],
        to: [...temp2, ...temp3],
      });
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        alignContent: "flex-start",
        textAlign: "left",
      }}
    >
      <Grid
        container
        columnSpacing={5}
        rowSpacing={2}
        columns={{ xs: 1, sm: 1, md: 2 }}
      >
        <Grid item xs={1} sm={1} md={2} key={1}>
          <span style={{ fontSize: "14px" }}>TO</span>

          <AsyncDropDown
          height="auto"
            promiseOptions={promiseOptions}
            name="primaryContact"
            placeHolder="Select a Primary contact"
            handleChange={(e: any) => {
              setFilterEmailData({ ...filterEmailData, selectedTo: [...e] });
            }}
            defaultValue={filterEmailData.selectedTo}
            isMulti={true}
          />

          {/* {filterEmailData.selectedTo?.map((item: any) => {
            return (
              <>
                <div style={{ marginTop: "10px", display: "flex", gap: 10 }}>
                  <CheckCircleIcon sx={{ color: "green" }} />
                  <span style={{ fontSize: "14px" }}>{item.label}</span>
                </div>
              </>
            );
          })} */}
        </Grid>
        <Grid item xs={1} sm={1} md={2} key={1}>
          <span style={{ fontSize: "14px" }}>CC</span>
          <AsyncDropDown
            promiseOptions={promiseOptions}
            name="primaryContact"
            placeHolder="Select a Secondary contact"
            handleChange={(e: any) => {
              setFilterEmailData({ ...filterEmailData, selectedCC: [...e] });
            }}
            defaultValue={filterEmailData.selectedCC}
            isMulti={true}
          />

          {/* {filterEmailData.selectedCC?.map((item: any) => {
            return (
              <>
                <div style={{ marginTop: "10px", display: "flex", gap: 10 }}>
                  <CheckCircleIcon sx={{ color: "green" }} />
                  <span style={{ fontSize: "14px" }}>{item.label}</span>
                </div>
              </>
            );
          })} */}
        </Grid>

        <Grid item xs={1} sm={1} md={2} key={1}>
          <span style={{ fontSize: "14px" }}>BCC</span>

          <AsyncDropDown
            promiseOptions={promiseOptions}
            name="primaryContact"
            placeHolder="Enter email id"
            handleChange={(e: any) => {
              setFilterEmailData({ ...filterEmailData, selectedBCC: [...e] });
            }}
            defaultValue={filterEmailData.selectedBCC}
            isMulti={true}
          />

          {/* {filterEmailData.selectedBCC?.map((item: any) => {
            return (
              <>
                <div style={{ marginTop: "10px", display: "flex", gap: 10 }}>
                  <CheckCircleIcon sx={{ color: "green" }} />
                  <span style={{ fontSize: "14px" }}>{item.label}</span>
                </div>
              </>
            );
          })} */}
        </Grid>
      </Grid>
    </Box>
  );
}
