import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useSelector } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';
interface IRadialBarChartProps {
  value:number;
  height?:number;
  color?:string;
  trackColor?:string;
  title?:string
}

const RadialBarChart: React.FC<IRadialBarChartProps> = ({title="",value=0,height=130, color="#5d5be5", trackColor="#dddddd"}:IRadialBarChartProps) => {
  const toggleTheme = useSelector((state: IState) => state.theme.theme);
  const [options,setOptions] = useState<ApexOptions>({
    series:[value],
    chart: {
      type: 'radialBar',
    },
    theme: {
      mode: toggleTheme ? 'dark' : 'light', // Enables dark mode
    },
    plotOptions: {
      radialBar: {
        hollow:{
          size  : "60%"
        },
        track:{
           background:trackColor,
           strokeWidth:"100%"
        },
        dataLabels: {
          value: {
            fontSize: '20px',
            color: undefined,
            offsetY:0
          },
          total: {
            show: true,
            label: "",
            formatter: (w) => {
              return w.globals.seriesTotals.reduce((a:any, b:any) => a + b, 0) + "%"; // Example: Summing up all series values and appending "%"
            }
          },
        },
      },
    },
    stroke: {
      lineCap: 'round',
    },
    title:{
      text: title,
      align: "center",
      style:{
        fontWeight : 400,
        fontFamily : "inherit",
      }
    },
    colors: [color],
    labels: ['Percent'],
  })
  useEffect(()=>{
    setOptions({
      ...options,
      series : [value],
      theme: {
            mode: toggleTheme ? 'dark' : 'light', // Enables dark mode
      },
    })
  },[value])
  useEffect(()=>{
    setOptions({
      ...options,
      theme: {
            mode: toggleTheme ? 'dark' : 'light', // Enables dark mode
      },
    })
  },[toggleTheme])
  return (
    <div>
      <Chart
        options={options}
        series={options.series}
        type="radialBar"
        height={height}
      />
    </div>
  );
};

export default RadialBarChart;
