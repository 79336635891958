import { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { isError } from "lodash";
import { makeStyles } from "@mui/styles";
import moment from "moment";



const useStyles = makeStyles(() => ({
    wrapper:{
      "& p": {
        marginLeft:0,
       },
       width:"auto !important",
       height:"auto",
      //  backgroundColor:"white",
       "& fieldset, input":{
        height:"auto"
      },
      
       "& .MuiInputBase-root.Mui-disabled": {
        WebkitTextFillColor: "black",
        backgroundColor:"#EAEAEA",
        cursor:"not-allowed",
  
      },
      "& .MuiInputBase-root":{
        fontSize:"14px"
      },
      "& .Mui-error":{
        color:"red",
        opacity:1,
        "& svg":{
          color:"red !important"
        }
      },
      
    },
    wrapper1:{
      "& p": {
        marginLeft:0,
       },
       width:"200px !important",

       "& fieldset, input":{
        height:"10px",
        border:"none",
        fontSize:"13px !important",
        fontWeight:900
            },
      
       "& .MuiInputBase-root.Mui-disabled": {
        WebkitTextFillColor: "black",
        backgroundColor:"#EAEAEA",
        cursor:"not-allowed",
  
      },
      "& .MuiInputBase-root":{
        fontSize:"14px",
        border:"none"
      },
      "& .Mui-error":{
        color:"red",
        opacity:1,
        "& svg":{
          color:"red !important"
        }
      },
      
    }
  }))
  
  

export default function CustomDatePicker(
  {
  value,
  onHandleChange,
  isError,
  helperText,
  name,
  isDisabled,
  maxDate,
  minDate,
  classeNames=""

}: {
  value: any;
  onHandleChange: any;
  isError: any;
  helperText: any,
  name: any,
  isDisabled: any,
  maxDate?: any,
  minDate?: any
  classeNames?:any

}
) {
  const [local, setLocal] = useState<any>("");
  const classes = useStyles();

  useEffect(() => {
    if (localStorage.getItem("lang")) {
      setLocal(localStorage.getItem("lang"));
    }
  }, [localStorage.getItem("lang")]);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={local}>

   <div className={`${classeNames} ${classes.wrapper}`} >
            <DateTimePicker 
            // sx={{width:"100%"}}
            maxDate={maxDate}
            minDate={minDate}
            disabled={isDisabled}
              onChange={(e: any) => onHandleChange(e)}
              value={value}
              renderInput={(params) => (
                <TextField
                  name={name}
                  InputLabelProps={{shrink: false}}
                  fullWidth
                  {...params}
                  placeholder="dd/mm/yy • hh:mm"
                  className="date-picker-input"
                  // sx={{input:"red", svg:"red"}}
                  error={isError}
                  autoComplete='off'
                  helperText={helperText}
                />
              )}
            />
                  </div>

    </LocalizationProvider>
  );
}
export function CustomDatePickerWithoutBorder(
  {
  value,
  onHandleChange,
  isError,
  helperText,
  name,
  isDisabled

}: {
  value: any;
  onHandleChange: any;
  isError: any;
  helperText: any,
  name: any,
  isDisabled: any

}
) {
  const [local, setLocal] = useState<any>("");
  const classes = useStyles();

  useEffect(() => {
    if (localStorage.getItem("lang")) {
      setLocal(localStorage.getItem("lang"));
    }
  }, [localStorage.getItem("lang")]);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={local}>

   <div className={classes.wrapper1} >
            <DateTimePicker
          views={['year', 'month', 'day']}

            disabled={isDisabled}
              onChange={(e: any) => onHandleChange(e)}
              value={value}
              renderInput={(params) => (
                <TextField
               
                  name={name}
                  InputLabelProps={{shrink: false}}
                  fullWidth
                  {...params}
                  // sx={{input:"red", svg:"red"}}
                  error={isError}
                  helperText={helperText}
                />
              )}
            />
                  </div>

    </LocalizationProvider>
  );
}
