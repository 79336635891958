import { Box, Skeleton } from "@mui/material";

export const WhatsappSkeleton = () => {
    return (
        <>
        <Box className="p-1">
        <Skeleton width="200px"
                variant="rounded"
                height={"15px"}
                animation="wave"
        ></Skeleton>
        <Box sx={{ display: "flex",margin:"5px 0" , paddingBottom:"15px", gap: "15px" }}>
            <Skeleton width="100%"
                variant="rounded"
                height={"65px"}
                animation="wave"
            ></Skeleton>
        </Box>
        <Skeleton width="120px"
                variant="rounded"
                height={"15px"}
                animation="wave"
        ></Skeleton>
        

        <Box sx={{ display: "flex", justifyContent:"center", paddingBottom:"15px", gap: "15px" }}>
            
            <Skeleton width="100px"
                variant="rounded"
                height={"36px"}
                animation="wave"
            ></Skeleton>
            <Skeleton width="100px"
                variant="rounded"
                height={"36px"}
                animation="wave"
            ></Skeleton>
        </Box>
        </Box>
        </>
    )
}
export default WhatsappSkeleton;