import { Box, Grid, Tooltip, Typography } from "@mui/material"
import AddNewPart from "../../../components/common/AddNew"
import SideNavbarForAdmin from "../../../components/navbar/admin-navbar/side-navbar/SideNavBar"
import TopNavBarForAdmin from "../../../components/navbar/admin-navbar/top-navbar/TopNavBar"
import MobileMenu from "../../../components/navbar/sideNavbar/MobileMenu"
import TopFilter from "../../../components/common2/TopFilter"
import { useEffect, useState } from "react"
import Card from "../../../components/common/card";
import editIcon from "../../../assest/icons/edit.svg";
import restoreIcon from "../../../assest/icons/restore.svg";
import deleteIcon from "../../../assest/icons/delete.svg";
import CommonStyles from "../../../components/common/CommonStyles"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { IState } from "../../../redux/reducers/rootReducers"
import { getTempletesSsp, hardDeleteEmailTemplatesSsp } from "../../../redux/actions/SelfServicePortal/ContactAid/EmailTempletesSsp/EmailTempletesSsp"
import Breadcrumbs from "../../../components/common2/Breadcrumbs";

const EmailTemplateSsp = () => {
    const [group, setGroup] = useState([]);
    const [searchData, setSearchData] = useState("");
    const [listStatus, setlistStatus] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const TemplatesListSsp = useSelector(
        (state: IState) => state?.EmailTemplatesSspReducer?.AllEmailTemplatesSsp
    );
    const [records, setRecords] = useState([])
    const handleSearch = (e: any) => {
        let lowerCase = e.target.value;

        setSearchData(lowerCase);
    };
    const filterHandler = (e: any) => { };
    const checkBoxHandler = (e: any, id: any) => { };
    const showDataHandler = (show: any) => {
        setlistStatus(show);
    };
    const updateAllHandler = (value: any, id: any) => { };
    const [update, setUpdate] = useState(false);
    const classes = CommonStyles();
    const hardDelete = (key: number) => {
        const deleteItem: any = records[key];
        setRecords(records.filter((x: any) => x.id !== key));
        dispatch(hardDeleteEmailTemplatesSsp(deleteItem.id));
    };
    const EditHandler = (id: string) => {
        navigate(`/AddEmailTempleteSsp/${id}`);
    };
    useEffect(() => {
        dispatch(getTempletesSsp());
    }, []);
    useEffect(() => {
        console.log("pop", TemplatesListSsp);
        setRecords(TemplatesListSsp);
    }, [TemplatesListSsp]);

    return (
        <>
            
            <div>
            <Breadcrumbs
                data={[
                    {
                        title: 'Home',
                        path: '/adminPanel'
                    },
                    {
                        title: 'EmailTemplates ',
                        path: '/EmailTempleteSsp'
                    }
                ]
                }
            />
            </div>
            <div>
                <Typography variant="h6" className={`${classes.themeColorTxt}`}>
                    Email Templates
                </Typography>
            </div>

            <Box className="pageHolder">
                <div>
                    <Grid container className="pt-1" spacing={1}>
                        <div style={{ width: "100%" }}>
                            <TopFilter
                                path="/AddEmailTempleteSsp"
                                Data={group}
                                SearchHandler={handleSearch}
                                value={searchData}
                                filterHandler={filterHandler}
                                checkBoxHandler={checkBoxHandler}
                                checked={false}
                                hideSelectAllOption={true}
                                hideActiveBtns={true}
                                filterItems={showDataHandler}
                                updateAllHandler={updateAllHandler}
                                titleState={update}
                                placeHolder="Search ..."
                            />
                        </div>
                        <Grid container className="pt-1" spacing={1}>

                            {records.length > 0 ? (
                                <>
                                    {records.map((item: any, key: number) => {
                                        const { id, name, subject, body } = item;
                                        return (
                                            <Grid item md={3} xs={12} key={key}>
                                                <Card
                                                    styleProp={classes.cardStyle}
                                                    customHeader={
                                                        <Box
                                                            className={`${classes.row} ${classes.alignItemsCenter} ${classes.justifyBetween} ${classes.w100}`}
                                                        >
                                                            <Typography variant="body2" className={classes.title}>
                                                                {name}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    cardBody={
                                                        <Box>
                                                            <Typography variant="body2">
                                                                <b>Subject :</b>
                                                            </Typography>
                                                            <Typography variant="body1">{subject}</Typography>
                                                        </Box>
                                                    }
                                                    cardFooter={
                                                        <Box>
                                                            {(
                                                                <Tooltip title="Edit Email Template">
                                                                    <img
                                                                        src={editIcon}
                                                                        alt="edit icons"
                                                                        onClick={() => {
                                                                            EditHandler(id);
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                            {
                                                                <Tooltip
                                                                    title={`${listStatus
                                                                        ? "Delete Email Template"
                                                                        : "Restore Email Template"
                                                                        }`}
                                                                >
                                                                    <img
                                                                        src={listStatus ? deleteIcon : restoreIcon}
                                                                        alt="delete icons"
                                                                        onClick={() => {
                                                                            hardDelete(key);
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            }
                                                            {!listStatus && (
                                                                <Tooltip title="Permanent Delete">
                                                                    <img
                                                                        src={deleteIcon}
                                                                        alt="delete icons"
                                                                        onClick={() => {
                                                                            hardDelete(key);
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                        </Box>
                                                    }
                                                />
                                            </Grid>
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    <AddNewPart title="Add New" path="/AddEmailTempleteSsp" />
                                </>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </Box >
        </>
    )
}
export default EmailTemplateSsp