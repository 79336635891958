import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IState } from "../../redux/reducers/rootReducers";
import CommonStyles from "../common/CommonStyles";
import { Link } from "react-router-dom";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Home from "./../../assest/icons/menu/home.svg";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import { DoLogOutAction } from "../../redux/actions/Login/Login";
import { storeAllResponses } from "../../redux/actions/TicketsManagement/TicketsManagement";
import { theme } from "../../redux/actions/Theme/Theme";
import logoImg from "./../../assest/icons/logo.png";
import ConfirmationNumberOutlined from "@mui/icons-material/ConfirmationNumberOutlined";
import ApprovalIcon from "@mui/icons-material/Approval";
import { useMsal } from "@azure/msal-react";
const drawerWidth = 200;

const SideNavbarSSP: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = CommonStyles();
  const currAgent = useSelector(
    (state: IState) => state?.agentReducer?.loggedInAgent
  );
  const defaultService:any = useSelector((state: IState) => state?.ContactsReducer?.DefaultService);
  function isActive(pathname: string) {
    return false;
  }
  const { instance } = useMsal();
  function LogOut() {
    if(instance.logout) instance.logout();
    dispatch(theme(false));
    localStorage.clear();
    dispatch(DoLogOutAction());
    dispatch(storeAllResponses({}));
    navigate("/");
  }
  const closeMenu = () => {
    const bodyTag = document.getElementsByTagName("body");
    bodyTag[0].classList.remove("menuOpened");
  };
  return (
    <Drawer
      variant="permanent"
      className={`${classes.mainSideHolder} sidebarHolder`}
    >
      <div className={classes.mainSideBar}>
        <div>
          <Link to="/" className="sidebarLogo">
            <img src={logoImg} alt="" />
            <span></span>
          </Link>
        </div>
        <Divider />
        <List>
          <Link to="/home">
            <ListItem className={[isActive("/") ? "active" : ""].join(" ")}>
              <ListItemIcon id="homeIcon">
                <img src={Home} alt="" />
                <span>Home</span>
              </ListItemIcon>
            </ListItem>
          </Link>
          <Link to={defaultService?.id ? `/viewService?id=${defaultService.id}` :"/"}>
            <ListItem
              className={[isActive("#/CMDB") ? "active" : ""].join(" ")}
            >
              <ListItemIcon id="newTicIcon">
                <ConfirmationNumberOutlined />
                <span>
                  New <br /> Ticket
                </span>
              </ListItemIcon>
            </ListItem>
          </Link>
          <Link to="/MyTaskApproval">
            <ListItem
              className={[isActive("#/MyTaskApproval") ? "active" : ""].join(" ")}
            >
              <ListItemIcon id="newTicIcon">
                <ApprovalIcon />
                <span>
                  My <br /> Approvals
                </span>
              </ListItemIcon>
            </ListItem>
          </Link>
        </List>
        <Divider />
        <Box className={`md-none ${classes.sideLogoutBtn}`} onClick={LogOut}>
          <LogoutOutlinedIcon />
          <span>logout</span>
        </Box>
      </div>
      <span onClick={closeMenu} className={classes.menuClose}></span>
    </Drawer>
  );
};

export default SideNavbarSSP;
