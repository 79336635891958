const initialState = {
  AllAssets: [],
  AllAssetsTickets: [],
  isAssetSaved:'',
}
// export const GET_ASSETS = 'GET_ASSETS';
// export const ADD_ASSETS = 'ADD_ASSETS';
// export const GET_ASSETS_TICKETS = 'GET_ASSETS_TICKETS';
const AssetsReducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case 'GET_ASSETS':
          return {
              ...state,
              AllAssets: action.payload,
          };
      case 'GET_ASSETS_SSP':
          return {
              ...state,
              AllAssets: action.payload,
          };
      case 'ADD_ASSETS':
          return {
              ...state,
              isAssetSaved: action.payload,
          };
      case 'GET_ASSETS_TICKETS':
        return {
          ...state,
          AllAssetsTickets: action.payload,
        };
      case 'LOAD_MORE_ASSETS':
        return {
          ...state,
          AllAssets: [...state.AllAssets, ...action.payload],
        };
      default:
          return state;
  }
}

export default AssetsReducer;
