const initialState = {
    licenseList: [],
    getCoreProducts: []
}
const LicenseReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case 'ADD_ORGANIZATION':
            return {
                ...state,
                licenseList: action.payload,
            };
        case 'GET_COREPRODUCTS':
            return {
                ...state,
                getCoreProducts: action.payload,
            };
        default:
            return state;
    }
}
  
export default LicenseReducer;
  