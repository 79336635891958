import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../../../redux/reducers/rootReducers";
import { lazy, memo, useCallback, useEffect, useMemo, useState } from "react";
import { storeFiltersValue } from "../../../../../redux/actions/TicketsManagement/GetTickets";
import { Box } from "@mui/material";
import { loadOtherTickets } from "../../../../../redux/actions/TicketsManagement/TicketsManagement";
import { createStyles, makeStyles } from "@mui/styles";
import TicketPriorityView from "./TicketPriorityView";
import TicketStatusView from "./TicketStatusView";
import TableExp from "../../TableExp";
import TableView from "./TableExp";
import { useNavigate } from "react-router-dom";
import Sortable from "sortablejs";
import CustomChip from "../../../../common/chip/CustomChip";
import Merge from "../../../../merge/popup/Merge";
import { filterInitialState } from "../../../../../redux/reducers/FiltersTicketManagement/FiltersTicketManagement";

// const TicketPriorityView = lazy(()=>import("./TicketPriorityView"));
// const TicketStatusView = lazy(()=>import("./TicketStatusView"));
// const TableExp = lazy(()=>import("../../TableExp"));

const TableViewWrapper = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let allGroups: any = document.getElementById(`ListView`);
    if (!allGroups) return;
    var sortable = Sortable.create(allGroups, {
      animation: 150,
      group: "list",
      onAdd: function (e: any) {
        let isMyTickets = e.item.getAttribute("data-mytickets");
        let ticketId = e.item.getAttribute("data-ticket-id");
        console.log("isMyTickets", isMyTickets);
        if (isMyTickets === "myTickets" || isMyTickets === "newTickets") {
          //alert('redirect')
          navigate(`/home/ticket/${ticketId}`);
          return;
        }
        e.clone.replaceWith(e.item);
      },
    });
  }, []);
  return (
    <>
      <div className="pos-rel">
        <div id={`ListView`} className="column-overlay"></div>
        <TableView />
      </div>
    </>
  );
};

export const ViewHolder = memo(() => {
  const LayoutType: any = useSelector(
    (state: IState) => state?.LayoutTicketManagementReducer?.layout
  );
  // const otherOptions = ()=>(<><NoTickets /><LoadMore /></>);
  const [isLoaded, setIsLoaded] = useState(false);
  const [viewVal, setViewVal] = useState(LayoutType ? LayoutType : "Priority");
  
  useEffect(() => {
    setIsLoaded(true);
  }, []);
  useEffect(() => {
    setViewVal(LayoutType);
    console.log("when clicked", LayoutType);
  }, [LayoutType]);
  const layoutsOptions = useMemo(
    () => (
      <>
        {isLoaded && (
          <>
            {viewVal === "Priority" && (
              <TicketPriorityView />
            )}
            {viewVal === "Status" && <TicketStatusView />}
            {viewVal === "List" && <TableViewWrapper />}
          </>
        )}
      </>
    ),
    [viewVal, isLoaded]
  );
  return (
    <Box>
      {layoutsOptions}
      {/* {otherOptions} */}
      <NoTickets />
      <LoadMore />
    </Box>
  );
});
const NoTickets = memo(() => {
  //const {AllTickets} = props;
  const AllTickets = useSelector(
    (state: IState) => state?.AllTicketsReducer?.otherTickets
  );
  return (
    <>
      {AllTickets?.length === 0 && (
        <Box className={"text-center No-Tickets"}>No Tickets Found</Box>
      )}
    </>
  );
});
const LoadMore = () => {
  const dispatch = useDispatch();
  const classes = loadMoreBtnStyles();
  const AllData = useSelector(
    (state: IState) => state?.TicketsReducer?.allResponses
  );
  const loading = useSelector((state: IState) => state?.LayoutTicketManagementReducer?.showLoader)
  const FiltersVal: any = useSelector(
    (state: IState) => state?.filtersTicketManagementReducer
  );
  const hasMoreTickets = useSelector(
    (state: IState) => state?.AllTicketsReducer?.hasOtherTickets
  );
  const pageCount = useSelector(
    (state: IState) => state?.AllTicketsReducer?.pageNoOtherTickets
  );
  const AllTickets = useSelector(
    (state: IState) => state?.AllTicketsReducer?.otherTickets
  );
  const loadMoreData = useCallback(() => {
    if (AllTickets.length === 0) return;
    dispatch(loadOtherTickets(FiltersVal, AllData, pageCount + 1, loadOtherTicketsCB));
  }, [AllTickets, FiltersVal, AllData, pageCount]);
  const loadOtherTicketsCB = (res: any) => {
    //setLoading(false);
  };
  useEffect(() => {
    console.log(
      "AllTickets,hasMoreTicketsAllTickets,hasMoreTickets",
      loading
    );
  }, [loading]);
  const pageContent = useMemo(
    () => (
      <>
        {AllTickets?.length !== 0 && hasMoreTickets && (
          <Box className={classes?.loadMoreBtn}>
            {loading   ? (
              <>Loading...</>
            ) : (
              <span onClick={loadMoreData}>Load More</span>
            )}
          </Box>
        )}
      </>
    ),
    [AllTickets, hasMoreTickets, classes,loading]
  );
  return <>{pageContent}</>;
};
export const loadMoreBtnStyles = makeStyles(() =>
  createStyles({
    loadMoreBtn: {
      flex: "0 0 100%",
      padding: "10px 10px 50px 10px",
      fontSize: ".8em",
      textAlign: "center",
      color: "#1261b4",
      "& span": {
        cursor: "pointer",
        display: "inline-block",
        border: "1px solid",
        borderRadius: "3px",
        padding: "5px 15px",
      },
    },
  })
);
export default ViewHolder;
