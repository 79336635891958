const initialState = {
  articleList: [],
};

const KnowledgeBaseReducer = (
  state = initialState,
  action: { type: any; payload: any }) => {
  switch (action.type) {
    case "STORE_ALL_ARTICLES":
      return {
        ...state,
        articleList: action.payload,
      };

    default:
      return state;
  }
};

export default KnowledgeBaseReducer;
