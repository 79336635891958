const generateList = (level1:number|string|null,adminTypeId: number, permId: any, isContact : boolean, isAgentAdmin: boolean, isSystemAdmin: boolean) => {
    const permissionFormat = {
        "adminTypeId": adminTypeId,//systemType [id]
        "adminChildIdLeavel1": level1,// INCIDENT (COL-1) [ID]
        "adminChildIdLeavel2": null,
        "adminChildIdLeavel3": null,
        "adminChildIdLeavel4": null,
        "permissionId": permId,//PERMISSIONS LIST - (ALLOW ACCESS -ID) - STATIC
        "isActive": true,
        isContact,//SSP role
        isAgentAdmin, // FROM AGENT PT ADMIN
        isSystemAdmin// FROM admin PT ADMIN
    };
    return permissionFormat;
}

export const generateApiRequest = (data:any) =>{
    console.log("generateApiRequest",data);
    const {id=0,systemItems={}, agentItems,systemTpesByName, name, description, isSuperAdmin, isSysAdmin, isAgent, isSSPUser,categoryObject,rankingObj={},permissions=[]} = data;
    const allowAccessPermissionId = permissions.find((x:any)=>x.name==="AllowAccess")?.id
    const apiPayload = {
        id,
        name,
        description,
        "isActive": true,
        isSuperAdmin,
        "isSSPRole": isSSPUser,
        "agentPermissionsList": []
    }
    if(isSuperAdmin) return apiPayload;
    const permissionList:any = []
    const pushCategories = (arr:number[]=[])=>{
        debugger;
        arr.map((i:any)=>{
            if(systemTpesByName[`Categories`]){
                // const Ids = i//.split('_');
                permissionList.push({
                    "adminTypeId": systemTpesByName[`Categories`]?.id,//systemType [id]
                    "adminChildIdLeavel1" : Number(i),// === 0 ? null : adminChildIdLeavel1,// INCIDENT (COL-1) [ID]
                    "adminChildIdLeavel2" : null,// Ids?.[1] ? Number(Ids?.[1]) : null,// === 0 ? null : adminChildIdLeavel2,
                    "adminChildIdLeavel3" : null,// Ids?.[2] ? Number(Ids?.[2]) : null,// === 0 ? null : adminChildIdLeavel3,
                    "adminChildIdLeavel4" : null,// Ids?.[3] ? Number(Ids?.[3]) : null,// === 0 ? null : adminChildIdLeavel4,
                    "permissionId": allowAccessPermissionId,//PERMISSIONS LIST - (ALLOW ACCESS -ID)
                    "isActive": true,
                    isContact: isSSPUser,//SSP role
                    isAgentAdmin:isAgent ,// FROM AGENT PT ADMIN
                    "isSystemAdmin" : false,// FROM admin PT ADMIN
                })
            }
        })
    }
    /* ======== FORM_AGENT_INFO ======== */
    if(agentItems && (isAgent || isSSPUser)){
        const modules = Object.keys(agentItems)
        modules.map((x:string)=>{
            console.log('modulesname',x)
            if(x === "Categories"){
                debugger;
                 let arr:any = Object.keys(agentItems?.[`${x}`]);
                //.map((i)=>{
                //     return Object.keys(agentItems?.[`${x}`][i])
                // })
                // arr =  arr.flat();
                pushCategories(arr)
                return;
            }
            if(systemTpesByName[`${x}`] && agentItems?.[`${x}`]){
                let typeKeys = Object.keys(agentItems?.[`${x}`]);
                typeKeys.map((i:string)=>{
                    if(agentItems?.[`${x}`]?.[i].map){
                        agentItems?.[`${x}`]?.[i].map((j:any)=>{
                            //isContact, isAgentAdmin, isSystemAdmin)
                            const permissionItem:any =  generateList(+i,systemTpesByName[`${x}`]?.id, j, isSSPUser, isAgent, false)
                            permissionItem.ranking = rankingObj?.[systemTpesByName[`${x}`]?.name]?.[i] || 0;
                            console.log("systemTpesByName98765", permissionItem );
                            permissionList.push(permissionItem)
                        })
                    }
                })
            }
        })
    }
    /* ======== FORM_SYSTEM_INFO ======== */
    if(Object.keys(systemItems).length > 0){
        const modules = Object.keys(systemItems)
        modules.map((x:string)=>{
            if(systemTpesByName[`${x}`] && systemItems?.[`${x}`]?.map){
                systemItems[`${x}`].map((i:string)=>{
                    permissionList.push(generateList(null,systemTpesByName[`${x}`]?.id, i, false, false, true))
                })
            }
        })
    }
    console.log("permissionListXpermissionList",permissionList)
    /* ======== FORM_AGENT_INFO ======== */
    apiPayload.agentPermissionsList = permissionList;
    return apiPayload
}