import {Box, Typography} from '@mui/material'

function NoDataFound({path, pathname, addHandler}:any) {
  console.log("path=>", pathname)
  return (
    <Box className="p-2">
        <Typography className="text-center">No Records Found...!</Typography>
    </Box>
  )
}

export default NoDataFound