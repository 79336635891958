import { ActionType, IChangeTheme } from "../../actions/Theme/Theme"

export interface ITheme {
    theme: boolean
}

const initState: ITheme = {
    theme: false
}

const Theme= (state: ITheme = initState, action: IChangeTheme) => {
    switch (action.type) {
        case ActionType.THEME:
            return {
                ...state,
                theme: action.payload
            }
        default:
            return state
    }
}

export default Theme