import { Avatar, Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { createRef, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';
import CommonStyles from '../../common/CommonStyles';
import { TextBoxLatest } from '../../common/TextBoxLatest/TextBoxLatest';
import Card from "../../common/card";
import CustomModalDialog from "../../common/customDialog";
import { TicketFilterSSP } from '../TicketFilter/TicketFilterSSP';
import CustomDialog from '../../common/dialog/CustomDialog';
import BasicButton from '../../common/button/BasicButton';
import { updateNewTicket } from '../../../services/dashboard/dashboard.service';
import { SnackBar } from '../../../services/AssetsManagement/AssetType';
import { SspStatusUrl, getTicketStatus } from '../../../redux/actions/SystemManagement/TicketStatus';
import ViewIcon from "../../../assest/icons/viewIcon.svg";
import LanguageData from "../../../configs/LanguageData.json";
import { useNavigate } from 'react-router-dom';
import NoDataFound from '../../common2/NoDataFound';

const WorkCenterNew = () => {
  const loggedContact = useSelector((state: IState) => state?.ContactsReducer?.loggedContact);
  const defaultService: any = useSelector(
    (state: IState) => state?.ContactsReducer?.DefaultService
  );
  const classes = CommonStyles();
  const navigate = useNavigate()
  return (
    <>
      {loggedContact?.id ?
        <TicketFilterSSP userId={loggedContact.id}
          extraFeatures={<>
          
            <div
              onClick={() => {
                navigate(`/viewService?id=${defaultService?.id}`);
              }}
            >
              + Report a fault
            </div></>}>
          <><WorkCenterLayout /></>
        </TicketFilterSSP>
      : <>Loading...</>}
    </>
  )
}
const WorkCenterLayout = () => {
  const tickets = useSelector((state: IState) => state?.ContactsReducer?.contactTickets);
  const classes = CommonStyles();
  const [viewId,setViewId] = useState(0);
  const viewModal = useMemo(()=><>
   {viewId !== 0 && <ViewModal id={viewId} setViewId={setViewId} ticketList={tickets}/>}
  </>,[viewId])
  return (
    <>
      <Grid container className="pt-1" spacing={1}>
        {tickets && tickets.length > 0 ? (
          tickets.map((i: any, index: number) => {
            const {
              title,
              description,
              ticketId,
              ticketStatus,
              contacts,
              dueDateTimeFormatted,
            } = i?.ticketLogs;

            return (
              <Grid item md={3} xs={12} key={index}>
                <Card
                  styleProp={classes.cardStyle}
                  customHeader={
                    <Box
                      className={`${classes.row} flx-gap-10 ${classes.alignItemsCenter} ${classes.justifyBetween} ${classes.w100}`}
                    >
                      <div>
                        <Avatar className="sml-avatar" sx={{ height: 30, width: 30 }} alt="" src={`${contacts?.profilePicturePath || ""}`} />
                      </div>

                      <Typography variant="body2" className={classes.title}>
                        ID : {ticketId}
                      </Typography>
                      <Box>
                        <Tooltip title="Ticket Status">
                          <Typography
                            variant="body2"
                            className={`${classes.title} ${classes.ticketCardTags}`}
                          >
                            {ticketStatus?.displayName || "-"}
                          </Typography>
                        </Tooltip>
                      </Box>
                    </Box>
                  }
                  cardBody={
                    <Box className="txt-ellipsis">
                      <Typography className="pb-5p" variant="body1">
                        Subject : {title}
                      </Typography>
                      <Typography className="pb-5p" variant="body1">
                        Description : {description}
                      </Typography>
                      <Typography className="pb-5p" variant="body1">
                        Service : {i?.contactAid?.name}
                      </Typography>
                      <Typography variant="body1">
                        Due Date :{" "}
                        <b className={classes.primaryTxt}>
                          {dueDateTimeFormatted ? moment(dueDateTimeFormatted).format('DD-MM-YYYY') : "---"}{" "}
                        </b>
                      </Typography>
                    </Box>
                  }
                  cardFooter={
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                        {ticketStatus?.name !== "Closed" && (
                          <Tooltip title="View Details">
                            <img
                              src={ViewIcon}
                              alt="view icon"
                              onClick={() => {
                                setViewId(i.id);
                              }}
                            />
                          </Tooltip>
                        )}
                      </Box>
                    </Box>
                  }
                />
              </Grid>
            );
          })
        ) : (
          <NoDataFound />
        ) }
      </Grid>

      {viewModal}
    </>
  )
}
export const ViewModal = ({id=0,setViewId,ticketList}:any) => {

  const classes = CommonStyles();
  const descRef: any = createRef();
  const dispatch= useDispatch()
  const [ticketStatus,setTicketStatus]=useState<any>({})
  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const filterd :any= ticketList.filter((ele: any) => {
    if (ele.id == id) {
      return ele
    }
  })
  const updateTicket = async (id: any, payload: any) => {
    let newTicket: any = await updateNewTicket(payload, id, "user/Ticket");
    return newTicket;
  };
  const submitForm = () => {
    filterd[0].ticketLogs.note = descRef.current.value
    filterd[0].ticketLogs.effort = "00:00:00"
    filterd[0].ticketLogs.isNotePrivate = false
    updateTicket(filterd[0].id, filterd[0]).then((res: any) => {
      console.log("ticketCreation res", res);
      if (res.status == 200) {
        setViewId(0)
        // setDeleteModalStatus(false);
        dispatch(SnackBar(`${LanguageData.SUCCESS} ${res?.data?.id} ${LanguageData.TICKET_UPDATED} `, 'success'));

      }
    });
    // descRef.current.value = ""
  }
  const handleClose =()=>{
    setDeleteModalStatus(true)

    console.log("Hiiiii")
  }
  useEffect(() => {
    dispatch(getTicketStatus(getTicketStatusCB,SspStatusUrl));
  }, []);

  const getTicketStatusCB = (res: any = []) => {
    setTicketStatus(res[1])
    console.log("popopopo", res)
  }
  const onTicketClose = () => {
    const payload = { ...filterd[0] }
    debugger
    console.log('onTicketClose', payload)

    if (payload.ticketLogs) {
      payload.ticketLogs.statusId = ticketStatus.filter((ele: any) => ele.name === "Closed")?.[0]?.id;
      payload.ticketLogs.ticketStatus = ticketStatus.filter((ele: any) => ele.name === "Closed")?.[0]
      payload.ticketLogs.note = descRef.current.value
    }
    // dispatch(updateTicket(payload.id, payload))//taskObj
    updateTicket(payload.id, payload).then((res: any) => {
      console.log("ticketCreation res", res);
      if (res.status == 200) {
        setViewId(0)
        dispatch(SnackBar(`${LanguageData.SUCCESS} ${res?.data?.id} ${LanguageData.TICKET_CLOSED}`, 'success'));

      }
    });
    console.log('onTicketClose', payload)
  };
  return (
    <>{
      <CustomModalDialog
        title=""
        id="upgradeModal"
        isOpen={true}
        onClose={() => {
          setViewId(0)
        }}
        headerContent={
          <div>
            <Typography variant="caption" style={{ fontSize: "14px" }}>
              Ticket Info - {id}
            </Typography>
            <Button
              style={{ marginLeft: "650px" }}
              onClick={handleClose}
            >
              Close Ticket
            </Button>
          </div>
        }
        bodyContent={
          <Box className={classes.upgradePopupHolder}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4}>
                <Typography className={classes.textWithSpanTitle}>
                  <span>Subject :</span>  {filterd[0]?.ticketLogs?.title ? filterd[0]?.ticketLogs?.title : "-"}
                </Typography>
                <Typography className={classes.textWithSpanTitle}>
                  <span>Description :</span>  {filterd[0]?.ticketLogs?.description ? filterd[0]?.ticketLogs?.description : "-"}
                </Typography>
                <Typography className={classes.textWithSpanTitle}>
                  <span>Created Date Time :</span>  {filterd[0]?.createdDateTimeFormatted}
                </Typography>
                <Typography className={classes.textWithSpanTitle}>
                  <span>Due Date Time  :</span>  {filterd[0]?.ticketLogs?.dueDateTimeFormatted ? filterd[0]?.ticketLogs?.dueDateTimeFormatted : "----"}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4} className='bl-1'>
                <Typography className={classes.textWithSpanTitle}>
                  <span>Service :</span>  {filterd[0]?.contactAid?.name ? filterd[0]?.contactAid?.name : "----"}
                </Typography>
                <Typography className={classes.textWithSpanTitle}>
                  <span>Ticket Status :</span>  {filterd[0]?.ticketLogs?.ticketStatus?.displayName ? filterd[0]?.ticketLogs?.ticketStatus?.displayName : "-"}
                </Typography>
                <Typography className={classes.textWithSpanTitle}>
                  {!filterd[0]?.ticketLogs?.isNotePrivate &&
                  <>
   <span>Note : </span> 
   <div>{filterd[0]?.ticketLogs?.note ? filterd[0]?.ticketLogs?.note : "-"}</div>
                  </>
                  
                  }
                  
                </Typography>
              </Grid>
              <Grid item xs={6} md={4} className='bl-1'>
                Add Quick Update
                <br />
                <div style={{marginTop:"5px"}}>
                <TextBoxLatest
                  multiline={true}
                  placeholder="Enter the Note"
                  title={"Note"}
                  ref={descRef}
                  isMandotary={false}
                  errorMsg={"Please Enter"}
                  isError={false}
                  name='desc'
                />
                </div>
                <Box className="text-center">
                  {/* <Button
                            className={`${classes.btn} ${classes.btnCancel}`}
                            variant="outlined"
                            onClick={backHandler}
                        >
                            <CancelIcon style={{ marginRight: "5px" }} />
                            Cancel

                        </Button> */}
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <Button
                    className={`${classes.btn} ${classes.btnSubmit}`}
                    variant="contained"
                    onClick={submitForm}
                  >
                    {"Update"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        }
      />
    }
      {deleteModalStatus && (
        <CustomDialog
          heading={`Close ticket  Task Id :${id} `}
          body={<>
              Are you sure want to close this ticket ?
              <Box >
                <br />
                <TextBoxLatest
                  multiline={true}
                  placeholder="Enter Note"
                  title={"Note"}
                  ref={descRef}
                  isMandotary={false}
                  errorMsg={"Please Enter"}
                  isError={false}
                  name='desc'
                />
              </Box>
            </>}
          open={deleteModalStatus}
          handleClose={() => {
            setDeleteModalStatus(false);
          }}
          footer={
            <>
              <div
                style={{ display: "flex", flexDirection: "row", gap: 20 }}
                className={classes.btnWrapperModal}
              >
                <BasicButton
                  onClick={(e: any) => {
                    setDeleteModalStatus(false);
                  }}
                  type={"Cancel"}
                  isLoading={false}
                  isDisabled={false}
                  color="primary"
                  size="large"
                  label="Cancel"
                  variant={"outlined"}
                  endIcon={false}
                />
                <BasicButton
                  onClick={() => {
                    onTicketClose();
                  }}
                  type={"submit"}
                  isLoading={false}
                  isDisabled={false}
                  color="primary"
                  size="large"
                  label="Close"
                  variant={"contained"}
                  endIcon={false}
                />
              </div>
            </>
          }
        />
      )}
    </>
  )
}
// const mapStateToProps = (state:IState) => {
//     return ({
//       ticketsList : state?.ContactsReducer?.contactTickets,
//       loggedContact: state?.ContactsReducer?.loggedContact
//     })
//   };
export default WorkCenterNew;//connect(mapStateToProps)(WorkCenterNew);