import {
  INewTicket,
  INewTicketAction,
  Dashboard,
  INewTicketNew,
} from "../../actions/Dashboard/dashboard.action";

const newTicketInitial = {
  createdDateTime: "2022-09-06T17:04:57.307",
  id: 1,
  isActive: true,
  ticketLogs: {
    id: "",
    customId: "",
    title: "",
    description: "",
    category: 0,
    status: 0,
    ticketType: 0,
    priority: 0,
    urgency: 0,
    source: 0,
    sla: 0,
    openDateTime: "2022-04-07T17:09:10.375Z",
    dueDateTime: "2022-04-07T17:09:10.375Z",
    firstResponseDueDateTime: "2022-04-07T17:09:10.375Z",
    scheduleDateTime: "2022-04-07T17:09:10.375Z",
    createdBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    createdDateTime: "2022-04-07T17:09:10.375Z",
    updatedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    updatedDateTime: "2022-04-07T17:09:10.375Z",
    isActive: true,
  },
};
export interface IAdditionalInfoDashboard {
  priorityId: number;
}

export interface IDashboardInterface {
  newTicket: INewTicket | INewTicketNew;
  allTickets: Array<any>;
  additionalInfoDashboard: IAdditionalInfoDashboard;
}

const initialState = {
  newTicket: newTicketInitial,
  allTickets: [newTicketInitial],
  additionalInfoDashboard: {
    priorityId: 0,
  },
};

const DashboardReducer = (
  state: IDashboardInterface = initialState,
  action: INewTicketAction
) => {
  switch (action.type) {
    case Dashboard.ADD_TICKET:
      return {
        ...state,
        dashbaord: {
          newTicket: action.payload,
        },
      };
    case Dashboard.ALL_TICKETS:
      return {
        ...state,
        allTickets: action.payload,
      };
    case Dashboard.ADDTIONAL_INFO_DASHBOARD:
      return {
        ...state,
        additionalInfoDashboard: action.payload,
      };
    default:
      return state;
  }
};

export default DashboardReducer;
