import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBMzpADwOzM93QcwoUUaUfclmzXDpMoXuI",
  authDomain: "push-notif-simplysys.firebaseapp.com",
  projectId: "push-notif-simplysys",
  storageBucket: "push-notif-simplysys.appspot.com",
  messagingSenderId: "971467407488",
  appId: "1:971467407488:web:5d00f25700a7f6f065a665",
  measurementId: "G-8C2PLQBB68"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


// Request permission and get token
const requestPermission = async (callBack) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      const token = await getToken(messaging, { vapidKey: "BH-oKbNhXRU6Y6a4Ff0PCgyRxQkrsvMz58DP8khjjruQYIEx9y-SvbwTkHVJ7yOyw3BxUGKeTQzRmY22q9C27fM" });
      console.log('Token:', token);
      callBack(token)
      // Send token to your server for storage
    } else {
      console.log("Unable to get permission to notify.");
    }
  } catch (error) {
    console.error("Error getting permission:", error);
  }
};



export {requestPermission, onMessage,messaging}