import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IState } from "../../../redux/reducers/rootReducers";
import Breadcrumbs from "../../common2/Breadcrumbs";
import TopFilter from "../../common2/TopFilter";
import stepOne from "../../../assest/teamsConfig/1.png"
import stepTwo from "../../../assest/teamsConfig/2.png"
import stepThree from "../../../assest/teamsConfig/3.png"
const TeamsConfig = () => {
    // const classes=CommonStyles()
    const [data, setData] = useState<any>([]);
    const [records, setRecords] = useState([])
    // const dispatch = useDispatch()
    // const navigate =useNavigate()
    // const [group, setGroup] = useState([]);
    const [searchData, setSearchData] = useState("");
    const [listStatus, setlistStatus] = useState(true);
    const [checked, setChecked] = useState(false);
    const [activeTeams, setActiveTeams] = useState([])
    const TeamsConfigDataList = useSelector((state: IState) => state?.TeamsConfigReducer?.Data);
    const filterHandler = (e: any) => { };
    const checkBoxHandler = (e: any, id: any) => {
        setChecked(e.target.checked);
    };
    const showDataHandler = (show: any) => {
        setlistStatus(show);
        if (show) {
            setUpdate(false);
        }
        if (show === false) {
            setUpdate(true);
        }
        // console.log("records",records);

    };
    const handleSearch = (e: any) => {
        let lowerCaseValue = e.target.value;
        setSearchData(lowerCaseValue);
    };
    const [update, setUpdate] = useState(false);
    const checkPermission: any = (valueKey: string) => {
        let val: boolean = permissionInfo[valueKey] ? true : false
        return val;
    }
    const [permissionInfo, setpermissionInfo] = useState({} as any);
    const rolesAccess = useSelector((state: IState) => state?.LoginReducer?.roleAccess);
    useEffect(() => {
        if (rolesAccess) {
            console.log('rolesAccess.OrganisationAzureActiveDirectiory', rolesAccess.OrganisationAzureActiveDirectiory)
            setpermissionInfo({ ...rolesAccess.OrganisationAzureActiveDirectiory, isSuperAdmin: rolesAccess.isSuperAdmin })
        }
    }, [rolesAccess]);
    // useEffect(() => {
    //     dispatch(getAllSSOConfig(getSSOConfigCb))
    // }, [])
    // const getSSOConfigCb = (data: any) => {
    //     console.log('data---', data)
    //     setData(data.map((i: any) => {
    //         return i;
    //     }));
    //     setRecords(data)
    // }
    const updateAllHandler = (value: any, id: any) => { //confusing about operation happening
        let arrayids: any[] = [];
        data.forEach((d: any) => {
            if (d.isActive) {
                arrayids.push(d.id);
            }
            if (d.isActive === false) {
                arrayids.push(d.id);
            }
        });
        // dispatch(multipalUpdateSSOConfig({ ...value }, arrayids, update));
    };
    // const deleteHandler = (val: string) => {
    //     console.log("listStatus>>>>>", listStatus);
    //     const id = parseInt(val);
    //     console.log("delete id", id)
    //     dispatch(softDeleteSSOConfig(id, !listStatus));
    // };
    // const EditHandler = (val: string) => {
    //     console.log("editId", val);
    //     const editVal = records.filter((x: any) => x.id === val);
    //     navigate(`/AddSSOConfig/${val}`);
    // };
    // const activeHandler = (type: any, value: any, id: any) => {
    //     dispatch(multipalUpdateSSOConfig({ ...value }, id, true));
    // };
    // useEffect(() => {
    //     if (data) {
    //         toggleActiveList(listStatus);
    //     }
    //     if (data && data.length > 0) {
    //         let activeTeams = data.filter((x: any) => x.isActive);
    //         setActiveTeams(activeTeams.length);
    //         console.log("activeTeams", activeTeams)
    //     }
    // }, [data]);
    const toggleActiveList = (type: boolean) => {
        const filtered = TeamsConfigDataList.filter((a: any) => a.isActive === type);
        setRecords(filtered);
    };
    useEffect(() => {
        if (TeamsConfigDataList && TeamsConfigDataList.length > 0) {
            const re = new RegExp(searchData, "i");
            const filtered = TeamsConfigDataList.filter(
                (entry: any) =>
                    entry.isActive === listStatus &&
                    Object.values(entry).some(
                        (val) => typeof val === "string" && val.match(re)
                    )
            );
            console.log("filtered>>>>", filtered, data, !listStatus);
            setRecords(filtered);
        }
    }, [searchData, listStatus]);
    return (
        <>
            <Breadcrumbs
                data={[
                    {
                        title: 'System Management',
                        path: '/adminPanel'
                    },
                    {
                        title: 'Teams Config ',
                        path: '/TeamsConfig'
                    }
                ]
                }
            />
            <Box className="pageHolder">
                <Grid container className="mt-1" spacing={2}>
                    <Grid item xs={12} md={8} className="pr-15p">
                        <Box className="white-bg p-2">
                            <Typography variant="h4">Teams Integration:</Typography>
                            <hr/>
                            <Typography>
                                <b>Step 1 : </b> Download a zip file using below Link <br/>
                                <a href="https://simplisysstoragev1.blob.core.windows.net/webasserts/TeamsSetup/Manifest_v1.0.2.zip" target="_blank" rel="noopener noreferrer">Click here to download the Zip file</a>
                            </Typography>
                            <br/>
                            <Typography><b>Step 2 : </b> Visit the URL given below:</Typography>
                            <Typography><a href="https://admin.teams.microsoft.com/policies/manage-apps" target="_blank" rel="noopener noreferrer">https://admin.teams.microsoft.com/policies/manage-apps</a></Typography>

                            <br/><br/>


                            <img width={"100%"} src={stepOne} alt="Teams Config Step 1 " />
                            <br/><br/>

                            <Typography><b>Step 3 : </b> You will find this page, In the top right corner there is Action inside that there is Upload new app. Select that and upload the Zip file which you downloaded from Website before.</Typography>
                            <br/>

                            <Typography><b>Step 4 : </b>After above step you need to make the uploaded teams app for all the User in the organization for that u need come to Setup policies(You can see in below picture in the left tab).</Typography>
                            <br/><br/>

                            <img width={"100%"} src={stepTwo} alt="Teams Config Step 2 " />
                            <br/><br/>

                            <Typography><b>Step 5 : </b>In the installed apps section there is Add apps button you need to select that.</Typography>
                            <br/><br/>

                            <img width={"100%"} src={stepThree} alt="Teams Config Step 3 " />
                            <br/><br/>
                            <Typography><b>Step 6 : </b>You will get side drawer from right side search for VizionsysBot you will see the uploaded application, Select and add and SAVE.</Typography>
                            <br/><br/>
                            <Typography><b>Note: </b> It may take few minutes to reflect the changes.</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <br/><br/>
                <br/><br/>

            </Box>
        </>
    )
}
export default TeamsConfig