import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { updateAgentCheckBoxes, updateCheckBoxes } from "../../../../redux/actions/userManagement/role";
import { Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { IState } from "../../../../redux/reducers/rootReducers";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const SspTicketTypeOptions = ["Create","View","Close","Update"];
const AgentPermissionsGroup = memo(({...rest}:any) => {
    const {permissions,titleKey,checkedItems,fieldId,isSSP=false} = rest;
    const dispatch = useDispatch();
    const changeHandler = useCallback((val:number,checked:boolean) =>{
        // console.log("changeHandler_clicked",titleKey,checkedItems,fieldId);
        // if(titleKey==="TicketTypes" && checked){
        //     dispatch({
        //         type: "TOGGLE_TICKET_TYPE_VALUES",
        //         payload: true
        //       });
        // }else{
            dispatch(updateAgentCheckBoxes(titleKey,val,fieldId, checked ? "edit" : "add"))
        // }
    },[])
    useEffect(()=>{
        console.log('check-status',checkedItems,titleKey)
    },[checkedItems?.[titleKey]?.[fieldId]])
    const layout = useMemo(()=><>
        <FormGroup aria-label="position" row>
            {permissions.filter((x:any)=> (x.name === "AllowAccess" && !isSSP) || (titleKey === "TicketTypes" && !isSSP) || (isSSP && titleKey === "TicketTypes" && SspTicketTypeOptions.includes(x.name))).map((item: any, ind: number) => {
                const {id,name} = item;
                const checked = checkedItems?.[titleKey]?.[fieldId] && checkedItems?.[titleKey]?.[fieldId]?.includes && checkedItems?.[titleKey]?.[fieldId]?.includes(id) ? true : false;
                return (
                    <div key={ind} className={`flx-20'}`}>
                        <Typography className={`radio-label`} onClick={()=>{changeHandler(id,checked)}}>
                            {checked ? <CheckBoxIcon /> :  <CheckBoxOutlineBlankIcon /> }
                            <span>{name}</span>
                        </Typography> &nbsp;&nbsp;
                    </div>
                )
            })
            }
        </FormGroup>
    </>,[checkedItems?.[titleKey]?.[fieldId]?.length])
    return (<>
        {layout}
    </>)
})
//export default AgentPermissionsGroup;//connect(mapStateToProps)(AgentPermissionsGroup);
const mapStateToProps = (state:IState) => {
    return ({
        checkedItems : state?.roleReducer?.agentCheckBoxStatus,
      //const checkBoxItems = useSelector((state: IState) => state?.roleReducer?.checkedStatus);
    })
};
export default connect(mapStateToProps)(AgentPermissionsGroup);