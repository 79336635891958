import React, { useEffect } from "react";
import { StylesConfig } from "react-select";
import SelectAsync from "./SelectAsync";
import { ActionType } from "../../../../redux/actions/dashboard";
import { useDispatch } from "react-redux";
import axios from "axios";

const colourStyles: StylesConfig<any> = {
  input: () => ({ height: "25px" }), // Only setting the height here
};


const AsyncDropDown = ({handleChange,defaultValue,apiUrl,placeHolder,name,promiseOptions,isMulti=false,height="auto",customClassNames="",isError=false,defaultOptions=[]}:{isError?:boolean,handleChange:any,defaultValue:any,apiUrl?: string,placeHolder: string,name: string,promiseOptions:any,isMulti?:any,height?: any,customClassNames?:string,defaultOptions?:any}) => {
  
  const API_URL = apiUrl

  const dispatch = useDispatch();


  return (
    <SelectAsync
      isOpen={false}
      placeholder={placeHolder}
      width={"100%"}
      height={height}
      name={name}
      isColor={false}
      isDisabled={false}
      isError={isError}
      defaultValue={defaultValue}
      options={promiseOptions}
      handleChange={handleChange}
      isMulti={isMulti}
      customClassNames={customClassNames}
      defaultOptions={defaultOptions}
    />
  );
};

export default AsyncDropDown;
