import { Box, List, Typography, ListItem,ListItemButton,ListItemText, ListItemIcon, InputAdornment } from '@mui/material'
import React, { createRef, useEffect, useState } from 'react'
import { IDropDownOption, IMobileDropdownProps } from '../mobileCommonSchema'
import { CloseIcon, DropdownArrow } from '../../common/svgIcons/chartIcon';
import BottomDrawer from './BottomDrawer';
import { debounce } from 'lodash';
import { TextBoxLatest } from '../../common/TextBoxLatest/TextBoxLatest';
import { useDispatch } from 'react-redux';
import { getContactsLazy } from '../../../redux/actions/ContactManagement/Contact';
import { DropdownListItem, NoRecordsFound } from './MobileDropdown';
import { getOrganisationsForDropdown } from '../../../redux/actions/CMDB/Organisation';

const OrgMobileDropdown = (props:IMobileDropdownProps) => {
    const fieldRef:any  = createRef();
    const {label,placeholder,isRequired=false,name="",icon,onChange} = props;
    const [opened,setOpened] = useState(false);
    const [value,setValue] = useState<null | IDropDownOption>(null);
    const onChangeHandler = debounce((i:IDropDownOption|null) =>{
        setValue(i)
        setOpened(false)
    },100)
    const drawerCallback = () =>{
        setOpened(false)
    }
    const clearValue = ()=>{
        onChangeHandler(null)
    }
    useEffect(()=>{
        if(fieldRef.current){
            fieldRef.current.value = value?.id || "";
        }
        if(onChange) onChange(value);
    },[value])
    return (
        <>
            <Box className="mobile-dropdown">
                <Typography><span className="label-required">{label} {isRequired && <b>*</b>}</span></Typography>
                <Box className="dropdown-ui">
                    <Typography onClick ={()=>{setOpened(true)}}>
                        {!value?.id && <span className='val'> {icon && <>{icon}</>} {placeholder}</span>}
                        {value?.id && <span className='val value'> {value?.iconLabel && <span className="name-initial initial-1">{value?.iconLabel}</span>} {value?.label}</span>}
                        <span><DropdownArrow /></span>
                    </Typography>
                </Box>
            </Box>

            
            <Box className="d-none"><TextBoxLatest  ref={fieldRef} name={name} /></Box>

            <BottomDrawer
                isOpened ={opened}
                onClose ={drawerCallback}
                title={label}
                rightCornerHandler={clearValue}
                rightCornerText="Clear"
            >
                <>
                    <LoadOrgForDropdown value={value} onChangeHandler={onChangeHandler}  />
                </>
            </BottomDrawer>
        </>
    )
}
interface ILoadOrgForDropdownProps{
    onChangeHandler : (val:any)=>void;
    value : null | IDropDownOption;
}
const LoadOrgForDropdown = (props:ILoadOrgForDropdownProps) =>{
    const {onChangeHandler,value} = props;
    const dispatch = useDispatch();
    const [orgList,setOrgList] = useState<any[]>([]);
    const searchRef: any = createRef();

    useEffect(()=>{
        loadOrg();
        return ()=>console.log("Cleanup funtion");
    },[])
    const loadOrg = debounce((searchVal:string="") =>{
        // dispatch(getContactsLazy(1, true, searchVal, loadOrgCB));
        dispatch(getOrganisationsForDropdown(searchVal,loadOrgCB,true))
    },100)
    const loadOrgCB = (res:any) => {
        console.log("loadOrgCB",res);
        setOrgList(res);
    }
    const onSearchChangeHandler = debounce((event: any) => {
        loadOrg(`${event.target.value || ""}`);
    },500)
    const clearField = () =>{
        searchRef.current.value = "";
        loadOrg("");
    }
    return(
        <Box>
            <Box>
            <TextBoxLatest
                    multiline={false}
                    placeholder="Please Enter"
                    title={""}
                    ref={searchRef}
                    onchangeHandler={onSearchChangeHandler}
                    isMandotary={false}
                    errorMsg={``}
                    isError={false}
                    name='search'
                    type="text"
                    parentClassNames="mobile-search-field"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment onClick={clearField} className='icon-holder' position="end">
                                <CloseIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            {orgList?.length === 0 ? 
            <NoRecordsFound />
            :
            <>
                <DropdownListItem
                        options = {orgList}
                        value = {value}
                        onChangeHandler = {onChangeHandler} 
                    />
            </>
            }
        </Box>
    )
}
export default OrgMobileDropdown;
