export const  formCategoryData = (category:any) =>{
    //console.log('priority---->', priority);
    const generatePayload = (name:string,id:number,parentId:number,level1:number=0,level2:number=0,level3:number=0,level4:number=0, categoryTicketTypes:any[] = []) =>{
        return {
            title: name,
            id: id,
            parentId:parentId,
            children: [],
            isChecked: false,
            intermediate: false,
            level1: level1,
            level2: level2,
            level3: level3,
            level4: level4,
            ticketTypes: categoryTicketTypes
        }
    }
    const categoryTicketTypes =  category?.categoryTicketTypeList?.map((i:any)=>{
        return i?.ticketType?.name;
    });
    const dataFormat:any = generatePayload(category.name,category.id,category.parentId,category.id, 0,0,0,categoryTicketTypes);
    //dataFormat.children
    //const childItems = [];
    category.subCategoryList && category.subCategoryList.forEach((i:any)=>{
        const tempSubItem:any =  generatePayload(i.name,i.id,i.parentId,category.id,i.id);
        i.itemList.forEach((j:any)=>{
            const tempItem:any =  generatePayload(j.name,j.id,j.parentId,category.id,i.id,j.id);
            j.childrenItemList.map((k:any)=>{   
                const tempItemChildren =  generatePayload(k.name,k.id,k.parentId,category.id,i.id,j.id,k.id);
                tempItem.children.push(tempItemChildren);
            })
            tempSubItem.children.push(tempItem)
        })
        dataFormat.children.push(tempSubItem)
    })
    return dataFormat;
}
const agentDataFormat = (TicketTypes:any,group:any,priority:any,permission:any,emailSettings:any = [],ticketStatusList:any = []) => {
    const AllInfo:any = {
        'Groups' : [],
        'TicketStatus': [],
        'MailService' : [],
        'TicketTypes' : {}
    }
    const allowAccess = permission.filter((i:any)=>i.name === 'AllowAccess')
    console.log('TicketTypes', TicketTypes);
    AllInfo.Priorities = priority.map((i:any)=>{
        return {
            name: i.name,
            id: i.id,
            isActive: i.isActive,
            isChecked: false,
            ticketTypeIds: i.ticketTypeList.map((i:any)=>i.ticketTypeId)
        }
    })
    AllInfo.TicketTypes = TicketTypes.map((i:any)=>{
        return {
            name: i.name,
            email:i.mailId,
            id: i.id,
            isActive: i.isActive,
            isChecked: false
        }
    })
    // TicketTypes.forEach((item:any) => {
    //     //console.log(item.name , item);
    //     AllInfo.TicketTypes[item.name] = {};
    //     AllInfo.TicketTypes[item.name].ticketTypeId = item.id;
    //     AllInfo.TicketTypes[item.name].permissions = permission;
    //     AllInfo.TicketTypes[item.name].categories = [];
    //     AllInfo.TicketTypes[item.name].priorities = [];
    //     AllInfo.TicketTypes[item.name].isChecked = false;
    // });
    console.log('emailSettings',emailSettings)
    AllInfo.MailService = emailSettings.map((i:any)=>{
        return {
            name: i.name,
            email:i.mailId,
            id: i.id,
            isActive: i.isActive,
            isChecked: false
        }
    })
    AllInfo.Groups = group.map((i:any)=>{
        return {
            name: i.name,
            id: i.id,
            isActive: i.isActive,
            isChecked: false
        }
    })
    console.log('ticketStatusList',ticketStatusList)
    AllInfo.TicketStatus = ticketStatusList.map((i:any)=>{
        return {
            name: `${i.displayName}`,
            hint: `(${i.name} ${i.type ==='TicketTaskStatus' ? "- Task Status" : ""})`,
            id: i.id,
            isActive: i.isActive,
            isChecked: false
        }
    })
    priority.map((i:any)=>{
        if(i.isActive && i?.ticketTypeList?.length && i.ticketTypeList?.length > 0){
            i.ticketTypeList.map((j:any)=>{
                if(AllInfo?.TicketTypes?.[j?.ticketType?.name]){
                    AllInfo.TicketTypes[j.ticketType.name].priorities.push(
                        {
                            id : i.id,
                            isActive : i.isActive,
                            name : i.name,
                            permissions : allowAccess,
                            ticketTypes : i.ticketTypeList.map((l:any)=>l.ticketType.name)
                        }    
                    )
                }
            })
        }
    })
   
    console.log('agentAllInfo',AllInfo)
    return AllInfo
}
export const formEditFlowAgentData = (defaultData:any, roleData:any, TicketTypes:any, priority:any, category:any,ticketStatusList:any=[]) =>{//updating system admin values on edit
    const ticketStructure:any  = {};
    TicketTypes.map((i:any)=>{ 
        ticketStructure[i.id] =  i.name;
    });
    let isAgent = false;
    const dataStruct = '';
    const allData = JSON.parse(JSON.stringify(defaultData));
    const priorityList:any =[];
    const categoriesList:any =[];
    roleData.agentPermissionsList.map((i:any)=>{
        const adminType = i.adminTypes.name;
        if(i.isActive && i.isAgentAdmin){
            isAgent = true;
            //console.log(adminType)
            if(adminType === 'TicketTypes'){
                //console.log('ticketStructure',ticketStructure);
                //console.log(adminType);
                //ticketStructure[i.adminChildIdLeavel1]
                if(allData?.TicketTypes?.[ticketStructure[i.adminChildIdLeavel1]]?.permissions){
                    allData?.TicketTypes?.[ticketStructure[i.adminChildIdLeavel1]]?.permissions.map((k:any)=>{
                        if(k.id === i.permissionId){
                            k.checked = true;   
                        }
                    })
                }
            }else if(adminType === 'Categories'){
                if(!categoriesList.some((items:any)=>items.id === i.id)){
                    categoriesList.push(i)
                }
            }else if(adminType === 'Priorities'){
                if(!priorityList.some((items:any)=>items.id === i.id)){
                    priorityList.push(i)
                }
            }else if(allData[adminType]) {
                allData[adminType].map((j:any)=>{
                    if(j.id === i.adminChildIdLeavel1){
                        j.isChecked = true;
                    }
                })
            }
        }
    })
    return {isAgent : isAgent, data : allData, categoryList:categoriesList , priorityList:priorityList};
}
export const formEditFlowSSPAgentData = (defaultData:any, roleData:any, TicketTypes:any ) =>{
    const ticketStructure:any  = {};
    TicketTypes.map((i:any)=>{ 
        ticketStructure[i.id] =  i.name;
    });
    let isAgent = false;
    const dataStruct = '';
    const allData = JSON.parse(JSON.stringify(defaultData));
    const priorityList:any =[];
    const categoriesList:any =[];
    roleData.agentPermissionsList.map((i:any)=>{
        const adminType = i.adminTypes.name;
        if(i.isActive && i.isContact){
            isAgent = true;
            //console.log(adminType)
            if(adminType === 'TicketTypes'){
                //console.log('ticketStructure',ticketStructure);
                //console.log(adminType);
                //ticketStructure[i.adminChildIdLeavel1]
                if(allData?.TicketTypes?.[ticketStructure[i.adminChildIdLeavel1]]?.permissions){
                    allData?.TicketTypes?.[ticketStructure[i.adminChildIdLeavel1]]?.permissions.map((k:any)=>{
                        if(k.id === i.permissionId){
                            k.checked = true;   
                        }
                    })
                }
            }else if(adminType === 'Categories'){
                if(!categoriesList.some((items:any)=>items.id === i.id)){
                    categoriesList.push(i)
                }
            }else if(adminType === 'ContactAid'){
                if(!priorityList.some((items:any)=>items.id === i.id)){
                    priorityList.push(i)
                }
            }else if(allData[adminType]) {
                allData[adminType].map((j:any)=>{
                    if(j.id === i.adminChildIdLeavel1){
                        j.isChecked = true;
                    }
                })
            }
        }
    })
    return {isSSPAgent : isAgent, data : allData, categoryList:categoriesList , aidList:priorityList};
}
export default agentDataFormat;