import { Box, List, Typography, ListItem,ListItemButton,ListItemText, ListItemIcon } from '@mui/material'
import React, { createRef, useEffect, useState } from 'react'
import { IDropDownOption, IMobileDropdownProps } from '../mobileCommonSchema'
import { DropdownArrow, NoDataImg } from '../../common/svgIcons/chartIcon';
import BottomDrawer from './BottomDrawer';
import { debounce } from 'lodash';
import { TextBoxLatest } from '../../common/TextBoxLatest/TextBoxLatest';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
const MobileDropdown = (props:IMobileDropdownProps) => {
    const fieldRef:any  = createRef();
    const {label="",placeholder,options=[],isRequired=false,name="",icon,onChange,defaultValue=null,dynamicValue} = props;
    const [opened,setOpened] = useState(false);
    const [value,setValue] = useState<null | IDropDownOption>(defaultValue);
    const onChangeHandler = debounce((i:IDropDownOption|null) =>{
        setValue(i)
        setOpened(false)
    },100)
    const drawerCallback = () =>{
        setOpened(false)
    }
    const clearValue = debounce(()=>{
        onChangeHandler(null)
    },100)
    useEffect(()=>{
        if(dynamicValue !== undefined) setValue(dynamicValue)
    },[dynamicValue])
    useEffect(()=>{
        if(fieldRef.current){
            fieldRef.current.value = value?.id || "";
        }
        if(onChange) onChange(value);
    },[value])
    useEffect(()=>{
        if(options?.length === 0){
            clearValue();
        }
        if(value?.id){
            const isChosenOptionExist = options.find((i:any)=>i.id === value.id)?.id;
            if(!isChosenOptionExist){
                clearValue(); 
            }
        }
    },[options])
    return (
        <>
            <Box className="mobile-dropdown">
                {label && <Typography><span className="label-required">{label} {isRequired && <b>*</b>}</span></Typography>}
                <Box className="dropdown-ui">
                    <Typography onClick ={()=>{setOpened(true)}}>
                        {!value?.id && <span className='val'> {icon && <>{icon}</>} {placeholder}</span>}
                        {value?.id && <span className='val value'> {value?.iconLabel && <span className="name-initial initial-1">{value?.iconLabel}</span>} {value?.label}</span>}
                        <span><DropdownArrow /></span>
                    </Typography>
                </Box>
            </Box>

            
            <Box className="d-none"><TextBoxLatest  ref={fieldRef} name={name} /></Box>

            <BottomDrawer
                isOpened ={opened}
                onClose ={drawerCallback}
                title={label}
                rightCornerHandler={clearValue}
                rightCornerText="Clear"
            >
                <>
                {options?.length === 0 ? <NoRecordsFound /> 
                    :
                    <DropdownListItem
                        options = {options}
                        value = {value}
                        onChangeHandler = {onChangeHandler} 
                    />
                }
                </>
            </BottomDrawer>
        </>
    )
}

export const NoRecordsFound = () =>{
    return <Box className="text-center">
        <NoDataImg />
    </Box>
}

interface IListItemProps {
    options:IDropDownOption[];
    value : IDropDownOption | null;
    onChangeHandler : (val:IDropDownOption|null)=>void;
}

export const DropdownListItem = (props:IListItemProps) =>{
    const {options,value,onChangeHandler} = props;
    return(
        <List className='drop-down-options'>
            {options.map((i:IDropDownOption,index:number)=>{
                return(<React.Fragment key={index} >
                        <ListItem key={index} className={value?.id === i.id ? "active" : ""} onClick={()=>{onChangeHandler(i)}}  disablePadding>
                            <ListItemButton className='mbl-dropdown-item'>
                                {/* <ListItemIcon><MailIcon /></ListItemIcon>  */}
                                {i?.iconLabel && <ListItemIcon className={`name-initial initial-${index < 4 ? index+1 : index%4+1}`}>{i?.iconLabel}</ListItemIcon>} 
                                <ListItemText primary={<span className='dropdown_option_span'>{i.label} {i?.isVIP ? <DiamondOutlinedIcon className='vip-icon' /> : ""}</span>} />
                            </ListItemButton>
                    </ListItem>
                    </React.Fragment>)
            })}
        </List>
    )
}
export default MobileDropdown
