const initialState = {
    Data: [],
    ticketBaseStatusList: [],
    TicketSources:[]
  };
  
  const ticketStatusReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case "getTicketBaseStatus":
        return {
          ...state,
          ticketBaseStatusList: action.payload,
        };
      case "getTicketStatus":
        return {
          ...state,
          Data: action.payload,
        };
      case "getTicketSources":
        return {
          ...state,
          TicketSources: action.payload,
        };
      case "singleTicketStatus":
        return {
          ...state,
          Data: action.payload,
        };
      case "updateTicketStatus":
      case "multipalUpdateTicketStatus":
    //   case "multipalParmanentDeleteTicketStatus":
      case "softDeleteTicketStatus":
        return {
          ...state,
        };
      case "deleteTicketStatus":
        return {
          ...state,
        };
      case "loading":
        return {
          ...state,
          showLoading: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default ticketStatusReducer;
  