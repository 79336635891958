import {
  FileUploadEnum,
  IFileUpload,
  IFileUploadAction,
  IFileUploadType,
} from "../../actions/FileUpload/FileUpload";

const initState: IFileUploadType = {
  fileupload: {
    description: "",
    filetypeid: 0,
    formFile: "" as unknown as File,
  },
  allFiles: [],
  ticketAttachment: [],
};

const FileUpload = (
  state: IFileUploadType = initState,
  action: IFileUploadAction
) => {
  switch (action.type) {
    case FileUploadEnum.FILE_UPLOAD:
      return {
        ...state,
        fileupload: action.payload,
      };
    case FileUploadEnum.ALL_FILES:
      return {
        ...state,
        allFiles: action.payload,
      };
    case FileUploadEnum.TICKET_ATTACHMENT:
      return {
        ...state,
        ticketAttachment: action.payload,
      };
    default:
      return state;
  }
};

export default FileUpload;
