import axios from "axios";
import configs from "../../../../../configs/config";
import { ActionType } from "../../../Snackbars";
const url = `${configs.BASE_URL}/master/EmailTemplates`;
const hardDeleteUrl = `${url}/permanent/delete`;
const dynamicFieldsURL = `${configs.BASE_URL}/master/DynamicFields`;
const ActionsUrl = `${configs.BASE_URL}/master/Actions`;

export const GET_TEMPLATES_SSP = 'GET_TEMPLATES_SSP';
export const GET_DYNAMIC_SSP = 'GET_DYNAMIC_SSP';
export const GET_ACTIONS_SSP = 'GET_ACTIONS_SSP';

export const storeAllEmailActionsSsp = (val: any) => ({
  type: GET_ACTIONS_SSP,
  payload: val,
});
export const storeDynamicFieldsSsp = (val: any) => ({
  type: GET_DYNAMIC_SSP,
  payload: val,
});
export const storeAllEmailTempletesSsp = (val: any) => ({
    type: GET_TEMPLATES_SSP,
    payload: val,
});
export const getTempletesSsp = (callback?: any) => {
    return async (dispatch: any) => {
        try {
            const response: any = await axios(url);
            const formattedData = response.data.map((item: any) => {
                let optionItem = {
                    ...item,
                    label: item.name,
                    value: item.name,
                }
                return optionItem;
            })
            dispatch(storeAllEmailTempletesSsp(formattedData));
            if (callback) callback(formattedData)
        } catch (error: any) {
            dispatch({
                type: ActionType.SNACKBAR,
                payload: {
                    message: error.response.data && typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Try again.",
                    severity: "error",
                    open: true,
                },
            });
            return console.log(error.response);
        }
    };
};
export const getTemplatesSspByID = (id:any="",callback?:any) => {
    return async (dispatch: any) => {
      try {
        const response:any = await axios(`${url}/${id}`);
        if(callback) callback(response.data)
      } catch (error:any) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error.response?.data && typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Try again.",
            severity: "error",
            open: true,
          },
        });
        return console.log(error.response);
      }
    };
  };
export const getDynamicFieldsSsp = () => {
    return async (dispatch: any) => {
      try {
        const response:any = await axios(dynamicFieldsURL);
        dispatch(storeDynamicFieldsSsp(response.data));
      } catch (error:any) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error.response.data && typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Try again.",
            severity: "error",
            open: true,
          },
        });
        return console.log(error.response);
      }
    };
  };
  export const getTemplatesActionsSsp = () => {
    return async (dispatch: any) => {
      try {
        const response:any = await axios(ActionsUrl);
        dispatch(storeAllEmailActionsSsp(response.data));
      } catch (error:any) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error.response.data && typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Try again.",
            severity: "error",
            open: true,
          },
        });
        return console.log(error.response);
      }
    };
  };
export const editEmailTemplatesSsp = (id:number,request:any,callback:any) => {
return async (dispatch: any) => {
    try {
    const response:any = await axios.put(`${url}/${id}`,request);
    // ;
    dispatch({
        type: ActionType.SNACKBAR,
        payload: {
        message: "Email Setting Updated Successfully!",
        severity: "success",
        open: true,
        },
    });
    console.log(`${url}/${id}`);
    callback();
    } catch (error:any) {
    dispatch({
        type: ActionType.SNACKBAR,
        payload: {
        message: error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.",
        severity: "error",
        open: true,
        },
    });
    return console.log(error.response);
    }
};
};
export const addEmailTemplatesSspAction = (data: any,callback :any) => {
    return async (dispatch: any) => {
      try {
        const response = await axios.post(url,data);
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: "New Email Template Added Successfully!",
            severity: "success",
            open: true,
          },
        });
        callback();
      } catch (error:any) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error.response &&  typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Please try again.",
            severity: "error",
            open: true,
          },
        });
        return console.log(error.response);
      }
    };
  };
export const hardDeleteEmailTemplatesSsp = (id:number) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.delete(`${hardDeleteUrl}/${id}`);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "Record Deleted Successfully!",
          severity: "success",
          open: true,
        },
      });
      dispatch(getTempletesSsp());
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response && typeof error.response.data === 'string' ? error.response.data :"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};