import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
ChartJS.defaults.font.family = "'DM Sans', sans-serif";
ChartJS.defaults.font.size = 14;

// Footer callback for tooltips
const footer = (tooltipItems: any) => {
  let sum = 0;
  tooltipItems.forEach((tooltipItem: any) => {
    sum += tooltipItem.parsed.y;
  });
  return 'Total: ' + sum;
};

const LineChart = ({
  datasets,
  labels,
  showLegend = true,
  showLabels = true,
  isDarkMode = false,
}: {
  datasets: any;
  labels: any;
  showLegend?: boolean;
  showLabels?: boolean;
  isDarkMode?: boolean;
}) => {
  const optionsForTicketTrendsChart: any = {
    maintainAspectRatio: false, // Don't maintain w/h ratio
    plugins: {
      legend: {
        display: showLegend,
        labels: {
          usePointStyle: false,
          boxHeight: 2,
        color: !isDarkMode ? "black":"white",
          font: {
            size: !showLabels ? 10 : 12, // Adjust font size based on showLabels prop
          },
          padding: !showLabels ? 5 : 8, // Adjust padding based on showLabels prop
        },
      },
      datalabels: {
        display: true,
        color: !isDarkMode ? "black":"white",
        font: {
          size: !showLabels ? 9 : 11, // Conditional font size for labels
          weight: "bold",
        },
      },
      tooltip: {
        display: false,
        callbacks: {
          footer: footer,
        },
        usePointStyle: true,
        filter: function (tooltipItem: any) {
          var value = tooltipItem.dataset.data[tooltipItem.dataIndex];
          return value !== 0; // Filter out 0 values
        },
      },
    },
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    animations: {
      radius: {
        duration: 400,
        easing: 'linear',
        loop: (context: any) => context.active,
      },
    },
    scales: {
      x: {
        ticks: {
        color: !isDarkMode ? "black":"white",
          font: {
            size: showLabels ? 10 : 12, // Adjust font size for x-axis ticks
          },
        },
      },
      y: {
        ticks: {
          callback: (yValue: any) => Math.floor(yValue), // format to your liking
        color: !isDarkMode ? "black":"white",
          font: {
            size: showLabels ? 10 : 12, // Adjust font size for y-axis ticks
          },
        },
      },
    },
  };

  const data = {
    labels: labels,
    datasets: datasets,
  };

  return <Line data={data} options={optionsForTicketTrendsChart} className="line-chart" />;
};

export default LineChart;
