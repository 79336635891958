import { Box } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { triggerAppAlert } from '../../../redux/actions/SelfServicePortal/ContactAid/ContactAid';
import { updateTicketTask } from '../../../redux/actions/TicketsManagement/TicketsManagement';
import { IState } from '../../../redux/reducers/rootReducers';
import TaskItem from './TaskItem';

function TaskList(props: any) {
    const { taskList = [], AllInfo = {}, callback, hideTitle } = props;
    const location = useLocation();
    const patamTaskId = new URLSearchParams(location.search).get('taskId');
    const dispatch = useDispatch();
    
    const taskCallback = (statusVal:any,taskInfo:any) =>{
        console.log("AllInfoAllInfoAllInfo",taskInfo,statusVal)
        if(statusVal === null) return;
        if (taskInfo.ticketTaskLogs) {
            taskInfo.ticketTaskLogs.statusId = statusVal?.id;
            taskInfo.ticketTaskLogs.ticketStatus = statusVal;
        }
        dispatch(updateTicketTask(taskInfo, taskApproveCB))
    }
    
    const taskApproveCB = (resStatus: string, response: any) => {
        console.log('taskApproveCB', resStatus, response)
        dispatch(triggerAppAlert(`Success! Task  ${response?.id} updated`, 'success'))
        callback(response)
    }
    return (
        <Box>
            {/* {taskList?.length === 0 && <NoDataFound />} */}
            {taskList?.map((i: any, index: number) => {
                const isChosen = `${patamTaskId}` === `${i?.id}`;
                return (
                    <Box key={index}>
                        <TaskItem callback={taskCallback} hideTitle={hideTitle} id={i.id} isChosen={isChosen} taskInfo={i || {}} />
                    </Box>
                );
            })}
        </Box>
    )
}


const mapStateToProps = (state: IState) => {
    return ({
        currAgent: state?.agentReducer?.loggedInAgent,
        AllInfo: state?.TicketsReducer?.alldataStructure
    })
};



export default connect(mapStateToProps)(TaskList);