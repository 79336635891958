import { useEffect, useMemo, useState } from "react";
import AgentRoleAccrodianItem from "./AgentRoleAccrodianItem";
import { IState } from "../../../../redux/reducers/rootReducers";
import { connect, useDispatch } from "react-redux";
import { selectAllAgentPermissions } from "../../../../redux/actions/userManagement/role";

const RoleContactAid = (props:any) =>{
    const {checkedTicketTypes={},aidList=[]} = props;
    const dispatch = useDispatch();
    const [isFirstLoad,setIsFirstLoad] = useState(true);
    const [contactAidList, setContactAidList] = useState<any>([]);
    const [activeTicketTypes,setActiveTicketTypes] = useState<any>([]);
    useEffect(()=>{
        if(checkedTicketTypes){
            const keys = Object.keys(checkedTicketTypes);
            const selectedTicketTypes =  keys.filter((i:any)=>checkedTicketTypes[i].length > 0)
            console.log('checkedTicketTypes_checkedTicketTypes',selectedTicketTypes)
            if(!isFirstLoad && activeTicketTypes.length > selectedTicketTypes.length ){
                dispatch(selectAllAgentPermissions(
                    {"ContactAid":{}}, "ContactAid"
                ))
            }
            if(activeTicketTypes.length !== selectedTicketTypes.length ){
                setActiveTicketTypes(selectedTicketTypes)
            }
        }
    },[checkedTicketTypes])
    useEffect(()=>{
        if(isFirstLoad){
            setIsFirstLoad(false);
            return;
        } 
        console.log('actiaidListveTicketTypes',aidList,activeTicketTypes)

        if(aidList.length>0){
            setContactAidList(aidList.filter((i:any)=>{
               return i?.isActive && i?.ticketTypesId && activeTicketTypes.includes(`${i?.ticketTypesId}`)
            }))
        }
    },[activeTicketTypes.length,aidList])
    const layout = useMemo(()=><>
        {contactAidList?.length>0 &&
            <AgentRoleAccrodianItem  info={contactAidList} keyLabel={'ContactAid'} />
        }
    </>,[contactAidList?.length])
    return(<>
        {layout}
    </>)
}
const mapStateToProps = (state:IState) => {
    return ({
        checkedTicketTypes : state?.roleReducer?.agentCheckBoxStatus?.TicketTypes,
        aidList : state?.roleReducer?.roleInfo?.sspUserData?.contactServices,
        //?.TicketTypes
    })
};
export default connect(mapStateToProps)(RoleContactAid);
//AgentCheckBoxList;//
// export default RoleContactAid;