import { memo, useEffect, useMemo, useState } from "react"
import { IState } from "../../../../redux/reducers/rootReducers";
import { connect} from "react-redux";
import AgentRoleAccrodianItem from "./AgentRoleAccrodianItem";
import  CategoryPart  from "./RoleCategories";
import RoleContactAid from "./RoleContactAid";
const SSPAdminAccess = memo(({roleDataSet}:any)=>{
    const [userAdminInfo,setUserAdminInfo] = useState<any>({});
    useEffect(()=>{
        console.log('roleDataSetroleDataSet',roleDataSet)
        if(roleDataSet?.isloaded){
            setUserAdminInfo(roleDataSet?.formAgentData)
        }
    },[roleDataSet?.isloaded])
   const usrlayout = useMemo(()=><>
        {userAdminInfo?.TicketStatus && <AgentRoleAccrodianItem  info={userAdminInfo?.TicketStatus} keyLabel={'TicketStatus'} />}
        {userAdminInfo?.TicketTypes && <AgentRoleAccrodianItem isSSP={true}  info={userAdminInfo?.TicketTypes} keyLabel={'TicketTypes'} />}
    </>,[userAdminInfo])
   const categoryLayout = useMemo(()=><><CategoryPart /></>,[userAdminInfo])
   const contactAid = useMemo(()=><><RoleContactAid /></>,[userAdminInfo])
    return(
        <> 
            {usrlayout}
            {categoryLayout} 
            {contactAid}
        </>
    )
})


const mapStateToProps = (state:IState) => {
    return ({
        roleDataSet : state?.roleReducer?.roleInfo,
    })
};
export default connect(mapStateToProps)(SSPAdminAccess);