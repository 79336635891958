import { Box, Checkbox, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MobileDropdown from '../MobileCommon/MobileDropdown'
import { IState } from '../../../redux/reducers/rootReducers'
import { connect } from 'react-redux'
import getTemplates from '../../../redux/actions/adminInterfaces/EmailTemplates'
import { debounce } from 'lodash'

interface IEmailTemplateOptionProps {
    emailTemplatesObj ?: any;
    ticketStatus ?: any;
    dispatch ?: any
}




const EmailTemplateOption = (props:IEmailTemplateOptionProps) => {
    const {emailTemplatesObj={}, dispatch, ticketStatus=null } = props;
    const [show,setShow] = useState(false);
    const [options,setOptions] = useState([]);
    const [value,setValue] = useState(null);
    useEffect(() => {
        if(Object.keys(emailTemplatesObj).length === 0){
            dispatch(getTemplates());
        }
    }, []);
    const checkBoxHandler = debounce((e:any) =>{
        setShow(e.target.checked);
        setValue(null)
    },100)
    
    useEffect(()=>{
        let list:any = []
        if(Object.keys(emailTemplatesObj).length > 0 && ticketStatus?.name){
            if(ticketStatus?.name === "Resolved"){
                list = emailTemplatesObj?.Resolve || []
            }else if(ticketStatus?.name === "Closed"){
                list = emailTemplatesObj?.Close || []
            }else {
                list = emailTemplatesObj?.AddUpdate || []
            }
        }else{
            list = []
        }
        setOptions(list)
    },[ticketStatus?.name,emailTemplatesObj])

    const onChangeHandler = (val:any)=>{
        setValue(val)
    }
    return (
        <Box className="pt-1">
            <Box className="mbl-snd-email-checkbox">
                <Typography>Send Email </Typography>
                <Box>
                    <Checkbox onChange={checkBoxHandler} name={`sendEmail`} />
                </Box>
            </Box>
            {show && 
                <Box className="pt-1">
                    <MobileDropdown 
                        placeholder = "Select a Template"
                        options={options}
                        isRequired={true}
                        name="emailTemplateId"
                        dynamicValue={value}
                        onChange={onChangeHandler}
                    />
                </Box>
            }
        </Box>
    )
}



const mapStateToProps = (state:IState) => {
    return ({
        emailTemplatesObj: state?.EmailTemplatesReducer?.emailTemplatesObj    
    })
  };
  
export default connect(mapStateToProps)(EmailTemplateOption);

// export default EmailTemplateOption
