export enum ActionType {
  API_STATUS = "API_STATUS",
}

export interface IAPIStatus {
  message: string;
  progress: boolean;
  error: boolean;
}

export interface IAPIStatusType {
  type: ActionType.API_STATUS;
  payload: IAPIStatus;
}

export const apiStatus = (apiStatus: IAPIStatus) => {
  return {
    type: ActionType.API_STATUS,
    payload: apiStatus,
  };
};
