import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { createTheme } from "@mui/material/styles";
import { makeStyles, styled } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AIIcon from "../../../assest/AI.png";
import { IState } from "../../../redux/reducers/rootReducers";
import AvatarWithText from "../../common/avatarWithText";
import CustomTheme from "../../common/CustomTheme";

const theme = createTheme();
const data = CustomTheme();

const Item = styled(Paper)(() => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "red",
}));

const AIManagement: React.FC = () => {
  const [UsrModules, setUsrModules] = React.useState([] as any);
  const rolesAccess = useSelector((state: IState) => state?.LoginReducer?.roleAccess);
  const [mode, setMode] = useState({} as any);
  const toggleTheme = useSelector((state: IState) => state.theme.theme);
  useEffect(() => {
    // console.log('>>>>>>',toggleTheme,">>>>",data);
    //  console.log('currOrganisation');
    if (toggleTheme) {
      setMode(data.all.dark);
      // setmodeType('dark');
    } else {
      setMode(data.all.light);
      // setmodeType('light');
    }
  }, [toggleTheme]);
  const useStyles = makeStyles(() => ({
    root: {
      display: "flex",
    },
    box: {
      backgroundColor: mode.solids,
      padding: "20px",
      "& .thumb": {
        lineHeight: "1",
      },
    },
    typography: {
      marginLeft: 10,
    },
    title: {
      fontSize: "24px",
      lineHeight: "29px",
      color: mode.text,
      marginBottom: "20px",
      "@media screen and (max-width : 980px)": {
        fontSize: "15px",
        marginBottom: "5px",
      },
    },
  }));
  const classes = useStyles();
  React.useEffect(()=>{
    let SysFields = [];
    // if(!rolesAccess){
    //   return;
    // }
    // if(rolesAccess?.isSuperAdmin ||  rolesAccess?.Groups?.AdminAccess){
      SysFields.push(
        {
            avatarText: "AI Sync",
            avatarURL: AIIcon,
            avatarAlt: "AI Sync",
            redirectLink: "/AI-Sync",
        },
        {
            avatarText: "AI Search",
            avatarURL: AIIcon,
            avatarAlt: "AI Search",
            redirectLink: "/AI-Search",
        },
    );
    // };
    
    setUsrModules([...SysFields]);
  },[rolesAccess])
  return (
    <>
      <Box sx={{ flexGrow: 1 }} className={classes.box}>
        <Typography className={classes.title}>Servodesk AI</Typography>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {UsrModules.map((mgt:any, index:number) => {
            return (
              <Grid item xs={6} md={4} className="thumbs">
                <Item style={{ backgroundColor: `${mode.body}` }}>
                  <AvatarWithText
                    avatarText={mgt.avatarText}
                    avatarURL={mgt.avatarURL}
                    avatarAlt={mgt.avatarAlt}
                    redirectLink={mgt.redirectLink}
                    isExtLink={false}
                  />
                </Item>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
}; 

export default AIManagement;
