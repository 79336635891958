import { useDispatch, useSelector } from "react-redux";
import { memo, useCallback, useMemo, useState } from "react";
import { storeFiltersValue } from "../../../../redux/actions/TicketsManagement/GetTickets";
import { makeStyles } from "@mui/styles";
import { Tooltip, Typography } from "@mui/material";
import { filterInitialState } from "../../../../redux/reducers/FiltersTicketManagement/FiltersTicketManagement";
import { IState } from "../../../../redux/reducers/rootReducers";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined';
import { getAllCalendarTickets, getAllTickets } from "../../../../redux/actions/TicketsManagement/TicketsManagement";
export const MoreActions = memo((props:any) => {
    const {hideExtraIcons, report ,page} = props
    const FiltersVal: any = useSelector((state: IState) => state?.filtersTicketManagementReducer);
    const groupShrinked: any = useSelector((state: IState) => state?.LayoutTicketManagementReducer?.groupShrinked);
    const columnShrinkVal: any = useSelector((state: IState) => state?.LayoutTicketManagementReducer?.columnShrinked);
    const AllData = useSelector((state: IState) => state?.TicketsReducer?.allResponses);
    // groupShrinked:false,
    // columnShrinked:true
    const dispatch = useDispatch();
    const callback = useCallback(()=>{},[])

    const classes = useStyles();
    const toggleColumn = useCallback(() => {
        dispatch(storeFiltersValue(!columnShrinkVal, 'COLUMN_SHRINK_VALUE'));
    },[columnShrinkVal])
    const toggleGroup = () => {
        dispatch(storeFiltersValue(!groupShrinked, 'GROUP_SHRINK_VAL'));
    }
    const reload = useCallback(() => {
        if(page==='calendar'){
            dispatch(getAllCalendarTickets(FiltersVal,AllData,callback))
          }else{
            dispatch(getAllTickets(FiltersVal,AllData,callback,report))
          }
    },[FiltersVal,AllData,callback,report])
    const toggleBtns = useMemo(() => <Tooltip title="Shrink Priorities">
        <>
            {columnShrinkVal ?
                <OpenInFullOutlinedIcon className={"priorityToggler"} onClick={toggleColumn} /> :
                <CloseFullscreenOutlinedIcon className={"priorityToggler"} onClick={toggleColumn} />
            }
        </>
    </Tooltip>, [columnShrinkVal,classes])
    const extraBtns = useMemo(() => <>
        {!hideExtraIcons &&
            <>
                <Tooltip title="Toggle Groups">
                    <GroupOutlinedIcon className={groupShrinked ? "active" : ""} onClick={toggleGroup} />
                </Tooltip>
                {toggleBtns}
            </>
        }</>, [hideExtraIcons,groupShrinked,columnShrinkVal,classes])
    return (
        <>
          {/* {component}   */}
            <Typography className={classes.title} variant="body2">
                <Tooltip title="Reload Tickets">
                    <CachedOutlinedIcon onClick={reload}/>
                </Tooltip>
                {extraBtns}
            </Typography>
        </>
    )
})
const useStyles = makeStyles(() => ({
    title:{
        fontSize: "10px",
        cursor: "pointer",
        "& svg" : {
            fontSize: 20,
            marginLeft: 6,
            "&.active":{
                background: "#ddd",
                borderRadius: "5px"
            }
        }
    }
}))
export default MoreActions;