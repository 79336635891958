import { Box, Container, Grid } from '@mui/material';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { handleTime } from '../../components/helpers/helperFunctions';
import { getDashboardData } from '../../redux/actions/Dashboard/insight';
import { IDashboardInsights } from '../../redux/actions/TicketsManagement/schema';
import { storeDashboardSummary } from '../../redux/actions/TicketsManagement/TicketsManagement';
import { getGroup } from '../../redux/actions/userManagement/group';
import { IState } from '../../redux/reducers/rootReducers';
import CountCards from './dashboardWidgets/CountCards';
import DashboardSettings from './dashboardWidgets/DashboardSettings';
import GroupDataChart from './dashboardWidgets/GroupDataChart';
import PriorityTreeChart from './dashboardWidgets/PriorityTreeChart';
import RadialChartsBox from './dashboardWidgets/RadialChartsBox';
import DashboardPieChart from './dashboardWidgets/TrendsChart';
interface IHomeProps {
  dispatch: any;
  dashboardSummary: IDashboardInsights | null;
  groupsList:any;
  dashboardGroup:any
}
const HomeContent = ({dispatch,dashboardSummary = null,groupsList,dashboardGroup={}}:IHomeProps) => {
  useEffect(() => {
    // API CALLS WHEN GROUP SELECTIONS ARE CHANGED
    const timer = setTimeout(() => {
      let tempArr:any = [];
      if(Object.keys(dashboardGroup).length>0){
        tempArr = Object.values(dashboardGroup).map(i=>Number(i))
      }else{
        tempArr = groupsList.map((i:any)=>i.id)
      }
      dispatch(getDashboardData(0,tempArr))  
    }, 1000); 

    return () => clearTimeout(timer);
  }, [dashboardGroup]);

  return (
    <Box className="white-bg pb-1 home-dashboard-holder">
      {/* {window.innerWidth} */}
      <Container maxWidth="lg">
        <Grid container spacing={0} alignItems={"center"}>
          <Grid item md={7}>
            <CountCards />
          </Grid>
          <Grid item md={5}>
            <DashboardSettings />
            <RadialChartsBox />
          </Grid>
        </Grid>
          <Grid container spacing={0.5} alignItems={"center"}>
            <Grid item md={5}>
                <Box className="home-chart-box white-bg">
                  {dashboardSummary?.priority && 
                    <GroupDataChart />
                  }
                </Box>
            </Grid>
            <Grid item md={7}>
              <Grid container spacing={0.5}>
                <Grid item md={6}>
                  {dashboardSummary?.tickettype && 
                      <><DashboardPieChart  /></>
                  }
                </Grid>
                <Grid item md={6}>
                  {/* {priorityData.length > 0 &&<> */}
                    <PriorityTreeChart  />
                  {/* </>
                  } */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
      </Container>
    </Box>
  )
}

const Home = (props: IHomeProps) => {
  const { dispatch, groupsList=[]} = props;
  useEffect(() => {
    if(groupsList.length===0){
      dispatch(getGroup());//groupsCB
    }
    dispatch(storeDashboardSummary())
  }, []);

  // const groupsCB = (res:any) =>{
  //   if(res[0] === "0"){
  //     const tempObj:any = {};
  //     res[1].map((i:any)=>{ 
  //       if(i?.isActive) tempObj[i.name] = i.id;
  //      })
  //     dispatch({
  //       type:"STORE_DASHBOARD_GROUPS",
  //       payload : tempObj
  //     })
  //   }
  // }
  return( 
    <>
      {(groupsList.length>0) ? <>
        <HomeContent  {...props} />
      </> : 
      <>Loading Dashboard...!</>}
    </>)
}
const mapStateToProps = (state: IState) => {
  return ({
    dashboardSummary: state?.TicketsReducer?.dashboardSummary,
    groupsList: state?.groupReducer?.Data?.data,
    dashboardGroup: state?.TicketsReducer?.dashboardGroup,
  })
};
export default connect(mapStateToProps)(Home);
