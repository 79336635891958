import axios from "axios";
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";
import intl from "react-intl-universal";
import LanguageData from "../../../configs/LanguageData.json"
import moment from "moment";
export const url = `${configs.BASE_URL}/Priority`;
const deleteUrl = `${configs.BASE_URL}/Priority/permanent/delete/`;
const changeStatus = `${configs.BASE_URL}/Priority/status/change?ids=`;
const bulkDelete =  `${configs.BASE_URL}/Priority//permanent/delete?ids=`
const unassignedTicketurl = `${configs.BASE_URL}/Priority/unassigned/tickettypes`;
export const storePriorityList = (val: any) => ({
  type: 'getPriority',
  payload: val,
});

export const getPriority = (callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(url);
      dispatch(loading(false));
      response.data = response.data.map((i:any)=>{
        return {
          ...i,
          label : i.displayName,
          value : i.id
        }
      })
      dispatch(storePriorityList(response));
      if(callback) callback(['0', response.data]);
    } catch (error:any) {
      if(callback) callback(['1', error.response]);
      return console.log(error);
    }
  };
};

export const getDateRecom = async (id:any) => {
  try {
      let result = await axios.get(`${configs.BASE_URL_Ticket}/Ticket/nextactiondate-duedate/by?priorityid=${id}`);
      return result.data;
  } catch (e) {
      console.log(e)
      return false
  }
}
export const getDateRecomFun = async (id: any) => {
  let res = await getDateRecom(id);
  return res;
};

export const unassignedTicket = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios(unassignedTicketurl);
      dispatch(loading(false));
      dispatch({
        type: "unassignedTicket",
        payload: response,
      });
    } catch (error) {
      return console.log(error);
    }
  };
};

const addPriority = (data: any,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: "addPriority",
        payload: response,
      });
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_NEW_PRIORITY}${LanguageData.TICKET_CREATED}`,
          severity: "success",
          open: true,
        },
      });
      callback()
    } catch (error:any) {
      if (error.response) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error.response.data,
            severity: "error",
            open: true,
          },
        });
        console.log('error=>', error.response.data);
      }  
    }
  };
};



export const singlePriority = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${url}/${id}`);
      dispatch({
        type: "singlePriority",
        payload: response,
      });
    } catch (error) {
      return console.log(error);
    }
  };
};

export const updatePriority = (data: any, id: any,callback?:any) => {
  return async (dispatch: any)=> {
    try {
      const response:any = await axios.put(`${url}/${id}`, data).then((resp) => {
        dispatch({
          type: "updatePriority",
        });
        dispatch(getPriority());
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message:`${LanguageData.SUCCESS_PRIORTIY}${LanguageData.TICKET_UPDATED}`,
            severity: "success",
            open: true,
          },
        });
      });
      callback()
    } catch (error:any) {
      if (error.response) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error.response.data,
            severity: "error",
            open: true,
          },
        });
        console.log('error=>', error.response.data);
      }  
    }
    
  };
};

export const multipalUpdatePriority = (data: any, ids: any, status:any) => {
  const idMsg = ids.length === undefined ? `${LanguageData.SUCCESS_PRIORTIY}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_PRIORTIY}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  const statusMsg = status ? `${LanguageData.SUCCESS_PRIORTIY}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}`: `${LanguageData.SUCCESS_PRIORTIY}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`;
  return function (dispatch: any) {
    axios.put(`${changeStatus}${ids}&activestatus=${status}`, data).then((resp) => {
      dispatch({
        type: "multipalUpdatePriority",
      });
      dispatch(getPriority());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: ids.length === undefined ? idMsg : statusMsg,
          severity: "success",
          open: true,
        },
      });
    });
  };
};

export const softDeletePriority = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${url}/${id}`);
      dispatch({
        type: "softDeletePriority",
        payload: response,
      });
      dispatch(getPriority());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_PRIORTIY}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`,
          severity: "success",
          open: true,
        },
      });
    } catch (error) {
      return console.log(error);
    }
  };
};

export const bulkDeletePriority = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${bulkDelete}${id}`);
      dispatch({
        type: "bulkDeletePriority",
        payload: response,
      });
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${intl.get("Success ! \n Priority updated to Active")}`,
          severity: "success",
          open: true,
        },
      });
      dispatch(getPriority());
    } catch (error) {
      return console.log(error);
    }
  };
};

export const deletePriority = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${deleteUrl}${id}`);
      dispatch({
        type: "deletePriority",
        payload: response,
      });
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${intl.get("Success ! \n Priority updated to Active")}`,
          severity: "success",
          open: true,
        },
      });
      dispatch(getPriority());
    } catch (error) {
      return console.log(error);
    }
  };
};

export const loading = (status: any) => {
  return {
    type: "loading",
    payload: status,
  };
};


export const getValueToPrefillDueDate = (priority: any, res: any) => {
  const tempObj = {
    dueDate: {
      isRequired: true,
      value: null
    },
    nextActionDate: {
      isRequired: true,
      value: null
    },
    isAlert: false
  }
  if (priority && res) {
    let dueDateInUtc: any = moment.utc(res.dueDate).local();

    let nextActionDateInUtc: any = moment.utc(res.nextActionDate).local();

    if (priority?.isResolutionTimeNoSLA) {
      tempObj.dueDate.isRequired = false;
    }

    if (
      (priority.isResolutionTimeNoSLA ||
        priority.isResolutionTimeCustomDueDate) &&
      (priority.isResponseTimeNoSLA ||
        priority.isResponseTimeCustomDueDate)
    ) {
      tempObj.dueDate.value = null;
      tempObj.nextActionDate.value = null;
    } else if (
      priority.isResolutionTimeNoSLA ||
      priority.isResolutionTimeCustomDueDate
    ) {
      tempObj.nextActionDate.value = nextActionDateInUtc;
      tempObj.dueDate.value = null;
    } else if (
      priority.isResponseTimeNoSLA ||
      priority.isResponseTimeCustomDueDate
    ) {
      tempObj.nextActionDate.value = null;
      tempObj.dueDate.value = dueDateInUtc;
    } else {
      tempObj.nextActionDate.value = nextActionDateInUtc;
      tempObj.dueDate.value = dueDateInUtc;
    }
  } else {
    tempObj.nextActionDate.value = null;
    tempObj.dueDate.value = null;
    tempObj.isAlert = true
  }
  return tempObj;
};


export default addPriority;
