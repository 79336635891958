import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import CustomInput from "../common/textField/CustomInput";
import { generateErrorList } from "../../pages/new-ticket/LatestTicket/Form";
import CustomFormLabel from "../common/form-label/CustomFormLabel ";
import CustomSelect from "../common/customSelect/CustomSelect";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../redux/reducers/rootReducers";
import { isValidate } from "../../pages/new-ticket/LatestTicket/Validation";
import { addEmailException } from "./schema";
import { addMailException, editMailException } from "../../redux/actions/adminInterfaces/EmailException";
import { ValidationResultS } from "../../pages/new-ticket/LatestTicket/Type";
import BasicButton from "../common/button/BasicButton";
import CustomCreatableSelect from "../common/customSelect/CustomCreate";


export const Form = ({ value }: { value: any }) => {
  const param: any = useParams();
  const [emailOptions, setemailOptions] = useState<any>([]);
  const DynamicFieldsList = useSelector(
    (state: IState) => state?.EmailSettingsReducer?.AllDynamicFields
  );
  console.log("value", value)
  useEffect(() => {
    if (DynamicFieldsList) {
      const options: any = []
      DynamicFieldsList.map((field: any) => {
        if (field.name.toLowerCase().includes('email')) {
          options.push({ id: field.id, label: field.value, value: field.value, isActive: true });
        }
      })
      setemailOptions(options)
    }
  }, [DynamicFieldsList]);

  const [toValue, setToValue] = useState<any>(value.to);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleChange = (val: any) => {
    setToValue(val)
  };
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const backHandler = () => {
    Navigate("/MailExceptionList");
  };
  const [error, setError] = React.useState<ValidationResultS>({
    isValid: false,
    errors: {},
  });
  const submitHandler = (event: any) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData(event.currentTarget);

    const data: any = {};

    formData.forEach((value, key) => {
      data[key] = value.toString();
    });

    let validationData = {
      name: data?.name,
    };
    const validation = isValidate(
      validationData,
      addEmailException
    );
    if (validation.isValid) {
        setLoading(false);
        let request = {
          from: data.from,
          name: data.name,
          body: data.body,
          subject: data.subject,
          to: toValue?.value,
          isActive: true,
        };
        console.log("update form", request)
        if (param?.id) {
          dispatch(editMailException(param.id, request, backHandler));
        } else {
          dispatch(addMailException(request, backHandler));
        }

      // Form is valid, proceed with submission
      setError({ isValid: false, errors: {} });

    } else {
      console.log("update form - validation", validation)

      // Form is invalid, update the errors state
      setError(validation);
    }


  }
  return <>
    <form onSubmit={submitHandler} style={{marginTop:"10px"}}>
      <Grid container spacing={1}>
        <Grid container spacing={1} style={{ gap: "5%", marginTop: "-30px" }}>
        
          <Grid item md={3} xs={3} className="mailexception-incomingmail" style={{ marginLeft: "15px", marginTop:"30px" }}>
          <CustomFormLabel
                labelName={"Name"}
                isMandotary={true}
              />
              <CustomInput
                isDisabled={false}
                readOnly={false}
                onClick={null}
                iconPosition={"start"}
                icon={false}
                isError={false}
                label={"name"}
                name="name"
                defaultValue={value.name}
                helperText={""}
                placeholder={"Please enter"}
              />{" "}

              {generateErrorList(error.isValid, error.errors, "name")}
            <h4 style={{ marginLeft: "0px" }}>Incoming Mail</h4>
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              
              <CustomFormLabel
                labelName={"From"}
                isMandotary={false}
              />
              <CustomInput
                type="text"
                isDisabled={false}
                readOnly={false}
                onClick={null}
                iconPosition={"start"}
                icon={false}
                isError={false}
                label={"from"}
                name="from"
                defaultValue={value.from}
                helperText={""}
                placeholder={"Please enter"}
              />{" "}
              {generateErrorList(error.isValid, error.errors, "email")}

              <CustomFormLabel
                labelName={"Subject"}
                isMandotary={false}
              />
              <CustomInput
                isDisabled={false}
                readOnly={false}
                onClick={null}
                iconPosition={"start"}
                icon={false}
                isError={false}
                label={"Subject"}
                name="subject"
                defaultValue={value.subject}
                helperText={""}
                placeholder={"Please enter"}
              />{" "}
              <CustomFormLabel
                labelName={"Body"}
                isMandotary={false}
              />
              <CustomInput
                isDisabled={false}
                readOnly={false}
                onClick={null}
                iconPosition={"start"}
                icon={false}
                isError={false}
                label={"Body"}
                name="body"
                defaultValue={value.body}
                helperText={""}
                placeholder={"Please enter"}

              />{" "}
            </div>
          </Grid>
          <Grid item md={3} xs={3} className="mailexception-outgoingmail" style={{ marginLeft: "15px" }}>
            <h4 style={{ marginTop: "125px" }}>Outgoing Mail</h4>
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              <CustomFormLabel
                labelName={"To"}
                isMandotary={false}
              />
              <CustomCreatableSelect
                name="to"
                options={emailOptions}
                defaultValue={toValue}
                placeholder=""
                width={"100% !important"}
                isMulti={false}
                handleInputChange={(e: any) => {
                  console.log("on Hnadle inpurc chnage", e)
                  setToValue(e)
                }}
              />
              {generateErrorList(error.isValid, error.errors, "email")}
            </div>  
          </Grid>
        </Grid>
        <Box style={{ textAlign: "end", width: "100%", display: "flex", justifyContent: "flex-end", gap: 7 }}>
          <Button
            className="emailFields-button"
            variant="outlined"
            onClick={backHandler}
          >
            <CancelIcon style={{ marginRight: "5px" }} />
            Cancel
          </Button>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <BasicButton
            className="emailFields-button"
            type={"submit"}
            isLoading={loading}
            color="primary"
            label={!param?.id ? "Save" : "Update"}
            size="large"
            variant={"contained"}
            endIcon={false}
          />

        </Box>
      </Grid>
    </form>
  </>
}