const initialState = {
    PricingList: [],
    getPricing: {},
}
const PricingReducer = (state = initialState, action: { type: any; payload: any; }) => {
    const {payload} = action;
    switch (action.type) {
        case 'STORE_PRICING':
            return {
                ...state,
                PricingList: payload,
            };
        case 'EDIT_PRICING':
            return {
                ...state,
                PricingList: state.PricingList.map((i:any)=>{
                    if(i.id === payload.id){
                        i = payload;
                    }
                    return i 
                }),
            };
        default:
            return state;
    }
}
  
export default PricingReducer;
  