import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { ChartOptions, ChartData } from "chart.js";
import { colorPalette } from "../../../components/Theme/Theme";
import { Box, Typography } from "@mui/material";
import { ChartHelper, IBasicObj } from "./TrendsChart";
import { Chart } from "chart.js";
import { ChartNoDataPlaceHolder } from "./TreeMapChart";
import { useSelector } from "react-redux";
import { IState } from "../../../redux/reducers/rootReducers";
interface  IColumnChartProps {
  labels : string[];
  values : number[];
  height ?: number; 
  title ?: string;
  callback?:(index:number,label:string,value?:number)=>void;
}
const getDarkLightModeTheme = (theme:boolean) =>{
  return  {
    y: {
      beginAtZero: true,
      ticks: {
        color : theme ? "#ffffff" : "#000000",
        font: {
          size: 8, // Set font size for Y-axis labels
        },
      },
      grid: {
        color: theme ? "#ffffff10" : "#00000010",
      }
    },
    x:{
      grid : {
        color : theme ? "#ffffff10" : "#00000010",
      }
    }
  }
}
const ColumnChart: React.FC<IColumnChartProps> = ({labels=[],callback, values=[],height=275,title}:IColumnChartProps) => {
  const toggleTheme = useSelector((state: IState) => state.theme.theme);
  const chartRef = useRef<any>(null);
  // Define the data and labels for the chart
  const [data,setData] = useState<ChartData<"bar">>({
    labels: [""],
    datasets: [],
  });
  useEffect(()=>{
    setData({
      ...data,
      datasets : labels.map((i:string,index:number)=>{
        return {
            label: i,
            data: [values[index]],
            backgroundColor: colorPalette[index],
            borderWidth: 1,
            borderRadius: 15
        }
      })
    })
  },[labels,values])
  // Define options for the chart
  const options : ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: getDarkLightModeTheme(toggleTheme),
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      datalabels: {
        display: false, // This hides the data labels on each bar
      },
    },
  };

  useEffect(() => {
    if (chartRef?.current?.chartInstance?.update) {
      chartRef.current.chartInstance.update();
    }
  }, [data]);
  const toggleWithCustomLegends = (tempObj: IBasicObj) => {
    const tempLablels = labels.filter((_, index) => tempObj?.[`${index}`] ? false : true);
    const tempValues = values.filter((_, index) => tempObj?.[`${index}`] ? false : true);
    const filteredColors = colorPalette.filter((_, index) => tempObj?.[`${index}`] ? false : true);
    setData({
      ...data,
      datasets : tempLablels.map((i:string,index:number)=>{
        return {
            label: i,
            data: [tempValues[index]],
            backgroundColor: filteredColors[index],
            borderWidth: 1,
            borderRadius: 15
        }
      })
    })

  }

  return (
      <Box className="home-chart-box white-bg" sx={{height:"100%"}}>
        <Box className="chart-header pb-1">
          <Typography className="chart-title">{title}</Typography>
          <ChartHelper callback={toggleWithCustomLegends} labels={labels} values={values} />
        </Box>
        <Box sx={{height:height}}>
          {(values.length > 0) ? <>
            <Bar ref={chartRef} data={data} options={options}
              onClick={(event:any) => {
                const chart = Chart.getChart(event.currentTarget as HTMLCanvasElement);
                if (chart) {
                  const elements = chart.getElementsAtEventForMode(event, "nearest", { intersect: true }, true);
                  const elementIndex = elements[0].datasetIndex;
                  if(callback){
                    callback(elementIndex,`${data?.datasets?.[elementIndex]?.label||""}`)
                    console.log("elementIndex",data.datasets[elementIndex].label)
                  }
                  // handleClick(event, elements);
                }
              }} 
            />
          </> : 
          <ChartNoDataPlaceHolder />
          }
        </Box>
      </Box>
  );
};

export default ColumnChart;
