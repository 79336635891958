import { Box } from '@mui/material'
import { iWebFormField } from './schema';
import { WebFormTextArea } from './FieldTypes/TextArea';
import { WebFormInputBox } from './FieldTypes/InputBox';
import { WebFormDropDown } from './FieldTypes/DropDown';
import { WebCheckBox } from './FieldTypes/CheckBox';
import HeaderContent from './FieldTypes/HeaderContent';

const SelectWebformField = ({block, value = null, editable = true }:{block : iWebFormField,value?:any,editable?:boolean}) => {
    const { fieldType} = block;
    return (
        <Box>
            {(fieldType === "Heading") && <HeaderContent fieldConfig={block} />}
            {fieldType === "TextArea" && <WebFormTextArea fieldConfig={block} value={value} editable={editable}/>}
            {(fieldType === "InputBox" || fieldType === "DatePicker") && <WebFormInputBox fieldConfig={block} value={value}  editable={editable}/>}
            {fieldType === "DropDown" && <WebFormDropDown fieldConfig={block} value={value} editable={editable}/>}
            {fieldType === "CheckBox" && <WebCheckBox fieldConfig={block}  value={value} editable={editable}/>}
        </Box>
    )
}

export default SelectWebformField
