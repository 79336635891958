import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CommonStyles from "../../../components/common/CommonStyles";
import AppLoader from "../../../components/common/AppLoader";
import MobileMenu from "../../../components/navbar/sideNavbar/MobileMenu";
import TopNavBarForAdmin from "../../../components/navbar/admin-navbar/top-navbar/TopNavBar";
import SideNavbarForAdmin from "../../../components/navbar/admin-navbar/side-navbar/SideNavBar";
import { Box, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Typography } from "@mui/material";
import CustomFormLabel from "../../../components/common/form-label/CustomFormLabel ";
import GmailConfigSsp from "./GmailConfigSsp";
import OutlookConfigSsp from "./OutlookConfigSsp";
import { getMailSettingsSspByID } from "../../../redux/actions/SelfServicePortal/ContactAid/EmailSettingsSsp/EmailSettingsSsp";
import Breadcrumbs from "../../../components/common2/Breadcrumbs";

function AddEmailSettingsSsp() {
    const param: any = useParams();
    const dispatch = useDispatch();
    const [loader, setloader] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [Data, setData] = useState<any>({
        name: ""
    });
    const [selectedRadio, setSelectedRadio] = useState("OutLook");
    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedRadio(event.target.value);
    };
    const classes = CommonStyles();
    const callback = (data: any) => {
        console.log("Shammmmm", data)
        setSelectedRadio(data.type)
        setIsEditMode(true);
        setloader(false)
    };
    useEffect(() => {
        if (param.id) {
            setloader(true)
            dispatch(getMailSettingsSspByID(param.id,callback));
        }
    }, []);
    return (
        <>
            
            <div>
                <AppLoader enabled={loader} />
                <Box className="mb2 pb2">
                <Breadcrumbs
                    data={[
                        {
                            title: 'Home',
                            path: '/adminPanel'
                        },
                        {
                            title: 'EmailSettings',
                            path: '/EmailSettingsSsp'
                        },
                        {
                            title: `${!param.id ? "Add " : "Edit "}Email Template`,
                            path: `${!param.id ? "/AddEmailSettingsSsp" : `/AddEmailSettingsSsp/${param.id}`}`
                        }
                    ]}
                />
                </Box>

                <Box className="mb2 pb2">
                    <Typography variant="h6" className={`${classes.themeColorTxt}`}>
                        {param.id ? "Edit" : "Add New"} Email Settings
                    </Typography>
                </Box>
                {!param.id?<CustomFormLabel labelName={"Please select Mail services"} isMandotary={false} />:<></>}
                <Box>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={selectedRadio}
                            onChange={handleRadioChange}
                            name="radio-buttons-group"
                        >
                            <Box display="flex">
                                {isEditMode ? ( // If in edit mode, show only the selected button
                                    <FormGroup>
                                        <FormControlLabel value={selectedRadio} control={<Radio />} label={selectedRadio === "OutLook" ? "Outlook" : "G-mail/Web-Mail"} />
                                    </FormGroup>
                                ) : ( // If not in edit mode, show both buttons
                                    <>
                                        <FormGroup>
                                            <FormControlLabel value="OutLook" control={<Radio />} label="Outlook" />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormControlLabel value="Gmail" control={<Radio />} label="G-mail/Web-Mail" />
                                        </FormGroup>
                                    </>
                                )}
                            </Box>
                        </RadioGroup>
                    </FormControl>
                </Box>
                {
                    <>
                        {selectedRadio === "OutLook" && <OutlookConfigSsp />}
                        {selectedRadio === "Gmail" && <GmailConfigSsp />}
                    </>
                }
            </div>
        </>
    );
}

export default AddEmailSettingsSsp;