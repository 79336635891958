import { InputAdornment, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useEffect, useRef, useState } from "react";
import { MobileCalendarIcon } from "../../common/svgIcons/chartIcon";
interface ICommonMobileDatePickerProps {
    name?:string;
    isDisabled?:boolean;
    maxDate?:any;
    minDate?:any;
    classeNames?:string;
    label?:string | JSX.Element;
    isRequired ? : boolean;
    dynamicValue?: any;
    onChange?:(e:any)=>void;
}
// startDateTime = new Date (Date.UTC(startDateTime.getFullYear(), startDateTime.getMonth(), startDateTime.getDate(),0,0,0,0)).toISOString();
// endDateTime = new Date (Date.UTC(endDateTime.getFullYear(), endDateTime.getMonth(), endDateTime.getDate(),23,59,59,59)).toISOString();
function CommonMobileDatePicker(props: ICommonMobileDatePickerProps) {
    const { classeNames = "",label="",minDate=new Date(),maxDate=null,isRequired=false,name="",dynamicValue,onChange} = props;
    const fieldRef:any = useRef();
    const [value, setValue] = useState<any>(null);
    const [local, setLocal] = useState<any>("");
    const onHandleChange = (e:any) =>{
        console.log("onHandleChange",e);
        setValue(e);
        if(onChange) onChange(e)
    }
    useEffect(() => {
        if (localStorage.getItem("lang")) {
            setLocal(localStorage.getItem("lang"));
        }
    }, []);
    useEffect(() => {
        if(dynamicValue){
            setValue(dynamicValue);
        }
    }, [dynamicValue]);
    const onClickIcon = ()=>{
        if(fieldRef?.current){
            fieldRef.current.focus();
        }
    }
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={local}>
            <div className={`${classeNames} mob-date-picker`} >
                <DateTimePicker
                    value={value} 
                    {...props}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(e: any) => onHandleChange(e)}
                    // views={['year', 'month', 'day']}
                    inputFormat="DD-MM-YYYY HH:MM"
                    renderInput={(params) => (
                        <TextField 
                            {...params} 
                            ref={fieldRef}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment onClick={onClickIcon} className='icon-holder' position="end">
                                        <MobileCalendarIcon />
                                    </InputAdornment>
                                )
                            }}
                            placeholder="Please choose"
                            name={name}
                            label={<span>{label} {isRequired && <b className="txt-danger">*</b>}</span>}
                        />)}     
                />
            </div>
        </LocalizationProvider>
    );
}
export default CommonMobileDatePicker