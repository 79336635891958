const initialState = {
    AllEmailSettings: [],
    AllDynamicFields: [],
    isTemplateAdded : ''
  }
  const EmailSettingsReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'GET_EMAIL_SETTINGS':
            return {
                ...state,
                AllEmailSettings: action.payload,
            };
      case 'STORE_DYNAMICFIELDS':
        return {
            ...state,
            AllDynamicFields: action.payload,
        };
      case 'ADD_SETTINGS':
            return {
                ...state,
                isTemplateAdded: action.payload,
           };
      case 'UNSET_SETTINGS_ALERT':
            return {
                ...state,
                isTemplateAdded: action.payload,
           };
        default:
            return state;
    }
  }
  
  export default EmailSettingsReducer;
  