const initialState = {
    OrganizationItem: {},
    getProdList: [],
    getLocationType: [],
    isSubmitted: '',
    getOrganisation : [],
    getLoggedOrganisation : {}
}
const FreeTrialReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case 'ADD_ORGANIZATION':
            return {
                ...state,
                OrganizationItem: action.payload,
            };
        case 'GET_SUBMITTED':
           // ;
            return {
                ...state,
                isSubmitted: action.payload,
            };
        case 'GET_ORGANISATION':
            // ;
            return {
                ...state,
                getOrganisation: action.payload,
            };
        case 'GET_COREPRODUCTS':
           // ;
            return {
                ...state,
                getProdList: action.payload,
            };
        case 'GET_LOCATIONTYPES':
            // ;
            return {
            ...state,
            getLocationType: action.payload,
        };
        case 'GET_WITH_TOKEN':
            // ;
            return {
            ...state,
            getLoggedOrganisation: action.payload,
        };
        default:
            return state;
    }
}
  
export default FreeTrialReducer;
  
