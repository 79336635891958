import { Button, Typography } from '@mui/material'
import React from 'react'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
interface IAddNewButtonProps{
    label:string;
    onClick?:(e:any)=>void;
}
const AddNewButton = (props:IAddNewButtonProps) => {
    const {label="",onClick} = props; 
    return (
        <Button onClick={onClick} className='mobile-add-new-button'>
            <AddRoundedIcon /> <Typography component={"span"}>{label}</Typography>
        </Button>
    )
}

export default AddNewButton
