import * as React from 'react';
import { styled } from '@mui/system';
import { TextField } from '@mui/material';

export const CommonTextArea = React.memo((props:any) =>{
  const {onChange,value,multiline,name="",type="text",placeholder="Please Enter"}=props;
  // console.log('given-value',value)
  // const [defaultVal,setDefaultVal] = React.useState('');
  // React.useEffect(() => {
  //   setDefaultVal(value);
  // }, [])
  const onchangeHandler = (e:any) =>{
    if(type === "number"){
      e.target.value = e?.target?.value.replace(/[^0-9]/g, '').slice(0, 2)
    }
    return onChange(e,name)
  }
  return (
    <>
        <TextField
          //id="standard-multiline-flexible"
          fullWidth
          label=""
          multiline={multiline}
          maxRows={3}
          rows={3}
          defaultValue={value}
          //value={value}
          // type={type}
          placeholder={placeholder}
          className={`common-new-input ${multiline?"multiline":""}`}
        //   variant="standard"
          name={name}
          onChange={onchangeHandler}
        />
    </>
  );
})
export default CommonTextArea;