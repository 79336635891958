import NotificationItem from "./NotificationItem";

const Notification = () => {
  const notifications = new Array(100)
    .fill(null)
    .map((_, index) => <></>);
  return (
    <>
      <div style={{ overflowY: "auto", height: "100vh", width:"100%" }}>{notifications}</div>
    </>
  );
};

export default Notification;
