import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createRef, memo, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getSSPContacts } from "../../../redux/actions/ContactManagement/Contact";
import { SspStatusUrl, getTicketStatus } from "../../../redux/actions/SystemManagement/TicketStatus";
import { IState } from "../../../redux/reducers/rootReducers";
import { getTicketByContact } from "../../../redux/actions/TicketsManagement/TicketsManagement";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { TextBoxLatest } from "../../common/TextBoxLatest/TextBoxLatest";
import { handleTime } from "../../helpers/helperFunctions";
interface TicketFilterSSPProps {
    userId?: number;
    extraFeatures?: React.ReactNode;
    children?: React.ReactNode;
    [key: string]: any; 
}

export const TicketFilterSSP = ({ userId = 0, extraFeatures, ...rest }: TicketFilterSSPProps) => {
    const contacts = useSelector((state: IState) => state?.ContactsReducer?.contactsSSP);
    const statuses = useSelector((state: IState) => state?.ticketStatusReducer?.Data);

    console.log("check_rerender",contacts, statuses , userId, rest)
    const dispatch = useDispatch();
    const classes = useStyles();
    const searchRef: any = createRef();
    const [checkedVal, setCheckedVal] = useState<any>({
        status: {},
        contact: {
            [userId] : true
        }
    });
    useEffect(() => {
        if (Object.keys(checkedVal.status).length > 0) {
            searchHandler();
            console.log('checkedVal', checkedVal);
        }
    }, [checkedVal]);
    useEffect(() => {
        if (statuses && statuses.length > 0) {
            fetchTickets();
        }
    }, [statuses]);
    useEffect(() => {
        if (contacts?.length === 0) {
            dispatch(getSSPContacts());
        }
        if (statuses?.length === 0) {
            //dispatch(getTicketStatus(getTicketStatusCB,SspStatusUrl))
            dispatch(getTicketStatus(null, SspStatusUrl));
        }
        // fetchTickets()
    }, [])
    const fetchTickets = () => {
        const contactIds = Object.keys(checkedVal.contact).filter((i:string)=>checkedVal.contact?.[i]).map((i)=>+i);
        const statusIds = Object.keys(checkedVal.status).filter((i:string)=>checkedVal.status?.[i]).map((i)=>+i);
        console.log(statusIds);
        
        const notClosed=statuses.reduce((acc:any, ele:any) => {
            if (ele.name !== "Closed" && ele.name !== "Delete") {
              acc.push(ele.id);
            }
            return acc;
          }, []);
        const payload: any = {
            "statusIds": statusIds.length===0 ? notClosed : statusIds,
            "contactIds": contactIds.length > 0 ? contactIds : [userId], //can be changed
            "search": searchRef?.current?.value || ""
        }
        dispatch(getTicketByContact(payload))
    }
    const searchHandler = (e?: any) => {
        handleTime.cancel()
        handleTime.setup(() => { fetchTickets() }, 1000);
    }
    const checkBoxVal = (keyVal: string = "_", id: string | number) => {
        setCheckedVal({
            ...checkedVal,
            [keyVal]: {
                ...checkedVal?.[keyVal],
                [id]: !(checkedVal?.[keyVal]?.[id] || false)
            }
        })
    }
    const reset = () =>{
        searchRef.current.value = ""
        setCheckedVal({
            status: {},
            contact: {
                [userId] : true
            }
        })
    }
    return (
        <>
            <Box className={`${classes.row} align-items-center  gap15  ${classes.filterBox}`}>
                <Box>
                    <Typography className="pointer label-like-txt last-col">{extraFeatures && extraFeatures}</Typography>
                </Box>
                <Box>
                    <TextBoxLatest
                        multiline={false}
                        placeholder={"Search..."}
                        title={""}
                        ref={searchRef}
                        isMandotary={false}
                        type={"search"}
                        onchangeHandler={searchHandler}
                    />
                </Box>
                <Box className="list_box_holder">
                    <Typography className="pointer label-like-txt">Status
                    &nbsp;({Object.values(checkedVal.status).filter((i)=>i).length})
                    </Typography>
                    <Box className="list_box">
                        {statuses.map && statuses.map((i: any) => {
                            const checked = checkedVal?.status?.[i.id];
                            return (<>
                                <Typography className={`radio-label`}
                                    onClick={() => { checkBoxVal("status", i.id) }}>
                                    {checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                    <span>{i.displayName}</span>
                                </Typography>
                            </>)
                        })}
                    </Box>
                </Box>
                <Box className="list_box_holder">
                    <Typography className="pointer label-like-txt">Contacts 
                    &nbsp;({Object.values(checkedVal.contact).filter((i)=>i).length})
                    </Typography>
                    <Box className="list_box">
                        <Typography
                            className={`radio-label`}
                            onClick={() => { checkBoxVal("contact", userId) }}>
                            {checkedVal?.contact?.[userId] ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                            <span>My Tickets</span>
                        </Typography>
                        {contacts.map && contacts.map((i: any) => {
                            const checked = checkedVal?.contact?.[i.id];
                            return (<>
                                <Typography
                                    className={`radio-label`}
                                    onClick={() => { checkBoxVal("contact", i.id) }}>
                                    {checked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                    <span>{i.name}</span>
                                </Typography>
                            </>)
                        })}
                    </Box>
                </Box>
                <Box>
                    <Typography className="pointer label-like-txt" onClick={reset}>Reset</Typography>
                </Box>
                
            </Box>

            {rest.children||<></>}
            {rest.length}
        </>
    )
}

const useStyles = makeStyles(() => ({
    filterBox: {
        padding: "1.5rem 0",
        borderBottom: "1px solid #ddd"
    },
    addNewButton: {
        color: "white",
        height: "23px",
        fontSize: "9px",
        backgroundColor: "#007bff",
        padding: "2px",
        whiteSpace: "nowrap",
    },
    icon: {
        width: 18,
        height: 18,
    },
    searchBox: {
        width: "150px",
    },
    input1: {
        height: 6,
        fontSize: "13px",
        fontWeight: 400,
    },
    buttonNew: {
        marginRight: 8,
    },
    searchIcon: {
        fontSize: "16px",
        marginLeft: "-5px",
    },
    topbarSelect: {
        "& fieldset": {
            border: "none",
        },
        "& > div": {
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: "3px",
        },
    },
    dropdown: {
        "& .MuiSelect-select.MuiSelect-select": {
            fontSize: 10,
        },
        marginRight: 4,
    },
    title: {
        display: "flex",
        alignItems: "center",
    },
    titleDate: {
        fontSize: 10,
    },
    userBoxNav: {
        fontSize: '10px',
        borderRadius: '50%',
        lineHeight: '25px',
        minWidth: '25px',
        textAlign: 'center',
        cursor: 'pointer',
        boxShadow: '0 0 0px 0px #48b963',
        transition: '.5s ease-in-out',
        "&.checked": {
            boxShadow: '0 0 0px 4px #48b963',
            zIndex: 1
        }
    },
    applyButton: {
        backgroundColor: "#007bff",
        marginLeft: "9px",
        color: "white",
        "& span": {
            lineHeight: 1,
        },
    },
    dateRange: {
        // position: "absolute",
        // right: "10px",
        // top: "30px",
        // "& .rdrDateDisplayWrapper": {
        //   display: "none",
        // },
        // "& .rdrInputRanges": {
        //   display: "none",
        // },
        // "& .rdrDefinedRangesWrapper": {
        //   width: "120px",
        // },
        // "& .rdrMonthAndYearWrapper": {
        //   paddingTop: "6px",
        //   height: "40px",
        // },
    },
    dateInnerContainer: {
        display: "flex",
    },
    dateRangeActionButtons: {
        borderTop: '1px solid #ddd',
        width: "100%",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: '10px',
        "& span": {
            lineHeight: 1,
            fontSize: "11px",
        },
    },
    row: {
        display: "flex",
    },
    rowWrap: {
        flexWrap: "wrap",
    },
    filterTitle: {
        fontSize: "13px",
        borderBottom: "1px solid #ddd"
    },
    filterItem: {
        fontSize: "11px",
        cursor: "pointer",
        padding: "2px 5px",
        display: "flex",
        alignItems: "center",
        gap: "2px",
        "& svg": {
            width: "18px",
            height: "18px",
            color: "#777",
        }
    },
    checkedIcon: {
        color: "#007bff !important"
    }
}))




const mapStateToProps = (state: IState) => {
    return ({
        ticketsList : state?.ContactsReducer?.contactTickets,
        contacts: state?.ContactsReducer?.contactsSSP,
        statuses: state?.ticketStatusReducer?.Data,
    })
};
export default connect(mapStateToProps)(TicketFilterSSP);
// export default TicketFilterSSP;