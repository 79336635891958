import { Container, Box, Typography } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';
import { CrownIcon } from '../../../components/common/svgIcons/chartIcon';

function UpgradePlan({currentPlanId=0}:{currentPlanId:number}) {
  return (
    <Box>
      <Container>
        <Box className="white-bg p-2">
            <Box className="upgrade_content">
                <Box>
                    <Box className="icon-holder"><CrownIcon /></Box>
                    <Typography component="h3">
                        Upgrade to Access the Full Potential of Servodesk                        
                    </Typography>
                    <Typography> Ready to take your helpdesk to the next level? Upgrade to Servodesk Pro and unlock powerful tools designed to streamline your support process and enhance customer satisfaction. </Typography><br/><br/>
                    <Typography> Please contact admin to upgrade the system. </Typography>
                </Box>
            </Box>
        </Box>
      </Container>
    </Box>
  )
}


const mapStateToProps = (state:IState) => {
    return ({
      featurePermissions: state?.FeatureReducer?.featurePermissions,
    })
  };
  
export default connect(mapStateToProps)(UpgradePlan);