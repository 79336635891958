import React, { createRef, useEffect, useReducer, useState } from 'react';
import AddContactAid from './AddContactAid';
import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import { TextBoxLatest } from '../../common/TextBoxLatest/TextBoxLatest';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';
import getWebForm from '../../../redux/actions/WebForms/webForms';
import GroupAgent from './GroupAgent';
import ChooseAidImage from './ChooseAidImage';
import addContactAid, { editContactAid, getContactAidById } from '../../../redux/actions/SelfServicePortal/ContactAid/ContactAid';
import { useNavigate, useParams } from 'react-router-dom';
import { getCategory } from '../../../redux/actions/SystemManagement/AddCategory';
import Breadcrumbs from "./../../common2/Breadcrumbs";
import { ValidationResultS, iCataloguePayload } from '../../../pages/new-ticket/LatestTicket/Type';
import { isValidate } from '../../../pages/new-ticket/LatestTicket/Validation';
import { addCatalogueSchema } from './Schema';

const initVal:CounterState = {
    ticketType : null,
    category: null,
    webFrom: null,
    group:null,
    agent:null,
    pictureId : null,
    pictureIdPath : "",
    errors:{}
}
// Define the type for the state
interface CounterState {
    ticketType  : null | any;
    category : null | any;
    webFrom : null | any;
    group : null | any;
    agent : null | any;
    pictureId  : null | any;
    pictureIdPath : string;
    errors : any
}

// Define the type for the action
type CounterAction = { type: string,payload?:any};

// Reducer function
const catalogueReducer = (state: CounterState=initVal, action: CounterAction): CounterState => {
  switch (action.type) {
    case 'CHANGE_VALUE':
      return { 
            ...state,
            ...action.payload
    };
    case 'STORE_ERR':
      return { 
            ...state,
            errors : action.payload
    };
    case 'UNSET_ERR':
      return { 
            ...state,
            errors : {
                ...state.errors,
                [action.payload] : null
            }
    };
    default:
      return state;
  }
};

// Component
const ContactAIDHolder: React.FC = () => {
    const dispatch = useDispatch();
    const param = useParams();
    const [initVal,setInitVal] = useState<any>(null)
    useEffect(() => {
        dispatch(getCategory())
        dispatch(getWebForm())
    },[])
    useEffect(()=>{
        if(!param?.id) return;
        dispatch(getContactAidById(param?.id,getAidByIdCB))
    },[param?.id])
    const getAidByIdCB = (resStatus:string, res:any) =>{
        if(resStatus === "0"){
            setInitVal(res)
        }else{
            
        }
    }
    return(
        <Box className='p-1'>
        <Breadcrumbs
            data={[
            {
                title: "SSP Configuration ",
                path: "/adminPanel",
            },
            {
                title: "Catalogue",
                path: "/ServicesSSPList",
            },
            {
                title: `${param?.id ? "Edit " : "Add New "} Service`,
                path: `${param.id ? "/ServicesSSPList/"+param.id  : "/AddServicesSSP"}`,
                //`AddServicesSSP${param?.id ? "/ServicesSSPList/" param?.id : ""}`,
            },
            ]}
        />
            <Box className="pt-1"> </Box>
            {(!param?.id || (param?.id && initVal?.name) ) && <ContactAIDHolder1 initVal={initVal} /> }
        </Box>
    )
}
const ContactAIDHolder1 = (props:any) => {
  const {initVal} = props;
  const param = useParams();
  const commonDispatch = useDispatch();
  const navigate = useNavigate();
  // useReducer returns the current state and a dispatch function
  const [state, dispatch] = useReducer(catalogueReducer, initVal);
  const titleRef: any = createRef();
  const costRef: any = createRef();
  const descRef: any = createRef();
  const checkBoxRef:any = createRef();
  const isDefaultRef:any = createRef();
  const WebFormList = useSelector((state: IState) => state?.WebformReducer?.data);

  
  useEffect(()=>{
    /* ==== Prefill EDIT value ==== */
    if(!initVal) return;
    const {cost,description,name} = initVal;
    if(titleRef.current) titleRef.current.value = name || "";
    if(costRef.current) costRef.current.value = cost || "";
    if(descRef.current) descRef.current.value = description || "";
  },[initVal])

  /* ==== OnSubmit ==== */
    const onSubmit = () =>{
        const {agent,category,group,pictureId,pictureIdPath,ticketType,webFrom}= state;
        const payload:iCataloguePayload = {
            "id": param.id ? +param.id : 0,
            "name" : titleRef.current.value,
            "cost" : costRef.current.value,
            "description" : descRef.current.value,
            "categoryId": category?.id || null,
            "webformId": webFrom?.id || null,
            "groupId": group?.id || null,
            "agentId": agent?.id || null,
            "systemIconsId": pictureId,
            // "systemIcons" : `${pictureIdPath}`,
            "ticketTypeId": ticketType?.id || null,
            // "isAuthorizeRequired": checkBoxRef.current.checked || false,
            "isActive": true,
            "isDefault" : isDefaultRef.current.checked || false
        }
        console.log("onSubmit_____",payload);

        /* ================== validation ================== */   
        const {name,description ,ticketTypeId ,groupId  ,systemIconsId } = payload;
        const validation:ValidationResultS = isValidate(
            {name,description ,ticketTypeId ,groupId  ,systemIconsId },
            addCatalogueSchema
        );
        debugger;
        if (!validation.isValid) {
            dispatch({
                type: "STORE_ERR",
                payload : {
                    ...validation.errors
                }
            })
            return;
        }
        if(param.id){
            commonDispatch(editContactAid(payload.id,payload, submitCB))
            return;
        }
        commonDispatch(addContactAid(payload, submitCB))
    }
    const unsetErr = (name:string="") => {
        //UNSET_ERR
        dispatch({
            type: "UNSET_ERR",
            payload : name
        })
    }
    const submitCB = (resStatus: string, res: any) => {
        console.log("submitCB", resStatus, res)
        if(resStatus === "0"){
            cancelHandler()
        }
    }
    const cancelHandler = () =>{
        navigate('/ServicesSSPList')
    }
  return (
        <Box className="p-2 white-bg ServicesSSP">
            <Typography className='SSP-Heading' >
            {param?.id ? "Edit" : "Add New "}  Service
            </Typography>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item md={6} xs={12} className='add-service-lists'>
                            <TextBoxLatest
                                multiline={false}
                                placeholder="Enter Name"
                                title={"Name"}
                                ref={titleRef}
                                onchangeHandler={()=>{unsetErr("name")}}
                                isMandotary={true}
                                errorMsg={`${state?.errors?.name}`}
                                isError={state?.errors?.name ? true : false}
                                name='name'
                            />
                            {/* {state?.errors?.name} */}
                        </Grid>
                        
                        <AddContactAid webFormList={WebFormList} stateVal={state} customDispatch={dispatch} initValue={initVal} />
                        <GroupAgent stateVal={state} customDispatch={dispatch} isAgentRequired={false} initValue={initVal} />
                        <Grid item md={12} xs={12} className='add-service-lists'>
                            <TextBoxLatest
                                multiline={false}
                                placeholder="Enter Cost"
                                title={"Cost"}
                                onchangeHandler={()=>{unsetErr("cost")}}
                                ref={costRef}
                                isMandotary={false}
                                errorMsg={`${state?.errors?.cost}`}
                                isError={state?.errors?.cost ? true : false}
                                name='cost'
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <TextBoxLatest
                                multiline={true}
                                placeholder="Enter Description"
                                title={"Description"}
                                ref={descRef}
                                onchangeHandler={()=>{unsetErr("description")}}
                                isMandotary={true}
                                errorMsg={`${state?.errors?.description}`}
                                isError={state?.errors?.description ? true : false}
                                name='description'
                            />
                            {/* <Typography className={`radio-label`} >
                                <Checkbox 
                                    defaultChecked={initVal?.isAuthorizeRequired || false}
                                    inputRef={checkBoxRef} 
                                />
                                <span>Need Authorisation?</span>
                            </Typography> &nbsp;&nbsp; */}
                            <Typography className={`radio-label`} >
                                <Checkbox 
                                    defaultChecked={initVal?.isDefault || false}
                                    inputRef={isDefaultRef} 
                                />
                                <span>Make Default</span>
                            </Typography> &nbsp;&nbsp;
                        </Grid>
                        
                    </Grid>
                    <Box className="text-center pt-2">
                        <Button onClick={cancelHandler}>
                            Cancel
                        </Button> &nbsp; &nbsp; &nbsp;
                        <Button variant='contained' onClick={onSubmit}>
                            {param?.id ? "Update" : "Submit"}
                        </Button>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12} className='pt-2'>
                    <ChooseAidImage stateVal={state} initValue={initVal}  customDispatch={dispatch}/>
                </Grid>
            </Grid>
        </Box>
  );
};

export default ContactAIDHolder;