const initialState = {
  AllEmailSettingsSsp: [],
  isTemplateAdded : ''
}
const EmailSettingsSspReducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case 'GET_SETTINGS_SSP':
      return {
        ...state,
        AllEmailSettingsSsp: action.payload,
      };
    case 'ADD_SETTINGS':
      return {
        ...state,
        isTemplateAdded: action.payload,
      };
    default:
      return state;
  }
}

export default EmailSettingsSspReducer;
