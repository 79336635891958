import { memo, useEffect, useState } from "react";
import { Box,Typography,Accordion,AccordionDetails, Grid, FormControl, FormGroup, FormControlLabel, Checkbox, AccordionSummary, Tooltip } from "@mui/material";
import CommonStyles from "../../common/CommonStyles";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import AppLoader from "../../common/AppLoader";
const UserAdmin = memo((props:any) =>{
    const classes = CommonStyles();
    const [loader, setloader] = useState(false);
    const [expanded, setExpanded] = useState('');
    const [firstEditPaint, setfirstEditPaint] = useState(true);
    const [AllData, setAllData] = useState({} as any);
    const [priorityLists, setpriorityLists] = useState([] as any[]);
    const [dummyRecord, setdummyRecord] = useState([1] as any[]);
    const [categoryLists, setcategoryLists] = useState([] as any[]);
    const [treeData, setTreeData] = useState<any>([]);
    const [categoryItem, setcategoryItem] = useState(0);
    const {AllAccess, callback, isEdit, EditCategoryList,EditPriorityList} = props;
    const updateTreeData = (modifiedtreeData: any) => {
      setTreeData(modifiedtreeData);
    };
    const categoryClickHandler = (item: any, index:number) => {
      //console.log(categoryLists[item])
      // let categoryListing = categoryLists.map((i:any)=>i);
      // categoryListing[categoryItem] = treeData[0];
      // setcategoryLists(categoryListing);
      setTreeData([item]);
      setcategoryItem(index)
    };
    const collapse = (treeData: any) => {
        //console.log('treeData',treeData);
    };
    const handleChange =(panel: string) => {
      setExpanded(expanded === panel ? "" : panel);
    };
    useEffect(()=>{
      if(AllAccess){
        setAllData(AllAccess)
      }
    },[AllAccess])
    const allowAllAccess = (e:any,moduleName:string) =>{
      if(!AllData){
          return;
        }
        const check = e.target.checked;
        const allInfo = JSON.parse(JSON.stringify(AllData));
        //isChecked
        if(moduleName === 'TicketTypes'){
            Object.keys(allInfo[moduleName]).forEach((j:string)=>{
                allInfo[moduleName][j].permissions.forEach((k:any)=>{
                    k.checked = check;
                })
            })
        }else if(moduleName){
            let modifiedChanges = allInfo[moduleName].map((a:any)=>{
              a.isChecked = check;
              return a;
            })
          allInfo[moduleName] = modifiedChanges;
        }
        setAllData({
          ...allInfo
        })
    }
    const checkboxHandler = (e:any,i:any,j:any,ind:number) =>{
      if(!AllData){
          return;
        }
        const check = e.target.checked;
        const allInfo = JSON.parse(JSON.stringify(AllData));
        allInfo[i][j].permissions[ind].checked = check;
        //allInfo.Groups[ind].isChecked = check
        setAllData({...allInfo})
    }
    
    const checkboxGroupHandler = (e:any,moduleName:string,ind:number) =>{
      if(!AllData){
          return;
        }
        const check = e.target.checked;
        const allInfo = JSON.parse(JSON.stringify(AllData));
        allInfo[moduleName][ind].isChecked = check
        setAllData({...allInfo})
    }
    const getIntermediateVal = (i:string)=>{
        if(!AllData){
          return;
        }
        let hasFalseVal = false;
        let hasTrueVal = false;
        let prioritiesList:any = [];
        let categoriesList:any = [];
        if(i=== 'TicketTypes' && AllData[i]){
            Object.keys(AllData[i]).forEach((j:string)=>{
                AllData[i][j].permissions.forEach((k:any)=>{
                    if(k.checked){
                      AllData[i][j].priorities.forEach((item:any)=>{
                        if(!prioritiesList.some((items:any)=>items.id === item.id)){
                          prioritiesList.push({...item,isChecked:false})
                        }
                      })
                      AllData[i][j].categories.forEach((item:any)=>{
                        if(!categoriesList.some((items:any)=>items.id === item.id)){
                          categoriesList.push({...item,isChecked:false})
                        }
                      })
                    }
                    if(!hasTrueVal && k.checked){
                        hasTrueVal = true;
                    }else if(!hasFalseVal && !k.checked){
                        hasFalseVal = true;
                    }
                })
            })
        }else {
          AllData?.[i] && AllData[i].forEach((k:any)=>{
              if(!hasTrueVal && k.isChecked){
                hasTrueVal = true;
              }else if(!hasFalseVal && !k.isChecked){
                hasFalseVal = true;
              }
          })
        }
        return {
          hasFalseVal:hasFalseVal,
          hasTrueVal:hasTrueVal,
          otherVals : {
            prioritiesList:prioritiesList,
            categoriesList:categoriesList
          }
        }
    };
    useEffect(()=>{
      callback(AllData,priorityLists,categoryLists)
    },[AllData,priorityLists,categoryLists])
    useEffect(()=>{
      if(!AllData){
        return;
      }
      const dates:any =getIntermediateVal('TicketTypes')
      let priority:any = dates.otherVals.prioritiesList;
      const categories = dates.otherVals.categoriesList
      if(isEdit && firstEditPaint){ // to paint the admin permissions data
        priority = priority.map((i:any,index:number)=>{
          EditPriorityList.map((j:any)=>{
              if(i.id === j.adminChildIdLeavel1){
                  i.permissions.map((k:any)=>{
                    if(k.id === j.permissionId){
                        k.checked = true;
                    }
                  })
              }
          })
          return i;
        })
        EditCategoryList.map((i:any)=>{
          categories.map((j:any)=>{
              if(i.adminChildIdLeavel1 !== 0  && i.adminChildIdLeavel2 === 0 && j.level1 === i.adminChildIdLeavel1){
                j.isChecked = true;
                return;
              }
              //j.level3 === 0 && j.level4 === 0
            j.children.map((k:any)=>{
              if(i.adminChildIdLeavel2 !== 0  && i.adminChildIdLeavel3 === 0 && j.level1 === i.adminChildIdLeavel1 &&  k.level2 === i.adminChildIdLeavel2){
                  k.isChecked = true;
                  return;
              }
              k.children.map((l:any)=>{
                  if(i.adminChildIdLeavel3 !== 0 && i.adminChildIdLeavel4 === 0 && j.level1 === i.adminChildIdLeavel1 &&  k.level2 === i.adminChildIdLeavel2 && l.level3 === i.adminChildIdLeavel3){
                    l.isChecked = true;
                    return;
                  }
                l.children.map((m:any)=>{
                  if(i.adminChildIdLeavel4 !== 0 && j.level1 === i.adminChildIdLeavel1 &&  k.level2 === i.adminChildIdLeavel2 && l.level3 === i.adminChildIdLeavel3 && m.level4 === i.adminChildIdLeavel4){
                    m.isChecked = true;
                    return;
                  }
                })
              })
            })
          })
        })
        console.log(EditCategoryList,EditPriorityList)
      }
      setpriorityLists(priority);
      setcategoryLists(categories);
      setcategoryItem(0)
    },[AllData])
    useEffect(()=>{
      console.log('priorityLists',priorityLists);
    },[priorityLists])
    useEffect(()=>{
        console.log('console.log(categoryLists)',categoryLists);
        if(categoryLists.length>0){
          setTreeData([categoryLists[0]]);
        }else{
          setTreeData([]);
        }
        setcategoryItem(0);
    },[categoryLists])
    const checkPriorityHandler = (e:any,itemIndex:number,permissionIndex:number)=>{
      const check = e.target.checked;
      const temArr = priorityLists;
      temArr[itemIndex].permissions[permissionIndex].checked = check;
      //console.log(temArr);
      setpriorityLists([...temArr])
    }
    const priorityCheckStatus = () =>{
      let hasFalseVal = false;
      let hasTrueVal = false;
      priorityLists.forEach((i:any)=>{ // checking whether priorities as truly or falsy checkbox values
        i.permissions.forEach((j:any)=>{
            if(!hasTrueVal && j.checked){
                hasTrueVal = true;
            }else if(!hasFalseVal && !j.checked){
                hasFalseVal = true;
            }
        })
      });
      //return true
      return {
        hasFalseVal:hasFalseVal,
        hasTrueVal:hasTrueVal
      }
    }
    const categoryCheckStatus = () =>{
      let hasFalseVal = false;
      let hasTrueVal = false;
      //console.log(categoryLists);
      categoryLists.forEach((i:any)=>{ // checking whether priorities as truly or falsy checkbox values
            if(!hasTrueVal && i.isChecked){
                hasTrueVal = true;
            }else if(!hasFalseVal && !i.isChecked){
                hasFalseVal = true;
            }
      });
      //return true
      return {
        hasFalseVal:hasFalseVal,
        hasTrueVal:hasTrueVal
      }
    }
    const allowAllAccessPriority = (e:any) => {
      const check = e.target.checked;
      const tempArr = priorityLists.map((i:any)=>{ // checking whether priorities as truly or falsy checkbox values
          i.permissions.map((j:any)=>{
                  j.checked = check;
          })
          return i;
      })
      setpriorityLists([...tempArr])
    }
    const getReversedArray = (Arr:any []) =>{
      let tempArr = [];
      for (let index = 0; index < Arr.length; index++) {
        tempArr.unshift(Arr[index]);
      }
      return tempArr;
    }
    const checkParentChecks = (data:any)=>{
        //console.log(data)
        const checkList = [];
        let treeInfo:any = JSON.parse(JSON.stringify(data));
        treeInfo.children.forEach((i:any,index:number)=>{
            if(i.children.length === 0){
                checkList.push([]);
                return;
            }
            i.children.forEach((j:any,jIndex:number)=>{
              j.children.forEach((k:any, kIndex:number)=>{
                k.children.forEach((l:any, lIndex:number)=>{
                  
                })
              })
            })
        })
        return data;
    }
    const checkBoxCategory = (element:any,TreeArr:any) => {
      const check = element.checked;
      let treeInfo:any = JSON.parse(JSON.stringify(treeData[0]));
      if(TreeArr.length === 1){
        treeInfo.isChecked = check;
        treeInfo.children.forEach((i:any)=>{
            i.isChecked = check;
            i.children.forEach((j:any)=>{
              j.isChecked = check;
              j.children.forEach((k:any)=>{
                k.isChecked = check;
                k.children.forEach((l:any)=>{
                  l.isChecked = check;
                })
              })
            })
        });
      }else if(TreeArr.length === 2) {
        const reverserArr = getReversedArray(treeInfo.children)
        reverserArr[TreeArr[1]].isChecked = check;
        reverserArr[TreeArr[1]].children.map((a:any)=> {
          a.isChecked = check;
          a.children.map((b:any)=> {
            b.isChecked = check;
          })
        });
        if(check){
            treeInfo.isChecked = check;
        }
        treeInfo.children = getReversedArray(reverserArr);
      } else if(TreeArr.length === 3) {
        const revtempChilds = getReversedArray(treeInfo.children);
        const revchildrens = getReversedArray(revtempChilds[TreeArr[1]].children);
        revchildrens[TreeArr[2]].isChecked = check;
        if(check){
          treeInfo.isChecked = check;
          revtempChilds[TreeArr[1]].isChecked = true;
        }
        revchildrens[TreeArr[2]].children.map((a:any)=> {
          a.isChecked = check;
          a.children.map((b:any)=> {
            b.isChecked = check;
          })
        });

        //checking for checked values


        revtempChilds[TreeArr[1]].children = getReversedArray(revchildrens);
        treeInfo.children = getReversedArray(revtempChilds);

      }else if(TreeArr.length === 4) {
        const revtempChilds = getReversedArray(treeInfo.children);
        const revchildrens = getReversedArray(revtempChilds[TreeArr[1]].children);
        const revItem = getReversedArray(revchildrens[TreeArr[2]].children);
        revItem[TreeArr[3]].isChecked = check;
        
        if(check){
          revtempChilds[TreeArr[1]].isChecked = true;
          revchildrens[TreeArr[2]].isChecked = true;
        }
        revtempChilds[TreeArr[2]].children = getReversedArray(revItem)
        revtempChilds[TreeArr[1]].children = getReversedArray(revchildrens);
        treeInfo.children = getReversedArray(revtempChilds);
      }
      const data = checkParentChecks(treeInfo);
      setTreeData([data])
      //console.log('structured Data', treeInfo)
      let categoryListing = categoryLists.map((i:any)=>i);
      categoryListing[categoryItem] = data;
      setcategoryLists(categoryListing);
    }
    const checkAllCategories = (e:any) =>{
      const check = e.target.checked;
      const allList = [...categoryLists];
      allList.forEach((i:any)=>{
        i.isChecked = check;
        i.children.forEach((j:any)=>{
            j.isChecked = check;
            j.children.forEach((k:any)=>{
                k.isChecked = check;
                k.children.forEach((l:any)=>{
                  l.isChecked = check;
                })
            })
        })
      });
      setcategoryLists(allList)
    }
    const tabClickCategories = (e:any,item:any,index:number) =>{
      const check = e.target.checked;
      const allList = [...categoryLists];
      const editRec = item;
        editRec.isChecked = check;
        editRec.children.map((j:any)=>{
            j.isChecked = check;
            j.children.map((k:any)=>{
                k.isChecked = check;
                k.children.map((l:any)=>{
                  l.isChecked = check;
                });
            });
        });
      allList[index] = editRec;
      if(categoryItem === index){
        setTreeData([editRec]);
      }
      setcategoryLists(allList);
      
    }
    const checkEachTicketType = (e:any,ticketType:string) => {
      if(!AllData){
        return;
      }
      const check = e.target.checked;
      const allInfo = JSON.parse(JSON.stringify(AllData));
      console.log(allInfo);
      allInfo['TicketTypes'][ticketType].permissions = allInfo['TicketTypes'][ticketType].permissions.map((i:any)=>{
        i.checked = check;
        return i;
      })
      console.log(allInfo)
      //allInfo.Groups[ind].isChecked = check
      setAllData({...allInfo})
    }
    const checkIntermediateVals = (arr:any [])=>{
      // console.log(arr)
        let hasFalseVal = false;
        let hasTrueVal = false;
        arr.forEach((k:any)=>{
          if(!hasTrueVal && k.checked){
            hasTrueVal = true;
          }else if(!hasFalseVal && !k.checked){
            hasFalseVal = true;
          }
        })
        return {
          hasFalseVal:hasFalseVal,
          hasTrueVal:hasTrueVal
        }
    }
    return (
    <>
        <Box className={classes.whiteBox}>
        <AppLoader enabled={loader} />
          {AllData && Object.keys(AllData).map((i:any, Dataindex:number)=>{
            let interMediateVals:any = getIntermediateVal(i);
            let interMediate = interMediateVals.hasFalseVal && interMediateVals.hasTrueVal ? true : false;
            const isAllChecked = !interMediateVals.hasFalseVal && interMediateVals.hasTrueVal ? true : false;
            return (
                <Accordion expanded={expanded === `${i}`}   className="accordionDtls">
                            <AccordionSummary aria-controls={`d-content`} id={`d-header`} className='accrodTitle'>
                                <Box className={`${classes.row} ${classes.justifyBetween} w-100`}>
                                    <Box>
                                        <Typography> {i ==='TicketTypes' ? 'Ticket Types' : i } </Typography>
                                    </Box>
                                    <Box className={`${classes.row} ${classes.alignItemsCenter}`} >
                                        <Box className={classes.roleAllowAccessBtn}> 
                                            <FormControlLabel
                                                value=''
                                                control={
                                                    <Checkbox size="small" 
                                                        onChange={(e:any)=>allowAllAccess(e,i)}
                                                        name={i}
                                                        checked={isAllChecked}
                                                        indeterminate={interMediate}
                                                    />
                                                }
                                                label='Allow all access'
                                                labelPlacement="end"
                                                className="sml-txt roles-checkbox"
                                            />
                                        </Box>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        {expanded !== i  ? 
                                            <KeyboardArrowDownOutlinedIcon className="pointer" onClick={()=>handleChange(`${i}`)}/> :
                                            <KeyboardArrowUpOutlinedIcon className="pointer" onClick={()=>handleChange('')}/>
                                        }
                                    </Box>
                                </Box>
                                
                            </AccordionSummary>
                            <AccordionDetails>
                            <Grid container spacing={0} alignItems="center"> 
                            {i === 'TicketTypes' ? 
                              Object.keys(AllData[i]).map((j:any,ind:number)=>{
                                const data =  checkIntermediateVals(AllData[i][j].permissions);
                                const interMediate = data.hasFalseVal && data.hasTrueVal ? true : false;
                                const isAllChecked = !data.hasFalseVal && data.hasTrueVal ? true : false;
                                return (
                                  <Grid item xs={6} md={12}>
                                      <div className={`p-1 ${ind % 2 === 1 ? '' : 'stripe'}`}>
                                      <Grid container spacing={0} >  
                                          <Grid item xs={6} md={3}>
                                                  <Box>
                                                    <Typography variant="body2" className={`${classes.linkTitle} ${classes.rolesBlueTitle}`}>
                                                      {j} 
                                                    </Typography>
                                                      <FormControlLabel
                                                        checked={AllData[i][j].permissions.some((x:any)=>x.checked)}
                                                        value=''
                                                        control={
                                                            <Checkbox size="small"
                                                                onChange={(e:any)=>{checkEachTicketType(e,j)}} 
                                                                checked={isAllChecked}
                                                                indeterminate={interMediate}
                                                            />
                                                        }
                                                        label='Select All'
                                                        labelPlacement="end"
                                                        className="sml-txt roles-checkbox"
                                                      />
                                                   
                                                  </Box>
                                          </Grid>
                                          <Grid item xs={6} md={9}>
                                            {
                                              AllData?.[i]?.[j]?.permissions && AllData[i][j].permissions.map((k:any,index:number)=>{
                                                return (
                                                  <FormControl >
                                                      <FormGroup aria-label="position" row>
                                                        <div className={`flx-20'}`}>
                                                            <FormControlLabel
                                                                checked={k.checked ? true : false}
                                                                value=''
                                                                control={
                                                                    <Checkbox size="small"
                                                                        onChange={(e:any)=>{checkboxHandler(e,i,j,index)}} 
                                                                    />
                                                                }
                                                                label={k.name}
                                                                labelPlacement="end"
                                                                className="sml-txt roles-checkbox"
                                                            />
                                                        </div>
                                                      </FormGroup>
                                                  </FormControl>
                                                )
                                              })
                                            }
                                          </Grid>

                                      </Grid>

                                      </div>
                                  </Grid>
                                )
                              })
                            :
                              <>
                              {AllData[i] && AllData[i].map((j:any,ind:number)=>{
                                return (
                                  <Grid item xs={6} md={12}>
                                      <div className={`p-1 ${ind % 2 === 1 ? '' : 'stripe'}`}>
                                      <Grid container spacing={0} >  
                                          <Grid item xs={6} md={i === 'MailService' ? 6 :3}>
                                            <Box><Typography variant="body2" className={`${classes.linkTitle} ${classes.rolesBlueTitle}`}>{j.name}</Typography></Box>
                                            {i === 'MailService' &&
                                              <Box><Typography variant="body2">{j.email}</Typography></Box>
                                            }
                                          </Grid>
                                          <Grid item xs={6} md={i === 'MailService' ? 6 :9}>
                                                  <FormControl >
                                                      <FormGroup aria-label="position" row>
                                                        <div className={`flx-20'}`}>
                                                            <FormControlLabel
                                                                checked={j.isChecked ? true : false}
                                                                value=''
                                                                control={
                                                                    <Checkbox size="small"
                                                                        onChange={(e:any)=>{checkboxGroupHandler(e,i,ind)}}
                                                                    />
                                                                }
                                                                label='Allow access'
                                                                labelPlacement="end"
                                                                className="sml-txt roles-checkbox"
                                                            />
                                                        </div>
                                                      </FormGroup>
                                                  </FormControl>
                                          </Grid>

                                      </Grid>

                                      </div>
                                  </Grid>
                                )
                              })
                              }
                              </>
                            }
                            </Grid>
                            </AccordionDetails>
              </Accordion>
            )
          })}
          {priorityLists.length > 0 &&
            <>
              {dummyRecord.map((item:any)=>{
                const checkVals = priorityCheckStatus();
                  return(
                  <Accordion expanded={expanded === 'priorities'}   className="accordionDtls">
                  <AccordionSummary aria-controls={`d-content`} id={`d-header`} className='accrodTitle'>
                    <Box className={`${classes.row} ${classes.justifyBetween} w-100`}>
                        <Box>
                            <Typography> Priorities </Typography>
                        </Box>
                        <Box className={`${classes.row} ${classes.alignItemsCenter}`} >
                            <Box className={classes.roleAllowAccessBtn}> 
                                <FormControlLabel
                                    value=''
                                    control={
                                        <Checkbox size="small" 
                                            indeterminate={checkVals.hasFalseVal && checkVals.hasTrueVal ? true : false}
                                            onChange={(e:any)=>{allowAllAccessPriority(e)}}//allowAllAccess('',0)
                                            name={''}
                                            checked={!checkVals.hasFalseVal && checkVals.hasTrueVal ? true : false}
                                        />
                                    }
                                    label='Allow all access'
                                    labelPlacement="end"
                                    className="sml-txt roles-checkbox"
                                />
                            </Box>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {expanded !== 'priorities'  ? 
                                <KeyboardArrowDownOutlinedIcon className="pointer" onClick={()=>handleChange('priorities')}/> :
                                <KeyboardArrowUpOutlinedIcon className="pointer" onClick={()=>handleChange('')}/>
                            }
                        </Box>
                    </Box>              
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={0} alignItems="center">   
                          {priorityLists.map((j:any,ind:number)=>{
                            //console.log('priorityLists',j)
                              return(
                                <>
                                  <Grid item xs={6} md={12}>
                                          <div className={`p-1 ${ind % 2 === 1 ? '' : 'stripe'}`}>
                                          <Grid container spacing={0} >  
                                              <Grid item xs={6} md={3}>
                                                  <Box>
                                                      <Tooltip title={`Ticket Types : ${j.ticketTypes.join(', ')}`} placement="top">
                                                        <Typography variant="body2" className={`${classes.linkTitle} ${classes.rolesBlueTitle}`}>{j.name}</Typography>
                                                      </Tooltip>
                                                  </Box>
                                              </Grid>
                                              <Grid item xs={6} md={9}>
                                                {
                                                  j.permissions && j.permissions.map((k:any,index:number)=>{
                                                    //console.log(k)
                                                    return (
                                                      <FormControl >
                                                          <FormGroup aria-label="position" row>
                                                            <div className={`flx-20'}`}>
                                                                <FormControlLabel
                                                                   checked={k.checked ? true : false}
                                                                    value=''
                                                                    control={
                                                                        <Checkbox size="small"
                                                                            onChange={(e:any)=>checkPriorityHandler(e,ind,index)} 
                                                                        />
                                                                    }
                                                                    label={k.name}
                                                                    labelPlacement="end"
                                                                    className="sml-txt roles-checkbox"
                                                                />
                                                            </div>
                                                          </FormGroup>
                                                      </FormControl>
                                                    )
                                                  })
                                                }
                                              </Grid>
    
                                          </Grid>
    
                                          </div>
                                      </Grid>
                                </>
                              )
                          })
                        }
                    </Grid>
                  </AccordionDetails>
                  </Accordion>
                  )
              })}
            </>
          }


          {categoryLists.length > 0 &&
          <>
            {dummyRecord.map((item:any)=>{
              const checkVals = categoryCheckStatus();
                return(
                <Accordion expanded={expanded === 'categories'}   className="accordionDtls">
                  <AccordionSummary aria-controls={`d-content`} id={`d-header`} className='accrodTitle'>
                      <Box className={`${classes.row} ${classes.justifyBetween} w-100`}>
                          <Box>
                              <Typography> Categories </Typography>
                          </Box>
                          <Box className={`${classes.row} ${classes.alignItemsCenter}`} >
                              <Box className={classes.roleAllowAccessBtn}> 
                                  <FormControlLabel
                                      value=''
                                      control={
                                          <Checkbox size="small" 
                                              onChange={(e:any)=>{checkAllCategories(e)}}//allowAllAccess('',0)
                                              name=''
                                              checked={!checkVals.hasFalseVal && checkVals.hasTrueVal ? true : false}
                                              indeterminate={checkVals.hasFalseVal && checkVals.hasTrueVal ? true : false}
                                          />
                                      }
                                      label='Allow all access'
                                      labelPlacement="end"
                                      className="sml-txt roles-checkbox"
                                  />
                              </Box>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              {expanded !== 'categories'  ? 
                                  <KeyboardArrowDownOutlinedIcon className="pointer" onClick={()=>handleChange('categories')}/> :
                                  <KeyboardArrowUpOutlinedIcon className="pointer" onClick={()=>handleChange('')}/>
                              }
                          </Box>
                      </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                  <Grid container spacing={0} >   
                      <Grid item xs={6} md={3}>
                          {categoryLists.map((i:any,index:number)=>{
                                return(
                                  <>
                                    <Tooltip title={`Ticket Types : ${i.ticketTypes.join(', ')}`} placement="top">
                                        <Typography variant="body2"  className={`${categoryItem === index ? 'selected' : ''} btn-txt roles-categories-list`}
                                        >
                                          <span className="col" onClick= {()=>{
                                              categoryClickHandler(i,index)
                                            }}
                                            >{i.title}</span>
                                          <Checkbox size="small" 
                                              indeterminate={false}
                                              onChange={(e:any)=>{tabClickCategories(e,i,index)}}//allowAllAccess('',0)
                                              checked={i.isChecked}
                                              className="sml-txt roles-checkbox"    
                                          />
                                          
                                        </Typography>
                                    </Tooltip>
                                    <br />
                                  </>
                                )
                            })
                          }
                      </Grid>
                                      
                        <Grid item xs={6} md={9}>
                          <SortableTree
                            treeData={treeData}
                            isVirtualized={false}
                            canDrag={false}
                            onChange={(e:any)=>{updateTreeData(e)}}
                            //onChange={}
                            generateNodeProps={({ clickedNode, node, path, treeIndex, lowerSiblingCounts, upperSiblingCounts }:any) => ({
                              title: (
                                <FormControlLabel
                                  value=''
                                  control={
                                      <Checkbox size="small" 
                                          indeterminate={false}
                                          onChange={(e:any)=>{
                                            checkBoxCategory(e.target , lowerSiblingCounts)
                                          }
                                            //console.log(e.target,clickedNode, node, path, treeIndex)}
                                          }//allowAllAccess('',0)
                                          name={node.title}
                                          checked={node.isChecked}
                                      />
                                  }
                                  label={node.title}
                                  labelPlacement="end"
                                  className="sml-txt roles-checkbox"
                                />
                              ),
                            })}
                          />
                          </Grid>
                    </Grid>

                  </AccordionDetails>
                </Accordion>
                )
              })}
          </>
          }

          


           

        </Box>
    </>
    )
})
export default UserAdmin;