import {
  addNewTicket,
  getTickets,
  updateNewTicket,
} from "../../../services/dashboard/dashboard.service";
import { uploadTicketAttachment } from "../../../services/fileUpload/FileUpload";
import { apiStatus } from "../apiStatus.action";
import { Loader } from "../Loader";
import { ActionType } from "../Snackbars";

export enum Dashboard {
  ADD_TICKET = "ADD_TICKET",
  ALL_TICKETS = "ALL_TICKETS",
  UPDATE_TICKET = "UPDATE_TICKET",
  ADDTIONAL_INFO_DASHBOARD = "ADDTIONAL_INFO_DASHBOARD",
}

export interface INewTicketNew {
  createdDateTime?: Date | null | string;
  id?: string | number;
  isActive?:boolean;
  ticketLogs: {
    id?: string;
    ticketId?: string | number;
    customId?: string | number;
    title: string;
    description: string;
    nextActionDescription?: string;
    category: number | string;
    status: number | string;
    ticketType: number | string;
    priority: number | string;
    urgency: number | string;
    source: number | string;
    sla: number | string;
    openDateTime: Date | null | string;
    dueDateTime: Date | null | string;
    firstResponseDueDateTime?: Date | null | string;
    scheduleDateTime: string;
    createdBy?: string;
    createdDateTime?: string;
    updatedBy?: string;
    updatedDateTime?: string;
    filetypeId?: number;
    isActive?: boolean;
    fileList?: any;
    contactId?: number;
    groupId?: number;
    ownerId?: number | null;
    user?: {
      id: number;
      name: string;
      userType: string;
    };
  };
}

export interface INewTicket {
  id?: string;
  customId?: string | number;
  title: string;
  description: string;
  category: number | string;
  status: number | string;
  ticketType: number | string;
  priority: number | string;
  urgency: number | string;
  source: number | string;
  sla: number | string;
  openDateTime: Date | null | string;
  dueDateTime: Date | null | string;
  firstResponseDueDateTime?: Date | null | string;
  scheduleDateTime: string;
  createdBy?: string;
  createdDateTime?: string;
  updatedBy?: string;
  updatedDateTime?: string;
  filetypeId?: number;
  isActive?: boolean;
  fileList?: any;
  contactId?: number;
  groupId?: number;
  ownerId?: number | null;
}

export interface INewTicketAction {
  type: Dashboard;
  payload: INewTicket | INewTicketNew;
}
export const addTicket = (
  ticket: INewTicket | INewTicketNew,
  fileId: string,
  navigateToDashboard?: any
) => {
  return async (dispatch: any) => {
    let newTicket:any = await addNewTicket(ticket);
    if (newTicket) {
      dispatch({
        type: Dashboard.ADD_TICKET,
        payload: newTicket,
      });
      let tickes = await getTickets();

      if (fileId) {
        let attachment = {
          ticketLogId: newTicket?.data?.ticketLogs?.id,
          attachmentId: fileId,
        };

        // let fileAttachment = await uploadTicketAttachment(attachment);
      }

      dispatch({
        type: Dashboard.ALL_TICKETS,
        payload: tickes?.data,
      });
      dispatch(
        apiStatus({
          message: "string",
          progress: false,
          error: false,
        })
      );
      navigateToDashboard && navigateToDashboard();
      // dispatch(Loader({ open: false }));
      // dispatch({
      //   type: ActionType.SNACKBAR,
      //   payload: {
      //     message: "New Ticket Added Successfully",
      //     severity: "success",
      //     open: true,
      //   },
      // });
    } else {
      dispatch(
        apiStatus({
          message: "string",
          progress: false,
          error: true,
        })
      );
    }
  };
};

export const additionalInfo = (data: any) => {
  return {
    type: Dashboard.ADDTIONAL_INFO_DASHBOARD,
    payload: data,
  };
};

export const updateTicket = (
  ticket: INewTicket | INewTicketNew,
  id: string,
  navigateToDashboard?: any
) => {
  // try {
  //   const success = await axios.post('http://www...', params);
  //   return onSuccess(success);
  // } catch (error) {
  //   return onError(error);
  // }
  return async (dispatch: any) => {
    dispatch(
      apiStatus({
        message: "string",
        progress: true,
        error: false,
      })
    );
    let newTicket = await updateNewTicket(ticket, id);
    dispatch(
      apiStatus({
        message: "string",
        progress: false,
        error: false,
      })
    );
    dispatch({
      type: Dashboard.ADD_TICKET,
      payload: newTicket,
    });
    let tickes = await getTickets();
    dispatch({
      type: Dashboard.ALL_TICKETS,
      payload: tickes?.data,
    });
    navigateToDashboard && navigateToDashboard();

    dispatch({
      type: ActionType.SNACKBAR,
      payload: {
        message: "Ticket Assigned Successfully",
        severity: "success",
        open: true,
      },
    });
  };
};

export const getAllTickets = () => {
  return async (dispatch: any) => {
    let tickes = await getTickets();
    dispatch({
      type: Dashboard.ALL_TICKETS,
      payload: tickes?.data,
    });
  };
};


