import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PanToolAltOutlinedIcon from '@mui/icons-material/PanToolAltOutlined';
import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import SortableTree from "react-sortable-tree";
import { getCategory } from "../../../redux/actions/SystemManagement/AddCategory";
import { getTicketProperties, storeWorkFlowFilters } from "../../../redux/actions/Workflow/workflow";
import { IState } from "../../../redux/reducers/rootReducers";
import BasicButton from "../../common/button/BasicButton";
import CustomCreatable from "../../common/customSelect/CustomCreate";
import CustomSelect from "../../common/customSelect/CustomSelect";
import CustomFormLabel from "../../common/form-label/CustomFormLabel ";
import { createStyles, makeStyles } from '@mui/styles';
import OrgDropDown , {ContactDropDownLazy} from '../../common2/OrgDropDown';
import { triggerAppAlert } from '../../../redux/actions/SelfServicePortal/ContactAid/ContactAid';
import ChooseAllCategory from '../../common/ChooseAllCategory';
const BoolOptions = [
    {id : 1,label : "true",value : "true"},
    {id : 2,label : "false",value : "false"}
];
const AddNewFilterValue = (props: any) => {
    const { properties=[],workflowFilterOptions,filterPropertyObj, categoryList, allCategoryTree, dispatch,allResponses,editVal=null,callback } = props;
    const [isOr,setIsOr] = useState<boolean>(false);
    const [showCategory,setShowCategory] = useState(false);

    // ____ DROPDOWN VALUES ____
    const [conditionOptions,setConditionOptions] = useState<any>([]) 
    const [valueOptions,setValueOptions] = useState<any>([]) // final dropdown options
    const [propertyVal,setPropertyVal] = useState<any>(null);
    const [conditionVal,setConditionVal] = useState<any>(null);
    const [value,setValue] = useState<any>(null);
    
    
    useEffect(()=>{
        console.log("AddNewFilterValue_editVal",editVal)
        const {property=null,condition=null,value=null,isOr=false} = editVal?.item || {}; 
        setPropertyVal(filterPropertyObj?.[property?.id] || null);
        if(filterPropertyObj?.[property?.id]){
            prefillData(filterPropertyObj?.[property?.id]);
        }
        setConditionVal(condition);
        setIsOr(isOr);
        setValue(value);
    },[editVal])

    useEffect(()=>{
        if(!allCategoryTree || allCategoryTree?.length === 0){
            dispatch(getCategory());
        }
        if(!properties){
            dispatch(getTicketProperties(allResponses));
        }
    },[])
   
    const hideCategory = () =>{
        setShowCategory(false)
    }
    const ChooseCategoryCB = (ids:any={}) =>{
        console.log("ChooseCategoryCB",ids)
        setValue(ids)
    }

    const onSubmit = debounce(() =>{
        
        if(!propertyVal || !conditionVal || !value){
            dispatch(triggerAppAlert("Please fill all the fields to add a filter!","warning"));
            return;
        }
        const newVal = {...(editVal?.item || {}) , property : propertyVal,condition : conditionVal,value,isOr}
        console.log("onSubmitonSubmit",workflowFilterOptions,editVal)
        let newArr:any = [];
        if(editVal?.item){
            newArr = workflowFilterOptions.map((i:any,index:number)=>{
                if(editVal?.index === index){
                    i = newVal;
                }
                return i;
            })
        }else{
            newArr = [...(workflowFilterOptions || []),newVal];
        }
        dispatch(storeWorkFlowFilters(newArr));
        
        setTimeout(() => {
            clearHandler();
            callback();
        }, 500);
    },200)
    const clearHandler = () =>{
        setPropertyVal(null)
        setConditionVal(null)
        setValue(null)
        setIsOr(false);
    }
    const prefillData = (val:any)=>{
        console.log("propertyValval",val)
        const conditions = (val?.conditionalOperatorList || []).map((i:any)=>{
            return {...i,label:i.name,value:i.name}
        });
        // const valueList =  (val?.inputvalueList || []).map((i:any)=>{
        //     return {id:i.id,label:i.displayName,value:i.name}
        // });
        setValueOptions(val?.inputvalueList || [])
        // setPropertyVal(val)
        setConditionOptions(conditions)
        // setValue(null)
        // setConditionVal(null)
   }
    const onChangeProperty = (val:any)=>{
        console.log("propertyValval",val)
        const conditions = (val?.conditionalOperatorList || []).map((i:any)=>{
            return {...i,label:i.name,value:i.name}
        });
        // const valueList =  (val?.inputvalueList || []).map((i:any)=>{
        //     return {id:i.id,label:i.displayName,value:i.name}
        // });
        setValueOptions(val?.inputvalueList || [])
        setPropertyVal(val)
        setConditionOptions(conditions)
        setValue(null)
        setConditionVal(null)
   }
    return (
        <>
            <Box className={``}>
                
                <Box className="col">
                    <Box>
                    {/* {workflowFilterOptions?.length} */}
                        <Box onClick={() => {setIsOr(!isOr)}} className={` ${isOr ?  "or" : "and" } filter-webform-toggler filters-option`}>
                            <span></span>
                            <Typography>AND</Typography>
                            <Typography>OR</Typography>
                        </Box>
                    </Box>

                    <Box className="">
                        <CustomFormLabel
                            labelName={"Choose Property"}
                            isMandotary={false}
                            classNames='sml-txt filter-heading'
                        />
                        <CustomSelect
                            isOpen={false}
                            placeholder="Select Property"
                            width={"100%"}
                            name="property"
                            isColor={false}
                            isDisabled={false}
                            isError={false}
                            defaultValue={propertyVal}
                            options={properties}
                            handleChange={onChangeProperty}
                            isMulti={false}
                            helperText={""}
                            customClassNames="sml-txt-dropdown"
                        />
                    </Box>

                    <Box className="">
                        <CustomFormLabel
                            labelName={"Choose Condition"}
                            isMandotary={false}
                            classNames='sml-txt filter-heading'
                        />
                        <CustomSelect
                            isOpen={false}
                            placeholder="Select Condition"
                            width={"100%"}
                            name="condition"
                            isColor={false}
                            isDisabled={false}
                            isError={false}
                            defaultValue={conditionVal}
                            options={conditionOptions}
                            handleChange={(val:any)=>{
                                setConditionVal(val)
                           }}
                            isMulti={false}
                            helperText={""}
                            customClassNames="sml-txt-dropdown"
                        />
                    </Box>
                    {/* {propertyVal?.name} */}

                {/* ______________ FINAL DROPDOWN ______________ */}
                    <>{conditionVal && <>
                            {(propertyVal?.name === "OrganisationId") && 
                                <>
                                    <OrgDropDown 
                                        callback={(val:any)=>{
                                            setValue(val)
                                        }} 
                                        defaultValue={value}
                                    />
                                </>
                            }

                            {(propertyVal?.name === "ContactId") && 
                                <>
                                    <ContactDropDownLazy 
                                        callback={(val:any)=>{
                                            setValue(val)
                                        }} 
                                        defaultValue={value}
                                    />
                                </>
                            }

                            {(propertyVal?.name !== "OrganisationId" && propertyVal?.name !== "ContactId") && 
                                <>
                                    {(propertyVal?.name === "CategoryId" && conditionVal?.type === "Multiple") ?

                                        <Box onClick={() => { setShowCategory(!showCategory) }}>
                                            <Typography>Choose category</Typography>
                                            {value && Object.keys(value)?.length ? <Box>
                                                    <Typography className="btnLike">{Object.keys(value)?.length}  items selected! <EditOutlinedIcon /></Typography>
                                                </Box> : <>
                                                <Box>
                                                    <Typography className="btnLike">Click here to Choose <PanToolAltOutlinedIcon /></Typography>
                                                </Box>
                                            </>}
                                        </Box> :
                                        <Box className="workflow-choose-value">
                                            <CustomFormLabel
                                                labelName={"Choose Value"}
                                                isMandotary={false}
                                                classNames='sml-txt filter-heading'
                                            />
                                            {/* {conditionVal?.type === "Multiple" ? "multi" : "single"} */}
                                            {propertyVal?.isUserInput ?
                                                <CustomCreatable
                                                    name="value"
                                                    options={propertyVal?.dataType === "bool" ? BoolOptions : valueOptions}
                                                    defaultValue={value}
                                                    placeholder="Select a tag"
                                                    width={"100%"}
                                                    isMulti={conditionVal?.type === "Multiple" ? true : false}
                                                    handleInputChange={(e: any) => {
                                                        if (e && e?.[e?.length - 1]?.label && e[e.length - 1].label.trim() === "") return;
                                                        setValue(e)
                                                        //formik.setFieldValue("tags", e);
                                                    }}
                                                />
                                                :
                                                <CustomSelect
                                                    isOpen={false}
                                                    placeholder="Select Value"
                                                    width={"100%"}
                                                    name="value"
                                                    isColor={false}
                                                    isDisabled={false}
                                                    isError={false}
                                                    defaultValue={value}
                                                    options={propertyVal?.dataType === "bool" ? BoolOptions : valueOptions}
                                                    handleChange={(e:any)=>{
                                                        setValue(e)
                                                    }}
                                                    isMulti={conditionVal?.type === "Multiple" ? true : false}
                                                    helperText={""}
                                                    customClassNames="sml-txt-dropdown"
                                                />
                                            }
                                        </Box>
                                    }
                                </>
                            }
                    </>}</>

                    <Box className="">
                        <Box className='pt-1 text-center'>
                            <Button variant="contained" onClick={()=>{
                                clearHandler();
                                callback();
                            }}>Clear</Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button variant="contained" onClick={onSubmit}>{!editVal ? "Save" : "Update"}</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {(showCategory) && <>
                {/* <ChooseCategory closeHandler={hideCategory} categoryList={categoryList} allCategoryTree={allCategoryTree} callback={ChooseCategoryCB} defaultValue={value} /> */}
                <ChooseAllCategory 
                    closeHandler = {hideCategory}
                    callback= {(values)=>{
                        console.log("ChooseAllCategory_callback",values)
                        ChooseCategoryCB(values)
                        hideCategory();
                    }}
                    defaultValue={value||{}}
                />
            </>}
        </>
    )
}









export const ChooseCategory = (props:any) =>{
    const {closeHandler,defaultValue,allCategoryTree,callback} = props;
    console.log('defaultValuedefaultValue',allCategoryTree)
    const classes = useStyles();
    const [treeData, setTreeData] = useState<any>([]);
    const [allCategoryData, setAllCategoryData] = useState<any>([]);
    const [selectedIndex, setSelectedIndex] = useState<any>(0);
    const [checkedIDs, setcheckedIDs] = useState<any>(defaultValue?defaultValue:[]);
    useEffect(()=>{
        console.log('allCategoryTree_allCategoryTree',allCategoryTree)
        if(allCategoryTree?.length > 0){
            setAllCategoryData(allCategoryTree)
            setTreeData([allCategoryTree?.[0]?.categoryList]);//.categoryList
        }
    },[])
    const getChildIds = (node:any) =>{
        const ids:any = [];
        ids.push(node?.id);
        node?.children && node?.children.map((i:any)=>{
            ids.push(i.id);
            i?.children && i?.children.map((j:any)=>{
                ids.push(j.id);
                j?.children && j?.children.map((k:any)=>{
                    ids.push(k.id);
                })
            })
        })
        return ids;
    }
    const checkBoxCategory = (element:any,node:any) => {
        console.log('====================================');
        console.log("nodenodenodenode",node);
        console.log('====================================');
        const checked = element.checked;
        if(checked){
            setcheckedIDs((nds:any)=>nds.concat(getChildIds(node)))
        }else{
            const idsToRemove = getChildIds(node);
            setcheckedIDs((nds:any)=>nds.filter((i:any)=>!idsToRemove.includes(i)))
        }
        console.log('checkBoxCategory', getChildIds(node),node)
    }
    const updateTreeData = (e:any) =>{
        setTreeData(e);
    }
    const chooseCategory = (i:any,index:number)=>{
        console.log("chooseCategory",treeData,i,index,allCategoryData)
        setTreeData([allCategoryData?.[index]?.categoryList])
        setSelectedIndex(index)
    }
    const onSubmit = () =>{
        callback(checkedIDs);
        closeHandler();
    }
    return (<>
        {allCategoryTree?.length > 0 ? <></> : <>No Categories available!</>}
        <Box className={classes.popupHolder}>
            <Box className="modal-dialog">
                <Box className="modal-content">
                    <Box className="modal-header">
                        Choose Category 
                        <Box>
                        <CloseIcon className="modalCloseBtn" onClick={()=>{closeHandler()}} />
                        </Box>
                    </Box>
                    <Box className="modal-body">
                        <Box className="p-1">
                            <Box className={classes.row}>
                                <Box>
                                    {allCategoryData.map((i:any,index:number)=>{
                                        return(
                                            <Box className={`${classes.categoryBtn} ${selectedIndex === index ? "active" : ""} categoryPopup-TabLinks`}>
                                                <Typography onClick={()=>{chooseCategory(i,index)}} >{i?.name}</Typography>
                                                <FormControlLabel
                                                    value=''
                                                    control={
                                                        <Checkbox size="small" 
                                                            indeterminate={false}
                                                            onChange={(e:any)=>{
                                                                checkBoxCategory(e.target ,i?.categoryList)
                                                            }
                                                            }
                                                            name={i.title}
                                                            checked={checkedIDs.includes(i?.id)}
                                                        />
                                                    }
                                                    label={i.title}
                                                    labelPlacement="end"
                                                    className="sml-txt"
                                                    />
                                            </Box>
                                        )
                                    })}
                                </Box>










                                <Box>
                                    <Box className="aside t-0">
                                        <SortableTree
                                            treeData={treeData}
                                            isVirtualized={false}
                                            canDrag={false}
                                            onChange={(e:any)=>{updateTreeData(e)}}
                                            //onChange={}
                                            generateNodeProps={({ clickedNode, node, path, treeIndex, lowerSiblingCounts, upperSiblingCounts }:any) => ({
                                            title: (
                                                <FormControlLabel
                                                value=''
                                                control={
                                                    <Checkbox size="small" 
                                                        indeterminate={false}
                                                        onChange={(e:any)=>{
                                                            //console.log('nodeCLicked',clickedNode, node, path, treeIndex, lowerSiblingCounts, upperSiblingCounts)
                                                            checkBoxCategory(e.target ,node)
                                                        }
                                                            //console.log(e.target,clickedNode, node, path, treeIndex)}
                                                        }//allowAllAccess('',0)
                                                        name={node.title}
                                                        checked={checkedIDs.includes(node?.id)}
                                                    />
                                                }
                                                label={node.title}
                                                labelPlacement="end"
                                                className="sml-txt"
                                                />
                                            ),
                                            })}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="modal-footer">
                        <Box className="text-right p-1">
                        <BasicButton
                            onClick={closeHandler}
                            type={"cancel"}
                            isLoading={false}
                            isDisabled={false}
                            color="primary"
                            size="large"
                            label={"Cancel"}
                            variant={"outlined"}
                            endIcon={<CloseIcon sx={{ width: "20px" }} />}
                        />

                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <BasicButton
                            onClick={onSubmit}
                            type="submit"
                            label={`Save ${checkedIDs?.length} Items`}
                            isLoading={false}
                            isDisabled={false}
                            color="primary"
                            size="large"
                            variant={"contained"}
                            endIcon={<SaveIcon sx={{ width: "20px" }} />}
                        />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    </>)
}
export const useStyles = makeStyles(() =>
    createStyles({
        topRow:{
            display:"flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        filterRow:{
            position:"relative",
            "& .type-f":{
                background: "#f1f1f1",
                position: "absolute",
                fontSize: "9px",
                lineHeight: "26px",
                width: "26px",
                height: "26px",
                textAlign: "center",
                borderRadius: "50%",
                left: "0",
                top: "50%",
                transform: "translate(-50%, -50%)",
            }
        },
        toggler:{
            display: "inline-flex",
            background: "#f1f1f1",
            borderRadius: "7px",
            position: "relative",
            cursor:"pointer",
            "& p":{
                padding: "10px",
                fontSize: "10px",
                minWidth: "40px",
                minHeight: "32px",
                lineHeight: "12px",
                textAlign:"center",
                position: "relative",
                cursor:"pointer",
            },
            "& span":{
                background: "#c5d0f7",
                position: "absolute",
                width: "50%",
                height: "100%",
                borderRadius: "6px",
                transition:".5s ease-in-out",
                left: 0,
                cursor:"pointer",
            },
            "&.or ":{
                "& span":{
                    left: "50%"
                }
            }
        },
        chooseCategory:{
            marginTop : "10px",
            cursor:"pointer",
            "& p":{
                fontSize:"12px",
                "&.btnLike":{
                    border: "1px solid #ddd",
                    borderRadius: "2px",
                    display: "flex",
                    alignItems: "center",
                    padding: "6px",
                    justifyContent: "center",
                    gap: "10p"
                }
            }
        },
        categoryBtn:{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            background : "#f1f1f1",
            color : "#000",
            marginBottom : "10px",
            cursor:"pointer",
            padding:"10px",
            "&.active":{
                background: "#c5d0f7"
            },
            "& p":{
                fontSize:"10px",
                width:"100%"
            },
            "& label":{
                margin:0,
                "& span":{
                    padding: 0
                }
            }
        },
        row:{
            display:"flex",
        },
        popupHolder: {
            position: "fixed",
            top: 0,
            left: 0,
            background: "#0005",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            zIndex: 1399,
            "& .modal-dialog": {
              display: "flex",
              height: '100%',
              justifyContent: "center",
              alignItems: "center",
            },
            "& .modal-header": {
              display: "flex",
              alignItems: "center",
              padding: "20px",
              justifyContent: "space-between",
              borderBottom: "1px solid #ddd",
              paddingBottom: "10px"
            },
            "& .modal-body": {
              maxHeight: "calc(100vh - 200px)",
              overflowY: "scroll",
              padding: "0px"
            },
            "& .modal-body > div": {
              margin: "0 !important",
              maxWidth: "calc(100% - 15px)"
            },
            "& .modal-content": {
              background: "#fff",
              width: "100%",
              maxWidth: "1000px",
              borderRadius: "5px",
              //padding: "20px",
              "& img.Contactprofile": {
                width: "calc(100% - 10px)",
              },
              "& img": {
                objectFit: "cover"
              }
            }
        },
        addCondtion:{
            "& .col":{
                border : "1px solid #ddd",
                padding: "10px",
                fontSize: "12px",
                "&.pl-2":{
                    paddingLeft:"2rem !important"
                }
            },
            "& .col-auto":{
                border : "1px solid #ddd",
                padding: "10px",
                fontSize: "12px"
            }
        },
        smlBtns:{
            fontSize: "10px",
            padding: "2px 10px",
            minWidth: "auto",
            borderRadius: "3px",
            textTransform: "unset",
            marginRight: "3px",
        },
        addBtn: {
            "& p":{
                width: "max-content",
                background: "#f1f1f1",
                cursor: "pointer",
                fontSize: "12px",
                padding: "5px 15px",
                borderRadius: "4px",
            }
        }
    })
)


const mapStateToProps = (state:IState) => {
    return ({
        categoryList : state?.categoryReducer?.Data?.data,
        allCategoryTree : state?.categoryReducer?.allCategoryTree,
        properties :  state?.WorkflowReducer?.filterProperties,
        workflowFilterOptions :  state?.WorkflowReducer?.workflowFiltersVal,
        filterPropertyObj  :  state?.WorkflowReducer?.filterPropertyObj,
        allResponses :  state?.TicketsReducer?.allResponses,
        // const ContactList =  state?.ContactsReducer?.AllContactAid
    })
  };
  
export default connect(mapStateToProps)(AddNewFilterValue);