const initialState = {
    AllMailExceptionList: [],
  }
  const MailExceptionListReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'GET_MAILEXCEPTION':
            return {
                ...state,
                AllMailExceptionList: action.payload,
            };
        default:
        return state;
    }
  }
  
  export default MailExceptionListReducer;
  