import React, { ReactChild, ReactChildren, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { IState } from "../../redux/reducers/rootReducers";
export const colorPalette = [
  "#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0", "#FFB6C1", "#36B9E6", 
  // "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#FF9F40",
  //  "#9966FF", "#FFB6C1", "#36B9E6", 
  "#FFD700", "#D2691E", "#8A2BE2", "#8B0000", "#20B2AA", "#7FFF00", "#FF1493", "#A52A2A", 
  "#800080", "#800000", "#008080", "#0000FF", "#00FF00", "#FFFF00", "#00FFFF", "#C71585", 
  "#32CD32", "#8B4513", "#FF4500", "#2E8B57", "#D3D3D3", "#FF6347", "#9ACD32", "#ADFF2F", 
  "#F0E68C", "#F4A300", "#B0E0E6", "#FF8C00", "#FF00FF", "#4B0082", "#228B22", "#FFD700", 
  "#FF1493", "#C71585", "#B22222", "#C0C0C0", "#DAA520", "#A52A2A", "#008B8B", "#4B0082", 
  "#8B4513", "#8B0000", "#98FB98", "#D2691E", "#FF7F50", "#FF6347", "#4682B4", "#7CFC00", 
  "#B0C4DE", "#FFD700", "#DA70D6", "#FF6347", "#8A2BE2", "#40E0D0", "#EE82EE", "#5F9EA0", 
  "#B8860B", "#8FBC8F", "#D2B48C", "#D3D3D3", "#708090", "#F0F8FF", "#D8BFD8", "#DC143C", 
  "#FF00FF", "#7FFF00", "#6495ED", "#DC143C", "#B22222", "#006400", "#32CD32", "#ADFF2F", 
  "#FF8C00", "#98FB98", "#F0E68C", "#FF1493", "#7CFC00", "#FF4500", "#8B0000", "#4B0082", 
  "#8A2BE2", "#D3D3D3", "#F0F8FF", "#800080", "#FFD700", "#E6E6FA", "#C71585", "#4682B4"
];


interface ITheme {
  children: ReactChild | ReactChildren;
}

const Theme: React.FC<ITheme> = (props: ITheme) => {
  const toggleDarkTheme = useSelector((state: IState) => state.theme.theme);

  const lightTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: '#2F80ED',
      },
      background: {
        default: "#F0F3FE",
      },
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  const { children } = props;
  return (
    <ThemeProvider theme={toggleDarkTheme ? darkTheme : lightTheme}>
      <>{children}</>
    </ThemeProvider>
  );
};

export default Theme;
