import axios from "axios"; 
import configs from "../../../configs/config";
const getTicketStatusUrl = `${configs.BASE_URL}/Dashboard/v3`;
const getTicketTrendsUrl= `${configs.BASE_URL}/Dashboard/tickettrends`;
const getResponseOrResolutionBySlaUrl= `${configs.BASE_URL}/Dashboard/ticket/response-resolution`;
export const getDashboardDataUrl = `${getTicketStatusUrl}?ticketDashboardFilter=0`
export const userGroupInfoUrl = `${configs.BASE_URL}/Dashboard/usergroup/v4`;
  export const getTicketStatus = async (ticketDashboardFilter: number) => {
    try {
        const response = await axios.get(`${getTicketStatusUrl}?ticketDashboardFilter=${ticketDashboardFilter}`);
        const userGroupInfo = await axios.get(`${userGroupInfoUrl}`);
        response.data.group = userGroupInfo.data.group;
        response.data.resolved = response.data.ticketStatus.resolvedInPercentage;
        response.data.unResolved = response.data.ticketStatus.unResolvedInPercentage;
        response.data.responseSlaCount = response.data.responseSlainpercentage;
        response.data.resolutionSlaCount = response.data.resolutionSlainpercentage;
        response.data.ticketStatus =  response.data.ticketStatus.dataList;
        return response
      } catch (error) {
        return false
      }
  };
  export const getDashboardData = (type: number,groupIds:number[]|string[]=[]) => {
    return async (dispatch: any) => {
      try {
          const response = await axios.post(getTicketStatusUrl,{
            "GroupIds": groupIds
          });

          dispatch({
            type:"STORE_DASHBOARD_SUMMARY",
            payload : {
              ...response.data
            }
          });

        } catch (error) {
          console.log("getDashboardData",error)
        }
    }
  };
  export const getDashboardDataBKP = (type: number,groupIds:number[]|string[]) => {
    return async (dispatch: any) => {
      try {
          const urls = [`${getTicketStatusUrl}?ticketDashboardFilter=${type}`, userGroupInfoUrl,getTicketTrendsUrl];
          Promise.all(
            urls.map((endpoint: string, index: number) => axios.get(`${endpoint}`))
          ).then((all) => {
            console.log("getDashboardData>>", {
              ...all[0].data,
              ...all[1].data,
              ...all[2].data,
            });
            dispatch({
              type:"STORE_DASHBOARD_SUMMARY",
              payload : {
                ...all[0].data,
                ...all[1].data,
                trends : all[2].data,
              }
            })
            // const responses = 
            // dispatch(asyncCallback(all, callback));
          });
          return urls
        } catch (error) {
          return false
        }
    }
  };
  export const getGroupDashboardData = () => {
    return async (dispatch: any) => {
      try {
          const response = await axios(`${userGroupInfoUrl}`);
          
            dispatch({
              type:"STORE_DASHBOARD_SUMMARY_GROUP",
              payload : {
                ...response.data
              }
            });
        } catch (error) {
          return false
        }
    }
  };

  export const getTicketTrends = async () => {
    try {
        const response = await axios.get(`${getTicketTrendsUrl}`);
        return response
      } catch (error) {
        return false
      }
  };
  export const getResponseOrResolutionBySla = async () => {
    try {
        const response = await axios.get(getResponseOrResolutionBySlaUrl);
        return response
      } catch (error) {
        return false
      }
  };

  export default getTicketStatus