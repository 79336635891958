import Backdrop from "@mui/material/Backdrop";
import { makeStyles, createStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Typography, Button, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#F2F4FF",
  border: "2px solid #F2F4FF",
  boxShadow: 10,
  p: 4,
  width: "50%",
};

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      textAlign: "center",
      fontWeight: 400,
      fontSize: "24px",
      lineHight: "29px",
      color: "#212529",
    },
    subTitile: {
      width: "110px",
    },
    DateBox: {
      display: "flex",
    },
    Databoxbtm: {
      display: "flex",
      alignItems: "baseline",
    },
    dataBoxTitle: {
      fontWeight: 400,
      lineHeight: "20px",
      color: "#252525",
      fontSize: "13px",
      width: "110px",
    },
    endTime: {
      marginLeft: "15px",
    },
    btnGroup: {
      marginTop: "3rem",
      marginBottom: "3rem",
      textAlign: "center",
    },
    btn: {
      margin: "10px",
      lineHeight: "24px",
      fontSize: "13px",
      fontWeight: 400,
      color: "#ffffff",
      padding: "5px",
      width: "100px",
    },
    btnSubmit: {
      color: "#ffffff !important",
      width: " 110px !important",
      backgroundColor: "#1976d2 !important",
      "&:hover": {
        backgroundColor: "#1976d2 !important",
      },
    },
    btnCancel: {
      color: "#6989fa",
      width: "110px",
      backgroundColor: "white",
      border: "2px solid #6989fa",
      marginRight: "10px",
      "&:hover": {
        backgroundColor: "white",
      },
    },
    closeIcon: {
      float: "right",
    },
  })
);

export default function TransitionsModal({
  open,
  handleOpen,
  handleClose,
  onSubmitHandler,
  label,
  isBtnShow=true,
  ModelBody,
  btn,
  hideFooterButtons = false,
}: any) {
  const classes = useStyles();
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="calender-drag-drop">
            <Box className={classes.closeIcon}>
              <IconButton
                className="cardIconBtn"
                size="small"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              className={classes.title}
            >
              {label}
            </Typography>
            <br />
            <Box>{ModelBody}</Box>
              <Box className={classes.btnGroup}>
              {!hideFooterButtons && (

                <Button
                  variant="contained"
                  className={`${classes.btnCancel} category-ticket-type-button`}
                  onClick={handleClose}
                >
                  <CancelIcon style={{ marginRight: "5px" }} />
                  Cancel
                </Button>
              )}
                {isBtnShow && (
                  <Button
                    variant="contained"
                    className={`${classes.btnSubmit} category-ticket-type-button`}
                    onClick={onSubmitHandler}
                  >
                    <SaveIcon
                      style={{ marginRight: "12px", color: "#ffffff" }}
                    />
                    {btn ? btn : "save"}
                  </Button>
                 )} 
              </Box>
          
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
