const initialState = {
    AllEmailTemplatesSsp: [],
    AllTemplateActionsSsp:[],
    AllDynamicActionsSsp:[]
  }
  const EmailTemplatesSspReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case 'GET_TEMPLATES_SSP':
            return {
                ...state,
                AllEmailTemplatesSsp: action.payload,
            };
      case 'GET_ACTIONS_SSP':
            return {
                ...state,
                AllTemplateActionsSsp: action.payload,
            };
      case 'GET_DYNAMIC_SSP':
              return {
                  ...state,
                  AllDynamicActionsSsp: action.payload,
              };      
        default:
            return state;
    }
  }
  
  export default EmailTemplatesSspReducer;
  