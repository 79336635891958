const initialState = {
  subscriptions:[],
  featureList:[],
  featurePermissions:null,
  viewOnlyFeatures :[]
}
  const FeatureReducer = (
    state = initialState,
    action: { type: any; payload: any }) => {
      const {type,payload}= action;
    switch (type) {
      case "STORE_VIEW_ONLY_FEATURES":
        return {
          ...state,
          viewOnlyFeatures: payload,
        }
      case "STORE_ALL_FEATURE":
        return {
          ...state,
          subscriptions: payload.subscriptions,
          featureList: payload.featureList
        }
      case "STORE_ALL_PRE_DEFINED_FEATURE":
        return {
          ...state,
          featureList: payload,
        }
      case "STORE_FEATURE_PERMISSIONS":
          return {
            ...state,
            featurePermissions: payload,
      }
      default:
        return state;
    }
  };

export default FeatureReducer;
