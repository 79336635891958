import React, { useEffect, useState } from 'react'
import { Box, Card, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import ViewIcon from "../../../assest/icons/viewIcon.svg";
import CardCustom from '../../common/card';
import { useDispatch } from 'react-redux';
import { getArticles } from '../../../redux/actions/KnowledgeBase/article';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from "./../../common2/Breadcrumbs";
import TopFilter from '../../common2/TopFilter';

function ArticlesSSP() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState<any>([]);
    const [list, setList] = useState([]);
    const [seachData, setSearchData] = useState("");
    const [checked, setChecked] = React.useState(false);
    const [active, setActive] = useState([]);
    const [update, setUpdate] = useState(false);

    useEffect(()=>{
        dispatch(getArticles(getCallback,true))
    },[])
    const getCallback = (resStatus:string,res:any)=>{
        if(resStatus==="0"){
            setList(res);
        }
    }
    const handleSearch = (e: any) => {
        let lowerCase = e.target.value;

        setSearchData(lowerCase);
    };
    const filterHandler = (e: any) => { };
    const checkBoxHandler = (e: any, id: any) => {
        setChecked(e.target.checked);
    };
    const showDataHandler = (show: any) => {
        const newItems = data?.filter((val: any) => val?.isActive === show);
        setActive(newItems);
        if (show) {
            setUpdate(false);
        }
        if (show === false) {
            setUpdate(true);
        }
    };
    const updateAllHandler = (value: any, id: any) => {
        // console.log('type:', active?.isActive ? 'false': 'true')

        let arrayids: any[] = [];
        data.forEach((d: any) => {
            if (d.isActive) {
                arrayids.push(d.id);
            }
            if (d.isActive === false) {
                arrayids.push(d.id);
            }
        });
        // let result: any = multipalUpdateArticleType({ ...value }, arrayids, update)
        // result.then((resw: any) => {
        //     console.log("multipalUpdateArticleType", resw)
        //     if (resw) {
        //         getAllArticleType().then((res: any) => {
        //             setData(res)
        //         })
        //         dispatch(SnackBar("Success ! ArticleType Updated", "success"))
        //     }
        // })
    };
    const addArticleTypeHandler = () => {};
    return (
        <Box className="p-1">
            <Breadcrumbs
                data={[
                    {
                        title: 'Knowledge Base ',
                        path: '/'
                    },
                    {
                        title: 'Article List ',
                        path: '/ArticlesSSP'
                    }
                ]
                }
            />
            <Box className="pt-1"></Box>
            <TopFilter
                path='/createArticle'
                Data={data}
                SearchHandler={handleSearch}
                value={seachData}
                hideAddBtn={true}
                hideActiveBtns={true}
                filterHandler={filterHandler}
                checkBoxHandler={checkBoxHandler}
                checked={checked}
                filterItems={showDataHandler}
                updateAllHandler={updateAllHandler}
                //bulkDeleteHandler={bulkDeleteHandler}
                hideSelectAllOption={active?.length > 0 ? false : true}
                titleState={update}
                addHandler={addArticleTypeHandler}
                pathname={"/articlesList"}
                temp={active}
                redirectTo="/ContactManagement"
                placeHolder='Search Article'
            />
            <Box className="pt-1"></Box>
            <Grid container className='pt-1' spacing={1}>
                {list.map((i: any, index: number) => {
                    const { isActive,name,id,keywords } = i;
                    const objVals = `${Object.values(i)}`.toLowerCase();
                    return (
                        <>
                            {(isActive && objVals.indexOf(`${seachData}`.toLowerCase()) >= 0) && 
                                <Grid key={index} item xs={12} md={3}>
                                    <CardCustom
                                        customHeader={
                                            <Typography>
                                                ID : {id}
                                            </Typography>
                                        }
                                        cardBody={
                                            <Box>
                                                <>
                                                    <Box>
                                                        <Typography variant='body2' className="medium-title">{name}</Typography>
                                                        <hr/>
                                                        <Typography variant='body2'>{keywords}</Typography>
                                                    </Box>
                                                </>
                                            </Box>
                                        }
                                        cardFooter={
                                            <Box>
                                                <Tooltip title={`View Article`}>
                                                    <IconButton
                                                        disabled={isActive ? false : true}
                                                        size="small"
                                                        onClick={()=>{
                                                            navigate(`/ArticlesList/${id}?searchVal=${seachData}`)
                                                        }}
                                                    >
                                                        <img src={ViewIcon} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        }
                                    />
                                </Grid>
                            }
                        </>)
                })}
            </Grid>
        </Box>
    )
}

export default ArticlesSSP;
