import React from 'react';
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import Screenshot01 from "../../../assest/Screenshot01.png";
import { log } from 'util';

export default function TeamsInstruction() {

    const downloadHandler = () => {
        const fileUrl = 'https://get.videolan.org/vlc/3.0.20/win32/vlc-3.0.20-win32.exe';

        // Open the download link in a new window
        //  When ever they tell me which is the format see a youtube video and do changes 
        window.open(fileUrl, '_blank');
    }
    
    return (
        <>
            <h2>Teams page </h2>
            <Button variant="contained" onClick={downloadHandler} startIcon={<ArrowCircleDownIcon />}>
                Download
            </Button>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        {/* Content 1 */}
                        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px', height: '26%' }}>
                            <Typography variant="h4" gutterBottom>
                                Welcome to the Instruction Page
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>
                                        Step 1: Follow these instructions
                                    </Typography>
                                    <img src={Screenshot01} alt="Step 1" style={{ maxWidth: '100%', height: '80%' }} />
                                </Grid>
                            </Grid>
                        </Paper>

                        {/* Content 2 */}
                        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px', height: '26%' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>
                                        Step 2: Follow these instructions
                                    </Typography>
                                    <img src={Screenshot01} alt="Step 2" style={{ maxWidth: '100%', height: '80%' }} />
                                </Grid>
                            </Grid>
                        </Paper>

                        {/* Content 3 */}
                        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px', height: '26%' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>
                                        Step 3: Follow these instructions
                                    </Typography>
                                    <img src={Screenshot01} alt="Step 3" style={{ maxWidth: '100%', height: '80%' }} />
                                </Grid>
                            </Grid>
                        </Paper>

                        {/* Content 4 */}
                        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px', height: '26%' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>
                                        Step 4: Follow these instructions
                                    </Typography>
                                    <img src={Screenshot01} alt="Step 4" style={{ maxWidth: '100%', height: '80%' }} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* 25% for a paragraph */}
                    <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column' }}>
                        {/* Paragraph 1 */}
                        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px', height: '111%' }}>
                            <Typography variant="body1">
                                This is a paragraph 1 that takes up 25% of the screen.
                            </Typography>
                        </Paper>

                        {/* Paragraph 2 */}
                        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px', height: '111%' }}>
                            <Typography variant="body1">
                                This is a paragraph 2 that takes up 25% of the screen.
                            </Typography>
                        </Paper>

                        {/* Paragraph 3 */}
                        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px', height: '111%' }}>
                            <Typography variant="body1">
                                This is a paragraph 3 that takes up 25% of the screen.
                            </Typography>
                        </Paper>

                        {/* Paragraph 4 */}
                        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px', height: '111%' }}>
                            <Typography variant="body1">
                                This is a paragraph 4 that takes up 25% of the screen.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
