import { Box, Typography } from "@mui/material";
import React from "react";
import CommonStyles from "./../common/CommonStyles";
const useStyles = CommonStyles;
function Footer() {
  const classes = useStyles();
  return (
    <Box style={{ marginTop: "3rem", paddingBottom: "2rem" }}>
      <hr />
      <br />
      <Box className={classes.footerRow}>
        <Typography variant="h6" >
          Page Info
        </Typography>
        <p>
          You can nominate whether you want an agent to have access to settings
          under the Admin tab. Agents with admin access can be Operation Agents
          with limited access, or Super Admins with the ability to edit all
          configurations. You can have as many Super Admins with the ability to
          view and modify your billing details, or as few as one.
        </p>
      </Box>
    </Box>
  );
}

export default Footer;
