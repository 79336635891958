import React from 'react';
import { connect } from 'react-redux';
import UpgradePlan from '../pages/admin-portal/Pricings/UpgradePlan';
import { IState } from '../redux/reducers/rootReducers';
const FeatureControlRouteSSP = ({ featurePermissions=false, ...rest }:any) => {
  console.log("FeatureControlRouteSSP",featurePermissions,rest)
  return (
    <React.Fragment>
      {featurePermissions ?
        <>{rest.component}</>
        :
        <>
          <UpgradePlan currentPlanId={1}/>
        </>}
    </React.Fragment>
  );
};

const mapStateToProps = (state:IState) => {
  return ({
    featurePermissions: state?.FeatureReducer?.featurePermissions?.SSP,
  })
};

export default connect(mapStateToProps)(FeatureControlRouteSSP);