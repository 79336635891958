import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { getAllCallbackRequest } from "./ContactUsAPI";
import { tableIcons } from "../new-ticket/AddAsset";
import { ConvertZ } from "../../components/helpers/helperFunctions";

export default function CallbackRequest() {
    const [list, setList] = useState([]);

    const getContactByIdApi = async () => {
        let res = await getAllCallbackRequest();
        return res;
    };

    useEffect(() => {
        getContactByIdApi().then((res: any) => {
            if (res) {
                setList(res);
            } else {
                console.log("No data found");
            }
        });
    }, []);

    return (
        <>
            <h1>Callback Requests</h1>
                <MaterialTable
                    options={{
                        filtering: false,
                        sorting: true,
                        exportButton: true,
                        showTitle: false,
                        selection: false,
                        paginationType: "stepped",
                        pageSize: 10,
                        headerStyle: {
                            backgroundColor: "#EAF2FD",
                        },
                    }}
                    localization={{
                        toolbar: {
                            nRowsSelected: "{0} row(s) selected",
                        },
                        body: {
                            emptyDataSourceMessage: "No callback requests to display",
                        },
                    }}
                    columns={[
                        { title: "ID", field: "id", width: "50px", sorting: false },
                        { title: "Name", field: "name", width: "30%", sorting: false },
                        { title: "Mobile Number", field: "mobileNumber", width: "20%", sorting: false },
                        { title: "Mail ID", field: "mailId", width: "20%", sorting: false },
                        { title: "Available At", field: "availableAt", width: "20%", sorting: false },
                        { title: "Created At", field: "createdAt", width: "20%", sorting: true,render: (rowData: any) => ConvertZ(rowData.createdAt,"DD/MM/YYYY"), },
                      ]}
                    data={list}
                    icons={tableIcons}
                />
        </>
    );
}
