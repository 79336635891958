import { useEffect, useState } from "react";
import { getEmailExceptionByID } from "../../redux/actions/adminInterfaces/EmailException";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "./Form";
import { IState } from "../../redux/reducers/rootReducers";


export const Edit = ({ id }: { id: number }) => {
    const dispatch = useDispatch();
    const DynamicFieldsList = useSelector(
        (state: IState) => state?.EmailSettingsReducer?.AllDynamicFields
    );
    const [value, setValue] = useState<any>({
        isDataFetched: false,
        name: "",
        from: "",
        subject: "",
        body: "",
        to: null
    })

    useEffect(() => {
        if(id!==0){
            dispatch(getEmailExceptionByID(id, getTemplateByIdCB));
        }
    }, [id,DynamicFieldsList.length > 0 ]);
    const getTemplateByIdCB = (val: any) => {
        console.log("response", val,DynamicFieldsList);
        const valueToFilter = val.to;
        if(DynamicFieldsList.length !==0){
            // const filteredList = DynamicFieldsList.find((field: any) => field.value === valueToFilter);
            // console.log("filteredList",filteredList)
                setValue({
                    isDataFetched: true,
                    name: val?.name,
                    from: val?.from,
                    subject: val?.subject,
                    body: val?.body,
                    to: {
                        name:valueToFilter,
                        label:valueToFilter,
                        value:valueToFilter}
                });
        }
        console.log("filteredList",value)

    };
    return <>
        {generateForm(value?.isDataFetched, value)}
    </>
}

const generateForm =(val: any, value: any)=>{
    if(val){
        return <Form value={value} />
    }
    else{
return "loading"
    }
}