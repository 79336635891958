import Joi from "joi";

// VALIDATION FOR TICKET CREATION FORM DATA


  const surveyTemplateSchema = Joi.number()
  .required()
  .custom((value, helpers) => {
    if (value === 0) {
      return helpers.error("number.notZero");
    }
    return value;
  })
  .messages({
    "number.base": "Survey Template is required.",
    "any.required": "Survey Template is required.",
    "number.notZero": "Survey Template is required.",
  });

const subjectSchema = Joi.string()
  .trim()
  .max(100)
  .messages({
    "string.empty": "Name is required.",
    "any.required": "Name is required.",
    "string.max":
      "Name length must be less than or equal to 100 characters.",
  });
const thanksMessageSchema = Joi.string()
  .trim()
  .max(1000)
  .messages({
    "string.empty": "Thanks Message is required.",
    "any.required": "Thanks Message is required.",
    "string.max":
      "Thanks Message length must be less than or equal to 100 characters.",
  });

export const addSurveySchema = Joi.object({
    name: subjectSchema,
    webformId: surveyTemplateSchema,
    thanksMessage:thanksMessageSchema
});

export interface iSurvey {
    "id":number;
    "name":string;
    "description": string;
    "thanksMessage": string;
    "isOneResponsePerUser": boolean;
    "body" : string;
    "webformId" : number;
    "isActive": boolean;
  }