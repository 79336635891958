import { Box } from "@mui/material";
import { AssignTicketToGroupOrAgent } from "../../../services/dashboard/dashboard.service";
import { groupListDropableUpdate } from "../../../redux/actions/TicketsManagement/TicketActionUtils";
import { memo, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ActionType } from "../../../redux/actions/Snackbars";
import Sortable from "sortablejs";
import LanguageData from "../../../configs/LanguageData.json"

export const GroupTicketDropable = memo((props: any) => {
    const { id, labelAgentId, labelAgentName, labelGroupName, labelGroupid,labelIstitledrop } = props;
    const dispatch = useDispatch();
    const triggerAlert = useCallback((msg: string, type: string) => {
        dispatch({
            type: ActionType.SNACKBAR,
            payload: {
                message: msg,
                severity: type,
                open: true,
            },
        });
    }, []);
    useEffect(() => {
        let allGroups: any = document.getElementById(`${id}`);
        if (!allGroups) return;
        var sortable = Sortable.create(allGroups, {
            animation: 150,
            group: "list",
            onAdd: function (e: any) {
                ticketDropped(e)
                e.clone.replaceWith(e.item);
            },
        });
    }, [])
    const ticketDropped = async (evt: any) => {
        let groupName = evt.to.getAttribute("label-group-name");
        let groupId = evt.to.getAttribute("label-groupid");
        let ticketOwnerId = evt.item.getAttribute("data-ticket-ownerId");
        let isMyTickets = evt.item.getAttribute("data-mytickets");
        let ticketId = evt.item.getAttribute("data-ticket-id");
        ticketId = +ticketId;
        let agentName = evt.to.getAttribute("label-agent-name");
        let agentId = evt.to.getAttribute("label-agent-id");
        const ownerId = evt.to.getAttribute("label-istitledrop") === "0" ? '0' : agentId;
        const newTcktStatusId = ownerId === '0' ? 1 : 2;
//        const newTcktStatusId = ownerId === '0' ? AllInfo?.statusTypes?.New : AllInfo?.statusTypes?.NewtoMe;
        if (ownerId !== '0' && `${ticketOwnerId}` === ownerId) {
            triggerAlert(`${LanguageData.TICKECT_ALREADY_ASSIGNED}${agentName}`, 'warning')
            return;
        }
        const tempObj = {
            source: "Phone",
            statusId: newTcktStatusId,
            groupId: +groupId,
            agentId: +ownerId,
        }
        AssignTicketToGroupOrAgent("Phone", ticketId, groupId, ownerId, newTcktStatusId).then((response: any) => {
            dispatch(groupListDropableUpdate(ticketId, tempObj, isMyTickets, response));
            dispatch({
                type:"UPDATE_GROUP_INFO_VAL",
                payload : {
                    groupName : groupName,
                    agentId : +ownerId,
                    isTitleDrop : ownerId === '0' ? true : false
                }
            })
            triggerAlert(`${LanguageData.SUCCESS}${ticketId} ${LanguageData.TICKET_ASSIGNED}${agentName ? agentName : 'group'} `, 'success')
        })
    };
    return (<Box
        sx={{ height: 100, background: "#555" }} className="groupUser-overlay" label-prioritycolid={id} label-prioritycolname={"column Name"} id={id}
        label-agent-id={labelAgentId}
        label-agent-name={labelAgentName}
        label-groupid={labelGroupid}
        label-istitledrop={labelIstitledrop}
        label-group-name={labelGroupName}
    ></Box>)
})

export default GroupTicketDropable;