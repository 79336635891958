import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { getAllCalendarTickets,getAllTickets, getAllTicketsMobile } from '../../../redux/actions/TicketsManagement/TicketsManagement';
import { IState } from '../../../redux/reducers/rootReducers';
import { debounce } from 'lodash';
import MobileLoader from '../MobileCommon/MobileLoader';
interface IMobileFilterProps{
    filtersVal ?: any;
    alldata ?: any;
    dispatch ?: any;
}
function MobileFilter(props:IMobileFilterProps) {
    const {filtersVal, alldata, dispatch} = props;
    const [loader,setLoader]= useState<any>(false);

    // const dispatch = useDispatch();
    useEffect(()=>{
        console.log("filtersVal",filtersVal)
        if(alldata) {
            loadTickets();
        }
        // return ()=>console.log("cleanup function")
    },[alldata,filtersVal])
    const loadTickets = debounce(() =>{
        setLoader(true);
        const payload:any = {
            searchVal: filtersVal?.search || "",
            contacts: [],
            agents: [],
            agentsWithGroup: [],
            statuses: [],
            priorities: [],
            ticketTypes: [],
            OrgList: [],
            sortType: "unset",
            rangeVal: filtersVal?.dateRange || null,
        }
        const keys = Object.keys(filtersVal.otherValues || {})
        keys.map((i)=>{
            if(i.includes("group")){
                const splitVal = filtersVal.otherValues[i].split('and')
                payload.agentsWithGroup.push({
                    groupId : +splitVal[0],
                    agentId : +splitVal[1],
                })
            }else if(i.includes("ticketStatus")){
                if(filtersVal.otherValues[i]) payload.statuses.push(+filtersVal.otherValues[i])
            }else if(i.includes("ticketType")){
                if(filtersVal.otherValues[i]) payload.ticketTypes.push(+filtersVal.otherValues[i])
            }else if(i.includes("priority")){
                if(filtersVal.otherValues[i]) payload.priorities.push(+filtersVal.otherValues[i])
            }
        })
        if(payload.agentsWithGroup.length === 0 && filtersVal?.loggedInAgentId){
            payload.agents = filtersVal?.loggedInAgentId;
        }
        console.log("setLoadersetLoader",payload)
        window.scrollTo(0,0);
        dispatch(getAllTicketsMobile(payload,alldata,callback));
    },500)
    const callback=()=>{
        setLoader(false)
    }
    return (
        <Box>
            {/* Mobile Filter     */}
            {loader && <><MobileLoader /></>}
        </Box>
    )
}


const mapStateToProps = (state:IState) => {
    return ({
        filtersVal : state?.mobileFiltersReducer,
        alldata : state?.TicketsReducer?.allResponses,
    });
  };
  
export default connect(mapStateToProps)(MobileFilter);
