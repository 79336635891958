const initialState = {
    surveyList: [],
  };
  
  const SurveyReducer = (
    state = initialState,
    action: { type: any; payload: any }) => {
    switch (action.type) {
      case "STORE_ALL_SURVEY":
        return {
          ...state,
          surveyList: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default SurveyReducer;