import React, { createRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form } from "./Form";

export const Add = () => {
    const value ={
        name  : "",
        from : "",
        subject : "",
        body : "",
        to : null
    }
    return <>
       <Form value={value}/>
    </>
}