import {
  IAPIStatus,
  IAPIStatusType,
  ActionType,
} from "../actions/apiStatus.action";

export interface IAPIStatusProps {
  apiStatus: IAPIStatus;
}

const initState: IAPIStatusProps = {
  apiStatus: { message: "", progress: true, error: false }
};

const apiStatus = (state: IAPIStatusProps = initState, action: IAPIStatusType) => {
  switch (action.type) {
    case ActionType.API_STATUS:
      return {
        ...state,
        apiStatus: action.payload,
      };
    default:
      return state;
  }
};

export default apiStatus;
