import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CollapseIcon } from '../../common/svgIcons/chartIcon';
import { Box, Checkbox } from '@mui/material';
import { TicketFieldValue } from '../TicketManagement/Tickets/TicketView/TicketInformation';
interface IBasicModule{
    name : string;
    id:number;
}
interface IAsset{
    name : string;
    id:number;
    contacts : IBasicModule | null;
    assetType : IBasicModule | null;
    assetTag : string;
    group : IBasicModule | null;
}
interface IAssetItemProps{
    info : IAsset;
    defaultVal : any;
}


export function AssetItem(props:IAssetItemProps) {
    const {defaultVal} = props; 
    const {name, id, contacts , assetType , assetTag="-" , group } =  props?.info || {};
    const checkBoxHandler = (e:any) =>{
        e.stopPropagation();
    }
    return (
        <Box className="accordion-holder asset-accrodian">
            <Accordion  className="mobile-accrodion">
                <AccordionSummary
                    className='mobile-collapse-summary'
                    expandIcon={<CollapseIcon />}
                    aria-controls={`${id}-asset-pane-content`}
                    id={`${id}-asset-pane-header`}
                >
                    <Box className="asset-collapse-title">
                        <Typography className='collapse-title'>
                            {name}
                        </Typography>

                        <Checkbox defaultChecked={defaultVal?.[`asset-${id}`] ? true : false} onClick={checkBoxHandler} name={`asset-${id}`} />
                    </Box>
                </AccordionSummary>
                <AccordionDetails className="collapse-body">
                    <Box className="collapse-body-content">
                        <TicketFieldValue
                            label="Contact"
                            value={contacts?.name || ""}
                        />
                        <TicketFieldValue
                            label="Type"
                            value={assetType?.name || ""}
                        />
                        <TicketFieldValue
                            label="Group"
                            value={group?.name || ""}
                        />
                        <TicketFieldValue
                            label="Tag"
                            value={assetTag}
                        />
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}