import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import parse from 'html-react-parser';
import { memo, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { IState } from "../../../redux/reducers/rootReducers";



interface ViewArticlePopupProps {
    articleInfo?:any;
    dispatch?:any;
}
const ViewArticlePopup = memo((props:ViewArticlePopupProps) => {
    const { articleInfo,dispatch } = props;
    const classes = useStyles();
   
    const onCloseHandler = useCallback(() =>{
        dispatch({
            type:"ARTICLE_POPUP_INFO",
            payload : {}
        })
    },[])


    const viewContent = useMemo(()=>{
        return(
            <>
                {articleInfo?.articleId  && 
                <Box className={`${classes.popupHolder} ${classes.textWithSpanTitle}`}>
                    <Box className="modal-dialog">
                    <Box className="modal-content">
                        <Box className="modal-header">
                        {articleInfo?.title}
                        <Typography className="cursor-pointer" onClick={onCloseHandler}>
                            <CloseIcon className="cursor-pointer" />
                        </Typography>
                        </Box>
                        <Box className="modal-body resolve-popup">
                            <Box className="p-1 pr-0">
                                {parse(articleInfo?.articleBody)}
                            </Box>
                        </Box>
                    </Box>
                    </Box>
                </Box>
                }
            </>);
    },[articleInfo])
    return (<>
      {viewContent}
    </>)
  })



const useStyles = makeStyles(() =>
  createStyles({
    popupHolder: {
    position: "fixed",
    top: 0,
    left: 0,
    background: "#0005",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    zIndex: 1399,
    "& .modal-dialog": {
        display: "flex",
        height: '100%',
        justifyContent: "center",
        alignItems: "center",
    },
    "& .modal-header": {
        display: "flex",
        alignItems: "center",
        padding: "20px",
        justifyContent: "space-between",
        borderBottom: "1px solid #ddd",
        paddingBottom: "10px"
    },
    "& .modal-body": {
        maxHeight: "calc(100vh - 200px)",
        overflowY: "scroll",
        padding: "0px"
    },
    "& .modal-body > div": {
        margin: "0 !important",
        maxWidth: "calc(100% - 15px)"
    },
    "& .modal-content": {
        background: "#fff",
        width: "100%",
        maxWidth: "700px",
        borderRadius: "5px",
        //padding: "20px",
        "& img.Contactprofile": {
        width: "calc(100% - 10px)",
        },
        "& img": {
        objectFit: "cover"
        }
    }
    },
    textWithSpanTitle: {
        "& .title-txts":{
            lineHeight: '1.5',
            marginBottom: 10,
            fontSize: '13px',
            "& > span": {
            display: 'block',
            fontSize: '11px',
            marginBottom: '2px',
            lineHeight: '1',
            color: "#5559"//mode.link,
            }
        }
    }
}))


const mapStateToProps = (state:IState) => {
    return ({
        articleInfo : state?.TicketViewReducer?.articlePopupInfo,
    })
  };
  
export default connect(mapStateToProps)(ViewArticlePopup);