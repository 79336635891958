
export enum ActionType{
    THEME='THEME'
}

export interface IChangeTheme {
    type: ActionType.THEME;
    payload: boolean;
}

export const theme=(theme:boolean)=>{
    if(theme){
        document.body.classList.add('dark-mode');
        localStorage.setItem("darkTheme","true")
    } else{
        document.body.classList.remove('dark-mode');
        localStorage.removeItem("darkTheme")
    }
    return {
        type:ActionType.THEME,
        payload:theme
    };
}