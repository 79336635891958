import { useNavigate } from "react-router-dom";

export const ErrorFallback = ({ error }:any) => {
  const navigate = useNavigate();
  const goHome = ()=>{
    window.location.href = window.location.origin;
  }
  return(
    <div>
      <h2>Something went wrong:</h2>
      <pre>{error.message}</pre>
      <button onClick={goHome}>Try again</button>
    </div>
  )
};