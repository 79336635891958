import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    Box,
    Grid,
    Typography,
    IconButton,
    TextField,
    Button,
} from "@mui/material";
import { Checkbox } from "@mui/material";
import Card from "../../common/card";
import TopFilter from "../../common2/TopFilter";
import Footer from "../../common2/Footer";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../redux/reducers/rootReducers";
import Tooltip from "@mui/material/Tooltip";
import Loading from "../../common2/Loading";
import Model from "../../common2/Model";
import editIcon from "../../../assest/icons/edit.svg";
import NoDataFound from "../../common2/NoDataFound";
import SideDrawer from "../../common/sideDrawer";
import {
    matchPath,
    useLocation
} from "react-router-dom";
import intl from "react-intl-universal";
import CustomTheme from "../../common/CustomTheme";
import CommonStyles from "../../common/CommonStyles";
import deleteIcon from "../../../assest/icons/delete.svg";
import restoreIcon from "../../../assest/icons/restore.svg";
import { createTheme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import Breadcrumbs from "./../../common2/Breadcrumbs";
import ContactTypeAdd, { changeContactType, deleteContactType, getContactType, multipalUpdateContactType, SnackBar, softDeleteContactType, updateContactType } from "../../../services/cmdb/ContactType";
import LanguageData from "../../../configs/LanguageData.json";

const theme = createTheme();
const data = CustomTheme();
const mode = data.theme;

const useStyles = makeStyles(() =>
    createStyles({
        Box: {
            padding: "10px",
            borderRadius: "8px",
        },
        title: {
            fontSize: "13px",
            fontWeight: 600,
            color: mode.text,
            letterSpacing: "1px",
        },
        disabledtitle: {
            fontSize: "13px",
            fontWeight: 600,
            color: mode.text,
            letterSpacing: "1px",
            textDecoration: "line-through",
        },
        data: {
            fontSize: "11px",
            fontWeight: 500,
            color: mode.text,
            letterSpacing: "0.5px",

        },
        footer: {
            display: "flex",
            width: "100%",
            justifyContent: "end",
            alignItems: "center",
        },
        icon: {
            padding: "2.8px",
            height: "15px",
            fontWeight: 400,
            color: "#0056b3",
            lineHeight: "16px",
            backgroundColor: "#abbaf4",
            borderRadius: "3px",
        },
        icon1: {
            color: "#000000",
            height: "20px",
            fontWeight: 400,
            lineHeight: "16px",
            // backgroundColor: "#f4a4d5",
            padding: "2px",
            borderRadius: "3px",
        },
        TicketType: {
            fontSize: "10px",
            margin: "2px",
            letterSpacing: "0.5px",
            fontWeight: 700,
        },
        ticketBox: {
            display: "flex",
        },
        footerTitle: {
            fontSize: "12px",
            fontWeight: 600,
            color: mode.text,
        },
        editbtn: {
        },
        activatebtn: {
        },
        editicon: {
            height: "20px",
            fontWeight: 400,
            color: "#0056b3",
            lineHeight: "16px",
            backgroundColor: "#abbaf4",
            borderRadius: "3px",
        },
        disableddata: {
            height: "20px",
            color: "#270664",

            textDecoration: "line-through",
        },
        cardStyle1: {
            height: "auto",
            backgroundColor: mode.solids,
        },
        cardStyle: {
            boxShadow: "3px 3px 3px 0px #0e8efb66",
            height: "auto",
            backgroundColor: mode.solids,
        },
        btn: {
            margin: "10px",
            lineHeight: "24px",
            fontSize: "13px",
            fontWeight: 400,
            color: "#ffffff",
            padding: "5px",
            width: "100px",
            "@media screen and (max-width:980px)": {
                padding: "5px 9px",
                fontSize: "9px",
                width: "auto",
                lineHeight: "18px",
                minWidth: "auto",
                "& svg": {
                    height: ".6em",
                },
            },
        },
        btnSubmit: {
            color: "#ffffff !important",
            width: " 110px !important",
            backgroundColor: "#1976d2 !important",
            "&:hover": {
                backgroundColor: "#1976d2 !important",
            },
        },
        btnCancel: {
            color: "#6989fa !important",
            width: "110px",
            backgroundColor: "white",
            border: "2px solid #6989fa",
            marginRight: "10px",
            "&:hover": {
                backgroundColor: "white",
            },
        },
        drawerBtn: {
            margin: '0 5px',
            backgroundColor: '#6989fa',
            fontSize: '11px',
        }
    })
);

function ContactType() {
    const mode = CommonStyles();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [checked, setChecked] = React.useState(false);
    const [seachData, setSearchData] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [active, setActive] = useState([]);
    const [getInfo, setGetInfo] = useState([]);
    const [update, setUpdate] = useState(false);
    const [drawerState, setDrawerState] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [id, setID] = useState(0)
    const [formError, setFormError] = useState({});
    const [isSubmit, setIsSubmit] = useState(false)
    const [contacttypeData, setcontacttypeData] = useState({
        name: "",
        isActive: true
    });
    const [data, setData] = useState<any>([]);

    const checkBoxHandler = (e: any, id: any) => {
        setChecked(e.target.checked);
    };

    //  const { data } = useSelector(
    //   (state: IState) => state?.ticketStatusReducer?.Data
    // );

    const { showLoading } = useSelector(
        (state: IState) => state.ticketStatusReducer
    );

    const [permissionInfo, setpermissionInfo] = useState({}as any);
    const rolesAccess = useSelector((state: IState) => state?.LoginReducer?.roleAccess);
    useEffect(() => {
        if(rolesAccess){
            console.log('rolesAccess.contacttype',rolesAccess.contacttype)
            setpermissionInfo({...rolesAccess.contacttype, isSuperAdmin: rolesAccess.isSuperAdmin})
        }
    }, [rolesAccess]);
    const checkPermission:any = (valueKey:string) =>{
        let val:boolean = permissionInfo[valueKey] ? true : false
        return val;
    }
    
    const softDelete = (type: any, id: any) => {
        setIsOpen(true);
        const tempType = type[2];
        const combine: any = [tempType, id];
        setGetInfo(combine);
    };

    const handleSearch = (e: any) => {
        let lowerCase = e.target.value;

        setSearchData(lowerCase);
    };

    const filterHandler = (e: any) => { };

    const activeHandler = (type: any, value: any, id: any) => {
        // dispatch(multipalUpdateContactType({ ...value }, id, true));
        let result: any = changeContactType(id, true)
        result.then((resw: any) => {
            console.log("result", resw)
            if (resw) {
                getListAPI();
                dispatch(SnackBar(`${LanguageData.SUCCESS_CONTACT_TYPE}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}`, "success"))
            }
        })

    };

    const showDataHandler = (show: any) => {
        const newItems = data?.filter((val: any) => val?.isActive === show);
        setActive(newItems);
        if (show) {
            setUpdate(false);
        }
        if (show === false) {
            setUpdate(true);
        }
    };

    const updateAllHandler = (value: any, id: any) => {
        // console.log('type:', active?.isActive ? 'false': 'true')

        let arrayids: any[] = [];
        data.forEach((d: any) => {
            if (d.isActive) {
                arrayids.push(d.id);
            }
            if (d.isActive === false) {
                arrayids.push(d.id);
            }
        });
        let result: any = multipalUpdateContactType({ ...value }, arrayids, update)
        result.then((resw: any) => {
            console.log("multipalUpdateContactType", resw)
            if (resw) {
                getListAPI()
                dispatch(SnackBar(`${LanguageData.SUCCESS_CONTACT_TYPE}${LanguageData.TICKET_UPDATED}`, "success"))
            }
        })
    };

    const onSubmitHandler = () => {
        if (getInfo[0] === `${intl.get("In-Active")}`) {

            let result: any = softDeleteContactType(getInfo[1])
            result.then((resw: any) => {
                console.log("softDeleteContactType", resw)
                if (resw) {
                    getListAPI();
                    dispatch(SnackBar(`${LanguageData.SUCCESS_CONTACT_TYPE}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`, "success"))
                }
            })
        }
        if (getInfo[0] === `${intl.get("Delete")}`) {
            dispatch(deleteContactType(getInfo[1]));
            let result: any = deleteContactType(getInfo[1])
            result.then((resw: any) => {
                console.log("in-active is called", resw)
                if (resw) {
                    getListAPI();
                }
            })
        }

        setIsOpen(false);
    };

    const getAllContactTypeCB = (resStatus:string,res:any) => {
        if(resStatus === "0"){
            setData(res)
        }
    }
    useEffect(() => {
        getListAPI();
    }, [])
    const getListAPI = () =>{
        dispatch(getContactType(getAllContactTypeCB))
    }
    useEffect(() => {
        const val = true;
        showDataHandler(val);
    }, [data]);

    // const bulkDeleteHandler = (value: any, id: any) => {
    //   let arrayids: any[] = [];
    //   data.forEach((d: any) => {
    //     if (d.isActive) {
    //       arrayids.push(d.id);
    //     }
    //   });
    //   dispatch(bulkDeletePriority(arrayids));
    // };
    // const OnCloseDrawer = (event: React.KeyboardEvent | React.MouseEvent) => { };
    const onCancelHandler = () => {
        setDrawerState(false)
    }

    const addcontacttypeHandler = () => {
        setDrawerState(true);
        setIsEdit(false)
        setcontacttypeData({ ...contacttypeData, name: '' })
    };
    const onTicketStatusHandler = () => {
        const validationResult =  validateHandler(contacttypeData)
        setFormError(validationResult);

        if(validationResult?.hasErrors){
        return;
        }
        if (isEdit) {

            let result: any = updateContactType(contacttypeData, id)
            result.then((resw: any) => {
                // console.log("result after calling AssetStatusAdd", resw)
                if (!resw.isError) {
                    getListAPI()
                    dispatch(SnackBar(`${LanguageData.SUCCESS_CONTACT_TYPE}${LanguageData.TICKET_UPDATED}`, "success"));
                    setDrawerState(false);
                } else {
                    dispatch(SnackBar(resw.message, "error"));
                    setDrawerState(true);
                }
            })
        }
        else {
            let result: any = ContactTypeAdd(contacttypeData)
            result.then((resw: any) => {
                // console.log("result after calling AssetStatusAdd", resw)
                if (!resw.isError) {
                    getListAPI()
                    dispatch(SnackBar(`${LanguageData.SUCCESS_NEW_CONTACT_TYPE}${LanguageData.TICKET_CREATED}`, "success"))
                    setDrawerState(false);
                }
                else {
                    dispatch(SnackBar(resw.message, "error"))
                    setDrawerState(true);
                }
            })

        }

    };

    const inputChangeHandler = (e: any) => {
        const name = e.target.name;
        const val = e.target.value;
        setcontacttypeData({ ...contacttypeData, [name]: val });
    };

    const onEditHandler = (value: any) => {
        setIsEdit(true)
        const temp = { ...contacttypeData }
        temp.name = value?.name
        setcontacttypeData(temp)
        setDrawerState(true);
        setID(value.id)
    };

    const validateHandler = (formValues: any) => {
        const errors: any = {
            hasErrors: false
        };
        if (!formValues?.name || !formValues?.name?.trim || formValues.name.trim() === "") {
            errors.name = "Name is Required!";
            errors.hasErrors = true
        }
        return errors;
    };

    const formValidate: any = formError

    useEffect(() => {
        if (Object.keys(formError).length === 0) {
            console.log("ticketTypesData=>", formError);
        }

    }, [formError]);

    return (
        <>
            <Breadcrumbs
                data={[
                    {
                        title:'ContactManagement ',
                        path:'/ContactManagement' 
                     },
                     {
                        title:'Contact-Type ',
                        path:'/ContactType' 
                     }
                ]
                }
            />
            <TopFilter
                path='h'
                Data={data}
                SearchHandler={handleSearch}
                value={seachData}
                filterHandler={filterHandler}
                checkBoxHandler={checkBoxHandler}
                checked={checked}
                filterItems={showDataHandler}
                updateAllHandler={updateAllHandler}
                // bulkDeleteHandler={bulkDeleteHandler}
                hideSelectAllOption={active?.length > 0 ? false : true}
                titleState={update}
                addHandler={addcontacttypeHandler}
                pathname={"NA"}
                temp={active}
                redirectTo="/ContactManagement"
                placeHolder='Search contacttype'
                hideAddBtn = {checkPermission('Create') || checkPermission('isSuperAdmin') ? false : true}
            />
            <Model
                open={isOpen}
                label={`Proceed for ${getInfo[0]}`}
                onSubmitHandler={onSubmitHandler}
                handleClose={() => setIsOpen(false)}
                btn={`${intl.get("Update")}`}
            />
            <SideDrawer
                onClose={onCancelHandler}
                drawerState={drawerState}
                title={isEdit ? "Edit Contact Type" : " Add Contact-Type"}
            >
                <Box
                    style={{
                        marginTop: "15px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <TextField
                        id="outlined-basic"
                        label="Name"
                        variant="outlined"
                        value={contacttypeData.name}
                        onBlur={(e) => {
                            console.log("out of focus", e.target.value)
                            const temp = { ...contacttypeData };
                            temp.name = e.target.value;
                            setcontacttypeData(temp);
                        }}
                        name="name"
                        onChange={inputChangeHandler}
                        style={{ margin: "15px" }}
                    />
                    {formValidate.name && <p style={{ color: 'red' }}>{formValidate.name}</p>}

                    <div style={{ margin: "auto" }} className="contact-management-buttons">
                        <Button
                            className={`${classes.btn} ${classes.btnCancel}`}
                            variant="contained"
                            onClick={onCancelHandler}

                        >
                            <CancelIcon style={{ marginRight: "5px" }} />

                            Cancel
                        </Button>
                        <Button
                            color="primary"

                            variant="contained"
                            className={`${classes.btn} ${classes.btnSubmit}`}
                            onClick={onTicketStatusHandler}
                        >
                            {isEdit ? "Update" : "Add"}
                        </Button>
                    </div>
                </Box>
            </SideDrawer>
            {showLoading && <Loading />}
            {active?.length === 0 && (
                <NoDataFound
                />
            )}
            <Box style={{marginTop:"15px"}} className={`${classes.Box} ${mode.themeBgColor}`}>
                <Grid container spacing={3} style={{marginTop:"0px"}}>
                    {active
                        ?.filter((val: any) => {
                            if (seachData == "") {
                                return val;
                            } else if (
                                val?.name?.toLowerCase().includes(seachData.toLowerCase()) || val?.displayName?.toLowerCase().includes(seachData.toLowerCase())
                            ) {
                                return val;
                            }
                        })
                        .map((value: any) => {
                            console.log("hello", value)
                            const { id, name, description, displayName, isActive } = value;
                            const type = [`${intl.get("Delete")}`, `${intl.get("Active")}`, `${intl.get("In-Active")}`];

                            return (
                                <Grid item xs={12} md={3} key={id} style={{ marginTop: "1%" }} className="Article-Type">
                                    <Card
                                        styleProp={checked ? classes.cardStyle : classes.cardStyle1}
                                        cardHeaderTitle1={
                                            <Typography
                                                variant="h6"
                                                className={
                                                    isActive ? classes.title : classes.disabledtitle
                                                }
                                            >
                                                {name}
                                            </Typography>
                                        }
                                        cardHeaderTitle2={
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                {checked ? (
                                                    <Checkbox
                                                        size="small"
                                                        color="primary"
                                                        checked={checked}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </Box>
                                        }
                                        cardBody={
                                            <Box>
                                                <>
                                                    <Box>
                                                        <Typography
                                                            className={
                                                                isActive ? mode.cardDisplay : mode.disabledtitle
                                                            }
                                                        > {displayName}</Typography>
                                                        <Typography>{description}</Typography>
                                                    </Box>
                                                </>
                                            </Box>
                                        }
                                        cardFooter={
                                            <Box className={classes.footer}>
                                                {permissionInfo && (checkPermission('Activate') || checkPermission('isSuperAdmin')) && !isActive  && (
                                                    <Tooltip title={`${intl.get("Active")}`}>
                                                        <IconButton
                                                            disabled={isActive ? true : false}
                                                            size="small"
                                                            onClick={(e: any) =>activeHandler(type, value, value.id)}
                                                        >
                                                        <img src={restoreIcon} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                
                                                {permissionInfo && (checkPermission('Edit') || checkPermission('isSuperAdmin')) && isActive  && (
                                                    <Tooltip title={`${intl.get("Edit")}`}>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => onEditHandler(value)}
                                                        >
                                                            <img src={editIcon} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}

                                                
                                                {permissionInfo && (checkPermission('DeActivate') || checkPermission('isSuperAdmin')) && isActive  && (
                                                    <Tooltip title={`${intl.get("In-Active")}`}>
                                                        <IconButton
                                                            disabled={isActive ? false : true}
                                                            size="small"
                                                            className="cardIconBtn"
                                                            onClick={() => softDelete(type, value.id)}
                                                        >
                                                            <img src={deleteIcon} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}

                                            </Box>
                                        }
                                    />
                                </Grid>
                            );
                        })}
                </Grid>
                <Footer />
            </Box>
        </>
    );
}

export default ContactType;