const initialState = {
    Data: [],
    showLoading: false,
  };
   
  const userGroupReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case "removeAgent":
        return {
          ...state,
        };
      case "loading":
        return {
          ...state,
          showLoading: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default userGroupReducer;