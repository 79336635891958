import { Box, Button, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFeedbackBySurvey } from "../../../redux/actions/Survey/feedback";
import { getSurveys } from "../../../redux/actions/Survey/survey";
import { IState } from "../../../redux/reducers/rootReducers";
import VerticalChart from "../../charts/horizontal-chart/VerticalChart";
import CommonStyles from "../../common/CommonStyles";
import Breadcrumbs from "../../common2/Breadcrumbs";
import Dropdown from "../../common2/Dropdown";
import CalendarRangeOptionSurvey from "../SurveyResults/CalendarRangeOptionSurvey";
import ResetFilterSurvey from "../SurveyResults/ResetFilterSurvey";

export default function SurveyReports() {

    const classes = CommonStyles()
    const dispatch = useDispatch()
    const surveys = useSelector((state: IState) => state.SurveyReducer.surveyList)
    const Feedbacks = useSelector((state: IState) => state.FeedbackReducer.feedbackList)
    const rangeVal = useSelector((state: IState) => state?.FeedbackReducer?.rangeval);
    const profileImg = useSelector((state: IState) => state?.agentReducer?.saveOrgProfile);
    const [selected, setSelected] = useState<any>({});
    const [parsedFeedbackValue, setParsedFeedbackValue] = useState<any>({})
    const [logoSrc, setLogoSrc] = useState(profileImg);

    const [Data, setData] = useState<any>({
        surveyID: 0,
        searchVal: "",
    })

    const onInputChangeHandler = (e: any) => {
        const val = e.target.value;
        const name = e.target.name;

        setData({
            ...Data,
            [name]: val,
        });
    };

    useEffect(() => {
        dispatch(getSurveys())
    }, [])

    useEffect(() => {
        let selectedFeedback = Feedbacks.map((ele: any) => JSON.parse(ele.feedbackValue));
        const allKeys: any = {};

        selectedFeedback.forEach((obj: any) => {
            const keys = Object.keys(obj)
            keys.forEach(key => {
                if (allKeys.hasOwnProperty(key)) {
                    allKeys[key].push(obj[key]);
                } else {
                    allKeys[key] = [obj[key]];
                }
            });
        });
        setParsedFeedbackValue(allKeys)
    }, [Feedbacks])

    useEffect(() => {
        if (Data.surveyID !== 0) {
            dispatch(getFeedbackBySurvey(Data.surveyID, null, "", moment(rangeVal?.startDate)?.toISOString(), moment(rangeVal?.endDate)?.toISOString()))
        }
        const surveySelected = surveys.find((ele: any) => ele.id === Data.surveyID)
        setSelected(surveySelected)
    }, [Data, rangeVal])
    const PageButtons = (props: any) => {
        const { onClickHandler, title } = props;
        return (
            <Button className={classes.ReportBtns} onClick={onClickHandler}>
                {title}
            </Button>
        );
    };
    const exportPDF = () => {
        window.print();
    };
    const fileChangeHandler = (e: any) => {
        console.log(e.target.value);
        // var reader: any = new FileReader();
        // reader.readAsDataURL(e.target.files[0]);
        // reader.onload = function () {
        //   console.log(reader.result);
        //   setLogoSrc(reader.result);
        // };
        // reader.onerror = function (error: any) {
        //   console.log("Error: ", error);
        // };
      };
    const webFormFields = useMemo(() => {
        return selected?.webform?.webformFieldList?.map((field: any, index: number) => {
            debugger
            const { fieldLabel, fieldType, fieldId, fieldOptions } = field;
            let graphData = null;
            let checkboxValues: any[] = [];

            if (fieldType === 'CheckBox' || fieldType === 'DropDown') {
                const options = fieldOptions.split('|');
                checkboxValues = parsedFeedbackValue[fieldId] || [];

                const valueCounts: { [key: string]: number } = {};
                let totalCount = 0;

                checkboxValues.forEach((value: any) => {
                    totalCount++;
                    if (valueCounts[value]) {
                        valueCounts[value]++;
                    } else {
                        valueCounts[value] = 1;
                    }
                });

                const datasets = options.map((option: string) => ({
                    label: option,
                    data: [valueCounts[option] || 0], // Sending count of values instead of percentage
                    backgroundColor: `rgba(${Math.floor(Math.random() * (255 - 100 + 1)) + 100}, 162, 235, 0.5)`,
                    borderColor: `rgba(${Math.floor(Math.random() * (255 - 100 + 1)) + 100}, 162, 235, 1)`,
                    borderWidth: 1,
                }));

                graphData = {
                    labels: ["Responses"],
                    datasets: datasets,
                }
                console.log("graphData", graphData);
            }

            return (
                <>
                    <Box key={fieldId}>
                        {fieldType !== 'Heading' &&

                            <div style={{ marginBottom: "10px", borderBottom: "1px solid #ccc" }}>
                                <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
                                    Question {index + 1}: {fieldLabel}
                                </div>


                                <>
                                    <div style={{ marginBottom: "5px" }}>
                                        <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>Answer</th>
                                                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>Owner</th>
                                                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>Group</th>
                                                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>Contact Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {parsedFeedbackValue[fieldId]?.map((value: any, index: any) => (
                                                    <tr key={index}>
                                                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{fieldType === "DatePicker" ? moment(value).format("DD/MM/YYYY") : value}</td>
                                                        {Feedbacks[index] ? (
                                                            <>
                                                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>{Feedbacks[index]?.agent?.firstName ? `${Feedbacks[index]?.agent?.firstName} ${Feedbacks[index]?.agent?.lastName}` : "----"}</td>
                                                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>{Feedbacks[index]?.group?.name ? Feedbacks[index]?.group?.name : "----"}</td>
                                                                <td style={{ border: "1px solid #ddd", padding: "8px" }}>{Feedbacks[index]?.contacts?.name ? Feedbacks[index]?.contacts?.name : "----"}</td>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <td style={{ border: "1px solid #ddd", padding: "8px" }} colSpan={3}>---</td>
                                                            </>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <div style={{ marginBottom: "7px" }}>Type: {fieldType}</div> */}
                                </>

                                {graphData ? (
                                    <div style={{ marginBottom: "5px", width: "30%", position: "relative", left: "33.333%" }}>
                                        <VerticalChart
                                            label={graphData.labels}
                                            dataSets={graphData.datasets}
                                        />
                                    </div>
                                ) : (
                                    <div style={{ marginBottom: "5px" }}>
                                        {checkboxValues.map((value: any, valueIndex: number) => (
                                            <p key={valueIndex}><b>Option {valueIndex + 1}</b>: {value}</p>
                                        ))}
                                    </div>
                                )}
                            </div>
                        }
                    </Box>
                </>
            );
        });
    }, [selected?.webform?.webformFieldList, parsedFeedbackValue]);
    
    const generateTimeRange = (FiltersVal: any) => {
        if (FiltersVal) {
          return (
            <p
              style={{
                color: "black",
                textDecoration: "underline",
                marginTop: "10px",
                fontSize: "14px",
              }}
            >
              {moment(FiltersVal?.startDate).format("L")} -{" "}
              {moment(FiltersVal?.endDate).format("L")}
            </p>
          );
        }
    };

    const resetAndCalendars = useMemo(() => <><CalendarRangeOptionSurvey />
        <ResetFilterSurvey /></>, [classes])
    return (
        <>
            <div className="report-hide">
                <Breadcrumbs
                    data={[
                        {
                            title: 'System Management ',
                            path: '/adminPanel'
                        },
                        {
                            title: 'Survey Reports',
                            path: '/SurveyReports'
                        }
                    ]}
                />
                <Grid container spacing={1}>
                    <Grid item md={4} xs={12}>
                        <Typography variant="h6" style={{ marginBottom: "2rem", marginLeft: "5px" }}>
                            Survey Reports
                        </Typography>
                        <Dropdown
                            data={surveys}
                            classname="h-inputs sm-pd"
                            label="Survey"
                            styplePropTitle={classes.title}
                            value={Data.surveyID}
                            name="surveyID"
                            onInputChangeHandler={onInputChangeHandler}
                            inputStyle={classes.inputStyle}
                        />
                    </Grid>
                    <Grid item md={4} xs={12} className="survey-calender p-0" style={{ marginTop: "auto" }}>
                        <Box style={{ display: "flex", gap: "15px", alignItems: "center" }}>
                            {resetAndCalendars}
                        </Box>
                    </Grid>
                </Grid>
            </div>
            <br className="report-hide" />
            <div
                style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}
                className="report-hide"
            >
                {" "}
                <PageButtons title="Export Report" onClickHandler={exportPDF} />
            </div>
            {parsedFeedbackValue && Object.keys(parsedFeedbackValue).length > 0 &&
                <Box className="p-2 view-service">
                    <>
                        <Box className={`${classes.row} ${classes.justifyCenter} px-2`}>
                            <div className="custom-chart custom-Report-chart">
                                <input
                                    // type="file"
                                    name=""
                                    id="reportLogo"
                                    className="d-none"
                                    onChange={fileChangeHandler}
                                />
                                <label htmlFor="reportLogo">
                                    <img
                                        src={logoSrc === "" ? logoSrc : logoSrc}
                                        alt=""
                                        style={{ width: "150px" }}
                                    />
                                </label>
                                <p
                                    style={{
                                        fontSize: "25px",
                                        textAlign: "left",
                                        marginBottom: "25px",
                                    }}
                                >
                                    {selected?.name}
                                </p>
                                {generateTimeRange(rangeVal)}
                            </div>
                        </Box>
                        <Grid container className="pt-1" spacing={1}>
                            <Grid item md={12} xs={12}>
                                <div
                                    style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
                                    className="report-hide"
                                >
                                    {" "}
                                </div>
                                {webFormFields}
                            </Grid>
                        </Grid>
                    </>
                </Box>
            }
        </>
    )
}