import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import configs from "../../configs/config";

export const url = `${configs.BASE_URL}/Asset/testing`;

const TenantCode = "vizionsys"


export const getDeployTest = (callback?: any) => {
    return async (dispatch: any) => {
        try {
            // const response = await axios(`${ url}`);
            const response = await axios(`${url}`,{
                headers: {
                    "tenant-code": TenantCode,
                },
            });
            // response.data = response.data.map((i: any) => {
            //     return { ...i, rid: `#${i.id}`, value: i.name, label: i.name }
            // })
            try { callback && callback("0", response.data) } catch (e) { }
        } catch (error: any) {
            callback && callback("1", error.response)
            return console.log(error.response);
        }
    };
};
const DeployTest = () => {
    const [data,setData]=useState('')
    const dispatch=useDispatch()
    useEffect(()=>{
        dispatch(getDeployTest(getCallback))
    },[])
    const getCallback = (resStatus:string,res:any)=>{
        if(resStatus==="0"){
            setData(res);
        }
    }
    return (
        <>
            Deploy Version is = {data}
        </>
    )

}
export default DeployTest