import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import { createRef, useCallback, useContext, useEffect } from "react";
import { FieldsContext } from "./WebFormWorkspace";
import { TextBoxLatest } from "../common/TextBoxLatest/TextBoxLatest";
import { iWebFormField } from "./schema";

const WebFormProperties = () =>{
    // const {setEditIndex,blocks,editIndex,setBlocks,editFieldVal} = useContext(FieldsContext)
    const {blocks,setBlocks,setEditFieldIndex,editFieldIndex,setEditFieldVal,editFieldVal} = useContext(FieldsContext)
    console.log('WebFormProperties',editFieldVal)
    const labelRef:any = createRef();
    const descRef:any = createRef();
    const optionsRef:any = createRef();
    const checkBoxRef:any = createRef();
    const placeHolderRef:any = createRef();
    const submitHandler = ()=>{
        console.log('WebFormProperties_Submit',editFieldIndex,blocks)
        const temp = {val:[...blocks.map((i:iWebFormField,index:number)=>{
            if(index === editFieldIndex){
                i.isMandatory = checkBoxRef?.current?.checked || false;
                i.fieldLabel = labelRef?.current?.value;
                // i.id = Math.random() * 10000000000000000000;
                i.fieldPlaceholder = placeHolderRef?.current?.value;
                i.fieldDescription = descRef?.current?.value;
                i.fieldOptions = optionsRef?.current?.value;
            }
            return i;
        })]}
        setBlocks(JSON.parse(JSON.stringify(temp)).val)
        setTimeout(()=>{
            cancelHandler()
        },100)
    }
    const cancelHandler = ()=>{
        setEditFieldIndex(null)
    }
    useEffect(()=>{
        if(labelRef?.current){
            labelRef.current.value  = editFieldVal?.fieldLabel || "";
        }
        if(descRef?.current){
            descRef.current.value = editFieldVal?.fieldDescription || "";
        }
        if(placeHolderRef?.current){
            placeHolderRef.current.value = editFieldVal?.fieldPlaceholder || "";
        }
        if(optionsRef?.current){
            optionsRef.current.value = editFieldVal?.fieldOptions || "";
        }
        if(checkBoxRef?.current){
            checkBoxRef.current.checked = true;
        }
    },[editFieldVal])
    // useCallback(()=>{},[labelRef?.current,descRef?.current])

    return (
        <>
            
            <Grid item md={3} xs={12}>
                <Box className="aside">
                    <Typography>Properties</Typography>
                    <hr />
                    <TextBoxLatest
                        multiline={false}
                        title="Field Title"
                        placeholder="Please Enter"
                        ref={labelRef}
                        isMandotary={false}
                    />
                    
                   
                    
                    <TextBoxLatest
                            multiline={true}
                            title= {editFieldVal?.fieldType === "Heading" ? "Enter Description" : "Enter Helper Text"}
                            placeholder= {editFieldVal?.fieldType === "Heading" ? "Enter Description" : "Enter Helper Text"}
                            ref={descRef}
                            isMandotary={false}
                    />

                    {(editFieldVal?.fieldType === "DropDown" || editFieldVal?.fieldType === "CheckBox") &&
                        <TextBoxLatest
                            multiline={true}
                            title="Field Options"
                            placeholder="Please Enter"
                            ref={optionsRef}
                            isMandotary={false}
                        />
                    }
                    {(editFieldVal?.fieldType !== "Heading" && editFieldVal?.fieldType !== "CheckBox" && editFieldVal?.fieldType !== "DropDown") &&
                        <>
                            <TextBoxLatest
                                multiline={false}
                                title="Field Placeholder"
                                placeholder="Please Enter"
                                ref={placeHolderRef}
                                isMandotary={false}
                            />
                        </>
                    }
                    {(editFieldVal?.fieldType !== "Heading") &&
                        <>
                            <Typography className={`radio-label`}>
                                <Checkbox 
                                    defaultChecked={editFieldVal?.isMandatory || false}
                                    inputRef={checkBoxRef} 
                                />
                                <span>Mandatory Field</span>
                            </Typography> &nbsp;&nbsp;
                        </>
                    }
                    <Box className="pt-1 text-center">
                    <Button size="small" onClick={cancelHandler}>
                        Cancel
                    </Button> &nbsp; &nbsp;
                    <Button size="small" variant="contained" onClick={submitHandler}>
                        Submit
                    </Button>
                    </Box>
                </Box>
            </Grid>
        </>
    )
}
export default WebFormProperties;