import axios from "axios";
import configs from "../../../../configs/config";
import { ActionType, snackbar } from "../../Snackbars";
import { filesApiUrl } from "../../AssetsManagement/Assets";
export const url = `${configs.BASE_URL}/ContactAid`;
export const contactAidUrl = `${configs.BASE_URL}/ContactAid`;
export const contactAidUrlSSP = `${configs.BASE_URL}/user/ContactAid`;
export const softDeleteUrl = `${url}/status/change`;
export const hardDeleteUrl = `${url}/permanent/delete`;
export const contactUserChangePassword=`${configs.BASE_URL}/user/Contact/change/password`
export const STORE_CONTACT_AID = 'STORE_CONTACT_AID';
export const STORE_CONTACT_AID_BY_ID = 'STORE_CONTACT_AID_BY_ID';
export interface IFileUpload {
  _FormFile:File;
  type:string;
  fileupload?: any;
}
export const storeAllContactsAid = (val: any) => ({
    type: STORE_CONTACT_AID,
    payload: val,
});
export const storeAllContactAidByID = (val: any) => ({
  type: STORE_CONTACT_AID_BY_ID,
  payload: val,
});
export const alertMessage = (msg:string='',severity:string='success') => {
    return async (dispatch: any) => {
      try {
        dispatch({
            type: ActionType.SNACKBAR,
            payload: {
              message: msg,
              severity: severity,
              open: true,
            },
          });
      } catch (error:any) {
        return console.log(error.response);
      }
    };
  };
export const addContactAid = (data: any, callback: any  = undefined) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(url,data);
      dispatch(alertMessage("New Record Added Successfully!","success"))
      try{callback && callback("0",response.data)}catch(e){}
    } catch (error:any) {
      dispatch(alertMessage(error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.","error"))
      callback && callback("1",error.response)
      return console.log(error.response);
    }
  };
};
export const editContactAid = (id:any,data: any, callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.put(`${url}/${id}`,data);
      dispatch(alertMessage("Record Updated Successfully!","success"))
      try{callback && callback("0",response.data)}catch(e){}
    } catch (error:any) {
      dispatch(alertMessage(error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.","error"))
      callback && callback("1",error.response)
      return console.log(error.response);
    }
  };
};
export const getContactAid = (callback?:any, altUrl?:string) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(altUrl ? `${configs.BASE_URL}/${altUrl}` : url ); 
      const res:any[] = [];
      const iconIds:any[] = [];
      response.data.map((i:any)=>{
        if(altUrl && !i.isActive) return;
        res.push({
          ...i,
          label : i.name,
          value : i.name,
        });
        iconIds.push(i.iconId);
      })
      console.log("Icons__id",iconIds)
      dispatch(storeAllContactsAid(res))
      try{callback && callback("0",res)}catch(e){}
    } catch (error:any) {
      callback && callback("1",error.response)
      return console.log(error.response);
    }
  };
};
export const getContactAidById = (id:string|number, callback?:any,altUrl?:string) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios(altUrl ? `${configs.BASE_URL}/${altUrl}/${id}` : `${url}/${id}`); 
      if(response?.data?.agent) response.data.agent = {...response?.data?.agent, label: `${response?.data?.agent.firstName} ${response?.data?.agent.lastName}`,value:`${response?.data?.agent.firstName} ${response?.data?.agent.lastName}`}
      if(response?.data?.group) response.data.group = {...response?.data?.group, label: response?.data?.group.name,value:response?.data?.group.name}
      if(response?.data?.ticketType) response.data.ticketType = {...response?.data?.ticketType, label: response?.data?.ticketType.name,value:response?.data?.ticketType.name}
      if(response?.data?.webform) response.data.webform = {...response?.data?.webform, label: response?.data?.webform.name,value:response?.data?.webform.name}
      if(response?.data?.category) response.data.category = {...response?.data?.category, label: response?.data?.category.name,value:response?.data?.category.name}
      console.log('storeAllContactAidByID', response.data)
      dispatch(storeAllContactAidByID(response.data))
      try{callback && callback("0",response.data)}catch(e){}
    } catch (error:any) {
      callback && callback("1",error.response)
      return console.log(error.response);
    }
  };
};
export const softDeleteContactAid = (id:number,status:boolean) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.delete(`${softDeleteUrl}?ids=${id}&status=${status}`);
      dispatch(alertMessage("Record Updated Successfully!","success"))
      dispatch(getContactAid());
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response && typeof error.response.data === 'string' ? error.response.data :"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};

export const hardDeleteContactAid = (id:number) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.delete(`${hardDeleteUrl}/${id}`);
      
      dispatch(getContactAid());
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response && typeof error.response.data === 'string' ? error.response.data :"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const changePasswordContactUser = (data: any,callback:any) => {//to change logged in Contact password
  return async (dispatch: any) => {
    try {
      console.log('data',data)
      const response = await axios.post(contactUserChangePassword, data);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "Password Updated Successfully",
          severity: "success",
          open: true,
        },
      });
      dispatch(callback('0'));
    } catch (error:any) {
      if (error.response) {
        dispatch(callback('1',error?.response?.data && typeof error.response.data === "string" ? error.response.data : "Something went wrong! Try again later."));
        console.log('error=>', error.response.data);
      }  
    }
  };
};
export const triggerAppAlert = (content:string="", type:string="success") => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionType.SNACKBAR,
      payload: {
        message:  content,
        severity: type,
        open: true,
      },
    });
  };
};
export default addContactAid;
