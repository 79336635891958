import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import CommonStyles from './../common/CommonStyles';
export default function CommonAppCarousel (props:any) {
  const { bodyContent,nxtIcon,prevIcon,classNames,stepVal=0} = props;//?: React.ReactNode
  const [activeStep, setActiveStep] = React.useState(stepVal);
  const [steps, setsteps] = React.useState([] as any);
  const maxSteps = steps.length;
  useEffect(() => {
    setsteps(bodyContent)
  }, [bodyContent]);
  const handleNext = () => {
    setActiveStep((prevActiveStep:number) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep:number) => prevActiveStep - 1);
  };

  return (
    <Box className={`${classNames} CommonAppCarousel`} sx={{flexGrow: 1 }}>
      <Box>
        {steps[activeStep]}
      </Box>
            <Button size="small" className='btn btn-prev' onClick={handleBack} disabled={activeStep === 0}>
               {prevIcon?prevIcon:`Back`}
            </Button>
            <Button
            size="small"
            className='btn btn-nxt'
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}>
               {nxtIcon?nxtIcon:`Next`}
            </Button>
    </Box>
  );
}