import { ActionTypeLoader, ILoader, ILoaderType } from "../actions/Loader";

export interface ILoaderState {
  loader: ILoader;
}
const initState: ILoaderState = {
  loader: {
    open: false,
  },
};

const loader = (state: ILoaderState = initState, action: ILoaderType) => {
  switch (action.type) {
    case ActionTypeLoader.LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    default:
      return state;
  }
};

export default loader;
