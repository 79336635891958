import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import deleteIcon from "../../assest/icons/delete.svg";
import editIcon from "../../assest/icons/edit.svg";
import restoreIcon from "../../assest/icons/restore.svg";
import ViewIcon from "../../assest/icons/viewIcon.svg";
import { getSurveys, statusUpdateSurvey } from '../../redux/actions/Survey/survey';
import { IState } from '../../redux/reducers/rootReducers';
import CardCustom from '../common/card';
import Breadcrumbs from "../common2/Breadcrumbs";
import TopFilter from '../common2/TopFilter';
function SurveyList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [list, setList] = useState([]);
    const [DummyList, setDummyList] = useState([]);
    const [permissionInfo, setpermissionInfo] = useState({}as any);
    const [listStatus, setListStatus] = useState(true);
    const [seachData, setSearchData] = useState("");
    const [checked, setChecked] = useState(false);
    const rolesAccess = useSelector((state: IState) => state?.LoginReducer?.roleAccess);

    useEffect(()=>{
        getSurveysApi();
    },[])
    useEffect(() => {
        if(rolesAccess){
          console.log('rolesAccess.Contacts',rolesAccess.Survey)
          setpermissionInfo({...rolesAccess.Survey, isSuperAdmin: rolesAccess.isSuperAdmin})
        }
    }, [rolesAccess]);

    const getSurveysApi = () => {
        dispatch(getSurveys(getCallback))
    }
    const getCallback = (resStatus:string,res:any)=>{
        if(resStatus==="0"){
            setList(res);
        }
    }
    const handleSearch = (e: any) => {
        let lowerCase = e.target.value;
        setSearchData(lowerCase);
    };
    const filterHandler = (e: any) => {
        // setListStatus(e)
    };
    const checkBoxHandler = (e: any, id: any) => {};
    const showDataHandler = (show: boolean) => {
        setListStatus(show)
        console.log('0987654',show)
    };
    const updateAllHandler = (value: any, id: any) => {};
    const addArticleTypeHandler = () => {};
    const statusChangeHandler = (id:number,statusToChange:boolean) =>{
        dispatch(statusUpdateSurvey(id,statusToChange, getSurveysApi));
    }

    const checkPermission:any = (valueKey:string) =>{
        let val:boolean = permissionInfo[valueKey] ? true : false
        return val;
    }
    return (
        <Box className="p-1">
            <Breadcrumbs
                data={[
                    {
                        title: 'System Management',
                        path: '/adminPanel'
                    },
                    {
                        title: 'Survey List ',
                        path: '/SurveyList'
                    }
                ]
                }
            />
            <TopFilter
                path='/createSurvey'
                Data={DummyList}
                SearchHandler={handleSearch}
                value={seachData}
                filterHandler={filterHandler}
                checkBoxHandler={checkBoxHandler}
                checked={checked}
                filterItems={showDataHandler}
                updateAllHandler={updateAllHandler}
                //bulkDeleteHandler={bulkDeleteHandler}
                hideSelectAllOption={true}
                titleState={listStatus}
                addHandler={addArticleTypeHandler}
                pathname={"/surveyList"}
                temp={DummyList}
                redirectTo="/adminPanel"
                placeHolder='Search Survey'
                inActiveLabel = "Depricated"
                activeLabel = "Active"
                hideAddBtn = {checkPermission('Create') || checkPermission('isSuperAdmin') ? false : true}
            />
            <Grid container className='pt-1' spacing={1}>
                {list.map((i: any, index: number) => {
                    const objVals = `${Object.values(i)}`.toLowerCase();
                    const { isActive,name,id,keywords } = i;
                    return (
                        <React.Fragment key={index}>
                            {(listStatus === isActive && objVals.indexOf(`${seachData}`.toLowerCase()) >= 0) && 
                                <Grid item xs={12} md={3}>
                                    <CardCustom
                                        customHeader={
                                            <Typography>
                                                {/* ID : {id} */}
                                                {name}
                                            </Typography>
                                        }
                                        cardBody={
                                            <Box>
                                                <>
                                                    <Box>
                                                        <Typography variant='body1' className="medium-title">
                                                            ID : {id}
                                                        </Typography>
                                                        <hr/>
                                                        <Typography variant='body2'>{keywords}</Typography>
                                                    </Box>
                                                </>
                                            </Box>
                                        }
                                        cardFooter={
                                            <Box>
                                                <Box>
                                                    {/* 
                                                    <Tooltip title={`View`}>
                                                        <IconButton
                                                            size="small"
                                                            onClick={()=>{
                                                                navigate(`/ViewArticle/${id}`)
                                                            }}
                                                        >
                                                            <img src={ViewIcon} />
                                                        </IconButton>
                                                    </Tooltip>
                                                     */}
                                                    {permissionInfo && (checkPermission('Edit') || checkPermission('isSuperAdmin')) && isActive  && (
                                                        <Tooltip title={`Edit`}>
                                                            <IconButton
                                                                // disabled={isActive ? false : true}
                                                                size="small"
                                                                onClick={()=>{
                                                                    navigate(`/SurveyList/${id}`)
                                                                }}
                                                            >
                                                                <img src={editIcon} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}

                                                    {(checkPermission('isSuperAdmin') || (!isActive && checkPermission('ReActivate'))  || (isActive && checkPermission('DeActivate'))) && 
                                                        <Tooltip title={`${isActive? "Delete" : "Restore"}`}>
                                                            <IconButton
                                                                size="small"
                                                                onClick={(e: any) => { statusChangeHandler(id,!isActive) }}
                                                            >
                                                                <img src={isActive ? deleteIcon : restoreIcon} />
                                                            </IconButton>
                                                        </Tooltip> 
                                                    }
                                                </Box>
                                            </Box>
                                        }
                                    />
                                </Grid>
                            }
                        </React.Fragment>)
                })}
            </Grid>
        </Box>
    )
}

export default SurveyList;