import axios,{AxiosResponse} from "axios";
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../../actions/Snackbars";

export const url = `${configs.BASE_URL}/articleType`;
const deleteUrl = `${configs.BASE_URL}/articleType/permanent/delete/`;
const bulkUpdate = `${configs.BASE_URL}/articleType/status/change?ids=`;

export const getArticleType = (callback:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(url);
      response.data = response.data.map((i:any)=>{
        return {...i,rid:`#${i.id}`,value:i.name,label:i.name}
      }) 
      dispatch({
        type : "STORE_ALL_ARTICLE_TYPES",
        payload : response.data
      })
      try{callback && callback("0",response.data)}catch(e){}
    } catch (error:any) {
      callback && callback("1",error.response)
      return console.log(error.response);
    }
  };
};
export const ArticleTypeAdd = async (data: any) => {
  try {
   let s =  await axios.post(`${configs.BASE_URL}/ArticleType`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return s


  } catch (error:any) {
    if (error.response) {
     
      return {
        isError:true,
        message:error.response.data
      }
    }  
  }
};

export const updateArticleType = async (data: any, id: any,) => {
  try {
    let s = await axios.put(`${url}/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("updateArticleType success bro", s)
    return s
  } catch (error:any) {
    if (error.response) {
      console.log("this is error of update",error.response.data)

      return {
        isError:true,
        message:error.response.data
      }
    }  

  }
  
}

export const singleArticleType = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${url}/${id}`);
      dispatch({
        type: "singleAssetStatus",
        payload: response,
      });
    } catch (error) {
      return console.log(error);
    }
  };
};




export const multipalUpdateArticleType = async (data: any, ids: any,status:any) => {
  
  
  try {
    let result = await axios.delete(`${bulkUpdate}${ids}&status=${status}`, data);
    console.log("bulk delete",result)
    
   if (result.status==200){
    return true
    
   }
   else{
    return false
   }
  }  catch (error:any) {
    if (error.response) {
     
      return error.response.data
    }  
  }
};


export const softDeleteArticleType = async (id: any) => {
  {
    try {
      let result = await axios.delete(`${bulkUpdate}${id}`);
      
     if (result.status==200){
      return true
      
     }
     else{
      return false
     }
    }  catch (error:any) {
      if (error.response) {
       
        return error.response.data
      }  
    }
  };
}
export const changeArticleType = async (id: any, status:boolean) => {
  {
    try {
      let result = await axios.delete(`${configs.BASE_URL}/ArticleType/status/change?ids=${id}&status=${status}`);
      
     if (result.status==200){
      return true
      
     }
     else{
      return false
     }
    }  catch (error:any) {
      if (error.response) {
       
        return error.response.data
      }  
    }
  };
}

export const deleteArticleType = async(id: any) => {
  {
    try {
      let s =  await axios.delete(`${deleteUrl}${id}`);
      
      console.log("result after calling AssetStatusAdd", s)

      return s.data
    }  catch (error:any) {
      if (error.response) {
       
        return error.response.data
      }  
    }
  };
};
export const SnackBar =(msg: any,severity:string)=>{
  return async (dispatch: any) => {

  dispatch({
    type: ActionType.SNACKBAR,
    payload: {
      message: msg,
      severity: severity,
      open: true,
    },
  });
}
}

export const loading = (status: any) => {
  return {
    type: "loading",
    payload: status,
  };
};

export default ArticleTypeAdd;