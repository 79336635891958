import { connect } from 'react-redux';
import { IState } from '../../../../../../redux/reducers/rootReducers';
import Merge from '../../../../../merge/popup/Merge';
interface IMergePopupProps{
  ticketToMerge?:any
}
function MergePopup(props:IMergePopupProps) {
  const {ticketData} = props?.ticketToMerge || {};
  return (
    <>
      {ticketData && (
        <Merge />
      )}
    </>
  )
}

const mapStateToProps = (state:IState) => {
  return ({
    ticketToMerge: state?.TicketViewReducer?.linkMerge,
  })
};

export default connect(mapStateToProps)(MergePopup);