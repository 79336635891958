import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Box, FormControlLabel, Grid, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { createTheme } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getSTatusOrg } from "../../services/cmdb/Organization";
import DoughnutChart from "../charts/dougnut/DoughnutChart";
import CommonStyles from "../common/CommonStyles";
import CustomCard from "../modules/home/CustomCard";
import LogoImg from "./../../assest/logo/logo.svg";


function Status() {
  const location = useLocation();
  const dispatch = useDispatch()
  const classes1 = useStyles()
  const classes = CommonStyles();
  const navigate = useNavigate();
  const chartRef: any = useRef(null);
  const [response, setResponse] = useState<any>("")
  const [expanded, setExpanded] = useState('');
  const [status, setStatus] = useState<any>([])
  const [formData, setFormData] = useState<any>({
    labelsForFilterByUnResolved: [
      "InitiatingDatabaseCreating",
      "SystemManagementDbCreating",
      "TicketManagementDbCreating",
      "FileManagementDbCreating",
    ],
    dataSetForFilterByUnResolved: [
      {
        "label": "data set 1",
        "data": [
          25,
          25,
          25,
          25
        ],
        "backgroundColor": ['#5b62c3', '#5b62c3', "#5b62c3", "#5b62c3"],
        "borderColor": [
          "#ffffff",
          "#ffffff",
          "#ffffff",
          "#ffffff"
        ],
        "borderWidth": 1
      }
    ]
  })

  const orgName = new URLSearchParams(location.search).get('orgName');

  useEffect(() => {
    dispatch(getSTatusOrg(orgName, resCB));
  }, [])

  const resCB = (res: any,) => {
    setResponse(res)
    let newStatus: any;

    switch (res) {
      case 'InitiatingDatabaseCreating':
        newStatus = ['InitiatingDatabaseCreating'];
        break;
      case 'SystemManagementDbCreating':
        newStatus = ['InitiatingDatabaseCreating', 'SystemManagementDbCreating'];
        break;
      case 'TicketManagementDbCreating':
        newStatus = ['InitiatingDatabaseCreating', 'SystemManagementDbCreating', 'TicketManagementDbCreating'];
        break;
      case 'FileManagementDbCreating':
        newStatus = ['InitiatingDatabaseCreating', 'SystemManagementDbCreating', 'TicketManagementDbCreating', 'FileManagementDbCreating'];
        break;
      case 'Completed':
        newStatus = ['InitiatingDatabaseCreating', 'SystemManagementDbCreating', 'TicketManagementDbCreating', 'FileManagementDbCreating', 'Completed'];
        break;
      default:
        newStatus = [];
    }

    setStatus(newStatus);
    if (response !== res) {
      const a = JSON.parse(JSON.stringify(formData));//{ ...formData }
      a.dataSetForFilterByUnResolved[0].backgroundColor = getColorForResponse(res)
      //const deepCopy = JSON.parse(JSON.stringify(a));
      setFormData(a)
      console.log("dataSetForFilterByUnResolved", a);
    }
    if (res !== "Completed") {
      setTimeout(() => {
        dispatch(getSTatusOrg(orgName, resCB));
      }, 2000)
    }
    debugger
    if (res === "Completed") {
      setTimeout(() => {
        navigate("/success/1");
      }, 4000);
    }
  }

  const handleChange = (panel: string) => {
    setExpanded(expanded === panel ? "" : panel);
  };
  const getColorForResponse: any = (response: any) => {
    switch (response) {
      case 'InitiatingDatabaseCreating':
        return ['#5b62c3', '#5b62c3', "#5b62c3", "#5b62c3"];
      case 'SystemManagementDbCreating':
        return ['green', '#5b62c3', "#5b62c3", "#5b62c3"];
      case 'TicketManagementDbCreating':
        return ['green', 'green', "#5b62c3", "#5b62c3"];
      case 'FileManagementDbCreating':
        return ['green', 'green', "green", "#5b62c3"];
      case 'Completed':
        return ['green', 'green', "green", "green"];
      default:
        return ['#14539a', '#ffffff']; // Default to #5b62c3
    }
  };
  console.log("responseresponse", response, status);
  function getMessageForAccordion(response: any, accordionType?: any) {
    switch (accordionType) {
      case "InitiatingDatabaseCreating":
        return "Database Initialization "

      case "SystemManagementDbCreating":
        return "Ticket Type ,Ticket Status,Category,Working Time Plan,Priority,SLA,Email Templates Etc...."

      case "TicketManagementDbCreating":
        return "Ticket Management Dashboard,Ticket Table,Ticket card,Ticket Info and Ticket Tasks Etc..."

      case "FileManagementDbCreating":
        return "File Management Database"

      default:
        return "Pending";
    }
  }
  const CheckStatus = (ele: any) => {
    let sts: any = "Pending";
    if (response === ele) {
      sts = "Inprogress"
    } else if (status.includes(ele)) {
      sts = "Done"
    }
    return sts
  }


  return (
    <>
      <Box className="gutter-v bg-theme">
        <Box className={classes1.pricingTopBar}>
          <Box>
            <img
              height={66}
              width={"auto"}
              src={LogoImg}
              alt=""
              onClick={() => {
                navigate("/");
              }}
            />
          </Box>
        </Box>
      </Box>
      <div
        style={{ width: "100%", padding: "30px", height: "500px" }}
        className={classes1.cardForPriorityAndTickets}
      ><CustomCard
          className={classes1.cardForPriorityAndTickets}
          prefixIcon={
            <div
              style={{
                fontSize: "40px",
                color: "#5b62c3",
                fontWeight: 900,
                alignItems: "flex-start",
                display: "flex",
                flexDirection: "column",
              }}
            >
            </div>
          }
          headerColor="white"
          height="auto"
          width={"100%"}
          header={
            <div
              style={{
                fontSize: "20px",
                color: "#5b62c3",
                fontWeight: 900,
                padding:"10px"
              }}
            >
              Organisation Status Tracking
            </div>
          }
          subHeader={<></>}
          body={(
            <>
              <Grid container className="" spacing={1}>
                <Grid item md={5} xs={12}>
                  <Box height={350}>
                    <DoughnutChart
                      id="chart-1"
                      ref={chartRef}
                      showLegend={false}
                      labels={
                        formData?.labelsForFilterByUnResolved
                      }
                      datasets={
                        formData?.dataSetForFilterByUnResolved
                      }
                    />
                  </Box>
                </Grid>
                <Grid item md={1} xs={12}>
                  {/* {response} */}
                </Grid>
                <Grid item md={4} xs={12}>
                  <Accordion
                    className="accordionDtls"
                    expanded={expanded === 'panel1'}
                    onChange={() => handleChange('panel1')}
                  >
                    <AccordionSummary aria-controls={`d-content`} id={`d-header`} className='accrodTitle'>
                      <Box className={`${classes.row} ${classes.justifyBetween} w-100`}>
                        <Box className={`${classes.row} ${classes.alignItemsCenter}`} >
                          {status.includes("SystemManagementDbCreating") ? <CheckCircleIcon style={{ color: "green" }}></CheckCircleIcon> : <ErrorIcon style={{ color: "#F39732" }}></ErrorIcon>}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <Typography style={{fontSize:"14px"}}>Initiating Database Creation</Typography>
                        </Box>
                        <Box className={`${classes.row} ${classes.alignItemsCenter}`} >
                          <Box className={classes.statusDoneBtn}>
                            <FormControlLabel
                              style={{ margin: "0px" }}
                              value=''
                              label={CheckStatus("InitiatingDatabaseCreating")}
                              labelPlacement="end"
                              className="sml-txt"
                              control={<></>}
                            />
                          </Box>
                          {/* <Box className={`${classes.row} ${classes.alignItemsCenter}`} > */}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {expanded === 'panel1' ? (
                            <KeyboardArrowUpOutlinedIcon className="pointer" />
                          ) : (
                            <KeyboardArrowDownOutlinedIcon className="pointer" />
                          )
                          }
                        </Box>
                      </Box>

                    </AccordionSummary>
                    <AccordionDetails>
                      <div>{getMessageForAccordion(response, "InitiatingDatabaseCreating")}</div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionDtls"
                    expanded={expanded === 'panel2'}
                    onChange={() => handleChange('panel2')}
                  >
                    <AccordionSummary aria-controls={`d-content`} id={`d-header`} className='accrodTitle'>
                      <Box className={`${classes.row} ${classes.justifyBetween} w-100`}>
                        <Box className={`${classes.row} ${classes.alignItemsCenter}`} >
                          {status.includes("TicketManagementDbCreating") ? <CheckCircleIcon style={{ color: "green" }}></CheckCircleIcon> : <ErrorIcon style={{ color: "#F39732" }}></ErrorIcon>}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <Typography style={{fontSize:"14px"}}>System Management Db Creation</Typography>
                        </Box>
                        <Box className={`${classes.row} ${classes.alignItemsCenter}`} >
                          <Box className={classes.statusInProgressBtn}>
                            <FormControlLabel
                              style={{ margin: "0px" }}
                              value=''
                              label={CheckStatus("SystemManagementDbCreating")}
                              labelPlacement="end"
                              className="sml-txt"
                              control={<></>}
                            />
                          </Box>
                          {/* <Box className={`${classes.row} ${classes.alignItemsCenter}`} > */}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {expanded === 'panel2' ? (
                            <KeyboardArrowUpOutlinedIcon className="pointer" />
                          ) : (
                            <KeyboardArrowDownOutlinedIcon className="pointer" />
                          )
                          }
                        </Box>
                      </Box>

                    </AccordionSummary>
                    <AccordionDetails>
                      <div>{getMessageForAccordion(response, "SystemManagementDbCreating")}</div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionDtls"
                    expanded={expanded === 'panel3'}
                    onChange={() => handleChange('panel3')}
                  >
                    <AccordionSummary aria-controls={`d-content`} id={`d-header`} className='accrodTitle'>
                      <Box className={`${classes.row} ${classes.justifyBetween} w-100`}>
                        <Box className={`${classes.row} ${classes.alignItemsCenter}`} >
                          {status.includes("FileManagementDbCreating") ? <CheckCircleIcon style={{ color: "green" }}></CheckCircleIcon> : <ErrorIcon style={{ color: "#F39732" }}></ErrorIcon>}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <Typography style={{fontSize:"14px"}}>Ticket Management Db Creation</Typography>
                        </Box>
                        <Box className={`${classes.row} ${classes.alignItemsCenter}`} >
                          <Box className={classes.statusPendingBtn}>
                            <FormControlLabel
                              style={{ margin: "0px" }}
                              value=''
                              label={CheckStatus("TicketManagementDbCreating")}
                              labelPlacement="end"
                              className="sml-txt"
                              control={<></>}
                            />
                          </Box>
                          {/* <Box className={`${classes.row} ${classes.alignItemsCenter}`} > */}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {expanded === 'panel3' ? (
                            <KeyboardArrowUpOutlinedIcon className="pointer" />
                          ) : (
                            <KeyboardArrowDownOutlinedIcon className="pointer" />
                          )
                          }
                        </Box>
                      </Box>

                    </AccordionSummary>
                    <AccordionDetails>
                      <div>{getMessageForAccordion(response, "TicketManagementDbCreating")}</div>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionDtls"
                    expanded={expanded === 'panel4'}
                    onChange={() => handleChange('panel4')}
                  >
                    <AccordionSummary aria-controls={`d-content`} id={`d-header`} className='accrodTitle'>
                      <Box className={`${classes.row} ${classes.justifyBetween} w-100`}>
                        <Box className={`${classes.row} ${classes.alignItemsCenter}`} >
                          {status.includes("Completed") ? <CheckCircleIcon style={{ color: "green" }}></CheckCircleIcon> : <ErrorIcon style={{ color: "#F39732" }}></ErrorIcon>}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <Typography style={{fontSize:"14px"}}>File Management Db Creation</Typography>
                        </Box>
                        <Box className={`${classes.row} ${classes.alignItemsCenter}`} >
                          <Box className={classes.statusPendingBtn}>
                            <FormControlLabel
                              style={{ margin: "0px" }}
                              value=''
                              label={CheckStatus("FileManagementDbCreating")}
                              labelPlacement="end"
                              className="sml-txt"
                              control={<></>}
                            />
                          </Box>
                          {/* <Box className={`${classes.row} ${classes.alignItemsCenter}`} > */}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {expanded === 'panel4' ? (
                            <KeyboardArrowUpOutlinedIcon className="pointer" />
                          ) : (
                            <KeyboardArrowDownOutlinedIcon className="pointer" />
                          )
                          }
                        </Box>
                      </Box>

                    </AccordionSummary>
                    <AccordionDetails>
                      <div>{getMessageForAccordion(response, "TicketManagementDbCreating")}</div>
                    </AccordionDetails>
                  </Accordion>

                </Grid>
              </Grid>

            </>
          )
          }
        />
      </div>

      <div style={{ marginTop: "150px" }}>


      </div>
    </>
  );
}
export default Status

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 469,
      sm: 699,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
const useStyles: any = makeStyles(() =>
  createStyles({
    pricingTopBar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "spaceBetween",
      padding: "15px",
      // paddingTop: "20px",
      borderBottom: "1px solid #ddd",
      flexDirection: "row",
    },
    cardForPriorityAndTickets: {
      [theme.breakpoints.down("lg")]: {
        width: "100% !important",
      },
    },
  })
);