import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../../redux/reducers/rootReducers";
import { Box,  Popover, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { storeFiltersValue } from "../../../../redux/actions/TicketsManagement/GetTickets";
import { makeStyles } from "@mui/styles";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
export const LayoutShift = memo(() => {
    console.log('SearchBox_rerendered', 'SortOption')
    const dispatch = useDispatch();
    const classes:any = useStyles();
    const LayoutType: any = useSelector(
        (state: IState) => state?.LayoutTicketManagementReducer?.layout
    );
    const [viewVal,setViewVal] = useState(LayoutType ? LayoutType : "Priority")
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    useEffect(()=>{
        dispatch(storeFiltersValue(viewVal, 'TCKT_LAYOUT_VALUE'));
    },[viewVal])
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const clickHandler = ( value: string) => {
        //dispatch(storeFiltersValue(value, 'TCKT_LAYOUT_VALUE'));
        setViewVal(value)
        handleClose();
    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <>
            <Typography aria-describedby={id} onClick={handleClick} className={`${classes.filterItem} toggler`}>
                <MenuOutlinedIcon /> {LayoutType}  <ArrowDropDownIcon />
            </Typography>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ p: 1 }}>
                    <Typography onClick={() => { clickHandler("Status") }} className={`${classes.filterItem} ${LayoutType === "Status" ? "active" : "" }`}>
                        <span>Status</span>
                    </Typography>
                    <Typography onClick={() => { clickHandler("Priority") }} className={`${classes.filterItem} ${LayoutType === "Priority" ? "active" : "" }`}>
                        <span>Priority</span>
                    </Typography>
                    <Typography onClick={() => { clickHandler("List") }} className={`${classes.filterItem} ${LayoutType === "List" ? "active" : "" }`}>
                        <span>List View</span>
                    </Typography>
                </Box>
            </Popover>
        </>
    )
})
const useStyles:any = makeStyles(() => ({
    filterTitle:{
        fontSize:"13px",
        borderBottom:"1px solid #ddd"
    },
    filterItem:{
        fontSize:"11px",
        padding: "2px 5px",
        display: "flex",
        alignItems: "center",
        justifyContent:"space-between",
        cursor:"pointer",
        gap: "2px",
        "&.toggler":{
            cursor:"pointer",
            "&.active":{
                color:"#0b0b89 !important",
                background:"#fff0 !important"
            },
            "&:hover":{
                color:"#0b0b89 !important",
                background:"#fff0 !important"
            },
        },
        "& svg":{
            width: "18px",
            height: "18px",
            color: "#777",
            cursor:"pointer",
            "&:hover":{
                color:"#007bff"
            },
            "&.active":{
                color:"#007bff"
            },
        },
        "&:hover":{
            background:"#2f80ed14"
        },
        "&.active":{
            background:"#2f80ed14"
        },
    },
    checkedIcon:{
        color:"#007bff !important"
    }
}))
export default LayoutShift;