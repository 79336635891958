import Joi from "joi";

// VALIDATION FOR TICKET CREATION FORM DATA
const ticketTypeSchema = Joi.number()
  .required()
  .custom((value, helpers) => {
    if (value === 0) {
      return helpers.error("number.notZero");
    }
    return value;
  })
  .messages({
    "number.base": "Ticket type is required.",
    "any.required": "Ticket type is required.",
    "number.notZero": "Ticket type is required.",
  });

const subjectSchema = Joi.string()
  .trim()
  .max(100)
  .messages({
    "string.empty": "Name is required.",
    "any.required": "Name is required.",
    "string.max":
      "Name length must be less than or equal to 100 characters.",
  });
const costSchema = Joi.string()
  .trim()
  .max(100)
  .messages({
    "string.empty": "Cost is required.",
    "any.required": "Cost is required.",
    "string.max":
      "Cost length must be less than or equal to 100 characters.",
});
const descSchema = Joi.string().trim().max(5000).required().messages({
  "string.empty": "Description is required.",
  "any.required": "Description is required.",
  "string.max":
    "Description length must be less than or equal to 100 characters.",
});

const catSchema = Joi.number().required()
  .custom((value, helpers) => {
    if (value === 0) {
      return helpers.error("number.notZero");
    }
    return value;
  })
  .messages({
  "number.empty": "Category is required.",
  "number.base": "Category is required.",
  "any.required": "Category is required.",
  "number.notZero": "Category is required.",
});

const groupIdSchema = Joi.number()
  .required()
  .custom((value, helpers) => {
    if (value === 0) {
      return helpers.error("number.notZero");
    }
    return value;
  })
  .messages({
    "number.base": "Group is required.",
    "any.required": "Group is required.",
    "number.notZero": "Group is required.",
  });

  const agentIdSchema = Joi.number()
  .required()
  .custom((value, helpers) => {
    if (value === 0) {
      return helpers.error("number.notZero");
    }
    return value;
  })
  .messages({
    "number.base": "Agent is required.",
    "any.required": "Agent is required.",
    "number.notZero": "Agent is required.",
  });

  const webformIdSchema = Joi.number()
  .required()
  .custom((value, helpers) => {
    if (value === 0) {
      return helpers.error("number.notZero");
    }
    return value;
  })
  .messages({
    "number.base": "WebForm is required.",
    "any.required": "WebForm is required.",
    "number.notZero": "WebForm is required.",
  });

  const iconIdSchema = Joi.number()
  .required()
  .custom((value, helpers) => {
    if (value === 0) {
      return helpers.error("number.notZero");
    }
    return value;
  })
  .messages({
    "number.base": "Icon is required.",
    "any.required": "Icon is required.",
    "number.notZero": "Icon is required.",
  });
export const addCatalogueSchema = Joi.object({
    name: subjectSchema,
    description: descSchema,
    // cost : costSchema,
    // categoryId: catSchema,
    ticketTypeId: ticketTypeSchema,
    // webformId : webformIdSchema,
    groupId : groupIdSchema,
    // agentId : agentIdSchema,
    systemIconsId : iconIdSchema,
});
