import LoadingButton from '@mui/lab/LoadingButton';
import { createTheme } from "@mui/material/styles";
const theme = createTheme();

export interface iBasicButton {
  variant?: any, 
  isDisabled?: boolean, 
  size?: any, 
  isLoading?: boolean, 
  endIcon?: any, 
  color?: any, 
  type?:any, 
  label?:any,
  onClick?: any
}

export default function BasicButton(props:any) {
  return (
    <>
      <LoadingButton 
        sx={{  
          textTransform:"capitalize",
          fontWeight: 300
        }} 
        loadingPosition= {props?.endIcon ? "end" : undefined}
        {...props}
      >{props?.label || "Button"}
      </LoadingButton>
    </>
  );
}