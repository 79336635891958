import CancelIcon from "@mui/icons-material/Cancel";
import { Autocomplete, Box, Button, Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    image,
    lineHeight,
    link,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
} from 'suneditor/src/plugins';
import * as Yup from 'yup';
import AppLoader from "../../../components/common/AppLoader";
import CommonStyles from "../../../components/common/CommonStyles";
import CustomFormLabel from "../../../components/common/form-label/CustomFormLabel ";
import Breadcrumbs from "../../../components/common2/Breadcrumbs";
import Dropdown from "../../../components/common2/Dropdown";
import InputBox from "../../../components/common2/InputBox";
import AutoCompleteInputBox from "../../../components/common2/autoComplete";
import { getEmailSettingsSsp } from "../../../redux/actions/SelfServicePortal/ContactAid/EmailSettingsSsp/EmailSettingsSsp";
import { addEmailTemplatesSspAction, editEmailTemplatesSsp, getDynamicFieldsSsp, getTemplatesSspByID } from "../../../redux/actions/SelfServicePortal/ContactAid/EmailTempletesSsp/EmailTempletesSsp";
import { IState } from "../../../redux/reducers/rootReducers";
const getValidationSchema = () => {
    const schemaObject: any = {};
    schemaObject['name'] = Yup.string().test('name',
        function (value = "") {
            if (value.trim() === '') {
                return this.createError({ path: this.path, message: "Please Enter Name" });
            } else { return true; }
        }
    );
    schemaObject['body'] = Yup.string().test('body',
        function (value = "") {
            if (value.trim() === '' || value.trim() === '<p><br></p>') {
                return this.createError({ path: this.path, message: "Please Enter Body Content" });
            } else { return true; }
        }
    );
    schemaObject['subject'] = Yup.string().test('subject',
        function (value = "") {
            if (value.trim() === '') {
                return this.createError({ path: this.path, message: "Please Enter Subject" });
            } else { return true; }
        }
    );
    schemaObject['roleId'] = Yup.string().test('role',
        function (value) {
            if (value === '0') {
                return this.createError({ path: this.path, message: "Please Select Role" });
            } else { return true; }
        }
    );
    schemaObject['from'] = Yup.string().test('from',
        function (value = "") {
            if (value.trim() === '0' || value.trim() === '') {
                return this.createError({ path: this.path, message: "Please Select From" });
            } else { return true; }
        }
    );
    return Yup.object().shape(schemaObject);
};
function AddEmailTemplatesSsp() {
    const param: any = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loader, setloader] = useState(false);
    const [TemplateActions, setTemplateActions] = useState([]);
    // const [mailServicesByRole, setmailServicesByRole] = useState([]);
    const TemplatesActions = useSelector(
        (state: IState) => state?.EmailTemplatesSspReducer?.AllTemplateActionsSsp
    );
    const SettingsList = useSelector(
        (state: IState) => state?.EmailSettingsSspReducer?.AllEmailSettingsSsp
    );
    const DynamicFieldsList = useSelector(
        (state: IState) => state?.EmailTemplatesSspReducer?.AllDynamicActionsSsp
    );
    const [allDynamicFields, setallDynamicFields] = useState([]);
    const [from,setFrom]=useState([])
    const [Data, setData] = useState({
        name: "",
        subject: "",
        body: "",
        companyName: "",
        from: "",
        cc: "",
        actions: "",
        to: "",
        bcc: "",
        enabled: true,
        description: "",
        isDefault:true,
        isActive: true
    });
    useEffect(() => {
        if (SettingsList.length > 0) {
            if(param.id){
                dispatch(getTemplatesSspByID(param.id, getTemplateByIdCB));
            }
            const updatedTemplatesList = SettingsList.map((ele: any) => ({
                ...ele,
                name: ele.mailId,
            }));
            setFrom(updatedTemplatesList);
        }
    }, [SettingsList]);
    useEffect(() => {
        if (DynamicFieldsList) {
            console.log(DynamicFieldsList);
            setallDynamicFields(DynamicFieldsList.map((x: any) => {
                x.isActive = true;
                return x;
            }));
        }
    }, [DynamicFieldsList]);
    useEffect(() => {
        if (TemplatesActions) {
            setTemplateActions(TemplatesActions.map((x: any) => {
                x.RecordId = x.id;
                x.id = x.name;
                return x;
            }))
        }
    }, [TemplatesActions]);
    const getTemplateByIdCB = (val: any) => {
        try {
            let b=SettingsList.find((ele:any)=>ele.mailId===val.from)
            console.log('popopop', val,b)
            setData({ ...val,from:b.id})    
        } catch (error) {
            throw new Error('getTemplateByIdCB Error - code broken');
        }
        
    }
    useEffect(() => {
        dispatch(getEmailSettingsSsp());
        dispatch(getDynamicFieldsSsp())
    }, [])
    const backHandler = () => {
        navigate("/EmailTempleteSsp");
    };
    return (
        <>
            
            <div>
                <AppLoader enabled={loader} />
                <Breadcrumbs
                    data={[
                        {
                            title: 'Home',
                            path: '/adminPanel'
                        },
                        {
                            title: 'EmailTemplates ',
                            path: '/EmailTempleteSsp'
                        },
                        {
                            title: `${!param.id ? "Add " : "Edit "}Email Template`,
                            path: `${!param.id ? "/AddEmailTempleteSsp" : `/AddEmailTempleteSsp/${param.id}`}`
                        }
                    ]}
                />
                <br />
                {(!param.id || Data.name !== "") &&
                    <Formik
                        initialValues={{ ...Data }}
                        validationSchema={getValidationSchema()}
                        onSubmit={(values, { resetForm }) => {
                            console.log('Success! Data submitted.', values);
                            let fromName:any=from.find((ele:any)=>ele.id===values.from);
                            console.log(values.from,fromName);
                            const request: any = { ...values }
                            request.cc = request.cc === "" ? null : request.cc;
                            request.to = request.to === "" ? null : request.to;
                            request.bcc = request.bcc === "" ? null : request.bcc;
                            request.from = fromName.mailId
                            const originUrl = window.location.origin
                            request.body = request.body.replaceAll(`${originUrl}/`, "")
                            console.log("request.body", request.body);
                            if (param.id) {
                                dispatch(editEmailTemplatesSsp(param.id, request, backHandler));
                            } else {
                                dispatch(addEmailTemplatesSspAction(request, backHandler));
                            }
                            //resetForm();
                        }}
                    >
                        {(props) => <AddForm
                            // setmailServicesByRole={setmailServicesByRole}
                            // mailServicesByRole={mailServicesByRole}
                            // settingsByRoleCB={settingsByRoleCB}
                            backHandler={backHandler}
                            allDynamicFields={allDynamicFields}
                            from={from}
                            templateActions={TemplateActions}
                            {...props}
                        />}
                    </Formik>
                }
            </div>
        </>
    )
}

export const AddForm = (props: any) => {
    const { errors, touched, allDynamicFields, backHandler, submitForm, mailServicesByRole, isSubmitting, values, setFieldValue, templateActions,from} = props;
    const dispatch = useDispatch();
    const param: any = useParams();
    const [DynamicData, setDynamicData] = useState({} as any);
    const classes = CommonStyles();
    useEffect(() => {
        console.log('props123', props)
    }, [props]);
    const pasteDynamicField = (e: any) => {
        if (Object.keys(DynamicData).length > 0) {
            let val = e.target.value;
            const name = e.target.name;
            setFieldValue(`${name}`, val + DynamicData.value)
            setDynamicData({})
        }
    }
    const handleChange = (content: any) => {
        setFieldValue("body", content)
    }
    return (
        <>
            <Box className={`pageHolder ${classes.px1}`}>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item md={4} xs={12}>
                            <div className='aside'>
                                <Box className="mb2 pb2">
                                    <Typography variant="h6" className={`${classes.themeColorTxt}`}>
                                        {!param.id ? "Add " : "Edit "}
                                        Email Template 
                                    </Typography>
                                </Box>
                                <InputBox
                                    title="Title "
                                    type="text"
                                    required={true}
                                    classname="h-inputs sm-pd"
                                    placeholder="Please Enter"
                                    styleProp={classes.BoxTitle}
                                    inputStyle={classes.inputStyle}
                                    name="name"
                                    onChange={(e: any) => {
                                        setFieldValue("name", e.target.value);
                                    }}
                                    value={values?.name}
                                    errMsg='Please Enter Title'
                                    showErr={touched?.name && errors?.name ? true : false}
                                />
                                <Dropdown
                                    data={from}
                                    label="From "
                                    required={true}
                                    value={values?.from === '' ? 0 : values?.from}
                                    name="from"
                                    classname="h-inputs sm-pd"
                                    onInputChangeHandler={(e: any) => {
                                        setFieldValue("from", e.target.value);
                                    }}
                                    styplePropTitle={classes.title}
                                    inputStyle={classes.inputStyle}
                                    errMsg='Please Choose From'
                                    showErr={touched?.from && errors?.from ? true : false}
                                />
                                
                                <Box className="text-center">
                                    <Button
                                        className={`${classes.btn} ${classes.btnCancel}`}
                                        variant="outlined"
                                        onClick={backHandler}
                                    >
                                        <CancelIcon style={{ marginRight: "5px" }} />
                                        Cancel
                                    </Button>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <Button
                                        className={`${classes.btn} ${classes.btnSubmit}`}
                                        variant="contained"
                                        onClick={submitForm}
                                    >
                                        {!param?.id ? "Save" : "Update"}
                                    </Button>
                                </Box>
                            </div>
                        </Grid>
                        <Grid item md={7} xs={12}>
                            <Autocomplete
                                value={DynamicData.value ? DynamicData.value : ''}
                                disablePortal
                                options={allDynamicFields.map((i: any) => i.name)}
                                // sx={{ height: 300 }}
                                size="small"
                                onChange={(event: any, newValue: string | null) => {
                                    let data = allDynamicFields.filter(
                                        (j: any) => j.name == newValue
                                    );
                                    if (data.length > 0) {
                                        setDynamicData(data[0]);
                                    }
                                }}
                                renderInput={(params) => {
                                    //console.log(params);
                                    const values = { ...params }//, ['value'] : Object.keys(DynamicData).length > 0 ? DynamicData.value : ''}
                                    //console.log('>>>=============<<<  ',values);
                                    return (
                                        <AutoCompleteInputBox placeholder='Choose Field' {...params} title="Dynamic Fields" />
                                    )
                                }}
                            />
                            <br />
                            <Box className={`${classes.whiteBox} emailTemplateMailBox`}>
                                <InputBox
                                    title=""
                                    type="text"
                                    classname="h-inputs sm-pd"
                                    placeholder="To"
                                    styleProp={classes.BoxTitle}
                                    inputStyle={classes.inputStyle}
                                    name="to"
                                    onChange={(e: any) => {
                                        setFieldValue("to", e.target.value);
                                    }}
                                    value={values?.to}
                                    onFocusHandler={pasteDynamicField}
                                    errMsg='Please Enter'
                                    showErr={touched?.to && errors?.to ? true : false}
                                />

                                <InputBox
                                    title=""
                                    type="text"
                                    classname="h-inputs sm-pd"
                                    placeholder="CC"
                                    styleProp={classes.BoxTitle}
                                    inputStyle={classes.inputStyle}
                                    name="cc"
                                    onChange={(e: any) => {
                                        setFieldValue("cc", e.target.value);
                                    }}
                                    value={values?.cc}
                                    onFocusHandler={pasteDynamicField}
                                    errMsg='Please Enter'
                                    showErr={touched?.cc && errors?.cc ? true : false}
                                />

                                <InputBox
                                    title=""
                                    type="text"
                                    classname="h-inputs sm-pd"
                                    placeholder="BCC"
                                    styleProp={classes.BoxTitle}
                                    inputStyle={classes.inputStyle}
                                    name="bcc"
                                    onChange={(e: any) => {
                                        setFieldValue("bcc", e.target.value);
                                    }}
                                    value={values?.bcc}
                                    onFocusHandler={pasteDynamicField}
                                    errMsg='Please Enter'
                                    showErr={touched?.bcc && errors?.bcc ? true : false}
                                />

                                <InputBox
                                    title=""
                                    type="text"
                                    classname="h-inputs sm-pd"
                                    placeholder="Subject *"
                                    styleProp={classes.BoxTitle}
                                    inputStyle={classes.inputStyle}
                                    name="subject"
                                    onChange={(e: any) => {
                                        setFieldValue("subject", e.target.value);
                                    }}
                                    value={values?.subject}
                                    onFocusHandler={pasteDynamicField}
                                    errMsg='Please Enter Subject'
                                    showErr={touched?.subject && errors?.subject ? true : false}
                                />
                                <br />
                                <CustomFormLabel labelName={"Body Content"} isMandotary={true} />
                                <Box>
                                    <SunEditor
                                        autoFocus={true}
                                        lang="en"
                                        setOptions={{
                                            showPathLabel: false,
                                            minHeight: "50vh",
                                            maxHeight: "50vh",
                                            placeholder: "Enter your text here!!!",
                                            plugins: [
                                                align,
                                                font,
                                                fontColor,
                                                fontSize,
                                                formatBlock,
                                                hiliteColor,
                                                horizontalRule,
                                                lineHeight,
                                                list,
                                                paragraphStyle,
                                                table,
                                                template,
                                                textStyle,
                                                image,
                                                link
                                            ],
                                            buttonList: [
                                                ["undo", "redo"],
                                                ["font", "fontSize", "formatBlock"],
                                                ["paragraphStyle"],
                                                [
                                                    "bold",
                                                    "underline",
                                                    "italic",
                                                    "strike",
                                                    "subscript",
                                                    "superscript"
                                                ],
                                                ["fontColor", "hiliteColor"],
                                                ["removeFormat"],
                                                "/", // Line break
                                                ["outdent", "indent"],
                                                ["align", "horizontalRule", "list", "lineHeight"],
                                                ["table", "link", "image"]
                                            ],
                                            formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                                            font: [
                                                "Arial",
                                                "Calibri",
                                                "Comic Sans",
                                                "Courier",
                                                "Garamond",
                                                "Georgia",
                                                "Impact",
                                                "Lucida Console",
                                                "Palatino Linotype",
                                                "Segoe UI",
                                                "Tahoma",
                                                "Times New Roman",
                                                "Trebuchet MS"
                                            ],
                                            imageMultipleFile: true,
                                        }}
                                        defaultValue={values?.body}
                                        setContents={values?.body}
                                        onChange={(content: string) => {
                                            handleChange(content)
                                        }}
                                    />
                                    {touched?.body && errors?.body &&
                                        <p><span className={classes.dangerTxt}>Please Enter Body Content</span></p>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}
export default AddEmailTemplatesSsp