const initialState = {
    Data: []
  };
  
  const IntuneReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case "INTUNE_INFO":
        return {
          ...state,
          Data: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default IntuneReducer;
  