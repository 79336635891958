import { memo, useEffect } from "react";
import CommonStyles from "../../../../../common/CommonStyles";
import { Box, Typography } from "@mui/material";

export const PriorityTitle = memo((props:any) =>{
    const {title,color} = props;
    // const classes = CommonStyles();
    return(
        <Typography variant="body2" className="text-center">
            {title} Priority Tickets
         {/* <Box
        //     className={`${classes.tcktPriorityTitle}`}
        //     sx={{ background: color ? color : "#ddd" }}
        // >
        //     {title} Priority Tickets
        // </Box> */}
        </Typography>
    )
})
export default PriorityTitle;