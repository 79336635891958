import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useLocation } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import CommonStyles from "./../common/CommonStyles";
import intl from "react-intl-universal";
const useStyles = CommonStyles;

function handleClick(event:any) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const locationData = ['/Groups','/Roles','/Agents', '/Agent-Groups']

export default function CustomSeparator(props:any) {
  const {data}=props;
    const location = useLocation();
    const navigate = useNavigate();
    const classes = useStyles();
   const path =location.pathname
   const slicedPath= path.slice(1,path.length)

   const pathArray=slicedPath.split("/")
   //console.log("this redirect",pathArray)
  
  

    
      
       

  return (
    <Stack spacing={2} className={`${classes.Breadcrumbs} breadcrums`}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="medium" />}
        aria-label="breadcrumb"
      >
        {data && data.map((item : any,index:number)=>{
      return <Link key={index} to={item.path} style={{textTransform: "capitalize"}}>
      {item.title}
    </Link>
    })}
      </Breadcrumbs>
    </Stack>
  );
}

