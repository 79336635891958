import intl from 'react-intl-universal';
import axios from 'axios';


export const DEFAULT_LANG = 'en';
export const ALL_LOCALES: string[] = [
    'en',
    'ar',
]


export const loadLocales=()=> {
    let currentLocale = intl.determineLocale({
      urlLocaleKey: 'lang',
      cookieLocaleKey: 'lang'
    });

    // if (!_.find(ALL_LOCALES, { value: currentLocale })) {
    //   currentLocale = 'zh-CN';
    // }

    return axios
      .get(`locales/${currentLocale}.json`)
      .then(res => {
        return intl.init({
          currentLocale,
          locales: {
            [currentLocale]: res.data
          }
        });
      })
      .then(() => {
          return true;
      });
  }