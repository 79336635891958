import { ConstructionOutlined } from "@mui/icons-material";
import axios from "axios";
import configs from "../../configs/config";

export const getTimeLogs = async (id: Number, pageCount: Number) => {
  try {
    let tickets = await axios.get(
      `${configs.BASE_URL_Ticket}/Ticket/timeline/${id}?page=${pageCount}&count=10`
    );
    console.log("api is called", tickets.data);
    return tickets.data;
  } catch (e) {
    console.log(e);
  }
};

export const UpdateTimeLineLogs = async (id: number, value: boolean) => {
  try {
    let res: any = await axios.get(
      `${configs.BASE_URL_Ticket}/Ticket/update/isprivate?TicketLogId=${id}&IsNotePrivate=${value}`
    );
    console.log("api is called", res);
    if (res.status==200) {
        return true;
    }
    else{
        return false;
    }
       
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getNotifications = async (pageCount: Number) => {
  try {
    let tickets = await axios.get(
      `${configs.BASE_URL_Ticket}/TicketNotification?page=${pageCount}&count=10`
    );
    console.log("getNotifications API is called", tickets.data);
    return tickets.data;
  } catch (e) {
    console.log(e);
  }
};
export const handleReadOrUnread = async (isReadorNot: any,ticketId: any) => {
  try {
    let tickets = await axios.put(
      `${configs.BASE_URL_Ticket}/TicketNotification/${ticketId}?status=${isReadorNot}`
    );
    return tickets.data;
  } catch (e) {
    console.log(e);
  }
};