import { addTicketType, deleteTicketType, getTicketTypes, updateTicketType } from "../../../services/systemManagement/ticketTypes";
import { ActionType } from "../Snackbars";


export enum TicketTypes {
    ADD_TICKET_TYPE = 'ADD_TICKET_TYPE'
}
export interface ITicketType {
    id: number,
    name: string,
    displayName: string,
    isActive?:boolean
}

export interface ITicketTypeData {
    name: string,
    displayName: string
}

export interface ITicketTypes {
    ticketTypes: Array<ITicketType>
}

export interface IActionTicketTypes {
    type: TicketTypes.ADD_TICKET_TYPE,
    payload: ITicketType,
}

export const getTicketTypesAction = () => {
    return async (dispatch: any) => {
        let ticketTypes = await getTicketTypes();
        dispatch({
            type: TicketTypes.ADD_TICKET_TYPE,
            payload: ticketTypes
        })
    };
};

export const addTicketTypes = (ticketType: ITicketTypeData) => {
    return async (dispatch: any) => {
        await addTicketType(ticketType);
        let ticketTypes = await getTicketTypes();
        dispatch({
            type: TicketTypes.ADD_TICKET_TYPE,
            payload: ticketTypes
        })
        dispatch({
            type: ActionType.SNACKBAR,
            payload: {
                message: "Ticket Type Added Successfully",
                severity: "success",
                open: true
            }
        })
    };
};

export const updateTicketTypes = (ticketType: ITicketType) => {
    return async (dispatch: any) => {
        await updateTicketType(ticketType);
        let ticketTypes = await getTicketTypes();
        dispatch({
            type: TicketTypes.ADD_TICKET_TYPE,
            payload: ticketTypes
        })
        dispatch({
            type: ActionType.SNACKBAR,
            payload: {
                message: "Ticket type Updated Successfully",
                severity: "success",
                open: true
            }
        })
    };
};

export const deleteTicketTypes = (id: number | string) => {
    return async (dispatch: any) => {
        await deleteTicketType(id, false);
        let ticketTypes = await getTicketTypes();
        dispatch({
            type: TicketTypes.ADD_TICKET_TYPE,
            payload: ticketTypes
        })
        dispatch({
            type: ActionType.SNACKBAR,
            payload: {
                message: "Ticket type Deleted Successfully",
                severity: "success",
                open: true
            }
        })
    };
};