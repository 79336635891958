import React from 'react';
import { connect } from 'react-redux';
import AccessDenied from '../components/OtherPages/AccessDenied';
import { getEditionPermission, getPagePermission } from '../components/helpers/helperFunctions';
import { IState } from '../redux/reducers/rootReducers';
import ProtectedRoute from './ProtectedRoute';
import UpgradePlan from '../pages/admin-portal/Pricings/UpgradePlan';
const FeatureControlRoute = ({ featurePermissions, ...rest }:any) => {
  console.log("FeatureControlRoute",featurePermissions,rest)
  return (
    <React.Fragment>
      {featurePermissions?.[rest?.featureName] ?
        <ProtectedRoute path={rest?.path} component={<>{rest.component}</>} /> :
        <>
          <UpgradePlan currentPlanId={1}/>
        </>}
    </React.Fragment>
  );
};

const mapStateToProps = (state:IState) => {
  return ({
    featurePermissions: state?.FeatureReducer?.featurePermissions,
  })
};

export default connect(mapStateToProps)(FeatureControlRoute);