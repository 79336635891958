import { Box } from '@mui/material'
import BottomDrawer from '../MobileCommon/BottomDrawer'
import { useState } from 'react'
import { IState } from '../../../redux/reducers/rootReducers';
import { connect } from 'react-redux';
import { storeSortInfo } from '../../../redux/actions/TicketsManagement/MobileTicketActions';
import CommonRadioGroup from '../MobileCommon/RadioGroup';
import FormWrapper from './FormWrapper';
import { storeFiltersValue } from '../../../redux/actions/TicketsManagement/GetTickets';
interface ISortOptionProps {
    dispatch?:any;
    isOpened?:boolean;
    filtersVal:any;
}
const items = [
    {label : "Due Date",value : "dueDateTime"},
    {label : "Open Date",value : "OpenDateTime"},
    {label : "Next Action Date",value : "firstResponseDueDateTime"},
];
//"Ascending","Descending"
const sortTypes = [
    {label : "Ascending",value : "Asc"},
    {label : "Descending",value : "Desc"},
];
function SortOption(props:ISortOptionProps) {
    const {dispatch,isOpened=false,filtersVal} = props;
    const submitSortOption = (val:any) =>{
        console.log("submitSortOption",val)
        dispatch(storeFiltersValue(val,"TCKT_MOBILE_SORT_VALUE"))
        closeSortOption();
    }
    const closeSortOption = () =>{
        dispatch(storeSortInfo(false))
    }
    return (
        <Box>
            <BottomDrawer
                isOpened ={isOpened}
                onClose ={closeSortOption}
                title="Sort By"
            >
                <>
                    <FormWrapper
                        onClose={closeSortOption}
                        onSubmit={submitSortOption}
                    >
                        <>
                            <CommonRadioGroup
                                id = "sort-prop"
                                labelPlacement = {"start"}
                                name = "sortProp"
                                options = {items}
                                defaultValue =  {filtersVal?.sortProp || "OpenDateTime"}
                            />
                            <CommonRadioGroup
                                id = "sort-type"
                                subHeading='Order'
                                labelPlacement = {"start"}
                                name = "sortType"
                                options = {sortTypes}
                                defaultValue =  {filtersVal?.sortType || "Asc"}
                            />
                        </>
                    </FormWrapper>
                </>
            </BottomDrawer>
        </Box>
    )
}



const mapStateToProps = (state:IState) => {
    return ({
        isOpened: state?.AllTicketsReducer?.mobileSort,
        filtersVal : state?.mobileFiltersReducer,
    })
  };
  
export default connect(mapStateToProps)(SortOption);
// export default SortOption