export interface iWebFieldDropZoneProps {
  fieldBlocks?: iWebFormField[];
  callback?: (e: string) => void;
}
export interface IWebFormDropDown {
  disabled?:boolean;
  fieldsOptions:string;
  fieldConfig: iWebFormField;
}
export interface iWebFormField {
  id: number|string;
  webformId?: number;
  fieldId?: string;
  fieldType?: string;
  fieldLabel: string;
  fieldOptions?: null|string;
  fieldDescription: string;
  fieldPlaceholder: string;
  isMandatory: boolean;
  isActive?: boolean;
  imgID?: string;
  parent_id?: null|number;
}


export const returnInitArr = () =>{
  const arr:iWebFormField[] =   [
      { 
        id : Math.random() * 10000000000000000000,
        webformId : 0,
        fieldId : "",
        fieldType : "Heading",
        fieldLabel : "Enter Title...!",
        fieldOptions : "",
        fieldDescription : "Enter some description..!",
        fieldPlaceholder : "",
        isMandatory : false,
        isActive : true,
        imgID:"2",
        parent_id: null,
      },
      {
        id: Math.random() * 10000000000000000000,
        webformId: 0,
        fieldId: "",
        fieldType: "InputBox",
        fieldLabel: "Input Field",
        fieldOptions: "",
        fieldDescription: "",
        fieldPlaceholder: "Please Enter",
        isMandatory: false,
        isActive: false,
        imgID:"1",
        parent_id: null,
      },
      {
        id : Math.random() * 10000000000000000000,
        webformId : 0,
        fieldId : "",
        fieldType : "DatePicker",
        fieldLabel : "Date Field",
        fieldOptions : null,
        fieldDescription : " ",
        fieldPlaceholder : "Please Enter",
        isMandatory : true,
        isActive : true,
        imgID:"4",
        parent_id: null,
      },
      {
        id : Math.random() * 10000000000000000000,
        webformId : 0,
        fieldId : "",
        fieldType : "TextArea",
        fieldLabel : "TextArea Field",
        fieldOptions : null,
        fieldDescription : " ",
        fieldPlaceholder : "Please Enter",
        isMandatory : true,
        isActive : true,
        imgID:"3",
        parent_id: null,
      },{
        id : Math.random() * 10000000000000000000,
        webformId : 0,
        fieldId : "",
        fieldType : "DropDown",
        fieldLabel : "DropDown Field",
        fieldOptions : null,
        fieldDescription : "",
        fieldPlaceholder : "Please Enter",
        isMandatory : true,
        isActive : true,
        imgID:"3",
        parent_id: null,
      },
      {
        id : Math.random() * 10000000000000000000,
        webformId : 0,
        fieldId : "",
        fieldType : "CheckBox",
        fieldLabel : "CheckBox Field",
        fieldOptions : null,
        fieldDescription : "",
        fieldPlaceholder : "Please Enter",
        isMandatory : true,
        isActive : true,
        imgID:"3",
        parent_id: null,
      }
  ]
  return arr;
}