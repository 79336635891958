const initialState = {
    Data: [],
    showLoading: false,
    systemTpesByName : {}
  };
  
  const systemTypeReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case "getSystemType":
        console.log('getSystemType',action.payload)
        const tempObj:any = {};
        if(action?.payload?.map){
          //x[0].name 
          action?.payload.map((i:any)=>{
            tempObj[`${i.name}`] = i;
          })
        }
        return {
          ...state,
          Data: action.payload,
          systemTpesByName: tempObj
        };
      case "loading":
        return {
          ...state,
          showLoading: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default systemTypeReducer;
  