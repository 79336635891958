import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Button, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AllHistoryContent } from '../../../../pages/history/AllHistory';
import { ITicket, getCategoryByID } from '../../../../redux/actions/TicketsManagement/TicketsManagement';
import NavBar from '../../MobileUtilities/NavBar';
import TicketInformation from './TicketView/TicketInformation';
import { TicketIcon, TaskIcon, TimelineIcon, NavPlusButton, VerticalDots } from '../../../common/svgIcons/chartIcon';
import MobileTasksList from './TicketView/MobileTasksList';
import AddNewTask from '../../MobileUtilities/AddNewTask';
import { useDispatch } from 'react-redux';
import storeTicketPopoverInfo from '../../../../redux/actions/TicketsManagement/MobileTicketActions';
import TicketActions from '../../MobileUtilities/TicketActions';
import { debounce } from 'lodash';
import { getTicketsById, getTicketsByIdFunc } from '../../../../services/dashboard/dashboard.service';
import AddUpdateOption from '../../MobileUtilities/AddUpdateOption';
import MobileLoader from '../../MobileCommon/MobileLoader';

// const info: ITicket | any = { "id": 200, "source": 0, "createdDateTime": "2023-05-30T12:36:02.537", "isActive": true, "ticketLogs": { "id": 588, "ticketId": 200, "customId": null, "title": "testing  dont use this ticket", "note": "close it", "description": "testing  dont use this ticket", "categoryId": 754, "statusId": 1, "ticketType": 48, "priority": 0, "urgency": 0, "sourceCode": "System", "sla": 0, "openDateTime": "2023-05-30T12:36:02.44", "dueDateTime": "2023-05-30T12:36:02.44", "firstResponseDueDateTime": "2023-05-30T12:36:02.44", "scheduleDateTime": "2023-05-30T12:36:02.44", "createdDateTime": "2023-05-30T12:36:02.537", "createdByUserId": 169, "isActive": true, "contactId": 24, "secondaryContactId": 0, "groupId": 0, "agentId": 0, "nextActionDescription": "null", "from": null, "to": null, "circulationListTO": null, "cc": null, "circulationListCC": null, "bcc": null, "circulationListBCC": null, "subject": null, "body": null, "isQuickEmail": false, "user": null, "ticketLogAttachmentsList": [], "ticketLogAssetList": [], "ticketLogChangesList": [], "slaRemainingInTicks": -2752900226990, "slaRemaining": "-3.04:28:10.0226990", "effortInTicks": 0, "effort": "00:00:00", "downtimeInTicks": 0, "downtime": "00:00:00" }, "ticketRelationshipList": [], "ticketTagList": [], "ticketTaskList": [] };
function MobileTicketView() {
    const dispatch = useDispatch();
    const param = useParams();
    const [value, setValue] = useState('1');
    const [ticketData, setTicketData] = useState<any>(null);
    const [loader,setLoader]= useState<any>(false);
    
    const loadTicketInfo = debounce(()=>{
        if(!param?.ticketId) return;
        setLoader(true)
        getTicketsByIdFunc(param?.ticketId).then((res) => {
            setTicketData(res);
            setLoader(false)
        });
    },100)
    useEffect(()=>{
        loadNewData();
    },[param?.ticketId])
    const loadNewData = () =>{
        if(param?.ticketId){
            setTicketData(null)
            loadTicketInfo();
       } 
    }
    useEffect(()=>{
        if(ticketData?.ticketLogs?.categoryId)(
            dispatch(getCategoryByID(ticketData?.ticketLogs?.categoryId,getCategoryByIDCB))
        )
    },[ticketData?.ticketLogs?.categoryId])
    const getCategoryByIDCB = (res:any) =>{
        if(!res) return;
        const {id,name} = res;
        console.log("getCategoryByIDCB",res);
        setTicketData({...ticketData,"category":{
            id,
            value: id,
            label : name
        }})
    }
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };
    const onClickMoreOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
        dispatch(storeTicketPopoverInfo({
            ticketId : param?.ticketId ? +param?.ticketId : 0,
            anchorEl : event.currentTarget
        }))
    };
    return (<>
        <Box>
            <NavBar
                toggler={false}
                backIcon={true}
                backPath={"/"}
                rightControls={<>
                    {value === "3" && <AddNewTask callback={loadTicketInfo} />}
                    <Button onClick={onClickMoreOptions}><VerticalDots /></Button>
                </>}
            />
            {ticketData?.id && 
                <Box className="pb-100vh">
                    <Box className="ticket-banner">
                        <Typography component={"h4"}>{ticketData?.id}</Typography>
                        <Box>
                            <Box className="badge"><Typography>{ticketData?.ticketLogs?.ticketStatus?.displayName}</Typography></Box>
                            <Box className="badge"><Typography>{ticketData?.ticketLogs?.priority?.name}</Typography></Box>
                        </Box>
                    </Box>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList className="ticket-tab-links" onChange={handleChange} aria-label="Ticket Information">
                                <Tab className={`${value === "1" ? "active" : ""}`} label={<Typography className={`tckt-tab-link`} component={"span"}><TicketIcon /> <span>Info</span></Typography>} value="1" />
                                <Tab className={`${value === "2" ? "active" : ""}`} label={<Typography className={`tckt-tab-link`} component={"span"}><TimelineIcon /> <span>Timeline</span></Typography>} value="2" />
                                <Tab className={`${value === "3" ? "active" : ""}`} label={<Typography className={`tckt-tab-link`} component={"span"}><TaskIcon /> <span>Tasks</span></Typography>} value="3" />
                            </TabList>
                        </Box>

                        <TabPanel value="1">
                            <TicketInformation ticketInfo={ticketData} />
                        </TabPanel>
                        <TabPanel value="2">
                            <AllHistoryContent showBreadCrumbs={false} ticketId={param?.ticketId || 0} />
                        </TabPanel>
                        <TabPanel value="3">
                            <Box>
                                <MobileTasksList
                                    tasks={
                                        ticketData?.ticketTaskList || []
                                    }
                                    callback={loadNewData}
                                />
                            </Box>
                        </TabPanel>
                    </TabContext>
                </Box>
            }
            {loader && <><MobileLoader /></>}
        </Box>
        <TicketActions />
        <AddUpdateOption callback={loadTicketInfo} /> 
        </>
    )
}

export default MobileTicketView
