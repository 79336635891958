import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import * as React from "react";
import CommonStyles from "./../common/CommonStyles";

const useStyles = CommonStyles;

export default function CardCustom(props: any) {
  const {
    customHeader,
    cardHeaderTitle1,
    cardHeaderTitle2,
    cardBody,
    cardFooter,
    styleProp,
  } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={`${classes.ThemeCard} ${styleProp}`}>
      {(customHeader || cardHeaderTitle1 || cardHeaderTitle2) && 
      <div className="cardHeader" >
        {customHeader}
        <div className="userIcon">
          <span>
            <b>{cardHeaderTitle1}</b>
          </span>
        </div>
        <div className="userIcon">
          <span>
            <b>{cardHeaderTitle2}</b>
          </span>
        </div>
      </div>}
      {cardBody &&
      <CardContent
      className="cardContent"
        
      >
        {cardBody}
      </CardContent>
}
     {cardFooter &&  <div className="cardFooter">{cardFooter}</div>}
    </Card>
  );
}
