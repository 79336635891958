import { ApiModules, AssetsModules, contactsModules, InterfaceModules, knowledgeBaseModules, ReportsManagementModules, SurveyModules, SystemAdminModules, UserAdminModules } from '../../userManagement/Role/formSysAdminData';

const getSideBarPermission = (rolesData:any, isSuperAdmin:boolean=false) => {
    // console.log('(rolesData:any,permissionData:any)',rolesData,permissionData);
    // console.log('>>-----<<',contactsModules, SystemAdminModules, UserAdminModules, AssetsAccess)
    const accessData:any = {
        sysAdminAccess:false,
        usrAdminAccess:false,
        assetAdminAccess:false,
        contactAdminAccess:false,
        interfacesAdminAccess:false,
        knowledgeBaseAccess:false,
        ApisAdminAccess:false,
        TicketDashboardAccess:false,
        isSuperAdmin:isSuperAdmin,
        "isLoaded":true
    };
    
    
    rolesData.forEach((i:any)=>{
        if(!i.isSystemAdmin) return;
        if(!accessData.sysAdminAccess && SystemAdminModules.includes(i.adminTypes.name)) {
            accessData.sysAdminAccess = true;
        }else if(!accessData.usrAdminAccess && UserAdminModules.includes(i.adminTypes.name)) {
            accessData.usrAdminAccess = true;
        }else if(!accessData.contactAdminAccess && contactsModules.includes(i.adminTypes.name)) {
            accessData.contactAdminAccess = true;
        }else if(!accessData.assetAdminAccess && AssetsModules.includes(i.adminTypes.name)) {
            accessData.assetAdminAccess = true;
        }else if(!accessData.interfacesAdminAccess && InterfaceModules.includes(i.adminTypes.name)) {
            accessData.interfacesAdminAccess = true;
        }else if(!accessData.ApisAdminAccess && ApiModules.includes(i.adminTypes.name)) {
            accessData.ApisAdminAccess = true;
        }else if(!accessData.TicketDashboardAccess && i.adminTypes.name === 'TicketDashboard') {
            accessData.TicketDashboardAccess = true;
        }else if(!accessData.knowledgeBaseAccess && knowledgeBaseModules.includes(i.adminTypes.name)) {
            accessData.knowledgeBaseAccess = true;
        }else if(!accessData.ReportsManagementAccess && ReportsManagementModules.includes(i.adminTypes.name)) {
            accessData.ReportsManagementAccess = true;
        }else if(!accessData.SurveyAccess && SurveyModules.includes(i.adminTypes.name)) {
            accessData.SurveyAccess = true;
        }
        try{
            accessData[i.adminTypes.name] = accessData[i.adminTypes.name] ? accessData[i.adminTypes.name] : {};
            accessData[i.adminTypes.name].AdminAccess = i.isSystemAdmin;
            accessData[i.adminTypes.name][i.permissions?.name] = true;
        }catch(e){
            console.log('Edit data structure',e)
        }
    })
    console.log('Sidebar-accessData',accessData)
    return accessData;
}

export const getAgentPermission = (rolesData:any, isSuperAdmin:boolean=false) => {
    const accessData:any = {
        ticketTypeAccess:{
            isSuperAdmin:isSuperAdmin,
        }
    };
    rolesData.forEach((i:any)=>{
        if(!i.isAgentAdmin) return;
        try{
            if(i?.adminTypes?.name === "TicketTypes"){
                //console.log("getAgentPermission123456",i?.permissions?.name)
                if(!accessData.ticketTypeAccess?.[`${i.adminChildIdLeavel1}`]){
                    accessData.ticketTypeAccess[`${i.adminChildIdLeavel1}`] = {};
                }
                accessData.ticketTypeAccess[`${i.adminChildIdLeavel1}`][`${i?.permissions?.name}`] = true;
            }
        }catch(e){
            console.log('Edit data structure',e)
        }
    })
    console.log('Sidebar-accessData',accessData)
    return accessData;
}

export default getSideBarPermission;