import { Autocomplete, Box, Button, Grid, Skeleton, TextareaAutosize, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import InputBox from "../common2/InputBox";
import Dropdown from "../common2/Dropdown";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IState } from "../../redux/reducers/rootReducers";
import CommonStyles from "../common/CommonStyles";
import CancelIcon from "@mui/icons-material/Cancel";
import { Formik } from 'formik';
import * as Yup from 'yup';
import Avatar from "@mui/material/Avatar";
import AddCategory from "../../pages/new-ticket/AddCategory";
import CustomInput from "../../components/common/textField/CustomInput";
import AutoCompleteInputBox from "../common2/autoComplete";
import SimpleAccordion from "../common/accordion/SimpleAccordion";
import { getCategory } from "../../redux/actions/SystemManagement/AddCategory";
import { singleCategory } from "../../redux/actions/SystemManagement/AddCategory";
import { fileUploadService,bulkFileUploadService } from "../../services/fileUpload/FileUpload";
import { addNewTicket } from "../../services/dashboard/dashboard.service";
import { ActionType } from "../../redux/actions/Snackbars";
import { getTicketSources, getTicketStatus } from "../../redux/actions/SystemManagement/TicketStatus";
import CustomFormLabel from "../common/form-label/CustomFormLabel ";
import CustomSelect from "../common/customSelect/CustomSelect";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import configs from "../../configs/config";
import { getContactAid, getContactAidById, triggerAppAlert } from "../../redux/actions/SelfServicePortal/ContactAid/ContactAid";
import { iFormData } from "../../pages/new-ticket/LatestTicket/Type";
import { WebFormTextArea } from "../webForms/FieldTypes/TextArea";
import { Console } from "console";
import SelectWebformField from "../webForms/SelectWebformField";
import { iWebFormField } from "../webForms/schema";
import { WebFormInputBox } from "../webForms/FieldTypes/InputBox";
import LanguageData from "../../configs/LanguageData.json"
const summaryconfigs:iWebFormField = {
  fieldPlaceholder : "Please enter your issue summary", 
  fieldDescription:"", 
  fieldLabel: "Summary", 
  isMandatory : true, 
  id : "webFormDescription"
}
const titleconfigs:iWebFormField = {
  fieldPlaceholder : "Please enter ", 
  fieldDescription:"", 
  fieldLabel: "Title", 
  isMandatory : true, 
  id : "webFormTitle"

}
interface AutocompleteOption {
  label: string;
  id?:number
}
const addTicketAsContactUrl = "user/Ticket";
const getStatusByContactUrl = "user/TicketStatus";
const getValidationSchema:any = () => {
    const schemaObject: any = {};
    schemaObject['title'] = Yup.string().test('title',
      function (value = "") {
        if (value.trim() === '') {
          return this.createError({ path: this.path, message: "Please Enter Title" });
        } else { return true; }
      }
    );
    schemaObject['description'] = Yup.string().test('title',
      function (value = "") {
        if (value.trim() === '') {
          return this.createError({ path: this.path, message: "Please Enter Description" });
        } else { return true; }
      }
    );
    schemaObject['category'] = Yup.object()
        .typeError("Please select the Category")
        .required("Please select the Category");
    // schemaObject['description'] = Yup.string().test('title',
    //   function (value = "") {
    //     if (value.trim() === '') {
    //       return this.createError({ path: this.path, message: "Please Enter Name" });
    //     } else { return true; }
    //   }
    // );
    return Yup.object().shape(schemaObject);
  };
export const NewTicketSSP = () =>{
    const dispatch = useDispatch();
    const [newTicketId,setNewTicketId] = useState(0);
    const [category,setCategory] = useState([]);
    const navigate = useNavigate();
    const TicketSources = useSelector(
      (state: IState) => state?.ticketStatusReducer?.TicketSources
    );
    const currContact = useSelector(
      (state: IState) => state?.ContactsReducer?.loggedContact
    );
    const getTicketStatusCB = (res:any[]) =>{
      console.log("getTicketStatusCB",res);
      if(res[0] === "0"){
        let newStatusId = 0;
        res[1].map((i:any)=>{
            if(newStatusId === 0 && i.name === "New"){
              newStatusId = i.id
            }
        });
        console.log("newStatusId",newStatusId)
        setNewTicketId(newStatusId)
      }
    }
    const getVal = (item:any) =>{
      //return item.name;
        return {
            label : item.name,
            id : item.id,
            value : item.id
        }
    }
    const getCategoryCB = (resStatus:string, res:any) =>{
      console.log("getCategoryCB",res)
      if(resStatus === "0"){
        res.map((i:any)=>{
            if(i?.categoryTicketTypeList?.[0]?.ticketType?.name === "Incident"){
                  let records:any =[];
                  i.subCategoryList.map((i:any)=>{
                    records.push(getVal(i))
                      i.itemList.map((j:any)=>{
                        records.push(getVal(j))
                          j.childrenItemList.map((k:any)=>{
                            records.push(getVal(k))
                          })
                      })
                  });
                  setCategory(records)
            }
        })
      }
    }
    useEffect(()=>{
      dispatch(getTicketStatus(getTicketStatusCB,getStatusByContactUrl))
      dispatch(getCategory(getCategoryCB,"user/Category"))
      if (!TicketSources || TicketSources.length === 0) {
        dispatch(getTicketSources(null, "user/Source"))
      }
    },[])
    const [data, setData] = useState(
      {
        title:"",
        description:"",
        categoryId:null,
        ticketTypeId:48,
        contactId:0,
        statusId:1,
        ticketLogAttachmentsList:[]
      }
    );
    const ticketCreation = async (payload: any) => {
      console.log("payload", payload);
      let newTicket: any = await addNewTicket(payload,addTicketAsContactUrl);
      return newTicket;
    };

    return(
        <>
        <Formik
          initialValues={{...data}}
          validationSchema={getValidationSchema()}
          onSubmit={(values:any, { resetForm }) => {
            const sspSource = TicketSources.filter((x: any) => x?.name === "SSP")?.[0]?.id;
            console.log('Success! Data submitted.', data);
            let request:any = {
               "sourceCode":"Ssp",
               "ticketLogs":{...values,"statusId":newTicketId,"categoryId":values.category.id,"sourceCode":"Ssp",
              "circulationListTO": currContact.email
              }
            }
            request.ticketLogs.ticketLogAttachmentsList = request.ticketLogs.ticketLogAttachmentsList.map((i:any)=>{
              let tempObj = {attachmentId : i.id}
              return tempObj
            })
            ticketCreation(request).then((res: any) => {
              console.log("ticketCreation res",res);
              if (res.status == 201) {
                dispatch({
                  type: ActionType.SNACKBAR,
                  payload: {
                    message:  `${LanguageData.SUCCESS} ${res?.data?.id} ${LanguageData.TICKET_CREATED}`,
                    severity: "success",
                    open: true,
                  },
                });
                setTimeout(()=>{
                  navigate('/');
                  resetForm();
                },1000)
              }
            })
          }}
        >
          {(props) => <AddForm 
                        backHandler = {()=>{
                            navigate('/')
                        }}
                        {...props} 
                        categoryList={category}
                      />}
        </Formik>
        </>
    )
}

export const AddForm = (props: any) => {
  const { errors, touched, backHandler, submitForm, values, setFieldValue, categoryList } = props;
  const currContact = useSelector((state: IState) => state?.ContactsReducer?.loggedContact);
  const AllContactAid = useSelector((state: IState) => state?.ContactsReducer?.AllContactAid);
  const ContactAidById = useSelector((state: IState) => state?.ContactsReducer?.ContactAidById);
  const loggedInUser = useSelector((state: IState) => state?.ContactsReducer?.loggedContact);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const param: any = useParams();
  const [categories, setCategories] = useState<any>([]);
  const [showLoading, setShowLoading] = useState<any>(false);
  const [activeIndex, setActiveIndex] = useState<any>([])
  const [firstDefault, setFirstDefault] = useState<any>({})
  const [showWebform, setShowWebform] = useState<any>({})
  const [errValues, setErrValues] = useState<any>({});
  const [showForm, setShowForm] = useState<boolean>(true);
  const [byIdVal, setByIdVal] = useState<any>({});
  const roleData = useSelector((state: IState) => state.roleReducer?.Data);
  const classes = CommonStyles();
  const pageStyles = useStyles();
  useEffect(() => {
    if (currContact?.id) {
      setFieldValue("contactId", currContact?.id);
    }
  }, [currContact]);
  useEffect(() => {
    console.log('props123', props)
  }, [props]);
  const fileUploadCallback = (resStatus: string, res: any) => {
    setShowLoading(false)
    console.log('bulkUploadCB', resStatus, res)
    if (resStatus === "0") {
      const docs = res.map((i: any) => {
        return i.data
      });
      setFieldValue("ticketLogAttachmentsList", [...values.ticketLogAttachmentsList, ...docs]);
    }
  };
  const imgCallBack = async (file: any) => {
    setShowLoading(true)
    const filesList: any = [];
    for (const item in file) {
      if (item !== "length" && item !== "item") {
        let fileObject = {
          description: file[`${item}`].name,
          filetypeid: 1,
          formFile: file[`${item}`],
        };
        filesList.push(fileObject)
      }
    }
    dispatch(bulkFileUploadService(
      filesList,
      fileUploadCallback
    ));
  };
  const onChangeHandler = (e: any, fieldName: string) => {
    setFieldValue(fieldName, e)

  }
  const attachmentDeleteHandlrer = useCallback((id: number) => {
    setFieldValue("ticketLogAttachmentsList", values.ticketLogAttachmentsList.filter((i: any) => i.id !== id));
  }, [])


  useEffect(() => {
    dispatch(getContactAid(getContactAidCB, "user/ContactAid"))
  }, []);
  const getContactAidCB = (resStatus: string, arr: any) => {
    // const id:any = new URLSearchParams(searchPath).get('id');
    if (resStatus === "0") {
      setActiveIndex(arr)
    }
  }
  useEffect(() => {
    if (AllContactAid.length !== 0) {
      let temp = AllContactAid.find((ele: any) => ele.isDefault)
      setFirstDefault(temp)
    }
  }, [AllContactAid,ContactAidById])
  useEffect(() => {
    if (firstDefault && firstDefault.id) {
      console.log("get_by_id", firstDefault.id)
      dispatch(getContactAidById(firstDefault.id, getContactAidByIdCB, "user/ContactAid/details"))
      setByIdVal(firstDefault.id || {})
    }
    setErrValues({})
  }, [firstDefault]);
  const getContactAidByIdCB =(resStatus: string, arr: any)=>{
    if(resStatus==="0"){
      setShowWebform(arr)
      console.log("ContactAidByIdbyIdValContactAidByIdbyIdVal",arr);

    }
    
  }
  console.log("ContactAidByIdbyIdVal",ContactAidById,errValues,firstDefault);

  const validateWebForm = (enteredVal: any = {}, fields = []) => {
    let errObj: any = {
      hasError: false,
    };
    if (enteredVal["webFormDescription"].trim().length === 0) {
      errObj["webFormDescription"] = "Please enter this field";
      errObj.hasError = true;
    }
    if (enteredVal["webFormTitle"].trim().length === 0) {
      errObj["webFormTitle"] = "Please enter this field";
      errObj.hasError = true;
    }
    fields.map((i: any) => {
      // errObj[i.fieldId] = 
      if (!i.isMandatory || !enteredVal[i.fieldId]?.trim) return;
      if (enteredVal[i.fieldId].trim().length === 0) {
        errObj[i.fieldId] = "Please enter this field";
        errObj.hasError = true;
      }
    });
    console.log("validateWebForm", enteredVal, fields, errObj)
    return errObj;
  }
  const ticketCreation = async (payload: any) => {
    console.log("payload", payload);
    let newTicket: any = await addNewTicket(payload,addTicketAsContactUrl);
    return newTicket;
  };
  const onFormSubmit = (event: any) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const data: iFormData = {};
    formData.forEach((value, key) => {
      data[key] = value.toString();
    });
    
    const validationVal: any = validateWebForm(data, ContactAidById?.[showWebform.id]?.webform?.webformFieldList);
    setErrValues(validationVal)
    if(validationVal.hasError) return;
    const currentAid = firstDefault;
    let request:any = {
        "sourceCode":"SSP",
        "ticketLogs":{
            "statusId": 2,
            "categoryId": currentAid?.categoryId || 0,
            "agentId": currentAid?.agentId || 0,
            "groupId" : currentAid?.groupId || 0,
            "ticketTypeId" : currentAid?.ticketTypeId || 0,
            "description" : data.webFormDescription || "",
            "title": `${data.webFormTitle || ""}`.substring(0, 100),
            "sourceCode":"SSP",
            "contactId": loggedInUser?.id || 0,
            "webformvalues": `${JSON.stringify(data)}`,
            "webformId": byIdVal?.webform?.id || 0,
        }
     }

     ticketCreation(request).then((res: any) => {
        if (res.status == 200 || res.status == 201) {
            console.log("ticketCreation_res",res)
            dispatch(triggerAppAlert(`${LanguageData.SUCCESS} ${res?.data?.id} ${LanguageData.TICKET_CREATED}`,"success"))
            setTimeout(()=>{
                navigate('/');
                resetForm();
            },1000)
        }
      })
  }
  const resetForm = () => {
    setShowForm(false);
    setTimeout(() => {
      setShowForm(true);
    }, 500)
  }
  const webFormFields = useMemo(() => <>
    {showWebform?.webform?.webformFieldList?.map &&
      showWebform?.webform?.webformFieldList?.map((i: any) => {
        console.log('errValues__errValues', errValues)
        const { fieldLabel, fieldType, fieldPlaceholder, fieldId } = i;
        i = {
          ...i,
          id: fieldId,
          content: fieldType,
          placeholder: fieldPlaceholder,
          label: fieldLabel,
          type: "text",
        }
        return (
          <>
            <SelectWebformField  block={i}  />
            {errValues?.[fieldId] && <Typography variant="caption" className="txt-danger">{errValues?.[fieldId]}</Typography>}
          </>
        )
      })
    }
  </>, [showWebform?.webform?.webformFieldList?.map, errValues])
    return (
      <>   
        <Box className="p-2 view-service">
          <Box className="mb2 pb2">
            <Box className="pt-1">
                <Typography variant="body2" className={pageStyles.Title} gutterBottom>Create Request</Typography>
            </Box>
          </Box>
            <Grid container spacing={1}>
                <Grid item md={8} xs={12}>
                    <Grid container spacing={1}>
                        {/* <Grid item md={6} xs={12}>
                        <CustomFormLabel
                            classNames="ssp-create-request-label"
                            labelName={"Title"}
                            isMandotary={true}
                        />
                        <CustomInput
                            isDisabled={false}
                            readOnly={false}
                            onClick={null}
                            iconPosition={"start"}
                            icon={false}
                            isError={touched.title && Boolean(errors.title)}
                            label={"Please Enter"}
                            name="title"
                            value={values.title}
                            onChange={(e: any) => { onChangeHandler(e.target.value, "title") }}
                            helperText={touched.title && errors.title}
                            placeholder={"Please Enter"}
                            classNames="ssp-create-request"
                        />
                        </Grid> */}
                        {/* <Grid item md={6} xs={12}>    
                          <CustomFormLabel
                            classNames="ssp-create-request-label"
                              labelName={"Category"}
                              isMandotary={true}
                          />
                          <CustomSelect
                              customClassNames="ssp-newticket-category"
                              isMulti={false}
                              isOpen={false}
                              placeholder="Please Select"
                              width={"100%"}
                              name="Category"
                              isColor={false}
                              isDisabled={false}
                              isError={
                                  touched.category &&
                                  Boolean(errors.category)
                              }
                              defaultValue={values.category}
                              options={categoryList}
                              helperText={touched.category && errors.category}
                              handleChange={(e: any) => { onChangeHandler(e, "category") }}
                          />
                        </Grid> */}
                        {/* <Grid item md={12} xs={12}> 
                            <Box>
                                <CustomFormLabel
                                    classNames="ssp-create-request-label"
                                    labelName={"Description"}
                                    isMandotary={true}
                                />
                                <CustomInput
                                  isDisabled={false}
                                  readOnly={false}
                                  onClick={null}
                                  iconPosition={"start"}
                                  icon={false}
                                  isError={
                                      touched.description &&
                                      Boolean(errors.description)
                                  }
                                  label={"Description"}
                                  name="desc"
                                  value={values.description}
                                  onChange={(e:any)=>{onChangeHandler(e.target.value,"description")}}
                                  helperText={
                                      touched.description &&
                                      errors.description
                                  }
                                  placeholder={"Please Enter"}
                                  classNames={'bigTextarea'}
                                />
                            </Box>
                        </Grid> */}
                        
                        <Grid item md={12} xs={12}>
                          <form onSubmit={onFormSubmit} >
                            <WebFormInputBox fieldConfig={titleconfigs} />
                            {errValues?.["webFormTitle"] && <Typography variant="caption" className="txt-danger">{errValues["webFormTitle"]}</Typography>}
                            <WebFormTextArea fieldConfig={summaryconfigs} />
                            {errValues?.["webFormDescription"] && <Typography variant="caption" className="txt-danger">{errValues["webFormDescription"]}</Typography>}
                            {showWebform && showWebform?.webform && showForm && webFormFields}
                            
                            <Grid item md={12} xs={12} className="spp-newtickets-attachment">
                              <input
                                id="AssetFileUpload"
                                accept=".jpeg, .img, .png, .pdf"
                                type="file"
                                multiple={true}
                                onChange={(e: any) => {
                                  imgCallBack(e.target.files)
                                }}
                                className="d-none"
                              />
                              <label
                                htmlFor="AssetFileUpload"
                                className="Add-Attachment"
                                style={{
                                  fontSize: "16px",
                                  cursor: "pointer",
                                  float: "left",
                                  color: "#0A93FC",
                                  marginTop: "30px",
                                }}
                              >
                                + Add attachment
                              </label>
                            </Grid>
                            <Grid item md={12} xs={12} style={{ marginTop: "50px" }}>
                              <Box className="pt-1 text-center">
                                <Button type="submit" variant="contained">
                                  Create Ticket
                                </Button>
                              </Box>
                            </Grid>
                          </form>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4} xs={12} className="ssp-addnew-attachment  ">
                  <Box style={{marginTop:"20px"}}>
                    <SimpleAccordion
                      isExpand={true}
                      name="panel1"
                      header={
                        <>
                          <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                            {values.ticketLogAttachmentsList.length > 0 && (
                              <Avatar
                                sx={{
                                  width: 24,
                                  height: 24,
                                  fontSize: "12px",
                                  backgroundColor: "#2F80ED",
                                }}
                              >
                                {values.ticketLogAttachmentsList.length}
                              </Avatar>
                            )}
                            <div>Attachments</div>
                          </div>
                        </>
                      }
                      body={
                        <>
                          {values.ticketLogAttachmentsList.length > 0 ? <>
                            <Box className={`${classes.row} ${classes.rowWrap} `}>
                              {values.ticketLogAttachmentsList.map((i: any, index: number) => {
                                return (
                                  <>
                                    {/* <img src={`${i.filePath}`}  height={50}/> */}
                                    <Box className="col-4 p-3p" key={index}>
                                      <AttachmentItem fileData={i} callback={attachmentDeleteHandlrer} />
                                    </Box>
                                  </>
                                )
                              })}
                            </Box>
                          </> :
                            <>
                              {!showLoading &&
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <AttachmentIcon
                                    sx={{ width: "50px", height: "50px", color: "#2F80ED" }}
                                  />
                                  <div>Selected Attachments will appear here</div>
                                </div>
                              }
                            </>
                          }

                          {showLoading &&
                            <div style={{ width: "100%", height: "200px", padding: "25px" }} >
                              <Skeleton
                                variant="rounded"
                                width={"100px"}
                                height={"100px"}
                                sx={{ margin: "auto" }}
                                animation="wave"
                              />
                            </div>
                          }
                        </>
                      }
                    />

                  </Box>
                </Grid>
            </Grid>
        </Box>
      </>
    )
}
export const AttachmentItem = (props:any) => {
  const {fileData,callback} = props;
  const classes = useStyles();
  return(
        <Box className={`${classes.attachmentBox}`}>
          <img src={`${fileData?.filePath}`}  height={50}/>
          <Typography variant="body2">{fileData?.name}</Typography>
          <Box>
            <DeleteOutlinedIcon className="cursor-pointer" onClick={()=>{callback(fileData.id)}} />
          </Box>
        </Box>
  )
}
export const AttachmentItemForBlob = (props:any) => {
  const {fileData,callback} = props;
  const classes = useStyles();
  return(
        <Box className={`${classes.attachmentBox}`}>
          <img src={`${fileData?.filePath}`}  height={50}/>
          <Typography variant="body2">{fileData?.name}</Typography>
          <Box>
            <DeleteOutlinedIcon className="cursor-pointer" onClick={()=>{callback(fileData.id)}} />
          </Box>
        </Box>
  )
}
const useStyles = makeStyles(() =>
  createStyles({
    Title:{
        color: "#071C69",
        fontWeight: 600,
        marginBottom: "30px",
        fontSize: "1.6rem"
    },
    attachmentBox:{
      border:"1px solid #ddd",
      display: "flex",
      gap:5,
      padding:10,
      alignItems: "center",
      "& img" : {
        height: "30px"
      },
      "& p":{
        fontSize: 10,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 70,
      },
      "& svg": {
        height:'20px !important',
        width:'20px !important',
        color:"#ff0000"
      } 
    },
    gap5:{
      gap:5
    }
}));
export default NewTicketSSP;