import React, { useState, useCallback, useEffect } from "react";
import BasicButton from "../../components/common/button/BasicButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CustomInput from "../../components/common/textField/CustomInput";
import { DeleteIcon } from "../../components/common/svgIcons/chartIcon";
import DoneIcon from "@mui/icons-material/Done";
import submitContactUs, { getContactById, updateContactUs } from "./ContactUsAPI";
import {
  Container,
  Box,
  Typography,
  IconButton,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import CustomFormLabel from "../../components/common/form-label/CustomFormLabel ";
import { ActionType } from "../../redux/actions/dashboard";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const updateContactUsAPI = async (id: any, data: any)  => {
  let res = await  updateContactUs(id, data);
  return res;
};
const submitContactUsAPI = async (data: any) => {
  let res = await submitContactUs( data);
  return res;
};

const getContactByIdApi = async () => {
  let res = await getContactById();
  return res;
};

const ContactUs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [emailRows, setEmailRows] = useState([
    { id: Date.now(), name: "", email: "", isDefault: false },
  ]);

  const [phoneRows, setPhoneRows] = useState([
    { id: Date.now(), name: "", phone: "" },
  ]);

  const [whatsappNo, setWhatsappNo] = useState("");
  const [teamUrl, setTeamUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getContactByIdApi().then((res: any) => {
      console.log("getContactByIdApi", res);
      if (res) {
        setPhoneRows(JSON.parse(res?.mobilePhone));
        setEmailRows(JSON.parse(res?.email));
        setWhatsappNo(res?.whatsAppNumber);
        setTeamUrl(res?.teamsId);
        setIsEdit(res?.id);
      } else {
        setIsEdit(false);
      }
    });
  }, []);

  useEffect(() => {
    if (emailRows.length > 0 && !emailRows.some((row) => row.isDefault)) {
      setEmailRows((prevRows) =>
        prevRows.map((row, index) => ({
          ...row,
          isDefault: index === 0, // Set the first email as default if none are selected
        }))
      );
    }
  }, [emailRows]);

  const handleAddEmailRow = useCallback(() => {
    setEmailRows((prevRows) => [
      ...prevRows,
      { id: Date.now(), name: "", email: "", isDefault: false },
    ]);
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    let payload = {
      id: 0,
      teamsId: teamUrl,
      whatsAppNumber: whatsappNo,
      mobilePhone: JSON.stringify(phoneRows),
      Email: JSON.stringify(emailRows),
      IsActive: true,
    };
    if (isEdit) {
      updateContactUsAPI(isEdit, payload).then((res) => {
        setLoading(false);
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: `Success! Contact Us updated`,
            severity: "success",
            open: true,
          },
        });
        navigate("/adminPanel");
      });
    } else {
      submitContactUsAPI(payload).then((res: any) => {
        setLoading(false);
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: `Success! Contact Us created`,
            severity: "success",
            open: true,
          },
        });
        navigate("/adminPanel");
      });
    }
  };

  const handleAddPhoneRow = useCallback(() => {
    setPhoneRows((prevRows) => [
      ...prevRows,
      { id: Date.now(), name: "", phone: "" },
    ]);
  }, []);

  const handleDeleteEmailRow = useCallback((id) => {
    setEmailRows((prevRows) => prevRows.filter((row) => row.id !== id));
  }, []);

  const handleDeletePhoneRow = useCallback((id) => {
    setPhoneRows((prevRows) => prevRows.filter((row) => row.id !== id));
  }, []);

  const handleChange = useCallback((id, field, value, setRows, rows) => {
    setRows(
      rows.map((row: any) => (row.id === id ? { ...row, [field]: value } : row))
    );
  }, []);

  const handleDefaultChange = useCallback((event) => {
    const defaultId = parseInt(event.target.value, 10);
    setEmailRows((prevRows) =>
      prevRows.map((row) => ({
        ...row,
        isDefault: row.id === defaultId,
      }))
    );
  }, []);

  return (
    <Container>
      <Box sx={{ padding: 4, backgroundColor: "transparent", borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
          Contact Us
        </Typography>

        <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
          <Typography variant="h6" gutterBottom>
            Whatsapp
          </Typography>
          <CustomFormLabel labelName="Whatsapp No" isMandotary={false} />
          <CustomInput
            onChange={(e: any) => setWhatsappNo(e.target.value)}
            classNames="small-button"
            type="text"
            value={whatsappNo}
            label="Enter Whatsapp No"
            placeholder="1234567890"
          />
        </Paper>

        <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
          <Typography variant="h6" gutterBottom>
            Team
          </Typography>
          <CustomFormLabel labelName="URL" isMandotary={false} />
          <CustomInput
            onChange={(e:any) => setTeamUrl(e.target.value)}
            classNames="small-button"
            type="text"
            value={teamUrl}
            label="Enter Team URL"
            placeholder="https://example.com/team"
          />
        </Paper>

        {/* Email Section */}
        <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
          <Typography variant="h6" gutterBottom>
            Email Contacts
          </Typography>
          <RadioGroup
            value={emailRows.find((row) => row.isDefault)?.id || ""}
            onChange={handleDefaultChange}
          >
            {emailRows.map((row, index) => (
              <Box
                key={row.id}
                sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <CustomFormLabel labelName="Name" isMandotary={false} />
                    <CustomInput
                      classNames="small-button"
                      type="text"
                      value={row.name}
                      onChange={(e:any) =>
                        handleChange(
                          row.id,
                          "name",
                          e.target.value,
                          setEmailRows,
                          emailRows
                        )
                      }
                      label="Enter Name"
                      placeholder="John Doe"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomFormLabel labelName="Email" isMandotary={false} />
                    <CustomInput
                      classNames="small-button"
                      type="email"
                      value={row.email}
                      onChange={(e: any) =>
                        handleChange(
                          row.id,
                          "email",
                          e.target.value,
                          setEmailRows,
                          emailRows
                        )
                      }
                      label="Enter Email"
                      placeholder="john.doe@example.com"
                    />
                  </Grid>
                </Grid>
                <FormControlLabel
                  control={<Radio value={row.id} checked={row.isDefault} />}
                  label="Default"
                  sx={{ marginLeft: 2 }}
                />
                <IconButton
                  onClick={() => handleDeleteEmailRow(row.id)}
                  sx={{ marginLeft: 2 }}
                >
                  <DeleteIcon />
                </IconButton>
                {emailRows.length - 1 === index && (
                  <IconButton
                    onClick={handleAddEmailRow}
                    sx={{ marginLeft: 2 }}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </Box>
            ))}
          </RadioGroup>
        </Paper>

        {/* Phone Section */}
        <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
          <Typography variant="h6" gutterBottom>
            Phone Contacts
          </Typography>
          {phoneRows.map((row, index) => (
            <Box
              key={row.id}
              sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <CustomFormLabel labelName="Name" isMandotary={false} />
                  <CustomInput
                    classNames="small-button"
                    type="text"
                    value={row.name}
                    onChange={(e: any) =>
                      handleChange(
                        row.id,
                        "name",
                        e.target.value,
                        setPhoneRows,
                        phoneRows
                      )
                    }
                    label="Enter Name"
                    placeholder="John Doe"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomFormLabel
                    labelName="Phone Number"
                    isMandotary={false}
                  />
                  <CustomInput
                    classNames="small-button"
                    type="tel"
                    value={row.phone}
                    onChange={(e: any) =>
                      handleChange(
                        row.id,
                        "phone",
                        e.target.value,
                        setPhoneRows,
                        phoneRows
                      )
                    }
                    label="Enter Phone Number"
                    placeholder="123-456-7890"
                  />
                </Grid>
              </Grid>
              <IconButton
                onClick={() => handleDeletePhoneRow(row.id)}
                sx={{ marginLeft: 2 }}
              >
                <DeleteIcon />
              </IconButton>
              {phoneRows.length - 1 === index && (
                <IconButton onClick={handleAddPhoneRow} sx={{ marginLeft: 2 }}>
                  <AddIcon />
                </IconButton>
              )}
            </Box>
          ))}
        </Paper>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 4,
            gap: 6,
          }}
        >
          <BasicButton
            onClick={() => {
              navigate("/adminPanel");
            }}
            type="button"
            isLoading={loading}
            isDisabled={false}
            color="primary"
            size="large"
            label="Cancel"
            variant={"outlined"}
            endIcon={<CloseIcon sx={{ width: "20px" }} />}
          />
          <BasicButton
            onClick={handleSubmit}
            type="button"
            isLoading={loading}
            isDisabled={false}
            color="primary"
            size="large"
            label={isEdit ? "Update" : "Add"}
            variant="contained"
            endIcon={<DoneIcon />}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default ContactUs;
