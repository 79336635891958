import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IState } from '../../../redux/reducers/rootReducers';
import { connect } from 'react-redux';
import AgentBased from "../../../assest/agent-based.svg";
import AgentUnlimited from "../../../assest/agent-unlimited.svg";
import priceBg from "../../../assest/pricing-bg.webp";
import { useNavigate } from 'react-router-dom';
import CustomDialog from '../../../components/common/dialog/CustomDialog';
import CallRequest from './CallRequest';
import { backgroundImage } from 'html2canvas/dist/types/css/property-descriptors/background-image';
interface IPricingPageCardsProps {
    list ?: any,
    dispatch ?: any
}
const styles = {
    paperContainer: {
        padding:"100px 0",
        background:"#c5d0f7",
        paddingBottom: "0",
        marginBottom: "50px",
        borderTopRightRadius: "50px",
        borderTopLeftRadius: "50px",
    }
  };
function PricingPageCards(props:IPricingPageCardsProps) {
    const {list=[]}= props;
    const navigate = useNavigate();
    const startTrial = (item: any) => {
        if(item.isEnterprises){
            setShowContact(true)
        }else{
            navigate(`/freeTrial/${item.id}`);
        }
    };
    const [editions,setEditions] = useState([]);
    const [isEnterprise,setIsEnterprise] = useState(false);
    useEffect(()=>{
        console.log("PricingPageCards_props",props)
        if(!list.map) return;
        setEditions(list.filter((i:any)=>{
            return i.isEnterprises === isEnterprise;
        }))
    },[props?.list,isEnterprise])
    /* === CONTACT POPUP === */
    const [showContact, setShowContact] = useState<boolean>(false);
    return (
        <>
            <Box className="type_toggler">
              <Box className={`type_toggle_holder ${isEnterprise ? "checked" : ""}`}>
                <Box className="item" onClick={()=>setIsEnterprise(false)}>
                      <img src={AgentBased} alt="agent" />
                      <Box><span>Agent-Based</span> <br/> <small>For small and medium businesses</small></Box>
                  </Box>
                  <Box className="item" onClick={()=>setIsEnterprise(true)}>
                      <img src={AgentUnlimited} alt="agent" />
                      <Box><span>Unlimited Agents</span> <br/> <small>For larger businesses</small></Box>
                  </Box>
              </Box>
            </Box>

            <Box className="pricing-page-cards">
                <Box style={styles.paperContainer}>
                    <Grid container justifyContent={"center"} spacing={2}>
                        {editions.map((i:any)=>{
                            return(
                                <Grid item md={3}>
                                    <Box className="pricingBody">
                                        <Box className="pricing-head">
                                            <Typography component="h4">{i.name}</Typography>
                                            {!i?.isEnterprises ? 
                                                <Box>
                                                    <Typography component="h2" className="price">&#163;{i?.pricePerAgent}</Typography>
                                                    <Typography > / month <br/> &#163;{Number(i?.pricePerAgent) * 12} billed yearly</Typography>
                                                </Box> :

                                                <Box>
                                                    <Typography onClick={()=>startTrial(i)} component="h2" className="price"><u>Talk to Us</u></Typography>
                                                </Box>
                                            }
                                        </Box>
                                        {/* <Typography className="pt-1">Additional agents at $15/month</Typography> */}


                                        <Box className="pt-2 pb-2">
                                            <Button onClick={()=>startTrial(i)} className='upgrade_btn w-100'>Try for free</Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
            </Box>

           <CustomDialog
                heading={`Request a call back!`}
                body={
                <>
                    <CallRequest setShowContact={setShowContact} />
                </>
                }
                open={showContact}
                handleClose={() => {
                    setShowContact(false);
                }}
            /> 
        </>
    )
}


// export default ViewPricingTable
const mapStateToProps = (state:IState) => {
    return ({
      list: state?.FeatureReducer?.viewOnlyFeatures,
    })
};
  
export default connect(mapStateToProps)(PricingPageCards);
// export default PricingPageCards
