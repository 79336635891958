import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../common2/Breadcrumbs";
import WebForms from "./WebForms";

function AddWebForm() {
    const param = useParams();
    const submitHandler = () => {}
    return (
        <>
            <Box className="p-1">
                <Breadcrumbs
                    data={[
                        {
                            title:'SystemManagement ',
                            path:'/adminPanel' 
                        },
                        {
                            title: "Web-Form",
                            path: "/webFormList",
                        },
                        {
                            title: `${param?.id ? "Edit" : "Add"} Webform`,
                            path: `${param.id ? "/webForms/" + param.id : "/webForms"}`
                        }
                    ]
                    }
                />
                <WebForms type = "Ticket" />
            </Box>
        </>
    )
}

export default AddWebForm