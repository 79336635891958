export const startRecordingAction = () => ({
  type: "START_RECORDING",
});

export const stopRecordingAction = () => ({
  type: "STOP_RECORDING",
});
const initialState = {
  status: "idle",
  mediaBlobUrl: null,
  recordingNumber: 0,
};

const RecordingReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case "RECORD_STATUS":
      return {
        ...state,
        status: action.payload,
      };
    case "MEDIA_BLOB_URL":
      return {
        ...state,
        mediaBlobUrl: action.payload,
      };
    case "RECORDING_NUMBER":
      return {
        ...state,
        recordingNumber: action.payload,
      };
    case "START_RECORDING":
      return {
        ...state,
        status: "recording",
        recordingNumber: new Date().getTime(), // Set recordingNumber on start
      };
    case "STOP_RECORDING":
      return {
        ...state,
        status: "stopped",
      };
    default:
      return state;
  }
};

export default RecordingReducer;
