import React from 'react'
import { iWebFormField } from '../schema'
import { Box } from '@mui/material'

const HeaderContent = ({fieldConfig}: {fieldConfig:iWebFormField}) => {
    const {fieldLabel,fieldDescription}=fieldConfig;
    return (
        <Box>
            <h3>{fieldLabel}</h3>
            <p>{fieldDescription}</p>
        </Box>
    )
}

export default HeaderContent