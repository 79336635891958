const initialState = {
  Data: [],
  AllData: [],
};

const ticketReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "getTicket":
      return {
        ...state,
        Data: action.payload,
      };
    case "getAllTicket":
      return {
        ...state,
        AllData: action.payload,
      };
    case "singleTicket":
      return {
        ...state,
        Data: action.payload,
      };
    case "updateTicketTypeStatus":
    case "multipalUpdateTicketStatus":
    case "softDeleteTicketType":
      return {
        ...state,
      };
    case "deleteTicketType":
      return {
        ...state,
      };
    case "loading":
      return {
        ...state,
        showLoading: action.payload,
      };

    default:
      return state;
  }
};

export default ticketReducer;
