import { memo, useEffect, useMemo, useState } from "react"
import { IState } from "../../../../redux/reducers/rootReducers";
import { connect} from "react-redux";
import RoleAccrodianItem from "./RoleAccrodianItem";
import AgentRoleAccrodianItem from "./AgentRoleAccrodianItem";
import  CategoryPart  from "./RoleCategories";
import PrioritiesBox from "./PrioritiesBox";
import HelperModuleForCategory from "./HelperModuleForCategory";
const UserAdminAccess = memo(({roleDataSet}:any)=>{
    const [systemAdminInfo,setSystemAdminInfo] = useState<any>({});
    const [userAdminInfo,setUserAdminInfo] = useState<any>({});
    useEffect(()=>{
        console.log('roleDataSetroleDataSet',roleDataSet)
        if(roleDataSet?.isloaded){
            setSystemAdminInfo(roleDataSet?.sysDataInfo)
            setUserAdminInfo(roleDataSet?.formAgentData)
        }
    },[roleDataSet?.isloaded])
   const syslayout = useMemo(()=><>
        {systemAdminInfo?.TicketPermissions && <RoleAccrodianItem info={systemAdminInfo?.TicketPermissions} keyLabel={'TicketPermissions'}/>}
   </>,[systemAdminInfo])
   const usrlayout = useMemo(()=><>
        {userAdminInfo?.Groups && <AgentRoleAccrodianItem  info={userAdminInfo?.Groups} keyLabel={'Groups'} />}
        {userAdminInfo?.TicketStatus && <AgentRoleAccrodianItem  info={userAdminInfo?.TicketStatus} keyLabel={'TicketStatus'} />}
        {userAdminInfo?.MailService && <AgentRoleAccrodianItem  info={userAdminInfo?.MailService} keyLabel={'MailService'} />}
        {userAdminInfo?.TicketTypes && <AgentRoleAccrodianItem  info={userAdminInfo?.TicketTypes} keyLabel={'TicketTypes'} />}
    </>,[userAdminInfo])

    const priorityLayout = useMemo(()=><>
            {userAdminInfo?.Priorities && <PrioritiesBox priorities={userAdminInfo?.Priorities} />}
    </>,[userAdminInfo])
    // const categoryLayout = useMemo(()=><><CategoryPart /></>,[userAdminInfo])
    return(
        <> 
            {syslayout}
            {usrlayout}
            <HelperModuleForCategory />
            {priorityLayout}
            {/* {categoryLayout} */}
            <CategoryPart /> 
        </>
    )
})


// export default UserAdminAccess;
const mapStateToProps = (state:IState) => {
    return ({
        roleDataSet : state?.roleReducer?.roleInfo,
    })
};
export default connect(mapStateToProps)(UserAdminAccess);