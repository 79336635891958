import { useSelector } from "react-redux";
import { IState } from "../../../../../redux/reducers/rootReducers";
import { Box, Typography } from "@mui/material";
import CommonStyles from "../../../../common/CommonStyles";
import { Suspense, lazy, memo, useEffect, useMemo, useState } from "react";
import { PrioritySkeletonLoader } from "./widgets/DashboardSkeletonLoader";
import StatusColumn from "./widgets/StatusColumn";
//import { StatusColumn } from "./widgets/StatusColumn";
// const StatusColumn = lazy(() => import("./widgets/StatusColumn"));

export const TicketStatusView = memo(() => {
    const AllStatuses = useSelector((state: IState) => state?.TicketsReducer?.allResponses?.ticketStatus);
    const classes = CommonStyles();
    const shrinkVal: any = useSelector((state: IState) => state?.LayoutTicketManagementReducer?.columnShrinked);
    const StatusesToIgnore = ['New', 'NewtoMe', "Closed","Delete"];
    const [statusList,setStatusList] = useState([]);
    useEffect(()=>{
        if(AllStatuses?.length && AllStatuses?.length > 0){
            setStatusList(AllStatuses.filter((i: any) => !StatusesToIgnore.includes(i.name)))
        }
    },[AllStatuses?.length])
    const layout = useMemo(() => <Box className={`${classes.whiteBox} p-5p`}>
        {statusList.length > 0 &&
            <Box className={`${classes.row} pt-1 px-2 overflow-scroll-x`}>
                {statusList.map((i: any, index: number) => {
                    return (
                        <Box
                            key={index}
                            className={`${shrinkVal ? "col min-150 shrinked-cardCol" : "col-4"
                                } trans-5s px-10 position-relative`}
                        >
                            <Typography variant="body2" className="text-center">{i?.displayName}</Typography>
                            <StatusColumn columnId={i.id} columnName={i.name}/>
                        </Box>)
                })}
            </Box>
        }
    </Box>, [statusList,shrinkVal,classes])
    return (
        <>
            {layout}
            {/* <Suspense fallback={<><PrioritySkeletonLoader /></>}></Suspense> */}
        </>
    )
})
export default TicketStatusView;