import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Mode } from '@mui/icons-material';

export default function SimpleAccordion({name, isExpand, header, body}:{name:any, header: any, body: any, isExpand: Boolean}) {
  const [expanded, setExpanded] = React.useState<string | false>(isExpand? name : "");

  React.useEffect(()=>{
    setExpanded(isExpand? name:"")
  },[isExpand])
 
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  return (
    <div>
      <Accordion sx={{boxShadow:"none", border:"1px solid #C9C9C9", borderRadius:"5px !important"}} expanded={expanded ==name} onChange={handleChange(name)}>
        <AccordionSummary
        sx={{backgroundColor:"#EAF2FD", borderRadius: expanded==name ? "5px 5px 0px 0px" : "5px", height:"70px", fontSize:"18px"}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={{fontSize:"14px"}}>{header}</div>
        </AccordionSummary>
        <AccordionDetails 
                sx={{borderRadius:"5px",padding:"5px"}}
                >
          <Typography sx={{fontSize:"12px", textAlign:"center"}}>
           {body}
          </Typography>
        </AccordionDetails>
      </Accordion>
      
    </div>
  );
}