import Joi from "joi";

const nameSchema = Joi.string()
  .max(100)
  .messages({
    "string.max": "Name length should not exceed 100 characters.",
    "string.pattern.base": "Name should only contain alphabets.",
    "string.empty": "Name is required.",
  });

  const mobileNumberSchema = Joi.string()
  .trim()
  .regex(/^\d{10,15}$/) // Allowing only numerical values with a length between 10 and 15 digits
  .messages({
      "string.empty": "Mobile number is required.",
      "string.pattern.base": "Please provide a valid mobile number with 10 to 15 numerical digits.",
      "any.required": "Mobile number is required.",
  });
  const emailSchema = Joi.string()
  .allow("")
  .email({ tlds: { allow: false } })
  .messages({
    "string.email": "Invalid email format.",
    "any.required": "Email is required.",
  });
export const schemaForCallRequest = Joi.object({
  name: nameSchema,
  mobileNumber: mobileNumberSchema,
  mailId:emailSchema
});
