import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const PieChart = ({
  labels,
  datasets,
  showLegend = true,
  showLabels = true,
  isDarkMode = false,
}: {
  labels: any;
  datasets: any;
  showLegend?: boolean;
  showLabels?: boolean;
  isDarkMode?: boolean;
}) => {
  const options: any = {
    maintainAspectRatio: false, // Don't maintain w/h ratio
    plugins: {
      legend: {
        display: showLegend,
        labels: {
          usePointStyle: false,
          filter: (legendItem: any, data: any) =>
            data.datasets[0].data[legendItem.index] != 0,
            color: !isDarkMode ? "black":"white",
          font: {
            size: !showLabels ? 7 : 10, // Adjust font size based on showLabels
          },
          padding: !showLabels ? 3 : 8, // Adjust padding based on showLabels
        },
      },
      datalabels: {
        display: true,
        labels: {
          value: {
            color: !isDarkMode ? "black":"white",
            font: { size: showLabels ? 7 : 9 }, // Conditional font size for value labels
            formatter: function (value: any, ctx: any) {
              return ctx.active
                ? ""
                : ctx.chart.data.datasets[0].data[ctx.dataIndex] <= 0
                ? ""
                : ctx.chart.data.datasets[0].data[ctx.dataIndex];
            },
          },
        },
      },
    },
  };

  const data = {
    labels: labels,
    datasets: datasets,
  };

  return <Pie data={data} options={options} />;
};

export default PieChart;
