function timeSpanToReadableFormat(timeSpan: string, type?: string): string {
  if (!timeSpan) {
    return 'Error: Time span is empty';
  }

  // Determine if the time span is negative
  const isNegative = timeSpan.startsWith('-');
  if (isNegative) {
    timeSpan = timeSpan.substring(1); // Remove the negative sign for processing
  }

  const timeParts = timeSpan.split(':');
  if (timeParts.length < 2) {
    return 'Error: Invalid time span format';
  }

  let formattedTimeSpan = '';
  let days = '';
  let hours = '';
  let mins = '';
  let secs = '';

  // Extract days if present
  const daysIndex = timeParts[0].indexOf('.');
  if (daysIndex !== -1) {
    days = timeParts[0].substring(0, daysIndex);
    hours = timeParts[0].substring(daysIndex + 1);
  } else {
    hours = timeParts[0];
  }

  mins = timeParts[1];
  secs = timeParts[2]?.split('.')[0] || '0'; // Split to handle fractional seconds if present

  // Format days
  if (days) {
    formattedTimeSpan += `${parseInt(days)} d `;
  }

  // Format hours
  if (parseInt(hours) > 0) {
    formattedTimeSpan += `${parseInt(hours)} h `;
  }

  // Format minutes
  if (parseInt(mins) > 0) {
    formattedTimeSpan += `${parseInt(mins)} m `;
  }

  // Format seconds (optional, based on type parameter or additional requirements)
  if (type === 'includeSeconds' && parseInt(secs) > 0) {
    formattedTimeSpan += `${parseInt(secs)} s `;
  }

  // Remove trailing space and add negative sign if needed
  formattedTimeSpan = formattedTimeSpan.trim();
  if (isNegative) {
    formattedTimeSpan = `- ${formattedTimeSpan}`;
  }

  return formattedTimeSpan || '0';
}

export default timeSpanToReadableFormat;
