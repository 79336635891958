import { Box, Grid, FormControl, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import CommonStyles from "../../../common/CommonStyles";
import PermissionsFormGroup from "./PermissionsCheckboxGroup";
import { roleLabels } from "../AddRole";
import { IState } from "../../../../redux/reducers/rootReducers";
import { connect } from "react-redux";
import AgentPermissionsGroup from "./AgentPermissionsGroup";

const AgentCheckBoxList = memo((props: any) => {
    const { items = [],permissions=[],keyLabel,isSSP=false} = props;
    const classes = CommonStyles();
    console.log('AgentCheckBoxList-items', props)
    const layout = useMemo(() => {
        return (<>
            <Grid item xs={6} md={12}>
                {items?.map && items.map((i:any,index:number) => {
                    return (<>
                        <div className={`p-1 ${index % 2 === 1 ? '' : 'stripe'}`}>
                            <Grid container spacing={0} alignItems="center">
                                <Grid item xs={6} md={3}>
                                    <Box>
                                    <Typography variant="body2" className={`${classes.linkTitle} ${classes.rolesBlueTitle}`}>{i?.name}</Typography>
                                    {i?.hint && <Typography  variant="body2" className={`roleRow-hint`}>{i?.hint}</Typography>}
                                    </Box>
                                </Grid>
                                <Grid item xs={6} md={9}>
                                    <FormControl >
                                        <AgentPermissionsGroup isSSP={isSSP} fieldId={i?.id} titleKey={keyLabel} permissions={permissions} />
                                    </FormControl>
                                </Grid>

                            </Grid>

                        </div>
                    </>)
                })}

            </Grid>
        </>)
    }, [items])
    return (<>
        {layout}
    </>)
})
const mapStateToProps = (state:IState) => {
    return ({
        permissions : state?.permissionReducer?.Data?.data,
    })
};
export default connect(mapStateToProps)(AgentCheckBoxList);//AgentCheckBoxList;//