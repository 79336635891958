const NotificationCircle = ({count}: {count: number}) => {
  return (
    <>
      <div
        style={{
          backgroundColor: "red",
          width: "17px",
          height:"17px",
          borderRadius: "50%",
          padding: "1.5px",
          display: "flex",
          justifyContent: "center",
          fontSize: "11px",
          color: "white",
          position:"absolute",
          top:0,
          right:"3px"
        }}
      >
        {count}
      </div>
    </>
  );
};

export default NotificationCircle;
