import Joi from "joi";
const nameSchema = Joi.string().trim().min(3).max(50).required().messages({
  "string.empty": "Name is required.",
  "any.required": "Name is required.",
  "string.max": "Name length must be less than or equal to 50 characters.",
  "string.min": "Min 3 character required.",
});

const emailSchema = Joi.string()
  .trim()
  .email({ tlds: { allow: false } }) // You can adjust this based on your email validation requirements
  .messages({
    "string.email": "Please provide a valid email address.",
    "any.required": "Email is required.",
  });

export const addEmailException = Joi.object({    name : nameSchema
});

