const initialState = {
  Data: [],
  unAssignedData: [],
  showLoading: false,
  source:[]
};

const priorityReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "getPriority":
      return {
        ...state,
        Data: action.payload,
      };
      case "source":
        return {
          ...state,
          source: action.payload,
        };
      case "unassignedTicket":
        return {
          ...state,
          unAssignedData: action.payload,
        };
    case "singlePriority":
      return {
        ...state,
        Data: action.payload,
      };
    case "updatePriority":
    case "multipalUpdatePriority":
    case "bulkDeletePriority":
    case "softDeletePriority":
      return {
        ...state,
      };
    case "deletePriority":
      return {
        ...state,
      };
    case "loading":
      return {
        ...state,
        showLoading: action.payload,
      };
    default:
      return state;
  }
};

export default priorityReducer;
