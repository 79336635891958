import { Box, Grid, Tooltip, Typography } from "@mui/material"
import AddNewPart from "../../../components/common/AddNew"
import SideNavbarForAdmin from "../../../components/navbar/admin-navbar/side-navbar/SideNavBar"
import TopNavBarForAdmin from "../../../components/navbar/admin-navbar/top-navbar/TopNavBar"
import MobileMenu from "../../../components/navbar/sideNavbar/MobileMenu"
import TopFilter from "../../../components/common2/TopFilter"
import { useEffect, useState } from "react"
import Card from "../../../components/common/card";
import editIcon from "../../../assest/icons/edit.svg";
import restoreIcon from "../../../assest/icons/restore.svg";
import deleteIcon from "../../../assest/icons/delete.svg";
import CommonStyles from "../../../components/common/CommonStyles"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { IState } from "../../../redux/reducers/rootReducers"
import { getEmailSettingsSsp, hardDeleteEmailSettingsSsp } from "../../../redux/actions/SelfServicePortal/ContactAid/EmailSettingsSsp/EmailSettingsSsp"
import Breadcrumbs from "../../../components/common2/Breadcrumbs";

const EmailSettingsSsp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const TemplatesList = useSelector(
        (state: IState) => state?.EmailSettingsSspReducer?.AllEmailSettingsSsp
    );
    const classes = CommonStyles();
    const [listStatus, setlistStatus] = useState(true);
    const [isSaved, setisSaved] = useState(false);
    const [searchData, setSearchData] = useState("");
    const [group, setGroup] = useState([]);
    const [permissionInfo, setpermissionInfo] = useState({} as any);
    const rolesAccess = useSelector((state: IState) => state?.LoginReducer?.roleAccess);
    useEffect(() => {
        if (rolesAccess) {
            console.log('rolesAccess.MailService', rolesAccess.MailService)
            setpermissionInfo({ ...rolesAccess.MailService, isSuperAdmin: rolesAccess.isSuperAdmin })
        }
    }, [rolesAccess]);
    const checkPermission: any = (valueKey: string) => {
        let val: boolean = permissionInfo[valueKey] ? true : false
        return val;
    }
    const submitHandler = () => {
        setisSaved(true);
    };
    const handleSearch = (e: any) => {
        let lowerCase = e.target.value;

        setSearchData(lowerCase);
    };
    const filterHandler = (e: any) => { };
    const checkBoxHandler = (e: any, id: any) => { };
    const showDataHandler = (show: any) => {
        setlistStatus(show);
    };
    const updateAllHandler = (value: any, id: any) => { };
    const [update, setUpdate] = useState(false);
    const [active, setActive] = useState([]);
    const [records, setRecords] = useState([]);
    useEffect(() => {
        ;
        console.log("pop", TemplatesList);
        setRecords(TemplatesList);
    }, [TemplatesList]);
    useEffect(() => {
        console.log("records", records);
    }, [records]);
    useEffect(() => {
        // dispatch(unsetSubmitValuesAction());
        dispatch(getEmailSettingsSsp());
    }, []);
    useEffect(() => {
        if (TemplatesList && TemplatesList.length > 0) {
            const re = new RegExp(searchData, "i");
            const filtered = TemplatesList.filter((entry: any) =>
                Object.values(entry).some(
                    (val) => typeof val === "string" && val.match(re)
                )
            );
            setRecords(filtered);
        }
    }, [searchData, listStatus]);
    const EditHandler = (id: string) => {
        navigate(`/AddEmailSettingsSsp/${id}`);
    };
    const softDelete = (id: number) => { };
    const hardDelete = (key: number) => {
        const deleteItem: any = records[key]
        setRecords(records.filter((x: any) => x.id !== key));
        dispatch(hardDeleteEmailSettingsSsp(deleteItem.id));
    };
    return (
        <>
            
            <div>
            <Breadcrumbs
                data={[
                    {
                        title: 'Home',
                        path: '/adminPanel'
                    },
                    {
                        title: 'EmailSettings ',
                        path: '/EmailSettingsSsp'
                    }
                ]
                }
            />
            </div>
            <div>
                <Typography variant="h6" className={`${classes.themeColorTxt}`}>
                    Email Settings
                </Typography>
            </div>
            <Box className="pageHolder">
                <div>
                    <Grid container className="pt-1" spacing={1}>
                        <div style={{ width: "100%" }}>
                            <TopFilter
                                path="/AddEmailSettingsSsp"
                                Data={group}
                                SearchHandler={handleSearch}
                                value={searchData}
                                filterHandler={filterHandler}
                                checkBoxHandler={checkBoxHandler}
                                checked={false}
                                hideSelectAllOption={true}
                                hideActiveBtns={true}
                                filterItems={showDataHandler}
                                updateAllHandler={updateAllHandler}
                                titleState={update}
                                placeHolder="Search ..."
                            />
                        </div>
                        <Grid container className="pt-1" spacing={1}>

                            {records && records.length > 0 ? (
                                <>
                                    {records.map((item: any, key: number) => {
                                        const { id, mailId, name,isActive} = item;
                                        if (!isActive) return null;
                                        return (
                                            <Grid item md={3} xs={12} key={key}>
                                                <Card
                                                    styleProp={classes.cardStyle}
                                                    customHeader={
                                                        <Box
                                                            className={`${classes.row} ${classes.alignItemsCenter} ${classes.justifyBetween} ${classes.w100}`}
                                                        >
                                                            <Typography variant="body2" className={classes.title}>
                                                                Name : {name}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    cardBody={
                                                        <Box>
                                                            {/* <Typography variant="body2">
                                                                <b>Client Id :</b>
                                                            </Typography>
                                                            <Typography variant="body1">{clientId}</Typography>
                                                            <Typography variant="body2">
                                                                <b>Tenant Id :</b>
                                                            </Typography>
                                                            <Typography variant="body1">{tenantId}</Typography> */}
                                                            <Typography variant="body2">
                                                                <b>Mail : </b>
                                                            </Typography>
                                                            <Typography variant="body1">{mailId}</Typography>
                                                        </Box>
                                                    }
                                                    cardFooter={
                                                        <Box>
                                                            {
                                                                <Tooltip title="Edit Email Settings">
                                                                    <img
                                                                        src={editIcon}
                                                                        alt="edit icons"
                                                                        onClick={() => {
                                                                            EditHandler(id);
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            }
                                                            {
                                                                <Tooltip
                                                                    title={`${listStatus
                                                                        ? "Delete Email Settings"
                                                                        : "Restore Email Settings"
                                                                        }`}
                                                                >
                                                                    <img
                                                                        src={listStatus ? deleteIcon : restoreIcon}
                                                                        alt="delete icons"
                                                                        onClick={() => {
                                                                            //softDelete(key);
                                                                            hardDelete(key);
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            }
                                                        </Box>
                                                    }
                                                />
                                            </Grid>
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    <AddNewPart title="Add New" path="/Add-EmailSettings" />
                                </>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </Box >
        </>
    )
}
export default EmailSettingsSsp