import { Box, Container } from "@mui/material";
import UnderConstruction from "../../UnderConstruction/UnderConstruction";
import PricingBanner from "./PricingBanner";

export default function LicenceAgreement() {
    return (
        <>
            <PricingBanner />
            <Container>
                <Box className="white-bg p-2">
                    {/* <h1>Here Licence Agreement will come</h1> */}
                    {/* <UnderConstruction /> */}
                    <h1>SIMPLISYS ONLINE TERMS AND CONDITIONS</h1>
                    <p>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. BY SUBMITTING AN APPLICATION TO USE THE SERVODESK SERVICE, THE CUSTOMER IS AGREEING TO THESE TERMS IN FULL. YOU ALSO AGREE THAT YOU ARE AUTHORISED TO ACCEPT THESE TERMS ON BEHALF OF THE CUSTOMER</p>
                    <h3>AGREED TERMS</h3>
                    <p>The following definitions apply in these terms and conditions:</p>
                    <h4>1.	INTERPRETATION</h4>
                    <p>1.1	The definitions and rules of interpretation in this clause apply in this agreement.</p>
                    <p><b>Authorised Users: </b> those employees, agents and independent contractors of the Customer who are authorised by the Customer (as a result of the purchase of User Subscriptions) to use the Services and the Documentation.</p>
                    <p><b>Business Day: </b> any day which is not a Saturday, Sunday or public holiday in the UK.</p>
                    <p><b>Confidential Information: </b>information that is proprietary or confidential and is either clearly labelled as such or identified as Confidential Information.</p>
                    <p><b>Customer: </b>the company or legal entity wishing to use the Supplier’s service in its business operations. </p>
                    <p><b>Customer Data: </b>the data (to include personal data as defined in clause 5.1, where applicable) inputted by the Customer, Authorised Users, or the Supplier on the Customer's behalf for the purpose of using the Services or facilitating the Customer’s use of the Services. </p>
                    <p><b>Data Protection Legislation: </b>the UK Data Protection Legislation and any other European Union legislation relating to personal data and all other legislation and regulatory requirements in force from time to time which apply to a party relating to the use of personal data (including, without limitation, the privacy of electronic communications) and the guidance and codes of practice issued by the relevant data protection or supervisory authority and applicable to a party.</p>
                    <p><b>Documentation: </b>the user guide document made available to the Customer by the Supplier online (via such web address as notified by the Supplier to the Customer from time to time) or by email, and which sets out a description of the Services and the user instructions for the Services.</p>
                    <p><b>Effective Date: </b>the date that the Customer submits an application to use the Supplier’s services by accepting these terms and conditions.</p>
                    <p><b>GDPR: </b>the General Data Protection Regulation (Regulation (EU) 2016/679).</p>
                    <p><b>Initial Subscription Term: </b>the term selected by the Customer in its application to use the Services.</p>
                    <p><b>Normal Business Hours: </b>9.00 am to 5.30 pm local UK time, each Business Day.</p>
                    <p><b>Renewal Period: </b>the period described in clause 14.1.</p>
                    <p><b>Services: </b>the hosted services provided by the Supplier to the Customer under this agreement via https://portal.simplisys.com or any other website notified to the Customer by the Supplier from time to time, as more particularly described in the Documentation, and as selected by the Customer in the application process.</p>
                    <p><b>Software: </b>the online software applications provided by the Supplier as part of the Services and as listed in the Documentation.</p>
                    <p><b>Subscription Fees: </b>the subscription fees payable by the Customer to the Supplier for the current Services and User Subscriptions (being the User Subscription Fee multiplied by the number of User Subscriptions), as set out in the Documentation, or as amended under clause 9.6, or as otherwise agreed by the parties in writing.</p>
                    <p><b>Subscription Term: </b>has the meaning given in clause 14.1.</p>
                    <p><b>Supplier: </b>Simplisys Limited, incorporated and registered in England and Wales with company number 05862418, whose registered office is at Top Floor, 42 High Street, Portishead, Bristol, BS20 6EL.</p>
                    <p><b>Support Services Policy: </b>the Supplier's policy for providing support in relation to the Services as made available at www.simplisys.com or such other website address as may be notified to the Customer from time to time.</p>
                    <p><b>UK Data Protection Legislation: </b>all applicable data protection and privacy legislation in force from time to time in the UK including the General Data Protection Regulation ((EU) 2016/679); the Data Protection Act 2018; the Privacy and Electronic Communications Directive 2002/58/EC (as updated by Directive 2009/136/EC) and the Privacy and Electronic Communications Regulations 2003 (SI 2003/2426) as amended.</p>
                    <p><b>User Subscriptions: </b>the user subscriptions purchased by the Customer pursuant to clause 3 or clause 9.1 which entitle Authorised Users to access and use the Services and the Documentation in accordance with this agreement.</p>
                    <p><b>User Subscription Fee: </b> the amount payable by the Customer per User Subscription, as set out in the Documentation, or as amended under clause 9.6, or as otherwise agreed in writing.</p>
                    <p><b>Virus: </b>any thing or device (including any software, code, file or programme) which may: prevent, impair or otherwise adversely affect the operation of any computer software, hardware or network, any telecommunications service, equipment or network or any other service or device; prevent, impair or otherwise adversely affect access to or the operation of any programme or data; or adversely affect the user experience, including worms, trojan horses, viruses and other similar things or devices.</p>
                    <p>1.2	A person includes an individual, corporate or unincorporated body (whether or not having separate legal personality) and that person's legal and personal representatives, successors or permitted assigns.</p>
                    <p>1.3	Words in the singular shall include the plural and vice versa, and a reference to one gender shall include a reference to the other genders.</p>
                    <p>1.4	A reference to a statute or statutory provision is a reference to it as it is in force for the time being, taking account of any amendment, extension or re-enactment.</p>
                    <p>1.5	A reference to writing or written includes e-mail but not faxes.</p>
                    <p>1.6	References to clauses and schedule are to the clauses and schedule of this agreement.</p>

                    <h4>2.	USER SUBSCRIPTIONS</h4>

                    <p>2.1	Subject to the Customer purchasing the User Subscriptions in accordance with clause 3.3 (additional User Subscriptions) and clause 9.1 (payment of Subscription Fees), the  restrictions set out in this clause 2 and the other terms and conditions of this agreement, the Supplier hereby grants to the Customer a non-exclusive, non-transferable right to permit the Authorised Users to access and use the Services selected by the Customer and the Documentation during the Subscription Term for the Customer's business operations.</p>
                    <p>2.2	In relation to the Authorised Users, the Customer undertakes that:</p>
                    <p>(a)	it will not allow or suffer any User Subscription to be used by more than one individual Authorised User unless it has been reassigned in its entirety to another individual Authorised User, in which case the prior Authorised User shall no longer have any right to access or use the Services and/or Documentation;</p>
                    <p>(b)	each Authorised User shall keep a secure password for his use of the Services and Documentation, and that each Authorised User shall keep his password confidential.</p>
                    <p>2.3	The Customer shall not access, store, distribute or transmit any Virus, or any material during the course of its use of the Services that:</p>
                    <p>(a)	is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing, discriminatory or racially or ethnically offensive; or</p>
                    <p>(b)	facilitates or promotes illegal activity; or</p>
                    <p>(c)	facilitates or promotes any spam email,</p>
                    <p>and the Supplier reserves the right, without liability to the Customer, to disable the Customer’s access to any material that breaches the provisions of this clause.</p>
                    <p>2.4	The Customer shall not:</p>
                    <p>(a)	except as may be allowed by any applicable law which is incapable of exclusion by agreement between the parties:</p>
                    <p>(i)	and except to the extent expressly permitted under this agreement, attempt to copy, modify, duplicate, create derivative works from, republish, download, display, transmit, or distribute all or any portion of the Software and/or Documentation (as applicable) in any form or media or by any means; or</p>
                    <p>(ii)	attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Software; or</p>
                    <p>(b)	access all or any part of the Services and Documentation in order to build a product or service which competes with the Services and/or the Documentation; or</p>
                    <p>(c)	use the Services and/or Documentation to provide services to third parties without the written permission of the Supplier, subject to clause 2.6; or</p>
                    <p>(d)	subject to clauses 2.6 and 19.1 (assignment), license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Services and/or Documentation available to any third party except the Authorised Users without the written permission of the Supplier, or</p>
                    <p>(e)	attempt to obtain, or assist third parties in obtaining, access to the Services and/or Documentation other than as provided under this clause 2.</p>
                    <p>2.5	The Customer shall use all reasonable endeavours to prevent any unauthorised access to, or use of, the Services and/or the Documentation and, in the event of any such unauthorised access or use, promptly notify the Supplier.</p>
                    <p>2.6	The Customer may permit its customers or clients to use any self service portal provided in connection with the Services provided that it has the prior written consent of the Supplier to do so (and on such terms and conditions as the Supplier may in its discretion specify) and subject to clause 2.7.</p>
                    <p>2.7	The Customer shall fully indemnify the Supplier against any loss, liability, cost or expense which the Supplier may incur or suffer as a result of any claim or demand made by any such customer or client as referred to under clause 2.6, and where such claim or demand is made in connection with the Services or the use of such self-service portal.</p>

                    <h4>3.	ADDITIONAL USER SUBSCRIPTIONS</h4>
                    <p>3.1	Subject to clause 3.2 and clause 3.3, the Customer may, from time to time during any Subscription Term, purchase additional User Subscriptions in excess of the number paid for at the beginning of the Initial Subscription Term and the Supplier shall grant access to the Services and the Documentation to such additional Authorised Users in accordance with the provisions of this agreement.</p>
                    <p>3.2	If the Customer wishes to purchase additional User Subscriptions, the Customer shall notify the Supplier in writing. The Supplier shall evaluate such request for additional User Subscriptions and respond to the Customer with approval or disapproval of the request (such approval not to be unreasonably withheld).</p>
                    <p>3.3	If the Supplier approves the Customer’s request to purchase additional User Subscriptions, the Customer shall, within 30 days of the date of the Supplier’s invoice, pay to the Supplier the User Subscription Fees for such additional User Subscriptions and, if such additional User Subscriptions are purchased by the Customer part way through the Initial Subscription Term or any Renewal Period (as applicable), such fees shall be pro-rated for the remainder of the Initial Subscription Term or then current Renewal Period as applicable (but for the avoidance of doubt in such calculation any part of any month shall be deemed to be a whole month).</p>

                    <h4>4.	SERVICES</h4>
                    <p>4.1	The Supplier shall, during the Subscription Term, provide the Services and make available the Documentation to the Customer on and subject to the terms of this agreement.</p>
                    <p>4.2	The Supplier shall use commercially reasonable endeavours to make the Services available 24 hours a day, seven days a week except for:</p>
                    <p>(a)	planned maintenance performed outside Normal Business Hours; </p>
                    <p>(b)	unscheduled maintenance performed outside Normal Business Hours, provided that the Supplier has used reasonable endeavours to give the Customer at least 4 Normal Business Hours’ notice in advance; and</p>
                    <p>(c)	unscheduled maintenance work which the Supplier, acting reasonably, deems to be emergency maintenance work, and in such circumstances the Supplier shall give such notice to the Customer as it reasonably thinks fit (or may choose to give no notice at all, if the Supplier decides, acting reasonably, that such work must be done immediately).</p>
                    <p>4.3	The Supplier will, when selected by the Customer, provide the Customer with the Supplier’s standard customer support services during Normal Business Hours in accordance with the Supplier's Support Services Policy in effect at the time that the Services are provided.  The Supplier may amend the Support Services Policy in its sole and absolute discretion from time to time.  The Customer may purchase enhanced support services separately at the Supplier’s then current rates.</p>
                    <p>4.4	Subject to clause 4.5 and clause 4.6, the Customer may, from time to time during any Subscription Term, purchase additional Services in excess of those selected at the beginning of the Initial Subscription Term and the Supplier shall grant access to such additional Services in accordance with the provisions of this agreement.</p>
                    <p>4.5	If the Customer wishes to purchase additional Services, the Customer shall notify the Supplier in writing. The Supplier shall evaluate such request for additional Services and respond to the Customer with approval or disapproval of the request (such approval not to be unreasonably withheld).</p>
                    <p>4.6	If the Supplier approves the Customer’s request to purchase additional Services, the Customer shall, within 30 days of the date of the Supplier’s invoice, pay to the Supplier the User Subscription Fees for such additional Services and, if such additional Services are purchased by the Customer part way through the Initial Subscription Term or any Renewal Period (as applicable), such fees shall be pro-rated for the remainder of the Initial Subscription Term or then current Renewal Period as applicable (but for the avoidance of doubt in such calculation any part of any month shall be deemed to be a whole month).</p>

                    <h4>5.	CUSTOMER DATA</h4>
                    <p>5.1	In this clause 5 the terms controller, processor, data subject, personal data, personal data breach and processing (or any similar expression) shall have the meanings as defined in the Data Protection Legislation.</p>
                    <p>5.2	The Customer shall own all rights, title and interest in and to all of the Customer Data and shall have sole responsibility for the legality, reliability, accuracy and quality of the Customer Data uploaded to or otherwise inputted by the Authorised Users.</p>
                    <p>5.3	The Supplier shall follow its archiving procedures for Customer Data as set out in its back-up policy available at http://www.simplisys.co.uk/back-up-policy or such other website address as may be notified to the Customer from time to time, as such document may be amended by the Supplier in its sole discretion from time to time.  </p>
                    <p>5.4	The Supplier shall, in providing the Services, comply with its privacy policy relating to the privacy and security of the Customer Data available at www.simplisys.co.uk or such other website address as may be notified to the Customer from time to time, as such document may be amended from time to time by the Supplier in its sole discretion.</p>
                    <p>5.5	Both parties shall comply with all applicable requirements of the Data Protection Legislation. This clause 5.5 is in addition to, and does not relieve, remove or replace, a party’s obligations under the Data Protection Legislation.</p>
                    <p>5.6	The parties acknowledge that:</p>
                    <p>(a)	if the Supplier processes any personal data on the Customer's behalf when performing its obligations under this agreement, the Customer is the </p>
                    <p>(b)	controller and the Supplier is the processor for the purposes of the Data Protection Legislation; and</p>
                    <p>(c)	Part 1 of Schedule 1 sets out the scope, nature and purpose of processing by the Supplier, the duration of the processing and the types of personal data.</p>
                    <p>5.7	The Customer will ensure that it has all necessary appropriate consents and notices in place to enable lawful transfer of the personal data to the Supplier for the duration and purposes of this agreement so that the Supplier may lawfully use, process and transfer the personal data in accordance with this agreement on the Customer's behalf.</p>
                    <p>5.8	The Supplier shall, in relation to any personal data processed in connection with the performance by the Supplier of its obligations under this agreement:</p>
                    <p>(a)	process that personal data only on the written instructions of the Customer unless the Supplier is required by the laws of any member of the European Union or by the laws of the European Union applicable to the Supplier to process personal data (Applicable Laws). Where the Supplier is relying on laws of a member of the European Union or European Union law as the basis for processing personal data, the Supplier shall promptly notify the Customer of this before performing the processing required by the Applicable Laws unless those Applicable Laws prohibit the Supplier from so notifying the Customer;</p>
                    <p>(b)	immediately notify the Customer if, in its opinion, any instruction given by the Customer infringes the Data Protection Legislation;</p>
                    <p>(c)	ensure that all personnel who have access to and/or process personal data are obliged to keep the personal data confidential;</p>
                    <p>(d)	not transfer any personal data outside of the European Economic Area and the United Kingdom unless the following conditions are fulfilled:</p>
                    <p>(i)	the Customer or the Supplier has provided appropriate safeguards in relation to the transfer;</p>
                    <p>(ii)	the data subject has enforceable rights and effective legal remedies;</p>
                    <p>(iii)	the Supplier complies with its obligations under the Data Protection Legislation by providing an adequate level of protection to any personal data that is transferred; and</p>
                    <p>(e)	the Supplier complies with reasonable instructions notified to it in advance by the Customer with respect to the processing of the personal data;</p>
                    <p>(f)	assist the Customer, at the Customer's cost, in responding to any request from a data subject and in ensuring compliance with its obligations under the Data Protection Legislation with respect to security, breach notifications, impact assessments and consultations with supervisory authorities or regulators;</p>
                    <p>(g)	notify the Customer without undue delay on becoming aware of a personal data breach;</p>
                    <p>(h)	at the written direction of the Customer, delete or return personal data and copies thereof to the Customer on termination of the agreement unless required by Applicable Law to store the personal data;</p>
                    <p>(i)	maintain complete and accurate records and information to demonstrate its compliance with this clause 5; and</p>
                    <p>(j)	allow for and contribute to audits by the Customer or the Customer's designated auditor  not more than once in any 12-month period from the Effective Date (except where the Customer knows or reasonably believes that there has been a personal data breach involving the Supplier) during Normal Business Hours.</p>
                    <p>5.9	Each party shall ensure that it has in place appropriate technical and organisational measures, reviewed and approved by the other party, to protect against unauthorised or unlawful processing of personal data and against accidental loss or destruction of, or damage to, personal data, appropriate to the harm that might result from the unauthorised or unlawful processing or accidental loss, destruction or damage and the nature of the data to be protected, having regard to the state of technological development and the cost of implementing any measures (those measures may include, where appropriate, pseudonymising and encrypting personal data, ensuring confidentiality, integrity, availability and resilience of its systems and services, ensuring that availability of and access to personal data can be restored in a timely manner after an incident, and regularly assessing and evaluating the effectiveness of the technical and organisational measures adopted by it).</p>
                    <p>5.10	The Customer consents to the Supplier appointing the organisations listed in Part 2 of Schedule 1 as third-party processors of personal data under this agreement. The Supplier confirms that it has entered or (as the case may be) will enter with such third-party processors into a written agreement incorporating terms which are substantially similar to those set out in this clause 5. As between the Customer and the Supplier, the Supplier shall remain fully liable for all acts or omissions of any third-party processor appointed by it pursuant to this clause 5.</p>
                    <p>5.11	In the event that the Supplier wishes to make any changes concerning the addition or replacement of any sub-processor identified in Part 2 of Schedule 1:</p>
                    <p>(a)	it shall give the Customer not less than 60 days’ written notice of the proposed change including details of the identity of such sub-processor and a description of the nature of the processing to be undertaken by them;</p>
                    <p>(b)	if the Customer objects to any proposed change, it shall notify the Supplier with the grounds for such objection in writing not less than 30 days prior to expiry of the 60 day notice period and the parties shall use their respective reasonable endeavours to address and resolve those grounds of objection or agree alternative arrangements for the Customer; and</p>
                    <p>(c)	if the parties shall be unable to resolve the grounds of objection or agree alternative arrangements, either party may terminate the provision of the Services to the extent affected by the proposed change or, where this is not possible, this agreement without notice or liability.</p>
                    <p>5.12	In the event of any loss or damage to Customer Data, the Customer's sole and exclusive remedy shall be for the Supplier to use reasonable commercial endeavours to restore the lost or damaged Customer Data from the latest back-up of such Customer Data maintained by the Supplier in accordance with the archiving procedure described in its back-up policy. </p>
                    <p>5.13	The Supplier shall not be liable, however, for any loss, damage, alteration or disclosure of Customer Data caused by either:</p>
                    <p>(a)	any third party (except those third parties sub-contracted by the Supplier to perform services related to Customer Data maintenance and back-up for which it shall remain fully liable under clause 5.10); or</p>
                    <p>(b)	the Customer in breach of clauses 2.3 or 2.4,</p>
                    <p>in which event the Supplier shall be entitled to charge a reasonable sum for any recovered or restored Customer Data which it provides to the Customer (following a Customer request for such Customer Data).</p>

                    <h4>6.	THIRD PARTY PROVIDERS</h4>
                    <p>The Customer acknowledges that the Services may enable or assist it to access the website content of, correspond with, and purchase products and services from, third parties via third-party websites and that it does so solely at its own risk.  The Supplier makes no representation or commitment and shall have no liability or obligation whatsoever in relation to the content or use of, or correspondence with, any such third-party website, or any contract entered into by the Customer with any such third party.  </p>

                    <h4>7.	SUPPLIER'S OBLIGATIONS</h4>
                    <p>7.1	The Supplier undertakes that the Services will be performed substantially in accordance with the Documentation and with reasonable skill and care.</p>
                    <p>7.2	The undertaking at clause 7.1 shall not apply to the extent of any non-conformance which is caused by use of the Services contrary to the Supplier's instructions, or modification of the Services by any party other than the Supplier or the Supplier's duly authorised contractors or agents. If the Services do not conform with the foregoing undertaking, Supplier will, at its expense, use all reasonable commercial endeavours to correct any such non-conformance promptly, or provide the Customer with an alternative means of accomplishing the desired performance. Notwithstanding the foregoing, the Supplier:</p>
                    <p>(a)	does not warrant that the Customer's use of the Services will be uninterrupted or error-free; and</p>
                    <p>(b)	is not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of data over communications networks and facilities, including the internet, and the Customer acknowledges that the Services and Documentation may be subject to limitations, delays and other problems inherent in the use of such communications facilities.</p>
                    <p>7.3	This agreement shall not prevent the Supplier from entering into similar agreements with third parties, or from independently developing, using, selling or licensing similar documentation, products and/or services.</p>
                    <p>7.4	The Supplier warrants that it has and will maintain all necessary licenses and consents necessary for the performance of its obligations under this agreement.</p>

                    <h4>8.	CUSTOMER'S OBLIGATIONS</h4>
                    <p>The Customer shall:</p>
                    <p>(a)	provide the Supplier with:</p>
                    <p>(i)	all necessary co-operation in relation to this agreement; and</p>
                    <p>(ii)	all necessary access to such information as may be required by the Supplier;</p>
                    <p>in order to render the Services, including but not limited to Customer Data, security access information and configuration services;</p>
                    <p>(b)	comply with all applicable laws and regulations with respect to its activities under this agreement;</p>
                    <p>(c)	carry out all other Customer responsibilities set out in this agreement in a timely and efficient manner. In the event of any delays in the Customer's provision of such assistance as agreed by the parties, the Supplier may adjust any agreed timetable as reasonably necessary;</p>
                    <p>(d)	ensure that the Authorised Users use the Services and the Documentation in accordance with the terms and conditions of this agreement and shall be responsible for any Authorised User’s breach of this agreement;</p>
                    <p>(e)	ensure that its network and systems comply with the relevant specifications provided by the Supplier;</p>
                    <p>(f)	use its reasonable endeavours to ensure that spam email is not used in connection with the Services</p>

                    <h4>9.	CHARGES AND PAYMENT</h4>
                    <p>9.1	The Customer shall pay the Subscription Fees to the Supplier for the User Subscriptions on an annual or monthly basis (as selected by the Customer) and in advance (unless otherwise agreed in writing), and in accordance with this clause 9 and the Documentation.</p>
                    <p>9.2	The Customer shall on the Effective Date provide to the Supplier either valid, up-to-date and complete credit card details or approved purchase order information acceptable to the Supplier (and the payment method shall be at the discretion of the Supplier) and any other relevant valid, up-to-date and complete contact and billing details. If the Customer provides:</p>
                    <p>(a)	its credit card details to the Supplier, the Customer hereby authorises the Supplier to bill such credit card:</p>
                    <p>(i)	on the Effective Date for the Subscription Fees payable in respect of the Initial Subscription Term; and</p>
                    <p>(ii)	subject to clause 14.1, on each monthly or yearly anniversary of the Effective Date for the Subscription Fees payable in respect of the next Renewal Period;</p>
                    <p>(b)	its approved purchase order information to the Supplier, the Supplier shall invoice the Customer:</p>
                    <p>(i)	on the Effective Date for the Subscription Fees payable in respect of the Initial Subscription Term; and</p>
                    <p>(ii)	subject to clause 14.1 (term and termination), at least 30 days prior to each anniversary of the Effective Date for the Subscription Fees payable in respect of the next Renewal Period,</p>
                    <p>and the Customer shall pay each invoice within 30 days after the date of such invoice.</p>
                    <p>9.3	If the Supplier has not received payment within 30 days after the due date, and without prejudice to any other rights and remedies of the Supplier:</p>
                    <p>(a)	the Supplier may, without liability to the Customer, disable the Customer’s password, account and access to all or part of the Services and the Supplier shall be under no obligation to provide any or all of the Services while the invoice(s) concerned remain unpaid; and</p>
                    <p>(b)	interest shall accrue on such due amounts at an annual rate equal to 3% over the then current base lending rate of Barclays Bank plc at the date the relevant invoice was issued, commencing on the due date and continuing until fully paid, whether before or after judgment.</p>
                    <p>9.4	All amounts and fees stated or referred to in this agreement shall be payable in pounds sterling and are exclusive of value added tax, which shall be added to the Supplier's invoice(s) at the appropriate rate.</p>
                    <p>9.5	If, at any time whilst using the Services, the Customer exceeds the amount of disk storage space it has selected, the Supplier shall charge the Customer, and the Customer shall pay, the Supplier’s then current excess data storage fees. The Supplier’s excess data storage fees current as at the Effective Date are set out in the Documentation.  </p>
                    <p>9.6	The Supplier shall be entitled to amend the User Subscription Fee, the specified disk storage space and/or the excess storage fees payable pursuant to clause 9.5 at the start of each Renewal Period upon 35 days' prior notice to the Customer, and these terms and conditions (including the Subscription Fees payable in respect of that Renewal Period) shall be deemed to have been amended accordingly.</p>

                    <h4>10.	PROPRIETARY RIGHTS</h4>
                    <p>The Customer acknowledges and agrees that the Supplier and/or its licensors own all intellectual property rights in the Services (comprising the Software) and the Documentation. Except as expressly stated herein, this agreement does not grant the Customer any rights to, or in, patents, copyrights, database rights, trade secrets, trade names, trade marks (whether registered or unregistered), or any other rights or licenses in respect of the Services (comprising the Software) or the Documentation.</p>

                    <h4>11.	CONFIDENTIALITY</h4>
                    <p>11.1	Each party may be given access to Confidential Information from the other party in order to perform its obligations under this agreement.  A party's Confidential Information shall not be deemed to include information that:</p>
                    <p>(a)	is or becomes publicly known other than through any act or omission of the receiving party;</p>
                    <p>(b)	was in the other party's lawful possession before the disclosure;</p>
                    <p>(c)	is lawfully disclosed to the receiving party by a third party without restriction on disclosure; or</p>
                    <p>(d)	is required to be disclosed by law, by any court of competent jurisdiction or by any regulatory or administrative body.</p>
                    <p>11.2	Each party shall hold the other's Confidential Information in confidence and, unless required by law, not make the other's Confidential Information available to any third party, or use the other's Confidential Information for any purpose other than the implementation of this agreement.  Each party shall take all reasonable steps to ensure that the other's Confidential Information to which it has access is not disclosed or distributed by its employees or agents in violation of the terms of this agreement.</p>
                    <p>11.3	This clause 11 shall survive termination of this agreement, however arising.</p>

                    <h4>12.	INDEMNITY</h4>
                    <p>12.1	The Supplier shall defend the Customer, its officers, directors and employees against any claim that the Services or Documentation infringes any patent effective as of the Effective Date, copyright, trade mark, database right or right of confidentiality, and shall indemnify the Customer for any amounts awarded against the Customer in judgment or settlement of such claims, provided that:</p>
                    <p>(a)	the Supplier is given prompt notice of any such claim;</p>
                    <p>(b)	the Customer provides reasonable co-operation to the Supplier in the defence and settlement of such claim, at the Supplier's expense; and</p>
                    <p>(c)	the Supplier is given sole authority to defend or settle the claim.</p>
                    <p>12.2	In the defence or settlement of any claim, the Supplier may terminate this agreement on 2 Business Days’ notice to the Customer without any additional liability or obligation to pay liquidated damages to the Customer. </p>

                    <h4>13.	LIMITATION OF LIABILIY</h4>
                    <p>13.1	Subject to the provisions of clause 12, this clause 13 sets out the entire financial liability of the Supplier (including any liability for the acts or omissions of its employees, agents and sub-contractors) to the Customer under or in connection with this agreement. </p>
                    <p>13.2	Except as expressly and specifically provided in this agreement:</p>
                    <p>(a)	the Customer assumes sole responsibility for results obtained and conclusions drawn from the use of the Services and the Documentation by the Customer.  The Supplier shall have no liability for any damage caused by errors or omissions in any information, instructions provided to the Supplier by the Customer in connection with the Services, or any actions taken by the Supplier at the Customer's direction;</p>
                    <p>(b)	all warranties, representations and all other terms of any kind whatsoever implied by statute or common law are, to the fullest extent permitted by applicable law, excluded from this agreement; and</p>
                    <p>(c)	the Services and Documentation as provided to the Customer on an “as is” basis.</p>
                    <p>13.3	Nothing in this agreement excludes the liability of the Supplier:</p>
                    <p>(a)	for death or personal injury caused by the Supplier's negligence; or</p>
                    <p>(b)	for fraud or fraudulent misrepresentation. </p>
                    <p>13.4	Subject to clause 13.2 and clause 13.3:</p>
                    <p>(a)	the Supplier shall not be liable whether in tort, contract, misrepresentation or otherwise for any loss of business, loss or corruption of data, or for any indirect or consequential loss, costs or damages however arising under this agreement; and</p>
                    <p>(b)	the Supplier's total aggregate liability in contract, tort (including negligence or breach of statutory duty), misrepresentation or otherwise, arising in connection with the performance or contemplated performance of this agreement shall be limited to the total Subscription Fees paid for the User Subscriptions during the 18 months immediately preceding the date on which the claim arose.</p>

                    <h4>14.	TERM AND TERMINATION</h4>
                    <p>14.1	This agreement shall, unless otherwise terminated as provided in this clause 14, commence on the Effective Date and shall continue for the Initial Subscription Term and, thereafter, this agreement shall be automatically renewed for successive periods of the same length as the Initial Subscription Term (each a Renewal Period), unless: </p>
                    <p>(a)	in the case of annual subscriptions, either party notifies the other party of termination, in writing, at least 60 days before the end of the Initial Subscription Term or any Renewal Period, in which case this agreement shall terminate upon the expiry of the applicable Initial Subscription Term or Renewal Period; </p>
                    <p>(b)	in the case of monthly subscriptions, either party notifies the other party of termination, in writing, prior to the end of the Initial Subscription Term or any Renewal Period, in which case this agreement shall terminate upon the expiry of the applicable Initial Subscription Term or Renewal Period; or</p>
                    <p>(c)	otherwise terminated in accordance with the provisions of this agreement;</p>
                    <p>and the Initial Subscription Term together with any subsequent Renewal Periods shall constitute the Subscription Term.</p>
                    <p>14.2	Without prejudice to any other rights or remedies to which the parties may be entitled, either party may terminate this agreement without liability to the other if: </p>
                    <p>(a)	the other party commits a material breach of any of the terms of this agreement and (if such a breach is remediable) fails to remedy that breach within 21 days of that party being notified in writing of the breach; or</p>
                    <p>(b)	an order is made or a resolution is passed for the winding up of the other party, or circumstances arise which entitle a court of competent jurisdiction to make a winding-up order in relation to the other party; or</p>
                    <p>(c)	an order is made for the appointment of an administrator to manage the affairs, business and property of the other party; or</p>
                    <p>(d)	a receiver is appointed of any of the other party's assets or undertaking; or</p>
                    <p>(e)	the other party ceases to trade; or</p>
                    <p>(f)	the other party takes or suffers any similar or analogous action in any jurisdiction in consequence of debt.</p>
                    <p>14.3	On termination of this agreement for any reason:</p>
                    <p>(a)	all licenses granted under this agreement shall immediately terminate; </p>
                    <p>(b)	each party shall return and make no further use of any equipment, property, Documentation and other items (and all copies of them) belonging to the other party;</p>
                    <p>(c)	the Supplier shall make available a copy of the Customer Data via a secure FTP site for a period of 7 days following the effective date of termination (upon expiry of which the Customer Data shall be deleted); and</p>
                    <p>(d)	the accrued rights of the parties as at termination, or the continuation after termination of any provision expressly stated to survive or implicitly surviving termination, shall not be affected or prejudiced.</p>

                    <h4>15.	FORCE MAJEURE</h4>
                    <p>The Supplier shall have no liability to the Customer under this agreement if it is prevented from or delayed in performing its obligations under this agreement, or from carrying on its business, by acts, events, omissions or accidents beyond its reasonable control, including, without limitation, default of suppliers or sub-contractors, provided that the Customer is notified of such an event and its expected duration.</p>

                    <h4>16.	WAIVER</h4>
                    <p>A waiver of any right under this agreement is only effective if it is in writing and it applies only to the party to whom the waiver is addressed and to the circumstances for which it is given.</p>
                    <h4>17.	SEVERANCE</h4>
                    <p>17.1	If any provision (or part of a provision) of this agreement is found by any court or administrative body of competent jurisdiction to be invalid, unenforceable or illegal, the other provisions shall remain in force.</p>
                    <p>17.2	If any invalid, unenforceable or illegal provision would be valid, enforceable or legal if some part of it were deleted, the provision shall apply with whatever modification is necessary to give effect to the commercial intention of the parties.</p>

                    <h4>18.	ENTIRE AGREEMENT</h4>
                    <p>18.1	This agreement, and any documents referred to in it, constitute the whole agreement between the parties and supersede any previous arrangement, understanding or agreement between them relating to the subject matter they cover.</p>
                    <p>18.2	Each of the parties acknowledges and agrees that in entering into this agreement it does not rely on any undertaking, promise, assurance, statement, representation, warranty or understanding (whether in writing or not) of any person (whether party to this agreement or not) relating to the subject matter of this agreement, other than as expressly set out in this agreement.</p>

                    <h4>19.	ASSIGNMENT</h4>
                    <p>19.1	The Customer shall not, without the prior written consent of the Supplier, assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations under this agreement.</p>
                    <p>19.2	The Supplier may at any time assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations under this agreement.</p>

                    <h4>20.	NO PARTNERSHIP OR AGENCY</h4>
                    <p>Nothing in this agreement is intended to or shall operate to create a partnership between the parties, or authorise either party to act as agent for the other, and neither party shall have the authority to act in the name or on behalf of or otherwise to bind the other in any way.</p>

                    <h4>21.	THIRD PARTY RIGHTS</h4>
                    <p>This agreement does not confer any rights on any person or party (other than the parties to this agreement and, where applicable, their successors and permitted assigns) pursuant to the Contracts (Rights of Third Parties) Act 1999.</p>

                    <h4>22.	NOTICES</h4>
                    <p>22.1	Any notice required to be given under this agreement shall be in writing and shall be delivered by hand or sent by pre-paid first-class post or recorded delivery post to the other party at its address set out in this agreement, or such other address as may have been notified by that party for such purposes, or sent by fax to the other party's fax number as set out in this agreement.</p>
                    <p>22.2	A notice delivered by hand shall be deemed to have been received when delivered (or if delivery is not in business hours, at 9 am on the second business day following delivery). A correctly addressed notice sent by pre-paid first-class post or recorded delivery post shall be deemed to have been received at the time at which it would have been delivered in the normal course of post. A notice sent by fax shall be deemed to have been received at the time of transmission (as shown by the timed printout obtained by the sender).</p>

                    <h4>23.	VARIATION</h4>
                    <p>No variation of this agreement shall be effective unless it is in writing and signed by the parties (or their authorised representatives).</p>
                    <h4>24.	GOVERNING LAW AND JURISDICTION</h4>
                    <p>24.1	This agreement and any disputes or claims arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) are governed by, and construed in accordance with, the law of England.</p>
                    <p>24.2	The parties irrevocably agree that the courts of England have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with this agreement or its subject matter or formation (including non-contractual disputes or claims).</p>
                    <br />
                    <p>This agreement has been entered into on the date we provide you with access to the service or the date on which your Subscription is renewed, as applicable.</p>
                    <p>Schedule 1 – Data processing</p>
                    <p><b>Part 1 - Processing, personal data and data subjects</b></p>
                    <p><b>1.	Processing by the Supplier</b></p>
                    <p>a.	Scope</p>
                    <div className="pl-20p">
                        <p>•	Storage of documents</p>
                        <p>•	Creation, modification and deletion of database records </p>
                        <p>•	Backup of stored documents and database records</p>
                        <p>•	Sending / receiving emails</p>
                        <p>•	Sending / receiving information to customers systems such as but not limited to Active Directory, SCCM</p>
                    </div>

                    <p>b.	Purpose</p>
                    <p>Performance of the Supplier’s obligations under this Agreement.</p>

                    <p>c.	Duration</p>
                    <p>Processing will be undertaken for the term of this Agreement plus 7 days (after which the Customer Data will be deleted unless extracted by the Client in accordance with clause 14.3(c).</p>

                    <p><b>2.	Types of personal data</b></p>
                    <p>The types of personal data to be processed by the Supplier in the course of providing the Services are as follows:</p>
                    <div className="pl-20p">
                        <p>•	User id, password and email address</p>
                        <p>•	Other types of data as uploaded or entered into the system by the Customer</p>
                    </div>
                    <p><b>3.	Categories of data subject</b></p>
                    <p>The categories of data subject in respect of which the Supplier shall process personal data are as follows:</p>
                    <p>Such categories of data subject as the Customer shall determine through its use of the Services.</p>

                    <br />
                    <p><b>Part 2 – Approved sub-processors</b></p>
                    <table className="termsTable" cellPadding={10} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th>Sub-processor details (Legal name, trading name, registered office address)</th>
                                <th>Country/territory were data processing will occur (Location of sub-processor’s premises or datacentres)</th>
                                <th>Nature of processing activities undertaken by sub-processor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <p>Microsoft Limited</p> 
                                    <p>Company no: 01624297</p> 
                                    <p>Registered office:</p>
                                    <p>Microsoft Campus</p>
                                    <p>Thames Valley Park</p>   
                                    <p>Reading</p>
                                    <p>Berkshire</p>
                                    <p>RG6 1WG</p>
                                </td>
                                <td>
                                    <p>Microsoft Azure Data Centre - United Kingdom South</p>
                                </td>
                                <td>
                                    <p>Hosting services; back-up services.</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
            </Container>
        </>
    )
}