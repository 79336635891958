import { Box, Typography, Tooltip, Grid, Button } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Suspense, memo, useCallback, useEffect, useMemo, useState } from "react";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from "@mui/icons-material/Save";
import UserIcon from "../../../assest/icons/user.png";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../redux/reducers/rootReducers";
import { getContactByIdAction } from "../../../redux/actions/ContactManagement/Contact";
import { getTicketByID } from "../../../redux/actions/TicketsManagement/TicketsManagement";
import CustomInput from "../../common/textField/CustomInput";
import CustomFormLabel from "../../common/form-label/CustomFormLabel ";
import CommonAddAttachment from "../../common/CommonAddAttachment";
import { AttachmentItem } from "../../SelfServicePortal/NewTicket";
import WhatsappSkeleton from "./WhatsappSkeleton";
import { postWhatsAppOnTicket } from "../../../redux/actions/AssetsManagement/Assets";
import CommonTextArea from "../../common/textarea/CommonTextarea";

const WhatsAppModal = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const ticketInfo = useSelector((state: IState) => state?.TicketViewReducer?.whatsApp);
    //const AllTicketsById = useSelector((state: IState) => state?.TicketViewReducer?.allTicketsById);
    const [files, setFiles] = useState<any>([]);
    const [msg,setMsg] = useState('')
    const [isOpenAddContact,setIsOpenAddContact] = useState(false);
    console.log('WhatsAppModal_ticketInfo',ticketInfo?.ticketLogs?.contactVal?.whatsAppNumber)
    useEffect(()=>{
        setTimeout(()=>{
            setIsOpenAddContact(ticketInfo?.id ? true : false)
        },10)
    }, [ticketInfo?.id])
    const onCloseHandler = useCallback(() => {
        dispatch({
            type: "WHATSAPP_POPUP_INFO",
            payload: null
        })
        setMsg('')
        setFiles([])
    }, [])
    const imageUploadCB = useCallback((cbFiles: any[]) => {
        console.log('imageUploadCB', cbFiles)
        setFiles([...files, ...cbFiles])
    }, [files])
    const attachmentDeleteHandler = useCallback((id: number) => {
        const filteredArr = files.filter((i: any) => i.id !== id);
        setFiles([...filteredArr]);
    }, [files]);
    const onSubmit = () => {
        console.log('onSubmitonSubmitonSubmit',msg)
        //onCloseHandler();
        if(!ticketInfo?.ticketLogs?.contacts?.whatsAppNumber) return;
        let Attachment = files.map((i: any) => {
            let tempObj = { AttachmentId: i.id, File: { FilePath: i.filePath } }
            return tempObj
        })
        console.log("Attachment", Attachment)
        const request: any = {
            sourceCode: "Phone",
            ticketLogs: {
                ticketId: ticketInfo?.id,
                from: "",
                to: ticketInfo?.ticketLogs?.contactVal?.whatsAppNumber,//contact's whatsapp number
                body: msg, //message
                sourceCode: "Phone",
                outgoingSource: "WhatsApp",
                type: "Outgoing",
                TicketLogAttachmentsList: Attachment
            }
        }
        dispatch(postWhatsAppOnTicket(request, ticketInfo?.id, onCloseHandler));
    }
    const onchangeInput = (e:any) =>{
        setMsg(e?.target?.value)
    }
    const formSection = useMemo(() => {
        return (<>
            <CustomFormLabel
                labelName={"Message:"}
                isMandotary={true}
            />
            <CommonTextArea
                multiline={true}
                onChange={onchangeInput}
                value={''}
            />
        </>)
    }, [])
    const addFiles = useMemo(() => <CommonAddAttachment callback={imageUploadCB} />, [])
    const filesList = useMemo(() => <>
        {files && (
            <Box className={`${classes.row} ${classes.flxWrap} `}>
                {files.map((i: any, index: number) => {
                    return (
                        <>
                            {/* <img src={`${i.filePath}`}  height={50}/> */}
                            <Box className="col-2 p-3p" key={index}>
                                <AttachmentItem
                                    fileData={i}
                                    callback={() => { attachmentDeleteHandler(i.id) }}
                                />
                            </Box>
                        </>
                    );
                })}
            </Box>
        )}
    </>, [files])
    const viewContent = useMemo(() => <>
        {ticketInfo?.id &&
            <Box className={classes.popupHolder}>
                <Box className="modal-dialog">
                    <Box className="modal-content">
                        <Box className="modal-header">
                            <Typography variant="h6" className="txtCapitalize">
                                <span className={classes.cardTcktCount}>
                                    <span className="dots whiteBG"></span>
                                    <span>{ticketInfo.id}</span>
                                    <span className="dots whiteBG dots-right"></span>
                                </span>
                                &nbsp;&nbsp;
                                <span>Send WhatsApp message</span>
                                &nbsp;&nbsp;
                            </Typography>
                            <Typography className="cursor-pointer" onClick={onCloseHandler}>
                                <CloseIcon className="cursor-pointer" />
                            </Typography>
                        </Box>
                        <Box className="p-1">
                            {isOpenAddContact ? <>
                                {formSection}
                                <Box>
                                    {addFiles}
                                    {filesList}
                                </Box>
                            
                            <Box className="text-center">
                                <Button
                                    className={`${classes.btn} ${classes.btnCancel}`}
                                    variant="outlined"
                                    onClick={onCloseHandler}
                                >
                                    <CancelIcon style={{ marginRight: "5px" }} />
                                    Cancel
                                </Button>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <Button
                                    className={`${classes.btn} ${classes.btnSubmit}`}
                                    variant="contained"
                                    onClick={onSubmit}
                                >
                                    <SaveIcon style={{ marginRight: "5px" }} />
                                    Send
                                </Button>
                            </Box>
                            </> : <>
                            <WhatsappSkeleton />
                            </>}

                        </Box>
                    </Box>
                </Box>
            </Box>
        }
    </>, [ticketInfo?.id,files,isOpenAddContact])
    return (<>
        {viewContent}
    </>)
})
export default WhatsAppModal;
const useStyles = makeStyles(() =>
    createStyles({
        row: {
            display: "flex"
        },
        flxWrap: {
            flexWrap: "wrap",
        },
        popupHolder: {
            position: "fixed",
            top: 0,
            left: 0,
            background: "#0005",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            zIndex: 1399,
            "& .modal-dialog": {
                display: "flex",
                height: '100%',
                justifyContent: "center",
                alignItems: "center",
            },
            "& .modal-header": {
                display: "flex",
                alignItems: "center",
                padding: "20px",
                justifyContent: "space-between",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px"
            },
            "& .modal-body": {
                maxHeight: "calc(100vh - 200px)",
                overflowY: "scroll",
                padding: "0px"
            },
            "& .modal-body > div": {
                margin: "0 !important",
                maxWidth: "calc(100% - 15px)"
            },
            "& .modal-content": {
                background: "#fff",
                width: "100%",
                maxWidth: "1000px",
                borderRadius: "5px",
                //padding: "20px",
                "& img.Contactprofile": {
                    width: "calc(100% - 10px)",
                },
                "& img": {
                    objectFit: "cover"
                }
            }
        },
        btn: {
            margin: "10px",
            lineHeight: "24px",
            fontSize: "13px",
            fontWeight: 400,
            color: "#ffffff",
            padding: "5px",
            width: "100px",
            "@media screen and (max-width:980px)": {
                padding: "5px 9px",
                fontSize: "9px",
                width: "auto",
                lineHeight: "18px",
                minWidth: "auto",
                "& svg": {
                    height: ".6em",
                },
            },
        },
        btnCancel: {
            color: "#6989fa",
            width: "110px",
            backgroundColor: "white",
            border: "2px solid #6989fa",
            marginRight: "10px",
            "&:hover": {
                backgroundColor: "white",
            },
        },
        btnSubmit: {
            color: "#ffffff !important",
            minWidth: "110px !important",
            width: "auto",
            whiteSpace: 'nowrap',
            // backgroundColor: "#1976d2 !important",

        },
        cardTcktCount: {
            padding: "5px 10px",
            background: "#ffa000",
            display: "inline",
            lineHeight: "1",
            fontSize: "12px",
            position: "relative",
            "& .dots": {
                position: "absolute",
                width: "8px",
                height: "8px",
                background: '#fff',//mode.cardHeader,
                left: "-5px",
                borderRadius: "50%",
                top: "50%",
                transform: "translateY(-50%)",
            },
            "& .dots.dots-right": {
                left: "unset",
                right: "-5px",
            },
            "& .dots.whiteBG": {
                background: "#fff" //mode.whiteSolid
            },
        },
        textWithSpanTitle: {
            "& .title-txts": {
                lineHeight: '1.5',
                marginBottom: 10,
                fontSize: '13px',
                "& > span": {
                    display: 'block',
                    fontSize: '11px',
                    marginBottom: '2px',
                    lineHeight: '1',
                    color: "#5559"//mode.link,
                }
            }
        },
        txtWithLink: {
            fontSize: ".7em",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            "& span": {
                wordBreak: "break-all"
            },
            "& a": {
                fontSize: ".7em",
                textDecoration: "none",
                display: "inline-block",
                lineHeight: 1,
                "& svg": {
                    fontSize: "12px"
                }
            }
        }
    }))