import { Box } from '@mui/material'
import React from 'react'
import { ITicket } from '../../../../../redux/actions/TicketsManagement/TicketsManagement'
import { NoDataImg } from '../../../../common/svgIcons/chartIcon';
import TaskList from '../../../../Dashboard/Tasks/TaskList';
interface IMobileTasksListProps{
    tasks?:ITicket[];
    callback:()=>void;
}
function MobileTasksList(props:IMobileTasksListProps) {
    const {tasks=[],callback} = props;
    const taskUpdateCB = () =>{
        callback()
    }
  return (
        <Box>
            <TaskList callback={taskUpdateCB} taskList={tasks} />
            {tasks?.length === 0 && <NoDataImg />}
        </Box>
    );
}

export default MobileTasksList
