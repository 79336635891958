import React from "react";
import { CircularProgress } from "@mui/material";

export const AppLoader = (props: any) => {
  const { enabled } = props;
  return (
    <>
      {enabled && (
        <div className="app-loader">
          <CircularProgress />
        </div>
      )}
    </>
  );
};
export default AppLoader;
