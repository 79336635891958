import { Box,  Checkbox,  Grid, IconButton, Tooltip, Typography } from "@mui/material";
import Breadcrumbs from "../../common2/Breadcrumbs";
import TopFilter from "../../common2/TopFilter";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../redux/reducers/rootReducers";
import { getAllSSOConfig, multipalUpdateSSOConfig, softDeleteSSOConfig  } from "../../../redux/actions/adminInterfaces/SSOConfig";
import AddNewPart from "../../common/AddNew";
import Card from "../../common/card";
import CommonStyles from "../../common/CommonStyles";
import intl from "react-intl-universal";
import editIcon from "../../../assest/icons/edit.svg";
import restoreIcon from "../../../assest/icons/restore.svg";
import deleteIcon from "../../../assest/icons/delete.svg";
import { useNavigate } from "react-router-dom";

const SSOConfig = () => {
    const classes=CommonStyles()
    const [data, setData] = useState<any>([]);
    const [records, setRecords] = useState([])
    const dispatch = useDispatch()
    const navigate =useNavigate()
    const [group, setGroup] = useState([]);
    const [searchData, setSearchData] = useState("");
    const [listStatus, setlistStatus] = useState(true);
    const [checked, setChecked] = useState(false);
    const [activeSSO, setActiveSSO] = useState([])
    const SSOConfigDataList = useSelector((state: IState) => state?.SSOConfigReducer?.Data);
    const filterHandler = (e: any) => { };
    const checkBoxHandler = (e: any, id: any) => {
        setChecked(e.target.checked);
    };
    const showDataHandler = (show: any) => {
        setlistStatus(show);
        if (show) {
            setUpdate(false);
        }
        if (show === false) {
            setUpdate(true);
        }
        // console.log("records",records);

    };
    const handleSearch = (e: any) => {
        let lowerCaseValue = e.target.value;
        setSearchData(lowerCaseValue);
    };
    const [update, setUpdate] = useState(false);
    const checkPermission: any = (valueKey: string) => {
        let val: boolean = permissionInfo[valueKey] ? true : false
        return val;
    }
    const [permissionInfo, setpermissionInfo] = useState({} as any);
    const rolesAccess = useSelector((state: IState) => state?.LoginReducer?.roleAccess);
    useEffect(() => {
        if (rolesAccess) {
            console.log('rolesAccess.OrganisationAzureActiveDirectiory', rolesAccess.OrganisationAzureActiveDirectiory)
            setpermissionInfo({ ...rolesAccess.OrganisationAzureActiveDirectiory, isSuperAdmin: rolesAccess.isSuperAdmin })
        }
    }, [rolesAccess]);
    useEffect(() => {
        dispatch(getAllSSOConfig(getSSOConfigCb))
    }, [])
    const getSSOConfigCb = (data: any) => {
        console.log('data---', data)
        setData(data.map((i: any) => {
            return i;
        }));
        setRecords(data)
    }
    const updateAllHandler = (value: any, id: any) => { //confusing about operation happening
        let arrayids: any[] = [];
        data.forEach((d: any) => {
            if (d.isActive) {
                arrayids.push(d.id);
            }
            if (d.isActive === false) {
                arrayids.push(d.id);
            }
        });
        dispatch(multipalUpdateSSOConfig({ ...value }, arrayids, update));
    };
    const deleteHandler = (val: string) => {
        console.log("listStatus>>>>>", listStatus);
        const id = parseInt(val);
        console.log("delete id", id)
        dispatch(softDeleteSSOConfig(id, !listStatus));
    };
    const EditHandler = (val: string) => {
        console.log("editId", val);
        const editVal = records.filter((x: any) => x.id === val);
        navigate(`/AddSSOConfig/${val}`);
    };
    const activeHandler = (type: any, value: any, id: any) => {
        dispatch(multipalUpdateSSOConfig({ ...value }, id, true));
    };
    useEffect(() => {
        if (data) {
            toggleActiveList(listStatus);
        }
        if (data && data.length > 0) {
            let activeSSO = data.filter((x: any) => x.isActive);
            setActiveSSO(activeSSO.length);
            console.log("activeSSO", activeSSO)
        }
    }, [data]);
    const toggleActiveList = (type: boolean) => {
        const filtered = SSOConfigDataList.filter((a: any) => a.isActive === type);
        setRecords(filtered);
    };
    useEffect(() => {
        if (SSOConfigDataList && SSOConfigDataList.length > 0) {
            const re = new RegExp(searchData, "i");
            const filtered = SSOConfigDataList.filter(
                (entry: any) =>
                    entry.isActive === listStatus &&
                    Object.values(entry).some(
                        (val) => typeof val === "string" && val.match(re)
                    )
            );
            console.log("filtered>>>>", filtered, data, !listStatus);
            setRecords(filtered);
        }
    }, [searchData, listStatus]);
    return (
        <>
            <Breadcrumbs
                data={[
                    {
                        title: 'Interfaces',
                        path: '/adminPanel'
                    },
                    {
                        title: 'SSO Config ',
                        path: '/SSOConfig'
                    }
                ]
                }
            />
            <Box className="pageHolder">
                <TopFilter
                    path="/AddSSOConfig"
                    Data={SSOConfigDataList}
                    SearchHandler={handleSearch}
                    value={searchData}
                    filterHandler={filterHandler}
                    checkBoxHandler={checkBoxHandler}
                    checked={checked}
                    hideActiveBtns={false}
                    hideSelectAllOption={activeSSO?.length > 0 ? true : false}
                    filterItems={showDataHandler}
                    updateAllHandler={updateAllHandler}
                    //bulkDeleteHandler={bulkDeleteHandler}
                    titleState={update}
                    temp={activeSSO}
                    hideAddBtn={checkPermission('Create') || checkPermission('isSuperAdmin') ? false : true}
                    placeHolder="Search ..."
                />
                <Grid container className="pt-1" spacing={1}>
                    {records && records.length > 0 ? (
                        <>
                            {records.map((item: any, key: number) => {
                                const { id, clientId, tenantId, name, isActive } = item;
                                const type = [
                                    `${intl.get("Delete")}`,
                                    `${intl.get("Active")}`,
                                    `${intl.get("In-Active")}`,
                                ];
                                return (
                                    <>
                                        {listStatus === isActive &&
                                            <Grid item md={3} xs={12} key={key}>
                                                <Card
                                                    styleProp={classes.cardStyle}
                                                    cardHeaderTitle1={
                                                        <Typography variant="body2" className={classes.title}>
                                                            Name : {name}
                                                        </Typography>
                                                    }
                                                    cardHeaderTitle2={
                                                        <Box style={{ display: "flex", alignItems: "center" }}>
                                                            {checked ? (
                                                                <Checkbox
                                                                    size="small"
                                                                    color="primary"
                                                                    checked={checked}
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Box>
                                                    }
                                                    cardBody={
                                                        <Box className="listed-cards">
                                                            <Typography variant="body1">
                                                                tenant Id : {tenantId}
                                                            </Typography>
                                                            <Typography variant="body1">
                                                                Client Id : {clientId}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    cardFooter={
                                                        <Box >
                                                          {permissionInfo && (checkPermission('ReActivate') || checkPermission('isSuperAdmin')) && !isActive && (
                                                            <Tooltip title={`${intl.get("Active")}`}>
                                                              <IconButton
                                                                size="small"
                                                                onClick={(e: any) =>
                                                                  activeHandler(type, item, item.id)
                                                                }
                                                              >
                                                                <img src={restoreIcon} />
                                                              </IconButton>
                                                            </Tooltip>
                                                          )}
                                                            {permissionInfo && (checkPermission('Edit') || checkPermission('isSuperAdmin')) && isActive && (
                                                                <Tooltip title={`${intl.get("Edit")}`}>
                                                                    <IconButton
                                                                        disabled={isActive ? false : true}
                                                                        size="small"
                                                                        onClick={() => {
                                                                            EditHandler(id);
                                                                        }}
                                                                    >
                                                                        <img src={editIcon} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )
                                                            }
                                                          {permissionInfo && (checkPermission('DeActivate') || checkPermission('isSuperAdmin')) && isActive && (
                                                                <Tooltip title={`${intl.get("In-Active")}`}>
                                                                    <IconButton
                                                                        disabled={isActive ? false : true}
                                                                        size="small"
                                                                        className="cardIconBtn"
                                                                        onClick={() => {
                                                                            deleteHandler(id);
                                                                        }}
                                                                    >
                                                                        <img src={deleteIcon} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                          )}
                                                        </Box>
                                                    }
                                                />
                                            </Grid>
                                        }
                                    </>
                                )
                            })
                            }
                        </>
                    ) :
                        (
                            <>
                                <AddNewPart title="Add New" path="/AddSSOConfig" />
                            </>
                        )
                    }
                </Grid>
            </Box>
        </>
    )
}
export default SSOConfig
