import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getRole } from "../../../redux/actions/userManagement/role";
import { IState } from "../../../redux/reducers/rootReducers";
import { TextBoxLatest } from "../../common/TextBoxLatest/TextBoxLatest";
import CustomSelect from "../../common/customSelect/CustomSelect";
import CustomFormLabel from "../../common/form-label/CustomFormLabel ";
import Breadcrumbs from "../../common2/Breadcrumbs";
import ChatItem from "./ChartItem";
import { addWallboard, editWallboard, getWallboardById } from "../../../redux/actions/Wallboard/Wallboard";
import CancelIcon from "@mui/icons-material/Cancel";
import CommonStyles from "../../common/CommonStyles";

const CreateWallBoard = () => {
    const nameRef: any = createRef();
    const navigate = useNavigate()
    const classes = CommonStyles()
    const [editVal, seteditVal] = useState<any>(null);
    const [rolesVal, setRolesVal] = useState<any>(null)
    const [RolesList, setRolesList] = useState([])
    const roleData = useSelector((state: IState) => state.roleReducer?.Data);
    const wallboardTypeList = useSelector((state: IState) => state.WallboardReducer?.wallboardTypeList);
    const param = useParams()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getRole());
    }, []);
    useEffect(() => {
        if (roleData?.data) {
            let temp: any = []
            roleData.data?.filter((item: any) => {
                if (item.isActive) {
                    let a: any = {
                        label: `${item.name}`,
                        name: item.name,
                        value: item.id,
                        id: item.id,
                        isActive: item.isActive,
                        isSuperAdmin: item.isSuperAdmin,
                    };
                    temp.push(a);
                }
            });
            setRolesList(temp);
        }
    }, [roleData]);

    const cancelHandler = () => {
        navigate('/WallBoard')
    }
    const onSubmit = () => {
        const modifiedData = wallboardTypeList.map((ele: any) => {
            return { id: ele.id, chartType: ele.chartType, value: ele.data.datasets[0]?.label, isActive: ele.isActive }
        })
        console.log("modifiedData", modifiedData)
        const payload = {
            "name": nameRef?.current?.value || "",
            "roleId": rolesVal?.id || null,
            "wallBoardTypeList": modifiedData
        }
        if (param.id) {
            console.log("onSubmit_____", payload);
            dispatch(editWallboard(param.id, payload, submitCB))
            return;
        }
        console.log("onSubmit_____", payload);
        dispatch(addWallboard(payload, submitCB))
    }
    const submitCB = (resStatus: string, res: any) => {
        console.log("submitCB", resStatus, res)
        if (resStatus === "0") {
            cancelHandler()
        }
    }
    useEffect(() => {
        if (!param?.id) return;
        dispatch(getWallboardById(param.id, getWallboardByIdCB))
    }, [param?.id])
    const getWallboardByIdCB = (resStatus: string, res: any) => {
        console.log("getWallboardByIdCB", res, resStatus)
        if (resStatus === "0") {
            // initVal.category= res?.category || null;
            seteditVal(res)
        }
    }
    useEffect(() => {
        if (!editVal) return;
        const { name, roles } = editVal;
        if (nameRef?.current) nameRef.current.value = name;
        if (editVal?.roles?.id && editVal?.roles?.id !== 0) {
            const rolesObj = {
                label: editVal.roles.name,
                name: editVal.roles.name,
                value: editVal.roleId,
                id: editVal.roleId
            };
            setRolesVal(rolesObj);
        } else {
            setRolesVal(null);
        }
    }, [editVal])

    return (
        <>
            <Box className="p-1">
                <Breadcrumbs
                    data={[
                        {
                            title: 'Reports Management ',
                            path: '/ReportsManagement'
                        },
                        {
                            title: 'WallBoard',
                            path: '/WallBoard'
                        },
                        {
                            title: `${param?.id ? "Edit" : "Add"} Wallboard`,
                            path: `${param.id ? "/CreateWallBoard/" + param.id : "/CreateWallBoard"}`
                        }
                    ]
                    }
                />
                <Box className="white-bg p-2 mt-1 add-article">
                    <Typography>
                        {param?.id ? "Edit Wallboard" : "Add New Wallboard"}
                    </Typography>
                    <hr />
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <TextBoxLatest
                                multiline={false}
                                placeholder="Enter Name"
                                title={"Name"}
                                onchangeHandler={() => {
                                    // if(state?.errors?.name){unsetErr("name")}
                                }}
                                ref={nameRef}
                                isMandotary={true}
                                errorMsg={`Please Enter`}
                                isError={false}
                                name='name'
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CustomFormLabel
                                labelName={"Select Role"}
                                isMandotary={true}
                                classNames='sml-txt'
                            />
                            <CustomSelect
                                isOpen={false}
                                placeholder="Select Type"
                                width={"100%"}
                                name="typeId"
                                isColor={false}
                                isDisabled={false}
                                isError={false}
                                helperText={`Role is required.`}
                                defaultValue={rolesVal || null}
                                options={RolesList}
                                handleChange={(e: any) => {
                                    setRolesVal(e)
                                }}
                                isMulti={false}
                                customClassNames="sml-txt-dropdown new-service"
                            />
                        </Grid>
                        <hr />

                        {((param?.id && editVal) || !param?.id) &&
                            <Grid item md={12} xs={12} >
                                <ChatItem editWallboradList={editVal?.wallBoardTypeList} />
                            </Grid>}

                        <Grid item xs={12}>
                            <Box className="text-center pt-4">
                                <Button
                                    className={`${classes.btn} ${classes.btnCancel}`}
                                    variant="contained"
                                    onClick={cancelHandler}
                                >
                                    <CancelIcon style={{ marginRight: "5px" }} />
                                    Cancel
                                </Button> &nbsp; &nbsp; &nbsp;
                                <Button variant='contained' onClick={onSubmit}>
                                    {param?.id ? "Update" : "Submit"}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default CreateWallBoard;
