import { Box, Button, Container } from '@mui/material';
import React, { useState } from 'react';
import "./CustomReport.css";
import CSVLoader from './CSVLoader';
import { reportTicketLoad } from '../../redux/actions/TicketsManagement/TicketsManagement';
import { IState } from '../../redux/reducers/rootReducers';
import { connect } from 'react-redux';
import CustomDialog from '../common/dialog/CustomDialog';
const CreateCustomReport = ({ allTickets = [], dispatch }: { allTickets: any, dispatch: any }) => {
    const loadTickets = React.useCallback(() => {
        dispatch(reportTicketLoad(500))
    }, []);
    const loader = React.useMemo(() => {
        return <CSVLoader />
    }, [])


    const addCallback = (val:any)=> {console.log(val)};
    return (
        <>
            <button onClick={loadTickets}>Load Tickets</button>
            <button onClick={() => {dispatch({ type: "STORE_TICKETS_FOR_REPORT", payload: [] })}}>reset</button>
            {loader}
            <Container className='ml-0'>
                <Box className="report-builder">
                    <h1>Custom Report</h1>
                    {allTickets?.length}


                    <AddReportItem callback={addCallback}/>
                </Box>
            </Container>
        </>
    )
}


const AddReportItem = (props:any) =>{
    const { callback } = props;
    const [show,setShow] = useState(false);
    return(
        <Box>
            <Button onClick={()=>setShow(true)} className='add-report-item'>Add Item</Button>
            {show &&
                <CustomDialog
                    heading={`Add Chart`}
                    body={
                        <>
                            CustomDialog    
                        </>
                    }
                    open={true}
                    handleClose={()=>setShow(false)}
                    footer={<></>}
                />
            }
        </Box>
    )
}

const mapStateToProps = (state: IState) => {
    return ({
        allTickets: state?.AllTicketsReducer?.allTickets,
    })
};

export default connect(mapStateToProps)(CreateCustomReport);