import { Popover } from '@mui/material';
export interface IPopover {
    id: string|undefined;
    open: boolean;
    anchorEl:any;
    bodyContent?: React.ReactNode;
    handleClose: () => void;
}
const CommonPopover =(props:IPopover)=>{
const {id,open,anchorEl,handleClose,bodyContent}=props;
const onClose = () =>{
    handleClose();
}
return (
    <>
        <Popover 
             id={id}
             open={open}
             anchorEl={anchorEl}
             onClose={onClose}
             anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'left',
             }}
            >
            {bodyContent}
        </Popover>    

    </>
)
}
export default CommonPopover