import { Box, Divider } from '@mui/material';
import { connect } from 'react-redux';
import { storeAddUpdateInfo, toggleEmailPopup } from '../../../redux/actions/TicketsManagement/MobileTicketActions';
import { IState } from '../../../redux/reducers/rootReducers';
import BottomDrawer from '../MobileCommon/BottomDrawer';
import FormWrapper from './FormWrapper';
import MobileDropdown from '../MobileCommon/MobileDropdown';
import { MobileUserIconOrange } from '../../common/svgIcons/chartIcon';
import { createRef, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { TextBoxLatest } from '../../common/TextBoxLatest/TextBoxLatest';
import EffortField from './EffortField';
import OrgMobileDropdown from '../MobileCommon/OrgDropdown';
import { getTicketsByIdFunc, updateTicket } from '../../../services/dashboard/dashboard.service';
import MobileLoader from '../MobileCommon/MobileLoader';
import { convertToTimeSpan } from '../../../pages/new-ticket/AddUpdate';
import EmailTemplateOption from './EmailTemplateOption';
import { triggerAppAlert } from '../../../redux/actions/SelfServicePortal/ContactAid/ContactAid';
import { getAllGroup } from '../../../redux/actions/TicketsManagement/TicketsManagement';
interface IAddUpdateOptionProps {
    dispatch?:any;
    ticketId?:number | null;
    ticketStatus?:any;
    groups?:any;
    loggedInAgent?:any;
    callback?:any;
}
function AddUpdateOption(props:IAddUpdateOptionProps) {
    const {dispatch,ticketId=null,ticketStatus=[],groups=[],loggedInAgent={},callback} = props;

    const noteRef = createRef();
    const thirdPartyRef = createRef();
    console.log("AddUpdateOption",props);
    const [statusOptions,setstatusOptions] = useState<any[]>(ticketStatus.filter((x:any)=>x.name !== "New"));
    const [agentList,setAgentList] = useState<any[]>([]);
    const [ticketInfo,setTicketInfo] = useState<any>(null);
    const [loader,setLoader] = useState<boolean>(false);
    const [isThirdParty,setIsThirdParty] = useState<boolean>(false);
    
    const [statusVal,setStatusVal] = useState<any>(null);
    const [groupOptions, setGroupOptions] = useState<any>([]);
    useEffect(()=>{
        dispatch(getAllGroup(getCallback))
    },[])
    const getCallback = (resStatus: string, res: any) => {
        if (resStatus === "0") {
          setGroupOptions(res);
        }
      }
    useEffect(()=>{
        if(ticketId){
            setLoader(true);
            getTicketsByIdFunc(ticketId).then((res) => {
                const group = groups.find((x:any)=>x.id === res?.ticketLogs?.groupId) || null;
                let agent = null;
                if(group) {
                    agent = (group?.agentsList || []).find((x:any)=>x.id === res?.ticketLogs?.agentId) || null;
                    // groupChangeHandler(group);
                }
                let status =  statusOptions.find((x:any)=>x.id === res?.ticketLogs?.statusId) || null;
                if(status) statusChangeHandler(status);
                setTicketInfo({
                    ...res,
                    toPrePopulate : { group,agent,status }
                });

                console.log("getTicketsByIdFunc",{
                    ...res,
                    toPrePopulate : { group,agent,status }
                })
                setLoader(false);
                groupChangeHandler(group);
            });
        }else{
            closeAddUpdateOption();
        }
    },[ticketId])
   
    /* ===== function handlers ====== */
     const groupChangeHandler = debounce((val:any) =>{
        console.log("groupChangeHandler",val)
        setAgentList(val?.agentsList || [])
    },100)
    const agentChangeHandler = (val:any) =>{
        console.log("loggedInAgent",loggedInAgent,statusOptions)
        let temp = null;
        if(!val){
            temp = null;
        }else if(loggedInAgent?.id === val?.id){
            temp = statusOptions.find((x:any)=>x.name === "InProgress") || null;
        }else if(loggedInAgent?.id !== val?.id){
            temp = statusOptions.find((x:any)=>x.name === "NewtoMe") || null;
        }
        setStatusVal(temp);
    }
    const statusChangeHandler = (val:any) =>{
        setIsThirdParty(val?.name === "ThirdParty")
    }
    const submitAddUpdateOption = (val:any) =>{
        
        // closeAddUpdateOption();
        const {groupId=0,agentId=0,sendEmail,emailTemplateId="",statusId=0,hours="00",mins="00",contactOrganisationId=0,thirdPartyRefNo="",note="",downTimeHours="00",downTimeMins="00"} = val;
        const payload = {
            ...ticketInfo,
            ticketLogs : {
                ...ticketInfo?.ticketLogs,
                effort: convertToTimeSpan("",hours,mins),
                downtime: convertToTimeSpan("", downTimeHours, downTimeMins),  
                groupId : +groupId ? +groupId : 0,
                agentId : +agentId ? +agentId : 0,
                statusId : +statusId ? +statusId : 0,
                contactOrganisationId : +contactOrganisationId ? +contactOrganisationId : 0,
                thirdPartyRefNo
            }
        }
        if(sendEmail === "on" && emailTemplateId === ""){
            dispatch(triggerAppAlert("Please Choose Email Template","warning"))
            return;
        }
        updateTicket(ticketId, payload).then((res: any) => {
            if (res.status == 200) {
                dispatch(triggerAppAlert(`Ticket Updated Successfully. Ticket-Id : ${ticketId}`,"success"))
                if(emailTemplateId !==""){
                    dispatch(toggleEmailPopup(
                      {
                        emailTemplateId,
                        ticketId,
                      }
                    ))
                }
                if(callback) callback()
                closeAddUpdateOption();
            }
        })
        console.log("submitAddUpdateOption",payload)
    }
    const closeAddUpdateOption = () =>{
        setTicketInfo(null);
        setAgentList([])
        dispatch(storeAddUpdateInfo(null))
    }

    // console.log("ticketInfo_toPrePopulate_status",ticketInfo?.toPrePopulate?.status)
    return (
        <Box>
            {/* AddUpdateOption */}
            <BottomDrawer
                isOpened ={ticketId ? true : false}
                onClose ={closeAddUpdateOption}
                title={`Add Update ${ticketId ? "#"+ticketId : ""}`}
            >
                <>
                    <FormWrapper
                        onClose={closeAddUpdateOption}
                        onSubmit={submitAddUpdateOption}
                    >
                        <> 
                            {loader && <><MobileLoader /></>}
                            
                            {ticketInfo && 
                            <>
                                <MobileDropdown 
                                    label = "Group"
                                    placeholder = "Select a group"
                                    options={groupOptions}
                                    isRequired={true}
                                    defaultValue={ticketInfo?.toPrePopulate?.group || null}
                                    name="groupId"
                                    onChange={groupChangeHandler}
                                />
                                {agentList?.length > 0 && 
                                    <MobileDropdown 
                                        icon = {<MobileUserIconOrange />}
                                        label = "Agent"
                                        placeholder = "Select a agent"
                                        options={agentList}
                                        isRequired={true}
                                        defaultValue={ticketInfo?.toPrePopulate?.agent || null}
                                        name="agentId"
                                        onChange={agentChangeHandler}
                                    />
                                }

                                <MobileDropdown 
                                    label = "Status"
                                    placeholder = "Select a status"
                                    options={statusOptions}
                                    isRequired={true}
                                    defaultValue={ticketInfo?.toPrePopulate?.status || null}
                                    name="statusId"
                                    onChange={statusChangeHandler}
                                    dynamicValue={statusVal}
                                />
                            
                                <Box className="accordion-holder">
                                    <EffortField />
                                </Box>
                                {ticketInfo?.ticketLogs?.ticketLogAssetList?.length > 0 &&
                                    <Box className="accordion-holder">
                                        <EffortField 
                                            fieldLabel = "Downtime"
                                            fieldNames = {["downTimeHours","downTimeMins"]}
                                        />
                                    </Box>
                                }
                                {isThirdParty && 
                                    <Box className="thirdPartyBox pt-1">
                                        <OrgMobileDropdown 
                                            label = "Third Party"
                                            placeholder = "Select a Third Party"
                                            defaultValue={null}
                                            name="contactOrganisationId"
                                        />

                                        <Box className="accordion-holder">
                                            <TextBoxLatest
                                                multiline={false}
                                                placeholder="Enter Third Party Ref Number"
                                                title={"Third Party Ref Number"}
                                                ref={thirdPartyRef}
                                                isMandotary={true}
                                                errorMsg={``}
                                                isError={false}
                                                name='thirdPartyRefNo'
                                                type="text"
                                            />
                                        </Box>
                                    </Box>
                                }
                                <Box className="accordion-holder">
                                    <TextBoxLatest
                                        multiline={true}
                                        placeholder="Add a note..."
                                        title={"Note"}
                                        ref={noteRef}
                                        isMandotary={true}
                                        errorMsg={``}
                                        isError={false}
                                        name='note'
                                        type="text"
                                    />
                                </Box>
                                <Divider />
                                <EmailTemplateOption ticketStatus={statusVal} />
                                </>
                            }
                        </>
                    </FormWrapper>
                </>
            </BottomDrawer>
        </Box>
    )
}



const mapStateToProps = (state:IState) => {
    return ({
        ticketId: state?.AllTicketsReducer?.mobileAddUpdate,
        ticketStatus: state?.TicketsReducer?.allResponses?.ticketStatus,
        groups: state?.TicketsReducer?.allResponses?.groups,
        loggedInAgent : state?.agentReducer?.loggedInAgent
    })
  };
  
export default connect(mapStateToProps)(AddUpdateOption);
// export default AddUpdateOption