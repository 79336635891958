import React, { useState } from "react";
import { deleteSystemExceptionList, getSystemExceptionList } from "../../redux/actions/Login/Login";
import { useLocation, useNavigate } from "react-router-dom";
import BasicButton from "../../components/common/button/BasicButton";
import moment from "moment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Button, Typography } from "@mui/material";
import TopFilterNew from "../../components/common2/TopFilterNew";
import Tooltip from "@mui/material/Tooltip";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useDispatch } from "react-redux";

const ErrorLogs = () => {
  const [dialogBody, setDialogBody] = React.useState<any>([]);
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const org = queryParams.get("org");
  const [filterValues, setFilterValues] = React.useState({
    search: "",
    isActive: true,
  })
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getSystemExceptionListFun = async (organisationName: any) => {
    let count = 10;
    let page = 1;
    let res = await getSystemExceptionList(count, page, organisationName);
    return res;
  };

  React.useEffect(() => {
    console.log("location", org);
    getSystemExceptionListFun(org).then((res: any) => {
      if (res) {
        setDialogBody(
          res.map((item: any) => ({
            ...item,
            createdAt: moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss"), // Format createdAt field
          }))
        );
      }
    });
  }, []);
  const deleteHandler = (val: any, callback: any) => {
    const id = parseInt(val);
    dispatch(deleteSystemExceptionList(id, callback));
  };
  const refreshHandler = () => {
    getSystemExceptionListFun(org).then((res: any) => {
      if (res) {
        setDialogBody(
          res.map((item: any) => ({
            ...item,
            createdAt: moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss"), // Format createdAt field
          }))
        );
      }
    });
  };

  const backHandler = () => {
    navigate("/admin-portal");
  };
  const columns: GridColDef[] = [
    {
      field: "service",
      headerName: "Service Name",
      width: 150,
    },
    {
      field: "name",
      headerName: "API Method",
      width: 100,
    },
    {
      field: "requestUrl",
      headerName: "API URL",
      width: 210,
      cellClassName: "custom-requestUrl-cell",
    },
    {
      field: "exceptionType",
      headerName: "Type",
      width: 100,
    },
    {
      field: "exception",
      headerName: "Message",
      width: 350,
      cellClassName: "custom-exception-cell",
    },
    {
      field: "createdAt",
      headerName: "Logged On",
      width: 200,
    },
  ];
  // function CustomToolbar() {
  //   return (
  //     <GridToolbarContainer>
  //       {/* <GridToolbarColumnsButton /> */}
  //       <GridToolbarFilterButton />
  //       {/* <GridToolbarDensitySelector />
  //       <GridToolbarExport /> */}
  //     </GridToolbarContainer>
  //   );
  // }
  const filterCallback = React.useCallback((values: any) => {
    setFilterValues(values);
  }, []);
  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : dialogBody.length > 0 ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <h1 style={{ marginTop: "0px" }}>Logs</h1>
            <BasicButton
              onClick={() => refreshHandler()}
              size="small"
              label={"Refresh"}
              variant={"contained"}
              endIcon={<AutorenewIcon />}

            />
          </div>
          <TopFilterNew
            callback={filterCallback}
            backNavigationPath="/admin-portal"
            hideAddBtn={true}
            hideToggleBtns={true}
            extraFeatures={
              <>
                {rowSelectionModel.length > 0 && (
                  <>
                    <Tooltip title={`Delete Selected Items`}>
                      {/* <Button onClick={deleteTickets}> */}
                      <Button>
                        <DeleteOutlineIcon onClick={() => {
                          if (rowSelectionModel.length === 0) {
                            console.log("Select at least one item to delete.");
                          } else {
                            rowSelectionModel.forEach((item, index) => {
                              deleteHandler(item, (res: any) => {
                                if (res) {
                                  if (index === rowSelectionModel.length - 1) {
                                    refreshHandler();
                                  }
                                } else {
                                  console.log(`Failed to delete ${item}.`);
                                }
                              });
                            });
                          }
                        }} />
                      </Button>
                    </Tooltip>
                    <Typography component="span">
                      {rowSelectionModel.length} Item
                      {rowSelectionModel.length === 1 ? "" : "s"} selected.
                    </Typography>
                  </>
                )}
              </>
            }
          />
          <div style={{ height: "400px" }}>
            <DataGrid
              rows={dialogBody}
              columns={columns}
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) =>
                setRowSelectionModel(newRowSelectionModel)
              }
              rowSelectionModel={rowSelectionModel}
              disableRowSelectionOnClick
            // slots={{
            //   toolbar: CustomToolbar,
            // }}
            />
          </div>
        </>
      ) : (
        <div className="error-log-naviagte">
          <p>No logs to show</p>
        </div>
      )}
      <div className="error-log-naviagte pt-1 pb-1">
        <BasicButton
          onClick={() => backHandler()}
          size="small"
          label={"Go Back"}
          variant={"contained"}
          endIcon={<KeyboardBackspaceIcon />}
        />
      </div>
    </>
  );
};
export default ErrorLogs;
