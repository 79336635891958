const initialState = {
    Data: [],
    showLoading: false,
  };
  
  const permissionReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case "getPermission":
        return {
          ...state,
          Data: action.payload,
        };
      case "singlePermission":
        return {
          ...state,
          Data: action.payload,
        };
      case "loading":
      return {
        ...state,
        showLoading: action.payload,
      };
      default:
        return state;
    }
  };
  
  export default permissionReducer;
  