import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { KeyboardEventHandler, useEffect } from 'react';
import { StylesConfig } from 'react-select';

import CreatableSelect from 'react-select/creatable';
import { handleInputChange } from 'react-select/dist/declarations/src/utils';

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: string;
}




const createOption = (label: string) => ({
  label,
  value: label,
});



const  CustomCreatableSelect = ({width,placeholder,isMulti,handleInputChange, defaultValue,options, name}: {width: any,placeholder: any,isMulti: any,handleInputChange: any, defaultValue: any, options: any, name: any})=> {
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState<readonly Option[]>(defaultValue);
  // const classes = useStyles();

  useEffect(()=>{
handleInputChange(value)
  },[value])


  const customStyles: StylesConfig<any> = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      width: width,
      minWidth: width,
      height: "auto",
      minHeight: 53,
      
      ":hover": {
        border: "1px solid black",
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    container: (provided, state) => ({
      ...provided,
      fontFamily: "'DM Sans', sans-serif !important",
      fontSize: "14px",
    }),
    placeholder: (styles) => ({ ...styles, fontWeight: 400, color: "#c8c8c8" }),
  };
  const customStyles1: StylesConfig<any> = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "white",
      width: width,
      minWidth: width,
      height: "auto",
      minHeight: 53,
      border: "none",
      borderRadius:"0x",
      borderBottom: "0.3px solid grey",
      borderColor: state.isFocused ? 'grey !important' : 'grey !important',
      boxShadow: "none"
      // "input:focus":{
      //   border: "none",
      // },
      // ".input:hover":{
      //   border: "none",
      //   borderColor:"transparent"
      // },
      
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    container: (provided, state) => ({
      ...provided,
      fontFamily: "'DM Sans', sans-serif !important",
      fontSize: "14px",
      
    }),
    
    placeholder: (styles) => ({ ...styles, fontWeight: 400, color: "#c8c8c8", }),
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
    }
  };

  return (
    // <CreatableSelect
    // placeholder={placeholder && placeholder}
    //   components={components}
    //   styles={customStyles }
    //   // inputValue={inputValue}
    //   options={options}
    //   isClearable
    //   isMulti={isMulti}
    //   menuIsOpen={false}
    //   onChange={(newValue : any) => {setValue(newValue)
    //     console.log('selected create setValue ', newValue)}}
        
    //   // onInputChange={(newValue : any) =>{ setInputValue(newValue);
    //   //   console.log('selected create setInputValue', newValue)}}
    //   // onKeyDown={handleKeyDown}
    //   // value={value}
    // />
    <CreatableSelect  placeholder={placeholder && placeholder} isMulti={isMulti} options={options} styles={name=="to" || name=="bcc" || name=="cc" ? customStyles1 : customStyles } onChange={(e : any)=>{handleInputChange(e)}} value={defaultValue} className='add-additional-fields' />
  );
};

export default CustomCreatableSelect