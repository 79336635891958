import { memo, useEffect, useMemo, useState } from "react"
import sysData from "../formSysAdminData";
import { IState } from "../../../../redux/reducers/rootReducers";
import { connect} from "react-redux";
import RoleAccrodianItem from "./RoleAccrodianItem";
const SystemAdminAccess = memo(({roleDataSet}:any)=>{
    const [systemAdminInfo,setSystemAdminInfo] = useState<any>({});
    useEffect(()=>{
        console.log('roleDataSetroleDataSet',roleDataSet)
        if(roleDataSet?.isloaded){
            setSystemAdminInfo(roleDataSet?.sysDataInfo)
        }
    },[roleDataSet?.isloaded])
    const layout = useMemo(()=><>
        {systemAdminInfo?.SystemPermissions && <RoleAccrodianItem info={systemAdminInfo?.SystemPermissions} keyLabel={'SystemPermissions'}/>}
        {systemAdminInfo?.UserAdminPermissions && <RoleAccrodianItem info={systemAdminInfo?.UserAdminPermissions}  keyLabel={'UserAdminPermissions'}/>}
        {systemAdminInfo?.ReportsPermissions && <RoleAccrodianItem info={systemAdminInfo?.ReportsPermissions}  keyLabel={'ReportsPermissions'}/>}
        {systemAdminInfo?.ContactsPermissions && <RoleAccrodianItem info={systemAdminInfo?.ContactsPermissions}  keyLabel={'ContactsPermissions'}/>}
        {systemAdminInfo?.SurveyPermissions && <RoleAccrodianItem info={systemAdminInfo?.SurveyPermissions}  keyLabel={'SurveyPermissions'}/>}
        {systemAdminInfo?.AssetsPermissions && <RoleAccrodianItem info={systemAdminInfo?.AssetsPermissions}  keyLabel={'AssetsPermissions'}/>}
        {systemAdminInfo?.Interfaces && <RoleAccrodianItem info={systemAdminInfo?.Interfaces}  keyLabel={'Interfaces'}/>}
        {systemAdminInfo?.KnowledgeBase && <RoleAccrodianItem info={systemAdminInfo?.KnowledgeBase}  keyLabel={'KnowledgeBase'}/>}
        {systemAdminInfo?.APIs && <RoleAccrodianItem info={systemAdminInfo?.APIs}  keyLabel={'APIs'}/>}
    </>,[systemAdminInfo])
    return(
        <> 
            {layout}
            {/* {systemAdminInfo?.SystemPermissions && <RoleAccrodianItem info={systemAdminInfo?.SystemPermissions} keyLabel={'SystemPermissions'}/>}
            {systemAdminInfo?.UserAdminPermissions && <RoleAccrodianItem info={systemAdminInfo?.UserAdminPermissions}  keyLabel={'UserAdminPermissions'}/>} */}
            {/* <SystemAdmin access={{}} callback={()=>{}} labels={labelChanges}/> */}
        </>
    )
})

// export default SystemAdminAccess;
const mapStateToProps = (state:IState) => {
    return ({
        roleDataSet : state?.roleReducer?.roleInfo,
    })
};
export default connect(mapStateToProps)(SystemAdminAccess);