import { Suspense, memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import Sortable from "sortablejs";
import { loadMoreBtnStyles, newTicketsUseStyles } from "../../NewTickets";
import { IState } from "../../../../../../redux/reducers/rootReducers";
import { loadNewToMeTickets } from "../../../../../../redux/actions/TicketsManagement/TicketsManagement";
import CustomCard1 from "../../../../../common/customCard1";
import LoadMoreNewToMe from "../../LoadMore/LoadMoreNewToMe";
const NewToMeTicketsList = memo(({AllTickets=[]}:any) =>{
    const classes =  newTicketsUseStyles();
    const shrinkGroupsVal: any = useSelector((state: IState) => state?.LayoutTicketManagementReducer?.groupShrinked);
    useEffect(()=>{
        var ticketList: any = document.getElementById(`newtoMeTicketsHodler`);
        if(!ticketList) return;
        var sortable = Sortable.create(ticketList, {
          group: {
            name: "list",
            put: false,
            pull: "clone",
          },
          onStart: function (e:any) {
            let evt: any = document.getElementsByTagName("html");
            evt[0].classList.add("newToMeItemDragged");
          },
          onEnd: function (e:any) {
            let evt: any = document.getElementsByTagName("html");
            evt[0].classList.remove("newToMeItemDragged");
          },
        });
    },[])
    const layout = useMemo(() => <>
      {AllTickets && AllTickets.map((i: any, index: number) => {
        return (
          <div key={index} className={`${shrinkGroupsVal ? 'col-3' : 'col-6'} p-2p userItem newTicketsTiles`}
            data-ticket-id={i.id}
            ticket-id={i.id}
            data-mytickets={'myTickets'}
            data-new-tickets={true}
            data-ticket-priority={i.ticketLogs.priorityId}
            data-ticket-status={i.ticketLogs.statusId}
            data-ticket-ownerid={i.ticketLogs.agentId}
          >
            <CustomCard1 sourceCode={i?.sourceCode} id={i.id} ticket={i.ticketLogs} />
          </div>
        )
      })}
    </>, [AllTickets,shrinkGroupsVal,classes])
    return (<>
      <Box
        className={`${classes.row} ${classes.flxWrap}`}
        id={"newtoMeTicketsHodler"}
      >{layout}
        <LoadMoreNewToMe />
      </Box>
  </>)
  })
export default NewToMeTicketsList;