import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { IState } from "../../redux/reducers/rootReducers";
import { useEffect, useState } from "react";

export default function BackdropLoading() {
  const loaderState = useSelector((state: IState) => state.loader.loader);
  const [open, setOpen] = useState(loaderState.open);
  const [loader, setLoader] = useState(loaderState);
 
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setLoader(JSON.parse(JSON.stringify(loaderState)));
  }, [loaderState.open]);

  return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader.open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
  );
}
