const initialState = {
  data: [],
};

const AdditionalFieldReducer = (
  state = initialState,
  action: { type: any; payload: any }) => {
  switch (action.type) {
    case "AdditionalFields_Storage":
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default AdditionalFieldReducer;
