import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function Radio({setEmailChecked,emailChecked}:{setEmailChecked: any,emailChecked: any}) {
  return (
    <div>
      <Checkbox {...label} sx={{paddingLeft:"0px"}} defaultChecked={emailChecked} onClick={(e : any)=>{
        console.log("Send email clicked", e.target.checked)
        setEmailChecked(!emailChecked)
      }} />
 
    </div>
  );
}