import { Box, Container, Grid, Tooltip, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import RadialBarCharts from './RadialBarCharts';
import { IState } from '../../../redux/reducers/rootReducers';
import { IDashboardInsights } from '../../../redux/actions/TicketsManagement/schema';
interface IRadialChartsBoxProps {
    dispatch:any;
    dashboardSummary:IDashboardInsights|undefined;
}
const RadialChartsBox = ({dispatch,dashboardSummary}:IRadialChartsBoxProps) => {
    const {responseSlainPercentageToday=0, resolutionSlainPercentageToday=0, resolvedTodayPer=0,}=dashboardSummary || {};
    return (
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={4}>
                        <Tooltip placement="top" title={<Typography>Under Development</Typography>}>
                          <Box>
                            <RadialBarCharts value={resolutionSlainPercentageToday} title="Resolution By SLA"/>
                          </Box>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Tooltip placement="top" title={<Typography>Under Development</Typography>}>
                        <Box>
                          <RadialBarCharts value={responseSlainPercentageToday} title="Response By SLA"/>
                        </Box>
                      </Tooltip>  
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <RadialBarCharts color='#3e9c7c' value={resolvedTodayPer} title="Resolved %"/>
                        
                    </Grid>
                  </Grid>
                </Box>
    )
}

const mapStateToProps = (state:IState) => {
    return ({
      dashboardSummary : state?.TicketsReducer?.dashboardSummary,
    })
  };
export default connect(mapStateToProps)(RadialChartsBox);