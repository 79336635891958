import React, { createRef } from 'react';
import { Box } from '@mui/material';
import { TextBoxLatest } from '../../common/TextBoxLatest/TextBoxLatest';
import { debounce } from 'lodash';
interface IEffortFieldProps{
    fieldLabel?:string;
    fieldNames?:string[];
}
const EffortField = (props:IEffortFieldProps) => {
    const {fieldLabel="Effort",fieldNames=["hours","mins"]} = props
    const hoursRef:any = createRef();
    const minsRef:any = createRef();
    const onChangeHandler = debounce((e:any) =>{
        let {name,value} = e?.target;
        value = value.replace(/^0+/, '');
        value = parseInt(value, 10);
        //["downTimeHours","downTimeMins"]
        if (isNaN(value) || value < 0 || ((name==="mins" || name==="downTimeMins") && value > 59)) {
            if(name==="hours" || name === "downTimeHours"){
                hoursRef.current.value =  "00";
            } else {
                minsRef.current.value = value > 59 ? "59" : "";
            }
        }
    },50)
    return (
    <Box className="mob-effort-field">
        <Box>
            <TextBoxLatest
                multiline={false}
                placeholder="Hours"
                title={fieldLabel}
                ref={hoursRef}
                isMandotary={false}
                onInput={onChangeHandler}
                errorMsg={``}
                isError={false}
                name={fieldNames[0]}
                type="number"
            />
        </Box>
        <Box>
            <TextBoxLatest
                multiline={false}
                placeholder="Minutes"
                title={""}
                ref={minsRef}
                onInput={onChangeHandler}
                isMandotary={false}
                errorMsg={``}
                isError={false}
                name={fieldNames[1]}
                type="number"
            />
        </Box>
    </Box>
    )
}

export default EffortField
