import { Box, Grid, FormControl, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import CommonStyles from "../../../common/CommonStyles";
import PermissionsFormGroup from "./PermissionsCheckboxGroup";
import { roleLabels } from "../AddRole";

const CheckBoxList = memo((props:any) =>{
    const {indexVal,titleKey,permissions} = props;
    const classes = CommonStyles();
    const layout = useMemo(()=>{
        return(<>
        <Grid  item xs={6} md={12}>
            <div className={`p-1 ${indexVal % 2 === 1 ? '' : 'stripe'}`}>
                <Grid container spacing={0} alignItems="center">
                    <Grid item xs={6} md={3}>
                        <Box><Typography variant="body2" className={`${classes.linkTitle} ${classes.rolesBlueTitle}`}>
                            {roleLabels?.[titleKey] || titleKey}
                        </Typography></Box>
                    </Grid>
                    <Grid item xs={6} md={9}>
                        <FormControl >
                            <PermissionsFormGroup titleKey={titleKey} permissions={permissions} />
                        </FormControl>
                    </Grid>

                </Grid>

            </div>
        </Grid>
        </>)
    },[])
    return(<>
        {layout}
    </>)
})
// const mapStateToProps = (state:IState) => {
//     return ({
//       checkedValues : state?.roleReducer?.checkedStatus,
//     //   state
//     })
// };
export default CheckBoxList;//connect(mapStateToProps)(CheckBoxList);