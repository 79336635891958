const initialState = {
    wallboardList: [],
  };
  
  const WallboardReducer = (
    state = initialState,
    action: { type: any; payload: any }) => {
    switch (action.type) {
      case "STORE_ALL_WALLBOARD":
        return {
          ...state,
          wallboardList: action.payload,
        };
        case "WALL_BOARD_TYPE_LIST":
        return {
          ...state,
          wallboardTypeList: action.payload,
        };
  
      default:
        return state;
    }
  };

  
  
  export default WallboardReducer;