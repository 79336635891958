import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
// import "react-select/dist/react-select.css";
import Select, {
  StylesConfig,
  components,
  ContainerProps,
  ValueContainerProps,
  ControlProps,
} from "react-select";
import chroma from "chroma-js";
import Highlighter from "react-highlight-words";
import { Margin } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import CommonStyles from "../CommonStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Select: {
      // zIndex: 1
      // marginLeft:"-4px"
    },

    highlight: {
      // textTransform:"capitalize"
    },
    tooltipText: {
      zIndex: -1,
    },
  })
);
const dot = (color: any) => ({
  alignItems: "center",
  display: "flex",
  fontFamily: "'DM Sans', sans-serif !important",
  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const CustomSelect = ({
  isMulti,
  placeholder,
  defaultValue,
  options,
  handleChange,
  isDisabled,
  isColor,
  width,
  name,
  isError,
  isOpen,
  height,
  helperText='',
  customClassNames
}: {
  isMulti: boolean;
  defaultValue: any;
  options: any;
  handleChange: any;
  isDisabled: boolean;
  isColor: boolean;
  width: any;
  placeholder: any;
  name: string;
  isError: any;
  isOpen: boolean;
  height?: any;
  helperText?:any;
  customClassNames?:string
}) => {
  const [menuIsOpen, setMenuIsOpen] = React.useState(isOpen);
  const selectRef = React.useRef<any>();
  const mode = CommonStyles();

  const errorStyle: StylesConfig = {
    control: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      backgroundColor: isDisabled ? "#EAEAEA" : "white",
      width: width,
      minWidth: width,
      height: height? height: 53,
      minHeight: 50,
      border: state.isFocused ? "1px solid tranpsarent" : "1px solid red",
      ":hover": {
        border: "1px solid red",
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "red",
      };
    },
    container: (provided, state) => ({
      ...provided,
      fontFamily: "'DM Sans', sans-serif !important",
      fontSize: "14px",
    }),
  };

  const customStyles: StylesConfig<any> = {
    control: (styles) => ({
      ...styles,
      backgroundColor: isDisabled ? "#EAEAEA" : "white",
      width: width,
      minWidth: width,
      height: height? height: 53,
      minHeight: 50,
      ":hover": {
        border: "1px solid black",
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    container: (provided, state) => ({
      ...provided,
      fontFamily: "'DM Sans', sans-serif !important",
      fontSize: "14px",
    }),
    placeholder: (styles) => ({ ...styles, fontWeight: 400, color: "#c8c8c8" }),
    multiValue: (styles, { data }) => {
      const color = chroma(data?.color ? data?.color : "gray");
      return {
        ...styles,
        // backgroundColor: "red",
        // height:"auto"
      };
    },
  };

  const colourStyles: StylesConfig<any> = {
    control: (styles) => ({
      ...styles,
      backgroundColor: isDisabled ? "#EAEAEA" : "white",
      width: width,
      minWidth: width,
      height: height? height: 53,
      minHeight: 50,
      ":hover": {
        border: "1px solid black",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontFamily: "'DM Sans', sans-serif !important",
      fontSize: "14px",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data?.color ? data?.color : "gray");
      console.log("data.color", data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "blue"
            : "black"
          : data.color,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data?.color ? data?.color : "gray");
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ":hover": {
        backgroundColor: data.color,
        color: "white",
      },
    }),
    input: (styles) => ({ ...styles, ...dot("transparent") }),
    placeholder: (styles) => ({
      ...styles,
      color: "#c8c8c8",
      fontFamily: "'DM Sans', sans-serif !important",
    }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };

  const { Option, SingleValue } = components;

  const ValueOption = (props: any) => (
    <SingleValue {...props}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontFamily: "'DM Sans', sans-serif !important",
        }}
      >
        {props.data.icon && (
          <span
            style={{
              marginRight: "5px",
              marginBottom: "-4px",
              color: "#0A93FC",
            }}
          >
            {props.data.icon}
          </span>
        )}
        {props.data.label && <span>{props.data.label}</span>}
        {props.data.after && (
          <span
            style={{
              marginLeft: "5px",
              marginBottom: "-2px",
              color: "#0A93FC",
            }}
          >
            {props.data.after}
          </span>
        )}
      </div>
    </SingleValue>
  );

  const MyOption = (props: any) => {
    const classes = useStyles();
    const { innerProps, innerRef } = props;
    let a = [props.selectProps.inputValue];
    // console.log("props.data", a);
    return (
      <>
        <Option {...props}>
          <>
            <div
              ref={innerRef}
              {...innerProps}
              style={{
                display: "flex",
                alignItems: "start",
                fontFamily: "'DM Sans', sans-serif !important",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  wordBreak: "break-all",
                }}
              >
                {props.data.icon && (
                  <span style={{ marginRight: "5px", marginBottom: "-4px" }}>
                    {props.data.icon}
                  </span>
                )}
                <Highlighter
                  highlightClassName={classes.highlight}
                  searchWords={a}
                  autoEscape={false}
                  textToHighlight={props.data.label}
                />
                {props.data.iconRight && (
                  <span style={{ marginRight: "5px", marginBottom: "-4px" }}>
                    {props.data.iconRight}
                  </span>
                )}
              </div>
              {/* {props.data.body && 
              Object.values(props.data.body).map((item : any, index: any)=>{
                return <>
                <div>
                   <Highlighter
                highlightClassName={`sss${index}`}
                searchWords={a}
                autoEscape={false}
                textToHighlight={item}
              />
              </div>
                  </>

              })
              } */}
            </div>
          </>
        </Option>
      </>
    );
  };
  const classes = useStyles();
  const optionsWithSelect: any = isMulti
    ? [{ label: "Select All", value: "all" }, ...options]
    : options;

  return (
    <>
    <div className={`${classes.Select} custom-mulitselect-dropdowns ${customClassNames}`} style={{ width: width }}>
      {!isOpen ? (
        <Select
          isClearable={true}
          //  openMenuOnFocus={false}

          menuPortalTarget={document.body}
          name={name}
          id={name}
          closeMenuOnSelect={true}
          openMenuOnClick={true}
          placeholder={placeholder && placeholder}
          isDisabled={isDisabled}
          styles={isError ? errorStyle : isColor ? colourStyles : customStyles}
          options={optionsWithSelect}
          components={{ Option: MyOption, SingleValue: ValueOption }}
          isMulti={isMulti}
          value={defaultValue ? defaultValue : null}
          onInputChange={(e) => {
            console.log("onInputChange", e);
          }}
          onChange={(selected: any) => {
            console.log("hello mul", selected, isMulti);
            isMulti &&
            selected.length &&
            selected.find((option: any) => option.value === "all")
              ? handleChange(optionsWithSelect.slice(1), name)
              : !isMulti
              ? handleChange((selected && selected), name || null, name)
              : handleChange(selected, name);
          }}
        />
      ) : (
        <Select
          name={name}
          id={name}
          menuIsOpen={true}
          // closeMenuOnSelect={true}
          // openMenuOnClick={true}
          
          placeholder={placeholder && placeholder}
          isDisabled={isDisabled}
          styles={isError ? errorStyle : isColor ? colourStyles : customStyles}
          options={optionsWithSelect}
          components={{ Option: MyOption, SingleValue: ValueOption }}
          isMulti={isMulti}
          value={defaultValue ? defaultValue : null}
          onInputChange={(e) => {
            console.log("onInputChange", e);
          }}
          onChange={(selected: any) => {
            console.log("hello mul", selected, isMulti);
            isMulti &&
            selected.length &&
            selected.find((option: any) => option.value === "all")
              ? handleChange(optionsWithSelect.slice(1), name)
              : !isMulti
              ? handleChange((selected && selected), name || null, name)
              : handleChange(selected, name);
          }}
        />
      )}
      {(isError && helperText) && <Typography variant="caption" className={mode.errInlineMsg} display="block">{helperText}</Typography>}
    </div>
      
    </>
  );
};

export default CustomSelect;
