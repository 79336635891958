import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import DashboardSkeletonLoader from '../components/Dashboard/TicketManagement/TicketDashboardWidgets/views/widgets/DashboardSkeletonLoader';
import { IState } from '../redux/reducers/rootReducers';
import { getAllDAshboardData } from '../redux/actions/TicketsManagement/TicketsManagement';
interface IDataPreloadedWrapperProps {
  isRequiredDataLoaded : boolean;
  dispatch : any; 
  children : JSX.Element;
}
const DataPreloadedWrapper = ({ isRequiredDataLoaded, dispatch, children }:IDataPreloadedWrapperProps) => {
  useEffect(()=>{
    if(!isRequiredDataLoaded){
      dispatch(getAllDAshboardData())
    }
  },[])
  return (
    <React.Fragment>
      {isRequiredDataLoaded ?
        <>{children}</> :
        <>
          <DashboardSkeletonLoader />
        </>
      }
    </React.Fragment>
  );
};

const mapStateToProps = (state:IState) => {
  return ({
    isRequiredDataLoaded : state?.TicketsReducer?.allResponses?.isLoaded
  })
};

export default connect(mapStateToProps)(DataPreloadedWrapper);