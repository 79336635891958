import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, ChartEvent, ActiveElement, Chart } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import {Tooltip as MuiTooltip}  from "@mui/material"; 
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Box, Typography } from '@mui/material';
import { ChartOptions } from 'chart.js';
import { MouseEvent, MouseEventHandler, useEffect, useRef, useState } from 'react';
import { colorPalette } from '../../../components/Theme/Theme';
import { ChartNoDataPlaceHolder } from './TreeMapChart';
import { connect, useDispatch } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';

ChartJS.register(ArcElement, Tooltip, Legend,ChartDataLabels);
const options: ChartOptions<"pie"> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: true,
      color: "#fff",
      font:{
        size:12
      },
    },
    legend:{
      display: false,
      labels:{
        boxWidth : 12,
        font:{
          size:12
        }
      }
    },
  }
};

interface IDashboardPieChartProps{
  labels:string[],
  values:number[],
  callback ?: (val:IBasicObj)=>void;
  colors?:string[]
  ids?:number[]
  
}
export interface IBasicObj {
  [key:string] : boolean
}
const DashboardPieChart = ({dashboardSummary={},dispatch}:any)=>{
  //labels={Object.keys(dashboardSummary?.tickettype)} values={Object.values(dashboardSummary?.tickettype).map(i=>i.count)} ids={Object.values(dashboardSummary?.tickettype).map(i=>i.id)}
  // const dispatch = useDispatch();
  
  const [labels, setLabels] = useState<any>({
    labels : [],
    values : []
  })
  const [data, setData] = useState<ChartData<"pie", number[]>>({
    labels: Object.keys(dashboardSummary?.tickettype),
    datasets: [
      {
        data: Object.values(dashboardSummary?.tickettype).map((i:any)=>i.count),
        backgroundColor: colorPalette,
        borderWidth: 0,
        borderColor: "#fff0",
      },
    ],
  });

  useEffect(()=>{
    const tempLabels:string[] = Object.keys(dashboardSummary?.tickettype);
    const tempObj:any = {
      labels : [],
      values : Object.values(dashboardSummary?.tickettype).map((i:any)=>i.count),
      ids : Object.values(dashboardSummary?.tickettype).map((i:any)=>i.id)
    }
    tempObj.values = tempObj.values.filter((i:number,index:number)=>{
      // if(i===0){
      //   return false;
      // }else{
        tempObj.labels.push(tempLabels[index])
        return true;
      // }
    })
    setData({
      labels: tempObj.labels,
      datasets: [
        {
          data: tempObj.values,
          backgroundColor: colorPalette,
          borderWidth: 0,
          borderColor: "#fff0",
        },
      ],
    })
    setLabels(tempObj)
    

  },[dashboardSummary])

  const toggleWithCustomLegends = (tempObj: IBasicObj) => {
    const labels= Object.keys(dashboardSummary?.tickettype)
    const values= Object.values(dashboardSummary?.tickettype).map((i:any)=>i.count)
    // const ids= Object.values(dashboardSummary?.tickettype).map((i:any)=>i.id)
    const filteredColors = colorPalette.filter((_, index) => tempObj?.[`${index}`] ? false : true);

    setData((presState) => {
      return {
        ...presState,
        labels: labels.filter((_, index) => tempObj?.[`${index}`] ? false : true),
        datasets: [
          {
            ...presState.datasets[0],
            data: values.filter((_, index) => tempObj?.[`${index}`] ? false : true),
            backgroundColor: filteredColors,
          }
        ]
      }
    })
  }


  

  
    const onclickBar = (index:number) =>{
      // const labels= Object.keys(dashboardSummary?.tickettype)
      // const values= Object.values(dashboardSummary?.tickettype).map((i:any)=>i.count)
      // const ids= Object.values(dashboardSummary?.tickettype).map((i:any)=>i.id)
      // const label = labels.labels[index];
      const priorityName = `${data?.labels?.[index]||"_"}`;
      const id = labels.ids[index]//data.datasets[0].data[index]
      console.log("STORE_HOME_DASHBOARD_TCKT_TYPE",dashboardSummary,index,data,{[priorityName]: id})
      dispatch({
          type:"STORE_HOME_DASHBOARD_TCKT_TYPE",
          payload : {[priorityName]: id}
      })
    }
    return (
      <Box className="home-chart-box white-bg" sx={{height:"100%"}}>
        <Box className="chart-header pb-1">
          <Typography className="chart-title">Ticket Types</Typography>
          {labels.labels.length>0 &&
            <ChartHelper callback={toggleWithCustomLegends} labels={labels.labels} values={labels.values} />
          }
        </Box>
        <Box sx={{height:275}}>
          {/* {data.labels} */}
          {/* {values.length > 0 ?  */}
            <PieChart callback={onclickBar} data={data} />
            {/* :
            <ChartNoDataPlaceHolder />
          } */}
        </Box>
      </Box>
    );
  }

export const  PieChart = ({data,callback}:any)=>{
  const chartRef = useRef<any>(null);
  const handleCanvasClick:any = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (chartRef.current) {
      const chart = chartRef.current; // Access the chart instance from the ref
      const elements = chart.getElementsAtEventForMode(
        event as any,
        'nearest',
        { intersect: true },
        true
      ) as ActiveElement[];

      if (elements.length > 0) {
        const index = elements[0].index!;
        callback(index)
      }
    }
  };

    useEffect(() => {
      if (chartRef?.current?.chartInstance?.update) {
        chartRef.current.chartInstance.update();
      }
    }, [data]);
  return (
    <Pie onClick={handleCanvasClick} ref={chartRef} data={data} options={options} />
  )
}

export const ChartHelper = ({labels=[],values=[],colors=colorPalette,callback}:IDashboardPieChartProps) =>{
  const [disabledIndex,setDisabledIndex] = useState<IBasicObj>({})
  const toggleWithCustomLegends = (index:number) =>{

    const tempObj:{[key:string] : boolean} = {...disabledIndex};
    if(tempObj[index]) {
      delete tempObj[index];
    }else{
      tempObj[index] = true;
    }
    setDisabledIndex(tempObj);
  }
  useEffect(()=>{
    if(callback){
      callback(disabledIndex)
    }
  },[disabledIndex])
  useEffect(()=>{
    setDisabledIndex({})
  },[labels.length])
  return(
    <Box className="row m-0 gap15">
      {(Object.keys(disabledIndex).length > 0 && labels.length > 3) &&
        <Box onClick={()=>{setDisabledIndex({})}}>
          <MuiTooltip title="Reset Chart">
            <Typography className='pointer align-items-center txt-theme-orange' sx={{fontSize:12}}>Reset</Typography>
          </MuiTooltip>
        </Box>
      }
      <Box className="chart-helper">
          {labels.length <= 3 ?
            <ul className='chart-custom-legends white-bg default-legends row m-0 gap15'>
              {labels.map((i,index:number)=><li key={index} onClick={()=>toggleWithCustomLegends(index)}>
                <span className='color' style={{backgroundColor:`${colors?.[index] || "#000000"}`}} >&nbsp;</span> 
                {disabledIndex?.[`${index}`] ?   <del>{i}({values?.[index]}) </del> : <span>{i} ({values?.[index]})</span>}</li>)}
            </ul>
          :
            <>
              <Typography><span className="simple-icon icon-info"></span></Typography>
              <ul className='chart-custom-legends white-bg'>
                {labels.map((i,index:number)=><li key={index} onClick={()=>toggleWithCustomLegends(index)}>
                  <span className='color' style={{backgroundColor:`${colors?.[index] || "#000000"}`}} >&nbsp;</span> 
                  {disabledIndex?.[`${index}`] ?   <del>{i}({values?.[index]}) </del> : <span>{i} ({values?.[index]})</span>}</li>)}
              </ul>
            </>
          }
      </Box>
    </Box>
  )
}


const mapStateToProps = (state: IState) => {
  return ({
    dashboardSummary: state?.TicketsReducer?.dashboardSummary,
    // groupsList: state?.groupReducer?.Data?.data,
    // dashboardGroup: state?.TicketsReducer?.dashboardGroup,
  })
};
export default connect(mapStateToProps)(DashboardPieChart);