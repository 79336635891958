import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import LanguageData from "../../configs/LanguageData.json";
import { getBase64 } from "../../pages/new-ticket/LatestTicket/Form";
import { getMimeType } from "../../pages/new-ticket/LatestTicket/RightPanel";
import { iFormData } from "../../pages/new-ticket/LatestTicket/Type";
import {
  getContactAid,
  getContactAidById,
  triggerAppAlert,
} from "../../redux/actions/SelfServicePortal/ContactAid/ContactAid";
import {
  getCategory,
  sspSingleCategory,
} from "../../redux/actions/SystemManagement/AddCategory";
import { IState } from "../../redux/reducers/rootReducers";
import { addNewTicket } from "../../services/dashboard/dashboard.service";
import {
  fileUploadService,
  fileUploadServiceForBloeStorage,
} from "../../services/fileUpload/FileUpload";
import CustomSelect from "../common/customSelect/CustomSelect";
import CustomFormLabel from "../common/form-label/CustomFormLabel ";
import { WebFormTextArea } from "../webForms/FieldTypes/TextArea";
import SelectWebformField from "../webForms/SelectWebformField";
import { iWebFormField } from "../webForms/schema";
import AddAttachmentBlobWithPreview from "../EmailTemplates/AddAttachmentBlobWithPreview";
export const addTicketAsContactUrl = "user/Ticket";
const summaryconfigs: iWebFormField = {
  fieldPlaceholder: "Please enter your issue description",
  fieldDescription: "",
  fieldLabel: "Description",
  isMandatory: true,
  id: "webFormDescription",
};

const blobToBase64 = async (blob: any) => {
  return new Promise<string>((resolve, reject) => {
    const reader: any = new FileReader();
    reader.onloadend = () => {
      resolve(reader?.result?.split(",")[1] || ""); // Remove the 'data:...' prefix
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const sendRecordingToBackend = async (mediaBlobUrl: any) => {
  if (mediaBlobUrl) {
    try {
      console.log("Fetching blob from URL:", mediaBlobUrl);

      // Fetch the Blob from mediaBlobUrl
      const response = await fetch(mediaBlobUrl);
      const blob = await response.blob();
      const base64String = await blobToBase64(blob);
      // Convert Blob to File
      const file = new File([blob], `recording_ss.mp4`, { type: "video/mp4" });
      console.log("Converted file:", file);
      // Create FormData and append file
      const formData = new FormData();
      formData.append("file", file);

      return {
        file: file,
        blob: base64String,
      };
    } catch (error) {
      console.error("Error uploading file:", error);
      return false;
    }
  }
};
const validateWebForm = (enteredVal: any = {}, fields = []) => {
  let errObj: any = {
    hasError: false,
  };
  if (enteredVal["webFormDescription"].trim().length === 0) {
    errObj["webFormDescription"] = "Please fill this field";
    errObj.hasError = true;
  }
  if (!enteredVal["Category"] || enteredVal["Category"] === "Please Select") {
    errObj["Category"] = "Please select a category";
    errObj.hasError = true;
  }
  fields.map((i: any) => {
    // errObj[i.fieldId] =
    if (!i.isMandatory) return;
    let fieldVal = enteredVal[i.fieldId];
    if (
      !fieldVal ||
      !fieldVal?.trim ||
      enteredVal[i.fieldId].replaceAll("pleaseSelect", "").trim().length === 0
    ) {
      errObj[i.fieldId] = "Please fill this field";
      errObj.hasError = true;
    }
  });
  console.log("validateWebForm", enteredVal, fields, errObj);
  return errObj;
};
interface StringObject {
  [key: string]: string;
}
const mergeCheckBoxValues = (obj: StringObject) => {
  try {
    const tempObj: StringObject = {};
    Object.keys(obj).map((i) => {
      if (i.indexOf("checkBox_") === 0) {
        let key = i.split("_")[1];
        tempObj[key] = (tempObj[key] ? tempObj[key] + "," : "") + obj[i];
      } else {
        tempObj[i] = obj[i];
      }
    });
    return tempObj;
  } catch (error) {
    console.log("mergeCheckBoxValuesERROR", error);
    return obj;
  }
};
const ViewService = (props: any) => {
  const path = useLocation();
  const navigate = useNavigate();

  const currContact = useSelector(
    (state: IState) => state?.ContactsReducer?.loggedContact
  );

  const searchPath = path.search;

  const searchParams = new URLSearchParams(searchPath);

  const mediaBlobUrl = useSelector(
    (state: any) => state.recording.mediaBlobUrl
  );

  const srd: any = searchParams.get("srd");

  console.log("search path for new", srd);

  const {
    AllContactAid = [],
    ContactAidById = {},
    dispatch,
    ContactAidImages = {},
    loggedInUser = {},
  } = props;
  const [showForm, setShowForm] = useState<boolean>(true);
  const [byIdVal, setByIdVal] = useState<any>({});
  const [errValues, setErrValues] = useState<any>({});
  const [attachmentValue, setAttachmentVal] = useState<any>([]);
  const [AllCategory, setAllCategory] = useState<any>([]);
  const [categoryList, setCategoryList] = useState([]);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [showLoading, setShowLoading] = useState<any>(false);
  const [attachmentList, setAttachmentList] = useState<any>([]);
  const [valueCat, setValueCat] = useState<any>({});
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    if (
      AllContactAid?.[activeIndex]?.categoryId &&
      AllContactAid?.[activeIndex]?.categoryId !== 0
    ) {
      dispatch(
        sspSingleCategory(
          AllContactAid?.[activeIndex]?.categoryId,
          getCategoryByIdCB
        )
      );
    } else {
      let chosenCategory = AllCategory.find(
        (ele: any) =>
          ele.categoryTicketTypeList[0]?.ticketType?.id ===
          AllContactAid?.[activeIndex]?.ticketTypeId
      );
      if (chosenCategory) {
        dispatch(sspSingleCategory(chosenCategory.id, getCategoryByIdCB));
      }
    }
    if (
      AllContactAid?.[activeIndex]?.id &&
      !ContactAidById?.[AllContactAid?.[activeIndex]?.id]
    ) {
      console.log(
        "get_by_id",
        AllContactAid[activeIndex].id,
        AllContactAid[activeIndex],
        byIdVal
      );
      dispatch(
        getContactAidById(
          AllContactAid[activeIndex].id,
          null,
          "user/ContactAid/details"
        )
      );
    }
    setErrValues({});
  }, [activeIndex, AllCategory]);
  useEffect(() => {
    if (AllContactAid.length > 0 && activeIndex === -1) {
      setActiveIndex(0);
    }
  }, [AllContactAid]);

  useEffect(() => {
    // Define the async function inside useEffect
    const fetchData = async () => {
      try {
        let tempV: any = `${mediaBlobUrl}`;

        const res: any = await sendRecordingToBackend(tempV);

        if (res && srd) {
          console.log("blob get in view service", res);
          setAttachmentList([
            {
              preview: res?.blob,
              fileName: "Recording",
              fileType: res?.file?.type,
              formFile: res?.file,
            },
          ]);
        } else {
          console.log("blob get in view service", res);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    // Call the async function
    fetchData();
  }, [mediaBlobUrl]);
  useEffect(() => {
    dispatch(getCategory(getallCategoryCB, "user/Category"));
    if (AllContactAid.length === 0) {
      dispatch(getContactAid(getContactAidCB, "user/ContactAid"));
    } else {
      getContactAidCB("0", AllContactAid);
    }
  }, []);

  const getContactAidCB = (resStatus: string, arr: any[] = []) => {
    const id: any = new URLSearchParams(searchPath).get("id");
    if (id && resStatus === "0") {
      arr.map((i: any, index: number) => {
        if (i.id === +id) setActiveIndex(index);
      });
    }
  };

  const ticketCreation = async (payload: any) => {
    console.log("payload", payload);
    let newTicket: any = await addNewTicket(payload, addTicketAsContactUrl);
    return newTicket;
  };
  const onchangeCategory = useCallback((e: any) => {
    setValueCat(e);
  }, []);
  const onFormSubmit = (event: any) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    console.log("attachmentValue", attachmentList);

    let data: iFormData = {};
    formData.forEach((value, key) => {
      data[key] = value.toString();
    });
    data = mergeCheckBoxValues(data);
    const attachmentPaths = attachmentList?.map((item: any) => {
      return {
        AttachmentPath: item.fileName ? item.fileName : item.attachmentPath,
      };
    });

    const webformFieldList =
      ContactAidById?.[AllContactAid?.[activeIndex]?.id]?.webform
        ?.webformFieldList || [];
    const validationVal: any = validateWebForm(data, webformFieldList);
    setErrValues(validationVal);
    if (validationVal.hasError) return;
    const currentAid = AllContactAid?.[activeIndex];
    console.log("onFormSubmit", data, webformFieldList);
    console.log("webformFieldList", data);
    webformFieldList.map((i: any) => {
      const { fieldType, id } = i;
      if (fieldType === "DatePicker" && data[id]) {
        data[id] = new Date(data[id]).toISOString();
      }
    });
    console.log("webformFieldList1", data);
    let request: any = {
      sourceCode: "SSP",
      contactAidId: currentAid?.id || 0,
      ticketLogs: {
        statusId: 0, //currentAid?.agentId ? 2 : 1,
        categoryId: valueCat?.id || 0,
        agentId: currentAid?.agentId || 0,
        groupId: currentAid?.groupId || 0,
        ticketTypeId: currentAid?.ticketTypeId || 0,
        description: data.webFormDescription || "",
        title: `${data.webFormDescription || ""}`.substring(0, 100),
        sourceCode: "SSP",
        contactId: loggedInUser?.id || 0,
        webformvalues: `${JSON.stringify(data)}`,
        webformId: byIdVal?.webform?.id || 0,
        circulationListTO: currContact?.email,
        ticketLogAttachmentsList: attachmentPaths,
      },
    };
    // request.ticketLogs.ticketLogAttachmentsList = attachmentValue?.map(
    //   (i: any) => {
    //     let tempObj = { attachmentId: i.id, attachmentPath: i.attachmentPath };
    //     return tempObj;
    //   }
    // );
    console.log("request", request);

    ticketCreation(request).then((res: any) => {
      if (res.status == 200 || res.status == 201) {
        console.log("ticketCreation_res", res);
        res?.data?.ticketLogs?.ticketLogAttachmentsList?.map(
          (item: any, index: number) => {
            fileUploadServiceForBloeStorage(
              item?.attachmentPath,
              attachmentList[index]?.formFile,
              (isDone: any, res: any) => {
                if (isDone) {
                  console.log("inputFileHandler file uploaded", res);
                } else {
                  console.log("inputFileHandler file failed", res);
                }
              }
            );
          }
        );
        dispatch(
          triggerAppAlert(
            `${LanguageData.SUCCESS} ${res?.data?.id} ${LanguageData.TICKET_CREATED}`,
            "success"
          )
        );
        setTimeout(() => {
          navigate("/workCenter");
          resetForm();
        }, 1000);
      }
    });
  };
  const resetForm = () => {
    setShowForm(false);
    setTimeout(() => {
      setShowForm(true);
    }, 500);
  };
  useEffect(() => {
    setByIdVal(ContactAidById?.[AllContactAid?.[activeIndex]?.id] || {});
  }, [ContactAidById, AllContactAid, activeIndex]);

  const webFormFields = useMemo(
    () => (
      <>
        {byIdVal?.webform?.webformFieldList?.map &&
          byIdVal?.webform?.webformFieldList?.map((i: any) => {
            console.log("errValues__errValues", errValues);
            const { fieldLabel, fieldType, fieldPlaceholder, fieldId } = i;
            i.id = fieldId;
            return (
              <Box className="webform-fields-holder">
                <SelectWebformField block={i} />
                {errValues?.[fieldId] && (
                  <Typography variant="caption" className="txt-danger">
                    {errValues?.[fieldId]}
                  </Typography>
                )}
              </Box>
            );
          })}
      </>
    ),
    [byIdVal?.webform?.webformFieldList?.map, errValues]
  );

  function truncateString(str: string) {
    if (str.length > 5) {
      return str.substring(0, 8) + "...";
    }
    return str;
  }
  const imgCallBack = async (params: any, file: any) => {
    let fileObject = {
      description: file.name,
      filetypeid: 1,
      formFile: file,
    };
    let fileUpload: any = await fileUploadService(
      fileObject,
      () => {},
      () => {}
    );
    if (fileUpload.status == 201) {
      console.log("file upload success ", fileUpload);
      let recs: {
        file: any;
        fileName: string;
        id: any;
        attachmentPath: any;
      }[] = attachmentValue || [];
      recs.push({
        file: params,
        fileName: truncateString(file.name),
        id: fileUpload.data.id,
        attachmentPath: fileUpload.data.filePath,
      });
      setAttachmentVal([...recs]);
    } else {
    }
  };
  const getCategoryByIdCB = (resStatus: string, res: any) => {
    if (resStatus === "0") {
      let temp: any = [];
      res?.forEach((item: any) => {
        if (item.isActive) {
          let a: any = {
            label: `${item.name}`,
            name: item.name,
            value: item.id,
            id: item.id,
          };
          temp.push(a);
        }
      });
      if (temp.length === 1) {
        setValueCat(temp[0]);
      }
      setCategoryList(temp);
    }
  };

  useEffect(() => {
    if (byIdVal && activeIndex >= 0) {
      if (byIdVal?.category?.id && byIdVal?.category?.id !== 0) {
        const categoryObj = {
          label: byIdVal.category.name,
          name: byIdVal.category.name,
          value: byIdVal.categoryId,
          id: byIdVal.categoryId,
        };
        setValueCat(categoryObj);
      } else {
        setValueCat(null);
      }
    }
  }, [byIdVal, activeIndex]);

  const onRemoveAttchment = (index: number) => {
    let allRecs = attachmentValue;
    allRecs.splice(index, 1);
    setAttachmentVal([...allRecs]);
  };

  const getallCategoryCB = (resStatus: string, res: any) => {
    if (resStatus === "0") {
      setAllCategory(res);
    }
  };
  return (
    <>
      {AllContactAid?.length > 0 ? (
        <Box className="p-2 view-service">
          <Grid container justifyContent={"space-between"} spacing={0}>
            <Grid item md={6} xs={12}>
              <Grid container justifyContent={"space-between"} spacing={0}>
                <Grid item md={3} xs={12} className="viewservice-usericon">
                  <Avatar
                    sx={{ height: 130, width: 130 }}
                    alt={AllContactAid?.[activeIndex]?.name}
                    src={`${
                      AllContactAid?.[activeIndex]?.systemIcons?.filePath || ""
                    }`}
                  />
                </Grid>
                <Grid item md={9} xs={12}>
                  <Typography variant="h6">
                    {AllContactAid?.[activeIndex]?.name}
                  </Typography>
                  <Typography className="aid_desc" variant="body2">
                    {AllContactAid?.[activeIndex]?.description}
                  </Typography>
                  <Typography variant="body2">
                    <span className="text-primary">Cost : </span>
                    <span>{AllContactAid?.[activeIndex]?.cost}</span>
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <hr />
                </Grid>
                <Grid item md={12} xs={12}>
                  <form onSubmit={onFormSubmit}>
                    <Grid item md={12} xs={12}>
                      <CustomFormLabel
                        classNames="ssp-create-request-label"
                        labelName={"Category"}
                        isMandotary={true}
                      />
                      <CustomSelect
                        isMulti={false}
                        isOpen={false}
                        placeholder="Please Select"
                        width={"100%"}
                        name="Category"
                        isColor={false}
                        isDisabled={false}
                        isError={errValues?.["Category"]}
                        defaultValue={valueCat}
                        options={categoryList}
                        helperText={errValues?.["Category"]}
                        handleChange={onchangeCategory}
                        customClassNames="sml-txt-dropdown new-service"
                      />
                    </Grid>
                    <WebFormTextArea
                      fieldConfig={summaryconfigs}
                      value={null}
                      editable={true}
                    />
                    {errValues?.["webFormDescription"] && (
                      <Typography variant="caption" className="txt-danger">
                        {errValues["webFormDescription"]}
                      </Typography>
                    )}
                    {byIdVal && byIdVal?.webform && webFormFields}

                    <Grid
                      item
                      md={12}
                      xs={12}
                      className="spp-newtickets-attachment"
                    >
                      <input
                        id="AssetFileUpload"
                        accept=".jpeg, .img, .png, .pdf"
                        type="file"
                        multiple={true}
                        onChange={(e: any) => {
                          getBase64(e.target.files[0], imgCallBack);

                          // imgCallBack(e.target.files)
                        }}
                        className="d-none"
                      />
                      <AddAttachmentBlobWithPreview
                        accept={
                          ".pdf,.doc,.docx,.jpg,.jpeg,.png,.zip,.txt,.xls,.xlsx,.mp4"
                        }
                        type="Attachment"
                        isAttachmentOnFly={false}
                        isShowBtn={true}
                        attachmentList={attachmentList}
                        setAttachmentList={setAttachmentList}
                        btnLabel="Add attachments"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Box className="pt-1 text-center">
                        <Button type="submit" variant="contained">
                          Create Ticket
                        </Button>
                      </Box>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3} xs={12}>
              <aside className="aside viewservice-services">
                <Typography variant="h6">Services</Typography>
                <AidList
                  listVal={AllContactAid}
                  ContactAidImages={ContactAidImages}
                  activeIndex={activeIndex}
                  callback={setActiveIndex}
                />
              </aside>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="p-2 white-bg">
          <Typography variant="h5">No Services Found</Typography>
        </Box>
      )}
    </>
  );
};

const AidList = (props: any) => {
  const {
    listVal = [],
    activeIndex = 0,
    callback,
    ContactAidImages = {},
  } = props;
  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {listVal.map((i: any, index: number) => {
        return (
          <>
            <ListItem
              key={index}
              onClick={() => {
                callback(index);
              }}
              className={`aid_list_items ${
                activeIndex === index ? "active" : ""
              }`}
              alignItems="center"
            >
              <ListItemAvatar>
                <Avatar
                  sx={{ height: 30, width: 30 }}
                  alt={i.name}
                  src={i?.systemIcons?.filePath || ""}
                />
              </ListItemAvatar>
              <ListItemText
                primary={i.name}
                secondary={<>{/* {i.description} */}</>}
              />
            </ListItem>
          </>
        );
      })}
    </List>
  );
};
const mapStateToProps = (state: IState) => {
  return {
    ContactAidImages: state?.ContactsReducer?.contactImgDetailsById,
    AllContactAid: state?.ContactsReducer?.AllContactAid,
    ContactAidById: state?.ContactsReducer?.ContactAidById,
    loggedInUser: state?.ContactsReducer?.loggedContact,
  };
};

export default connect(mapStateToProps)(ViewService);
