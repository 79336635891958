import { memo, useEffect, useState } from "react";
import { Box,Typography, Grid,Accordion,AccordionSummary, AccordionDetails, FormControl, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import CommonStyles from "../../common/CommonStyles";
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
export const SystemAdmin = memo(({access,callback,labels}: {access: any;callback: any;labels:any;}) =>{
    const classes = CommonStyles();
    const [expanded, setExpanded] = useState('');
    const [AllData, setAllData] = useState({} as any);
   useEffect(()=>{
        setAllData(access)
    },[access])
    useEffect(()=>{
        if(AllData && Object.keys(AllData).length>0){
            callback(AllData)
        }
    },[AllData]);
    const handleChange =(panel: string) => {
      setExpanded(expanded === panel ? "" : panel);
    };
    const allowAllAccess = (e:any)=>{
        const checked = e.target.checked;
        const name = e.target.name;
        const moduleSections = Object.keys(AllData[name]).filter(x=>x !== 'title' && x !== 'onlyAllowAccess');
        const allInfo = JSON.parse(JSON.stringify(AllData[name]));
        moduleSections.map((i:any)=>{
            const modulePermissions = allInfo[i].permissions.map((j:any)=>{
                j.checked = checked;
                return j;
            })
        });
        setAllData({
            ...AllData,
            [name] : allInfo
        });
    }
    const indeterminateAllAccess = (e:any) => {
        let hasFalseVal = false;
        let hasTrueVal = false;
        const moduleSections = Object.keys(AllData[e]).filter(x=>x !== 'title' && x !=="onlyAllowAccess");
        const allInfo = AllData[e];
        moduleSections.forEach((i:any)=>{
            allInfo[i].permissions.forEach((j:any)=>{
                if(!j.checked){
                    hasFalseVal = true;
                } else {
                    hasTrueVal = true;
                }
                
            })
        });
        return {hasFalseVal:hasFalseVal,hasTrueVal:hasTrueVal}
    }
    const checkboxHandler = (sectionTitle:string,module:any,index:number)=>{
        let allInfo = JSON.parse(JSON.stringify(AllData));
        allInfo[sectionTitle][module].permissions[index].checked = !allInfo[sectionTitle][module].permissions[index].checked;
        setAllData({...allInfo})
    }
    return (
        <>
        <Box className={`${classes.whiteBox} p-0`}>
            {/* <Typography variant="button" display="block" gutterBottom>
                Specify Permissions
            </Typography>
            <hr /> */}
            {
                Object.keys(AllData).length>0 && Object.keys(AllData).map((i:any,key:number)=>{
                    let res:any = indeterminateAllAccess(i);
                    const interMediate = res.hasFalseVal && res.hasTrueVal ? true : false;
                    let isAllChecked = !res.hasFalseVal && res.hasTrueVal ? true : false;
                    return <>
                    <Accordion key={key} expanded={expanded === `${AllData[i].title}`}  className="accordionDtls">
                        <AccordionSummary aria-controls={`${AllData[i].title}d-content`} id={`${AllData[i].title}d-header`} className='accrodTitle'>
                            <Box className={`${classes.row} ${classes.justifyBetween} w-100`}>
                                <Box>
                                    <Typography>{AllData[i].title}</Typography>
                                </Box>
                                <Box className={`${classes.row} ${classes.alignItemsCenter}`} >
                                    <Box className={classes.roleAllowAccessBtn}> 
                                        <FormControlLabel
                                            value=''
                                            control={
                                                <Checkbox size="small" 
                                                    indeterminate={interMediate}
                                                    onChange={allowAllAccess}
                                                    name={i}
                                                    checked={isAllChecked}
                                                />
                                            }
                                            label='Allow access'
                                            labelPlacement="end"
                                            className="sml-txt roles-checkbox"
                                        />
                                    </Box>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {expanded !== AllData[i].title ? 
                                        <KeyboardArrowDownOutlinedIcon className="pointer" onClick={()=>handleChange(`${AllData[i].title}`)}/> :
                                        <KeyboardArrowUpOutlinedIcon className="pointer" onClick={()=>handleChange(`${AllData[i].title}`)}/>
                                    }
                                </Box>
                            </Box>
                            
                        </AccordionSummary>
                        <AccordionDetails>
                        <Grid container spacing={0} alignItems="center">                            
                                {
                                    Object.keys(AllData[i]).map((j:any,index:number)=>{
                                        if(j !== 'title' && j!=="onlyAllowAccess"){
                                            //console.log(labels, j, labels[j])
                                            return <>
                                                <Grid key={index} item xs={6} md={12}>
                                                    <div className={`p-1 ${index % 2 === 1 ? '' : 'stripe'}`}>
                                                    <Grid container spacing={0} alignItems="center">  
                                                        <Grid item xs={6} md={3}>
                                                                <Box><Typography variant="body2" className={`${classes.linkTitle} ${classes.rolesBlueTitle}`}>{labels[j] ? labels[j] : j}</Typography></Box>
                                                        </Grid>
                                                        <Grid item xs={6} md={9}>
                                                        <FormControl >
                                                            <FormGroup aria-label="position" row>
                                                                {
                                                                    AllData[i][j].permissions.map((item: any, ind: number) => {
                                                                        return (
                                                                            <div key={ind} className={`flx-20'}`}>
                                                                                <FormControlLabel
                                                                                    checked={AllData[i][j].permissions[ind]['checked'] ? true: false}
                                                                                    value={AllData[i][j].permissions[ind].name}
                                                                                    control={
                                                                                        <Checkbox size="small"
                                                                                            onChange={()=>{checkboxHandler(i,j,ind)}} 
                                                                                        />
                                                                                    }
                                                                                    label={AllData[i][j].permissions[ind].name}
                                                                                    labelPlacement="end"
                                                                                    className="sml-txt roles-checkbox"
                                                                                />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </FormGroup>
                                                        </FormControl>
                                                        </Grid>

                                                    </Grid>

                                                    </div>
                                                </Grid>
                                            </>
                                        }
                                    })
                                }
                            
                        </Grid>
                        </AccordionDetails>
                    </Accordion>
                    </>
                })
            }
        </Box>
    </>
    )
});
export default SystemAdmin;
