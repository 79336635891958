import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import History from "./History";
import { Box, Button, Grid, Typography } from "@mui/material";
import CustomSelect from "../../components/common/customSelect/CustomSelect";
import CustomInput from "../../components/common/textField/CustomInput";
import CustomDatePicker from "../../components/common/date-picker/CustomDatePicker";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";
import AddUpdate from "../new-ticket/AddUpdate";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IState } from "../../redux/reducers/rootReducers";
import { getAgent } from "../../redux/actions/userManagement/agents";
import { getTicketStatus } from "../../redux/actions/SystemManagement/TicketStatus";
import { getTicketsById } from "../../services/dashboard/dashboard.service";
import SendEmailPopup from "../new-ticket/SendEmailPopup";
import getTemplates from "../../redux/actions/adminInterfaces/EmailTemplates";
import BasicTimeline from "../../components/common/CustomTimeLine";
import { getTimeLogs } from "../../services/timeline/timeline.service";
import { getContacts } from "../../redux/actions/ContactManagement/Contact";
import { getTicketSourceAction } from "../../services/systemManagement/ticketTypes";
import Breadcrumbs from "./../../components/common2/Breadcrumbs";
import { getGroup } from "../../redux/actions/userManagement/group";
import timeSpanToReadbleFormat from "../../util/timeSpanToReadbleFormat";
import TicketAddUpdatePopup from "../../components/TicketManagementSystem/Modules/AddUpdatePopup";
import DataPreloadedWrapper from "../../util/DataPreloadedWrapper";
import CommonStyles from "../../components/common/CommonStyles";
import CustomModalDialog from "../../components/common/customDialog";
import {useNavigate} from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const theme = createTheme();
const useStyles = makeStyles(() => ({
  leftSection: {
    position: "relative",
    padding: "10px",
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    backgroundColor: "#ffff",
    borderRadius: "10px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      position: "relative",
    },
  },
  rightSection: {
    padding: "10px",
    width: "30%",
    backgroundColor: "#FFFFFF",
    height: "100%",
    borderRadius: "10px",
    overflowY: "scroll",
    overflow: "hidden",
    position: "sticky",
    bottom: 0,
    top: "72px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      position: "relative",
      display: "none",
    },
  },
  wrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },

    "& *": {
      fontFamily: "'DM Sans', sans-serif !important"
    },
  },
  heading: {
    fontFamily: "'DM Sans', sans-serif !important"
  },
  updateBoxWrapper: {
    marginTop: "10px",
    marginBottom: "40px",
    display: "flex",
    maxWidth: "700px",
    minWidth: "700px",
    gap: 10,
    flexDirection: "row",
    marginLeft: "auto",
    marginRight: "auto",
    //  position:"fixed",
    //  top:0,
    [theme.breakpoints.down("md")]: {
      minWidth: "auto",
    },
  },
}));
const getTimeLineById = async (id: Number, pageCount: Number) => {
  let timelineLogs = await getTimeLogs(id, pageCount);
  return timelineLogs;
};
export const AllHistory = () => {
  const { ticketId } = useParams();
  return <DataPreloadedWrapper><><AllHistoryContent ticketId={ticketId} /></></DataPreloadedWrapper>;
};

const generateSendEmailPopup = (
  userFormData: any,
  submittedData: any,
  isSendEmailPopup: any,
  setIsSendEMailPopup?: any,
  filterEmailData?: any,
  setData?: any
) => {
  if (isSendEmailPopup) {
    return (
      <SendEmailPopup
        setEditData={false}
        filterEmailData={filterEmailData}
        type="add"
        isSendEmailPopup={isSendEmailPopup}
        setIsSendEMailPopup={setIsSendEMailPopup}
        submittedData={{
          ...submittedData,
          contactList: userFormData.contactList,
        }}
        setData={setData}
      />
    );
  }
};

export const AllHistoryContent = (props: any) => {
  const { ticketId, showBreadCrumbs = true } = props;
  const classes = useStyles();
  const classes1 = CommonStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isAddUpdate, setIsAddUpdate] = useState(false);
  const [isSendEmailPopup, setIsSendEMailPopup] = useState<any>(false);
  const [submittedData, setSubmittedData] = useState<any>([]);
  const [data, setData] = useState<any>({});
  const [editData, setEditData] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [totalEffort, setTotalEffort] = useState("");
  const [isFromAsset, setIsFromAsset] = useState(false);
  const [isTicketClassified, setIsTicketClassified] = useState(false);
  const [AssetId, setAssetId] = useState<any>(0);

  const currAgent = useSelector(
    (state: IState) => state?.agentReducer?.loggedInAgent
  );
  const TemplatesList = useSelector(
    (state: IState) => state?.EmailTemplatesReducer?.AllEmailTemplates
  );
  const group = useSelector((state: IState) => state?.groupReducer?.Data?.data);

  const agent = useSelector((state: IState) => state.agentReducer?.Data?.data);
  const status = useSelector(
    (state: IState) => state?.ticketStatusReducer?.Data.data
  );
  const ContactList = useSelector(
    (state: IState) => state?.ContactsReducer?.AllContacts
  );
  const source = useSelector((state: IState) => state?.priorityReducer?.source);

  const ticketInfo = useSelector(
    (state: IState) => state?.TicketViewReducer?.addUpdate
  );
  const sendEmailPreview = useSelector(
    (state: IState) => state?.TicketViewReducer?.sendEmailView
  );

  let a: any = {
    label: `${currAgent.firstName} ${currAgent.lastName} (${currAgent.userName}) - ${currAgent?.roles?.name}`,
    name: currAgent.firstName,
    value: currAgent.id,
    id: currAgent.id,
    color: "red",
  };
  console.log("TemplatesList", TemplatesList);
  const [formData, setFormData] = useState<any>({
    ticketType: [],
    Category: [],
    group: [],
    priority: [],
    agent: [],
    currentStatus: {},
    status: [],
    contactList: [],
    source: [],
    currentAgent: a?.id !== undefined ? a : {},
    currentSource: {},
    currentPriority: [],
    template: [],
    currActionDate: null,
    currEditData: {},
  });
  const getTicketsByIdFunc = async (id: any) => {
    let res = await getTicketsById(id);
    return res;
  };

  useEffect(() => {
    dispatch(getContacts());
    dispatch(getAgent());
    dispatch(getTemplates());
    dispatch(getTicketStatus());
    dispatch(getTicketSourceAction());
    dispatch(getGroup());

    window.scrollTo(0, 0);
    const queryParams = new URLSearchParams(location.search).get("From");
    const queryAssetId = new URLSearchParams(location.search).get("AssetId");
    setIsFromAsset(queryParams === "AssetView");
    setAssetId(queryAssetId);
  }, []);
  const fetchTimeline = () => {
    getTimeLineById(Number(ticketId), 0).then((res: any) => {
      if (res?.ticketLog?.length > 0) {
        setData({
          logData: res?.ticketLog,
          totalEfforts: res?.totalEfforts,
        });
        console.log("datatotalEffortss", res);
        // setTotalEffort(res?.totalEfforts)
      } else {
        setHasMore(false);
      }
    });
  };

  useEffect(() => {
    fetchTimeline();
    getTicketsByIdFunc(ticketId).then((res) => {
      console.log("getTicketsByIdFunc", res);

      setEditData(res);
    });
  }, [ticketId]);

  useEffect(() => {
    fetchTimeline();
  }, [ticketInfo]);

  useEffect(() => {
    console.log("source", source);
    let temp: any = [];
    if (source.length > 0) {
      source?.filter((item: any) => {
        let a = {
          label: `${item.name}`,
          value: item.id,
          id: item.id,
          name: item.name,
          // icon: "sss",
        };
        temp.push(a);
      });
      setFormData({ ...formData, source: [...temp] });
    }
  }, [source]);

  useEffect(() => {
    // console.log('TemplatesList', TemplatesList)
    let temp: any = [];
    TemplatesList?.filter((item: any) => {
      let a = {
        label: `${item.name}`, //done
        value: item.name, // done
        id: item.id, //done
        body: item.body, // done
        subject: item.subject, // done
        companyName: item.companyName,
        from: item.from,
        cc: item.cc,
        actions: item.actions,
        to: item.to,
        bcc: item.bcc,
        enabled: item.enabled,
        description: item.description,
        roleId: item.roleId,
        isActive: item.isActive,
      };
      temp.push(a);
    });
    console.log("TemplatesList", temp);

    setFormData({ ...formData, template: [...temp] });
  }, [TemplatesList]);
  useEffect(() => {
    console.log("currAgent", currAgent);

    let a: any = {
      label: `${currAgent.firstName} ${currAgent.lastName} (${currAgent.userName}) - ${currAgent?.roles?.name}`,
      name: currAgent.firstName,
      value: currAgent.id,
      id: currAgent.id,
      color: "red",
      // <CustomChip label="1" bg={"#0A93FC"} color="white" transform={false}    icon ={<ConfirmationNumberIcon sx={{width:"15px"}}/>
    };
    setFormData({ ...formData, currentAgent: a });
  }, [currAgent]);

  useEffect(() => {
    let temp: any = [];
    console.log("Category", agent);
    agent?.filter((item: any) => {
      let a: any = {
        label: `${item.firstName} ${item.lastName} (${item.userName}) - ${item?.roles?.name}`,
        name: item.firstName,
        value: item.id,
        id: item.id,
        color: "red",
      };
      temp.push(a);
    });
    setFormData({ ...formData, agent: [...temp] });
  }, [agent]);

  useEffect(() => {
    let temp: any = [];
    status?.filter((item: any) => {
      if (item.isActive) {
        let a = {
          label: `${item.name}`,
          value: item.id,
          id: item.id,
          name: item.name,
          displayName: item.displayName,
          // icon: "sss",
        };
        temp.push(a);
      }
    });
    let s = { ...formData, status: [...temp] };
    setFormData(s);
  }, [status]);
  useEffect(() => {
    let temp: any = [];
    console.log("ContactList", ContactList);
    if (ContactList.length > 0) {
      ContactList?.filter((item: any) => {
        let a: any = {
          label: `${item.name}, ${item.userName}(${item.email})`,
          name: item.name,
          value: item.id,
          id: item.id,
          color: "red",
          knownAs: item.knownAs,
          userName: item.userName,
          jobTitle: item.jobTitle,
          icon: false,
          mail: item.email,
          whatsAppNumber: item.whatsAppNumber,
          company: item.companyName,
          place: item.officeLocation,
          no: item.mobilePhone,
          isVIP: item.isVIP,
        };
        temp.push(a);
      });

      setFormData({ ...formData, contactList: temp });
    }
  }, [ContactList]);
  useEffect(() => {
    let temp: any = [];
    console.log("group----", group, currAgent);
    group?.filter((item: any) => {
      let a: any = {
        label: `${item.name}`,
        name: item.name,
        value: item.id,
        id: item.id,
        color: "red",
        userGroupList: item.userGroupList,
      };
      temp.push(a);
    });
    setFormData({ ...formData, group: [...temp] });
  }, [group]);
  const fetchNextWeek = () => {
    getTimeLineById(Number(ticketId), page + 1).then((res: any) => {
      if (res?.ticketLog?.length > 0) {
        setData({
          logData: [...data.logData, ...res?.ticketLog],
          totalEfforts: res?.totalEfforts,
        });
        console.log("datatotalEffortss", res);
        // setHasMore(true);
        // setTotalEffort(res?.totalEfforts)
      } else {
        setHasMore(false);
      }
    });
    setPage(page + 1);
  };

  const openModal = (payload: any) => {
    dispatch({
      type: "ADD_UPDATE_POPUP_INFO",
      payload: payload,
    });
  };

  useEffect(() => {
    console.info("sendEmailPreview", sendEmailPreview);
    if (sendEmailPreview) {
      setIsSendEMailPopup(true);
    } else {
      setIsSendEMailPopup(false);
    }
  }, [sendEmailPreview]);
  useEffect(() => {

    if (editData?.ticketLogs?.categoryId === 0 || editData?.ticketLogs?.priorityId === 0 || editData?.ticketLogs?.ticketTypeId === 0) {
      setIsTicketClassified(false);
    } else {
      setIsTicketClassified(true);
    }
  }, [editData?.ticketLogs]);
  const EditHandler = () => {
    navigate(`/home/ticket/${editData?.id}`);
  };
  return (
    <>
      {showBreadCrumbs && (
        <Breadcrumbs
          data={
            isFromAsset
              ? [
                  {
                    title: "View-Asset",
                    path: `/ViewAsset/${AssetId}`,
                  },
                  {
                    title: "Timeline",
                    path: `/history/${ticketId}`,
                  },
                ]
              : [
                  {
                    title: "Ticket Management",
                    path: `/TicketManagement?assignee=${currAgent.id}`,
                  },
                  {
                    title: "Timeline",
                    path: `/history/${ticketId}`,
                  },
                ]
          }
        />
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            color: "#273A6B",
            fontSize: "20px",
            fontWeight: 900,
            marginTop: "7px",
            marginBottom: "15px",
          }}
          className={`${classes.heading} timeline-heading`}
        >
          Timeline - {ticketId}
        </div>
        <div className="timeline-total-effort">
          Total effort: {timeSpanToReadbleFormat(data?.totalEfforts, "effort")}
        </div>
      </div>
      <div className={classes.wrapper}>
        {generateSendEmailPopup(
          [],
          sendEmailPreview,
          isSendEmailPopup,
          setIsSendEMailPopup,
          [],
          setData
        )}
        {/* {isSendEmailPopup && (
          <SendEmailPopup
            setData={false}
            setEditData={false}
            filterEmailData={false}
            formData={formData}
            type="addfromtimeline"
            isSendEmailPopup={isSendEmailPopup}
            setIsSendEMailPopup={setIsSendEMailPopup}
            submittedData={{
              ...submittedData,
              contactList: formData.contactList,
            }}
          />
        )} */}
        <TicketAddUpdatePopup />

        <div className={`${classes.leftSection} timeline-update`}>
          {isTicketClassified ?(
            <div className={classes.updateBoxWrapper}>
              <Avatar
                className="timeline-avatar"
                sx={{ bgcolor: "#2F80ED", width: 52, height: 52 }}
                variant="rounded"
                onClick={() => {
                  openModal({
                    type: "update",
                    ...editData,
                  });
                }}
                onChange={(e: any) => {
                  console.log("change", e.target.value);
                  //   setValue(e.target.value);
                }}
              ></Avatar>

              <CustomInput
                isDisabled={false}
                readOnly={true}
                iconPosition={"start"}
                icon={<EditRoundedIcon />}
                isError={
                  false
                  //   formik.touched.category && Boolean(formik.errors.category)
                }
                label={"Category"}
                name="category"
                value={"Add update"}
                onClick={() => {
                  openModal({
                    type: "update",
                    ...editData,
                  });
                }}
                onChange={(e: any) => {
                  console.log("change", e.target.value);
                  //   setValue(e.target.value);
                }}
                helperText={""}
                placeholder={"Search category"}
              />
            </div>)
            :(
              <>
                <CustomModalDialog
                  title=""
                  type=""
                  id="upgradeModal"
                  parentClasses="sml-popup"
                  isOpen={!isTicketClassified}
                  bodyContent={
                    <>
                      <Box className="text-center">
                        <ErrorOutlineIcon style={{ fontSize: 40, color: '#f44336' }} />
                        <br />
                        <Typography variant="body2" sx={{ fontSize: '16px' }}>
                          This ticket is currently unclassified. Kindly click the button below to classify it.
                        </Typography>
                        <br />
                      </Box>
                      <Box className="text-center">
                        <Button
                          className={`${classes1.btn} ${classes1.btnSubmit}`}
                          variant="contained"
                          onClick={EditHandler}
                        >
                          Classify It
                        </Button>
                      </Box>
                    </>
                  }
                />
              </>
            )

          }
          <BasicTimeline
            type="long"
            status={formData}
            setData={setData}
            handleClick={() => {}}
            data={data}
            isTestFlag={true}
            fetchNextWeek={() => {
              fetchNextWeek();
            }}
            hasMore={hasMore}
          />
          {/* <History
            id={ticketId}
            loading={false}
            type="short"
            data={data}
            setData={setData}
          /> */}
        </div>
        {/* <div className={classes.rightSection}> */}
        {/* <Grid
            container
            columnSpacing={5}
            rowSpacing={2}
            columns={{ xs: 1, sm: 1, md: 1 }}
          >
            <Grid item xs={1} sm={1} md={1} key={1}>
              <span style={{ fontSize: "14px" }}>Primary contact</span>
              <CustomSelect
                isOpen={false}
                placeholder="Select a Primary contact"
                width={"100%"}
                name="primaryContact"
                isColor={false}
                isDisabled={true}
                isError={false}
                defaultValue={[]}
                options={[]}
                handleChange={(e: any) => {
                  //   console.log("primaryContact", e);
                  //   formik.setFieldValue("primaryContact", e);
                }}
                isMulti={false}
              />
            </Grid>

            <Grid item xs={1} sm={1} md={1} key={8}>
              <span style={{ fontSize: "14px" }}>Secondary contact</span>
              <CustomSelect
                isOpen={false}
                placeholder="Select a Secondary contact"
                width={"100%"}
                name="secondaryContact"
                isColor={false}
                isDisabled={true}
                isError={false}
                defaultValue={[]}
                options={[]}
                handleChange={(e: any) => {
                  // formik.setFieldValue("secondaryContact", e);
                }}
                isMulti={false}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} key={8}>
              <span style={{ fontSize: "14px" }}>Group</span>
              <CustomSelect
                isOpen={false}
                placeholder="Select a Group"
                width={"100%"}
                name="group"
                isColor={false}
                isDisabled={true}
                isError={false}
                defaultValue={[]}
                options={[]}
                handleChange={(e: any) => {
                  // formik.setFieldValue("group", e);
                }}
                isMulti={false}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} key={8}>
              <span style={{ fontSize: "14px" }}>Agent</span>
              <CustomSelect
                isOpen={false}
                placeholder="Select an Agent"
                width={"100%"}
                name="agent"
                isColor={false}
                isDisabled={true}
                isError={false}
                defaultValue={[]}
                options={[]}
                handleChange={(e: any) => {
                  // formik.setFieldValue("agent", e);
                }}
                isMulti={false}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} key={8}>
              <span style={{ fontSize: "14px" }}>Ticket types</span>
              <CustomSelect
                isOpen={false}
                placeholder="Select a ticket type"
                width={"100%"}
                name="ticketType"
                isColor={false}
                isDisabled={true}
                isError={false}
                defaultValue={[]}
                options={[]}
                handleChange={(e: any) => {}}
                isMulti={false}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} key={8}>
              <span style={{ fontSize: "14px" }}>Category</span>
              <CustomInput
                isDisabled={true}
                readOnly={true}
                iconPosition={"start"}
                icon={false}
                isError={false}
                label={"Category"}
                name="category"
                value={"s"}
                onClick={() => {}}
                onChange={() => {}}
                helperText={""}
                placeholder={"Click here to select an Category"}
              />{" "}
            </Grid>
            <Grid item xs={1} sm={1} md={1} key={8}>
              <span style={{ fontSize: "14px" }}>Priority</span>
              <CustomSelect
                isOpen={false}
                placeholder="Select a Priority"
                width={"100%"}
                name="priority"
                isColor={true}
                isDisabled={true}
                isError={false}
                defaultValue={[]}
                options={[]}
                handleChange={(e: any) => {}}
                isMulti={false}
              />
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              key={8}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <span style={{ fontSize: "14px" }}>Open Date</span>
              <CustomDatePicker
                isDisabled={true}
                onHandleChange={(e: any) => {
                  // formik.setFieldValue("openDate", e);
                }}
                value={""}
                isError={false}
                helperText={""}
                name={"openDate"}
              />
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              key={8}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <span style={{ fontSize: "14px" }}>Due Date</span>
              <CustomDatePicker
                isDisabled={true}
                onHandleChange={(e: any) => {
                  // formik.setFieldValue("dueDate", e);
                }}
                value={""}
                isError={false}
                helperText={""}
                name={"dueDate"}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} key={1} order={{ md: 11, sm: 12 }}>
              <span style={{ fontSize: "14px" }}>
                Subject<span style={{ color: "red" }}>*</span>
              </span>

              <CustomInput
                isDisabled={true}
                readOnly={false}
                onClick={null}
                iconPosition={"start"}
                icon={false}
                isError={false}
                label={"Enter the subject"}
                name="subject"
                value={""}
                onChange={() => {}}
                helperText={""}
                placeholder={"Enter the subject"}
              />
            </Grid>
          </Grid> */}
        {/* </div> */}
      </div>
    </>
  );
};
export default AllHistory;
