import { Box, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ITicket } from '../../../../../redux/actions/TicketsManagement/TicketsManagement';
import AccordionCollapse from '../../../MobileCommon/Collapse';
import { ConvertZ } from '../../../../helpers/helperFunctions';
import timeSpanToReadbleFormat from '../../../../../util/timeSpanToReadbleFormat';
import FileViewFromBlobUrl from '../../../MobileCommon/FileView';
interface IMobileTicketViewProps{
    ticketInfo? : ITicket;
}
function TicketInformation(props:IMobileTicketViewProps) {
    const {sourceCode,category,ticketAssetList=[]}= props?.ticketInfo || {};
    const {title,description,ticketType,priority,categoryId,contacts,secondaryContacts,dueDateTime,firstResponseDueDateTime,nextActionDescription,group,agent,ticketStatus,effort="",note,ticketLogAttachmentsList=[]} = props?.ticketInfo?.ticketLogs || {};
    return (
    <Box>
            <Box>

                <Box>
                    <Typography component={"h4"}>{title}</Typography>

                    <AccordionCollapse
                        title={<span className="label-required">Description <b>*</b></span>}
                        body={<>
                            <TicketFieldValue
                                label=""
                                value={description}
                                isRequired={true}
                            />
                        </>}
                        id="desc"
                    />
                    <AccordionCollapse
                        title={"Case Information"}
                        body={<>
                            <TicketFieldValue
                                label="Ticket Type"
                                value={ticketType?.displayName}
                                isRequired={true}
                            />
                            <TicketFieldValue
                                label="Priority"
                                value={priority?.name}
                                isRequired={true}
                            />
                            <TicketFieldValue
                                label="Category"
                                value={category?.label || ""}
                                isRequired={true}
                            />
                        </>}
                        id="case-info"
                    />



                    <AccordionCollapse
                        title={"Contact Details"}
                        body={<>
                            <TicketFieldValue
                                label="Primary Contact"
                                value={contacts?.name}
                                isRequired={true}
                            />
                            <TicketFieldValue
                                label="Secondary Contact"
                                value={secondaryContacts?.name}
                                isRequired={false}
                            />
                        </>}
                        id="contact-info"
                    />

                    {/* <AccordionCollapse 
                            title={"Assets"}
                            body={<>
                            <AddNewButton label="Add Asset" />
                            </>}
                            id="t-asset"
                        /> 
                    */}

                    <AccordionCollapse
                        title={"Details"}
                        body={<>
                            <TicketFieldValue
                                label="Source"
                                value={sourceCode}
                                isRequired={true}
                            />
                            <TicketFieldValue
                                label="Due Date"
                                value={`${ConvertZ(dueDateTime)}`}
                                isRequired={true}
                            />
                            <TicketFieldValue
                                label="Next action date"
                                value={`${ConvertZ(firstResponseDueDateTime)}`}
                                isRequired={true}
                            />
                            <TicketFieldValue
                                label="Next action description"
                                value={nextActionDescription}
                                isRequired={true}
                            />
                        </>}
                        id="t-details"
                    />

                    <AccordionCollapse
                        title={"Additional Information"}
                        body={<>
                            <TicketFieldValue
                                label="Group"
                                value={group?.name}
                                isRequired={true}
                            />
                            <TicketFieldValue
                                label="Agent"
                                value={`${agent?.firstName || ""} ${agent?.lastName || ""}`}
                                isRequired={true}
                            />
                            <TicketFieldValue
                                label="Status"
                                value={ticketStatus?.displayName}
                                isRequired={true}
                            />
                            <TicketFieldValue
                                label="Effort"
                                value={`${timeSpanToReadbleFormat(effort)}`}
                                isRequired={true}
                            />
                            <TicketFieldValue
                                label="Note"
                                value={note}
                                isRequired={true}
                            />
                        </>}
                        id="group-and-status-details"
                    />

                    <AccordionCollapse 
                        title={`Assets`}
                        body={<>
                            {ticketAssetList.map((i:any)=>{
                                return( 
                                    <Box className="mblAssetItem">
                                        <Box className="name">
                                            <Typography>#{i?.assetId} {i?.assetId?.name}</Typography>
                                            <Typography className="tag">Tag : {i?.assetId?.assetTag || "-"}</Typography>
                                        </Box>
                                        <Box></Box>
                                    </Box>
                                )
                            })}
                        </>}
                        id="t-asset"
                    />

                    <AccordionCollapse
                        title={"Attachments"}
                        body={<>
                            <Grid container spacing={2}>
                                {ticketLogAttachmentsList?.map((i:any,index:number)=>{
                                    return(
                                    <Grid item xs={4} key={index}>
                                        <FileViewFromBlobUrl url={i.attachmentPath || ""}/>
                                    </Grid>)
                                })}
                            </Grid>
                        </>}
                        id="attachments-fields"
                    />
                </Box>
            </Box>
        </Box>
    )
}

interface ITicketFieldValueProps{
    isRequired?:boolean | undefined;
    label: string | undefined;
    value: string | number | undefined;
}
export const TicketFieldValue = (props:ITicketFieldValueProps) =>{
    const {label,value,isRequired}=props;
    return(
        <Box className='label-item'>
            {label &&  <Typography component={"label"}>{label} {isRequired && <span className='txt-danger'>*</span>}</Typography>}
            <Typography>{value || "-"}</Typography>
        </Box>
    )
}

export default TicketInformation
