import {  memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import Sortable from "sortablejs";
import { newTicketsUseStyles } from "../../NewTickets";
import { IState } from "../../../../../../redux/reducers/rootReducers";
import CustomCard1 from "../../../../../common/customCard1";
import LoadMoreNewTickets from "../../LoadMore/LoadMoreNewTickets";
const NewTicketsList = memo(({AllTickets=[]}:any) =>{
  console.log('NewTicketsListNewTicketsList',AllTickets)
    const classes =  newTicketsUseStyles();
    //const AllTickets = useSelector((state: IState) => state?.AllTicketsReducer?.newTickets);
    const shrinkGroupsVal: any = useSelector((state: IState) => state?.LayoutTicketManagementReducer?.groupShrinked);
    useEffect(()=>{
      var ticketList: any = document.getElementById(`newTicketsHodler`);
      if(!ticketList) return;
      var sortable = Sortable.create(ticketList, {
        group: {
          name: "list",
          put: false,
          pull: "clone",
        },
        onStart: function (e:any) {
          let evt: any = document.getElementsByTagName("html");
          evt[0].classList.add("newItemDragged");
        },
        onEnd: function (e:any) {
          let evt: any = document.getElementsByTagName("html");
          evt[0].classList.remove("newItemDragged");
        },
      });
    },[])
    return (
      <Box
        className={`${classes.row} ${classes.flxWrap}`}
        id={"newTicketsHodler"}
      >
            {AllTickets && AllTickets.map((i: any, index: number) => {
              // console.log('i.ticketLogs',i.ticketLogs)
              return (
                <div key={index} className={`${shrinkGroupsVal ? 'col-3' : 'col-6'} p-2p userItem newTicketsTiles`}
                  data-ticket-id={i.id}
                  data-mytickets={'newTickets'}
                  data-new-tickets={true}
                  data-ticket-priority={i.ticketLogs.priorityId}
                  data-ticket-status={i.ticketLogs.statusId}
                  data-ticket-ownerid={i.ticketLogs.agentId}
                >
                    <CustomCard1 id={i.id} sourceCode={i?.sourceCode} ticket={i.ticketLogs} />
                </div>
              )
            })}
        <LoadMoreNewTickets />
      </Box>
    )
})
export default NewTicketsList;