import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/styles";
import * as React from "react";
import { useEffect } from "react";
import CommonStyles from "./CommonStyles";

import { createTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

const theme = createTheme();
const BootstrapDialog = styled(Dialog)(() => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export interface CustomModalDialog {
  id: string;
  title: string;
  children?: React.ReactNode;
  headerContent?: React.ReactNode;
  bodyContent?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: CustomModalDialog) => {
  const { title, id, children, headerContent, onClose, ...other } = props;
  return (
    <Box>
      <Box className="row m-0 align-items-center">
        <Box className="col">
          <DialogTitle
            className="headerTxt"
            alignItems="center"
            sx={{ m: 0, p: 2 }}
            {...other}
          >
            {headerContent}
          </DialogTitle>
        </Box>
        <Box className="pr-1">
          <IconButton
            aria-label="close"
            onClick={onClose}
            className="closeIcon"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default function CustomizedDialogs(props: any) {
  //const useStyles = CommonStyles;
  const classes = CommonStyles();
  const {
    title,
    id,
    children,
    maxWidth,
    bodyContent,
    classnames,
    headerContent,
    footerContent,
    onClose,
    isOpen = false,
    parentClasses = "",
    fullScreen=false,
    ...other
  } = props;
  console.log("full screen", fullScreen);
  const [open, setOpen] = React.useState(props.isOpen);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      {open && (
        <Dialog
          className={`${classes.customModalDialog} ${parentClasses}  ${classnames}`}
          onClose={onClose ? onClose : handleClose}
          aria-labelledby={id}
          open={open}
          fullScreen={props.fullScreen}
          title={title}
        >
          {headerContent && (
            <BootstrapDialogTitle
              id={id}
              onClose={onClose ? onClose : handleClose}
              headerContent={headerContent}
              title={title}
            ></BootstrapDialogTitle>
          )}
          {bodyContent ? (
            <DialogContent >
              {bodyContent}
            </DialogContent>
          ) : (
            <></>
          )}
          {footerContent ? (
            <DialogActions>{footerContent}</DialogActions>
          ) : (
            <></>
          )}
        </Dialog>
      )}
    </div>
  );
}
