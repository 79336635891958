const initialState = {
    isSubmitted: '',
    theme:{},
    isLoggedIn:false,
    roleAccess:{},
    ticketDashboardData:{},
    roleAgentAccess:{},
    loginError:false,
    loginLoader:false,
    ssoInfo:null,
}
const LoginReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case 'SSO_STATUS':
            return {
                ...state,
                ssoInfo: action.payload,
            };
        case 'GET_SUBMITTED':
            return {
                ...state,
                isSubmitted: action.payload,
            };
        case 'SET_LOGIN_ERROR':
            return {
                ...state,
                loginError: action.payload,
            };
        case 'LOGIN_LOADER':
            return {
                ...state,
                loginLoader: action.payload,
            };
        case 'SET_SUBMITTED':
            return {
                ...state,
                theme: action.payload,
            };
        case 'STORE_AGENT_ROLE_ACCESS':
            return {
                ...state,
                roleAgentAccess: action.payload,
            };
        case 'STORE_ROLE_ACCESS':
            return {
                ...state,
                roleAccess: action.payload,
            };
        case 'LOGIN_CALL':
            return {
                ...state,
                isLoggedIn: action.payload,
            };
        case 'STORE_DASHBOARD_DATA':
            console.log('action.payload',action.payload)
            return {
                ...state,
                ticketDashboardData: action.payload,
            };
        default:
            return state;
    }
}
  
export default LoginReducer;
  
