import { Box, Button, Divider } from '@mui/material'
import React, { createRef, useState } from 'react'
import { MobileUserIconBlue, MobileUserIconOrange, NavPlusButton } from '../../common/svgIcons/chartIcon'
import BottomDrawer from '../MobileCommon/BottomDrawer'
import FormWrapper from './FormWrapper';
import { connect } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';
import MobileDropdown from '../MobileCommon/MobileDropdown';
import { debounce } from 'lodash';
import CommonMobileDatePicker from '../MobileCommon/MobileDatePicker';
import { TextBoxLatest } from '../../common/TextBoxLatest/TextBoxLatest';
import { useParams } from 'react-router-dom';
import { addNewTicketTask } from '../../../redux/actions/TicketsManagement/TicketsManagement';
import { formatDate } from '../../helpers/helperFunctions';

function AddNewTask(props:any) {
    const {groups=[],ticketStatus=[],dispatch,callback} = props;
    const descRef = createRef();
    const param = useParams();
    const [isOpened, setIsOpened] = useState(false);
    const [agentList,setAgentList] = useState<any[]>([]);
    
    const submitTaskOption = (val:any) => {
        console.log("submitTaskOption",param?.ticketId,val)
        const newToMeStatusId = ticketStatus.find((i:any)=>i.name==="NewtoMe")?.id || 2;
        const {agentId="0",description="",groupId="0",dueDateTime=""} = props;
        let request:any = {
            "ticketId": +`${param?.ticketId}` ? +`${param?.ticketId}` : 0,
            "sourceCode":"System",
            "ticketTaskLogs":{
                "sourceCode":"System",
                'title' : val.description,
                "agentId": +agentId ? +agentId  : 0,
                "statusId": newToMeStatusId,
                "groupId": +groupId ? +groupId  : 0,
                "description" : description,
                "dueDateTime": formatDate(dueDateTime)
            }
        }
        dispatch(addNewTicketTask(request,addTaskCB));
    }
    const addTaskCB = (...props:any) =>{
        closeTaskOption();
        if(callback) callback(...props)
        // toggleForm()
    }

    const closeTaskOption = () => {
        setIsOpened(false);
    }
    const openTask = () => {
        setIsOpened(true);
    }


    /* ===== function handlers ====== */
    const groupChangeHandler = debounce((val:any) =>{
        console.log("groupChangeHandler",val)
        setAgentList(val?.agentsList || [])
    },100)
    return (
        <>
            <Button onClick={openTask}><NavPlusButton /></Button>

            {isOpened &&
                <BottomDrawer
                    isOpened={isOpened}
                    onClose={closeTaskOption}
                    title="Add New Task"
                >
                    <>
                        <FormWrapper
                            onClose={closeTaskOption}
                            onSubmit={submitTaskOption}
                        >
                            <>
                            <MobileDropdown 
                                label = "Select Group"
                                placeholder = "Select a group"
                                options={groups}
                                isRequired={true}
                                defaultValue={null}
                                name="groupId"
                                onChange={groupChangeHandler}
                            />
                            
                            <MobileDropdown 
                                icon = {<MobileUserIconOrange />}
                                label = "Select Agent"
                                placeholder = "Select a agent"
                                options={agentList}
                                isRequired={true}
                                defaultValue={null}
                                name="agentId"
                            />
                            <CommonMobileDatePicker
                                label={"Select Due Date"}
                                isRequired={true}
                                name='dueDateTime'
                            />
                            <Box className="accordion-holder">
                                <TextBoxLatest
                                    multiline={true}
                                    placeholder="Add a description..."
                                    title={"Enter Task Description"}
                                    ref={descRef}
                                    isMandotary={true}
                                    errorMsg={``}
                                    isError={false}
                                    name='description'
                                    type="text"
                                />
                            </Box>
                            <Divider />
                            </>
                        </FormWrapper>
                    </>
                </BottomDrawer>
            }
        </>
    )
}


const mapStateToProps = (state:IState) => {
    return ({
        ticketStatus: state?.TicketsReducer?.allResponses?.ticketStatus,
        groups: state?.TicketsReducer?.allResponses?.groups,
    })
  };
  
export default connect(mapStateToProps)(AddNewTask);