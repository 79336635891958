const initialState = {
  timeZone: [],
  WTPTimeExceptions: [],
  customDay: [],
  Data: [],
  holidays: [],
};

const workingTimePlanReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "getWTP":
      return {
        ...state,
        Data: action.payload,
      };
      case "singleWTP":
      return {
        ...state,
        Data: action.payload,
      };
    case "getHolidays":
      return {
        ...state,
        holidays: action.payload,
      };
    case "getCustomDay":
      return {
        ...state,
        customDay: action.payload,
      };
    case "getWTPTimeExceptions":
      return {
        ...state,
        WTPTimeExceptions: action.payload,
      };
    case "getTimeZone":
      return {
        ...state,
        timeZone: action.payload,
      };
    case "updateWTP":
    case "updateWTPTimePlanExceptions":
    case "editCustomDay":
    case "bulkDeleteWtp":
    case "multipalUpdateWTP":
    case "softDeleteWTP":
      return {
        ...state,
      };
    case "deleteWTP":
      return {
        ...state,
      };
    case "deleteWTPTimePlanExceptions":
      return {
        ...state,
      };
    case "addCustomDay":
      return {
        ...state,
      };
    case "loading":
      return {
        ...state,
        showLoading: action.payload,
      };

    default:
      return state;
  }
};

export default workingTimePlanReducer;
