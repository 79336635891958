import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IState } from "../../../redux/reducers/rootReducers";
import CommonStyles from "../../common/CommonStyles";
import DBIcon from "./../../../../src/assest/db.svg";
import logoImg from "./../../../../src/assest/icons/logo.png";
import CalSvg from "./../../../../src/assest/icons/menu/calendar.svg";
import Home from "./../../../../src/assest/icons/menu/home.svg";
import Sys from "./../../../../src/assest/icons/menu/sys.svg";
import UserIcon from "./../../../../src/assest/icons/menu/user.svg";
// import AIIcon from "./../../../../src/assest/AI.png";
import ConfirmationNumberOutlined from '@mui/icons-material/ConfirmationNumberOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { useNavigate } from "react-router-dom";
import TcktSvg from "./../../../../src/assest/tckt.svg";

const SideNavbar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationVal = useLocation();
  const roleAccess =  useSelector((state: IState) => state?.LoginReducer?.roleAccess);
  const classes = CommonStyles();
  function isActive(pathName: string) {
    return locationVal?.pathname === pathName;
  }
  return (
    <Drawer
      variant="permanent"
      className={`${classes.mainSideHolder} sidebarHolder`}
    >
      <div className={`${classes.mainSideBar} home-sidebar`}>
        <div>
          {/* <IconButton onClick={handleDrawerClose}>
          <MenuIcon />
        </IconButton> */}
          <Link to="/" className="sidebarLogo">
            <img src={logoImg} alt="" />
            <span></span>
          </Link>
        </div>
        <Divider />
        <List>
          <Link to="/home">
            <ListItem className={[isActive("/") ? "active" : ""].join(" ")}>
              <ListItemIcon id="homeIcon">
                <img src={Home} alt="" />
                <span className="sidebar-lists">Home</span>
              </ListItemIcon>
            </ListItem>
          </Link>

          {/* <Link to="/AIManagement">
            <ListItem className={`sidebar-AI-icon ${isActive("/AIManagement") && "active" }`}>
              <Tooltip title="Explore with AI">
                <ListItemIcon id="homeIcon">
                  <img src={AIIcon} alt="" />
                  <span className="sidebar-lists">AI</span>
                </ListItemIcon>
              </Tooltip>
            </ListItem>
          </Link> */}

          {(roleAccess.isSuperAdmin || roleAccess?.TicketDashboardAccess) && 
          <>
          <Link to="/home/ticket/new-ticket">
            <ListItem
              className={[isActive("#/CMDB") ? "active" : ""].join(" ")}
            >
              <ListItemIcon id="newTicIcon">
                <ConfirmationNumberOutlined />
                <span className="sidebar-lists">
                  New <br /> Ticket
                </span>
              </ListItemIcon>
            </ListItem>
          </Link>
          <Link to={`/TicketManagementSys`}>
            <ListItem
              className={[isActive(`/TicketManagementSys`) ? "active" : ""].join(
                " "
              )}
            >
              <ListItemIcon id="ticMngIcon">
                <img src={TcktSvg} alt="" />
                <span  className="sidebar-lists">
                  Tickets <br /> Management
                </span>
              </ListItemIcon>
            </ListItem>
          </Link>
          </>
          }
          <Link to="/calender">
            <ListItem
              className={[isActive("/calender") ? "active" : ""].join(" ")}
            >
              <ListItemIcon id="calenderIcon">
                <img src={CalSvg} alt="" />
                <span className="sidebar-lists">Calender</span>
              </ListItemIcon>
            </ListItem>
          </Link>
          {(roleAccess.isSuperAdmin || roleAccess?.assetAdminAccess) && 
            <Link to={roleAccess.isSuperAdmin || roleAccess.CMDB ? "/CMDB" : '/AssetManagement'}>
              <ListItem
                className={[isActive("#/CMDB") ? "active" : ""].join(" ")}
              >
                <ListItemIcon id="CMDBIcon">
                  <img src={DBIcon} alt="" />
                  <span className="sidebar-lists">CMDB</span>
                </ListItemIcon>
              </ListItem>
            </Link>
          }
          {(roleAccess.isSuperAdmin || roleAccess?.contactAdminAccess) && 
            <Link to={roleAccess.isSuperAdmin || roleAccess.Contacts ? "/contact" : '/ContactManagement'}>
              <ListItem
                className={[isActive("/contact") ? "active" : ""].join(" ")}
              >
                <ListItemIcon id="contIcon">
                  <PeopleAltOutlinedIcon />
                  <span className="sidebar-lists">Contacts</span>
                </ListItemIcon>
              </ListItem>
            </Link>
          }
          {/* className="disabled" */}
          {(roleAccess.isSuperAdmin || roleAccess?.knowledgeBaseAccess) &&   
            <Link to={'/ArticlesList'}>
              <ListItem
                className={[isActive("#/ArticlesList") ? "active" : ""].join(" ")}
              >
                <ListItemIcon id="knldgIcon">
                  <SchoolOutlinedIcon />
                  <span className="sidebar-lists">Knowledge <br />Base </span>
                </ListItemIcon>
              </ListItem>
            </Link>
          }
          
          {(roleAccess.isSuperAdmin ||roleAccess?.ReportsManagementAccess) &&
            <Link to={'/ReportsManagement'}>
              <ListItem
                className={[isActive("/ReportsManagement") ? "active" : ""].join(" ")}
              >
                <ListItemIcon id="ReportsIcon">
                  <SummarizeOutlinedIcon />
                  <span className="sidebar-lists">Reports</span>
                </ListItemIcon>
              </ListItem>
            </Link>
          }
          {(roleAccess.isSuperAdmin ||roleAccess?.sysAdminAccess || roleAccess?.ApisAdminAccess || roleAccess?.interfacesAdminAccess) &&
            <Link to="/adminPanel">
              <ListItem
                className={[isActive("/adminPanel") ? "active" : ""].join(
                  " "
                )}
              >
                <ListItemIcon id="sysMngIcon">
                  <img src={Sys} alt="" />
                  <span className="sidebar-lists">
                    System <br /> Management
                  </span>
                </ListItemIcon>
              </ListItem>
            </Link>
           }
           {(roleAccess.isSuperAdmin || roleAccess?.usrAdminAccess) &&
            <Link to="/UserManagement">
              <ListItem
                className={[isActive("/UserManagement") ? "active" : ""].join(" ")}
              >
                <ListItemIcon id="userMangIcon">
                  <img src={UserIcon} alt="" />
                  <span className="sidebar-lists">
                    User <br /> Management
                  </span>
                </ListItemIcon>
              </ListItem>
            </Link>
          }
        </List>
      </div>
    </Drawer>
  );
};

export default SideNavbar;
