import axios from "axios";
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";
import LanguageData from "../../../configs/LanguageData.json"
const url = `${configs.BASE_URL}/EmailTemplates`;
const ActionsUrl = `${configs.BASE_URL}/Actions`;
const bulkUpdate = `${url}/status/change?ids=`;
const hardDeleteUrl = `${url}/permanent/delete`;
export const ADD_TEMPLATES = 'ADD_TEMPLATES';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_ACTIONS = 'GET_ACTIONS';
export const storeAllEmailTemplates = (val: any,objVal:any = {}) => ({
    type: GET_TEMPLATES,
    payload: {val,objVal},
});
export const storeAllEmailActions = (val: any) => ({
  type: GET_ACTIONS,
  payload: val,
});
export const addEmailTemplatesAction = (data: any,callback :any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(url,data);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message:`${LanguageData.SUCCESS_NEW_EMAIL_TEMPLATE}${LanguageData.TICKET_CREATED}`,
          severity: "success",
          open: true,
        },
      });
      callback(response);
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response &&  typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const getTemplatesByID = (id:any="",callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios(`${url}/${id}`);
      if(callback) callback(response.data)
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response.data && typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const getTemplates = (callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios(url);
      
      const templateObj:any = {};
      const formattedData:any = [];
      response.data.map((item:any)=>{
        let optionItem = {
          ...item,
          label: item.name,
          value: item.id,
        }
        formattedData.push(optionItem);
        if(!item.isActive) return;
        templateObj[item.actions] = [...(templateObj[item.actions] || []) , optionItem]
      })
      dispatch(storeAllEmailTemplates(formattedData,templateObj));
      if(callback) callback(formattedData)
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response.data && typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const getTemplatesActions = () => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios(ActionsUrl);
      dispatch(storeAllEmailActions(response.data));
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response.data && typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const softDeleteEmailTemplates = (id: any,status:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${bulkUpdate}${id}&status=${status}`);
      dispatch(getTemplates());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_EMAIL_TEMPLATE}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`,
          severity: "success",
          open: true,
        },
      });
    } catch (error) {
      return console.log(error);
    }
  };
};

export const hardDeleteEmailTemplates = (id:number) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.delete(`${hardDeleteUrl}/${id}`);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_EMAIL_TEMPLATE}${LanguageData.TICKET_DELETED}`,
          severity: "success",
          open: true,
        },
      });
      dispatch(getTemplates());
      // ;
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response && typeof error.response.data === 'string' ? error.response.data :"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};

export const editEmailTemplates = (id:number,request:any,callback:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.put(`${url}/${id}`,request);
      // ;
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_EMAIL_TEMPLATE}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      });
      console.log(`${url}/${id}`);
      callback(response);
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      callback(false);
      return console.log(error.response);
    }
  };
};
export const multipalUpdateEmailTemplates = (data: any, ids: any,status:any) => {
  const idMsg = ids.length === undefined ? `${LanguageData.SUCCESS_EMAIL_TEMPLATE}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_EMAIL_TEMPLATE}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  const statusMsg = status ? `${LanguageData.SUCCESS_EMAIL_TEMPLATE}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_EMAIL_TEMPLATE}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  return function (dispatch: any) {
    axios.delete(`${bulkUpdate}${ids}&status=${status}`, data).then((resp) => {
      dispatch({
        type: "multipalUpdateEmailTemplates",
      });
      dispatch(getTemplates());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: ids.length === undefined ? idMsg : statusMsg,
          severity: "success",
          open: true,
        },
      });
    });
  };
};


export const loading = (status: any) => {
  return {
    type: "loading",
    payload: status,
  };
};

export default getTemplates;
