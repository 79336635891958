import { Suspense, lazy, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../../redux/reducers/rootReducers";
import { Box, Typography } from "@mui/material";
import CommonStyles from "../../../common/CommonStyles";
import Sortable from "sortablejs";
import { ActionType } from "../../../../redux/actions/Snackbars";
import { AssignTicketToGroupOrAgent } from "../../../../services/dashboard/dashboard.service";
import newToMeDropUpdate from "../../../../redux/actions/TicketsManagement/TicketActionUtils";
import { NewBoxSkeletonLoader } from "./views/widgets/DashboardSkeletonLoader";
import NewToMeTicketsList from "./views/widgets/NewToMeTicketsList";
import LanguageData from "../../../../configs/LanguageData.json"
//const NewToMeTicketsList = lazy(()=>import('./views/widgets/NewToMeTicketsList'))
export const NewtoMeTicketsBox = memo((props:any) =>{
    const classes = CommonStyles();
    //const AllInfo = useSelector((state: IState) => state?.TicketsReducer?.alldataStructure);
    const AllTickets = useSelector((state: IState) => state?.AllTicketsReducer?.newToMeTickets);
    // const [isComponentLoaded, setIsComponentLoaded] = useState(false);
    // useEffect(() => {
    //   const delay = setTimeout(() => {
    //     setIsComponentLoaded(true);
    //   }, 10);
    //   return () => clearTimeout(delay);
    // }, []);
    const AllInfo = useSelector((state: IState) => state?.TicketsReducer?.alldataStructure);
    const tiketsList = useMemo(()=><NewToMeTicketsList AllTickets={AllTickets}/>,[AllTickets,classes])
    const ticketDropable = useMemo(()=><NewToMeDropable  id = "NewToMeDropable-area" AllInfo={AllInfo}/>,[AllInfo,classes])

    return (
        <>
            <Box className={`${classes.newTcktsBox} px-5p py-5p position-relative`} >
                {/* {isComponentLoaded ? <> */}
                <Suspense fallback={<><NewBoxSkeletonLoader /></>}>
                    <Typography
                    className={`${classes.title} ${classes.themeColorTxt}`}
                    color="textSecondary"
                    >
                    My New Tickets ({AllTickets?.length})
                    </Typography>
                    {tiketsList}
                </Suspense>
                {ticketDropable}
                 {/*</>
                : <><NewBoxSkeletonLoader /></>} */}
            </Box>
        </>
    )
})

export const NewToMeDropable = memo((props:any) =>{
    const {id,AllInfo} = props;
    const currAgent = useSelector((state: IState) => state?.agentReducer?.loggedInAgent);
    // const AllInfo = useSelector((state: IState) => state?.TicketsReducer?.alldataStructure);
    // const FiltersVal: any = useSelector((state: IState) => state?.filtersTicketManagementReducer);
    // const AllData = useSelector((state: IState) => state?.TicketsReducer?.allResponses);
    const dispatch = useDispatch();
    const triggerAlert = useCallback((msg: string, type: string) => {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: msg,
            severity: type,
            open: true,
          },
        });
    },[]); 
    useEffect(()=>{
        let allGroups: any = document.getElementById(`${id}`);
        var sortable = Sortable.create(allGroups, {
            animation: 150,
            group: "list",
            onAdd: function (e: any) {
                //startLoader();
                ticketDropped(e)
                e.clone.replaceWith(e.item);
            },
        });
    },[])
    useEffect(()=>{
        console.log('AllInfo>>>>>>>',AllInfo)
    },[AllInfo])
    const droptoMyTickets = async (ticketId:any,groupId:any,ownerId:any,newToMeStatusId:any) => {
        //setloader(true);
        const response:any = await AssignTicketToGroupOrAgent(AllInfo?.sourceTypes?.Phone, ticketId,groupId,ownerId,newToMeStatusId);
        return response;
    };
    const ticketDropped = async (evt: any) => {
        let ticketId = evt.item.getAttribute("data-ticket-id");
        const appCurrAgent = {...currAgent}
        console.log('currAgent',appCurrAgent.id,ticketId);
        let ownerId = appCurrAgent.id;
        // if(!appCurrAgent.userGroupList) return;
        const groupId:any = appCurrAgent.userGroupList ? appCurrAgent.userGroupList.filter((x:any) => x.isDefault)?.[0]?.groupId : '1';
        if(!groupId){
            triggerAlert(`User not assigned to any groups!`,'warning');
            return;
        }
        const newToMeStatusId = AllInfo?.statusTypes?.NewtoMe;
        console.log('newToMeStatusId',AllInfo)
        //return;
        console.log(ticketId,groupId,ownerId,newToMeStatusId)
        //dispatch(newToMeDropUpdate(ticketId))
        //return;
        droptoMyTickets(ticketId,groupId,ownerId,newToMeStatusId).then((response: any) => {
            console.log('(response.status === 200)',response)
            //setloader(false);
            if(response && response?.status === 200){
                //dispatch(getAllTickets(FiltersVal,AllData));
                //newToMeDropUpdate(ticketId)
                dispatch(newToMeDropUpdate(+ticketId))
                triggerAlert(`${LanguageData.SUCCESS}${ticketId} ${LanguageData.TICKET_UPDATED}`,'success')
            }else{
                const errMsg = response?.data && typeof response.data === 'string' ? response.data : '';
                if(errMsg !== '') triggerAlert( errMsg,'error')
            }  
        })
    };
    return (<Box sx={{height:100,background:"#555"}} className="new-column-overlay" label-prioritycolid={id} label-prioritycolname={"column Name"} id={id}></Box>)
})
export default NewtoMeTicketsBox;